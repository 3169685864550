import React from 'react'

import Alert from '../../../components/Alert'
import Divider from '../../../components/Divider'
import CardTreeItem from '../../../components/CardTreeItem'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import FormSection from '../../../components/Forms/FormSection'
import Textarea from '../../../components/Forms/Textarea'

export const TreatmentPlanSignatureSettings = ({ prefix }: any) => {
  const model = `${prefix ? `${prefix}.` : ''}settings`

  return (
    <>
      <CardTreeItem isOpen title="Signatures">
        <FormSection className="pt-3 pb-5 pr-4">
          <CheckboxGroup
            label="Signatures Required for Sign-Off"
            layout="vertical-dense"
            trueIcon="check"
            falseIcon="empty_checkbox"
            falseStyle="faded"
          >
            <Checkbox label="Require Client Signature" model={`${model}.initial_signatures_required.client`} />
            <Checkbox label="Require Guardians Signatures" model={`${model}.initial_signatures_required.contacts`} />
            <Checkbox label="Require Staff Members Signatures" model={`${model}.initial_signatures_required.staff`} />
            <Checkbox label="Require Supervisors Signatures" model={`${model}.initial_signatures_required.supervisors`} />
          </CheckboxGroup>

          <Divider className="!m-0" />
          <CheckboxGroup
            label="Special Signature Cases"
            layout="vertical-dense"
            trueIcon="check"
            falseIcon="empty_checkbox"
            falseStyle="faded"
          >
            <Checkbox
              label="Require Client Signature only when a Guardian is added"
              model={`${model}.initial_signatures_required.client_only_if_guardian_exists`}
            />
          </CheckboxGroup>
        </FormSection>
      </CardTreeItem>

      <CardTreeItem isOpen title="Legal Copy">
        <FormSection className="pt-3 pb-5 pr-4">
          <Alert small contrast glyph="info">
            The text below will appear on the signature dialog for each signee type.
          </Alert>

          <Textarea label="Client Signatures Legal Copy" model={`${model}.signatures_legal_copy.client`} />
          <Textarea label="Guardians Signatures Legal Copy" model={`${model}.signatures_legal_copy.contacts`} />
          <Textarea label="Staff Signatures Legal Copy" model={`${model}.signatures_legal_copy.staff`} />
          <Textarea label="Supervisors Signatures Legal Copy" model={`${model}.signatures_legal_copy.supervisors`} />
        </FormSection>
      </CardTreeItem>
    </>
  )
}
