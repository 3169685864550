import React from 'react'
import startCase from 'lodash/startCase'
import { darken, tint } from 'polished'

import Glyph from '../Glyph'
import withSettings from '../../hocs/withSettings'
import { daysFrom } from '../../utils/functions'
import { COLORS } from '../../theme'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'
import ClientStatus from '../Statuses/ClientStatus'

const columns = (to?: Function, moveBedsTo?: Function, timezone?: string) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'reference.name',
    Header: 'Name',
    Cell: ({ value, row }: any) => {
      return <TableCell.MainLink to={to && to(row.original)} label={value} avatar={row.original.avatar} />
    },
  },
  {
    width: 150,
    accessor: 'reference.status',
    Header: 'Client Status',
    Cell: ({ value }: any) => {
      return <ClientStatus small status={value} />
    },
  },
  {
    width: 190,
    accessor: 'started_at',
    Header: 'Bed Assignment From',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => {
      return <TableCell.UsDateTime value={value} timezone={timezone} />
    },
  },
  {
    width: 190,
    accessor: 'ended_at',
    Header: 'Bed Assignment Until',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ row, value }: any) => {
      if (row.original?.period_type === 'indefinite') return 'Discharged'
      return <TableCell.UsDateTime value={value} timezone={timezone} />
    },
  },
  {
    id: 'actions',
    width: 160,
    accessor: 'reference.id',
    disableFilters: true,
    canToggleVisible: false,
    Header: 'Actions',
    Cell: ({ row }: any) => (
      <TableCell.Link to={moveBedsTo && moveBedsTo(row.original.reference?.id)} label="Move Beds…" glyph={<Glyph glyph="swap" />} />
    ),
  },
  {
    width: 100,
    accessor: 'reference.sex',
    Header: 'Sex',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'male',
        label: 'Male',
      },
      {
        value: 'female',
        label: 'Female',
      },
      {
        value: 'decline_to_respond',
        label: 'Decline To Respond',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  {
    width: 190,
    accessor: 'reference.current_admission',
    Header: 'Admit Date',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value?.admitted_at} timezone={timezone} />,
  },
  {
    id: 'length_of_stay',
    width: 180,
    accessor: 'reference.current_admission',
    Header: 'Current Length of Stay',
    Cell: ({ value }: any) => <TableCell value={value?.admitted_at ? `${daysFrom(value.admitted_at, timezone)} Days` : '–'} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  moveBedsTo?: Function
  timezone: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const RoomTable = (props: Props) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    moveBedsTo,
    title,
    to,
    timezone,
    titleAfter,
  } = props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, moveBedsTo, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      customCellProps={(cell: any) => {
        if (cell.row?.original?.in_the_past) {
          return {
            style: {
              background: tint(0.7, COLORS.red),
              boxShadow: `inset 0 0 1px ${darken(0.2, COLORS.red)}`,
            },
          }
        }
      }}
    />
  )
}

RoomTable.defaultProps = {
  icon: 'clients',
  emptyDescription: 'No clients added to this room yet',
  localStorageKey: 'room',
}

export default withSettings(RoomTable)
