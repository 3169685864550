import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Alert from '@behavehealth/components/Alert'
import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { ClientsReportDataTable } from '@behavehealth/constructs/LiveReports/ClientsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Clients = () => {
  const queryParams = { status: 'current', category: 'current' }

  const tableProps = useDataTable({
    name: ['reports', 'clients'],
    // endpoint: `/live_reports/clients`,
    endpoint: `/live_reports`,
    updateDeleteEndpoint: `/residents`,
    params: queryParams,
    localStorageKey: 'report_clients_v3',
  })

  const to = React.useMemo(() => (rowData: any) => getClientLink(rowData), [])

  return (
    <Page feature="clients" title="Live Current Clients Report">
      <Grid gap="1rem" columns="100%">
        <Alert contrast type="neutral" glyph="info">
          Live reports rebuild every day around 5:30AM EST, 2:30AM PST. If the data is inaccurate or out of date you can use the rebuild
          report button. Please note rebuild can take up to 30 minutes to finish. You will receive an email notification when the report is
          ready.
        </Alert>

        <ClientsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Clients)
