import React from 'react'
import { useParams } from 'react-router-dom'

import { address } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import ContextShow from '../../components/Forms/ContextShow'
import CustomNoteSections from '../../components/Elements/CustomNoteSections'
import DataFormStatus from '../../components/Statuses/DataFormStatus'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Dialog from '../../components/Dialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SignaturePad from '../../components/Forms/SignaturePad'
import TooltipButton from '../../components/TooltipButton'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { CustomNoteUpdatesRequestedSection } from './CustomNoteUpdatesRequestedSection'

const RootGrievanceCustomNoteOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    saveWithData,
    isLoading,
  } = useOverlay({
    name: 'custom_note',
    endpoint: '/custom_notes',
    invalidate: 'custom_notes',
    options: props,
    openAfterCreate: true,
  })

  const params: any = useParams()

  const { user, tenant, timezone, isPortal, isBehave, isManagement } = useSettings()

  const [supervisor, setSupervisor] = React.useState(data?.supervisor)
  const [supervisorSignatureRequired, setSupervisorSignatureRequired] = React.useState(false)

  const { data: client }: any = useGet({
    name: ['client', params.resource_id],
    url: `/residents/${params.resource_id}`,
  })

  const onDisabledClick = () => {
    form.current.highlightInvalid()
  }

  const sendForSupervisorReviewWithoutSignature = async () => {
    await saveWithData({
      status: 'pending_review',
    })
  }

  const requestUpdates = async (updates) => {
    await saveWithData({
      status: 'updates_required',
      request_updates: updates.value,
    })
  }

  const signOff = async (signature) => {
    await saveWithData({
      [signature.model]: signature.value,
    })
  }

  const signOffWithoutSignature = async () => {
    await saveWithData({
      status: 'signed_off',
    })
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={82} />
  }

  const isSupervisor = user?.id === data?.supervisor?.id
  const isSelfAdd = data?.portal_settings === 'self_add'
  const isClient = user?.type === 'resident'
  const isTrialing = tenant?.plan_status === 'trialing'

  // allow only Owner, Administrator and Behave to edit
  const isFinalStatus = data?.status === 'closed' || data?.status === 'signed_off'
  const hasPermission = !isPortal && (isBehave || isManagement)

  return (
    <Overlay
      onClose={close}
      showBackdrop={isNew || isEditable}
      position="right"
      maxWidth={82}
      closeWrapper={(element) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost. This action cannot be undone."
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={close}
          skip={!isEditable}
        >
          {element}
        </Dialog>
      )}
    >
      <Overlay.Header
        icon="grievance_notes"
        title={isNew ? 'New Grievance Note' : data?.name}
        titleAside={<DataFormStatus status={data?.status} />}
        description={<ClientProfileHeader client={data?.client || client} />}
      />

      {!isEditable && !isTrialing && (
        <Permission permission={hasPermission}>
          <Overlay.SubHeader>
            <ExportPDFButton url={`/custom_notes/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          linked={{
            reference_id: client?.id,
            reference_type: client?.type,
            variant: 'grievance',
          }}
          key={`updated-${data?.updated_at}`}
        >
          <CustomNoteUpdatesRequestedSection data={data} isEditable={isEditable} isSupervisor={isSupervisor} />

          <Section title="Grievance Note Details" commentsModel="settings.comments" commentsName="custom_notes" commentsURL="/custom_notes">
            <FormSection maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
                className="!grow-[1]"
              />

              <Flex gap={8} alignItems="flex-end">
                <ObjectSelector
                  isPolymorphic
                  className="!flex-auto min-w-[200px]"
                  label="Administrator"
                  blankLabel="Select Administrator…"
                  icon="employees"
                  type="owners_administrators"
                  model="supervisor"
                  selectTitle={(data) => data.name}
                  selectDescription={() => null}
                  disableLink={isPortal}
                  validations={
                    supervisorSignatureRequired && {
                      presence: {
                        message: 'Please select an Administrator',
                      },
                    }
                  }
                  onUpdate={({ object }) => {
                    setSupervisor(object)
                  }}
                  value={isEditable ? supervisor : data?.supervisor}
                />

                <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox
                    label="Require Administrator Signature"
                    model="settings.require_supervisor_signature"
                    onUpdate={({ value }) => setSupervisorSignatureRequired(value)}
                  />
                </CheckboxGroup>
              </Flex>

              {data?.portal_settings === 'assigned' && (
                <CheckboxGroup trueIcon="check" falseStyle="faded" falseIcon="cross" isEditable={isClient ? false : isEditable}>
                  <Checkbox
                    label="Request Client to complete this Note"
                    model="settings.client_should_complete"
                    isEditable={isClient ? false : isEditable}
                  />
                </CheckboxGroup>
              )}

              <Flex gap="1rem" stretchChildrenX>
                <Select label="Status" model="status" layout="vertical-dense" defaultValue="draft">
                  <Option label="Draft" value="draft" />
                  <Option label="Pending Review" value="pending_review" />
                  <Option label="Updates Required" value="updates_required" />
                  <Option label="Signed Off" value="signed_off" />

                  <Option label="Closed" value="closed" />
                </Select>

                <DateTimeInput
                  defaultToNow
                  model="started_at"
                  label="Start Date and Time"
                  validations={{
                    presence: {
                      message: 'Please enter a date and time',
                    },
                  }}
                />

                <DateTimeInput model="ended_at" label="End Date and Time" />
              </Flex>

              <MiniRichTextEditor useQuickText isEditable={isClient ? false : isEditable} label="Description" model="notes" maxRows={20} />
            </FormSection>
          </Section>

          <CustomNoteSections
            model="sections"
            validations={{
              presence: {
                message: 'Please add at least one section',
              },
            }}
          />

          {/* <Divider /> */}

          {!isSelfAdd && (
            <>
              <Section headingType="h2" title="Places">
                <FormSection maxWidth="100%" layout="vertical">
                  <ObjectSelector
                    isEditable={isClient ? false : isEditable}
                    model="property"
                    label="Location"
                    type="properties"
                    icon={ICONS.properties}
                    selectTitle={(data) => data.name}
                    selectDescription={(data) => address(data.address)}
                  />

                  <ObjectSelector
                    isEditable={isClient ? false : isEditable}
                    model="organization"
                    label="Organization"
                    type="organizations"
                    icon={ICONS.organizations}
                    selectTitle={(data) => data.name}
                    selectDescription={(data) => address(data.address)}
                  />
                </FormSection>
              </Section>

              <Divider />
            </>
          )}

          <Section headingType="h2" title="Signatures">
            <FormSection layout="vertical" maxWidth={'650px'} className="!mt-4">
              <ContextShow when="settings.require_reference_signature" is={true}>
                <SignaturePad
                  label="Client Signature"
                  person={data?.reference || props.current}
                  model="reference_signature"
                  signedAtModel="reference_signed_at"
                  allowPin={false}
                />
              </ContextShow>

              {data?.supervisor_signature && (
                <SignaturePad
                  className="!max-w-[650px]"
                  label="Administrative Signature"
                  person={data?.supervisor}
                  allowPin={false}
                  model="supervisor_signature"
                  signedAtModel="supervisor_signed_at"
                />
              )}
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this shift note">
            <FormSection maxWidth="100%" layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      {hasPermission && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label={isNew ? 'Save as Draft' : 'Save Changes'}
                glyph="check"
                type="primary"
                color="green"
                onClick={save}
                isLoading={isSaving}
                isDisabled={isSaving}
                flex="100 1 auto"
              />

              {isInvalid && (
                <Button
                  label="Highlight Required Fields"
                  glyph="view"
                  type="default"
                  color="orange"
                  onClick={onDisabledClick}
                  isDisabled={isSaving}
                />
              )}

              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button glyph="edit" label="Edit Custom Note" type="default" isDisabled={isSaving} onClick={edit} flex="100 1 auto" />

              {isSupervisor && !isFinalStatus && (
                <>
                  {data?.settings?.require_supervisor_signature ? (
                    <SignatureDialog
                      model="supervisor"
                      title="Sign Off as Administrator"
                      yesLabel="Apply Signature & Sign Off"
                      onYes={signOff}
                      isDisabled={isLoading || isInvalid}
                      onDisabledClick={onDisabledClick}
                    >
                      <TooltipButton
                        fullWidth
                        label="Sign Off as Administrator…"
                        glyph="signature"
                        type="primary"
                        color="green"
                        isDisabled={isLoading || isInvalid}
                        show={isInvalid}
                        message="Click to highlight required fields"
                      />
                    </SignatureDialog>
                  ) : (
                    <ConfirmDialog
                      model="supervisor"
                      title="Sign Off as Administrator"
                      yesLabel="Sign Off"
                      onYes={signOffWithoutSignature}
                      isDisabled={isLoading || isInvalid}
                      onDisabledClick={onDisabledClick}
                    >
                      <TooltipButton
                        fullWidth
                        label="Sign Off as Administrator…"
                        glyph="check"
                        type="primary"
                        color="green"
                        isDisabled={isLoading || isInvalid}
                        show={isInvalid}
                        message="Click to highlight required fields"
                      />
                    </ConfirmDialog>
                  )}

                  <TextareaDialog
                    model="request_updates"
                    title="What updates should be made?"
                    onYes={requestUpdates}
                    value={data?.request_updates}
                    yesLabel="Save"
                  >
                    <Button label="Request Updates…" glyph="note" type="default" isDisabled={isSaving} />
                  </TextareaDialog>
                </>
              )}

              {!isSupervisor && !isFinalStatus && (
                <>
                  <ConfirmDialog
                    model="supervisor"
                    title="Send for Administrative Review"
                    yesLabel="Send for Review"
                    onYes={sendForSupervisorReviewWithoutSignature}
                    isDisabled={isSaving || isInvalid}
                    onDisabledClick={onDisabledClick}
                  >
                    <TooltipButton
                      fullWidth
                      label="Send for Administrative Review…"
                      glyph="check"
                      type="default"
                      isDisabled={isSaving || isInvalid}
                      show={isInvalid}
                      message="Click to highlight required fields"
                    />
                  </ConfirmDialog>
                </>
              )}

              <DeleteDialog
                title="Delete Custom Note?"
                message={`Are you sure you want to delete this custom note? This action cannot be undone.`}
                onYes={deleteRecord}
              >
                <Button glyph="delete" label="Delete Custom Note…" type="default" color="red" isDisabled={isSaving} fullWidth />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const GrievanceCustomNoteOverlay = withOverlayError(RootGrievanceCustomNoteOverlay)
