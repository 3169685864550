import React from 'react'
import { useQueryClient } from 'react-query'
import produce from 'immer'

import { apiCreate } from '../../modules/api'
import { getClientLink } from '../../utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { getPrefix, invalidateQueries } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import Notifications from '../../modules/notifications'
import Status from '../../components/Status'
import Text from '../../components/Typography/Text'

export const StaffAccessListAuditDataTable = (props: any) => {
  const { to, timezone, employee, rebuildReport, ...rest } = props

  const queryClient = useQueryClient()

  const columns = React.useMemo(
    () => [
      {
        title: 'Access List Clients',
        id: 'access_list_resource_name',
        model: 'client',
        width: 320,
        // disableSort: true,
        disableHide: true,
        formatValue: ({ data, value: client }: any) => {
          if (!data.is_client_in_access_list) {
            return null
          }

          return (
            <MainCell
              id={data.id}
              as={props.mainLinkAs}
              to={getClientLink(data.client)}
              value={client?.name}
              avatar={client?.avatar || ''}
            />
          )
        },
      },
      {
        title: 'Staff Programs/Locations Clients',
        id: 'location_programs_client_name',
        model: 'client',
        width: 320,
        // disableSort: true,
        disableHide: true,
        formatValue: ({ data, value: client }: any) => {
          if (!data.is_client_in_programs_locations) {
            return null
          }

          return (
            <MainCell
              id={data.id}
              as={props.mainLinkAs}
              to={getClientLink(data.client)}
              value={client?.name}
              avatar={client?.avatar || ''}
            />
          )
        },
      },
      {
        title: 'Audit Result',
        model: 'result',
        width: 320,
        // disableSort: true,
        formatValue: ({ data, value: result }: any) => {
          if (data.status === 'valid') return <Status glyph="tick_circle" label="Valid" small color="green" />

          if (result === 'missing_from_programs_and_locations') {
            return <Text glyph="circle_error">Missing from Staff's Programs/Locations</Text>
          }

          if (result === 'missing_from_access_list') {
            return <Text glyph="circle_error">Missing from Staff's Access List</Text>
          }

          return null
        },
      },
      {
        title: 'Recommendation',
        id: '_recommendation',
        disableSort: true,
        hoverExpand: false,
        width: 200,
        formatValue: ({ data }: any) => {
          const showAssign = data.result === 'missing_from_access_list'
          const showRemove = data.result === 'missing_from_programs_and_locations'

          if (showAssign) {
            return (
              <ConfirmDialog
                title="Assign client?"
                message={`Assign ${data.client?.name} from the access list? This staff member will not be able to access their profile anymore.`}
                yesLabel="Assign"
                noLabel="Cancel"
                yesColor="green"
                glyph="tick_circle"
                onYes={async () => {
                  const updated = await apiCreate({
                    name: 'access_control_audits',
                    url: `/access_control_audits/${data?.id}/fix`,
                    notify: false,
                  })

                  if (updated?.data?.data?.status === 'valid') {
                    Notifications.send('Client assigned to Access List', 'positive')

                    const queryKey = [getPrefix(), rest.queryKey].flat()
                    const queryData = queryClient.getQueryData(queryKey)

                    const index = queryData?.data?.findIndex?.((item: any) => item.id === data.id)

                    if (queryData && index > -1) {
                      queryClient.setQueryData(queryKey, (current) => {
                        return produce(current, (draft) => {
                          draft.data[index] = updated?.data?.data
                        })
                      })

                      invalidateQueries(null, 'employees')
                    }
                  }

                  await rebuildReport?.()
                }}
              >
                <Button label="Assign Client" size={100} type="default" color="blue" glyph="add" />
              </ConfirmDialog>
            )
          }

          if (showRemove) {
            return (
              <ConfirmDialog
                title="Remove client?"
                message={`Remove ${data.client?.name} from the access list? This staff member will not be able to access their profile anymore.`}
                yesLabel="Remove"
                noLabel="Cancel"
                yesColor="red"
                glyph="delete"
                onYes={async () => {
                  const updated = await apiCreate({
                    name: 'access_control_audits',
                    url: `/access_control_audits/${data?.id}/fix`,
                    notify: false,
                  })

                  if (updated?.data?.data?.status === 'valid') {
                    Notifications.send('Client removed from Access List', 'positive')

                    const queryKey = [getPrefix(), rest.queryKey].flat()
                    const queryData = queryClient.getQueryData(queryKey)

                    const index = queryData?.data?.findIndex?.((item: any) => item.id === data.id)

                    if (queryData && index > -1) {
                      queryClient.setQueryData(queryKey, (current) => {
                        return produce(current, (draft) => {
                          draft.data[index] = updated?.data?.data
                        })
                      })

                      invalidateQueries(null, 'employees')
                    }
                  }

                  await rebuildReport?.()
                }}
              >
                <Button label="Remove Client" size={100} type="default" color="red" glyph="cross" />
              </ConfirmDialog>
            )
          }

          return null
        },
      },
      {
        title: `Client's Location`,
        id: 'resource_house',
        model: 'client_location',
        width: 320,
        // disableSort: true,
        type: 'profile',
      },
      {
        title: `Client's Programs`,
        id: 'resource_programs',
        model: 'client_programs',
        width: 320,
        disableSort: true,
        type: 'profiles',
      },
    ],
    [to, timezone],
  )

  return <DataTable asCard title="Access Report Results" icon="permissions" columns={columns} filtersConfig={FILTERS_CONFIG} {...rest} />
}

const FILTERS_CONFIG = {
  access_list_resource_name: {
    label: 'Access List Client Name',
    type: 'string',
  },
  location_programs_client_name: {
    label: 'Staff Programs/Locations Client Name',
    type: 'string',
  },
  result: {
    label: 'Audit Result',
    type: 'multi_select',
    condition: 'in',
    options: [
      { label: 'Valid', value: 'valid' },
      { label: `Missing from Staff's Programs/Locations`, value: 'missing_from_programs_and_locations' },
      { label: `Missing from Staff's Access List`, value: 'missing_from_access_list' },
    ],
  },
  resource_house: {
    label: 'Location',
    type: 'multi_object',
    endpoint: '/houses',
    apiKey: 'properties',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  resource_programs: {
    label: 'Programs',
    type: 'multi_object',
    endpoint: '/programs',
    apiKey: 'programs',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
}
