import React from 'react'
import { Route, Redirect, Switch, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom'
import { Global } from '@emotion/react'

import ClaimTypeStatus from '../../components/Statuses/ClaimTypeStatus'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import Flex from '../../components/Flex'

import Overlay from '../../components/Overlay'
import Tabs from '../../components/Tabs'
import OverlayLoader from '../../components/OverlayLoader'

import ClaimStatus from './ClaimStatus'
import ClaimFlagStatus from './ClaimFlagStatus'

import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { ClaimOverlaySummary } from './ClaimOverlay/ClaimOverlaySummary'
import { ClaimOverlayForm } from './ClaimOverlay/ClaimOverlayForm'
import { ClaimOverlayElectronicStatuses } from './ClaimOverlay/ClaimOverlayElectronicStatuses'
import { ClaimOverlayNotes } from './ClaimOverlay/ClaimOverlayNotes'
import { ClaimOverlayAttachments } from './ClaimOverlay/ClaimOverlayAttachments'
import { ClaimOverlayTasks } from './ClaimOverlay/ClaimOverlayTasks'
import { ClaimOverlayERAs } from './ClaimOverlay/ClaimOverlayERAs'
import { ClaimOverlayLogs } from './ClaimOverlay/ClaimOverlayLogs'

import { ClientTagSelector } from '../Tags/ClientTagSelector'

import { useGet } from '../../hooks/useNewAPI'
import { FEATURES } from '../../theme'

const RootNewClaimOverlay = (props: any) => {
  const match = useRouteMatch()
  const location = useLocation()
  const history: any = useHistory()
  const params: any = useParams()

  const id = props?.dataID || params.id

  const { isBehave } = useSettings()
  const isNew = id === 'new'
  const [tab, setTab] = React.useState(isNew ? 'claim_form' : 'claim_info')

  const { data, isLoading } = useGet({
    name: ['insurance_claims', id, 'summary'],
    url: `/insurance_claims/${id}/summary`,
    options: { enabled: !isNew },
  })

  const close = () => {
    if (props.onClose) {
      return props.onClose()
    }

    if (!!location?.parent) {
      history.push(location.parent)
    } else {
      const rootPath = match.url.substring(0, match.url.lastIndexOf('/')).substring(0, match.url.lastIndexOf('/'))
      history.push(rootPath)
    }
  }

  if (isLoading) return <OverlayLoader position="right" maxWidth={125} onClose={close} />

  return (
    <Overlay fullheight showBackdrop={props.showBackdrop} maxWidth={125} onClose={close}>
      <Overlay.Header
        icon={FEATURES.claims.icon}
        title={isNew ? 'Claim' : `Claim #${data?.claim?.identifier}`}
        titleAside={
          !isNew && (
            <Flex gap="0.5rem">
              <ClaimTypeStatus status={data?.claim?.category} />
              <ClaimStatus status={data?.claim?.status} />
              <ClaimFlagStatus status={data?.claim?.flagged_status} />
            </Flex>
          )
        }
        description={
          data?.claim?.client && (
            <div className="mt-1">
              <Flex gap="0.5rem">
                <div>
                  <ClientProfileHeader client={data?.claim?.client} />
                </div>

                <div>
                  <ClientTagSelector client={data?.claim?.client} />
                </div>
              </Flex>
            </div>
          )
        }
      />

      <Tabs activeTab={tab} onChange={setTab}>
        {!isNew && (
          <Tabs.List css={styles.tabsList}>
            <Tabs.Item label="Form" icon="web_form" to={`${match.url}/form`} />
            <Tabs.Item label="Summary" icon="dashboard" to={`${match.url}/summary`} />
            <Tabs.Item
              label={`Attachments ${data?.claim?.attachments_count ? '(' + data?.claim?.attachments_count + ')' : ''}`}
              icon="files"
              to={`${match.url}/attachments`}
            />
            {/* <Tabs.Item label="Electronic Statuses" icon="intake_forms" to={`${match.url}/statuses`} /> */}
            <Tabs.Item label="Notes" icon="clinical_assessments" to={`${match.url}/notes`} />
            <Tabs.Item label="Tasks" icon="tasks" to={`${match.url}/tasks`} />
            <Tabs.Item label="ERAs" icon="deposit" to={`${match.url}/eras`} />

            {isBehave && <Tabs.Item label="🧬 Logs" to={`${match.url}/logs`} />}
          </Tabs.List>
        )}
      </Tabs>

      <Switch>
        <Route path={`${match.path}/form`} children={<ClaimOverlayForm id={id} />} />
        <Route path={`${match.path}/summary`} children={<ClaimOverlaySummary id={id} />} />
        <Route path={`${match.path}/statuses`} children={<ClaimOverlayElectronicStatuses id={id} />} />
        <Route path={`${match.path}/attachments`} children={<ClaimOverlayAttachments id={id} />} />
        <Route path={`${match.path}/notes`} children={<ClaimOverlayNotes id={id} />} />
        <Route path={`${match.path}/tasks`} children={<ClaimOverlayTasks id={id} />} />
        <Route path={`${match.path}/eras`} children={<ClaimOverlayERAs id={id} />} />

        {isBehave && <Route path={`${match.path}/logs`} children={<ClaimOverlayLogs id={id} />} />}

        <Redirect from={match.url} to={`${match.url}/form`} />
      </Switch>

      <Global styles={GLOBAL_STYLES} />
    </Overlay>
  )
}

const GLOBAL_STYLES = { '.NotificationsContainer': { top: `7.5rem !important` } }

const styles = {
  wrapper: {
    padding: '1rem',
  },

  tabsList: {
    padding: '0 1rem',
  },

  claimGrid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '1fr',

    '@media (min-width: 1000px)': {
      gridTemplateColumns: '360px 1fr',
    },

    '@media (min-width: 1500px)': {
      gridTemplateColumns: '360px 1fr 460px',
    },
  },

  gridColumn: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '1fr',

    '@media (min-width: 1000px)': {
      gridColumn: '-1 / 1',
      gridTemplateColumns: '1fr 1fr',
    },

    '@media (min-width: 1500px)': {
      gridTemplateColumns: '1fr',
      gridColumn: '3',
    },
  },
}

export const NewClaimOverlay = withOverlayError(RootNewClaimOverlay)
