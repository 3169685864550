import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { countWord, mapToArray } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import Notifications from '../../modules/notifications'

import Button from '../../components/Button'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import useAnalytics from '../../hooks/useAnalytics'

import { Worksheet } from '../../components/Worksheet/Worksheet'

export const SafetyCheckWorksheetOverlay = (props: any) => {
  const { onClose, onSuccess, invalidateKeys, save, isSaving, allow = 'create-update-delete', editDisabledColumns } = props

  const location: any = useLocation()

  const initialData = props.initialData || location.data

  const { trackEvent } = useAnalytics()

  const form = React.useRef()
  const history = useHistory()

  const [isValid, setIsValid] = React.useState(false)
  const [rowsCount, setRowsCount] = React.useState(0)

  const createMutation: any = useCreate({
    name: ['create-safety-checks'],
    url: `/safety_checks/batch`,
    invalidate: 'safety_checks',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      Notifications.send(`Successfully created ${countWord('Safety Checks', rowsCount)}`, 'positive')

      trackEvent({ name: 'Worksheet Saved', params: { worksheetType: 'Safety Check' } })

      if (onSuccess) onSuccess()
      if (onClose) onClose()
    },
  })

  const close = () => {
    if (onClose) return onClose()

    const url = location?.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  const handleSave = async () => {
    const data = form.current.getFormValue()

    if (save) {
      await save(mapToArray(data))
    } else {
      await createMutation.mutateAsync(mapToArray(data))
    }

    close()
  }

  React.useEffect(() => {
    trackEvent({ name: 'Worksheet Opened', params: { worksheetType: 'Safety Check' } })
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Note',
        model: 'notes',
        type: 'rich_text',
        config: {
          validations: {
            presence: {
              message: 'Please add a note',
            },
          },
        },
      },
      {
        title: 'Date & Time',
        model: 'dated_at',
        type: 'date_time',
        config: {
          defaultTo: 'now',
          validations: {
            presence: {
              message: 'Please add a date & time',
            },
          },
        },
      },
      {
        title: 'Staff',
        model: 'employees',
        type: 'multi_object_selector',
        config: {
          endpoint: '/employees',
          queryKey: 'employees',
          queryParams: { status: 'active' },
          selectTitle: (data: any) => data.name,
          validations: {},
        },
      },
      {
        title: 'Client',
        model: 'resident',
        type: 'object_selector',
        config: {
          endpoint: '/residents',
          queryKey: 'residents',
          queryParams: { status: 'current' },
          selectTitle: (data: any) => data.name,
          validations: {
            presence: {
              message: 'Please select a client',
            },
          },
        },
      },
      {
        title: 'Location',
        model: 'house',
        type: 'object_selector',
        config: {
          endpoint: '/houses',
          queryKey: 'locations',
          queryParams: { category: 'housing,mixed_category' },
          selectTitle: (data: any) => data.name,
          validations: {},
        },
      },
      {
        title: 'Organization',
        model: 'organization',
        type: 'object_selector',
        config: {
          endpoint: '/organizations',
          queryKey: 'organizations',
          selectTitle: (data: any) => data.name,
          validations: {},
        },
      },
    ] as const
  }, [])

  return (
    <Overlay showBackdrop onClose={onClose} position="center" maxWidth={140}>
      <Overlay.Header title="Create Safety Checks" icon="safety_checks" />

      <Overlay.Content className="!p-4">
        <Grid gap="1rem" className="!pb-3">
          <Worksheet
            asCard
            getForm={form}
            title="Safety Checks"
            allow={allow}
            columns={columns}
            onValidationUpdate={setIsValid}
            onRowsCountUpdate={setRowsCount}
            initialData={initialData ? [...initialData] : undefined}
            editDisabledColumns={editDisabledColumns}
          />
        </Grid>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save ${countWord('Safety Checks', rowsCount)}`}
          glyph="check"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={createMutation.isLoading || isSaving}
          isDisabled={!isValid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
