import React from 'react'
import { transparentize } from 'polished'
import clsx from 'clsx'

import { useEditor, EditorContent } from '@tiptap/react'

import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'

import { COLORS, SHADOW } from '../../../../theme'
import { RichTextToolbar } from '../../components/RichTextToolbar'
import Portal from '../../../../components/Portal'
import Markup from '../../../../components/Markup'

export const Text = (props: any) => {
  const { children, className, defaultContent, editElementConfig, element, hoverElement, isEditable, onClick, isPreviewMode, getEditor } =
    props

  const isActive = element?._isActive

  const handleUpdate = ({ editor }) => {
    editElementConfig({
      uuid: element.uuid,
      config: { content: editor.getHTML() },
    })
  }

  const editor = useEditor(
    {
      content: element?.config?.content || defaultContent || 'Click to edit your text…',
      editable: isEditable,
      onUpdate: handleUpdate,
      extensions: [
        Highlight.configure({ multicolor: true }),
        Image.configure({ inline: true }),
        Link.configure({
          protocols: ['tel', 'mailto'],
          linkOnPaste: true,
          openOnClick: false,
        }),
        StarterKit,
        // Strike,
        Table.configure({ resizable: true }),
        TableCell,
        TableHeader,
        TableRow,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TextStyle,
        Color,
        Underline,
        TaskList,
        TaskItem,
      ],
    },
    [isActive, isEditable],
  )

  React.useEffect(() => {
    if (!editor || !getEditor || !isActive) return

    getEditor(editor)
  }, [isActive, editor])

  const rootClasses = clsx(isEditable ? 'is-editable' : 'is-readonly', className)

  if (isPreviewMode || !isEditable) {
    return (
      <div id={element.uuid} css={STYLES.root} className={rootClasses}>
        <Markup value={element?.config?.parsed?.content || element?.config?.content} />
      </div>
    )
  }

  return (
    <>
      <Portal type="rich-text-toolbar">{isEditable && isActive && <RichTextToolbar editor={editor} css={STYLES.toolbar} />}</Portal>

      <div css={STYLES.root} className={rootClasses}>
        {hoverElement}
        {children}

        <EditorContent editor={editor} onClick={onClick} />
      </div>
    </>
  )
}

const STYLES = {
  root: {
    '.ProseMirror': {
      cursor: 'text',
      lineHeight: 1.44,
      outline: 'none !important',

      '> * + *': {
        marginTop: '0.75rem',
      },

      p: {
        'br + br': {
          display: 'none',
        },
      },

      a: {
        cursor: 'text',
      },

      'li > p': {
        margin: 0,
      },

      h1: {
        fontSize: '1.7rem',
        margin: '0.6em 0 0.4em',
      },

      h2: {
        fontSize: '1.5rem',
        margin: '0.8em 0 0.45em',
      },

      h3: {
        fontSize: '1.35rem',
        margin: '1em 0 0.5em',
      },

      h4: {
        fontSize: '1.2rem',
        margin: '1.2em 0 0.6em',
      },

      img: {
        maxWidth: '100%',
        height: 'auto',
      },

      blockquote: {
        marginLeft: '1rem',
        paddingLeft: '0.8em',
        color: COLORS.text,
        borderLeft: `2px solid ${COLORS.textMuted}`,
        fontStyle: 'italic',
      },

      hr: {
        margin: '1.75rem 0',
        border: 'none',
        borderBottom: `1px solid ${COLORS.divider}`,
      },

      '& > *:first-child': {
        marginTop: '0 !important',
      },

      '& > *:last-child': {
        marginBottom: '0 !important',
      },

      table: {
        borderSpacing: 0,
        borderCollapse: 'collapse',
        fontVariant: 'tabular-nums',
        fontFeatureSettings: 'tnum',
        border: `1px solid ${COLORS.text}`,
        minWidth: 200,
        tableLayout: 'fixed',
      },

      'td, th': {
        padding: '0.5rem',
        border: `1px solid ${COLORS.text}`,

        '& > *': {
          textAlign: 'left',
        },

        '&.selectedCell': {
          background: transparentize(0.85, COLORS.blue),
        },

        p: {
          margin: 0,
        },
      },

      th: {
        background: COLORS.lightBackground,
      },

      'ul[data-type="taskList"]': {
        listStyle: 'none',
        padding: 0,

        p: {
          margin: 0,
        },

        li: {
          display: 'flex',
          alignItems: 'center',

          '& > label': {
            flex: '0 0 auto',
            marginRight: '0.5rem',
            userSelect: 'none',
          },

          '& > div': {
            flex: '1 1 auto',
          },
        },

        'input[type="checkbox"]': {
          cursor: 'pointer',
        },
      },
    },

    '&.is-readonly .ProseMirror': {
      a: {
        cursor: 'pointer',
      },

      'ul[data-type="taskList"]': {
        'li > label': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pointerEvents: 'none !important',
        },

        'input[type="checkbox"]': {
          display: 'none',

          '& + span': {
            display: 'flex',
          },

          '& + span::after': {
            content: '""',
            width: 18,
            height: 18,
            display: 'inline-block',
            backgroundRepeat: 'none',
            backgroundPosition: 'center',
            backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuNzMzNDUgMi4xMzI5NkMzLjU5Njk4IDIuMTMyOTYgMy40NjAzNyAyLjE4NDk1IDMuMzU2MzcgMi4yODkyMUwyLjI4OTcgMy4zNTU4OEMyLjA4MTE3IDMuNTY0NDEgMi4wODExNyAzLjkwMjA1IDIuMjg5NyA0LjExMDA1TDYuMTc5MjggNy45OTk2M0wyLjI4OTcgMTEuODg5MkMyLjA4MTE3IDEyLjA5NzcgMi4wODExNyAxMi40MzU0IDIuMjg5NyAxMi42NDM0TDMuMzU2MzcgMTMuNzFDMy41NjQ5IDEzLjkxODYgMy45MDI1MyAxMy45MTg2IDQuMTEwNTMgMTMuNzFMOC4wMDAxMiA5LjgyMDQ2TDExLjg4OTcgMTMuNzFDMTIuMDk3NyAxMy45MTg2IDEyLjQzNTkgMTMuOTE4NiAxMi42NDM5IDEzLjcxTDEzLjcxMDUgMTIuNjQzNEMxMy45MTkxIDEyLjQzNDggMTMuOTE5MSAxMi4wOTcyIDEzLjcxMDUgMTEuODg5Mkw5LjgyMDk1IDcuOTk5NjNMMTMuNzEwNSA0LjExMDA1QzEzLjkxOTEgMy45MDIwNSAxMy45MTkxIDMuNTYzODggMTMuNzEwNSAzLjM1NTg4TDEyLjY0MzkgMi4yODkyMUMxMi40MzUzIDIuMDgwNjggMTIuMDk3NyAyLjA4MDY4IDExLjg4OTcgMi4yODkyMUw4LjAwMDEyIDYuMTc4OEw0LjExMDUzIDIuMjg5MjFDNC4wMDYyNyAyLjE4NDk1IDMuODY5OTIgMi4xMzI5NiAzLjczMzQ1IDIuMTMyOTZaIiBmaWxsPSIjQ0NEMERCIi8+Cjwvc3ZnPgo=")`,
          },

          '&:checked + span::after': {
            backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjg4NzEgMi45MTEwOUwxMi43ODM4IDIuMTYxMDlDMTIuNDc4NCAxLjk1NDM2IDEyLjA2MDIgMi4wMzM2OSAxMS44NTU5IDIuMzM2NTdMNi40NDcxNyAxMC4zMTI1TDMuOTYxNjEgNy44MjY5NEMzLjcwMiA3LjU2NzMyIDMuMjc4OTMgNy41NjczMiAzLjAxOTMxIDcuODI2OTRMMi4wNzQ1OSA4Ljc3MTY5QzEuODE0OTggOS4wMzEyNiAxLjgxNDk4IDkuNDU0MzYgMi4wNzQ1OSA5LjcxNjM4TDUuODk2NzIgMTMuNTM4NUM2LjExMDY0IDEzLjc1MjQgNi40NDcxNyAxMy45MTU5IDYuNzUwMDUgMTMuOTE1OUM3LjA1Mjk4IDEzLjkxNTkgNy4zNTgyNiAxMy43MjYgNy41NTUzOCAxMy40NEwxNC4wNjUgMy44MzY1N0MxNC4yNzE3IDMuNTMzNjkgMTQuMTkyNCAzLjExNzgyIDEzLjg4NzEgMi45MTEwOVoiIGZpbGw9IiMwRjk5MTMiLz4KPC9zdmc+Cg==")`,
          },
        },
      },
    },
  },

  toolbar: {
    position: 'absolute',
    top: '0.25rem',
    left: '0.25rem',
    right: '0.25rem',
    zIndex: 10,
  },

  menuBar: {
    borderRadius: 5,
    boxShadow: SHADOW(4),
    background: COLORS.white,
    zIndex: 10,

    '.toolbar': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },

    '.toolbar + .toolbar': {
      borderTop: `1px solid ${COLORS.divider}`,
    },
  },

  menuDivider: {
    width: 1,
    height: 20,
    background: COLORS.divider,
    margin: '0 0.5rem',
  },

  menuButton: {
    margin: '0.3rem',
    padding: '0.4rem',
    border: 'none',
    background: 'transparent',
    borderRadius: 3,
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.hover,
      svg: { fill: COLORS.blue },
    },

    '&.is-active': {
      background: COLORS.divider,
      svg: { fill: COLORS.text },
    },
  },
}
