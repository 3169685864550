import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import FileStatus from '../../components/Statuses/FileStatus'

import { FILTERS } from '../Filters/config'

import { useSettings } from '../../hooks/useSettings'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const StaffFilesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'title',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value} to={to?.(data)} />,
      },
      {
        title: 'Staff',
        id: 'resource',
        model: 'resource',
        type: 'profile',
      },
      {
        width: 140,
        id: 'content_type',
        model: 'content_type',
        title: 'Type',
        formatValue: ({ value }: any) => <FileStatus status={value} />,
      },
      {
        width: 140,
        disableFilters: true,
        id: 'tags',
        model: 'tags',
        title: 'Tags',
        formatValue: ({ value }: any) => {
          if (!value || value.length === 0) return '-'
          return value.map((tag: any) => tag.name).join(', ')
        },
      },
      {
        width: 200,
        id: 'created_at',
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Staff Files"
      icon="files"
      columns={columns}
      filtersConfig={FILTERS.staff_files}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="employee_documents"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=employee_documents'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'employee_documents' }} />
        </>
      }
    />
  )
}
