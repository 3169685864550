import React from 'react'

import Checkbox from '../Checkbox'
import CheckboxGroup from '../CheckboxGroup'
import ContextHide from '../ContextHide'
import ContextShow from '../ContextShow'
import Textarea from '../Textarea'

const TwelveStepMeetingsCheckboxGroup = ({ label, model, isRequired }) => (
  <>
    <CheckboxGroup
      label={label}
      layout="vertical-dense"
      validations={
        isRequired && {
          presence: {
            message: 'Please select at least one option',
          },
        }
      }
    >
      <Checkbox label="None" model={`${model}.none`} />
      <ContextHide when={`${model}.none`} is={true}>
        <Checkbox label="Alcoholics Anonymous (AA)" model={`${model}.alcoholics_anonymous`} />
        <Checkbox label="Narcotics Anonymous (NA)" model={`${model}.narcotics_anonymous`} />
        <Checkbox label="Faith-Based" model={`${model}.faith_based`} />
        <Checkbox label="Community-Based" model={`${model}.community_based`} />
        <Checkbox label="SMART Recovery" model={`${model}.smart_recovery`} />
        <Checkbox label="Celebrate Recovery" model={`${model}.celebrate_recovery`} />
        <Checkbox label="Other" model={`${model}.other`} />
      </ContextHide>
    </CheckboxGroup>

    <ContextHide when={`${model}.none`} is={true}>
      <ContextShow when={`${model}.other`} is={true}>
        <Textarea
          label="Please list the other meetings"
          model={`${model}_other`}
          validations={
            isRequired && {
              presence: {
                message: 'Please list the other meetings',
              },
            }
          }
        />
      </ContextShow>
    </ContextHide>
  </>
)

TwelveStepMeetingsCheckboxGroup.defaultProps = {
  model: 'twelve_step_meetings',
}

export default TwelveStepMeetingsCheckboxGroup
