import posthog from 'posthog-js'

class PostHog {
  constructor() {
    this.posthog = null
  }

  init = (id, url) => {
    this.posthog = posthog.init(id, {
      api_host: url,
      autocapture: {
        url_ignorelist: ['app.behavehealth.com/.*/forms/full-application', '/', 'app.behavehealth.com', 'app.behavehealth.com/'],
      },
    })
  }

  client = () => this.posthog

  abortPostHog = () => {
    this.posthog = null
  }
}

export default new PostHog()
