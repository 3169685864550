import React from 'react'

import { useSettings } from '../../hooks/useSettings'
import { usDateTime } from '../../utils/functions'

import EVOBCoverageStatus from '../../components/Statuses/EVOBCoverageStatus'
import EVOBStatus from '../../components/Statuses/EVOBStatus'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

import { FILTERS } from '../Filters/config'

export const InsuranceEvobsReportDataTable = (props: any) => {
  const { to } = props

  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Date Requested',
        id: 'created_at',
        model: 'created_at',
        width: 260,
        disableHide: true,
        type: 'date_time',
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={usDateTime(value, timezone)} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'client',
        model: 'resident.name',
        title: 'Client',
        disableSort: false,
        type: 'profile',
      },
      {
        width: 160,
        id: 'status',
        model: 'status',
        title: 'eVOB Status',
        disableSort: false,
        formatValue: ({ data, value }: any) => <EVOBStatus status={value} />,
      },
      {
        width: 160,
        id: 'coverage_status',
        model: 'coverage_status',
        title: 'Coverage Status',
        disableSort: false,
        formatValue: ({ data, value }: any) => (value ? <EVOBCoverageStatus status={value} /> : '-'),
      },
      {
        width: 160,
        id: 'date_of_service',
        model: 'date_of_service',
        title: 'Date of Service',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 160,
        id: 'payer_name',
        model: 'payer_name',
        title: 'Insurance Company',
        disableSort: false,
      },
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Insurance EVOBs"
      icon="insurance"
      columns={columns}
      filtersConfig={FILTERS.live_reports.insurance_evobs}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="insurance_evobs"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=insurance_evobs'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'insurance_evobs' }} />
        </>
      }
    />
  )
}
