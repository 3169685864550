import React from 'react'

import { DataTable } from '../../../../DataTable/DataTable'
import { MainCell } from '../../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../../DataTable/useDataTable'

import { countWord, daysBetween } from '../../../../../utils/functions'
import { FINANCIAL_STATUS } from '../../../../../utils/constants'

import ClientColorPicker from '../../../../ColorPickers/ClientColorPicker'
import { FILTERS } from '../../../../../constructs/Filters/config'

export const AlumniClientsTable = ({ onSelect, dependentValue, options = {}, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-clients-alumni'],
    endpoint: options?.endpoint || '/residents',
    params: options?.params || {
      status: 'alumni',
    },
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_clients_alumni_v2',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.name} avatar={data.avatar} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
      },
      {
        title: 'Color',
        model: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker clientID={data.id} color={data.color_code} />,
      },
      {
        title: 'Financial Status',
        model: 'financial_status',
        width: 150,
        formatValue: ({ value }: any) => FINANCIAL_STATUS[value],
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
      {
        title: 'Admission Date',
        model: 'current_admission.admitted_at',
        id: 'admission_admitted_at',
        type: 'date_time',
      },
      {
        title: 'Discharge Date',
        model: 'current_admission.discharged_at',
        id: 'admission_discharged_at',
        type: 'date_time',
      },
      {
        title: 'Length of Stay',
        id: 'length_of_stay',
        disableSort: true,
        width: 140,
        formatValue: ({ data, timezone }: any) => {
          const admitDate: any = data?.current_admission?.admitted_at
          const dischargeDate: any = data?.current_admission?.discharged_at

          if (!admitDate || !dischargeDate) return null

          return countWord('days', daysBetween(admitDate, dischargeDate, timezone))
        },
      },
      {
        title: 'Cell Phone',
        type: 'phone',
        model: 'phone_no',
      },
      {
        title: 'Email',
        type: 'email',
        model: 'email',
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      {
        title: 'Using Portal',
        model: 'client_portal_status',
        width: 120,
        formatValue: ({ data }: any) => {
          const value = data.client_portal_status

          if (value === 'access') return 'Yes'
          else if (value === 'invited') return 'Invited'
          else return 'No'
        },
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 180,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable {...tableProps} {...rest} title="Past Clients" columns={columns} filtersConfig={FILTERS.alumni} searchBarFilterKey="name" />
  )
}
