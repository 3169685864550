import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

import PayerNetworkStatus from '../../../Statuses/PayerNetworkStatus'
import PayerEnrollmentStatus from '../../../Statuses/PayerEnrollmentStatus'

import { FILTERS } from '../../../../constructs/Filters/config'
import { CLEARING_HOUSES } from '../../../../utils/constants'

export const InsurancePayersDataTable = (props: any) => {
  const { onSelect, dependentValue, ...rest } = props

  const tableProps = useDataTable({
    name: ['selector_insurance_payers'],
    endpoint: '/insurance_local_payers',
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    params: { status: 'active' },
    localStorageKey: 'selector_insurance_payers_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => {
          return <MainCell id={data.id} value={data.name} avatar={data.avatar} onClick={onSelect ? () => onSelect(data) : undefined} />
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
      },
      {
        title: 'Network Status',
        model: 'network_status',
        formatValue: ({ value }) => <PayerNetworkStatus status={value} />,
      },
      {
        title: 'Enrollment Status',
        model: 'enrolled_status',
        formatValue: ({ value }) => <PayerEnrollmentStatus status={value} />,
      },
      {
        title: 'States Covered',
        model: 'insurance_clearing_house_config.states_covered',
        formatValue: ({ value }) => {
          if (!value) return '–'
          if (value.length >= 50) return 'National'

          return value?.join(', ') || '–'
        },
      },
      {
        title: 'Clearing House',
        model: 'insurance_clearing_house_config.clearing_house',
        formatValue: ({ value }) => CLEARING_HOUSES[value] || '–',
      },
      {
        title: 'Payer ID',
        model: 'insurance_clearing_house_config.payer_id',
        width: 110,
      },
      {
        title: 'eVOBs',
        model: 'supports.eligibility',
        type: 'boolean',
      },
      {
        title: 'Claims',
        model: 'supports.claims',
        type: 'boolean',
      },
      {
        title: 'Secondary Claims',
        model: 'supports.secondary_claims',
        type: 'boolean',
      },
      {
        title: 'Claim Status',
        model: 'supports.claim_status',
        type: 'boolean',
      },
      {
        title: 'Remittance',
        model: 'supports.remittance',
        type: 'boolean',
      },
      {
        title: 'Main Phone Line',
        type: 'phone',
        model: 'phone_no',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable
      {...tableProps}
      {...rest}
      title="Insurance Payers"
      icon="bank_building"
      columns={columns}
      filtersConfig={FILTERS.insurance_local_payers}
    />
  )
}
