import React from 'react'

import { COLORS } from '../../../../theme'
import { withFormContext } from '../../../../components/Forms/context'

import Flex from '../../../../components/Flex'
import Grid from '../../../../components/Grid'

import Field from '../../../../components/Forms/Field'
import Value from '../../../../components/Value'
import { ComboBox } from '../../../../components/Forms/ComboBoxes/ComboBox'

import PayerNetworkStatus from '../../../../components/Statuses/PayerNetworkStatus'
import InsurancePayerSupport from '../InsurancePayerSupport'

import { SectionCard } from '../../../../components/SectionCard'

const ClaimPayerDetails = ({ insuranceProvider, onInsuranceProviderUpdate, isCompact }: any) => (
  <SectionCard title="Insurance Provider">
    <Grid gap="1rem">
      <ComboBox
        label="Insurance Payer"
        model="insurance_local_payer"
        icon="insurance"
        type="insurance_local_payers"
        selectTitle={(data: any) => data?.name}
        selectDescription={(data: any) => data?.notes}
        onUpdate={(value: any) => {
          onInsuranceProviderUpdate?.(value)
        }}
      />

      <Flex gap="1rem">
        <Field label="Network Status" isCompact={isCompact}>
          <Value value={insuranceProvider ? <PayerNetworkStatus status={insuranceProvider.network_status} /> : '–'} />
        </Field>

        <Field label="Payer ID" isCompact={isCompact}>
          <Value value={insuranceProvider?.payer_id || '–'} />
        </Field>
      </Flex>

      {insuranceProvider && <InsurancePayerSupport className="!mt-4" payer={insuranceProvider} />}
    </Grid>
  </SectionCard>
)

const styles = {
  dataList: {
    fontSize: '0.95rem',
    flex: '1 1 300px',
  },

  dataListTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: COLORS.textMuted,
  },

  cardTitle: {
    fontSize: '1rem',
  },

  cardContent: {
    height: '100%',
  },
}

export default withFormContext(ClaimPayerDetails)
