import React from 'react'
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'
import kebabCase from 'lodash/kebabCase'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'

import ClaimFlagStatus from '@behavehealth/constructs/RCM/ClaimFlagStatus'
import ClaimStatus, { STATUSES } from '@behavehealth/constructs/RCM/ClaimStatus'
import InsuranceClaimStatus, { STATUSES as INSURANCE_CLAIM_STATUSES } from '@behavehealth/components/Statuses/InsuranceClaimStatus'
import Notifications from '@behavehealth/modules/notifications'
import PayerNetworkStatus from '@behavehealth/components/Statuses/PayerNetworkStatus'
import ClaimTypeStatus from '@behavehealth/components/Statuses/ClaimTypeStatus'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'

import { ClaimStatusOverlay } from '@behavehealth/constructs/RCM/ClaimStatusOverlay'
import { ClaimFlagOverlay } from '@behavehealth/constructs/RCM/ClaimFlagOverlay'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'
import { getClientLink } from '@behavehealth/utils/functions'
import { titleCase } from '@behavehealth/utils/functions'
import { useCreate } from '@behavehealth/hooks/useNewAPI'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

export const Claims = ({ onAddClaimNotes }) => {
  const match = useRouteMatch()

  return (
    <>
      <Tabs defaultTab="draft">
        <Tabs.List className="px-4">
          <Tabs.Item label="All Claims" to={`${match.url}/all`} />

          {Object.keys(STATUSES).map((statusKey) => (
            <Tabs.Item key={statusKey} label={STATUSES[statusKey].label} to={`${match.url}/${kebabCase(statusKey)}`} />
          ))}
        </Tabs.List>
      </Tabs>

      <div className="grid grid-cols-[100%]">
        <Switch>
          <Route path={`${match.path}/:status`}>
            {(routeProps: any) => (
              <ClaimsPage
                key={`status-${routeProps.match?.params?.status}`}
                onAddClaimNotes={onAddClaimNotes}
                status={snakeCase(routeProps.match?.params?.status)}
              />
            )}
          </Route>

          <Redirect exact from={match.url} to={`${match.url}/draft`} />
        </Switch>
      </div>
    </>
  )
}

const ClaimsPage = ({ status, onAddClaimNotes }: any) => {
  const match = useRouteMatch()

  const [selectedClaims, setSelectedClaims]: any = React.useState([])
  const [isStatusOverlayOpen, setIsStatusOverlayOpen] = React.useState(false)
  const [isFlagOverlayOpen, setIsFlagOverlayOpen] = React.useState(false)

  const columns = React.useMemo(
    () => [
      {
        title: 'Claim',
        model: 'identifier',
        formatValue: ({ data, value }: any) => (
          <MainCell testKey="main_cell" id={data?.id} value={value} to={{ pathname: `${match.url}/${data?.id}`, parent: match }} />
        ),
      },
      {
        title: 'Client',
        model: 'resident',
        type: 'insurance_profile',
        config: {
          setLink: ({ rowData }: any) => `${getClientLink(rowData.resident)}/rcm/billing`,
        },
      },
      {
        id: 'insurance_local_payer',
        title: 'Insurance Payer',
        model: 'insurance_local_payer',
        type: 'profile',
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        width: 300,
        featureFlag: 'tags',
        editPermission: 'insurance_claims.edit',
        tagSmartCategories: 'claims',
      },
      {
        title: 'Service Start Date',
        width: 180,
        model: 'from_date',
        type: 'date_time',
      },
      {
        title: 'Service End Date',
        width: 180,
        model: 'to_date',
        type: 'date_time',
      },
      {
        title: 'Internal Status',
        model: 'status',
        formatValue: ({ value }: any) => <ClaimStatus status={value} />,
      },
      {
        title: 'Last Claim Status',
        model: 'last_clearinghouse_claim_status',
        formatValue: ({ value }: any) => <InsuranceClaimStatus status={value} />,
      },
      {
        title: 'Flag Status',
        id: 'flagged_status',
        model: 'flagged_status',
        formatValue: ({ value }: any) => (!!value ? <ClaimFlagStatus status={value} /> : '–'),
      },
      {
        title: 'Total Billed Amount',
        model: 'total_amount',
        width: 180,
        type: 'amount',
      },
      // {
      //   title: 'Total Remaining Balance',
      //   model: 'total_remaining_balance',
      //   width: 180,
      //   formatValue: () => 'TODO',
      // },
      {
        title: 'Claim Type',
        model: 'category',
        width: 250,
        formatValue: ({ value }: any) => <ClaimTypeStatus status={value} />,
      },
      {
        title: 'Payer ID',
        width: 100,
        id: 'payer_id',
        model: 'insurance_local_payer.payer_id',
      },
      {
        title: 'Network Status',
        width: 140,
        id: 'network_status',
        model: 'insurance_local_payer.network_status',
        formatValue: ({ value }: any) => <PayerNetworkStatus status={value} />,
      },
      {
        title: 'Fee Schedule',
        id: 'fee_schedule',
        model: 'insurance_new_fee_schedule.name',
      },
      {
        title: 'Assignee',
        model: 'assignee',
        type: 'profile',
      },
      {
        title: 'Last Claim Note',
        model: 'last_claim_note',
      },
      {
        title: 'Initial Bill Date',
        model: 'initial_submission_at',
        type: 'date_time',
      },
      {
        title: 'Last Bill Date',
        model: 'last_submission_at',
        type: 'date_time',
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  const tableProps = useDataTable({
    name: 'insurance_claims',
    endpoint: '/insurance_claims/search',
    updateDeleteEndpoint: '/insurance_claims',
    params: { version: 'v2', status: status === 'all' ? undefined : status },
    localStorageKey: 'insurance_claims_v1',
  })

  const { mutateAsync: validateClaims } = useCreate({
    name: ['insurance_claims', 'validate'],
    url: '/insurance_claims/batch_validate',
    invalidate: 'insurance_claims',
  })

  const { mutateAsync: submitClaims } = useCreate({
    name: ['insurance_claims', 'submit'],
    url: '/insurance_claims/batch_submit',
    invalidate: 'insurance_claims',
  })

  const { deleteRecords, batchUpdateRecords } = tableProps

  return (
    <div className="p-4 grid grid-cols-[100%]">
      <DataTable
        useMultiSort
        {...tableProps}
        asCard
        canBatchSelect
        title="Claims"
        icon="outbox"
        columns={columns}
        filtersConfig={CLAIM_FILTERS_CONFIG}
        batchActionsConfig={[
          {
            glyph: 'note',
            buttonType: 'primary',
            // keepSelected: true,
            label: () => 'Add Claim Notes',
            action: ({ selectedRows }: any) => {
              onAddClaimNotes(selectedRows)
            },
          },
          {
            glyph: 'warning',
            // keepSelected: true,
            label: () => 'Flag Claims',
            action: ({ selectedRows }: any) => {
              setSelectedClaims(selectedRows)
              setIsFlagOverlayOpen(true)
            },
          },
          {
            type: 'confirmation',
            glyph: 'tick_circle',
            // keepSelected: true,
            label: () => 'Validate Claims',
            config: {
              confirmationTitle: 'Validate Claims',
              confirmationMessage: 'Are you sure you want to Validate all the selected Claims?',
            },
            action: async ({ selectedRows }: any) => {
              let idsToProcess = []
              let skippedIds = []

              for (let i = 0; i < selectedRows.length; i++) {
                const element = selectedRows[i]

                if (element.status === 'draft') {
                  idsToProcess.push(element.id)
                  continue
                }

                if (element.status === 'validated') {
                  idsToProcess.push(element.id)
                  continue
                }

                if (element.status === 'validation_error') {
                  idsToProcess.push(element.id)
                  continue
                }

                skippedIds.push(element.id)
              }

              const hasSkippedIds = size(skippedIds) > 0
              if (hasSkippedIds) {
                Notifications.send(
                  `You have selected ${size(
                    skippedIds,
                  )} Claims that cannot be Validated. Please check the statuses of the selected Claims and try again`,
                  'warning',
                )
              }

              if (size(idsToProcess) === 0) return

              try {
                await validateClaims({ ids: idsToProcess })

                if (hasSkippedIds) {
                  Notifications.send(`Successfully Validated ${size(idsToProcess)} of the selected Claims`, 'positive')
                } else {
                  Notifications.send('Successfully Validated all the selected Claims', 'positive')
                }
              } catch (error) {
                Notifications.send(
                  'Something went wrong with running Validation on the selected Claims. Check the Claims have all the required fields before Validating',
                  'negative',
                )
              }
            },
          },
          {
            type: 'confirmation',
            glyph: 'check_out',
            // keepSelected: true,
            label: () => 'Submit Claims',
            config: {
              confirmationTitle: 'Submit Claims',
              confirmationMessage: 'Are you sure you want to Submit all the selected Claims?',
            },
            action: async ({ selectedRows }: any) => {
              let idsToProcess = []
              let skippedIds = []

              for (let i = 0; i < selectedRows.length; i++) {
                const element = selectedRows[i]

                if (element.status === 'validated') {
                  idsToProcess.push(element.id)
                  continue
                }

                skippedIds.push(element.id)
              }

              const hasSkippedIds = size(skippedIds) > 0
              if (hasSkippedIds) {
                Notifications.send(
                  `You have selected ${size(
                    skippedIds,
                  )} Claims that cannot be Submitted. Please check the statuses of the selected Claims and try again`,
                  'warning',
                )
              }

              if (size(idsToProcess) === 0) return

              try {
                await submitClaims({ ids: idsToProcess })

                if (hasSkippedIds) {
                  Notifications.send(`Successfully Submitted ${size(idsToProcess)} of the selected Claims`, 'positive')
                } else {
                  Notifications.send('Successfully Submitted all of the selected Claims', 'positive')
                }
              } catch (error) {
                Notifications.send(
                  'Something went wrong with the Submission of the selected Claims. Please try again in a few minutes',
                  'negative',
                )
              }
            },
          },
          {
            glyph: 'edit',
            // keepSelected: true,
            label: () => 'Update Status',
            action: ({ selectedRows }: any) => {
              setSelectedClaims(selectedRows)
              setIsStatusOverlayOpen(true)
            },
          },
          // {
          //   glyph: 'print',
          //   label: () => 'Print Claims',
          //   action: ({ selectedRows }: any) => {
          //     onPrintClaims(selectedRows)
          //   },
          // },
          {
            type: 'delete',
            action: async ({ ids }: any) => {
              await deleteRecords(ids.join(','))
            },
          },
        ]}
      />

      <SummonOverlay
        isOpen={isStatusOverlayOpen}
        onClose={() => {
          setSelectedClaims([])
          setIsStatusOverlayOpen(false)
        }}
        overlay={<ClaimStatusOverlay selectedClaims={selectedClaims} />}
      />

      <SummonOverlay
        isOpen={isFlagOverlayOpen}
        onClose={() => {
          setSelectedClaims([])
          setIsFlagOverlayOpen(false)
        }}
        overlay={<ClaimFlagOverlay selectedClaims={selectedClaims} />}
      />
    </div>
  )
}

const CLAIM_FILTERS_CONFIG = {
  resident: {
    label: 'Client',
    type: 'string',
  },
  insurance_local_payer: {
    label: 'Insurance Payer',
    type: 'multi_object',
    endpoint: '/insurance_local_payers',
    apiKey: 'insurance_local_payers',
    glyph: 'bank_building',
    showAvatar: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.status),
  },
  tags: {
    label: 'Tags',
    type: 'tags',
  },
  from_date: {
    label: 'Service Start Date',
    type: 'date_time',
  },
  to_date: {
    label: 'Service End Date',
    type: 'date_time',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: Object.keys(STATUSES).map((key) => ({
      value: key,
      label: STATUSES?.[key]?.label,
    })),
  },
  last_clearinghouse_claim_status: {
    label: 'Last Clearinghouse Claim Status',
    type: 'multi_select',
    options: Object.keys(INSURANCE_CLAIM_STATUSES).map((key: any) => ({
      value: key,
      label: INSURANCE_CLAIM_STATUSES?.[key]?.label,
    })),
  },
  flagged_status: {
    label: 'Flag Status',
    type: 'multi_select',
    options: [
      {
        label: 'To Review',
        value: 'flagged_to_review',
      },
      {
        label: 'Pending',
        value: 'flagged_pending',
      },
      {
        label: 'Finalized',
        value: 'flagged_finalized',
      },
      {
        label: 'Unflagged',
        value: '',
      },
    ],
  },
  payer_id: {
    label: 'Payer ID',
    type: 'string',
  },

  identifier: {
    label: 'Identifier',
    type: 'string',
  },
  network_status: {
    label: 'Network Status',
    type: 'multi_select',
    options: [
      {
        label: 'Out Of Network',
        value: 'out_of_network',
      },
      {
        label: 'In Network',
        value: 'in_network',
      },
    ],
  },
  fee_schedule: {
    label: 'Fee Schedule',
    type: 'string',
  },
  category: {
    label: 'Claim Type',
    type: 'multi_select',
    options: [
      {
        label: 'Institutional Claim (UB-04)',
        value: 'institutional',
      },
      {
        label: 'Professional Claim (CMS-1500)',
        value: 'professional',
      },
    ],
  },
  // total_remaining_balance: {
  //   label: 'Total Remaining Balance',
  //   type: 'number',
  // },
  control_number: {
    label: 'Control Number',
    type: 'string',
  },
  last_claim_note: {
    label: 'Last Claim Note',
    type: 'string',
  },
  total_amount: {
    label: 'Total Billed Amount',
    type: 'number',
  },
  initial_submission_at: {
    type: 'date_time',
    label: 'Initial Submission At',
    glyph: 'date',
  },
  last_submission_at: {
    type: 'date_time',
    label: 'Last Submission At',
    glyph: 'date',
  },
  assignee: {
    type: 'multi_object',
    label: 'Assignee',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.position),
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
