import React from 'react'
import { v4 as uuid } from 'uuid'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import Card from '../../components/Card'
import ButtonGroup from '../../components/ButtonGroup'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

import { SectionCard } from '../../components/SectionCard'
import { ERAClaims } from './components/ERAClaims'
import { ERAServiceLinesFullTable } from './components/ERAServiceLinesFullTable'

const RootERAOverlay = (props: any) => {
  const { data, initialModel, close, form, isOverlayLoading, isEditable, isNew, onValidationUpdate } = useOverlay({
    name: 'insurance_era',
    endpoint: '/insurance_eras',
    invalidate: 'insurance_eras',
    options: props,
  })

  const { isBehave, timezone } = useSettings()
  const [eraView, setEraView] = React.useState('grouped')

  const spreadsheetData = React.useMemo(() => {
    if (!data?.insurance_era_claims) return null

    const res: any = []

    for (const claim of data.insurance_era_claims) {
      for (const serviceLine of claim.insurance_era_service_lines) {
        res.push({
          insurance_era_claim: claim,
          ...serviceLine,
        })
      }
    }

    return res
  }, [data])

  const finalServiceLines = React.useMemo(() => {
    let finalServiceLines: any[] = []

    if (!data?.insurance_era_claims) return finalServiceLines

    for (let i = 0; i < data.insurance_era_claims.length; i++) {
      const claim = data.insurance_era_claims[i]?.insurance_claim

      if (!claim?.insurance_claim_service_lines) continue

      for (let j = 0; j < claim.insurance_claim_service_lines.length; j++) {
        const serviceLine = claim.insurance_claim_service_lines[j]

        finalServiceLines.push({
          _id: uuid(),
          insurance_claim: {
            ...claim,
            resident: claim?.resident || {},
          },
          insurance_claim_service_line: {
            ...serviceLine,
            insurance_new_code: serviceLine.insurance_new_code || {},
          },
          insurance_claim_service_line_id: serviceLine.id,
        })
      }
    }

    return finalServiceLines
  }, [data])

  if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={86} />

  return (
    <>
      <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} position="right" maxWidth={86}>
        <Overlay.Header
          icon="accounting"
          title="ERA"
          titleAside={
            data &&
            isBehave && (
              <Button
                label="Create Payment"
                size={100}
                glyph="add"
                type="primary"
                className="!ml-4"
                link={{
                  pathname: `/posting/payments/new`,
                  data: {
                    insurance_eras: [data],
                    insurance_era_ids: [data.id],
                    _service_lines: finalServiceLines,
                  },
                }}
              />
            )
          }
        />

        {initialModel?.insurance_new_payment && (
          <Overlay.SubHeader>
            <Flex centerY gap={8} justifyContent="space-between">
              <Button
                label={`Payment: ${initialModel?.insurance_new_payment?.identifier}`}
                link={`/posting/payments/${initialModel?.insurance_new_payment?.id}`}
                size={200}
                type="minimal"
                color="blue"
              />
            </Flex>
          </Overlay.SubHeader>
        )}

        <Overlay.Content
          css={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            display: 'grid',
            gridTemplateRows: '100%',
            gridTemplateColumns: '1fr',
          }}
        >
          <Form
            isCompact
            getForm={form}
            timezone={timezone}
            isEditable={false}
            initialModel={initialModel}
            onValidationUpdate={onValidationUpdate}
            maxWidth="100%"
            className="overflow-y-auto"
          >
            <Section>
              <Grid gap="0.75rem">
                <Flex gap="0.5rem">
                  <SectionCard title="ERA" className="!flex-grow-4">
                    <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2 mq1024:grid-cols-3 mq1440:grid-cols-4">
                      <Input label="Insurance Payer" value={data.insurance_local_payer?.name || '–'} />
                      <Input label="Facility Provider" value={data.provider?.credential || '–'} />

                      <Input label="ERA ID" value={data.identifier} />
                      <Input label="Clearing House Payer ID" value={data.clearing_house_payer_id} />

                      <Input label="Payment Method" value={data.payment_method} />
                      <Input label="Payment Format" value={data.payment_format} />
                      <DateInput label="Payment Date" value={data.paid_date} />

                      <Input label="Check Number" value={data.check_number} />
                      <AmountInput label="Paid Amount" value={data.paid_amount} />
                    </div>
                  </SectionCard>

                  <SectionCard title="Info" className="!flex-grow-1">
                    <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
                      <DateTimeInput label="Created At" value={data.created_at} />
                      <DateTimeInput label="Last Updated At" value={data.updated_at} />
                    </div>
                  </SectionCard>
                </Flex>

                <div className="grid gap-3">
                  <Card className="px-2 py-1.5 flex items-center justify-start">
                    <ButtonGroup>
                      <Button
                        label="Table"
                        color="text"
                        type={eraView === 'table' ? 'primary' : 'default'}
                        size={100}
                        onClick={() => {
                          setEraView('table')
                        }}
                      />
                      <Button
                        label="Grouped"
                        color="text"
                        type={eraView === 'grouped' ? 'primary' : 'default'}
                        size={100}
                        onClick={() => {
                          setEraView('grouped')
                        }}
                      />
                    </ButtonGroup>
                  </Card>

                  {eraView === 'table' && spreadsheetData && (
                    <Card className="w-full h-full overflow-hidden grid grid-rows-[100%] grid-cols-[100%]">
                      <ERAServiceLinesFullTable data={spreadsheetData} height="auto" />
                    </Card>
                  )}

                  {eraView === 'grouped' && <ERAClaims asCard data={data?.insurance_era_claims} />}
                </div>
              </Grid>
            </Section>
          </Form>
        </Overlay.Content>
      </Overlay>
    </>
  )
}

export const ERAOverlay = withOverlayError(RootERAOverlay)
