import React from 'react'

import { amount, usDate, daysToWords } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import ChargeStatus from '../../components/Statuses/ChargeStatus'
import Status from '../../components/Status'
import ClientStatus from '../../components/Statuses/ClientStatus'

import { useSettings } from '../../hooks/useSettings'
import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'
import ReportRefetchButton from '../../components/Buttons/ReportRefetchButton'

import Glyph from '../../components/Glyph'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { PAYMENT_METHODS } from '../../utils/constants'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

export const ChargesReportDataTable = (props: any) => {
  const { to } = props

  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'resident',
        model: 'resident.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 180,
        id: 'resident_status',
        model: 'resident.status',
        title: 'Client Status',
        formatValue: ({ value }: any) => <ClientStatus status={value} />,
      },
      {
        title: 'Color',
        id: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker isDisabled clientID={data?.resident?.id} color={data?.resident?.color_code} />,
      },
      {
        width: 180,
        id: 'total_amount',
        model: 'total_amount',
        title: 'Amount',
        formatValue: ({ value }: any) => <Status color="red" label={`-$${amount(value)}`} />,
      },
      {
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <ChargeStatus status={value} />,
      },
      {
        id: 'reference',
        model: 'reference.name',
        title: 'Payer',
      },
      {
        id: 'should_auto_charge',
        model: 'should_auto_charge',
        title: 'Auto Collection',
        type: 'boolean',
      },
      {
        width: 250,
        id: 'payed_with',
        model: 'payed_with',
        title: 'Expected Payment',
        formatValue: ({ data, value }: any) => {
          if (!value) return '–'

          return (
            <>
              <Glyph glyph={PAYMENT_METHODS[value]?.glyph} /> {PAYMENT_METHODS[value]?.label}
            </>
          )
        },
      },
      {
        id: 'service',
        model: 'service.name',
        title: 'Category',
        formatValue: ({ value }: any) => value || '–',
      },
      {
        width: 200,
        id: 'transactioned_at',
        model: 'transactioned_at',
        title: 'Due Date',
        formatValue: ({ data, value }: any) => (
          <>
            <span>{usDate(value, timezone)} </span>
            <span css={{ opacity: 0.6, fontSize: '0.9em' }}>({daysToWords(value, timezone)})</span>
          </>
        ),
      },
      {
        id: 'client_location',
        model: 'client_location.name',
        title: 'Client Location',
        type: 'profile',
      },
      {
        id: 'author',
        width: 180,
        model: 'author.name',
        title: 'Added By',
        type: 'profile',
      },
      {
        width: 140,
        id: 'created_at',
        model: 'created_at',
        title: 'Create Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 140,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Update Date',
        type: 'date_time',
        disableSort: false,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Charges"
      icon="financials"
      columns={columns}
      filtersConfig={CHARGES_FILTERS}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="charges"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=charges'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'charges' }} />
        </>
      }
      {...props}
    />
  )
}

const CHARGES_FILTERS = {
  name: {
    label: 'Name',
    type: 'string',
  },
  resident_status: {
    label: 'Client Status',
    type: 'multi_select',
    options: [
      { label: 'Applicant', value: 'lead' },
      { label: 'Intake', value: 'intake' },
      { label: 'Current', value: 'current' },
      { label: 'Past', value: 'past' },
      { label: 'Past Client', value: 'alumni' },
      { label: 'Declined', value: 'declined' },
    ],
  },
  amount: {
    label: 'Amount',
    type: 'number',
  },
  total_amount: {
    label: 'Total Amount',
    type: 'number',
  },
  notes: {
    label: 'Notes',
    type: 'string',
  },
  payed_with: {
    label: 'Expected Payment',
    type: 'string',
  },
  payment_reference: {
    label: 'Payer',
    type: 'string',
  },
  should_auto_charge: {
    label: 'Auto Collection',
    type: 'boolean',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Charged', value: 'charged' },
      { label: 'Future Charge', value: 'future' },
      { label: 'Cancelled', value: 'cancelled' },
    ],
  },
  service: {
    label: 'Category',
    type: 'string',
  },
  transactioned_at: {
    label: 'Due Date / Payment Date',
    type: 'date_time',
    glyph: 'date',
  },
  client_location: {
    label: 'Client Location',
    type: 'multi_object',
    endpoint: '/houses',
    apiKey: 'locations',
    glyph: 'user_neutral',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  author: {
    label: 'Added By',
    type: 'multi_object',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_neutral',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  created_at: {
    label: 'Create Date',
    type: 'date_time',
    glyph: 'date',
  },
  updated_at: {
    label: 'Last Update Date',
    type: 'date_time',
    glyph: 'date',
  },
}
