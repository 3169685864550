import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ICONS } from '@behavehealth/theme'

import { age, nicePhoneNo } from '@behavehealth/utils/functions'
import { UATests } from '@behavehealth/utils/constants'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import useStore from '@behavehealth/modules/store'

import {
  Accordion,
  AddressSelectorInput,
  Alert,
  Checkbox,
  CheckboxGroup,
  Chotomate,
  DateInput,
  DateOfBirthInput,
  EmailInput,
  Form,
  FormSection,
  GenderIdentitySelect,
  GoogleMeetInput,
  Grid,
  Header,
  HelpTagIframe,
  Input,
  Link,
  MessengerInput,
  MultiObjectSelector,
  ObjectSelector,
  Option,
  Page,
  Permission,
  PhoneInput,
  PreferredPronounsSelect,
  Radio,
  RadioGroup,
  Select,
  SexSelect,
  SkypeInput,
  Textarea,
  WhatsAppInput,
  ZoomInput,
} from '@behavehealth/components'

import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import ContextHide from '@behavehealth/components/Forms/ContextHide'
import ContextShow from '@behavehealth/components/Forms/ContextShow'
import MiniRichTextEditor from '@behavehealth/components/Forms/MiniRichTextEditor'
import SexualOrientationCheckboxGroup from '@behavehealth/components/Forms/elements/SexualOrientationCheckboxGroup'
import Switch from '@behavehealth/components/Forms/Switch'
import YesNoRadioGroupWithTextarea from '@behavehealth/components/Forms/elements/YesNoRadioGroupWithTextarea'
import RaceCheckboxGroup from '@behavehealth/components/Forms/elements/RaceCheckboxGroup'
import NewEthnicityCheckboxGroup from '@behavehealth/components/Forms/elements/NewEthnicityCheckboxGroup'

import { MessageBoardDataTable } from '@behavehealth/constructs/MessageBoard/MessageBoardDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { RecoveryCapitalFormConsent } from '@behavehealth/constructs/RecoveryCapitalForms/RecoveryCapitalFormConsent'

const GeneralInfo = () => {
  const match = useRouteMatch()
  const user = useStore((state) => state.user)

  const [referralSource, setReferralSource] = React.useState<any>(null)
  const [referralSourceOut, setReferralSourceOut] = React.useState<any>(null)

  const { timezone } = useSettings()

  const { data: client }: any = useGet({
    name: ['client', match.params.resource_id],
    url: `/residents/${match.params.resource_id}`,
  })

  const { mutateAsync: updateClient }: any = useUpdate({
    name: ['client', match.params.resource_id],
    url: `/residents/${match.params.resource_id}`,
    invalidate: ['clients'],
  })

  const [showExpiredMessages, setShowExpiredMessages] = React.useState(false)

  const tableProps: any = useDataTable({
    name: ['client', match.params.resource_id, 'messages'],
    endpoint: `/residents/${match.params.resource_id}/messages`,
    params: { show_expired: !!showExpiredMessages },
    keepPreviousData: false,
    localStorageKey: `client_profile_messages_v1`,
  })

  if (!client) return null

  const isBehave = user?.type === 'bh_employee'
  const isOwner = ['owner', 'administrator'].includes(user?.position)
  const hasClientPortalAccess = client?.client_portal_status === 'access'

  const canEditLoginDetails = isBehave || isOwner

  const getVia = (invite: any) => {
    if (invite?.settings?.invited_via?.sms) return `SMS (${nicePhoneNo(invite.sent_to_details.phone_no || '–')})`
    else if (invite?.settings?.invited_via?.email) return `email (${invite.sent_to_details.email || '–'})`

    return '–'
  }

  return (
    <Page feature="general_info" plan="lite" help={<HelpTagIframe id="client_general_info" />}>
      <Chotomate ready name="general_info" />

      <Permission showForCommunity permission="messages.view" featureFlagV2="message_board">
        <div className="grid mb-4">
          <Header
            icon="care_level_4"
            title="Message Board"
            className="!px-0 !py-3"
            aside={
              <>
                <Switch
                  isCompact
                  horizontal
                  onUpdate={({ value }) => setShowExpiredMessages(value)}
                  label="Show Expired Messages"
                  labelWidth="auto"
                  size={100}
                  withHover={false}
                />

                <Button
                  label="Add Message"
                  type="primary"
                  glyph="add"
                  link={{
                    pathname: `${match.url}/message-board/new`,
                    parent: match,
                    data: {
                      message_type: 'client_internal',
                      reference: client,
                    },
                  }}
                  permission="messages.create"
                />
              </>
            }
          />

          <Card>
            <MessageBoardDataTable
              to={(message) => `${match.url}/message-board/${message.id}`}
              title="Internal Client Messages"
              asCard={false}
              {...tableProps}
            />
          </Card>
        </div>
      </Permission>

      {/* Personal Details */}
      <Accordion
        isOpen
        activateEditMode
        initialModel={client}
        title="Personal Details"
        glyph="personal_details"
        permission="clients.general_info.personal_details.view"
        editPermission="clients.general_info.personal_details.edit"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form timezone={timezone} key={`personal_details-${client?.updated_at}`}>
          {({ data }) => (
            <FormSection>
              <Input
                horizontal
                label="First Name"
                model="first_name"
                validations={{
                  presence: {
                    message: "Please enter the client's first name",
                  },
                }}
              />

              <Input horizontal label="Middle Name" model="middle_name" />

              <Input
                horizontal
                label="Last Name"
                model="last_name"
                validations={{
                  presence: {
                    message: "Please enter the client's last name",
                  },
                }}
              />

              <Input horizontal label="Preferred Name" model="preferred_name" />

              <DateOfBirthInput horizontal label="Date of Birth" model="dob" smartDescription={(date) => `Age: ${age(date)} y/o`} />

              <RaceCheckboxGroup />

              <Select allowEmpty horizontal label="Ethnicity (Deprecated)" model="ethnicity">
                <Option label="American Indian or Alaska Native" value="american_indian_or_alaska_native" />
                <Option label="Asian" value="asian" />
                <Option label="Black or African American" value="black_or_african_american" />
                <Option label="Hispanic or Latino" value="hispanic_or_latino" />
                <Option label="Native Hawaiian or Other Pacific Islander" value="native_hawaiian_or_other_pacific_islander" />
                <Option label="White" value="white" />
                <Option label="Other" value="other" />
              </Select>

              <NewEthnicityCheckboxGroup />

              <SexSelect label="Sex Assigned at Birth" model="sex" />
              <SexualOrientationCheckboxGroup />
              <GenderIdentitySelect label="Gender Identity" model="gender_identity" />
              <PreferredPronounsSelect label="Preferred Pronouns" model="preferred_pronouns" />

              <RadioGroup label="Veteran Status" model="is_veteran" layout="horizontal-dense">
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <YesNoRadioGroupWithTextarea label="Has Disability" model="has_disability" />

              <CheckboxGroup label="Part of Priority Populations" layout="vertical-dense" trueIcon="check" falseStyle="hidden">
                <Checkbox label="IV Drug Users" model="part_of_priority_populations.iv_drug_users" />
                <Checkbox label="Pregnant Individuals" model="part_of_priority_populations.pregnant_individuals" />
                <Checkbox label="Mothers with Dependent Children" model="part_of_priority_populations.mothers_with_dependent_children" />
                <Checkbox label="Fathers with Dependent Children" model="part_of_priority_populations.fathers_with_dependent_children" />
                <Checkbox label="Veterans" model="part_of_priority_populations.veterans" />
                <Checkbox label="Family Member of Veteran" model="part_of_priority_populations.family_member_of_veteran" />
                <Checkbox label="Co-Occurring Disorders" model="part_of_priority_populations.co_occurring_disorders" />
                <Checkbox label="Justice-Involved" model="part_of_priority_populations.justice_involved" />
                <Checkbox label="Homeless" model="part_of_priority_populations.homeless" />
                <Checkbox label="LGBTQ+" model="part_of_priority_populations.lgbtq" />
                <Checkbox label="Indigenous" model="part_of_priority_populations.indigenous" />
                <Checkbox label="Disabled" model="part_of_priority_populations.disabled" />
                <Checkbox label="DV / Human Trafficking Victims" model="part_of_priority_populations.dv_human_trafficking_victims" />
              </CheckboxGroup>

              {(data?.is_veteran || data?.part_of_priority_populations?.veterans) && (
                <>
                  <Input label="Branch of Last Service:" model="military_branch_of_last_service" />

                  <RadioGroup label="Duty Status:" model="veteran_status" layout="vertical-dense">
                    <Radio label="Currently on active duty" value="currently_on_active_duty" />
                    <Radio label="Served on active duty" value="served_on_active_duty" />
                  </RadioGroup>
                </>
              )}

              <ContextShow when="part_of_priority_populations.family_member_of_veteran" is={true}>
                <Input label="Family Member's Branch of Last Service:" model="family_member_military_branch_of_last_service" />
              </ContextShow>

              <ContextShow
                orMode
                when={[
                  'new_ethnicity.native_indigenous',
                  'part_of_priority_populations.indigenous',
                  'new_race.american_indian_alaska_native',
                ]}
                is={true}
              >
                <Input label="Tribe" model="tribe" />
              </ContextShow>
            </FormSection>
          )}
        </Form>
      </Accordion>

      {/* Contact Details */}
      <Accordion
        isOpen
        skipValidCheck
        activateEditMode
        initialModel={client}
        title="Contact Details"
        glyph="contact_details"
        permission="clients.general_info.contact_details.view"
        editPermission="clients.general_info.contact_details.edit"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form key={`contact_details-${client?.updated_at}`}>
          <FormSection labelWidth="200px" maxWidth="700px">
            <PhoneInput
              horizontal
              label="Cell Number"
              model="phone_no"
              description="Please note that this is different to the Login Cell Number (see below)"
            />

            <PhoneInput
              horizontal
              label="Landline Number"
              model="landline_no"
              glyph="landline"
              layout="horizontal"
              after={<Input glyph="phone_ext" model="phone_no_ext" placeholder="Ext" size={6} />}
            />

            <EmailInput
              horizontal
              label="Email Address"
              model="email"
              description="Please note that this is different to the Login Email (see below)"
            />

            <Permission newFeatureFlag="zoom_gmeet">
              <WhatsAppInput horizontal label="Whatsapp" model="whatsapp" />
              <MessengerInput horizontal label="Facebook ID" model="facebook" />
              <SkypeInput horizontal label="Skype ID" model="skype" />
              <ZoomInput horizontal label="Zoom URL" model="zoom" />
              <GoogleMeetInput horizontal label="Google Meet URL" model="google_meet" />
            </Permission>

            <Grid gap={8}>
              <CheckboxGroup
                label="Consent to Contact"
                trueIcon="check"
                falseIcon="cross"
                falseStyle="faded"
                description={
                  <Alert glyph="info" type="warning" className="!mt-2">
                    Do not enable consent to contact without a valid, signed authorization from the client
                  </Alert>
                }
              >
                <Checkbox
                  testKey="consent_checkbox"
                  label="I acknowledge that the Client has given valid Consent to be contacted via SMS or email"
                  model="is_allowed_to_contact"
                />
              </CheckboxGroup>
            </Grid>
          </FormSection>
        </Form>
      </Accordion>

      {/* Recovery */}
      <Accordion
        activateEditMode
        initialModel={client}
        title="Recovery"
        glyph="recovery_information"
        permission="clients.general_info.recovery_information.view"
        editPermission="clients.general_info.recovery_information.edit"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form timezone={timezone} key={`recovery_information-${client?.updated_at}`}>
          <FormSection labelWidth="170px">
            <CheckboxGroup label="MOUD / MAT History" trueIcon="check" falseStyle="faded" layout="vertical-dense">
              <Checkbox
                label="Is the Client currently using, or has Client ever used any medications to help treat opioid / alcohol addiction or dependence?"
                model="has_moud_mat_history"
              />
            </CheckboxGroup>

            <MultiObjectSelector
              label="Treatment Centers"
              model="treatment_centers"
              type="treatment_centers"
              icon={ICONS.enterprise}
              selectTitle={(data) => data.name}
              selectDescription={(data) => data.category}
            />

            <MultiObjectSelector
              isRelations={false}
              model="drugs_of_choice"
              label="Drugs of Choice"
              blankLabel="Select Drugs…"
              apiData={UATests}
              showAvatars={false}
            />

            <Textarea label="Other Addictive Behaviors" model="other_addictive_behaviors" />
            <Textarea label="Co-Occuring Mental Health Disorders" model="co_occuring_mental_health_disorder" />

            <DateInput label="Sobriety Date" model="sobriety_date" />

            <CheckboxGroup label="Recovery Programs" trueIcon="check" falseStyle="hidden" layout="vertical-dense">
              <Checkbox label="Alcoholics Anonymous (AA)" model="recovery_programs.aa" />
              <Checkbox label="Narcotics Anonymous (NA)" model="recovery_programs.na" />
              <Checkbox label="Smart Recovery" model="recovery_programs.smart_recovery" />
              <Checkbox label="Celebrate Recovery" model="recovery_programs.celebrate_recovery" />
              <Checkbox label="Other" model="recovery_programs.other" />
            </CheckboxGroup>

            <ContextShow when="recovery_programs.other" is={true}>
              <Textarea label="Other Recovery Programs" model="recovery_programs_other" />
            </ContextShow>

            <Select allowEmpty label="Current Step" model="current_step">
              <Option label="Step 1" value="step_1" />
              <Option label="Step 2" value="step_2" />
              <Option label="Step 3" value="step_3" />
              <Option label="Step 4" value="step_4" />
              <Option label="Step 5" value="step_5" />
              <Option label="Step 6" value="step_6" />
              <Option label="Step 7" value="step_7" />
              <Option label="Step 8" value="step_8" />
              <Option label="Step 9" value="step_9" />
              <Option label="Step 10" value="step_10" />
              <Option label="Step 11" value="step_11" />
              <Option label="Step 12" value="step_12" />
            </Select>

            <YesNoRadioGroupWithTextarea
              label="Suicidal Ideations, Attempts, or Treatment For Self-Harming Behaviors"
              model="has_suicidal_ideations"
            />

            <YesNoRadioGroupWithTextarea
              label="Victim Of Sex Trafficking"
              model="was_sex_trafficking_victim"
              textareaLabel="More Details"
            />
          </FormSection>
        </Form>
      </Accordion>

      {/* Recovery Capital */}
      <Accordion
        activateEditMode
        initialModel={client}
        title="Recovery Capital"
        icon="recovery_capital_forms"
        permission="recovery_capital_forms.view"
        editPermission="recovery_capital_forms.edit"
        featureFlagV2="recovery_capital_forms"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form timezone={timezone} key={`recovery_capital-${client?.updated_at}`}>
          <FormSection>
            <RecoveryCapitalFormConsent />
          </FormSection>
        </Form>
      </Accordion>

      {/* Referral Details  */}
      <Accordion
        // activateEditMode={false}
        activateEditMode
        initialModel={client}
        title="Referrals Details"
        glyph="referral_details"
        permission="clients.general_info.referral_details.view"
        editPermission="clients.general_info.referral_details.edit"
        newFeatureFlag="referral_tracking"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form isEditable={false} key={`referral_details-${client?.updated_at}`}>
          <FormSection labelWidth="170px">
            <ObjectSelector
              label="Referral In"
              model="referral_source"
              type="organizations"
              selectTitle={(data) => data.name}
              selectDescription={(data) => data.category}
            />

            <ObjectSelector
              model="referral_in_contact"
              label="Referral In Contact"
              type="organization.contacts"
              dependent="referral_source_id"
              selectTitle={(data) => data.name}
              selectDescription={(data) => data.role}
            />

            <MiniRichTextEditor useQuickText label="Referral In Notes" model="referral_notes" />

            <ObjectSelector
              label="Referral Out"
              model="referral_out"
              type="organizations"
              selectTitle={(data) => data.name}
              selectDescription={(data) => data.category}
            />

            <ObjectSelector
              model="referral_out_contact"
              label="Referral Out Contact"
              type="organization.contacts"
              dependent="referral_out_id"
              selectTitle={(data) => data.name}
              selectDescription={(data) => data.role}
            />

            <MiniRichTextEditor useQuickText label="Referral Out Notes" model="referral_out_notes" />
          </FormSection>
        </Form>
      </Accordion>

      {/* Sensitive Information */}
      <Accordion
        activateEditMode
        initialModel={client}
        title="Sensitive Information"
        glyph="sensitive_information"
        permission="clients.general_info.sensitive_data.view"
        editPermission="clients.general_info.sensitive_data.edit"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form key={`sensitive_information-${client?.updated_at}`}>
          <FormSection>
            <Input isNested label="Social Security Number" model="encrypted_data.ssn" />
            <Input isNested label="Driver License Number" model="encrypted_data.driver_license" />
          </FormSection>
        </Form>
      </Accordion>

      {/* Employment */}
      <Accordion
        skipValidCheck
        activateEditMode
        initialModel={client}
        title="Employment"
        glyph="documents"
        permission="clients.general_info.employment.view"
        editPermission="clients.general_info.employment.edit"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form key={`employment-${client?.updated_at}`}>
          <FormSection>
            <Select allowEmpty label="Employment Status" model="employment_status">
              <Option label="Employed Full-Time" value="employed_full_time" />
              <Option label="Employed Part-Time" value="employed_part_time" />
              <Option label="Unemployed" value="unemployed" />
              <Option label="Not Employment Ready" value="not_employment_ready" />
              <Option label="Student" value="student" />
              <Option label="Retired" value="retired" />
              <Option label="Unable to Work" value="unable_to_work" />
            </Select>
          </FormSection>
        </Form>
      </Accordion>

      {/* Education */}
      <Accordion
        skipValidCheck
        activateEditMode
        initialModel={client}
        title="Education"
        glyph="documents"
        permission="clients.general_info.education.view"
        editPermission="clients.general_info.education.edit"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form key={`education-${client?.updated_at}`}>
          <FormSection>
            <Select allowEmpty label="Highest Level of Education" model="highest_level_of_education">
              <Option label="Elementary or High school, no diploma" value="elementary_or_high_school_no_diploma" />
              <Option label="Elementary or High school, GED" value="elementary_or_high_school_ged" />
              <Option label="7th Grade" value="seventh_grade" />
              <Option label="8th Grade" value="eighth_grade" />
              <Option label="9th Grade" value="ninth_grade" />
              <Option label="10th Grade" value="tenth_grade" />
              <Option label="11th Grade" value="eleventh_grade" />
              <Option label="12th Grade (no diploma)" value="twelfth_grade" />
              <Option label="High school diploma" value="high_school_diploma" />
              <Option label="College degree" value="college" />
              <Option label="College, no degree" value="college_no_degree" />
              <Option label="Undergraduate/Graduate Degrees" value="undergraduate_graduate_degrees" />
              <Option label="Associate's degree, vocational" value="associates_degree_vocational" />
              <Option label="Associate's degree, academic" value="associates_degree_academic" />
              <Option label="Bachelor's degree" value="bachelors_degree" />
              <Option label="Master's degree" value="masters_degree" />
              <Option label="Professional degree" value="professional_degree" />
              <Option label="Doctorate degree" value="doctorate_degree" />
              <Option label="Other" value="other" />
            </Select>

            <ContextShow when="highest_level_of_education" is="other">
              <Input label="Please add your highest level of education" model="highest_level_of_education_other" />
            </ContextShow>
          </FormSection>
        </Form>
      </Accordion>

      {/* Courts & Criminal Justice */}
      <Accordion
        skipValidCheck
        activateEditMode
        initialModel={client}
        title="Courts & Criminal Justice"
        glyph="legal_history"
        permission="clients.general_info.courts_criminal_justice.view"
        editPermission="clients.general_info.courts_criminal_justice.edit"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form key={`legal_history-${client?.updated_at}`}>
          <FormSection>
            <YesNoRadioGroupWithTextarea
              label="Charged/Convicted of Sexual/Violent Crimes"
              model="charged_convicted_of_crimes"
              textareaLabel="Details"
            />

            <Select allowEmpty label="Probation Status" model="probation_status">
              <Option label="Not on Probation" value="not_on_probation" />
              <Option label="Pending Probation" value="pending_probation" />
              <Option label="On Probation" value="on_probation" />
              <Option label="Completed Probation" value="completed_probation" />
              <Option label="Violation" value="violation" />
              <Option label="Revoked" value="revoked" />
              <Option label="Under Review" value="under_review" />
              <Option label="Extended Probation" value="extended_probation" />
            </Select>

            <ContextShow
              when="probation_status"
              within={[
                'pending_probation',
                'on_probation',
                'completed_probation',
                'violation',
                'revoked',
                'under_review',
                'extended_probation',
              ]}
            >
              <Textarea label="Probation Details" model="probation_status_details" />
            </ContextShow>

            <Select allowEmpty label="Parole Status" model="parole_status">
              <Option label="Not on Parole" value="not_on_parole" />
              <Option label="Pending Parole Hearing" value="pending_parole_hearing" />
              <Option label="Granted Parole (Not Released)" value="granted_parole_not_released" />
              <Option label="On Parole" value="on_parole" />
              <Option label="Violation" value="violation" />
              <Option label="Revoked" value="revoked" />
              <Option label="Under Review" value="under_review" />
              <Option label="Extended Parole" value="extended_parole" />
              <Option label="Completed Parole" value="completed_parole" />
            </Select>

            <ContextShow
              when="parole_status"
              within={[
                'pending_parole_hearing',
                'granted_parole_not_released',
                'on_parole',
                'violation',
                'revoked',
                'under_review',
                'extended_parole',
                'completed_parole',
              ]}
            >
              <Textarea label="Parole Details" model="parole_status_details" />
            </ContextShow>

            <Textarea label="Self Reported Legal Issues & Related Information" model="self_reported_legal_issues" />
          </FormSection>
        </Form>
      </Accordion>

      {/* Accounting */}
      <Accordion
        activateEditMode
        initialModel={client}
        title="Accounting HIPAA Compliance"
        featureFlagV2="accounting"
        glyph="financial_information"
        permission="clients.general_info.accounting_hipaa_compliance.view"
        editPermission="clients.general_info.accounting_hipaa_compliance.edit"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form key={`financial_information-${client?.updated_at}`}>
          <FormSection>
            <Alert glyph="info" type="neutral">
              <div>
                Visit <Link to={'/settings/accounting'}>https://app.behavehealth.com/settings/accounting</Link> to decide what information
                is sent to accounting system.
              </div>
            </Alert>

            <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
              <Checkbox label="Sync with Accounting Platform" description="" model="sync_with_accounting" />
            </CheckboxGroup>
          </FormSection>
        </Form>
      </Accordion>

      {/* Home Address */}
      <Accordion
        activateEditMode
        initialModel={client}
        title="Home Address"
        glyph="home_address"
        permission="clients.general_info.home_address.view"
        editPermission="clients.general_info.home_address.edit"
        onSubmit={async (form: any) => {
          await updateClient({
            current_living_situation: form.current_living_situation,
            homelessness_length: form.homelessness_length,
            encrypted_data_attributes: {
              address: form.address,
            },
            lat: form.lat,
            lon: form.lon,
          })
        }}
      >
        <Form key={`home_address-${client?.updated_at}`}>
          <FormSection labelWidth="150px">
            <RadioGroup label="Current Living Situation" model="current_living_situation" layout="vertical-dense">
              <Radio label="Living By Themselves" value="alone" />
              <Radio label="Living With Family" value="family" />
              <Radio label="Living With Roommate(s)" value="roommates" />
              <Radio label="Living At A Program, Facility, or Institution " value="program_facility_or_institution" />
              <Radio label="Currently Experiencing Homelessness" value="homelessness" />
            </RadioGroup>

            <ContextShow when="current_living_situation" is="homelessness">
              <RadioGroup label="Homeless Time Period" model="homelessness_length" layout="vertical-dense">
                <Radio label="1 to 7 days" value="1_to_7_days" />
                <Radio label="2 to 3 weeks" value="2_to_3_weeks" />
                <Radio label="1 to 6 months" value="1_to_6_months" />
                <Radio label="More than 6 months" value="more_than_6_months" />
              </RadioGroup>
            </ContextShow>

            <ContextHide when="current_living_situation" is="homelessness">
              <AddressSelectorInput actionLabel="Update Home Address" model="address" value={client?.encrypted_data?.address} />
            </ContextHide>
          </FormSection>
        </Form>
      </Accordion>

      {/* Vehicle Information */}
      <Accordion
        initialModel={client}
        title="Vehicle Information"
        glyph="car"
        permission="clients.general_info.vehicle_information.view"
        editPermission="clients.general_info.vehicle_information.edit"
        activateEditMode
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form key={`vehicle_information-${client?.updated_at}`}>
          <FormSection>
            <Input label="Make" model="vehicle.make" />
            <Input label="Model" model="vehicle.model" />
            <Input label="Color" model="vehicle.color" />
            <Input label="License Plate Number" model="vehicle.license_plate_number" />
          </FormSection>
        </Form>
      </Accordion>

      {/* Additional Information */}
      <Accordion
        activateEditMode
        initialModel={client}
        title="Additional Information"
        glyph="additional_information"
        permission="clients.general_info.other_information.view"
        editPermission="clients.general_info.other_information.edit"
        onSubmit={updateClient}
        timezone={timezone}
      >
        <Form key={`additional_information-${client?.updated_at}`}>
          <FormSection layout="vertical">
            <Textarea useQuickText isNested model="encrypted_data.notes" label="Additional Info" minRows={4} maxWidth="100%" />
          </FormSection>
        </Form>
      </Accordion>

      {/* Personal History */}
      {/* <Accordion
        data={data}
        title="Personal History"
        icon={ICONS.properties}
        permission
        activateEditMode
        onSubmit={(data) => apiUpdate({ name: 'clients', url: `/residents/${record.id}`, params: data })}>
        <Form>
          <FormSection>
            <RadioGroup
              label="Veteran"
              model="veteran"
              layout="horizontal">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <RadioGroup
              label="Has Recently Experienced Homelessness"
              model="has_recently_experienced_homelessness"
              layout="horizontal">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <Textarea label="Homelessness Details" />
          </FormSection>
        </Form>
      </Accordion> */}
    </Page>
  )
}

export default withPageError(GeneralInfo)
