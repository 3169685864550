import React from 'react'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { StaffAllowedIPsOverlay } from '@behavehealth/constructs/StaffIPRestrictions/StaffAllowedIPsOverlay'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { NavGroup, NavItem, Page, PageGrid, Nav, Status, HelpTagIframe } from '@behavehealth/components'

import AllowedIPs from './allowed_ips'

const UsingIP = () => <Status color="green" label="IP Restrictions Active" />
const NotUsingIP = () => <Status color="gray" label="No IP Restrictions" />

const pageConfig = {
  feature: 'staff_ip_restrictions',
  title: 'Staff IP Restrictions',
  help: <HelpTagIframe id="settings_staff_ip_restrictions" />,
}

const StaffIPRestrictions: React.FC = () => {
  const { data, isLoading }: any = useGet({
    name: ['employees', 'for_allowed_ips'],
    url: `/employees/for_allowed_ips`,
  })

  const isEmpty = size(data) === 0

  if (!data) {
    return (
      <Page feature="employees" title="Staff" isEmpty={isEmpty} isLoading={isLoading} emptyDescription="There are no Staff to display" />
    )
  }

  return (
    <PageGrid pinMobileHeader breakpoint={5} columns="280px 1fr">
      <Nav
        icon="employees"
        breakpoint={5}
        title="Staff"
        headingSize={300}
        menuLabel="All Staff"
        menuGlyph="user_group"
        help={<HelpTagIframe id="settings_staff_ip_restrictions_menu" />}
      >
        <NavGroup>
          {data.map((employee: any) => {
            let Component = null

            if (employee.prefs?.use_ip_whitelist) {
              Component = UsingIP
            } else {
              Component = NotUsingIP
            }

            return (
              <NavItem as={NavLink} showAvatarInitials label={employee.name} to={employee.id} color="#7e83a9" description={<Component />} />
            )
          })}
        </NavGroup>
      </Nav>

      <Routes>
        <Route index element={<Navigate to={data[0]?.id} replace />} />
        <Route path=":id/*" element={<AllowedIPs />} />
      </Routes>

      <AnimatedRoutes>
        <Route path=":resource_id/:id" element={<StaffAllowedIPsOverlay useV6Router />} />
      </AnimatedRoutes>
    </PageGrid>
  )
}

export default withPageError(withMarketing(StaffIPRestrictions, pageConfig))
