import React from 'react'
import { BaseEditorComponent } from '@handsontable/react'
import { createRef, MouseEvent, RefObject } from 'react'
import { tint } from 'polished'
import Handsontable from 'handsontable'
import size from 'lodash/size'

import { COLORS } from '../../../theme'

import Avatar from '../../Avatar'
import Button from '../../Button'
import Card from '../../Card'
import Glyph from '../../Glyph'
import Loader from '../../Loader'
import Overlay from '../../Overlay'
import Flex from '../../Flex'
import State from '../../State'
import { DropdownMenu, DropdownMenuItem } from '../../DropdownMenu'

import { SpreadsheetDropdown } from '../common/SpreadsheetDropdown'
import { useDataTable } from '../../DataTable/useDataTable'
import clsx from 'clsx'

type ObjectSelectorRendererProps = {
  TD?: HTMLTableCellElement
  value?: string
  row?: number
  col?: number
  cellProperties?: Handsontable.CellProperties
  config?: any
  isEditable?: boolean
  readOnly?: boolean
}

export const ObjectSelectorRenderer = (props: ObjectSelectorRendererProps) => {
  const { value, readOnly, isEditable, TD, config = {} } = props
  const { selectTitle, showAvatars } = config

  if (TD) {
    readOnly ? TD.classList.add('htDimmed') : TD.classList.remove('htDimmed')
  }

  const parsed = React.useMemo(() => {
    if (!value) return null

    try {
      return JSON.parse(value)
    } catch (error) {
      return null
    }
  }, [value])

  return (
    <div className="flex items-center flex-nowrap h-[28px]">
      {parsed?.id && (
        <>
          {showAvatars && <Avatar initials={parsed.name} src={parsed.avatar} size={20} className="mr-1.5" />}
          <div>
            <div>{selectTitle ? selectTitle(parsed) : parsed?.name}</div>
          </div>
        </>
      )}

      {isEditable && <Glyph glyph="triangle_down" size={10} color={COLORS.blue} className="ml-auto" />}
    </div>
  )
}

export class ObjectSelectorEditor extends BaseEditorComponent {
  mainElementRef: RefObject<HTMLDivElement>

  constructor(props: BaseEditorComponent['props']) {
    super(props)

    this.mainElementRef = createRef()
    this.state = {
      isOpen: false,
      value: '',
      record: null,
    }
  }

  setValue(value: any, callback: (() => void) | undefined) {
    this.setState((_state, _props) => {
      return { value }
    }, callback)
  }

  getValue() {
    return this.state.value
  }

  open() {
    this.setState({ isOpen: true })

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'block'
  }

  close() {
    this.setState({ isOpen: false })

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'none'
  }

  prepare(
    row: number,
    col: number,
    prop: string,
    td: HTMLTableColElement,
    originalValue: string,
    cellProperties: Handsontable.CellProperties,
  ) {
    super.prepare(row, col, prop, td, originalValue, cellProperties)

    const tdPosition = td.getBoundingClientRect()

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.left = `${tdPosition.left + window.pageXOffset}px`
    this.mainElementRef.current.style.top = `${tdPosition.top + window.pageYOffset}px`
  }

  stopMousedownPropagation(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
  }

  render() {
    const { isOpen } = this.state

    return (
      <div>
        {this.props?.config?.tableSelector ? (
          <SelectorOverlay
            isOpen={isOpen}
            table={this.props.config.tableSelector}
            onMouseDown={this.stopMousedownPropagation}
            onSelect={(item) => {
              const current = this.state.value ? JSON.parse(this.state.value) : null

              if (current && current.id === item.id) {
                return this.close()
              }

              this.setState(
                (state, props) => {
                  return { value: JSON.stringify(item) }
                },
                () => {
                  this.finishEditing()
                },
              )

              this.close()
            }}
            onClose={() => {
              this.close()
            }}
          />
        ) : (
          <SpreadsheetDropdown ref={this.mainElementRef} onMouseDown={this.stopMousedownPropagation}>
            <SelectorMenu
              {...this.props}
              value={this.state.value}
              onSelect={(item) => {
                const current = this.state.value ? JSON.parse(this.state.value) : null

                if (current && current.id === item.id) {
                  return this.close()
                }

                this.setState(
                  (state, props) => {
                    return { value: JSON.stringify(item) }
                  },
                  () => {
                    this.finishEditing()
                  },
                )

                this.close()
              }}
            />
          </SpreadsheetDropdown>
        )}
      </div>
    )
  }
}

const SelectorMenu = (props: any) => {
  const { onSelect, config = {} } = props
  const { selectTitle, selectDescription, showAvatars } = config

  const parsedValue = React.useMemo(() => {
    if (!props.value) return null

    try {
      return JSON.parse(props.value)
    } catch (error) {
      console.error(error)
      return null
    }
  }, [props.value])

  const tableProps = useDataTable({
    name: ['spreadsheet-menu', props.model],
    endpoint: props.config?.endpoint,
    params: props.config?.params,
    enabled: !!props.config?.endpoint,
  })

  const { meta, currentPage, onCurrentPageChange: setCurrentPage } = tableProps
  const pagesArray = Array.from({ length: meta?.pages || 0 }, (_, i) => i + 1)

  return (
    <div className="relative">
      {tableProps.isLoading ? (
        <div className="py-12 px-4 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          {tableProps.isRefetching && <Loader className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />}

          {!tableProps.isRefetching && !tableProps.isLoading && size(tableProps.data) === 0 && (
            <State isEmpty glyph="search" emptyDescription="There are no records to display" minHeight={100} />
          )}

          {tableProps.data?.map?.((item) => {
            const isActive = parsedValue && item?.id === parsedValue?.id

            return (
              <button
                key={item.id}
                css={STYLES.menuItem}
                onClick={() => {
                  onSelect?.(item)
                }}
                className={clsx('text-left', tableProps.isRefetching && 'opacity-0 pointer-events-none', isActive && 'is-active')}
              >
                {showAvatars && <Avatar initials={item.name} src={item.avatar} size={20} className="mr-1.5" />}
                <div>
                  <div>{selectTitle ? selectTitle(item) : item?.name}</div>
                  {selectDescription && <div className="text-[0.85rem] text-text-muted font-[400]">{selectDescription(item)}</div>}
                </div>
                <div className="inline-flex ml-auto items-center justify-center">
                  {isActive && <Glyph glyph="tick_circle" size={16} color="green" />}
                </div>
              </button>
            )
          })}

          {meta && size(pagesArray) > 1 && size(tableProps.data) > 0 && (
            <div className={clsx('sticky bottom-[-3px] w-full shadow border-t border-0 border-solid border-divider bg-white z-[1]')}>
              <Flex centerY justifyContent="space-between" gap="0.5rem">
                <Button
                  label="← Back"
                  isDisabled={!meta?.prev}
                  display="inline-flex"
                  type="minimal"
                  size={200}
                  onClick={() => {
                    if (meta?.prev) setCurrentPage?.(meta?.prev)
                  }}
                />

                {meta?.pages >= 2 ? (
                  <DropdownMenu
                    trigger={
                      <div css={STYLES.dropdown}>
                        <div>
                          <span className="!font-[600]">Page {meta?.page}</span> of{' '}
                          <span className="!font-[400] !text-text-muted">{meta?.pages}</span>
                        </div>
                        <Glyph glyph="triangle_down" size={12} />
                      </div>
                    }
                  >
                    {pagesArray.map((pageNumber) => (
                      <DropdownMenuItem
                        key={pageNumber}
                        isActive={pageNumber === meta?.page}
                        label={`Page ${pageNumber}`}
                        onClick={() => setCurrentPage?.(pageNumber)}
                      />
                    ))}
                  </DropdownMenu>
                ) : (
                  <div>
                    <span className="!font-[600]">Page {meta?.page}</span> of{' '}
                    <span className="!font-[400] !text-text-muted">{meta?.pages}</span>
                  </div>
                )}

                <Button
                  label="Next →"
                  isDisabled={!meta?.next}
                  display="inline-flex"
                  type="minimal"
                  size={200}
                  onClick={() => {
                    if (meta?.next) setCurrentPage?.(meta?.next)
                  }}
                />
              </Flex>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const SelectorOverlay = ({ table, isOpen, onSelect, onClose, onClear, title, icon, onMouseDown }: any) => {
  const [selectedRows, setSelectedRows] = React.useState([])

  const handleApplyOne = (selectedRow: any) => {
    if (!selectedRow) return

    onSelect?.(selectedRow)
    onClose()
  }

  if (!isOpen) return null

  const Table = table

  return (
    <div onMouseDown={onMouseDown}>
      <Overlay animate showBackdrop stopPropagation position="center" maxWidth={100} onClose={onClose}>
        <Overlay.Header title={`Select ${title || 'records…'}`} icon={icon} />

        <Overlay.Content>
          <Card className="m-4">{Table && <Table canBatchSelect={false} onSelect={handleApplyOne} />}</Card>
        </Overlay.Content>
      </Overlay>
    </div>
  )
}

const STYLES = {
  menuItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '28px',
    padding: '0.1rem 0.6rem',
    fontSize: '0.9rem',
    fontWeight: 600,
    color: COLORS.text,
    border: 'none',
    background: 'transparent',
    borderRadius: 5,

    'svg, img': {
      marginRight: '0.4rem',
    },

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      background: tint(0.9, COLORS.green),
    },

    '& > *': {
      pointerEvents: 'none',
    },
  },

  dropdown: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& > svg': { marginLeft: '0.4rem' },
  },
}
