import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { invalidateQueries } from '../../hooks/useNewAPI'
import { titleCase } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import Notifications from '../../modules/notifications'

import AddendumCard from '../../components/AddendumCard'
import AddendumOverlay from '../Overlays/AddendumOverlay'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Chotomate from '../../components/Chotomate'
import ClaimPlaceOfServiceSelect from '../RCM/components/ClaimPlaceOfServiceSelect'
import ClinicalNoteStatus from '../../components/Statuses/ClinicalNoteStatus'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import EventAttendanceAbsentStatusSelect from '../../components/Elements/EventAttendanceAbsentStatusSelect'
import EventAttendancePresentStatusSelect from '../../components/Elements/EventAttendancePresentStatusSelect'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Select from '../../components/Forms/Select'
import Option from '../../components/Forms/Option'
import Section from '../../components/Section'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SignaturePad from '../../components/Forms/SignaturePad'
import SummonOverlay from '../../components/SummonOverlay'
import Switch from '../../components/Forms/Switch'
import TextareaDialog from '../../components/Dialogs/TextareaDialog'
import Timeline from '../../components/Timeline/Timeline'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'
import { InlineInsuranceBillingFormSection } from '../RCM/components/InlineInsuranceBillingFormSection'
import { TreatmentPlanSelector } from '../../components/Forms/TreatmentPlanSelector'
import { NoteSignees } from '../NotesShared/NoteSignees'
import { InlineInsurancePoliciesDataTable } from '../Insurance/InlineInsurancePoliciesDataTable'
import { DiagnosesSelector } from '../Diagnoses/DiagnosesSelector'

export const RootClinicalNoteIndividualOverlay = (props: any) => {
  const { position = 'right' } = props

  const {
    cancel,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    invalidateKeys,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    isUpdating,
    onValidationUpdate,
    queryKey,
    saveWithData,
    updateAsync,
  } = useOverlay({
    name: 'clinical-notes',
    endpoint: '/clinical_notes',
    invalidate: 'clinical-notes',
    invalidateKeys: props.invalidateKeys,
    options: props,
    onDeleteSuccessful: props.onDeleteSuccessful,
    onSaveSuccessful: props.onSaveSuccessful,
    onAfterClose: (args) => {
      // open the newly created record
      if (!args.isNew || !args?.data?.id || !!props?.onClose) return

      if (args?.location?.pathname?.endsWith('/i/new')) {
        args.navigate(args?.location?.pathname.replace('/i/new', `/i/${args.data.id}`))
      }
    },
  })

  const location = useLocation()
  const history = useHistory()

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    const url = location?.parent?.url || location.pathname.substring(0, location.pathname.lastIndexOf('/i/'))

    history.push(url)
  }

  const { isPortal, isBehave, isOwner, isManagement, tenant, timezone, user, defaultSupervisor, systemPrefs }: any = useSettings()

  const isRequiredSupervisorNonEditable = systemPrefs?.require_clinical_notes_supervisor

  const [clientId, setClientId] = React.useState<string | null>(null)
  const [supervisor, setSupervisor] = React.useState<any>(null)
  const [requireSupervisor, setRequireSupervisor] = React.useState(true)

  const userNoteFormat = user?.preferred_clinical_note_format
  const tenantNoteFormat = tenant?.preferred_clinical_note_format
  const defaultFormat = userNoteFormat || tenantNoteFormat || 'dap'

  const { mutateAsync: closeClinicalNote, isLoading: isClosingClinicalNote } = useCreate({
    name: ['clinical_notes', id],
    url: `/clinical_notes/${id}/close`,
    invalidate: 'clinical-notes',
  })

  const { mutateAsync: signOffClinicalNote, isLoading: isSigningOffClinicalNote } = useCreate({
    name: ['clinical_notes', id],
    url: `/clinical_notes/${id}/sign_off`,
    invalidate: 'clinical-notes',
  })

  React.useEffect(() => {
    if (!!data) setRequireSupervisor(data.require_supervisor)
  }, [data?.require_supervisor])

  if (isOverlayLoading) {
    return <OverlayLoader position={position} />
  }

  const isSupervisor = initialModel?.supervisor?.id === user.id
  const isTrialing = tenant?.plan_status === 'trialing'

  const save = async () => {
    const formData = form.current.getFormValue()
    const finalForm = {
      ...formData,
      ...(!formData.is_billable && { insurance_new_fee_schedule_id: null, insurance_new_fee_schedule_service_id: null }),
    }

    if (props.save) {
      await props.save(finalForm)
      props.onClose?.()
    } else {
      await saveWithData(finalForm)
    }
  }

  const signOff = async (signature: any) => {
    try {
      await signOffClinicalNote({
        [signature.model]: signature.value,
      })
    } catch (error) {
      console.error(error)
    }

    props.onSaveSuccessful?.()
    cancel()
  }

  const closeOff = async () => {
    try {
      await closeClinicalNote({})

      Notifications.send('Successfully Closed Note', 'positive')

      close()
    } catch (error) {
      console.error(error)
    }
  }

  const sendForReview = async () => {
    updateAsync({ status: 'pending_review' })

    props.onSaveSuccessful?.()
    cancel()
  }

  const requestUpdates = async (updates) => {
    updateAsync({ status: 'updates_required', rejected_reason: updates.value })

    props.onSaveSuccessful?.()
    cancel()
  }

  return (
    <Overlay
      maxWidth={45}
      isDirty={isEditable}
      onClose={close}
      position={position}
      showBackdrop={isNew || isEditable || props.showBackdrop}
    >
      <Overlay.Header
        icon="clinical_notes"
        title="Individual Note"
        subtitle={<ClinicalNoteStatus status={initialModel?.status} />}
        help={<HelpTagIframe id="clinical_notes_individual" />}
      />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/clinical_notes/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Chotomate ready name="individual_note_overlay" />

        <Form
          getForm={form}
          initialModel={{
            ...initialModel,
            ...(isNew && defaultSupervisor && { supervisor: defaultSupervisor }),
          }}
          timezone={timezone}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && { category: 'individual' }}
          key={`updated-${data?.updated_at}`}
        >
          {initialModel?.status === 'updates_required' && (
            <Section>
              <Alert type="negative" glyph="note">
                <MiniRichTextEditor useDictation useQuickText fullwidth label="Updates Requested before Sign-Off" model="rejected_reason" />
              </Alert>
            </Section>
          )}

          <Section headingType="h2" title="Session Details">
            <FormSection maxWidth="100%" layout="vertical">
              {!isNew && (
                <Flex stretchChildrenX gap="1rem">
                  <Select label="Status" model="status">
                    <Option value="draft" label="Draft" />
                    <Option value="to_complete" label="To Complete" />
                    <Option value="rejected" label="Rejected" />
                    <Option value="pending_review" label="Pending Review" />
                    <Option value="updates_required" label="Updates Required" />
                    <Option value="pre_approved" label="Pre-Approved" />
                    <Option value="signed_off" label="Signed Off" />
                    <Option value="closed" label="Closed" />
                  </Select>

                  <ObjectSelector isEditable={false} model="author" label="Author" type="employees" icon="employees" />
                </Flex>
              )}

              <Flex gap="1rem">
                <DateTimeInput
                  defaultToNow
                  className="flex-[1_1_auto]"
                  model="started_at"
                  label="Session Start Date and Time"
                  validations={{
                    presence: {
                      message: 'Please enter a session date and time',
                    },
                  }}
                />

                <Input
                  model="duration"
                  label="Duration"
                  suffix="mins"
                  type="number"
                  min={1}
                  size={4}
                  validations={{
                    presence: {
                      message: 'Please enter a session duration',
                    },
                    numericality: {
                      greaterThanOrEqualTo: 1,
                      message: 'The duration must be higher than 0 minutes',
                    },
                  }}
                />
              </Flex>

              <FormFutureDateWarning dateLabel="Session Start Date and Time" model="started_at" />

              {!isNew && !isEditable && <DateTimeInput label="Session End Date and Time" model="ended_at" isEditable={false} />}

              <Flex gap="1rem" justifyContent="space-between" alignItems="flex-end">
                <ObjectSelector
                  isPolymorphic
                  css={isEditable && { flex: '1 1 auto' }}
                  model="supervisor"
                  label="Supervisor"
                  type="clinical_supervisors"
                  icon="employees"
                  selectTitle={(data) => data?.name}
                  selectDescription={(data) => titleCase(data?.position)}
                  validations={
                    requireSupervisor && {
                      presence: {
                        message: 'Please select a supervisor',
                      },
                    }
                  }
                  onUpdate={({ object }) => {
                    setSupervisor(object)
                  }}
                  value={isEditable ? supervisor : data?.supervisor}
                />

                <CheckboxGroup label="Supervisor" tooltip={isRequiredSupervisorNonEditable ? 'Required from Settings' : null}>
                  <Checkbox
                    isEditable={isRequiredSupervisorNonEditable ? false : isEditable}
                    defaultChecked
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="none"
                    label="Require Supervisor"
                    model="require_supervisor"
                    onChange={(state: any) => {
                      const nextValue = state.value
                      setRequireSupervisor(nextValue)

                      if (nextValue === false) {
                        setSupervisor(null)
                      }
                    }}
                  />
                </CheckboxGroup>
              </Flex>

              <MultiOverlaySelector
                label="Additional Staff Members"
                blankLabel="Select Staff…"
                icon="employees"
                type="employees.active"
                model="employees"
                selectTitle={(data) => data.name}
                selectDescription={(data) => titleCase(data.position)}
              />

              {!isNew && <DateTimeInput isEditable={false} model="supervisor_signed_at" label="Signed-off At" />}
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Session Notes">
            <FormSection maxWidth="100%">
              <OverlaySelector
                isEditable={isNew}
                model="client"
                type="clients.current"
                label="Client"
                icon="clients"
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
                onUpdate={({ object }) => {
                  setClientId(object?.id || null)
                }}
              />

              <Permission featureFlagV2="diagnoses">
                <DiagnosesSelector isEditable={isEditable} clientId={clientId} />
              </Permission>

              <TreatmentPlanSelector clientId={clientId} />

              <Flex gap="1rem">
                <RadioGroup
                  label="Presence"
                  defaultValue={true}
                  model="is_attending"
                  layout="horizontal-dense"
                  trueIcon="check"
                  falseIcon="cross"
                  validations={{
                    presence: {
                      message: 'Please set presence',
                    },
                  }}
                >
                  <Radio label="Present" value={true} />
                  <Radio label="Absent" value={false} />
                </RadioGroup>

                <ContextShow when="is_attending" is={true}>
                  <div className="!flex-auto">
                    <EventAttendancePresentStatusSelect label="Present Status" model="present_status" />
                  </div>
                </ContextShow>

                <ContextShow when="is_attending" is={false}>
                  <div className="!flex-auto">
                    <EventAttendanceAbsentStatusSelect label="Absent Status" model="absent_status" />
                  </div>
                </ContextShow>
              </Flex>

              <ContextShow when="is_attending" is={true}>
                <RadioGroup model="format" label="Note Type" layout="horizontal-dense" defaultValue={defaultFormat}>
                  <Radio label="DAP" value="dap" />
                  <Radio label="SOAP" value="soap" />
                  <Radio label="GIRP" value="girp" />
                  <Radio label="Individual Assessment" value="individual_assessment" />
                </RadioGroup>

                <ContextShow when="format" is="individual_assessment">
                  <MiniRichTextEditor useDictation useQuickText model="assessment" label="Individual Assessment" />
                </ContextShow>

                <ContextShow when="format" is="dap">
                  <MiniRichTextEditor useDictation useQuickText model="data" label="Data" />
                  <MiniRichTextEditor useDictation useQuickText model="assessment" label="Assessment" />
                  <MiniRichTextEditor useDictation useQuickText model="plan" label="Plan" />
                </ContextShow>

                <ContextShow when="format" is="soap">
                  <MiniRichTextEditor useDictation useQuickText model="subjective" label="Subjective" />
                  <MiniRichTextEditor useDictation useQuickText model="objective" label="Objective" />
                  <MiniRichTextEditor useDictation useQuickText model="assessment" label="Assessment" />
                  <MiniRichTextEditor useDictation useQuickText model="plan" label="Plan" />
                </ContextShow>

                <ContextShow when="format" is="girp">
                  <MiniRichTextEditor useDictation useQuickText model="goals" label="Goals" />
                  <MiniRichTextEditor useDictation useQuickText model="intervention" label="Intervention" />
                  <MiniRichTextEditor useDictation useQuickText model="response" label="Response" />
                  <MiniRichTextEditor useDictation useQuickText model="plan" label="Plan" />
                </ContextShow>
              </ContextShow>

              <ContextShow when="is_attending" is={false}>
                <MiniRichTextEditor
                  useDictation
                  useQuickText
                  testKeyQuickTextSelector="absentee_note_quick_text_selector"
                  model="absentee_note"
                  label="Absentee Note"
                />
              </ContextShow>

              <MiniRichTextEditor useDictation useQuickText model="notes" label="Notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section
            headingType="h2"
            title="Insurance"
            aside={<Switch horizontal model="use_insurance" />}
            description="Activate this section to add any Insurance related data"
          >
            <ContextShow when="use_insurance" is={true}>
              <FormSection maxWidth="100%">
                <InlineInsurancePoliciesDataTable clientId={clientId} />

                <CheckboxGroup label="Insurance" trueIcon="check" falseIcon="cross" falseStyle="faded">
                  <Checkbox defaultValue={false} label="Set as Billable" model="is_billable" />
                </CheckboxGroup>

                <ContextShow when="is_billable" is={true}>
                  <InlineInsuranceBillingFormSection isBillable />
                </ContextShow>

                <ClaimPlaceOfServiceSelect model="place_of_service" />

                <OverlaySelector maxWidth="100%" label="Location" type="properties.all" model="location" icon="gps_check_in_out" />
              </FormSection>
            </ContextShow>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this note">
            <FormSection maxWidth="100%" layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {!isPortal && (
            <>
              <Divider />

              <Section headingType="h2" title="Signatures">
                <FormSection maxWidth="100%" layout="vertical">
                  <SignaturePad
                    allowPin
                    person={data?.signed_by}
                    label="Author Signature"
                    model="employee_signature"
                    signedAtModel="author_signed_at"
                    showAccept={false}
                  />

                  <SignaturePad
                    allowPin
                    isEditable={false}
                    person={data?.supervisor}
                    label="Supervisor Signature"
                    model="supervisor_signature"
                    signedAtModel="supervisor_signed_at"
                    showAccept={false}
                  />

                  <NoteSignees
                    isEditingRecord={isEditable}
                    signees={data?.clinical_note_signees}
                    name="clinical_note_signees"
                    invalidate="clinical-notes"
                  />
                </FormSection>
              </Section>
            </>
          )}

          {(data?.status === 'signed_off' || data?.status === 'closed') && (
            <>
              <Divider />

              <Section headingType="h2" title="Addendums">
                <Grid gap="1rem">
                  {data?.addendums?.map((addendum: any) => {
                    return (
                      <AddendumCard
                        key={addendum.id}
                        data={addendum}
                        onUpdate={() => {
                          invalidateQueries(queryKey, invalidateKeys)
                        }}
                      />
                    )
                  })}
                </Grid>

                <SummonOverlay
                  overlay={
                    <AddendumOverlay
                      reference={data}
                      onUpdate={() => {
                        invalidateQueries(queryKey, invalidateKeys)
                      }}
                    />
                  }
                >
                  <Button size={200} label="Add New Addendum" glyph="add" type="primary" display="inline-flex" className="!mt-4" />
                </SummonOverlay>
              </Section>
            </>
          )}

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={initialModel?.id} recordType={initialModel?.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {/* no more actions if signed off */}
      {!isBehave && !isManagement && (initialModel?.status === 'signed_off' || initialModel?.status === 'closed') ? null : (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving || props.isSaving}
                onClick={save}
                flex="100 1 auto"
                permission={isNew ? 'clinical_notes.create' : 'clinical_notes.edit'}
                isDisabled={!clientId}
              />

              {isInvalid && (
                <Button
                  color="orange"
                  type="default"
                  glyph="view"
                  label="Highlight Required Fields"
                  isLoading={isSaving}
                  className="application-form-button"
                  onClick={() => {
                    form.current?.highlightInvalid()
                  }}
                />
              )}

              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving || props.isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              {isManagement || isBehave ? (
                <Button
                  glyph="edit"
                  label="Edit Clinical Note"
                  type="default"
                  isDisabled={isLoading}
                  onClick={edit}
                  flex="100 1 auto"
                  permission="clinical_notes.edit"
                />
              ) : (
                initialModel?.status !== 'signed_off' &&
                initialModel?.status !== 'closed' && (
                  <Button
                    glyph="edit"
                    label="Edit Clinical Note"
                    type="default"
                    isDisabled={isLoading}
                    onClick={edit}
                    flex="100 1 auto"
                    permission="clinical_notes.edit"
                  />
                )
              )}

              <DeleteDialog
                title="Delete Clinical Note?"
                message="Are you sure you want to delete this clinical note? This action cannot be undone."
                onYes={deleteRecord}
                permission="clinical_notes.delete"
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="clinical_notes.delete"
                />
              </DeleteDialog>

              {requireSupervisor &&
                !isSupervisor &&
                initialModel?.status !== 'pending_review' &&
                initialModel?.status !== 'signed_off' &&
                initialModel?.status !== 'closed' && (
                  <Button
                    fullWidth
                    label="Send for Review"
                    type="primary"
                    color="green"
                    glyph="check"
                    isDisabled={isUpdating || isInvalid}
                    onClick={sendForReview}
                    flex="100 1 auto"
                    permission="clinical_notes.edit"
                  />
                )}

              {requireSupervisor && isSupervisor && initialModel?.status !== 'signed_off' && initialModel?.status !== 'closed' && (
                <>
                  {initialModel?.status === 'pending_review' && (
                    <TextareaDialog
                      model="rejected_reason"
                      title="What updates should be made?"
                      onYes={requestUpdates}
                      yesLabel="Ask For Updates"
                      permission="clinical_notes.edit"
                    >
                      <Button
                        fullWidth
                        label="Request Updates…"
                        glyph="note"
                        type="negative"
                        isDisabled={isUpdating}
                        permission="clinical_notes.edit"
                      />
                    </TextareaDialog>
                  )}

                  <SignatureDialog
                    model="supervisor"
                    title="Sign Off as Supervisor"
                    yesLabel="Apply Signature & Sign Off"
                    onYes={signOff}
                    permission="clinical_notes.edit"
                  >
                    <Button
                      fullWidth
                      label="Sign Off as Supervisor…"
                      glyph="signature"
                      type="primary"
                      color="green"
                      isDisabled={isUpdating || isInvalid}
                      permission="clinical_notes.edit"
                    />
                  </SignatureDialog>
                </>
              )}

              {!requireSupervisor && initialModel?.status !== 'signed_off' && initialModel?.status !== 'closed' && (
                <ConfirmDialog
                  title="Close Clinical Note?"
                  message="Are you sure you want to close the Clinical Note?"
                  yesColor="green"
                  onYes={closeOff}
                  permission="clinical_notes.edit"
                >
                  <Button
                    fullWidth
                    label="Close Clinical Note…"
                    glyph="close"
                    color="green"
                    type="primary"
                    isLoading={isClosingClinicalNote}
                    permission="clinical_notes.edit"
                  />
                </ConfirmDialog>
              )}
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const ClinicalNoteIndividualOverlay = withOverlayError(RootClinicalNoteIndividualOverlay)
