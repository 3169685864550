import React from 'react'
import { Settings } from 'luxon'
import sortBy from 'lodash/sortBy'

import { buildBaseUrl, setHeaders } from '@behavehealth/modules/axios'
import Analytics from '@behavehealth/modules/analytics'
import Version from '@behavehealth/modules/version'

import { DEMO_ACCOUNTS } from '../utils/constants'

import { apiGet } from '@behavehealth/modules/api'
import { URL, CONFIG } from '@behavehealth/utils/globals'

import useStore from '@behavehealth/modules/store'

const buildEmployeeRoles = (is_ehr) => {
  let roles = []

  roles.push({ value: 'owner', text: 'Owner' })
  roles.push({ value: 'administrator', text: 'Administrator' })
  roles.push({ value: 'house_manager', text: 'House Manager' })
  roles.push({ value: 'housing_director', text: 'Housing Director' })
  roles.push({ value: 'support_staff', text: 'Support Staff' })
  roles.push({ value: 'admissions_director', text: 'Admissions Director' })
  roles.push({ value: 'program_director', text: 'Program Director' })
  roles.push({ value: 'housing_supervisor', text: 'Housing Supervisor' })
  roles.push({ value: 'accounting', text: 'Accounting' })
  roles.push({ value: 'reentry_coordinator', text: 'Reentry Coordinator' })
  roles.push({ value: 'shift_coordinator', text: 'Shift Coordinator' })
  roles.push({ value: 'psychiatrist', text: 'Psychiatrist' })
  roles.push({ value: 'nurse', text: 'Nurse' })
  roles.push({ value: 'medical_technician', text: 'Medical Technician' })
  roles.push({ value: 'medical_director', text: 'Medical Director' })
  roles.push({ value: 'clinical_director', text: 'Clinical Director' })
  roles.push({ value: 'therapist', text: 'Clinical Therapist' })
  roles.push({ value: 'counselor', text: 'Counselor' })
  roles.push({
    value: 'behavioral_health_technician',
    text: 'Behavioral Health Technician',
  })

  CONFIG.active_employee_roles = roles
}

export const initialize = async () => {
  // build urls for requests
  await buildBaseUrl()

  // check version
  Version.checkForUpdate(process.env.BH_VERSION_NAME)

  return true
}

export const setup = async () => {
  // set token
  const tokenStep = await setHeaders()
  if (!tokenStep) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'logout' }))
    } else {
      return window.location.replace('/logout')
    }
  }

  // getMe and add it to the store
  const me = await apiGet({ url: '/me', reducer: 'me' })

  // if there is an error, logout
  if (window.ReactNativeWebView && me?.error?.data?.type === 'auth') {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'logout' }))
  }

  const update = useStore.getState().update
  update({
    user: me.data.user,
    tenant: me.data.tenant,
    blocks: sortBy(me.data.blocks || [], 'order'),
    ehrBlocks: sortBy(me.data.ehr_blocks || [], 'order'),
    isPopulatingSeeds: me.data.seed_add_status,
    isDeletingSeeds: me.data.seed_delete_status,
    newUser: me.data.new_user,
    newRecord: me.data.new_record,
    permissions: me.data.permissions,
    feature_flags: me.data.tenant.feature_flags,
    subscriptions: me.data.subscriptions,
    isBehave: me.data.user?.type === 'bh_employee',
    notifications: me.data.notifications,
    connectedEntities: me.data.connected_entities,
    formVariables: [...me.data?.form_variables?.account, ...me.data?.form_variables?.custom_account, ...me.data?.form_variables?.system],
    defaultSupervisor: me.data.default_supervisor,
  })

  URL.subdomain = me.data?.tenant.subdomain
  CONFIG.is_demo = DEMO_ACCOUNTS.includes(me.data?.tenant.subdomain)

  // identify analytics
  Analytics.queue({
    type: 'identify',
    params: {
      user: me.data?.new_user,
      tenant: me.data?.tenant,
    },
  })

  // buildUI based on data received
  buildEmployeeRoles(me.data?.tenant?.category === 'healthcare_facility' || me.data?.tenant?.category === 'healthcare_practice')

  Settings.defaultZoneName = me.data?.tenant?.timezone || 'utc'
}

export const onboarding = async () => {
  // build urls for requests
  await buildBaseUrl()

  // set token
  const tokenStep = await setHeaders()
  if (!tokenStep) return window.location.replace(`${URL.app}/logout`)

  // getMe and add it to the store
  const onboarding = await apiGet({ name: 'onboarding', url: '/onboarding' })

  // identify analytics
  Analytics.queue({
    type: 'identify',
    params: {
      user: onboarding.data?.onboarding?.data?.user,
      tenant: onboarding.data?.onboarding?.data?.tenant,
    },
  })

  // buildUI based on data received
  buildEmployeeRoles(
    onboarding.data?.onboarding?.data?.tenant?.category === 'healthcare_facility' ||
      onboarding.data?.onboarding?.data?.tenant?.category === 'healthcare_practice',
  )

  return true
}
