import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

export const InsuranceVobSummariesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        id: 'client',
        model: 'client',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.client?.name} avatar={data.client?.avatar} to={to?.(data)} />,
      },
      {
        width: 260,
        id: 'dated_at',
        model: 'dated_at',
        title: 'Date Added',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 160,
        id: 'insurance_local_payer',
        model: 'insurance_local_payer.name',
        title: 'Insurance Payer',
        disableSort: false,
      },
      {
        width: 160,
        id: 'insurance_policy',
        model: 'insurance_policy.name',
        title: 'Plan Name',
        disableSort: false,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Insurance VOB Summaries"
      icon="insurance"
      columns={columns}
      filtersConfig={FILTERS.live_reports.insurance_vobs}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="vob_summaries"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=vob_summaries'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'vob_summaries' }} />
        </>
      }
      {...props}
    />
  )
}
