import React from 'react'

import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ButtonGroup from '../../components/ButtonGroup'
import Card from '../../components/Card'
import DateOfBirthInput from '../../components/Forms/DateOfBirthInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Section from '../../components/Section'
import SexSelect from '../../components/Forms/elements/SexSelect'
import SummonOverlay from '../../components/SummonOverlay'

import { CLAIMMD_SERVICE_CODE_DATA, ClaimMDServiceCodesSelector } from './components/ClaimMDServiceCodesSelector'
import { InsurancePoliciesDataTable } from '../../constructs/Insurance/InsurancePoliciesDataTable'
import { useDataTable } from '../../components/DataTable/useDataTable'
import { formatServiceCodes } from './functions'

const defaultServiceCodes = CLAIMMD_SERVICE_CODE_DATA.filter((item) => item.code === '30')

const RootEligibilityBuilderOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'insurance_new_evobs',
    endpoint: '/insurance_new_evobs',
    invalidate: 'insurance_new_evobs',
    options: props,
  })

  const { tenant, timezone } = useSettings()

  const [client, setClient] = React.useState(initialModel?.resident)
  const [insurancePayer, setInsurancePayer] = React.useState(initialModel?.insurance_local_payer)

  const [isPolicyholder, setIsPolicyholder] = React.useState(true)
  const [policyNumber, setPolicyNumber] = React.useState(initialModel?.insured_policy_number || '')

  const [firstName, setFirstName] = React.useState(initialModel?.resident?.first_name || '')
  const [middleName, setMiddleName] = React.useState(initialModel?.resident?.middle_name || '')
  const [lastName, setLastName] = React.useState(initialModel?.resident?.last_name || '')
  const [dob, setDob] = React.useState(initialModel?.resident?.dob || '')
  const [sex, setSex] = React.useState(initialModel?.resident?.sex || '')

  const [policyholderFirstName, setPolicyholderFirstName] = React.useState(initialModel?.resident?.first_name || '')
  const [policyholderMiddleName, setPolicyholderMiddleName] = React.useState(initialModel?.resident?.middle_name || '')
  const [policyholderLastName, setPolicyholderLastName] = React.useState(initialModel?.resident?.last_name || '')
  const [policyholderDob, setPolicyholderDob] = React.useState(initialModel?.resident?.dob || '')
  const [policyholderSex, setPolicyholderSex] = React.useState(initialModel?.resident?.sex || '')

  React.useEffect(() => {
    if (!client) return

    if (isPolicyholder) {
      setPolicyholderFirstName(client?.first_name)
      setPolicyholderMiddleName(client?.middle_name)
      setPolicyholderLastName(client?.last_name)
      setPolicyholderDob(client?.dob)
      setPolicyholderSex(client?.sex)
    } else {
      setFirstName(client?.first_name)
      setMiddleName(client?.middle_name)
      setLastName(client?.last_name)
      setDob(client?.dob)
      setSex(client?.sex)

      setPolicyholderFirstName('')
      setPolicyholderMiddleName('')
      setPolicyholderLastName('')
      setPolicyholderDob('')
      setPolicyholderSex('')
    }
  }, [isPolicyholder])

  const save = async () => {
    const formData = form.current.getFormValue()

    const saveData = {
      ...formData,
      relationship: isPolicyholder ? 'client_is_insured' : 'client_is_dependent',
      service_codes_config: formatServiceCodes(formData.service_codes_config_ids),
    }

    await saveWithData(saveData)
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="eligibility" title="Eligibility Check" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            ...initialModel,
            credential: tenant?.default_evob_provider,
            service_codes: defaultServiceCodes,
          }}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <div className="grid gap-4">
              <ButtonGroup stretchSelf>
                <Button
                  label="Client is the Policy Holder"
                  size={300}
                  type={isPolicyholder ? 'primary' : 'default'}
                  color={isPolicyholder ? 'text' : 'text'}
                  onClick={() => setIsPolicyholder(true)}
                />

                <Button
                  label="Client is the Dependent"
                  size={300}
                  type={!isPolicyholder ? 'primary' : 'default'}
                  color={!isPolicyholder ? 'text' : 'text'}
                  onClick={() => setIsPolicyholder(false)}
                />
              </ButtonGroup>

              <OverlaySelector
                label="Pre-Fill Client Details"
                model="client"
                type="clients.all"
                icon={ICONS.clients}
                value={client}
                onSelect={(o) => {
                  setClient(o)

                  if (isPolicyholder) {
                    setPolicyholderFirstName(o?.first_name)
                    setPolicyholderMiddleName(o?.middle_name)
                    setPolicyholderLastName(o?.last_name)
                    setPolicyholderDob(o?.dob)
                    setPolicyholderSex(o?.sex)
                  } else {
                    setFirstName(o?.first_name)
                    setMiddleName(o?.middle_name)
                    setLastName(o?.last_name)
                    setDob(o?.dob)
                    setSex(o?.sex)
                  }
                }}
              />

              <Card className="px-3 py-3">
                <FormSection maxWidth="100%">
                  <h3 className="-mb-2">Policy Holder Details</h3>

                  <Flex stretchChildrenX gap="1rem">
                    <Input
                      label="First Name"
                      model="insured_first_name"
                      value={policyholderFirstName}
                      onUpdate={({ value }) => setPolicyholderFirstName(value)}
                      validations={{
                        presence: {
                          message: "Please enter the client's first name",
                        },
                      }}
                    />

                    <Input
                      label="Middle Name"
                      model="insured_middle_name"
                      value={policyholderMiddleName}
                      onUpdate={({ value }) => setPolicyholderMiddleName(value)}
                    />
                  </Flex>

                  <Input
                    label="Last Name"
                    model="insured_last_name"
                    value={policyholderLastName}
                    onUpdate={({ value }) => setPolicyholderLastName(value)}
                    validations={{
                      presence: {
                        message: "Please enter the client's last name",
                      },
                    }}
                  />

                  <Flex stretchChildrenX gap="1rem">
                    <DateOfBirthInput
                      label="Date of Birth"
                      model="insured_dob"
                      value={policyholderDob}
                      onUpdate={({ value }) => setPolicyholderDob(value)}
                      validations={{
                        presence: {
                          message: "Please enter the client's date of birth",
                        },
                      }}
                    />

                    <SexSelect
                      isRequired
                      label="Sex Assigned at Birth"
                      model="insured_sex"
                      value={policyholderSex}
                      onUpdate={({ value }) => setPolicyholderSex(value)}
                      validateOn="blur-change-update"
                    />
                  </Flex>

                  <OverlaySelector
                    label="Insurance Payer"
                    model="insurance_local_payer"
                    type="insurance_local_payers"
                    icon="bank_building"
                    value={insurancePayer}
                    onSelect={(o) => setInsurancePayer(o)}
                    validations={{
                      presence: {
                        message: 'Please select an Insurance Payer',
                      },
                    }}
                  />

                  <Input
                    label="Insurance Member ID (Policy Number)"
                    labelAfter={
                      client?.id && (
                        <SummonOverlay
                          overlay={
                            <InsurancePolicySelectOverlay
                              clientId={client.id}
                              onSelect={(policy) => {
                                if (policy?.insurance_local_payer) {
                                  setInsurancePayer(policy?.insurance_local_payer)
                                }

                                if (policy?.member_id) {
                                  setPolicyNumber(policy?.member_id)
                                }
                              }}
                            />
                          }
                        >
                          <Button label="Find Insurance Policy" type="link" size={100} />
                        </SummonOverlay>
                      )
                    }
                    value={policyNumber}
                    onUpdate={({ value }) => setPolicyNumber(value)}
                    model="insured_policy_number"
                    validations={{
                      presence: {
                        message: 'Please enter a member ID',
                      },
                    }}
                  />
                </FormSection>
              </Card>

              {!isPolicyholder && (
                <Card className="px-3 py-3">
                  <FormSection maxWidth="100%">
                    <h3 className="-mb-2">Client Details</h3>

                    <Flex stretchChildrenX gap="1rem">
                      <Input
                        label="First Name"
                        model="client_first_name"
                        value={firstName}
                        onUpdate={({ value }) => setFirstName(value)}
                        validations={{
                          presence: {
                            message: "Please enter the client's first name",
                          },
                        }}
                      />

                      <Input
                        label="Middle Name"
                        model="client_middle_name"
                        value={middleName}
                        onUpdate={({ value }) => setMiddleName(value)}
                      />
                    </Flex>

                    <Input
                      label="Last Name"
                      model="client_last_name"
                      value={lastName}
                      onUpdate={({ value }) => setLastName(value)}
                      validations={{
                        presence: {
                          message: "Please enter the client's last name",
                        },
                      }}
                    />

                    <Flex gap="1rem">
                      <DateOfBirthInput
                        label="Date of Birth"
                        model="client_dob"
                        value={dob}
                        onUpdate={({ value }) => setDob(value)}
                        validations={{
                          presence: {
                            message: "Please enter the client's date of birth",
                          },
                        }}
                      />

                      <SexSelect
                        isRequired
                        label="Sex Assigned at Birth"
                        model="client_sex"
                        value={sex}
                        onUpdate={({ value }) => setSex(value)}
                        validateOn="blur-change-update"
                      />
                    </Flex>
                  </FormSection>
                </Card>
              )}

              <Card className="px-3 py-3">
                <FormSection maxWidth="100%">
                  <h3 className="-mb-2">Clearinghouse Details</h3>

                  <ObjectSelector
                    label="Provider"
                    icon="staff_credentials"
                    type="company.npis"
                    model="credential"
                    dependentValue={tenant.id}
                    selectTitle={(data) => `${data.credential} (${data.license_number})`}
                    selectDescription={(data) =>
                      data.level === 'organization' ? data.organization : `${data.first_name} ${data.last_name}`
                    }
                    validations={{
                      presence: {
                        message: 'Please select a provider',
                      },
                    }}
                  />

                  <ClaimMDServiceCodesSelector
                    label="Please select the Service Codes you would like to use for the eVOB:"
                    model="service_codes_config"
                  />
                </FormSection>
              </Card>
            </div>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Run Eligibility Check"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Eligibility Check"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="eligibility.edit"
            />

            <DeleteDialog
              title="Delete Eligibility Check?"
              message="Are you sure you want to delete this Eligibility Check? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="eligibility.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const InsurancePolicySelectOverlay = (props: any) => {
  const { clientId, onClose, onSelect } = props

  const insuranceTableProps = useDataTable({
    name: ['client', clientId, 'insurance_policies'],
    endpoint: `/residents/${clientId}/insurance_policies`,
    updateDeleteEndpoint: '/insurance_policies',
    enabled: !!clientId,
    localStorageKey: 'client_insurance_policies_v1',
  })

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center" maxWidth={100}>
      <Overlay.Header icon="insurance" title="Insurance Policies" />

      <Overlay.Content className="p-4">
        <InsurancePoliciesDataTable
          {...insuranceTableProps}
          onClick={(policy) => {
            onSelect?.(policy)
            onClose?.()
          }}
        />
      </Overlay.Content>
    </Overlay>
  )
}

export const EligibilityBuilderOverlay = withOverlayError(RootEligibilityBuilderOverlay)
