import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Overlay from '../../../components/Overlay'
import SummonOverlay from '../../../components/SummonOverlay'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import Form from '../../../components/Forms/Form'
import Attachments from '../../../components/Forms/Attachments'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

import DeleteDialog from '../../../components/Dialogs/DeleteDialog'

import { SectionCard } from '../../../components/SectionCard'

import { useSettings } from '../../../hooks/useSettings'
import { useGet, useCreate, useUpdate, useDelete } from '../../../hooks/useNewAPI'

import Notifications from '../../../modules/notifications'
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'

const ATTACHMENT_CODES = [
  { code: 'AS', name: 'Admission Summary' },
  { code: 'A3', name: 'Allergies/Sensitivities Document' },
  { code: 'AM', name: 'Ambulance Certification' },
  { code: 'A4', name: 'Autopsy Report' },
  { code: 'BS', name: 'Baseline' },
  { code: 'BR', name: 'Benchmark Testing Results' },
  { code: 'BT', name: 'Blanket Test Results' },
  { code: 'CT', name: 'Certification' },
  { code: '13', name: 'Certified Test Report' },
  { code: '11', name: 'Chemical Analysis' },
  { code: 'CB', name: 'Chiropractic Justification' },
  { code: 'CK', name: 'Consent Form(s)' },
  { code: '10', name: 'Continued Treatment' },
  { code: 'V5', name: 'Death Notification' },
  { code: 'DA', name: 'Dental Models' },
  { code: 'DG', name: 'Diagnostic Report' },
  { code: 'DJ', name: 'Discharge Monitoring Report' },
  { code: 'DS', name: 'Discharge Summary' },
  { code: 'D2', name: 'Drug Profile Document' },
  { code: '04', name: 'Drugs Administered' },
  { code: 'DB', name: 'Durable Medical Equipment Prescription' },
  { code: 'EB', name: 'Explanation of Benefits' },
  { code: '07', name: 'Functional Goals' },
  { code: 'HC', name: 'Health Certificate' },
  { code: 'HR', name: 'Health Clinic Records' },
  { code: 'I5', name: 'Immunization Record' },
  { code: '06', name: 'Initial Assessment' },
  { code: '15', name: 'Justification for Admission' },
  { code: 'LA', name: 'Laboratory Results' },
  { code: 'M1', name: 'Medical Record Attachment' },
  { code: 'MT', name: 'Models' },
  { code: 'NN', name: 'Nursing Notes' },
  { code: 'OE', name: 'Objective Physical Examination' },
  { code: 'OB', name: 'Operative Note' },
  { code: 'OD', name: 'Orders and Treatments Document' },
  { code: 'OC', name: 'Oxygen Content Averaging Report' },
  { code: 'OX', name: 'Oxygen Therapy Certification' },
  { code: 'PQ', name: 'Paramedical Results' },
  { code: 'PE', name: 'Parenteral or Enteral Certification' },
  { code: 'P4', name: 'Pathology Report' },
  { code: 'P5', name: 'Patient Medical History Document' },
  { code: 'XP', name: 'Photographs' },
  { code: 'PZ', name: 'Physical Therapy Certification' },
  { code: 'PN', name: 'Physical Therapy Notes' },
  { code: 'B3', name: 'Physician Order' },
  { code: 'PY', name: 'Physicians Report' },
  { code: '08', name: 'Plan of Treatment' },
  { code: 'B2', name: 'Prescription' },
  { code: '09', name: 'Progress Report' },
  { code: 'PO', name: 'Prosthetics or Orthotic Certification' },
  { code: 'RB', name: 'Radiology Films' },
  { code: 'RR', name: 'Radiology Reports' },
  { code: '21', name: 'Recovery Plan' },
  { code: 'B4', name: 'Referral Form' },
  { code: 'RX', name: 'Renewable Oxygen Content Averaging Report' },
  { code: '03', name: 'Report Justifying Treatment Beyond Utilization Guidelines' },
  { code: 'RT', name: 'Report of Tests and Analysis Report' },
  { code: 'IR', name: 'State School Immunization Records' },
  { code: 'OZ', name: 'Support Data for Claim' },
  { code: 'SG', name: 'Symptoms Document' },
  { code: '05', name: 'Treatment Diagnosis' },
]

export const ClaimOverlayAttachments = (props) => {
  const form = React.useRef(null)

  const { canEdit = true } = props

  const { timezone, tenant, isBehave } = useSettings()
  const params = useParams()

  const id = props.id || params?.id

  const [isEditable, setIsEditable] = React.useState(false)
  const [isValid, setIsValid] = React.useState(false)

  const { data: claim, isLoading } = useGet({
    name: ['insurance_claims', id],
    url: `/insurance_claims/${id}`,
    options: { enabled: true },
  })

  const { mutateAsync: updateAsync, isLoading: isUpdating } = useUpdate({
    name: ['insurance_claims', id, 'update'],
    url: `/insurance_claims/${id}`,
    invalidate: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Claim Updated Successfully', 'positive')
    },
  })

  const isSaving = isUpdating
  const initialData = location.data || claim

  const save = async () => {
    try {
      await updateAsync(form.current.getFormValue())
    } catch (error) {
      console.error(error)
    } finally {
      setIsEditable(false)
    }
  }

  const cancel = () => {
    form.current?.resetForm()
    setIsEditable(false)
  }

  return (
    <>
      <Overlay.Content css={{ zIndex: 0 }}>
        <Form
          isCompact
          useFullModel
          getForm={form}
          initialModel={initialData}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={setIsValid}
          linked={{ version: 'v2' }}
          maxWidth="100%"
          key={`updated-${claim?.updated_at}`}
        >
          <div className="!p-4">
            <Grid gap="0.5rem">
              <SectionCard title="Details" className="mq900:min-w-[420px]">
                <Grid gap="1rem">
                  <Select allowEmpty label="Attachment Type" model="attachment_type">
                    {ATTACHMENT_CODES.map((code: any) => (
                      <Option label={code.name} value={code.code} />
                    ))}
                  </Select>
                </Grid>
              </SectionCard>

              <SectionCard title="Files">
                <Attachments
                  model="documents"
                  label="Attachments"
                  labelAlign="top"
                  labelJustify="top"
                  allowedContentTypes={['application/pdf']}
                />
              </SectionCard>
            </Grid>
          </div>
        </Form>
      </Overlay.Content>

      {canEdit && (
        <Overlay.Footer className="!px-2">
          {isEditable && (
            <Flex stretchChildrenX gap={8}>
              {claim?.status === 'validated' ? (
                <ConfirmDialog
                  title="Save Changes?"
                  message="Are you sure you want to save these changes? The Claim Status will be changed to Draft and you will be required to Re-Validate."
                  glyph="check"
                  yesColor="green"
                  yesLabel="Yes, Save Changes"
                  onYes={save}
                  isLoading={isSaving}
                >
                  <Button
                    label="Save"
                    glyph="check"
                    type="primary"
                    color="green"
                    isLoading={isSaving}
                    flex="3 1 auto"
                    permission="insurance_claims.create"
                  />
                </ConfirmDialog>
              ) : (
                <Button
                  label="Save"
                  glyph="check"
                  type="primary"
                  color="green"
                  isLoading={isSaving}
                  onClick={save}
                  flex="3 1 auto"
                  permission="insurance_claims.create"
                />
              )}

              <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} flex="0 1 auto" />
            </Flex>
          )}

          {!isEditable && (
            <Flex gap={8}>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={isSaving}
                onClick={() => setIsEditable(true)}
                flex="1 1 auto"
                permission="insurance_claims.edit"
              />
            </Flex>
          )}
        </Overlay.Footer>
      )}
    </>
  )
}
