import React from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import Glyph from './Glyph'
import Button from './Button'
import Flex from './Flex'

import LogRocket from '../vendors/logrocket'
import withSettings from '../hocs/withSettings'

import { useFeedbackStore } from './FeedbackTool'

export const FallbackComponent = ({ user, tenant, onLeave, showHomeButton = true }: any) => {
  const open = useFeedbackStore((state) => state.open)

  return (
    <Flex vertical centerX centerY css={styles}>
      <Glyph glyph="circle_error" className="!w-8 !h-8" />
      <h2 data-test="error_component">Oops, something went wrong…</h2>
      <p className="!max-w-[26rem]">You can get in touch and let us know how this happened, or click the link below to go back</p>
      <Flex vertical gap={8}>
        <Button color="red" label="Let us know what happened" glyph="email" onClick={open} />
        {showHomeButton && <Button label="Go back to the Dashboard" glyph="long_arrow_left" type="link" onClick={onLeave} />}
      </Flex>
    </Flex>
  )
}

const ErrorBoundary = ({ user, tenant, children, fallback }: any) => {
  const history = useHistory()

  const defaultFallback = ({ resetError }: any) => (
    <FallbackComponent
      user={user}
      tenant={tenant}
      onLeave={() => {
        history.push('/dashboard')
        resetError()
      }}
    />
  )

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope: any) => {
        if (LogRocket.getRocket()) {
          scope.setContext('sessionURL', LogRocket.getRocket().sessionURL)
        }
      }}
      fallback={fallback || defaultFallback}
      onError={(error: any) => {
        console.error(error)
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

const styles = {
  textAlign: 'center',
  padding: '0 1.5rem',
  minHeight: '12rem',

  position: 'absolute',
  top: '0',
  right: '0',
  width: '100%',
  height: '100%',
}

export default withSettings(ErrorBoundary)
