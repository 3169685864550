import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import Button from '@behavehealth/components/Button'
import Page from '@behavehealth/components/Page'

import { EligibilityDataTable } from '@behavehealth/constructs/Eligibility/EligibilityDataTable'

const pageConfig = {
  feature: 'eligibility',
}

const RootEligibility: React.FC = () => {
  const match = useRouteMatch()

  const tableProps = useDataTable({
    name: ['eligibility'],
    endpoint: `/eligibility`,
  })

  const { data: client }: any = useGet({
    name: ['client', match.params.resource_id],
    url: `/residents/${match.params.resource_id}`,
  })

  return (
    <Page
      breakpoint="0"
      {...pageConfig}
      actions={
        <Button
          type="primary"
          glyph="add"
          label="Check Eligibility"
          permission="eligibility.create"
          link={{
            pathname: `${match.url}/new`,
            parent: match,
            data: {
              resident: client,
              resident_id: client?.id,
            },
          }}
        />
      }
    >
      <EligibilityDataTable {...tableProps} to={({ id }) => `${match.url}/${id}`} />
    </Page>
  )
}

export const Eligibility = withPageError(withMarketing(RootEligibility, pageConfig))
