import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

import {
  OUTCOME_MEASURE_DISCHARGE_TITLES,
  OUTCOME_MEASURE_MENTAL_HEALTH_DIAGNOSIS_TITLES,
  OUTCOME_MEASURE_DISCHARGE_LEVEL_OF_CARE_TITLES,
  OUTCOME_MEASURE_PAYMENT_TYPE_TITLES,
  OUTCOME_MEASURE_MAT_MEDICATION_TITLES,
  OUTCOME_MEASURE_DISORDER_CATEGORY_TITLES,
} from './constants'

import { useSettings } from '../../hooks/useSettings'
import { countWord } from '@behavehealth/utils/functions'

import DataFormStatus from '@behavehealth/components/Statuses/DataFormStatus'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

export const DischargeSurveyReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'client',
        model: 'client.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 180,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }) => {
          if (!value) return null

          return <DataFormStatus status={value} />
        },
      },
      {
        id: 'dated_at',
        model: 'dated_at',
        title: 'Date and Time',
        type: 'date_time',
      },
      {
        id: 'ended_at',
        model: 'ended_at',
        title: 'Session End Time',
        type: 'date_time',
      },
      {
        width: 210,
        id: 'discharge_level_of_care',
        model: 'discharge_level_of_care',
        title: 'Discharge Level Of Care',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_DISCHARGE_LEVEL_OF_CARE_TITLES[value]
        },
      },
      {
        id: 'discharge_type',
        model: 'discharge_type',
        title: 'Type Of Discharge',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_DISCHARGE_TITLES[value]
        },
      },
      {
        width: 280,
        id: 'inpatient_hospital_or_medical_detox',
        model: 'inpatient_hospital_or_medical_detox',
        title: 'Inpatient Hospital or Medical Detox',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        id: 'residential',
        model: 'residential',
        title: 'Residential',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        id: 'partial_hospitalization',
        model: 'partial_hospitalization',
        title: 'Partial Hospitalization',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        id: 'intensive_outpatient',
        model: 'intensive_outpatient',
        title: 'Intensive Outpatient',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 280,
        id: 'sober_living_recovery_residence',
        model: 'sober_living_recovery_residence',
        title: 'Sober Living Recovery Residence',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        id: 'recovery_support',
        model: 'recovery_support',
        title: 'Recovery Support',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        id: 'other',
        model: 'other',
        title: 'Other',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        id: 'payment_type',
        model: 'payment_type',
        title: 'Payment Type',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_PAYMENT_TYPE_TITLES[value]
        },
      },
      {
        width: 400,
        id: 'primary_diagnostic_substance_use_disorder_category',
        model: 'primary_diagnostic_substance_use_disorder_category',
        title: 'Primary Diagnostic Substance Use Disorder Category',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_DISORDER_CATEGORY_TITLES[value]
        },
      },
      {
        width: 250,
        id: 'mat_medications_received',
        model: 'mat_medications_received',
        title: 'MAT Medications Received',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_MAT_MEDICATION_TITLES[value]
        },
      },
      {
        width: 250,
        id: 'mental_health_diagnosis',
        model: 'mental_health_diagnosis',
        title: 'Mental Health Diagnosis',
        formatValue: ({ value }) => {
          if (!value) return null

          return value
            .split(',')
            .map((o) => OUTCOME_MEASURE_MENTAL_HEALTH_DIAGNOSIS_TITLES[o])
            .join(', ')
        },
      },
      {
        width: 200,
        id: 'did_use_telehealth',
        model: 'did_use_telehealth',
        title: 'Did Use Telehealth',
        type: 'boolean',
      },
      {
        width: 200,
        id: 'created_at',
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Discharge Survey"
      icon="outcome_measures"
      columns={columns}
      filtersConfig={FILTERS.live_reports.discharge_survey}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="discharge_survey"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=discharge_survey'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'discharge_survey' }} />
        </>
      }
      {...props}
    />
  )
}
