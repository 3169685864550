import { FEATURES } from '../../theme'
import { arrayToMapWithKey, beautifulAmount } from '../../utils/functions'

export const GENERAL_FEATURES = [
  { model: 'admissions', ...FEATURES['admissions'] },
  { model: 'clients', ...FEATURES['clients'] },
  { model: 'alumni', ...FEATURES['alumni'] },
  { model: 'staff', ...FEATURES['employees'] },
  { model: 'places', ...FEATURES['places'] },
  { model: 'properties', ...FEATURES['properties'] },
  { model: 'bed_management', ...FEATURES['bed_management'] },
  { model: 'message_board', ...FEATURES['messages'] },
  { model: 'activity_timeline', ...FEATURES['billed_by_behave'], title: 'Activity Timeline' },
  { model: 'data_export', ...FEATURES['billed_by_behave'], title: 'Data Export' },
]

export const ERP_FEATURES = [
  { model: 'todos', ...FEATURES['todos'] },
  { model: 'todo_templates', ...FEATURES['todos'], title: 'To-Do Templates' },
  { model: 'calendar', ...FEATURES['calendar'] },
  { model: 'calendar_templates', ...FEATURES['calendar'], title: 'Calendar Templates' },
  { model: 'live_reports', ...FEATURES['reports'], title: 'Live Reports' },
  { model: 'files', ...FEATURES['files'] },
  { model: 'excel_reports', ...FEATURES['reports'], title: 'Excel Reports' },
  { model: 'organizations', ...FEATURES['organizations'] },
  { model: 'organization_agreements', ...FEATURES['agreements'], title: 'Organization Agreements' },
  { model: 'organization_agreement_templates', ...FEATURES['agreements'], title: 'Organization Agreement Templates' },
  { model: 'staff_agreements', ...FEATURES['agreements'], title: 'Staff Agreements' },
  { model: 'staff_agreement_templates', ...FEATURES['agreements'], title: 'Staff Agreement Templates' },
]

export const CONTACTS_FEATURES = [
  { model: 'contacts', ...FEATURES['contacts'] },
  { model: 'communication_logs', ...FEATURES['communications'] },
]

export const CHAT_FEATURES = [
  { model: 'staff_chat', ...FEATURES['company_chat'] },
  { model: 'staff_message_blasts', ...FEATURES['company_chat'], title: 'Staff Message Blasts' },
  { model: 'clients_chat', ...FEATURES['clients_chat'] },
  { model: 'clients_message_blasts', ...FEATURES['clients_chat'], title: 'Clients Message Blasts' },
]

export const CARE_COORDINATION_FEATURES = [
  { model: 'custom_application_form', ...FEATURES['admissions'], title: 'Custom Application Form' },
  { model: 'discharges', ...FEATURES['discharges'] },
  { model: 'client_agreements', ...FEATURES['agreements'] },
  { model: 'client_agreement_templates', ...FEATURES['agreements'], title: 'Client Agreement Templates' },
  { model: 'client_portal', ...FEATURES['clients'], title: 'Client Portal' },
  { model: 'programs', ...FEATURES['programs'] },
  { model: 'care_team', ...FEATURES['connections'] },
  { model: 'location_gps_check_in', ...FEATURES['location_checkin'] },
]

export const RCM_FEATURES = [
  { model: 'services_book', ...FEATURES['financials'], title: 'Services Book' },
  { model: 'financials', ...FEATURES['financials'] },
  { model: 'organization_financials', ...FEATURES['financials'], title: 'Organization Financials' },
  { model: 'online_payments_collection_with_stripe', ...FEATURES['online_payments'], title: 'Online Payments (Stripe)' },
]

export const INSURANCE_FEATURES = [
  { model: 'insurance_authorizations', ...FEATURES['authorizations'] },
  { model: 'insurance_reports_files', ...FEATURES['rcm'], title: 'Reports & Files' },
  { model: 'insurance_policies', ...FEATURES['insurance'], title: 'Insurance Policies' },
  { model: 'insurance_payers', ...FEATURES['payers'] },
  { model: 'evobs', icon: 'behave_health', title: 'eVOBs' },
  { model: 'full_vobs', icon: 'behave_health', title: 'Full VOBs' },
  { model: 'company_credentials', ...FEATURES['company_credentials'] },
  { model: 'staff_credentials', ...FEATURES['staff_credentials'] },
  { model: 'billing_settings', ...FEATURES['staff_credentials'], title: 'Billing Settings' },
  { model: 'insurance_services', ...FEATURES['insurance'], title: 'Insurance Services' },
]

export const NOTES_FEATURES = [
  { model: 'shift_notes', ...FEATURES['housing_notes'] },
  { model: 'recovery_coaching', ...FEATURES['recovery_coaching'] },
  { model: 'client_custom_notes', ...FEATURES['custom_notes'], title: 'Client Custom Notes' },
  { model: 'staff_custom_notes', ...FEATURES['custom_notes'], title: 'Staff Custom Notes' },
  { model: 'property_custom_notes', ...FEATURES['custom_notes'], title: 'Location Custom Notes' },
  { model: 'organization_custom_notes', ...FEATURES['custom_notes'], title: 'Organization Custom Notes' },
  { model: 'program_custom_notes', ...FEATURES['custom_notes'], title: 'Program Custom Notes' },
  { model: 'client_custom_note_templates', ...FEATURES['custom_notes'], title: 'Client Custom Note Templates' },
  { model: 'staff_custom_note_templates', ...FEATURES['custom_notes'], title: 'Staff Custom Note Templates' },
  { model: 'property_custom_note_templates', ...FEATURES['custom_notes'], title: 'Location Custom Note Templates' },
  { model: 'organization_custom_note_templates', ...FEATURES['custom_notes'], title: 'Organization Custom Note Templates' },
  { model: 'program_custom_note_templates', ...FEATURES['custom_notes'], title: 'Program Custom Note Templates' },
]

export const MEDICAL_FEATURES = [
  { model: 'test_results', ...FEATURES['test_results'], title: 'Test Results' },
  { model: 'ua_tests', ...FEATURES['test_results'], title: 'UA Tests' },
  { model: 'breathalyzer_tests', ...FEATURES['test_results'], title: 'Breathalyzer Tests' },
  { model: 'communicable_disease_tests', ...FEATURES['test_results'], title: 'Communicable Disease Tests' },
  { model: 'vital_signs', ...FEATURES['test_results'], title: 'Vital Signs' },
  { model: 'medications', ...FEATURES['medications'] },
  { model: 'medication_logs', ...FEATURES['medication_logs'] },
  { model: 'medication_destruction_logs', ...FEATURES['medication_logs'], title: 'Medication Destruction Logs' },
  { model: 'medical_assessments', ...FEATURES['medical_assessments'] },
  { model: 'nursing', ...FEATURES['nursing'] },
  { model: 'physician_orders', ...FEATURES['physician_orders'] },
]

export const CLINICAL_FEATURES = [
  { model: 'clinical_assessments', ...FEATURES['clinical_assessments'] },
  { model: 'treatment_plans', ...FEATURES['treatment_plans'] },
  { model: 'treatment_plan_templates', ...FEATURES['treatment_plans'], title: 'Treatment Plan Templates' },
  { model: 'progress_reviews', ...FEATURES['progress_reviews'] },
  { model: 'clinical_notes', ...FEATURES['clinical_notes'] },
  { model: 'clinical_measures', ...FEATURES['clinical_measurements'] },
  { model: 'outcome_measures', ...FEATURES['outcome_measures'] },
]

export const SECURITY_FEATURES = [
  { model: 'staff_permissions', ...FEATURES['staff_permissions'] },
  { model: 'permission_templates', ...FEATURES['permission_templates'] },
  { model: 'staff_ip_restrictions', ...FEATURES['ip_restrictions'] },
  { model: 'staff_clients_access', ...FEATURES['access_lists'] },
  { model: 'client_portal_permissions', ...FEATURES['clients'], title: 'Client Portal Permissions' },
  { model: 'pin_based_signature', ...FEATURES['your_settings'], title: 'PIN Signature' },
]

export const GENERAL_FEATURES_KEYS = GENERAL_FEATURES.map((o) => o.model)
export const ERP_FEATURES_KEYS = ERP_FEATURES.map((o) => o.model)
export const CONTACTS_FEATURES_KEYS = CONTACTS_FEATURES.map((o) => o.model)
export const CHAT_FEATURES_KEYS = CHAT_FEATURES.map((o) => o.model)
export const CARE_COORDINATION_FEATURES_KEYS = CARE_COORDINATION_FEATURES.map((o) => o.model)
export const RCM_FEATURES_KEYS = RCM_FEATURES.map((o) => o.model)
export const INSURANCE_FEATURES_KEYS = INSURANCE_FEATURES.map((o) => o.model)
export const NOTES_FEATURES_KEYS = NOTES_FEATURES.map((o) => o.model)
export const MEDICAL_FEATURES_KEYS = MEDICAL_FEATURES.map((o) => o.model)
export const CLINICAL_FEATURES_KEYS = CLINICAL_FEATURES.map((o) => o.model)
export const SECURITY_FEATURES_KEYS = SECURITY_FEATURES.map((o) => o.model)

export const ALL_FEATURES = arrayToMapWithKey(
  [
    ...GENERAL_FEATURES,
    ...ERP_FEATURES,
    ...CONTACTS_FEATURES,
    ...CHAT_FEATURES,
    ...CARE_COORDINATION_FEATURES,
    ...RCM_FEATURES,
    ...INSURANCE_FEATURES,
    ...NOTES_FEATURES,
    ...MEDICAL_FEATURES,
    ...CLINICAL_FEATURES,
    ...SECURITY_FEATURES,
  ],
  'model',
)

export const PRICING_STRATEGIES = {
  free: 'Free',
  one_time: 'One Time Payment',
  monthly: 'Monthly',
  annually: 'Annual',
  metered_monthly: 'Monthly (Metered)',
  metered_annually: 'Annual (Metered)',
  payment_plan: 'Payment Plan',
}

export const BILLING_TYPE = {
  upfront: 'Upfront',
  end_of_cycle: 'At the End of Cycle',
}

export const PRICING_UNIT_TYPES = {
  client_per_month: 'Client / Month',
  alumni_per_month: 'Past Client / Month',
  employee_per_month: 'Staff / Month',
  user_per_month: 'User / Month',
  property_per_month: 'Property / Month',
  bed_per_month: 'Bed / Month',
  financial_charge_per_month: 'Per $1000 of Charges / Month',
  financial_charge_minus_writeoff_per_month: "Per $1000 of Charges minus Write-Off's / Month",
  financial_payments_per_month: 'Per $1000 of Payments / Month',
  insurance_payments_per_month: 'Per $1000 of Ins. Payments / Month',
  financial_charge_dollar_per_month: 'Per $1 of Charges / Month',
  financial_charge_minus_writeoff_dollar_per_month: "Per $1 of Charges minus Write-Off's / Month",
  financial_payments_dollar_per_month: 'Per $1 of Payments / Month',
  insurance_payments_dollar_per_month: 'Per $1 of Ins. Payments / Month',
  evob_charge_per_month: 'eVOB / Month (i)',
  storage_per_month: 'GB / Month',
  notification_per_month: 'Notification / Month (i)',
  thousand_units_per_month: '1000 Units / Month (i)',
  per_hour: 'Per Hour',
}

export const YEARLY_PRICING_UNIT_TYPES = {
  client_per_year: 'Client / Year',
  alumni_per_year: 'Past Client / Year',
  employee_per_year: 'Staff / Year',
  user_per_year: 'User / Year',
  property_per_year: 'Property / Year',
  bed_per_year: 'Bed / Year',
  financial_charge_per_year: 'Per $1000 of Charges / Year',
  financial_payments_per_year: 'Per $1000 of Payments / Year',
  insurance_payments_per_year: 'Per $1000 of Ins. Payments / Year',
  financial_charge_dollar_per_year: 'Per $1 of Charges / Year',
  financial_payments_dollar_per_year: 'Per $1 of Payments / Year',
  insurance_payments_dollar_per_year: 'Per $1 of Ins. Payments / Year',
  evob_charge_per_year: 'eVOB / Year (i)',
  storage_per_year: 'GB / Year',
  notification_per_year: 'Notification / Year (i)',
  thousand_units_per_year: '1000 Units / Year (i)',
  per_hour: 'Per Hour',
}

export const CALCULATION_UNITS = {
  client_per_month: 'Clients',
  employee_per_month: 'Staff',
  user_per_month: 'User',
  property_per_month: 'Properties',
  bed_per_month: 'Beds',
  financial_charge_per_month: 'in Monthly Charges',
  financial_payments_per_month: 'in Monthly Payments',
  insurance_payments_per_month: 'in Monthly Ins. Payments',
  evob_charge_per_month: 'eVOBs',
  storage_per_month: 'GBs',
  notification_per_month: 'Notifications',
  thousand_units_per_month: 'Thousand Units',
  client_per_year: 'Clients',
  employee_per_year: 'Staff',
  property_per_year: 'Properties',
  bed_per_year: 'Beds',
  financial_charge_per_year: 'in Yearly Charges',
  financial_payments_per_year: 'in Yearly Payments',
  insurance_payments_per_year: 'in Yearly Ins. Payments',
  evob_charge_per_year: 'eVOBs',
  storage_per_year: 'GBs',
  notification_per_year: 'Notifications',
  thousand_units_per_year: 'Thousand Units',
  per_hour: 'Hours',
}

export const CALCULATION_VALUES = {
  client_per_month: 10,
  employee_per_month: 10,
  user_per_month: 1,
  property_per_month: 10,
  bed_per_month: 10,
  financial_charge_per_month: 1000,
  financial_payments_per_month: 1000,
  insurance_payments_per_month: 1000,
  financial_charge_dollar_per_month: 1,
  financial_payments_dollar_per_month: 1,
  insurance_payments_dollar_per_month: 1,
  evob_charge_per_month: 100,
  storage_per_month: 2,
  notification_per_month: 1000,
  thousand_units_per_month: 1,
  client_per_year: 10,
  employee_per_year: 10,
  property_per_year: 10,
  bed_per_year: 10,
  financial_charge_per_year: 1000,
  financial_payments_per_year: 1000,
  insurance_payments_per_year: 1000,
  financial_charge_dollar_per_year: 1,
  financial_payments_dollar_per_year: 1,
  insurance_payments_dollar_per_year: 1,
  evob_charge_per_year: 100,
  storage_per_year: 2,
  notification_per_year: 1000,
  thousand_units_per_year: 1,
  per_hour: 10,
}

export const buildPriceLabel = (pricingType: string, price: number, unitType: string) => {
  if (pricingType === 'free') return 'Free'
  if (pricingType === 'monthly') return `${beautifulAmount(parseFloat(price))}/Month`
  if (pricingType === 'annually') return `${beautifulAmount(parseFloat(price))}/Year`
  if (pricingType === 'one_time') return beautifulAmount(parseFloat(price))
  if (pricingType === 'metered_monthly') return `${beautifulAmount(parseFloat(price))} ${PRICING_UNIT_TYPES[unitType]}`
  if (pricingType === 'metered_annually') return `${beautifulAmount(parseFloat(price))} ${YEARLY_PRICING_UNIT_TYPES[unitType]}`
}
