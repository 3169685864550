import React from 'react'
import size from 'lodash/size'
import uniq from 'lodash/uniq'

import { COLORS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'
import { useVerifyPermission } from '../../hooks/useVerifyPermission'

import Alert from '../Alert'

import WorkflowButtons from './WorkflowButtons'
import WorkflowContent from './WorkflowContent'
import WorkflowContinueButton from './WorkflowContinueButton'
import WorkflowSaveContinueButton from './WorkflowSaveContinueButton'
import WorkflowHeader from './WorkflowHeader'
import WorkflowPanel from './WorkflowPanel'
import WorkflowSkipButton from './WorkflowSkipButton'
import WorkflowStatus from './WorkflowStatus'
import WorkflowStepBadge from './WorkflowStepBadge'
import WorkflowTitle from './WorkflowTitle'

import { CSS_VARS } from './css'
import { useWorkflow } from './useWorkflow'
import { WorkflowContext, WorkflowPanelContext } from './context'

const getSteps = (children: React.ReactNode, hiddenSteps: string[], noPermissionSteps = []) => {
  const result = []

  for (const child of React.Children.toArray(children)) {
    if (!child?.props?.step) continue

    if (hiddenSteps.includes(child.props.step) || noPermissionSteps.includes(child.props.step)) continue

    result.push(child.props.step)
  }

  return result
}

const Workflow = (props: any) => {
  const {
    baseline,
    children,
    className,
    manualNavigationDisabled,
    paddingX,
    paddingY,
    showIndent,
    showStepNumber,
    //
    name,
    config = {},
    stepStatuses = {},
    requiredOptions = {},
    visibilityOptions = {},
    stepInstructions,
  } = props

  const { verifyPermission } = useVerifyPermission()

  const { tenant, user } = useSettings()

  const [workflowSteps, setWorkflowSteps]: any = React.useState([])
  const [noPermissionSteps, setNoPermissionSteps]: any = React.useState([])

  const hiddenSteps = React.useMemo(() => {
    const result: any = [...(props.hiddenSteps || [])]

    if (size(visibilityOptions) === 0) return result

    for (const key in config) {
      const step = config[key]

      // skip hiding required steps
      if (step.isRequired) continue

      // hide steps disabled from options
      if (visibilityOptions[key] === false) result.push(key)
    }

    return result
  }, [config, visibilityOptions, props.hiddenSteps])

  const requiredSteps = React.useMemo(() => {
    const result: any = []

    if (size(requiredOptions) === 0) return result

    for (const key in config) {
      const stepConfig = config[key]

      // skip mandatory and hidden steps
      if (stepConfig.isMandatory || hiddenSteps?.includes?.(key)) continue

      // add always required steps from config
      if (stepConfig.isRequired) {
        result.push(key)
        continue
      }

      // add required steps from options
      if (stepConfig.isRequiredOptional && requiredOptions[key] === true) {
        result.push(key)
      }
    }

    return result
  }, [config, requiredOptions, hiddenSteps])

  const childrenWithProps: any = React.Children.map(children, (child) => {
    if (!child) return

    const featureFlag = child.props.featureFlagV2 || child.props.featureFlag
    const permission = child.props.permission

    const hasFeatureFlag = verifyPermission({ featureFlag })
    const hasPermission = verifyPermission({ permission })

    const isHidden = hiddenSteps.includes(child.props.step)
    const isRequired = requiredSteps.includes(child.props.step)

    if (!hasFeatureFlag || isHidden) return null

    if (!workflowSteps.includes(child.props.step) && (hasPermission || isRequired)) {
      setWorkflowSteps((c: any) => uniq([...c, child.props.step]))
    }

    if (!hasPermission) {
      if (!isRequired && !noPermissionSteps.includes(child.props.step)) {
        setNoPermissionSteps((c: any) => uniq([...c, child.props.step]))
      }

      // hide steps with no permission that are not required
      if (!isRequired) return null

      const contactEmail = tenant?.contacts?.roles_permissions?.email || tenant?.contacts?.owner?.email

      const emailSubject = `Permission request for ${name ? `"${name}" workflow` : `a workflow`} in ${
        tenant?.name ? `"${tenant.name}" Behave Health EHR` : `Behave Health EHR`
      }`

      const emailBody = getNoPermissionMessage({
        workflowName: name,
        tenant,
        user,
        noPermissionSteps: noPermissionSteps.map((key) => config?.[key]?.label),
      })

      return React.cloneElement(child, {
        noPermissionUI: (
          <div className="p-4">
            <Alert contrast type="warning" glyph="warning">
              You do not have permission to view this step.{' '}
              {contactEmail ? (
                <>
                  Please contact your administrator (
                  <a href={`mailto:${contactEmail}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}>
                    {contactEmail}
                  </a>
                  ) to enable this for you.
                </>
              ) : (
                'Please contact your administrator to enable this for you.'
              )}
            </Alert>
          </div>
        ),
      })
    }

    return child
  })

  const isAllCompleted = React.useMemo(() => {
    if (size(requiredSteps) === 0) return true

    let result = false

    for (const step of requiredSteps) {
      if (stepStatuses[step] !== 'completed' && !config[step]?.isMandatory) {
        result = false
        break
      }

      result = true
    }

    return result
  }, [config, stepStatuses, requiredSteps])

  const context = useWorkflow({
    steps: getSteps(children, hiddenSteps, noPermissionSteps),
    defaultStep: props.defaultStep,
  })

  React.useEffect(() => {
    context.setAllSteps(workflowSteps)
  }, [workflowSteps])

  return (
    <WorkflowContext.Provider
      value={{
        ...context,
        goNext: () => context.goNext(context.currentStep),
        goBack: () => context.goBack(context.currentStep),
        config,
        hiddenSteps,
        isAllCompleted,
        manualNavigationDisabled,
        requiredSteps,
        showIndent,
        showStepNumber,
        stepStatuses,
        stepInstructions,
      }}
    >
      <div className={className} css={styles({ baseline, paddingX, paddingY })}>
        {childrenWithProps}
      </div>
    </WorkflowContext.Provider>
  )
}

const getNoPermissionMessage = ({ workflowName, tenant, user, noPermissionSteps }: any) => `Hello,

I am trying to complete ${workflowName ? `the "${workflowName}" workflow` : `a workflow`} in ${
  tenant?.name ? `"${tenant.name}" Behave Health EHR` : `Behave Health EHR`
} but I do not have permission to complete the following steps:

${size(noPermissionSteps) > 0 ? noPermissionSteps.map((o) => `- ❌ ${o}`).join?.('\n') : '…'}

Please enable these steps for me.

Thank you,
${user?.name || ''}
`

const styles = ({ baseline, paddingX, paddingY }: any) => ({
  [CSS_VARS.baseline]: baseline,
  [CSS_VARS.paddingX]: paddingX,
  [CSS_VARS.paddingY]: paddingY,
  borderBottom: `1px solid ${COLORS.divider}`,
})

Workflow.defaultProps = {
  baseline: '3rem',
  paddingX: '1rem',
  paddingY: '0.75rem',
  showIndent: true,
  showStepNumber: true,
}

Workflow.Content = WorkflowContent
Workflow.Content.displayName = 'WorkflowContent'

Workflow.Context = WorkflowContext
Workflow.Context.displayName = 'WorkflowContext'

Workflow.PanelContext = WorkflowPanelContext
Workflow.PanelContext.displayName = 'WorkflowPanelContext'

Workflow.ContinueButton = WorkflowContinueButton
Workflow.ContinueButton.displayName = 'WorkflowContinueButton'

Workflow.SaveContinueButton = WorkflowSaveContinueButton
Workflow.SaveContinueButton.displayName = 'SaveContinueButton'

Workflow.Header = WorkflowHeader
Workflow.Header.displayName = 'WorkflowHeader'

Workflow.Panel = WorkflowPanel
Workflow.Panel.displayName = 'WorkflowPanel'

Workflow.SkipButton = WorkflowSkipButton
Workflow.SkipButton.displayName = 'WorkflowSkipButton'

Workflow.Status = WorkflowStatus
Workflow.Status.displayName = 'WorkflowStatus'

Workflow.StepBadge = WorkflowStepBadge
Workflow.StepBadge.displayName = 'WorkflowStepBadge'

Workflow.Title = WorkflowTitle
Workflow.Title.displayName = 'WorkflowTitle'

Workflow.Buttons = WorkflowButtons
Workflow.Buttons.displayName = 'WorkflowButtons'

export default Workflow
