import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { WriteOffsReportDataTable } from '@behavehealth/constructs/LiveReports/WriteOffsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const WriteOffs = () => {
  const tableProps = useDataTable({
    name: ['reports', 'write-offs'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_write_offs_v1',
    params: { category: 'write_offs' },
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData?.resident)}/financials/write-offs/${rowData?.id}`, [])

  return (
    <Page feature="financials" title="Live Write-Offs Report">
      <Grid>
        <WriteOffsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(WriteOffs)
