import React from 'react'
import size from 'lodash/size'
import get from 'lodash/get'
import { singular } from 'pluralize'

import { isDefined } from '../../utils/functions'

export const useSpreadsheet = (props: any) => {
  const { manualRowDelete } = props

  const [isEditable, setIsEditable] = React.useState(!!props.isEditable)
  const [spreadsheet, setSpreadsheet]: any = React.useState(null)
  const [selectedRowIndexes, setSelectedRowIndexes]: any = React.useState([])

  const columns = React.useMemo(() => {
    const res = [...props.columns]

    if (manualRowDelete) {
      res.unshift({
        title: ' ',
        type: 'select_row',
        width: 50,
        config: {
          onRowSelect: (rowIndex, checked) => {
            setSelectedRowIndexes((prev: any) => {
              if (checked) {
                return prev.includes(rowIndex) ? prev : [...prev, rowIndex]
              }

              return prev.filter((r: number) => r !== rowIndex)
            })
          },
        },
      })
    }

    return res
  }, [props.columns])

  const edit = () => setIsEditable(true)
  const cancel = () => setIsEditable(false)

  const clear = () => {
    if (!spreadsheet?.hotInstance) return

    spreadsheet.hotInstance.clear()
    spreadsheet.hotInstance.deselectCell()
  }

  // return data in nested array format
  const getRawData = () => {
    if (!spreadsheet?.hotInstance) return

    return spreadsheet.hotInstance.getData()
  }

  // format data into objects based on column models
  const getData = () => {
    if (!spreadsheet?.hotInstance) return

    const rawData = spreadsheet.hotInstance.getData()

    if (!rawData) return

    const res: any = []

    for (let i = 0; i < rawData.length; i++) {
      const row = rawData[i]
      const obj: any = {}

      for (let j = 0; j < row.length; j++) {
        const { model, type, config } = columns[j]

        if (!model) continue

        let processedModel = model
        let value = row[j]

        if (value && type === 'object_selector') {
          const parsed = JSON.parse?.(value)

          obj[`${model}_id`] = parsed?.id

          if (config?.includeObject) {
            obj[model] = parsed
          }

          continue
        }

        if (value && type === 'multi_object_selector') {
          const parsed = JSON.parse(value)

          if (!parsed) continue

          obj[config?.idsModel || `${singular(model)}_ids`] = JSON.parse?.(value)?.map((item: any) => item.id)

          if (config?.includeObject) {
            obj[model] = JSON.parse?.(value)
          }

          continue
        }

        if (value && type === 'multi_search_selector') {
          obj[model] = JSON.parse?.(value)

          continue
        }

        obj[processedModel] = value
      }

      res.push(obj)
    }

    return JSON.parse(JSON.stringify(res))
  }

  const addDataRows = (newRows: any) => {
    if (!spreadsheet?.hotInstance) return

    const rawData = spreadsheet.hotInstance.getData()
    const rowsCount = size(rawData)

    const changes: any = []

    let nextAvailableRow = 0

    for (let y = rowsCount; y >= 0; y--) {
      const isRowEmpty = spreadsheet.hotInstance.isEmptyRow(y)

      if (!isRowEmpty) {
        nextAvailableRow = y + 1
        break
      }
    }

    for (const newRow of newRows) {
      const formatted: any = {}

      for (const column of columns) {
        const value = get(newRow, column.model, '')

        changes.push([nextAvailableRow, column.model, value])
      }

      nextAvailableRow++
    }

    spreadsheet.hotInstance.setDataAtRowProp(changes)
  }

  const formatInitialData = (data: any) => {
    if (!data) return

    const res: any = []

    for (const row of data) {
      const formatted: any = {}

      for (const column of columns) {
        const { type, model } = column

        if (!model) continue

        const value = get(row, column.model, '') || column?.config?.defaultValue

        if (!isDefined(value)) continue

        let parsed = value

        if (['object_selector', 'multi_object_selector', 'multi_search_selector'].includes(type)) {
          parsed = JSON.stringify(value)
        }

        formatted[model] = parsed
      }

      res.push(formatted)
    }

    return res
  }

  if (!columns) throw new Error('Spreadsheet columns are required in useSpreadsheet hook')

  return {
    addDataRows,
    cancel,
    clear,
    columns,
    edit,
    getData,
    getRawData,
    getSpreadsheet: setSpreadsheet,
    isEditable,
    manualRowDelete,
    selectedRowIndexes,
    setSelectedRowIndexes,
    spreadsheet,
    formatInitialData,
  }
}
