import React from 'react'
import { tint } from 'polished'
import { useLocation, useHistory } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom-v5-compat'
import { useMedia } from 'use-media'
import { useQueryClient } from 'react-query'
import * as Popover from '@radix-ui/react-popover'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { EVENT_CATEGORIES } from '../../utils/constants'
import { useSettings } from '../../hooks/useSettings'
import { invalidateQueries, useUpdate, useDelete } from '../../hooks/useNewAPI'
import { usTime, usTimeShort, usDate, countWord, titleCase, sleep } from '../../utils/functions'
import { get } from '../../modules/api/requests'

import Avatar from '../Avatar'
import Button from '../Button'
import ConfirmDialog from '../Dialogs/ConfirmDialog'
import DeleteDialog from '../Dialogs/DeleteDialog'
import EventStatus from '../Statuses/EventStatus'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Markup from '../Markup'
import ObjectSelector from '../Forms/Selectors/Object/ObjectSelector'
import Permission from '../Permission'
import SummonOverlay from '../SummonOverlay'
import URLInput from '../Forms/URLInput'

import { CalendarContext } from './context'
import { EventAttendanceNotesOverlay } from '../Overlays/pages/Calendar/EventAttendanceNotesOverlay'
import { EventAttendanceWorksheetOverlay } from '../Overlays/pages/Calendar/EventAttendanceWorksheetOverlay'
import { EventOutcomesAddMore, OUTCOMES_ADD_MORE } from '../Overlays/pages/Calendar/EventOutcomes'

type Props = {
  event: any
  link?: string
  location?: any
  onClick?: any
  timezone?: any
  useV6Router?: boolean
  useAttendance?: boolean
  trigger?: any
}

const CalendarEvent = ({ event, link, onClick, useV6Router, useAttendance = true, trigger }: Props) => {
  const isDesktop = useMedia({ minWidth: 1024 })

  const context: any = React.useContext(CalendarContext)
  const triggerRef = React.useRef(null)

  const isLargeCard = context?.view === 'agenda' || context?.mode === 'weeks'

  const { tenant, timezone, isPortal } = useSettings()
  const location = useLocation()
  const history = useHistory()
  const navigate = useV6Router ? useNavigate() : history.push

  const isActive = location.pathname === link
  const container = document?.getElementById?.('portal-default')

  const [isOpen, setIsOpen] = React.useState(false)

  const queryClient = useQueryClient()
  const queryKey = ['event', event?.id]

  const { mutate: activateEvent, isLoading: isActivating }: any = useUpdate({
    name: queryKey,
    url: `/events/${event?.id}/activate`,
    invalidate: 'events',
    onSuccess: async ({ data }: any) => {
      queryClient.setQueryData(queryKey, data)

      await sleep(2000)

      invalidateQueries(null, 'events')
    },
  })

  const { mutate: cancelEvent, isLoading: isCancelling }: any = useUpdate({
    name: queryKey,
    url: `/events/${event?.id}/cancel`,
    invalidate: 'events',
    onSuccess: async ({ data }: any) => {
      queryClient.setQueryData(queryKey, data)

      await sleep(2000)

      invalidateQueries(null, 'events')
    },
  })

  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useDelete({
    name: queryKey,
    url: `/events`,
    invalidate: 'events',
    onSuccess: async () => {
      await sleep(2000)

      invalidateQueries(null, 'events')
    },
  })

  const rootClasses = clsx(
    'select-none flex items-center flex-nowrap min-w-0 truncate overflow-hidden text-[0.85rem] text-text -mx-1 !mt-[3px] px-1.5 rounded-[3px] hover:bg-hover cursor-pointer py-[0.3rem] mq1024:py-[0.12rem]',
    `status-${event?.status}`,
    isActive && 'is-active text-text',
  )

  const status = event?.status
  const staffCount = size(event?.employees)
  const hasStaff = staffCount > 0

  const isHealthcare = tenant?.category === 'healthcare_facility' || tenant?.category === 'healthcare_practice'
  const isOutcomesEnabled = isHealthcare && event?.category && OUTCOMES_ADD_MORE.hasOwnProperty(event?.category)
  const eventOutcomesNo = size(event?.event_outcomes)
  const hasEventOutcomes = eventOutcomesNo > 0

  const color = event?.color ? event?.color : status === 'completed' ? COLORS.green : status === 'cancelled' ? COLORS.red : COLORS.blue
  const background = status === 'completed' ? tint(0.88, COLORS.green) : status === 'cancelled' ? tint(0.88, COLORS.red) : tint(0.88, color)

  const { current: collisionBoundary } = React.useRef([document.getElementById('app')])

  React.useEffect(() => {
    if (isActive) {
      setIsOpen(false)
    }
  }, [isActive])

  return (
    <>
      <Popover.Root openDelay={10} closeDelay={50} open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger asChild>
          {trigger || (
            <div
              ref={triggerRef}
              className={rootClasses}
              style={{
                background: isActive ? 'white' : background,
                boxShadow: isActive || isOpen ? `0 0 0 2px ${color}` : `inset 0 0 0 1px ${COLORS.divider}`,
              }}
              onClick={() => {
                onClick?.()

                if (isOpen && !isActive && link) {
                  useV6Router ? navigate(link) : history.push(link)
                }
              }}
              onDoubleClick={() => {
                if (!link) return

                useV6Router ? navigate(link) : history.push(link)
              }}
            >
              {isLargeCard ? (
                <div className="w-full">
                  <Flex centerY gap="0.25rem">
                    <div className="tabular-nums mr-1.5 -muted opacity-80 font-[400]">
                      {event?.is_all_day ? 'All day' : `${usTime(event?.start_time, timezone)} – ${usTime(event?.end_time, timezone)}`}
                    </div>

                    {event?.status !== 'active' && <EventStatus small status={event?.status} />}
                  </Flex>

                  <div className="flex flex-nowrap items-center">
                    <div className="w-2 h-2 rounded-full mr-1.5 flex-[0_0_auto]" style={{ background: color }} />
                    <div className="text-[0.9rem] font-[600]">{event?.title}</div>
                  </div>

                  <Flex gap="0.5rem" className="whitespace-normal">
                    {event?.share_with_client_portal && (
                      <>
                        <div className="flex items-center">
                          <Glyph glyph="portal" size={14} className="mr-0.5" />
                          Shared with Client Portal
                        </div>
                        <div className="opacity-80">•</div>
                      </>
                    )}

                    <div>{EVENT_CATEGORIES?.[event?.category]}</div>

                    {hasEventOutcomes && <div>{countWord('Documentation', eventOutcomesNo)}</div>}
                  </Flex>
                </div>
              ) : (
                <>
                  <div className="w-2 h-2 rounded-full mr-1.5 flex-[0_0_auto]" style={{ background: color }} />
                  <div className="tabular-nums mr-1.5 -muted opacity-80 font-[400]">
                    {event?.is_all_day ? 'All day' : usTimeShort(event?.start_time, timezone)}
                  </div>
                  <div className="truncate min-w-0 font-[500] flex-[1_1_auto]">{event?.title}</div>
                  {isOutcomesEnabled && <Glyph glyph="star" color={COLORS.text} size={13} className="mr-0.5" />}
                  {event?.share_with_client_portal && <Glyph glyph="portal" size={14} />}
                  {event?.status === 'completed' && <Glyph glyph="tick_circle" color={isActive ? 'green' : undefined} size={14} />}
                </>
              )}
            </div>
          )}
        </Popover.Trigger>

        <Popover.Portal container={container}>
          <Popover.Content
            asChild
            avoidCollisions
            sideOffset={6}
            collisionBoundary={collisionBoundary}
            align={isDesktop ? 'center' : undefined}
            side={isDesktop ? 'left' : undefined}
            onPointerDownOutside={(e) => {
              if (triggerRef?.current?.contains?.(e.target)) {
                e.preventDefault()
                e.stopPropagation()
                return
              }
            }}
            className="max-h-[--radix-popper-available-height,40vh] overflow-y-auto"
          >
            <div
              className={`grid gap-2 grid-cols-[100%] bg-white border-solid border-divider shadow-soft-3 px-2 pt-2 rounded-[5px] w-[280px] mq480:!w-[300px] text-[0.9rem] outline-none ${
                isDesktop ? 'animate-shortSlideInFromLeft' : 'animate-shortSlideInFromBottom'
              }`}
              style={{ borderLeft: `4px solid ${color}` }}
            >
              <div className="flex items-center flex-nowrap items-center truncate">
                <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                  <div className="w-[0.65rem] h-[0.65rem] rounded-full flex-[0_0_auto]" style={{ background: color }} />
                </div>
                <h4 className="text-[1rem] font-[600] flex-[1_1_auto] truncate">{event?.title}</h4>
                {event?.status !== 'active' && (
                  <EventStatus small glyph={event?.status === 'completed' ? 'tick_circle' : undefined} status={event?.status} />
                )}
              </div>

              {EVENT_CATEGORIES?.[event?.category] && (
                <div className="flex items-center flex-nowrap items-center truncate">
                  <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                    <Glyph glyph="selector" size={16} color={COLORS.textMuted} />
                  </div>
                  <div>
                    {EVENT_CATEGORIES?.[event?.category]}
                    {hasEventOutcomes && <span> ({countWord('Documentation', eventOutcomesNo)})</span>}
                  </div>
                </div>
              )}

              <div className="flex items-center flex-nowrap items-center truncate">
                <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                  <Glyph glyph="date" size={16} color={COLORS.textMuted} />
                </div>
                <div className="tabular-nums">{usDate(event?.started_at, timezone)}</div>
              </div>

              <div className="flex items-center flex-nowrap items-center truncate">
                <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                  <Glyph glyph="time" size={16} color={COLORS.textMuted} />
                </div>
                <div className="tabular-nums">
                  {event?.is_all_day ? 'All day' : `${usTime(event?.start_time, timezone)} - ${usTime(event?.end_time, timezone)}`}
                </div>
              </div>

              {event?.share_with_client_portal && (
                <div className="flex items-center flex-nowrap items-center truncate">
                  <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                    <Glyph glyph="portal" size={16} color={COLORS.textMuted} />
                  </div>

                  <div className="font-[400]">Shared with Client Portal</div>
                </div>
              )}

              <div className="flex items-center flex-nowrap items-center truncate">
                <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                  <Glyph glyph="user_group" size={16} color={COLORS.textMuted} />
                </div>

                <div className="font-[600]">
                  Event Type:{' '}
                  <span className="font-[400]">
                    {event?.meeting_type === 'one_to_one' ? 'One-to-One' : event?.meeting_type === 'group_meeting' ? 'Group Event' : '–'}
                  </span>
                </div>
              </div>

              <div className="flex items-center flex-nowrap items-center truncate">
                <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                  <Glyph glyph="user_group" size={16} color={COLORS.textMuted} />
                </div>

                <div className="font-[600]">
                  Staff: <span className="font-[400]">{hasStaff ? countWord('Staff Members', staffCount) : '–'}</span>
                </div>
              </div>

              {hasStaff && (
                <div className="pl-8 grid gap-2">
                  {event?.employees?.map?.((employee: any, index: number) => {
                    if (index > 2) return null

                    return (
                      <div className="flex flex-nowrap items-center">
                        <Avatar src={employee.avatar} size={18} initials={employee.name} className="mr-1" />
                        <div>{employee.name}</div>
                      </div>
                    )
                  })}

                  {staffCount > 3 && <div>+{staffCount - 3} more</div>}
                </div>
              )}

              {event?.meeting_place && event?.meeting_place !== 'none' && (
                <>
                  <div className="flex items-center flex-nowrap items-center truncate">
                    <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                      <Glyph glyph="map" size={16} color={COLORS.textMuted} />
                    </div>
                    <div className="font-[600]">
                      Meeting Place:{' '}
                      <span className="font-[400]">{event?.meeting_place === 'none' ? '–' : titleCase(event?.meeting_place)}</span>
                    </div>
                  </div>

                  <div className="pl-8 grid gap-2">
                    {event?.meeting_place === 'online' && <URLInput isCompact isEditable={false} value={event?.meeting_url} />}

                    {event?.meeting_place === 'property' && (
                      <>
                        <ObjectSelector
                          disableLink
                          isEditable={false}
                          icon="properties"
                          type="properties"
                          value={event?.house}
                          selectTitle={(data: any) => data?.name}
                        />

                        <ObjectSelector
                          disableLink
                          isEditable={false}
                          icon="rooms"
                          type="property.rooms"
                          value={event?.room}
                          selectTitle={(data: any) => data?.name}
                        />
                      </>
                    )}

                    {event?.meeting_place === 'organization' && (
                      <>
                        <ObjectSelector
                          disableLink
                          isEditable={false}
                          icon="organizations"
                          type="organizations"
                          value={event?.organization}
                          selectTitle={(data: any) => data?.name}
                        />
                      </>
                    )}
                  </div>
                </>
              )}

              {size(event?.imported_houses) > 0 && (
                <>
                  <div className="flex items-center flex-nowrap items-center truncate">
                    <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                      <Glyph glyph="home" size={16} color={COLORS.textMuted} />
                    </div>

                    <div className="font-[600]">
                      Import Clients from: <span className="font-[400]">{countWord('Locations', size(event?.imported_houses))}</span>
                    </div>
                  </div>

                  <div className="pl-8 grid gap-2">
                    {event?.imported_houses?.map?.((house: any) => {
                      return (
                        <div className="flex flex-nowrap items-center">
                          <Avatar src={house.avatar} size={18} initials={house.name} className="mr-1" />
                          <div>{house.name}</div>
                        </div>
                      )
                    })}
                  </div>
                </>
              )}

              {size(event?.imported_programs) > 0 && (
                <>
                  <div className="flex items-center flex-nowrap items-center truncate">
                    <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                      <Glyph glyph="checkbox_input" size={16} color={COLORS.textMuted} />
                    </div>

                    <div className="font-[600]">
                      Import Clients from: <span className="font-[400]">{countWord('Programs', size(event?.imported_programs))}</span>
                    </div>
                  </div>

                  <div className="pl-8 grid gap-2">
                    {event?.imported_programs?.map?.((program: any) => {
                      return (
                        <div className="flex flex-nowrap items-center">
                          <Avatar src={program.avatar} size={18} initials={program.name} className="mr-1" />
                          <div>{program.name}</div>
                        </div>
                      )
                    })}
                  </div>
                </>
              )}

              {event?.public_description && (
                <div className="flex items-center flex-nowrap items-center  min-w-0">
                  <div className="w-8 h-4 flex items-center justify-center flex-[0_0_auto]">
                    <Glyph glyph="info" size={16} color={COLORS.textMuted} />
                  </div>
                  <div className="min-w-0 max-w-[100%] ">
                    <Markup value={event?.public_description} />
                  </div>
                </div>
              )}

              <div className="grid gap-2 pb-1 pt-1 sticky bottom-0 bg-white pb-2">
                {!isPortal && useAttendance && (
                  <Permission permission={isPortal ? false : 'events.edit'}>
                    <AttendanceOverlay eventId={event?.id} />
                  </Permission>
                )}

                {!isPortal && useAttendance && (
                  <SummonOverlay portalType="overlay" overlay={<EventAttendanceNotesOverlay event={event} />}>
                    <Button
                      label={event?.attendance_notes ? 'Edit Attendance Notes' : 'Add Attendance Notes'}
                      size={200}
                      type="default"
                      glyph={event?.attendance_notes ? 'edit' : 'add'}
                    />
                  </SummonOverlay>
                )}

                {!isPortal && isOutcomesEnabled && (
                  <EventOutcomesAddMore category={event?.category} event={event} className="!p-0 !border-none w-full !pr-1 [&>*]:w-full" />
                )}

                <Button
                  as={useV6Router ? Link : undefined}
                  label="View Event Details"
                  icon="calendar"
                  link={link}
                  size={200}
                  permission="events.edit"
                  className="!min-w-[80px] flex-[3_1_auto]"
                />

                {!isPortal && (
                  <Flex stretchChildrenX gap="0.25rem" className="w-full">
                    {event?.status === 'cancelled' && (
                      <Button
                        label="Un-cancel Event"
                        glyph="check"
                        color="green"
                        size={100}
                        isLoading={isActivating}
                        onClick={() => {
                          activateEvent()
                        }}
                        permission="events.actions.activate"
                      />
                    )}

                    {(event?.status === 'active' || event?.status === 'completed') && (
                      <ConfirmDialog
                        title="Cancel Event?"
                        glyph="decline"
                        message="The event will be marked as cancelled so everyone knows it's not happening. Are you sure you want to do this?"
                        onYes={() => {
                          cancelEvent()
                        }}
                        yesLabel="Cancel Event"
                        yesColor="red"
                        noLabel="No, Go Back"
                      >
                        <Button
                          label="Cancel Event"
                          glyph="decline"
                          glyphColor="red"
                          color="text"
                          size={100}
                          isLoading={isCancelling}
                          permission="events.actions.cancel"
                        />
                      </ConfirmDialog>
                    )}

                    <Permission permission="events.delete">
                      <DeleteDialog
                        title="Delete Event?"
                        message="Are you sure you want to delete this event? This action cannot be undone."
                        onYes={() => {
                          deleteAsync(event?.id)
                        }}
                      >
                        <Button label="Delete" glyph="delete" color="red" size={100} isLoading={isDeleting} permission="events.delete" />
                      </DeleteDialog>
                    </Permission>
                  </Flex>
                )}
              </div>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  )
}

const AttendanceOverlay = ({ eventId }: any) => {
  const [event, setEvent] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isAttendanceOpen, setIsAttendanceOpen] = React.useState(false)

  const getEvent = async () => {
    setIsLoading(true)

    const result = await get(`/events/${eventId}`)

    setIsLoading(false)

    if (!result?.data?.id) return

    setEvent(result.data)
    setIsAttendanceOpen(true)
  }

  if (!eventId) return null

  return (
    <>
      {event && (
        <SummonOverlay
          portalType="overlay"
          isOpen={isAttendanceOpen}
          onClose={() => {
            setIsAttendanceOpen(false)
          }}
          overlay={
            <EventAttendanceWorksheetOverlay
              event={event}
              initialData={
                size(event?.event_attendances) > 0
                  ? event?.event_attendances
                  : event?.meeting_type === 'one_to_one'
                  ? [{ resident: event?.resident, status: 'present' }]
                  : event?.residents?.map?.((resident: any) => ({ resident, status: 'present' }))
              }
            />
          }
        />
      )}

      <Button label="Take Attendance" size={200} type="primary" glyph="user_group" onClick={getEvent} isLoading={isLoading} />
    </>
  )
}

export default CalendarEvent
