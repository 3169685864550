import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { useSettings } from '../../hooks/useSettings'

import { titleCase } from '../../utils/functions'

import { FILTERS } from '../Filters/config'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const DocumentationStatusReportDataTable = (props: any) => {
  const { to } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        width: 260,
        id: 'client',
        model: 'client.name',
        disableHide: true,
        disableSort: false,
        type: 'profile',
        formatValue: ({ data }: any) => (
          <MainCell id={data?.client?.id} value={data?.client?.name} avatar={data?.client?.avatar} to={to?.(data?.client)} />
        ),
      },
      {
        title: 'Client Status',
        width: 130,
        id: 'status',
        model: 'status',
        disableSort: false,
        formatValue: ({ data, value }: any) => (value ? titleCase(value) : '–'),
      },
      {
        title: 'Applicant Create Date',
        width: 230,
        id: 'created_at',
        model: 'created_at',
        disableSort: false,
        type: 'date_time',
      },
      {
        title: '1st eVOB Date',
        width: 230,
        id: 'first_evob_at',
        model: 'first_evob_at',
        disableSort: false,
        type: 'date_time',
      },
      {
        title: '1st VOB Call Date',
        width: 230,
        id: 'first_vob_call_at',
        model: 'first_vob_call_at',
        disableSort: false,
        type: 'date_time',
      },
      // {
      //   width: 230,
      //   title: '1st Pre-certification Date',
      //   model: 'first_pre_certification_at',
      //   disableSort: false,
      //   Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
      //   Filter: TableFilter.Date,
      //   filter: 'date',
      // },
      {
        title: 'Accepted Date',
        width: 230,
        id: 'accepted_at',
        model: 'accepted_at',
        disableSort: false,
        type: 'date_time',
      },
      {
        title: 'Admitted Date',
        width: 230,
        id: 'admission_admitted_at',
        model: 'admitted_at',
        disableSort: false,
        type: 'date_time',
      },
      {
        title: '1st Clinical Assessment Date',
        width: 230,
        id: 'first_clinical_assessment_at',
        model: 'first_clinical_assessment_at',
        disableSort: false,
        type: 'date_time',
      },
      {
        title: '1st Medical Assessment Date',
        width: 230,
        id: 'first_medical_assesment_at',
        model: 'first_medical_assesment_at',
        disableSort: false,
        type: 'date_time',
      },
      // {
      //   width: 180,
      //   title: '1st Authorization Date',
      //   model: 'first_authorization_at',
      //   disableSort: false,
      //   Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
      //   Filter: TableFilter.Date,
      //   filter: 'date',
      // },
      // {
      //   width: 230,
      //   title: '1st Concurrent Review Date',
      //   model: 'first_concurrent_review_date',
      //   disableSort: false,
      //   Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
      //   Filter: TableFilter.Date,
      //   filter: 'date',
      // },
      {
        title: '1st Clinical Record Date',
        width: 230,
        id: 'first_clinical_record_at',
        model: 'first_clinical_record_at',
        disableSort: false,
        type: 'date_time',
      },
      {
        title: '1st Medical Record Date',
        width: 230,
        id: 'first_medical_record_at',
        model: 'first_medical_record_at',
        disableSort: false,
        type: 'date_time',
      },
      {
        title: 'Last Clinical Record Date',
        width: 230,
        id: 'last_clinical_record_at',
        model: 'last_clinical_record_at',
        disableSort: false,
        type: 'date_time',
      },
      {
        title: 'Last Medical Record Date',
        width: 230,
        id: 'last_medical_record_at',
        model: 'last_medical_record_at',
        disableSort: false,
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Documentation Status Reports"
      icon="patient_progress_measure"
      columns={columns}
      filtersConfig={FILTERS.live_reports.clients_journey}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="clients_journey"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=clients_journey'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'clients_journey' }} />
        </>
      }
    />
  )
}
