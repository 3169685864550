import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../theme'
import { titleDate, usTime } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Flex from '../Flex'
import RecordQuickLink from '../QuickLinks/RecordQuickLink'
import ResourceQuickLink from '../QuickLinks/ResourceQuickLink'

import agreement from './messages/agreement'
import agreement_template from './messages/agreement_template'
import admission from './messages/admission'
import allergy from './messages/allergy'
import breathalyzer_test from './messages/breathalyzer_test'
import client from './messages/client'
import clinical_note from './messages/clinical_note'
import communicable_disease_test from './messages/communicable_disease_test'
import communication from './messages/communication'
import connection from './messages/connection'
import contact from './messages/contact'
import data_form from './messages/data_form'
import document from './messages/document'
import employee from './messages/employee'
import program_assignment from './messages/program_assignment'
import bed_assignment from './messages/bed_assignment'
import round from './messages/round'
import round_line from './messages/round_line'
import round_template from './messages/round_template'
import permission_template from './messages/permission_template'
import tag_group from './messages/tag_group'
import tag from './messages/tag'
import application_form from './messages/application_form'
import form_variable from './messages/form_variable'
import form_submission from './messages/form_submission'
import form from './messages/form'
import end from './messages/end'
import event from './messages/event'
import event_template from './messages/event_template'
import financial_transaction from './messages/financial_transaction'
import credential from './messages/credential'
import diagnosis from './messages/diagnosis'
import housing_note from './messages/housing_note'
import custom_note from './messages/custom_note'
import custom_note_template from './messages/custom_note_template'
import treatment_plan from './messages/treatment_plan'
import treatment_plan_template from './messages/treatment_plan_template'
import treatment_book_problem from './messages/treatment_book_problem'
import treatment_book_goal from './messages/treatment_book_goal'
import treatment_book_objective from './messages/treatment_book_objective'
import treatment_book_intervention from './messages/treatment_book_intervention'
import peer_note from './messages/peer_note'
import daily_note from './messages/daily_note'
import insurance_evob from './messages/insurance_evob'
import insurance_policy from './messages/insurance_policy'
import insurance_vob from './messages/insurance_vob'
import insurance_new_code from './messages/insurance_new_code'
import insurance_new_fee_schedule from './messages/insurance_new_fee_schedule'
import intake_application from './messages/intake_application'
import med from './messages/med'
import medication_log from './messages/medication_log'
import office from './messages/office'
import bookmark from './messages/bookmark'
import quick_paragraph from './messages/quick_paragraph'
import location_check from './messages/location_check'
import data_export from './messages/data_export'
import organization from './messages/organization'
import phase from './messages/phase'
import phases_resident from './messages/phases_resident'
import report from './messages/report'
import service from './messages/service'
import service_episode from './messages/service_episode'
import task from './messages/task'
import ua_test from './messages/ua_test'
import lab from './messages/lab'
import vital_sign from './messages/vital_sign'
import safety_check from './messages/safety_check'

export const MESSAGES_BY_TRACKABLE_TYPE: any = {
  agreement,
  agreement_template,
  admission,
  allergy,
  breathalyzer_test,
  client,
  clinical_note,
  communicable_disease_test,
  communication,
  connection,
  contact,
  data_form,
  form,
  document,
  employee,
  program_assignment,
  bed_assignment,
  round,
  round_line,
  round_template,
  permission_template,
  tag_group,
  tag,
  application_form,
  form_variable,
  form_submission,
  end,
  event,
  event_template,
  custom_note_template,
  treatment_plan,
  treatment_plan_template,
  treatment_book_problem,
  treatment_book_goal,
  treatment_book_objective,
  treatment_book_intervention,
  financial_transaction,
  credential,
  diagnosis,
  housing_note,
  custom_note,
  peer_note,
  daily_note,
  insurance_evob,
  insurance_policy,
  insurance_vob,
  insurance_new_code,
  insurance_new_fee_schedule,
  intake_application,
  med,
  medication_log,
  office,
  bookmark,
  quick_paragraph,
  location_check,
  data_export,
  organization,
  phase,
  phases_resident,
  report,
  resident: client,
  safety_check,
  service_episode,
  service,
  task,
  ua_test,
  lab,
  vital_sign,
}

const TimelineItem = ({ event, disableLinks, timezone }: any) => {
  if (!(event && event.action_type && event.object_type)) return null

  const Tag = MESSAGES_BY_TRACKABLE_TYPE[event.object_type]
  if (!Tag) return null

  const author = <ResourceQuickLink resource={event.author} disableLink={disableLinks} />
  const record = <RecordQuickLink record={event.trackable} />

  return (
    <Flex nowrap centerY css={styles.root}>
      <div data-test="timeline_item">
        <div css={styles.meta}>
          {event.object_type !== 'end' && `${titleDate(event.triggered_at, timezone)} at ${usTime(event.triggered_at, timezone)}`}
          {event.object_type === 'end' && <Tag event={event} author={author} record={record} disableLink={disableLinks} />}
        </div>
        {event.object_type !== 'end' && <Tag event={event} author={author} record={record} disableLink={disableLinks} />}
      </div>
    </Flex>
  )
}

const styles = {
  root: {
    fontSize: 14,
    marginLeft: '0.75rem',
    paddingLeft: '0.75rem',
    boxShadow: `-1px 0 0 0 ${tint(0.85, COLORS.gray)}`,
    zIndex: 0,
    paddingTop: '0.4rem',
    paddingBottom: '0.6rem',
  },

  meta: {
    fontSize: 13,
    marginBottom: '0.2em',
    position: 'relative',
    color: tint(0.3, COLORS.text),

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-0.75rem',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      flex: '0 0 auto',

      width: 6,
      height: 6,
      borderRadius: '50%',
      background: COLORS.white,
      boxShadow: `0 0 0 1px ${tint(0.75, COLORS.gray)}`,
      zIndex: 1,
    },
  },
}

export default withSettings(TimelineItem)
