import React from 'react'

import { H5 } from '../../components/Typography'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Section from '../../components/Section'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootBTQOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Brief Trauma Questionnaire (BTQ)"
      category="clinical_measurement"
      subcategory="btq"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Introduction"
        headingType="h2"
        scrollview={{
          id: 'introduction',
          name: 'Introduction',
        }}
      >
        <p>
          The BTQ is a brief self- report questionnaire that is derived from the Brief Trauma Interview (Schnurr et al., 1995). (Information
          about the reliability and validity of the BTI is provided in Schnurr et al., 2002). The BTQ was originally designed to assess
          traumatic exposure according to DSM‐IV but specifically asked only about Criterion A.1 (life threat/serious injury) because of the
          difficulty of accurately assessing A.2 (subjective response) in a brief self- report format. Criterion A.2 has been eliminated
          from the PTSD diagnostic criteria in DSM- 5, so the BTQ provides a complete assessment of Criterion A.
        </p>

        <p>
          <div>
            The questionnaire may be used to determine whether an individual has had an event that meets the A Criterion, or to determine
            the different types of Criterion A events an individual has experienced. In either case, exposure to anevent should be scored as
            positive if a respondent says yes to either:
          </div>

          <ul className="mt-2">
            <li>life threat or serious injury for events 1- 3 and 5- 7;</li>
            <li>life threat for event 4;</li>
            <li>serious injury for event 8, or;</li>
            <li>“Has this ever happened to you?” for events 9 and 10.</li>
          </ul>
        </p>
      </Section>

      <Divider />

      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>
          <b>The following questions ask about events that may be extraordinarily stressful or disturbing for almost everyone.</b> Please
          circle “Yes” or “No” to report what has happened to you.
        </p>

        <p>
          <b>If you answer “Yes” for an event</b>, please answer any additional questions that are listed below: (1) whether you thought
          your life was in danger or you might be seriously injured; and (2) whether you were seriously injured.
        </p>

        <p>
          <b>If you answer “No” for an event</b>, go on to the next event.
        </p>
      </Section>

      <Divider />

      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <p>
          <b>Version date: </b> 1999
        </p>

        <p>
          <b>Reference: </b> Schnurr, P., Vielhauer, M., Weathers, F., & Findler, M. (1999). The Brief Trauma Questionnaire (BTQ)
          [Measurement instrument]. Available from http://www.ptsd.va.gov
        </p>

        <p>
          <b>URL: </b>{' '}
          <a
            href="http://www.ptsd.va.gov/professional/as-sessment/te-measures/brief_trauma_question-naire_btq.asp"
            rel="noopener noreferrer"
            target="_blank"
          >
            http://www.ptsd.va.gov/professional/as-sessment/te-measures/brief_trauma_question-naire_btq.asp
          </a>
        </p>
      </Section>
    </DataFormOverlay>
  )
}

const QUESTIONS = [
  'Have you ever served in a war zone, or have you ever served in a noncombat job that exposed you to war-related casualties (for example, as a medic or on graves registration duty?)',
  'Have you ever been in a serious car accident, or a serious accident at work or somewhere else?',
  'Have you ever been in a major natural or technological disaster, such as a fire, tornado, hurricane, flood, earthquake, or chemical spill?',
  'Have you ever had a life-threatening illness such as cancer, a heart attack, leukemia, AIDS, multiple sclerosis, etc.?',
  'Before age 18, were you ever physically punished or beaten by a parent, caretaker, or teacher so that: you were very frightened; or you thought you would be injured; or you received bruises, cuts, welts, lumps or other injuries?',
  'Not including any punishments or beatings you already reported in Question 5, have you ever been attacked, beaten, or mugged by anyone, including friends, family members or strangers?',
  'Has anyone ever made or pressured you into having some type of unwanted sexual contact? Note: By sexual contact we mean any contact between someone else and your private parts or between you and some else’s private parts',
  'Have you ever been in any other situation in which you were seriously injured, or have you ever been in any other situation in which you feared you might be seriously injured or killed?',
  'Has a close family member or friend died violently, for example, in a serious car crash, mugging, or attack? ',
  'Have you ever witnessed a situation in which someone was ser io us ly injured or killed, or have you ever witnessed a situation in which you feared someone would be seriously injured or killed? Note: Do not answer “yes” for any event you already reported in Questions 1-9',
]

const Questionnaire = (props: any) => {
  return (
    <>
      <>
        {QUESTIONS.map((q, index) => {
          const count = index + 1
          return (
            <Grid gap="1rem" key={q}>
              <H5>
                {count}. {q}
              </H5>

              <YesNoRadioGroup label="Has this ever happened to you?" model={`data.questionnaire.q${count}`} />

              {![8, 9, 10].includes(count) && (
                <ContextShow when={`data.questionnaire.q${count}`} is={true}>
                  <YesNoRadioGroup
                    label="If the event happened, did you think your life was in danger or you might be seriously injured?"
                    model={`data.questionnaire.q${count}_life_threat`}
                  />
                </ContextShow>
              )}

              {![4, 10].includes(count) && (
                <ContextShow when={`data.questionnaire.q${count}`} is={true}>
                  <YesNoRadioGroup label="If the event happened, were you injured?" model={`data.questionnaire.q${count}_injured`} />
                </ContextShow>
              )}

              <Divider />
            </Grid>
          )
        })}
      </>
    </>
  )
}

export const BTQOverlay = withOverlayError(RootBTQOverlay)
