import React from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'

import { CSSRSScale } from '../../../../../constructs/DataFormsBuilder/components/CSSRSScale'

const RiskFactors = () => (
  <FormSection layout="vertical" maxWidth={570}>
    <CSSRSScale />

    <CheckboxGroup label="C-SSRS Suicidal Ideation Severity" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
      <Checkbox
        reverse
        label="1. Wish to be dead"
        description="Has the client wished they were dead or wished they could go to sleep and not wake up?"
        model="data.risk_factors.suicidal_ideation_severity.wish_to_be_dead"
      />
      <Checkbox
        reverse
        label="2. Current suicidal thoughts"
        description="Has the client actually had any thoughts of killing themselves?"
        model="data.risk_factors.suicidal_ideation_severity.current_suicidal_thoughts"
      />
      <Checkbox
        reverse
        label="3.	Suicidal thoughts w/ Method (w/no specific Plan or Intent or act)"
        description="Has the client been thinking about how they might do this?"
        model="data.risk_factors.suicidal_ideation_severity.suicidal_thoughts"
      />
      <Checkbox
        reverse
        label="4.	Suicidal Intent without Specific Plan"
        description="Has the client had these thoughts and had some intention of acting on them?"
        model="data.risk_factors.suicidal_ideation_severity.suicidal_intent"
      />
      <Checkbox
        reverse
        label="5.	Intent with Plan"
        description="Has the client started to work out or worked out the details of how to kill themselves? Do they intend to carry out this plan?"
        model="data.risk_factors.suicidal_ideation_severity.intent_with_plan"
      />
    </CheckboxGroup>

    <RadioGroup
      model="data.risk_factors.suicidal_behavior"
      label="6. C-SSRS Suicidal Behavior: Has the client ever done anything, started to do anything, or prepared to do anything to end their life?"
      description="Examples: 
      Collecting pills for the purpose of overdose.
      Obtaining a gun or other lethal means. Giving away valuables or personal belongings.
      Writing a will or a suicide note.
      Holding a gun or engaging in self-harm but changing one's mind or having the weapon taken away.
      Going to a high place with the intention of jumping but deciding not to.
      Actively attempting suicide through the ingestion of pills, attempting to shoot oneself, self-cutting, or attempting hanging. etc."
      layout="horizontal-dense"
    >
      <Radio label="Yes" value={true} />
      <Radio label="No" value={false} />
    </RadioGroup>

    <ContextShow when="data.risk_factors.suicidal_behavior" is={true}>
      <RadioGroup
        model="data.risk_factors.suicidal_behavior_past_three_months"
        label="Was it within the past 3 months?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>
    </ContextShow>

    <CheckboxGroup label="Current and Past Psychiatric Dx" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
      <Checkbox label="Mood disorder" model="data.risk_factors.psychiatric_dx.mood_disorder" />
      <Checkbox label="Psychotic disorder" model="data.risk_factors.psychiatric_dx.psychotic_disorder" />
      <Checkbox label="Alcohol/substance abuse disorders" model="data.risk_factors.psychiatric_dx.alcohol_substance_abuse_disorders" />
      <Checkbox label="PTSD" model="data.risk_factors.psychiatric_dx.ptsd" />
      <Checkbox label="ADHD" model="data.risk_factors.psychiatric_dx.adhd" />
      <Checkbox label="TBI" model="data.risk_factors.psychiatric_dx.tbi" />
      <Checkbox
        label="Cluster B Personality disorders or traits (i.e., Borderline, Antisocial, Histrionic, Narcissistic)"
        model="data.risk_factors.psychiatric_dx.cluster_b_personality_disorders"
      />
      <Checkbox
        label="Conduct problems (antisocial behavior, aggression, impulsivity)"
        model="data.risk_factors.psychiatric_dx.conduct_problems"
      />
      <Checkbox label="Recent onset" model="data.risk_factors.psychiatric_dx.recent_onset" />
    </CheckboxGroup>

    <CheckboxGroup label="Presenting Symptoms" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
      <Checkbox label="Anhedonia" model="data.risk_factors.presenting_symptoms.anhedonia" />
      <Checkbox label="Impulsivity" model="data.risk_factors.presenting_symptoms.impulsivity" />
      <Checkbox label="Hopelessness or despair" model="data.risk_factors.presenting_symptoms.hopelessness_or_despair" />
      <Checkbox label="Anxiety and/or panic" model="data.risk_factors.presenting_symptoms.anxiety_and_or_panic" />
      <Checkbox label="Insomnia" model="data.risk_factors.presenting_symptoms.insomnia" />
      <Checkbox label="Command hallucinations" model="data.risk_factors.presenting_symptoms.command_hallucinations" />
      <Checkbox label="Psychosis" model="data.risk_factors.presenting_symptoms.psychosis" />
    </CheckboxGroup>

    <CheckboxGroup label="Family History" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
      <Checkbox label="Suicide" model="data.risk_factors.family_history.suicide" />
      <Checkbox label="Suicidal behavior" model="data.risk_factors.family_history.suicidal_behavior" />
      <Checkbox label="Axis I psychiatric diagnoses requiring hospitalization" model="data.risk_factors.family_history.axis" />
    </CheckboxGroup>

    <CheckboxGroup label="Precipitants / Stressors" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
      <Checkbox
        label="Triggering events leading to humiliation, shame, and/or despair (e.g. Loss of relationship, financial or health status) (real or anticipated)"
        model="data.risk_factors.precipitants.triggering_events"
      />
      <Checkbox
        label="Chronic physical pain or other acute medical problem (e.g. CNS disorders)"
        model="data.risk_factors.precipitants.chronic_physical_pain"
      />
      <Checkbox label="Sexual/physical abuse" model="data.risk_factors.precipitants.sexual_physical_abuse" />
      <Checkbox label="Substance intoxication or withdrawal" model="data.risk_factors.precipitants.substance_intoxication" />
      <Checkbox label="Pending incarceration or homelessness" model="data.risk_factors.precipitants.pending_incarceration" />
      <Checkbox label="Legal problems" model="data.risk_factors.precipitants.legal_problems" />
      <Checkbox label="Inadequate social supports" model="data.risk_factors.precipitants.inadequate_social_supports" />
      <Checkbox label="Social isolation" model="data.risk_factors.precipitants.social_isolation" />
      <Checkbox label="Perceived burden on others" model="data.risk_factors.precipitants.perceived_burden" />
    </CheckboxGroup>

    <CheckboxGroup label="Change in treatment" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
      <Checkbox label="Recent inpatient discharge" model="data.risk_factors.change_in_treatment.recent_inpatient_discharge" />
      <Checkbox
        label="Change in provider or treatment (i.e., medications, psychotherapy, milieu)"
        model="data.risk_factors.change_in_treatment.change_in_provider_or_treatment"
      />
      <Checkbox
        label="Hopeless or dissatisfied with provider or treatment"
        model="data.risk_factors.change_in_treatment.hopeless_or_dissatisfied"
      />
      <Checkbox
        label="Non-compliant or not receiving treatment"
        model="data.risk_factors.change_in_treatment.non_compliant_or_not_receiving_treatment"
      />
    </CheckboxGroup>

    <CheckboxGroup label="Access to lethal methods" trueIcon="check" falseIcon="cross" falseStyle="faded">
      <Checkbox
        label="Ask specifically about presence or absence of a firearm in the home or ease of accessing"
        model="data.risk_factors.access_to_lethal_methods"
      />
    </CheckboxGroup>

    <SmartTextarea useDictation label="Notes" model="data.risk_factors.notes" />
  </FormSection>
)

export default RiskFactors
