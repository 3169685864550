import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const AgreementsReportDataTable = (props: any) => {
  const { to } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} avatar={data.avatar} to={to?.(data)} />,
        disableHide: true,
        disableSort: false,
      },
      {
        title: 'Client',
        width: 180,
        id: 'reference',
        model: 'reference.name',
        type: 'profile',
        disableSort: false,
      },
      {
        title: 'Staff Signee',
        width: 180,
        id: 'cosigner',
        model: 'cosigner.name',
        type: 'profile',
        disableSort: false,
      },
      {
        title: 'Status',
        width: 180,
        id: 'status',
        model: 'status',
        disableSort: false,
      },
      {
        title: '# Signees',
        width: 180,
        id: 'contract_count',
        model: 'contract_count',
        disableSort: false,
      },
      {
        title: 'Added By',
        width: 180,
        id: 'author',
        model: 'author.name',
        type: 'profile',
        disableSort: false,
      },
      {
        title: 'Review Cycle',
        width: 140,
        id: 'review_cycle',
        model: 'review_cycle',
        disableSort: false,
      },
      {
        title: 'Effective Date',
        width: 200,
        id: 'dated_at',
        model: 'dated_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Expired At',
        width: 200,
        id: 'expired_at',
        model: 'expired_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Last Review Date',
        width: 200,
        id: 'reviewed_at',
        model: 'reviewed_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Last Updated',
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        type: 'date_time',
        disableSort: false,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Agreements"
      icon="legal_agreement_alt"
      columns={columns}
      filtersConfig={FILTERS.live_reports.agreements}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="agreements"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=agreements'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'agreements' }} />
        </>
      }
    />
  )
}
