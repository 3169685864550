import React from 'react'
import { DateTime } from 'luxon'
import compact from 'lodash/compact'
import groupBy from 'lodash/groupBy'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import { formatURL, usDate } from '../../../utils/functions'
import { useOverlay } from '../../../hooks/useOverlay'
import { useGet } from '../../../hooks/useNewAPI'

import Card from '../../../components/Card'
import CardTreeItem from '../../../components/CardTreeItem'
import Button from '../../../components/Button'
import Divider from '../../../components/Divider'
import DataList from '../../../components/DataList'
import Nav from '../../../components/Nav'
import Overlay from '../../../components/Overlay'
import OverlayLoader from '../../../components/OverlayLoader'
import PageGrid from '../../../components/PageGrid'
import ScrollMenu from '../../../components/ScrollMenu'
import ScrollView from '../../../components/ScrollView'
import Section from '../../../components/Section'
import Status from '../../../components/Status'

import { SEX_ASSIGNED_AT_BIRTH_HASH, CLAIMMD_SERVICE_CODES_HASH, CLAIMMD_SERVICE_CODE_GROUPS_HASH } from '../../../utils/constants'
import { FormSection } from '../../../components'

const formatDate = (date: string) => {
  if (!date) return null

  const parsed = DateTime.fromFormat(date, 'yyyyMMdd')
  return usDate(parsed)
}

export const NewClaimMD = ({ data, contentAfter }: any) => {
  const [openItems, setOpenItems]: any = React.useState([])

  const summary = data?.clearinghouse_result?.elig || data?.clearinghouse_result
  const benefit = summary?.benefit || []

  const groupedBenefits = groupBy(benefit, 'benefit_description')
  const sortedDescriptions = sortBy(Object.keys(groupedBenefits))

  const isSomeOpen = size(openItems) >= 1

  const toggleAll = () => {
    if (isSomeOpen) {
      setOpenItems([])
    } else {
      setOpenItems(Object.keys(groupedBenefits))
    }
  }

  if (!data) return null

  const isClearinghouseError = data?.clearinghouse_status === 'error'

  return (
    <>
      <ScrollView>
        <PageGrid scroll breakpoint={3} className="overlay-page-grid flex-[1_1_auto]">
          <Nav
            top="0"
            breakpoint={3}
            title={' '}
            headingSize={300}
            desktopProps={{ title: null }}
            className="!bg-white shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!shadow-none [&_header]:!shadow-transparent [&_header]:!border-transparent"
          >
            <div className="opacity-80 text-[0.9rem] uppercase font-[700] tracking-[1px] mb-1">Table of Contents</div>
            <ScrollMenu />
          </Nav>

          <Overlay.Content>
            <Section
              title="Eligibility Check"
              scrollview={{
                id: 'eligibility_check',
                name: 'Eligibility Check',
              }}
              // aside={
              //   <SummonOverlay overlay={<PreviewOverlay id={data.id} />}>
              //     <Button label="Preview" size={100} display="inline-flex" type="default" glyph="view" />
              //   </SummonOverlay>
              // }
            >
              <FormSection maxWidth="100%">
                <Card className="px-4 py-2">
                  <DataList isCompact>
                    <DataList.Title title="Policy Holder Details" />
                    <DataList.Item label="First Name" value={data?.insured_first_name} />
                    <DataList.Item label="Middle Name" value={data?.insured_middle_name} />
                    <DataList.Item label="Last Name" value={data?.insured_last_name} />
                    <DataList.Item label="Date of Birth" value={usDate(data?.insured_dob)} />
                    <DataList.Item label="Sex Assigned at Birth" value={SEX_ASSIGNED_AT_BIRTH_HASH[data?.insured_sex]} />
                    <DataList.Item label="Insurance Payer" value={data?.insurance_local_payer?.name} />
                    <DataList.Item label="Insurance Member ID (Policy Number)" value={data?.insured_policy_number} />
                  </DataList>
                </Card>

                {data.relationship === 'client_is_dependent' && (
                  <Card className="px-4 py-2">
                    <DataList isCompact>
                      <DataList.Title title="Client Details" />
                      <DataList.Item label="First Name" value={data?.client_first_name} />
                      <DataList.Item label="Middle Name" value={data?.client_middle_name} />
                      <DataList.Item label="Last Name" value={data?.client_last_name} />
                      <DataList.Item label="Date of Birth" value={usDate(data?.client_dob)} />
                      <DataList.Item label="Sex Assigned at Birth" value={SEX_ASSIGNED_AT_BIRTH_HASH[data?.client_sex]} />
                    </DataList>
                  </Card>
                )}

                <Card className="px-4 py-2">
                  <DataList isCompact>
                    <DataList.Title title="Clearinghouse Details" />
                    <DataList.Item label="Provider" value={data?.credential?.credential} />
                    <DataList.Item
                      label="Service Codes Groups"
                      value={
                        <>
                          {data?.service_codes_config?.groups?.map((o: any) => CLAIMMD_SERVICE_CODE_GROUPS_HASH[o]?.name)?.join(', ') ||
                            '–'}
                        </>
                      }
                    />
                    <DataList.Item
                      label="Service Codes"
                      value={
                        data?.service_codes_config?.codes?.map((o: any) => `${CLAIMMD_SERVICE_CODES_HASH[o]?.name} (${o})`)?.join(', ') ||
                        '–'
                      }
                    />
                  </DataList>
                </Card>
              </FormSection>
            </Section>

            <Divider />

            <Section
              title="Results"
              scrollview={{
                id: 'insurance_details',
                name: 'Insurance Details',
              }}
              aside={
                <Button
                  onClick={toggleAll}
                  label={isSomeOpen ? 'Close All' : 'Open All'}
                  glyph={isSomeOpen ? 'close_all_rows' : 'open_all_rows'}
                  display="inline-flex"
                  type="link"
                  size={100}
                />
              }
            >
              <Card className="px-4 py-2">
                <DataList isCompact>
                  {isClearinghouseError ? (
                    <>
                      <DataList.Item label="Clearinghouse Status" value="Error" />
                      <DataList.Item label="Clearinghouse Message" value={data.clearinghouse_error_message.split('.').join('\n')} />
                    </>
                  ) : (
                    <>
                      <DataList.Item label="Policy #" value={summary?.ins_number} />
                      <DataList.Item label="Group #" value={summary?.group_number} />
                      <DataList.Item label="Plan #" value={summary?.plan_number} />
                      <DataList.Item label="Insured DOB" value={formatDate(summary?.ins_dob)} />
                      <DataList.Item label="Insured Gender" value={summary?.ins_sex} />
                      <DataList.Item
                        label="Insured Address"
                        value={`${summary?.ins_addr_1}, ${summary?.ins_city}, ${summary?.ins_state}, ${summary?.ins_zip}`}
                      />
                      {summary?.plan_begin_date && (
                        <DataList.Item
                          label="Plan Begin"
                          value={summary?.plan_begin_date
                            .split('-')
                            .map((date: any) => formatDate(date))
                            .join(' – ')}
                        />
                      )}
                    </>
                  )}
                </DataList>
              </Card>
            </Section>

            <div className="-mt-2 pb-8">
              {sortedDescriptions.map((description) => {
                const benefits = groupedBenefits[description]

                return (
                  <Section
                    scrollview={{
                      id: description,
                      name: description,
                    }}
                    key={description}
                    className="!my-1"
                  >
                    <CardTreeItem isOpen={openItems.includes(description)} title={description}>
                      <div className="grid gap-4 py-4">
                        <BenefitList benefits={benefits} />
                      </div>
                    </CardTreeItem>
                  </Section>
                )
              })}
            </div>

            {contentAfter}
          </Overlay.Content>
        </PageGrid>
      </ScrollView>
    </>
  )
}

const BenefitList = ({ benefits }: any) => {
  const sections = React.useMemo(() => {
    let activeCoverage = null

    const individual: any = []
    const individualInPlan: any = []
    const individualOutOfPlan: any = []

    const family: any = []
    const familyInPlan: any = []
    const familyOutOfPlan: any = []

    for (const benefit of benefits) {
      const { benefit_coverage_code, inplan_network, benefit_level_code } = benefit

      if (benefit_coverage_code === '1' || benefit_coverage_code === 'U') {
        activeCoverage = benefit
        continue
      }

      if (benefit_level_code === 'IND') {
        if (inplan_network === 'W') {
          individual.push(benefit)
          continue
        }

        if (inplan_network === 'Y') {
          individualInPlan.push(benefit)
          continue
        }

        if (inplan_network === 'N') {
          individualOutOfPlan.push(benefit)
          continue
        }
      }

      if (benefit_level_code === 'FAM') {
        if (inplan_network === 'W') {
          family.push(benefit)
          continue
        }

        if (inplan_network === 'Y') {
          familyInPlan.push(benefit)
          continue
        }

        if (inplan_network === 'N') {
          familyOutOfPlan.push(benefit)
          continue
        }
      }
    }

    return { activeCoverage, individual, individualInPlan, individualOutOfPlan, family, familyInPlan, familyOutOfPlan }
  }, [benefits])

  const { activeCoverage, individual, individualInPlan, individualOutOfPlan, family, familyInPlan, familyOutOfPlan } = sections

  return (
    <>
      {activeCoverage && <ActiveCoverageSection data={activeCoverage} />}

      <CoverageSection title="Individual" color="blue" data={individual} />

      {size(individualInPlan) > 0 && size(individualOutOfPlan) > 0 && (
        <div className="grid gap-6 grid-cols-1 mq768:grid-cols-2 mq768:gap-5">
          <CoverageSection title="Individual In-Plan-Network" color="green" data={individualInPlan} />
          <CoverageSection title="Individual Out-Of-Plan-Network" color="red" data={individualOutOfPlan} />
        </div>
      )}

      <CoverageSection title="Family" color="purple" data={family} />

      {size(familyInPlan) > 0 && size(familyOutOfPlan) > 0 && (
        <div className="grid gap-6 grid-cols-1 mq768:grid-cols-2 mq768:gap-5">
          <CoverageSection title="Family In-Plan-Network" color="green" data={familyInPlan} />
          <CoverageSection title="Family Out-Of-Plan-Network" color="red" data={familyOutOfPlan} />
        </div>
      )}
    </>
  )
}

const ActiveCoverageSection = ({ data }: any) => {
  const items = React.useMemo(() => {
    const result: any = []

    if (!data) return result

    if (data.insurance_plan) {
      result.push(<h4 className="mt-2">{data.insurance_plan}</h4>)
    }

    if (data.date_of_last_update) {
      result.push(<DataList.Item label="Date of Last Update" value={formatDate(data.date_of_last_update)} />)
    }

    if (Array.isArray(data.entity_description) && size(data.entity_description) > 0) {
      result.push(
        <DataList.Item
          label={data.entity_description[0]}
          value={
            <div>
              {data?.entity_name && (
                <div>
                  <div>{data?.entity_name?.[0]}</div>
                </div>
              )}

              {data?.entity_addr_1 && (
                <div>
                  <div>{data?.entity_addr_1?.[0]}</div>
                </div>
              )}

              {data?.entity_city && (
                <div>
                  <div>{compact([data?.entity_city?.[0], data?.entity_state?.[0], data?.entity_zip?.[0]]).join(', ')}</div>
                </div>
              )}

              {data?.entity_website?.[0] && (
                <a href={formatURL(data.entity_website[0])} target="_blank" rel="noreferrer">
                  {data.entity_website[0]}
                </a>
              )}
            </div>
          }
        />,
      )
    }

    if (data.benefit_notes) {
      result.push(<div className="text-text-muted tracking-[0.5px] mt-1">{data.benefit_notes}</div>)
    }

    return result
  }, [data])

  return (
    <div>
      <Status small label={`${data.insurance_type_description || ''} ${data.benefit_coverage_description}`} color="orange" />

      {size(items) > 0 && (
        <DataList isCompact labelWidth={180}>
          {items.map((item: any) => {
            return item || null
          })}
        </DataList>
      )}
    </div>
  )
}

const CoverageSection = ({ data, title, color }: any) => {
  if (size(data) === 0) return null

  return (
    <div>
      <Status small label={title} color={color || 'gray'} className="mb-1" />

      <DataList isCompact labelWidth={180}>
        {data.map((item: any) => {
          return (
            <>
              {item.benefit_coverage_description && (
                <DataList.Item
                  label={item.benefit_coverage_description}
                  value={
                    <div>
                      {!isUndefined(item.benefit_amount) && (
                        <div>
                          <b>${item.benefit_amount}</b>{' '}
                          {item.benefit_period_description !== 'Remaining' && <span className="text-text-muted opacity-50">/</span>}{' '}
                          {item.benefit_period_description}
                        </div>
                      )}

                      {!isUndefined(item.benefit_percent) && (
                        <div>
                          <b>{item.benefit_percent}%</b>{' '}
                          {item.benefit_period_description !== 'Remaining' && <span className="text-text-muted opacity-50">/</span>}{' '}
                          {item.benefit_period_description}
                        </div>
                      )}

                      {!isUndefined(item.benefit_qnty) && (
                        <div>
                          <b>{item.benefit_qnty}</b>{' '}
                          {item.benefit_period_description !== 'Remaining' && <span className="text-text-muted opacity-50">/</span>}{' '}
                          {item.benefit_period_description}
                        </div>
                      )}

                      {item.benefit_notes && <div className="text-text-muted text-[0.94em] opacity-80 mt-1">{item.benefit_notes}</div>}
                    </div>
                  }
                />
              )}
            </>
          )
        })}
      </DataList>
    </div>
  )
}

const PreviewOverlay = (props: any) => {
  const { close } = useOverlay({ name: 'preview_eligibility' })

  const { data, isLoading } = useGet({
    name: 'preview_eligibility',
    url: `/insurance_evobs/${props.id}/preview_eligibility`,
  })

  if (isLoading) return <OverlayLoader position="right" />

  return (
    <Overlay showBackdrop closeOnBackdrop position="right" onClose={props.onClose}>
      <Overlay.Header glyph="view" title="Preview" />

      <Overlay.Content className="p-4">
        <div dangerouslySetInnerHTML={{ __html: data?.html }}></div>
      </Overlay.Content>
    </Overlay>
  )
}
