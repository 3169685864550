import React, { useRef } from 'react'
import clsx from 'clsx'
import Handsontable from 'handsontable'

import { beautifulFloat } from '../../../utils/functions'
import Tooltip from '../../Tooltip'

type NumericRendererProps = {
  TD?: HTMLTableCellElement
  value?: string | number
  row?: number
  col?: number
  cellProperties?: Handsontable.CellProperties
  config?: any
  isEditable?: boolean
  readOnly?: boolean
  validator?: any
}

export const NumericRenderer = (props: NumericRendererProps) => {
  const { value, readOnly, isEditable, validator, TD, config = {} } = props
  const { animateOnChange } = config

  const ref = useRef<HTMLDivElement>(null)
  const [errors, setErrors] = React.useState([])

  const isValid = errors.length === 0
  const currentValue = useRef(value)

  React.useEffect(() => {
    if (!validator) return

    validator(value, (valid, newErrors) => {
      setErrors(newErrors)
    })
  }, [value])

  if (TD) {
    readOnly ? TD.classList.add('htDimmed') : TD.classList.remove('htDimmed')

    if (value && isEditable) {
      isValid ? TD.classList.remove('htInvalid') : TD.classList.add('htInvalid')
    }
  }

  if (animateOnChange && currentValue.current !== value) {
    if (!ref.current) return

    ref.current.classList.add('animated')

    setTimeout(() => {
      ref.current.classList.remove('animated')
    }, 200)

    currentValue.current = value
  }

  const displayValue = React.useMemo(() => {
    if (value === null || value === undefined || value === '') return null

    const parsedValue = typeof value === 'string' ? parseFloat(value) : value

    if (typeof parsedValue === 'number' && isFinite(parsedValue)) {
      return beautifulFloat(parsedValue)
    }

    // edge case for NaN === NaN, which is false in JS
    if (value !== value) {
      return 'Invalid Value'
    }

    // display current value if it's not literally NaN
    if (isNaN(value)) {
      return `Invalid Value: ${value}`
    }
  }, [value])

  return (
    <div
      ref={ref}
      className={clsx('flex items-center flex-nowrap h-[29px] font-[600] justify-end tabular-nums mx-[-4px] px-[4px] will-animate', {
        htDimmed: readOnly,
      })}
    >
      {displayValue}

      {!!value && isEditable && errors.length > 0 && (
        <div className="ml-2 inline-flex items-center">
          <Tooltip
            glyph="circle_error"
            color="red"
            content={
              <>
                {errors.map((error, index) => (
                  <div key={index}>{error?.message}</div>
                ))}
              </>
            }
          />
        </div>
      )}
    </div>
  )
}
