import React from 'react'
import { useNavigate, useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

import Button from '../../components/Button'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Tabs from '../../components/Tabs'
import { FILTERS } from '../Filters/config'

const CATEGORIES = {
  certification: 'authority_form',
  inspection: 'authority_form',
  complaint: 'authority_complaint',
  issue: 'authority_complaint',
  study: 'authority_study',
  survey: 'authority_study',
}

export const AuthorityFormsImportOverlay = () => {
  const params = useParams()
  const navigate = useNavigate()

  const { isBehave } = useSettings()

  const [selectedRows, setSelectedRows]: any = React.useState([])

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const [category, setCategory] = React.useState(params.category || 'certification')

  const tableProps = useDataTable({
    name: ['internal_authority_form_templates'],
    endpoint: `/internal_templates`,
    params: {
      category: CATEGORIES[category],
      subcategory: category,
      status: 'active',
      level: 'behave_template',
    },
    options: { enabled: isBehave },
  })

  const { mutate, isLoading: isImporting } = useCreate({
    name: 'import-templates',
    url: '/internal_templates/import',
    invalidate: 'authority_form_templates',
    onSuccess: () => {
      navigate(-1)
    },
  })

  const importTemplates = () => {
    const ids = selectedRows.map((o: any) => o.id)
    mutate({ ids: ids, category: CATEGORIES[category], subcategory: category })
  }

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        id: 'name',
        model: 'name',
        title: 'Name',
        formatValue: ({ value, data }: any) => <MainCell id={data.id} value={value} />,
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        type: 'title',
      },
      {
        id: 'category',
        width: 200,
        model: 'data.category',
        title: 'Category',
        type: 'title',
      },
      {
        id: 'subcategory',
        width: 200,
        model: 'data.subcategory',
        title: 'Subcategory',
        type: 'title',
      },
      {
        id: 'form_type',
        width: 200,
        model: 'data.form_type',
        title: 'Form Type',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return value?.toUpperCase?.()
        },
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Authority Forms" icon="authority_forms" />

      <Overlay.Content>
        <Section margin="0 0 1.5rem 0">
          <Tabs activeTab={category} onChange={setCategory}>
            <Tabs.List className="mb-4">
              <Tabs.Item label="Certifications" name="certification" />
              <Tabs.Item label="Site Review" name="inspection" />
              <Tabs.Item label="Complaints" name="complaint" />
              <Tabs.Item label="Issues" name="issue" />
              <Tabs.Item label="Studies" name="study" />
              <Tabs.Item label="Surveys" name="survey" />
            </Tabs.List>
          </Tabs>

          <DataTable
            asCard
            canBatchSelect
            {...tableProps}
            title="Authority Form Templates"
            icon="authority_forms"
            columns={columns}
            onRowSelectionUpdate={setSelectedRows}
            className="min-h-[480px]"
            filtersConfig={FILTERS.authority_form_templates}
          />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Templates', selectedRowsCount)}` : 'Select Templates to Import'}
          isDisabled={!hasSelectedRows}
          isLoading={isImporting}
          type="primary"
          color="green"
          onClick={importTemplates}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
