import React from 'react'

import { useSettings } from '../../hooks/useSettings'
import { usTime } from '../../utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import EventStatus from '../../components/Statuses/EventStatus'

import { FILTERS } from '../Filters/config'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

const CATEGORIES: any = {
  general: 'General',
  client_admission: 'Client Admission',
  recovery_coaching: 'Recovery Coaching',
  housing_activity: 'Housing Activity',
  discharge: 'Discharge',
  psych_appointment: 'Psych Appointment',
  medical_appointment: 'Medical Appointment',
  clinical_appointment: 'Clinical Appointment',
  progress_appointment: 'Progress Appointment',
  individual_therapy: 'Individual Therapy',
  individual_peer_note: 'Individual Peer Note',
  group_peer_notes: 'Group Peer Notes',
  individual_daily_note: 'Individual Daily Note',
  group_daily_notes: 'Group Daily Notes',
  group_therapy: 'Group Therapy',
  meds_pass: 'Meds Pass',
  urinalysis: 'Urinalysis',
  breathalyzer: 'Breathalyzer',
  vitals: 'Vitals',
  communicable_tests: 'Communicable Tests',
  bed_check: 'Bed Check',
  housing_intake: 'Housing Intake',
  show_as_busy: 'Show as Busy',
}

export const EventsReportDataTable = (props: any) => {
  const { to } = props

  const { tenant, isBehave, timezone } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        id: 'title',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.title} to={to?.(data)} />,
      },
      {
        width: 150,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <EventStatus status={value} />,
      },
      {
        width: 160,
        id: 'category',
        model: 'category',
        title: 'Category',
        formatValue: ({ value }: any) => {
          if (!(value in CATEGORIES)) return '-'

          return CATEGORIES[value]
        },
      },
      {
        width: 90,
        id: 'color',
        model: 'color',
        title: 'Color',
        type: 'color',
      },
      {
        width: 160,
        id: 'started_at',
        model: 'started_at',
        title: 'Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 160,
        id: 'is_all_day',
        model: 'is_all_day',
        title: 'All Day',
        type: 'boolean'
      },
      {
        width: 200,
        id: 'plain_public_description',
        model: 'plain_public_description',
        title: 'Public Description',
      },
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Events"
      icon="calendar"
      columns={columns}
      filtersConfig={FILTERS.events}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="events"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=events'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'events' }} />
        </>
      }
    />
  )
}
