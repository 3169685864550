import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { DocumentationTimeReportDataTable } from '@behavehealth/constructs/LiveReports/DocumentationTimeReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Timeliness = () => {
  const tableProps = useDataTable({
    name: ['reports', 'documentation-time'],
    endpoint: `/live_reports?category=timeliness`,
    localStorageKey: 'report_documentation_time_v1',
  })

  const to = React.useMemo(() => (rowData: any) => getResourceLink(rowData?.author), [])

  return (
    <Page icon="patient_progress_measure" title="Documentation Time">
      <Grid>
        <DocumentationTimeReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Timeliness)
