import React from 'react'
import dompurify from 'dompurify'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { Spreadsheet } from '../../components/Spreadsheet/Spreadsheet'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import ClaimTypeStatus from '../../components/Statuses/ClaimTypeStatus'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import State from '../../components/State'

import Tabs from '../../components/Tabs'
import PillTab from '../../components/PillTab'
import PillTabList from '../../components/PillTabList'
import TabPanels from '../../components/TabPanels'
import TabPanel from '../../components/TabPanel'

import ClaimDetails from './components/Template/ClaimDetails'
import ClaimPayerDetails from './components/Template/ClaimPayerDetails'
import ClaimInstitutionalProviders from './components/Template/ClaimInstitutionalProviders'
import ClaimInstitutionalValueCodes from './components/ClaimInstitutionalValueCodes'
import ClaimProfessionalProviders from './components/Template/ClaimProfessionalProviders'
import ClaimTemplateDetails from './components/Template/ClaimTemplateDetails'
import InstitutionalServiceLines from './components/Template/InstitutionalServiceLines'
import ProfessionalServiceLines from './components/Template/ProfessionalServiceLines'

import OverlayLoader from '../../components/OverlayLoader'
import { useSpreadsheet } from '../../components/Spreadsheet/useSpreadsheet'
import { INSURANCE_CODE_TYPES } from '../../utils/constants'

import { ClaimsSpreadsheetTipAlert } from './ClaimsSpreadsheetTipAlert'

const sanitize = dompurify.sanitize

const RootClaimTemplateOverlay = (props: any) => {
  const {
    data,
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isSaving,
    isOverlayLoading,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'insurance_claim_template',
    endpoint: '/insurance_claim_templates',
    invalidate: 'insurance_claim_templates',
    options: props,
  })

  const { timezone, tenant } = useSettings()

  const [claimCategory, setClaimCategory] = React.useState(null)
  const [insuranceProvider, setInsuranceProvider] = React.useState(null)
  const [feeSchedule, setFeeSchedule] = React.useState(null)

  const findAmountInFeeSchedule = (insurance_new_code_id: string) => {
    if (!feeSchedule) return 0

    if (!feeSchedule.insurance_new_fee_schedule_services) return 0

    for (let i = 0; i < feeSchedule.insurance_new_fee_schedule_services.length; i++) {
      if (feeSchedule.insurance_new_fee_schedule_services[i].insurance_new_code.id === insurance_new_code_id) {
        return feeSchedule.insurance_new_fee_schedule_services[i].price || 0
      }
    }

    return 0
  }

  const save = async () => {
    let service_lines: any = []

    if (claimCategory === 'professional') {
      service_lines = professionalSpreadsheetProps.getData()
    } else if (claimCategory === 'institutional') {
      service_lines = institutionalSpreadsheetProps.getData()
    }

    const apiServiceLineIds: any = data?.insurance_claim_template_service_lines?.map((line: any) => line.id) || []
    const deletedServiceLineIds: any = []

    for (const apiId of apiServiceLineIds) {
      const found = service_lines.find((line: any) => line.id === apiId)

      if (!found) {
        deletedServiceLineIds.push(apiId)
      }
    }

    for (const deletedId of deletedServiceLineIds) {
      service_lines.push({ id: deletedId, _destroy: true })
    }

    await saveWithData({
      ...form.current.getFormValue(),
      insurance_claim_template_service_lines_attributes: service_lines,
    })
  }

  const professionalColumns = React.useMemo(() => {
    return [
      {
        title: 'ID',
        model: 'id',
      },
      {
        title: 'Start Date',
        model: 'service_date_start',
        type: 'date',
        width: 140,
      },
      {
        title: 'End Date',
        model: 'service_date_end',
        type: 'date',
        width: 140,
      },
      {
        title: 'Insurance Code',
        model: 'insurance_new_code',
        width: 340,
        type: 'object_selector',
        onUpdate: ({ value, y, instance, get, set }: any) => {
          if (!value) return

          const parsed = JSON.parse(value)
          const price = findAmountInFeeSchedule(parsed.id)

          if (parsed.modifier_codes) {
            set('modifier_codes', JSON.stringify(parsed.modifier_codes))
          }

          set('unit_price', parseFloat(price))
        },
        config: {
          includeObject: true,
          endpoint: feeSchedule?.id && `/insurance_new_fee_schedules/${feeSchedule?.id}/insurance_new_codes`,
          dataType: 'insurance_new_code',
          params: { status: 'active' },
          selectTitle: (data: any) => `${data?.service_name} (${data?.procedure_code})`,
          selectDescription: (data: any) => `${INSURANCE_CODE_TYPES[data?.code_type]}`,
          tooltip: !feeSchedule?.id && 'Select a Fee Schedule to enable editing',
        },
      },
      {
        title: 'Modifier Codes',
        model: 'modifier_codes',
        type: 'multi_search_selector',
        config: {
          includeObject: true,
          animateOnChange: true,
          params: { type: 'insurance_codes', subtype: 'modifier_code' },
          selectTitle: (data: any) => data?.code,
          selectDescription: (data: any) => data?.description,
          selectMenuTitle: (data, highlights) => {
            let title = data?.code
            if (!highlights) return title

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'code') {
                title = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
          },
          selectMenuDescription: (data, highlights) => {
            let description = data?.description
            if (!highlights) return description

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'description') {
                description = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
          },
        },
      },
      {
        title: 'Units',
        model: 'units',
        align: 'right',
        type: 'numeric',
        onUpdate: ({ get, set }) => {
          const units = get('units') || 0
          const unitPrice = get('unit_price') || 0

          set('total_charge', units * unitPrice)
        },
        config: {
          defaultValue: 1,
          validations: {
            greaterThanOrEqualTo: {
              value: 0,
              message: 'Value must be greater than or equal to 0',
            },
          },
        },
      },
      {
        title: 'Unit Price',
        model: 'unit_price',
        align: 'right',
        type: 'amount',
        config: {
          animateOnChange: true,
          validations: {
            greaterThanOrEqualTo: {
              value: 0,
              message: 'Value must be greater than or equal to 0',
            },
          },
        },
        onUpdate: ({ get, set }) => {
          const units = get('units') || 0
          const unitPrice = get('unit_price') || 0

          set('total_charge', units * unitPrice)
        },
      },
      {
        title: 'Total Charge',
        model: 'total_charge',
        align: 'right',
        type: 'amount',
        readOnly: true,
        config: {
          animateOnChange: true,
        },
      },
    ]
  }, [feeSchedule?.id])

  const professionalSpreadsheetProps = useSpreadsheet({ columns: professionalColumns, manualRowDelete: true })

  const institutionalColumns = React.useMemo(() => {
    return [
      {
        title: 'ID',
        model: 'id',
      },
      {
        title: 'Service Date',
        model: 'service_date_start',
        type: 'date',
        width: 140,
      },
      {
        title: 'Insurance Code',
        model: 'insurance_new_code',
        width: 340,
        type: 'object_selector',
        onUpdate: ({ value, y, instance, get, set }: any) => {
          if (!value) return

          const parsed = JSON.parse(value)
          const price = findAmountInFeeSchedule(parsed.id)

          if (parsed.modifier_codes) {
            set('modifier_codes', JSON.stringify(parsed.modifier_codes))
          }

          set('unit_price', parseFloat(price))
        },
        config: {
          includeObject: true,
          endpoint: feeSchedule?.id && `/insurance_new_fee_schedules/${feeSchedule?.id}/insurance_new_codes`,
          dataType: 'insurance_new_code',
          params: { status: 'active' },
          selectTitle: (data: any) => `${data?.service_name} (${data?.procedure_code})`,
          selectDescription: (data: any) => `${INSURANCE_CODE_TYPES[data?.code_type]}`,
          tooltip: !feeSchedule?.id && 'Select a Fee Schedule to enable editing',
        },
      },
      {
        title: 'Revenue Code',
        model: 'revenue_code',
        type: 'input_search_selector',
        config: {
          params: { type: 'insurance_codes', subtype: 'revenue_code' },
          modelSelector: 'code',
          selectTitle: (data: any) => data?.code,
          selectDescription: (data: any) => data?.description,
          selectMenuTitle: (data, highlights) => {
            let title = data?.code
            if (!highlights) return title

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'code') {
                title = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
          },
          selectMenuDescription: (data, highlights) => {
            let description = data?.description
            if (!highlights) return description

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'description') {
                description = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
          },
        },
      },
      {
        title: 'Modifier Codes',
        model: 'modifier_codes',
        type: 'multi_search_selector',
        config: {
          includeObject: true,
          animateOnChange: true,
          params: { type: 'insurance_codes', subtype: 'modifier_code' },
          selectTitle: (data: any) => data?.code,
          selectDescription: (data: any) => data?.description,
          selectMenuTitle: (data, highlights) => {
            let title = data?.code
            if (!highlights) return title

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'code') {
                title = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
          },
          selectMenuDescription: (data, highlights) => {
            let description = data?.description
            if (!highlights) return description

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'description') {
                description = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
          },
        },
      },
      {
        title: 'Units',
        model: 'units',
        align: 'right',
        type: 'numeric',
        onUpdate: ({ get, set }) => {
          const units = get('units') || 0
          const unitPrice = get('unit_price') || 0

          set('total_charge', units * unitPrice)
        },
        config: {
          defaultValue: 1,
          validations: {
            greaterThanOrEqualTo: {
              value: 0,
              message: 'Value must be greater than or equal to 0',
            },
          },
        },
      },
      {
        title: 'Unit Type',
        model: 'unit_type',
        type: 'select',
        config: {
          defaultValue: 'UN',
          options: [
            { label: 'Units', value: 'UN' },
            { label: 'Days', value: 'DA' },
          ],
        },
      },
      {
        title: 'Unit Price',
        model: 'unit_price',
        align: 'right',
        type: 'amount',
        config: {
          animateOnChange: true,
          validations: {
            greaterThanOrEqualTo: {
              value: 0,
              message: 'Value must be greater than or equal to 0',
            },
          },
        },
        onUpdate: ({ get, set }) => {
          const units = get('units') || 0
          const unitPrice = get('unit_price') || 0

          set('total_charge', units * unitPrice)
        },
      },
      {
        title: 'Total Charge',
        model: 'total_charge',
        align: 'right',
        type: 'amount',
        readOnly: true,
        config: {
          animateOnChange: true,
        },
      },
    ]
  }, [])

  const institutionalSpreadsheetProps = useSpreadsheet({ columns: institutionalColumns, manualRowDelete: true })

  if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={120} />

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} maxWidth={120}>
      <Overlay.Header icon="accounting" title="Claim Template" titleAside={!isNew && <ClaimTypeStatus status={initialModel.category} />} />

      <Overlay.Content>
        <Form
          isCompact
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
          maxWidth="100%"
        >
          <Section>
            <Grid gap="0.6rem">
              <RadioGroup
                model="category"
                layout="horizontal"
                defaultValue="professional"
                maxWidth="100%"
                withHover={false}
                isEditable={isNew}
                className={`${isNew ? '' : 'is-hidden'} w-[500px]`}
                onUpdate={(state: any) => {
                  setClaimCategory(state.value)
                }}
              >
                <Radio label="Professional Claim (CMS-1500)" value="professional" />
                <Radio label="Institutional Claim (UB-04)" value="institutional" />
              </RadioGroup>

              <div>
                <Grid gap="0.4rem">
                  <Flex gap="1rem" alignItems="normal">
                    <ClaimTemplateDetails />

                    <ClaimPayerDetails insuranceProvider={insuranceProvider} onInsuranceProviderUpdate={setInsuranceProvider} />
                  </Flex>

                  <ClaimDetails feeSchedule={feeSchedule} onUpdate={setFeeSchedule} />

                  <ContextShow when="category" is="professional">
                    <ClaimProfessionalProviders tenant={tenant} />
                  </ContextShow>

                  <ContextShow when="category" is="institutional">
                    <Tabs defaultTab="providers">
                      <PillTabList css={{ marginTop: '0.25rem', marginBottom: '0.5rem' }}>
                        <PillTab label="Providers" name="providers" />
                        <PillTab label="Value Codes" name="value_codes" />
                      </PillTabList>

                      <TabPanels>
                        <TabPanel name="providers">
                          <ClaimInstitutionalProviders tenant={tenant} />
                        </TabPanel>

                        <TabPanel name="value_codes">
                          <ClaimInstitutionalValueCodes tenant={tenant} />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </ContextShow>
                </Grid>
              </div>

              <ClaimsSpreadsheetTipAlert />

              {claimCategory === 'professional' && (
                <>
                  {/* <ProfessionalServiceLines
                    isNested
                    key={initialModel?.updated_at}
                    value={data?.insurance_claim_template_service_lines}
                    model="insurance_claim_template_service_lines"
                    isNew={isNew}
                    tenant={tenant}
                    feeSchedule={feeSchedule}
                  /> */}

                  {!!feeSchedule?.id ? (
                    <div>
                      <Spreadsheet
                        {...professionalSpreadsheetProps}
                        key={`spreadsheet-professional-${data?.updated_at}`}
                        title="Professional Service Lines"
                        icon="outbox"
                        initialData={
                          isNew
                            ? [{ units: 1 }]
                            : professionalSpreadsheetProps.formatInitialData(data?.insurance_claim_template_service_lines)
                        }
                        isEditable={isEditable}
                        hiddenColumns={{ columns: isEditable ? [1] : [0, 1] }}
                      />
                    </div>
                  ) : (
                    <Card>
                      <State
                        isEmpty
                        icon="outbox"
                        title="Service Lines"
                        minHeight={200}
                        emptyDescription={
                          <Alert small type="warning" glyph="info" className="text-left">
                            You must select a <strong>Fee Schedule</strong> before adding any Service Lines
                          </Alert>
                        }
                      />
                    </Card>
                  )}
                </>
              )}

              {claimCategory === 'institutional' && (
                <>
                  {/* <InstitutionalServiceLines
                    isNested
                    key={initialModel?.updated_at}
                    value={data?.insurance_claim_template_service_lines}
                    model="insurance_claim_template_service_lines"
                    isNew={isNew}
                    tenant={tenant}
                    feeSchedule={feeSchedule}
                  /> */}

                  {!!feeSchedule?.id ? (
                    <div>
                      <Spreadsheet
                        {...institutionalSpreadsheetProps}
                        key={`spreadsheet-institutional-${data?.updated_at}`}
                        title="Institutional Service Lines"
                        icon="outbox"
                        initialData={
                          isNew
                            ? [{ units: 1, unit_type: 'UN' }]
                            : institutionalSpreadsheetProps.formatInitialData(data?.insurance_claim_template_service_lines)
                        }
                        isEditable={isEditable}
                        hiddenColumns={{ columns: isEditable ? [1] : [0, 1] }}
                      />
                    </div>
                  ) : (
                    <Card>
                      <State
                        isEmpty
                        icon="outbox"
                        title="Service Lines"
                        minHeight={200}
                        emptyDescription={
                          <Alert small type="warning" glyph="info" className="text-left">
                            You must select a <strong>Fee Schedule</strong> before adding any Service Lines
                          </Alert>
                        }
                      />
                    </Card>
                  )}
                </>
              )}

              {/* temp fix for dropdowns going outside viewport */}
              <div className="h-[400px]" />
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="insurance_claim_templates.create"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="insurance_claim_templates.edit"
            />

            <DeleteDialog
              title="Delete Claim Template?"
              message="Are you sure you want to delete this Claim Template? This action cannot be undone."
              onYes={deleteRecord}
              permission="insurance_claim_templates.delete"
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="insurance_claim_templates.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClaimTemplateOverlay = withOverlayError(RootClaimTemplateOverlay)
