import React from 'react'
import clsx from 'clsx'
import get from 'lodash/get'
import size from 'lodash/size'

import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'
import { H3 } from '../../components/Typography'
import { MEDIA_QUERY } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import { beautifulFloat } from '../../utils/functions'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardsGrid from '../../components/CardsGrid'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import ContextShow from '../../components/Forms/ContextShow'
import DataFormStatus from '../../components/Statuses/DataFormStatus'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import EducationCheckboxGroup from '../../components/Forms/elements/EducationCheckboxGroup'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Nav from '../../components/Nav'
import NewEthnicityCheckboxGroup from '../../components/Forms/elements/NewEthnicityCheckboxGroup'
import NumberInput from '../../components/Forms/NumberInput'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PageGrid from '../../components/PageGrid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import ScrollMenu from '../../components/ScrollMenu'
import ScrollView from '../../components/ScrollView'
import Section from '../../components/Section'
import Slider from '../../components/Forms/Slider'
import Tabs from '../../components/Tabs'
import Textarea from '../../components/Forms/Textarea'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../components/Forms/elements/YesNoRadioGroupWithTextarea'

import { BarChart } from './components/Charts'
import { Pill } from './components/Pill'
import { RecoveryCapitalHistoricData } from './RecoveryCapitalHistoricData'
import { ResultCard } from './components/ResultCard'

import CapitalCard from './CapitalCard'

import {
  GREEN,
  MAX_VALUES,
  PERSONAL_RECOVERY_CAPITAL,
  RECOVERY_GROUPS,
  RED,
  SERVICES,
  SOCIAL_RECOVERY_CAPITAL,
  SUBSTANCES,
} from './constants'

const ANIMATION_DELAY = 100

const RootRecoveryCapitalFormOverlayV1 = (props: any) => {
  const { isBehave, isPortal, timezone, defaultSupervisor } = useSettings()

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    parent,
    save,
    record,
    id,
  } = useOverlay({
    name: 'recovery_capital_form',
    endpoint: '/recovery_capital_forms',
    invalidate: 'recovery_capital_forms',
    parentType: props.parentType,
    parentRequest: props.parentRequest,
    options: props,
    onSaveSuccessful: props.onSaveSuccessful,
    onDeleteSuccessful: props.onDeleteSuccessful,
    openAfterCreate: true,
  })

  const [tab, setTab] = React.useState(isNew ? 'form' : 'results')

  const referenceID = props?.referenceID || parent?.id

  const barriers = React.useMemo(() => {
    const res = {
      housing: 0,
      substance_use: 0,
      risk_taking: 0,
      criminal_justice: 0,
      work_training_volunteering: 0,
    }

    if (!data) return res

    if (
      get(data, 'answers.barriers_to_recovery_domain.at_risk_of_eviction') ||
      get(data, 'answers.barriers_to_recovery_domain.having_acute_housing_problems') ||
      get(data, 'answers.barriers_to_recovery_domain.problems_securing_housing_due_to_debt')
    ) {
      res.housing = 1
    }

    if (get(data, 'answers.barriers_to_recovery_domain.used_substances_in_the_last_ninety_days')) {
      res.substance_use = 1
    }

    if (get(data, 'answers.barriers_to_recovery_domain.risk_taking.has_injected_in_the_last_ninety_days')) {
      res.risk_taking = 1
    }

    if (
      get(data, 'answers.barriers_to_recovery_domain.criminal_justice.offended_in_the_last_ninety_days') ||
      get(data, 'answers.barriers_to_recovery_domain.criminal_justice.in_probation_in_the_last_ninety_days') ||
      get(data, 'answers.barriers_to_recovery_domain.criminal_justice.on_parole_in_the_last_ninety_days') ||
      get(data, 'answers.barriers_to_recovery_domain.criminal_justice.other_involvement_in_the_last_ninety_days')
    ) {
      res.criminal_justice = 1
    }

    if (
      get(data, 'answers.barriers_to_recovery_domain.work_training_volunteering.working_full_time') ||
      get(data, 'answers.barriers_to_recovery_domain.work_training_volunteering.working_part_time') ||
      get(data, 'answers.barriers_to_recovery_domain.work_training_volunteering.attending_school') ||
      get(data, 'answers.barriers_to_recovery_domain.work_training_volunteering.volunteering')
    ) {
      res.work_training_volunteering = 1
    } else {
      res.work_training_volunteering = 0
    }

    return res
  }, [data])

  const services = React.useMemo(() => {
    const res: any = {
      engaged_satisfied: [],
      engaged_dissatisfied: [],
      not_engaged: [],
    }

    if (!data) return res

    for (const serviceKey in SERVICES) {
      const name = SERVICES[serviceKey]
      const answers = get(data, `answers.services_and_needs_domain.${serviceKey}`)

      if (answers?.engaged) {
        if (answers?.satisfied) {
          res.engaged_satisfied.push({ name, need_help: answers?.need_help })
        } else {
          res.engaged_dissatisfied.push({ name, need_help: answers?.need_help })
        }
      } else {
        res.not_engaged.push({ name, need_help: answers?.need_help })
      }
    }

    return res
  }, [data])

  const otherServices = React.useMemo(() => {
    if (!data) return null

    const answers = get(data, 'answers.services_and_needs_domain.other_help')

    if (!answers?.engagement && !answers?.satisfied && !answers?.need_help) return null

    return answers
  }, [data])

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={tab === 'results' ? 80 : 110} />
  }

  const onDisabledClick = () => {
    form.current.highlightInvalid()
  }

  return (
    <Overlay onClose={close} position="right" maxWidth={tab === 'results' ? 80 : 110}>
      <Overlay.Header
        icon="progress_reviews"
        title={data?.name || 'Recovery Capital Form'}
        titleAside={<DataFormStatus status={data?.status} />}
        description={<ClientProfileHeader client={data?.client || record} />}
      />

      <Tabs activeTab={tab} onChange={setTab} className={clsx('grid grow overflow-hidden', !isNew && 'grid-rows-[auto_1fr]')}>
        <Overlay.SubHeader className={clsx('!p-0 relative z-[3]', isNew && '!hidden')}>
          <Tabs.List className={clsx('!border-none px-4')}>
            <Tabs.Item label="Results" name="results" glyph="chart" />
            <Tabs.Item label="Form" name="form" glyph="document" />
          </Tabs.List>
        </Overlay.SubHeader>

        <Tabs.Panel name="results" className="p-4 bg-[#F5F6FA] overflow-auto">
          {data && (
            <div className="grid items-start content-start gap-4">
              <CardsGrid>
                <CapitalCard
                  title="Negative Recovery Capital"
                  value={beautifulFloat(parseFloat(data.negative_capital_summary_score))}
                  valueColor="red"
                  className="animate-scaleFadeIn opacity-0 scale-x-[0.95] scale-y-[0.95]"
                  animationDelay={1 * ANIMATION_DELAY}
                />
                <CapitalCard
                  title="Positive Recovery Capital"
                  value={beautifulFloat(parseFloat(data.positive_capital_summary_score))}
                  valueColor="green"
                  className="animate-scaleFadeIn opacity-0 scale-x-[0.95] scale-y-[0.95]"
                  animationDelay={2 * ANIMATION_DELAY}
                />
                <CapitalCard
                  title="Overall Recovery Capital Score"
                  value={beautifulFloat(parseFloat(data.recovery_capital_index_score))}
                  prefix="-"
                  className="animate-scaleFadeIn opacity-0 scale-x-[0.95] scale-y-[0.95]"
                  animationDelay={3 * ANIMATION_DELAY}
                />
              </CardsGrid>

              <ResultCard
                title="Quality of Life and Satisfaction"
                score={data.quality_of_life_score}
                maxScore={MAX_VALUES['quality_of_life_score']}
                color={data.quality_of_life_score < 33 ? 'red' : data.quality_of_life_score < 66 ? 'orange' : 'green'}
                animationDelay={4 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricData clientId={referenceID} dimension="quality_of_life" />
                  </Card>
                }
              >
                <BarChart
                  max={20}
                  labels={['Support Network', 'Housing Quality', 'Life Quality', 'Physical Health', 'Psychological Health']}
                  values={[
                    get(data, 'answers.quality_of_life_domain.support_network'),
                    get(data, 'answers.quality_of_life_domain.quality_of_housing'),
                    get(data, 'answers.quality_of_life_domain.overall_quality_of_life'),
                    get(data, 'answers.quality_of_life_domain.physical_health'),
                    get(data, 'answers.quality_of_life_domain.psychological_health'),
                  ]}
                />
              </ResultCard>

              <ResultCard
                title="Barriers to Recovery"
                score={data.barriers_to_recovery_score}
                maxScore={MAX_VALUES['barriers_to_recovery_score']}
                color={data.barriers_to_recovery_score < 1 ? 'green' : data.barriers_to_recovery_score < 3 ? 'orange' : 'red'}
                animationDelay={5 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricData clientId={referenceID} dimension="barriers_to_recovery" />
                  </Card>
                }
              >
                <BarChart
                  max={100}
                  showTooltip={false}
                  labels={[
                    'Work, Training, Volunteering',
                    'Criminal Justice System Involvement',
                    'Risk Taking',
                    'Substance Use',
                    'Housing',
                  ]}
                  values={[
                    {
                      value: 100,
                      itemStyle: {
                        color: barriers.work_training_volunteering ? RED : GREEN,
                      },
                    },
                    {
                      value: 100,
                      itemStyle: {
                        color: barriers.criminal_justice ? RED : GREEN,
                      },
                    },
                    {
                      value: 100,
                      itemStyle: {
                        color: barriers.risk_taking ? RED : GREEN,
                      },
                    },
                    {
                      value: 100,
                      itemStyle: {
                        color: barriers.substance_use ? RED : GREEN,
                      },
                    },
                    {
                      value: 100,
                      itemStyle: {
                        color: barriers.housing ? RED : GREEN,
                      },
                    },
                  ]}
                  label={{
                    show: true,
                    position: 'insideRight',
                    color: 'white',
                    formatter: (params) => {
                      const values = [
                        barriers.work_training_volunteering,
                        barriers.criminal_justice,
                        barriers.risk_taking,
                        barriers.substance_use,
                        barriers.housing,
                      ]
                      return values[params.dataIndex]
                    },
                  }}
                />
              </ResultCard>

              <ResultCard
                title="Services Involvement and Needs"
                maxScore={MAX_VALUES['services_and_needs_score']}
                score={data.services_and_needs_score}
                color={data.services_and_needs_score < 4 ? 'green' : data.services_and_needs_score < 6 ? 'orange' : 'red'}
                animationDelay={6 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricData clientId={referenceID} dimension="services_and_needs" />
                  </Card>
                }
              >
                <div className="my-4 grid grid-cols-1 mq900:grid-cols-3">
                  <div className="grid gap-2 content-start pr-4 border-r border-0 border-solid border-divider">
                    <div className="grid grid-cols-[1fr_100px]">
                      <h4>Engaged & Satisfied</h4>
                      <h4>Needs Help?</h4>
                    </div>

                    {size(services.engaged_satisfied) === 0 && (
                      <div className="italic text-text-strongly-muted text-center py-4">No services</div>
                    )}

                    {services.engaged_satisfied.map((service: any, index) => (
                      <div key={`${service.name}-${index}`} className="grid grid-cols-[1fr_100px] items-center">
                        <div className="font-[500]">{service.name}</div>
                        <Pill
                          label={service.need_help ? 'Yes' : 'No'}
                          glyph={service.need_help ? 'check' : 'cross'}
                          color={service.need_help ? 'red' : 'green'}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="grid gap-2 content-start px-4 border-r border-0 border-solid border-divider">
                    <div className="grid grid-cols-[1fr_100px]">
                      <h4>Engaged & Dissatisfied</h4>
                      <h4>Needs Help?</h4>
                    </div>

                    {size(services.engaged_dissatisfied) === 0 && (
                      <div className="italic text-text-strongly-muted text-center py-4">No services</div>
                    )}

                    {services.engaged_dissatisfied.map((service: any, index) => (
                      <div key={`${service.name}-${index}`} className="grid grid-cols-[1fr_100px] items-center">
                        <div className="font-[500]">{service.name}</div>
                        <Pill
                          label={service.need_help ? 'Yes' : 'No'}
                          glyph={service.need_help ? 'check' : 'cross'}
                          color={service.need_help ? 'red' : 'green'}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="grid gap-2 content-start pl-4 border-0 border-0 border-solid border-divider">
                    <div className="grid grid-cols-[1fr_100px]">
                      <h4>Not Engaged</h4>
                      <h4>Needs Help?</h4>
                    </div>

                    {size(services.not_engaged) === 0 && (
                      <div className="italic text-text-strongly-muted text-center py-4">No services</div>
                    )}

                    {services.not_engaged.map((service: any, index) => (
                      <div key={`${service.name}-${index}`} className="grid grid-cols-[1fr_100px] items-center">
                        <div className="font-[500]">{service.name}</div>
                        <Pill
                          label={service.need_help ? 'Yes' : 'No'}
                          glyph={service.need_help ? 'check' : 'cross'}
                          color={service.need_help ? 'red' : 'green'}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {otherServices && (
                  <div className="border-t border-0 border-solid border-divider py-4">
                    <div className="grid grid-cols-[1fr_100px]">
                      <h4>Other specialist help or support</h4>
                    </div>

                    <div className="grid gap-2 mt-2">
                      <div>
                        <b>Engagement: </b> {otherServices.engagement || '–'}
                      </div>

                      <div>
                        <b>Satisfaction: </b> {otherServices.satisfied || '–'}
                      </div>

                      <div>
                        <b>Need Help: </b> {otherServices.need_help || '–'}
                      </div>
                    </div>
                  </div>
                )}
              </ResultCard>

              <ResultCard
                title="Personal Recovery Capital"
                maxScore={MAX_VALUES['personal_capital_score']}
                score={data.personal_capital_score}
                animationDelay={7 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricData clientId={referenceID} dimension="personal_capital" />
                  </Card>
                }
              >
                <div className="my-4 grid grid-cols-1 mq480:grid-cols-2 mq800:grid-cols-3 mq1000:grid-cols-4 gap-2">
                  {PERSONAL_RECOVERY_CAPITAL.map((item) => {
                    const isChecked = get(data, item.model)

                    return <Pill key={item.model} label={item.shortLabel} tooltip={item.label} type={isChecked ? 'positive' : 'negative'} />
                  })}
                </div>
              </ResultCard>

              <ResultCard
                title="Social Recovery Capital"
                maxScore={MAX_VALUES['social_capital_score']}
                score={data.social_capital_score}
                animationDelay={8 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricData clientId={referenceID} dimension="social_capital" />
                  </Card>
                }
              >
                <div className="my-4 grid grid-cols-1 mq480:grid-cols-2 mq800:grid-cols-3 mq1000:grid-cols-4 gap-2">
                  {SOCIAL_RECOVERY_CAPITAL.map((item) => {
                    const isChecked = get(data, item.model)

                    return <Pill key={item.model} label={item.shortLabel} tooltip={item.label} type={isChecked ? 'positive' : 'negative'} />
                  })}
                </div>
              </ResultCard>

              <ResultCard
                title="Involvement with Recovery Groups and Local Community"
                maxScore={MAX_VALUES['recovery_group_score']}
                score={data.recovery_group_score}
                animationDelay={9 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricData clientId={referenceID} dimension="recovery_capital" />
                  </Card>
                }
              >
                <div className="my-4 grid grid-cols-1 mq480:grid-cols-2 mq800:grid-cols-3 mq1000:grid-cols-4 gap-2">
                  {RECOVERY_GROUPS.map((item) => {
                    const isChecked = get(data, item.model)

                    return <Pill key={item.model} label={item.shortLabel} tooltip={item.label} type={isChecked ? 'positive' : 'negative'} />
                  })}
                </div>
              </ResultCard>

              <ResultCard
                title="Other Support Score"
                maxScore={MAX_VALUES['other_support_score']}
                score={data.other_support_score}
                animationDelay={10 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricData clientId={referenceID} dimension="support" />
                  </Card>
                }
              >
                <BarChart
                  max={7}
                  right={180}
                  labels={['Need advice from others', 'Need resources from others', 'Need help from others', 'Need emotional support']}
                  values={[
                    get(data, 'answers.recovery_groups_domain.advice_from_others_met'),
                    get(data, 'answers.recovery_groups_domain.resources_from_others_met'),
                    get(data, 'answers.recovery_groups_domain.help_from_others_met'),
                    get(data, 'answers.recovery_groups_domain.emotional_support_met'),
                  ]}
                />
              </ResultCard>

              <ResultCard
                title="Commitment"
                maxScore={MAX_VALUES['commitment_score']}
                score={data.commitment_score}
                animationDelay={11 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricData clientId={referenceID} dimension="commitment" />
                  </Card>
                }
              >
                <BarChart
                  max={7}
                  labels={['Done with drugs', 'No more using', 'Recovery focused', 'Stay clean', 'Sobriety first']}
                  values={[
                    get(data, 'answers.commitment_domain.had_enough_commitment'),
                    get(data, 'answers.commitment_domain.no_return_commitment'),
                    get(data, 'answers.commitment_domain.recovery_commitment'),
                    get(data, 'answers.commitment_domain.off_drugs_commitment'),
                    get(data, 'answers.commitment_domain.sobriaty_importance'),
                  ]}
                />
              </ResultCard>
            </div>
          )}
        </Tabs.Panel>

        <Tabs.Panel name="form" className="[&.is-active]:!grid grid-cols-1 grid-rows-[1fr_auto] w-full h-full overflow-hidden">
          <ScrollView>
            <PageGrid scroll breakpoint={3} className="overlay-page-grid" css={STYLES.pageGrid}>
              <Nav
                top="0"
                breakpoint={3}
                title={' '}
                headingSize={300}
                desktopProps={{ title: null }}
                className="!bg-white shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!shadow-none [&_header]:!shadow-transparent [&_header]:!border-transparent"
              >
                <div className="opacity-80 text-[0.9rem] uppercase font-[700] tracking-[1px] mb-1">Table of Contents</div>
                <ScrollMenu />
              </Nav>

              <Form
                getForm={form}
                initialModel={{
                  ...initialModel,
                  ...(isNew && defaultSupervisor && { supervisor: defaultSupervisor }),
                }}
                timezone={timezone}
                isEditable={isEditable}
                onValidationUpdate={onValidationUpdate}
                key={`updated-${data?.updated_at}`}
                linked={
                  isNew && {
                    client_id: referenceID,
                  }
                }
              >
                <Section
                  scrollview={{
                    id: 'general',
                    name: 'General',
                  }}
                  headingType="h2"
                  title="General"
                >
                  <FormSection>
                    <Input
                      label="Name"
                      model="name"
                      defaultValue="Recovery Capital Form"
                      isEditable={isEditable && !isPortal}
                      validations={{
                        presence: {
                          message: 'Please enter a form name',
                        },
                      }}
                    />

                    <DateTimeInput
                      defaultToNow
                      model="started_at"
                      label="Start Date and Time"
                      isEditable={isEditable && !isPortal}
                      validations={{
                        presence: {
                          message: 'Please enter a date and time',
                        },
                      }}
                    />

                    <FormFutureDateWarning dateLabel="Start Date and Time" model="started_at" />

                    <DateTimeInput model="ended_at" label="End Date and Time" isEditable={isEditable && !isPortal} />
                  </FormSection>
                </Section>

                <Divider />

                {/* S1: DEMOGRAPHICS CHARACTERISTICS */}
                <Section
                  title="S1: Demographic Characteristics"
                  scrollview={{
                    id: 'demographic_characteristics',
                    name: 'Demographic Characteristics',
                  }}
                  headingType="h2"
                >
                  <FormSection>
                    <CheckboxGroup layout="vertical-dense" label="1.1 Gender" trueIcon="check" falseIcon="cross" falseStyle="faded">
                      <Checkbox label="Agender" model="answers.demographics.gender.agender" />
                      <Checkbox label="Demi-queer" model="answers.demographics.gender.demi_queer" />
                      <Checkbox label="Non-Binary" model="answers.demographics.gender.non_binary" />
                      <Checkbox label="Trans-Femme" model="answers.demographics.gender.trans_femme" />
                      <Checkbox label="Trans-Man" model="answers.demographics.gender.trans_man" />
                      <Checkbox label="Trans-Masculine" model="answers.demographics.gender.trans_masculine" />
                      <Checkbox label="Trans-Woman" model="answers.demographics.gender.trans_woman" />
                      <Checkbox label="Trans-Gender" model="answers.demographics.gender.trans_gender" />
                      <Checkbox label="Two-Spirit" model="answers.demographics.gender.two_spirit" />
                      <Checkbox label="Male" model="answers.demographics.gender.male" />
                      <Checkbox label="Female " model="answers.demographics.gender.female" />
                      <Checkbox label="None of These Describe Me" model="answers.demographics.gender.none_of_these_describe_me" />
                      <Checkbox label="Prefer Not to Answer" model="answers.demographics.gender.prefer_not_to_answer" />
                    </CheckboxGroup>

                    <NumberInput label="1.2 Age" suffix="years" model="answers.demographics.age" />
                    <NewEthnicityCheckboxGroup label="1.3 Ethnicity" model="answers.demographics.ethnicity" falseStyle="faded" />

                    <EducationCheckboxGroup label="1.4 Education" model="answers.demographics.education" falseStyle="faded" />
                  </FormSection>
                </Section>

                <Divider />

                {/* S2: QUALITY OF LIFE */}
                <Section
                  headingType="h2"
                  title="S2: Quality of Life and Satisfaction"
                  scrollview={{
                    id: 'quality_of_life_domain',
                    name: 'Quality of Life and Satisfaction',
                    parent: { id: 'domains', name: 'Domains' },
                  }}
                >
                  <FormSection layout="vertical" width="100%">
                    <p>
                      For each of the questions below, please give a rating on the scale for how you are feeling <b>today</b>, where higher
                      scores mean you are feeling better and lower scores that you are not so satisfied with this part of your life.
                      Indicate your score by marking on the "rulers".
                    </p>

                    <div className="grid gap-5">
                      <Slider
                        label="2.1 How good is your psychological health?"
                        model="answers.quality_of_life_domain.psychological_health"
                        max={20}
                        marks={{ 0: '0 (Poor)', 10: '10 (Acceptable)', 20: '20 (Good)' }}
                      />

                      <Slider
                        label="2.2 How good is your physical health?"
                        model="answers.quality_of_life_domain.physical_health"
                        max={20}
                        marks={{ 0: '0 (Poor)', 10: '10 (Acceptable)', 20: '20 (Good)' }}
                      />

                      <Slider
                        label="2.3 How would you rate your overall quality of life?"
                        model="answers.quality_of_life_domain.overall_quality_of_life"
                        max={20}
                        marks={{ 0: '0 (Poor)', 10: '10 (Acceptable)', 20: '20 (Good)' }}
                      />

                      <Slider
                        label="2.4 How would you rate the quality of your housing?"
                        model="answers.quality_of_life_domain.quality_of_housing"
                        max={20}
                        marks={{ 0: '0 (Poor)', 10: '10 (Acceptable)', 20: '20 (Good)' }}
                      />

                      <Slider
                        label="2.5 How would you rate your support network?"
                        model="answers.quality_of_life_domain.support_network"
                        max={20}
                        marks={{ 0: '0 (Poor)', 10: '10 (Acceptable)', 20: '20 (Good)' }}
                      />
                    </div>
                  </FormSection>
                </Section>

                <Divider />

                {/* S3: BARRIERS TO RECOVERY */}
                <Section
                  headingType="h2"
                  title="S3: Barriers to Recovery"
                  scrollview={{
                    id: 'barriers_to_recovery_domain',
                    name: 'Barriers to Recovery',
                    parent: { id: 'domains', name: 'Domains' },
                  }}
                >
                  <FormSection layout="vertical" maxWidth="800px">
                    <H3 className="mt-4">3.1 Housing</H3>

                    <Label label="3.1.1 At any point in the last month have you been:" />

                    <YesNoRadioGroup
                      label="At risk of eviction"
                      model="answers.barriers_to_recovery_domain.at_risk_of_eviction"
                      layout="horizontal-dense"
                    />

                    <YesNoRadioGroup
                      label="Having acute housing problems"
                      model="answers.barriers_to_recovery_domain.having_acute_housing_problems"
                      layout="horizontal-dense"
                    />

                    <Label label="3.1.2 Number of days in last 3 months (90 days) you have been living in:" />

                    <div className="grid grid-cols-1 gap-4 mq600:grid-cols-2 mq800:grid-cols-3">
                      <NumberInput label="Own home" suffix="Days" model="answers.barriers_to_recovery_domain.living_in_days.at_home" />
                      <NumberInput
                        label="Supported Accomodation"
                        suffix="Days"
                        model="answers.barriers_to_recovery_domain.living_in_days.supported_accomodation"
                      />
                      <NumberInput
                        label="Hospital or Detox"
                        suffix="Days"
                        model="answers.barriers_to_recovery_domain.living_in_days.hospital_detox"
                      />
                      <NumberInput
                        label="With family"
                        suffix="Days"
                        model="answers.barriers_to_recovery_domain.living_in_days.with_family"
                      />
                      <NumberInput
                        label="Hostel (Shelter)"
                        suffix="Days"
                        model="answers.barriers_to_recovery_domain.living_in_days.hostel"
                      />
                      <NumberInput
                        label="Treatment Center"
                        suffix="Days"
                        model="answers.barriers_to_recovery_domain.living_in_days.treatment_center"
                      />
                      <NumberInput
                        label="With friends"
                        suffix="Days"
                        model="answers.barriers_to_recovery_domain.living_in_days.with_friends"
                      />
                      <NumberInput
                        label="On streets/rough sleeping"
                        suffix="Days"
                        model="answers.barriers_to_recovery_domain.living_in_days.rough_sleeping"
                      />
                      <NumberInput label="Prison" suffix="Days" model="answers.barriers_to_recovery_domain.living_in_days.prison" />
                      <NumberInput
                        label="Recovery residence"
                        suffix="Days"
                        model="answers.barriers_to_recovery_domain.living_in_days.recovery_residence"
                      />
                    </div>

                    <YesNoRadioGroup
                      label="3.1.3 Do you have problems securing housing because of bad credit or other debt issues?"
                      model="answers.barriers_to_recovery_domain.problems_securing_housing_due_to_debt"
                      layout="horizontal-dense"
                    />

                    <H3 className="mt-4">3.2 Substance use</H3>

                    <YesNoRadioGroup
                      label="Have you used any legal or illegal substances in the last 90 days?"
                      model="answers.barriers_to_recovery_domain.used_substances_in_the_last_ninety_days"
                      layout="horizontal-dense"
                    />

                    <ContextShow when="answers.barriers_to_recovery_domain.used_substances_in_the_last_ninety_days" is={true}>
                      {SUBSTANCES.map((substance: any) => (
                        <FormSection key={substance.model} heading={substance.name} layout="vertical" className="mt-4 ml-4">
                          {substance.specify === true && (
                            <Input
                              label="Please specify the substance"
                              model={`answers.barriers_to_recovery_domain.substances.${substance.model}.specific_substance`}
                            />
                          )}

                          <YesNoRadioGroup
                            label="Ever been a problem?"
                            model={`answers.barriers_to_recovery_domain.substances.${substance.model}.problem`}
                            layout="horizontal-dense"
                          />

                          <ContextShow when={`answers.barriers_to_recovery_domain.substances.${substance.model}.problem`} is={true}>
                            <YesNoRadioGroup
                              label="Used in the last 90 days?"
                              model={`answers.barriers_to_recovery_domain.substances.${substance.model}.has_used_in_the_last_ninety_days`}
                              layout="horizontal-dense"
                            />

                            <ContextShow
                              when={`answers.barriers_to_recovery_domain.substances.${substance.model}.has_used_in_the_last_ninety_days`}
                              is={true}
                            >
                              <NumberInput
                                label="Average daily amount"
                                model={`answers.barriers_to_recovery_domain.substances.${substance.model}.units`}
                                suffix={substance.units}
                              />
                            </ContextShow>
                          </ContextShow>
                        </FormSection>
                      ))}
                    </ContextShow>

                    <ContextShow when="answers.barriers_to_recovery_domain.used_substances_in_the_last_ninety_days" is={true}>
                      <H3 className="mt-4">3.3 Risk taking</H3>

                      <YesNoRadioGroup
                        label="Have you injected drugs in the last 90 days?"
                        model="answers.barriers_to_recovery_domain.risk_taking.has_injected_in_the_last_ninety_days"
                        layout="horizontal-dense"
                      />
                    </ContextShow>

                    <ContextShow when="answers.barriers_to_recovery_domain.risk_taking.has_injected_in_the_last_ninety_days" is={true}>
                      <NumberInput
                        label="How many days have you injected on in the last 90 days or since last REC-CAP Evaluation?"
                        suffix="Days"
                        model="answers.barriers_to_recovery_domain.risk_taking.injected_days"
                      />

                      <YesNoRadioGroup
                        label="Have you injected with a needle or syringe used by someone else, or have you been injected by someone else, within the last 90 days or since last REC-CAP Evaluation?"
                        model="answers.barriers_to_recovery_domain.risk_taking.injected_with_others_needles"
                        layout="horizontal-dense"
                      />

                      <YesNoRadioGroup
                        label="Have you injected using a spoon, water or filter used by someone else within the last 90 days or since last REC-CAP Evaluation?"
                        model="answers.barriers_to_recovery_domain.risk_taking.injected_with_others_spoons"
                        layout="horizontal-dense"
                      />
                    </ContextShow>

                    <H3 className="mt-4">3.4 Involvement with the Criminal Justice System</H3>

                    <YesNoRadioGroup
                      label="Have you been involved in offending within the last 90 days or since last REC-CAP Evaluation?"
                      model="answers.barriers_to_recovery_domain.criminal_justice.offended_in_the_last_ninety_days"
                      layout="horizontal-dense"
                    />

                    <YesNoRadioGroup
                      label="Were you on probation at any time within the last 90 days or since last REC-CAP Evaluation?"
                      model="answers.barriers_to_recovery_domain.criminal_justice.in_probation_in_the_last_ninety_days"
                      layout="horizontal-dense"
                    />

                    <YesNoRadioGroup
                      label="Were you on parole at any time within the last 90 days or since last REC-CAP Evaluation?"
                      model="answers.barriers_to_recovery_domain.criminal_justice.on_parole_in_the_last_ninety_days"
                      layout="horizontal-dense"
                    />

                    <YesNoRadioGroupWithTextarea
                      label="Have you had any other form of involvement with the criminal justice system within the last 90 days or since last REC-CAP Evaluation?"
                      model="answers.barriers_to_recovery_domain.criminal_justice.other_involvement_in_the_last_ninety_days"
                      layout="horizontal-dense"
                    />

                    <H3 className="mt-4">3.5 Work, training, and volunteering</H3>

                    <YesNoRadioGroup
                      label="Are you currently working full-time?"
                      model="answers.barriers_to_recovery_domain.work_training_volunteering.working_full_time"
                      layout="horizontal-dense"
                    />

                    <YesNoRadioGroup
                      label="Are you currently working part-time?"
                      model="answers.barriers_to_recovery_domain.work_training_volunteering.working_part_time"
                      layout="horizontal-dense"
                    />

                    <YesNoRadioGroup
                      label="Are you currently attending school, including online course work?"
                      model="answers.barriers_to_recovery_domain.work_training_volunteering.attending_school"
                      layout="horizontal-dense"
                    />

                    <YesNoRadioGroup
                      label="Are you currently volunteering?"
                      model="answers.barriers_to_recovery_domain.work_training_volunteering.volunteering"
                      layout="horizontal-dense"
                    />
                  </FormSection>
                </Section>

                <Divider />

                {/* S4: SERVICES AND NEEDS DOMAIN  */}
                <Section
                  headingType="h2"
                  title="S4: Services Involvement and Needs"
                  scrollview={{
                    id: 'services_and_needs_domain',
                    name: 'Services Involvement and Needs',
                    parent: { id: 'domains', name: 'Domains' },
                  }}
                >
                  <FormSection layout="vertical" maxWidth="100%">
                    <p>
                      The following table assesses what services you are engaged with and whether your current level of service involvement
                      is meeting your needs.
                    </p>

                    <div className="py-4 overflow-x-auto">
                      <div className={`hidden font-[700] mq800:grid grid-cols-4 gap-4 bg-[#EBEDF5] text-gray-700 p-4 rounded`}>
                        <div>Service</div>
                        <div>Are you currently engaged?</div>
                        <div>Are you satisfied with the service?</div>
                        <div>Do you need help?</div>
                      </div>

                      {/* Drug treatment services */}
                      <div
                        className={`grid grid-cols-1 mq800:grid-cols-4 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                      >
                        <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Drug treatment services</div>

                        {/* Engagement */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you currently engaged?" />
                          <RadioGroup model="answers.services_and_needs_domain.drug_treatment_services.engaged" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Satisfaction */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you satisfied with the service?" />
                          <RadioGroup model="answers.services_and_needs_domain.drug_treatment_services.satisfied" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Need Help */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Do you need help?" />
                          <RadioGroup model="answers.services_and_needs_domain.drug_treatment_services.need_help" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>
                      </div>

                      {/* Alcohol treatment services */}
                      <div
                        className={`grid grid-cols-1 mq800:grid-cols-4 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                      >
                        <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Alcohol treatment services</div>

                        {/* Engagement */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you currently engaged?" />
                          <RadioGroup
                            model="answers.services_and_needs_domain.alcohol_treatment_services.engaged"
                            layout="horizontal-dense"
                          >
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Satisfaction */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you satisfied with the service?" />
                          <RadioGroup
                            model="answers.services_and_needs_domain.alcohol_treatment_services.satisfied"
                            layout="horizontal-dense"
                          >
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Need Help */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Do you need help?" />
                          <RadioGroup
                            model="answers.services_and_needs_domain.alcohol_treatment_services.need_help"
                            layout="horizontal-dense"
                          >
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>
                      </div>

                      {/* Mental health services */}
                      <div
                        className={`grid grid-cols-1 mq800:grid-cols-4 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                      >
                        <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Mental health services</div>

                        {/* Engagement */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you currently engaged?" />
                          <RadioGroup model="answers.services_and_needs_domain.mental_health_services.engaged" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Satisfaction */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you satisfied with the service?" />
                          <RadioGroup model="answers.services_and_needs_domain.mental_health_services.satisfied" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Need Help */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Do you need help?" />
                          <RadioGroup model="answers.services_and_needs_domain.mental_health_services.need_help" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>
                      </div>

                      {/* Housing support */}
                      <div
                        className={`grid grid-cols-1 mq800:grid-cols-4 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                      >
                        <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Housing support</div>

                        {/* Engagement */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you currently engaged?" />
                          <RadioGroup model="answers.services_and_needs_domain.housing_support.engaged" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Satisfaction */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you satisfied with the service?" />
                          <RadioGroup model="answers.services_and_needs_domain.housing_support.satisfied" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Need Help */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Do you need help?" />
                          <RadioGroup model="answers.services_and_needs_domain.housing_support.need_help" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>
                      </div>

                      {/* Employment services */}
                      <div
                        className={`grid grid-cols-1 mq800:grid-cols-4 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                      >
                        <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Employment services</div>

                        {/* Engagement */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you currently engaged?" />
                          <RadioGroup model="answers.services_and_needs_domain.employment_services.engaged" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Satisfaction */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you satisfied with the service?" />
                          <RadioGroup model="answers.services_and_needs_domain.employment_services.satisfied" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Need Help */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Do you need help?" />
                          <RadioGroup model="answers.services_and_needs_domain.employment_services.need_help" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>
                      </div>

                      {/* Primary healthcare services (GP, medical services) */}
                      <div
                        className={`grid grid-cols-1 mq800:grid-cols-4 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                      >
                        <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">
                          Primary Healthcare Services (Family Doctors, Medical Services)
                        </div>

                        {/* Engagement */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you currently engaged?" />
                          <RadioGroup model="answers.services_and_needs_domain.primary_healthcare.engaged" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Satisfaction */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you satisfied with the service?" />
                          <RadioGroup model="answers.services_and_needs_domain.primary_healthcare.satisfied" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Need Help */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Do you need help?" />
                          <RadioGroup model="answers.services_and_needs_domain.primary_healthcare.need_help" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>
                      </div>

                      {/* Family relationships */}
                      <div
                        className={`grid grid-cols-1 mq800:grid-cols-4 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                      >
                        <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Family relationships</div>

                        {/* Engagement */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you currently engaged?" />
                          <RadioGroup model="answers.services_and_needs_domain.family_relationships.engaged" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Satisfaction */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you satisfied with the service?" />
                          <RadioGroup model="answers.services_and_needs_domain.family_relationships.satisfied" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>

                        {/* Need Help */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Do you need help?" />
                          <RadioGroup model="answers.services_and_needs_domain.family_relationships.need_help" layout="horizontal-dense">
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </div>
                      </div>

                      {/* Other specialist help or support */}
                      <div
                        className={`grid grid-cols-1 mq800:grid-cols-4 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                      >
                        <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">
                          Other specialist help or support (please specify):
                        </div>

                        {/* Engagement */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you currently engaged?" />
                          <Textarea className="w-full" model="answers.services_and_needs_domain.other_help.engagement" />
                        </div>

                        {/* Satisfaction */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Are you satisfied with the service?" />
                          <Textarea className="w-full" model="answers.services_and_needs_domain.other_help.satisfied" />
                        </div>

                        {/* Need Help */}
                        <div className="grid gap-0">
                          <Label className="mq800:!hidden" label="Do you need help?" />
                          <Textarea className="w-full" model="answers.services_and_needs_domain.other_help.need_help" />
                        </div>
                      </div>
                    </div>
                  </FormSection>
                </Section>

                <Divider />

                {/* S5: PERSONAL RECOVERY CAPITAL */}
                <Section
                  headingType="h2"
                  title="S5: Personal Recovery Capital"
                  scrollview={{
                    id: 'personal_recovery_domain',
                    name: 'Personal Recovery Capital',
                    parent: { id: 'domains', name: 'Domains' },
                  }}
                >
                  <FormSection layout="vertical">
                    <p>
                      Please read the following statements and check a statement only if you agree with it entirely and unreservedly. Do not
                      linger over the question but give your initial feeling at this moment. If you disagree or are unsure, leave it blank.
                      These should reflect how you currently feel about things that have happened to you in the last 3 months (90 days).
                    </p>

                    <CheckboxGroup
                      layout="vertical-dense"
                      label="Please check all statements you agree with below:"
                      trueIcon="check"
                      falseIcon="cross"
                      falseStyle="faded"
                    >
                      {PERSONAL_RECOVERY_CAPITAL.map((item) => (
                        <Checkbox key={item.model} label={item.label} model={item.model} />
                      ))}
                    </CheckboxGroup>
                  </FormSection>
                </Section>

                <Divider />

                {/* S6: SOCIAL RECOVERY CAPITAL */}
                <Section
                  headingType="h2"
                  title="S6: Social Recovery Capital"
                  scrollview={{
                    id: 'social_recovery_domain',
                    name: 'Social Recovery Capital',
                    parent: { id: 'domains', name: 'Domains' },
                  }}
                >
                  <FormSection layout="vertical">
                    <p>
                      Please read the following statements and check a statement only if you agree with it completely. Do not linger over
                      the question but give your initial feeling at this moment. If you disagree or are unsure, leave it blank. These
                      statements are about how you currently feel and about things that have happened to you in the last 3 months (90 days).
                    </p>

                    <CheckboxGroup
                      layout="vertical-dense"
                      label="Please check all statements you agree with below:"
                      trueIcon="check"
                      falseIcon="cross"
                      falseStyle="faded"
                    >
                      {SOCIAL_RECOVERY_CAPITAL.map((item) => (
                        <Checkbox key={item.model} label={item.label} model={item.model} />
                      ))}
                    </CheckboxGroup>
                  </FormSection>
                </Section>

                <Divider />

                {/* S7: RECOVERY GROUPS AND LOCAL COMMUNITY */}
                <Section
                  headingType="h2"
                  title="S7: Involvement with Recovery Groups and Local Community"
                  scrollview={{
                    id: 'recovery_groups_domain',
                    name: 'Involvement with Recovery Groups',
                    parent: { id: 'domains', name: 'Domains' },
                  }}
                >
                  <FormSection layout="vertical">
                    <Label
                      label="7.1 Please check if you agree with any of the following statements about any group you have attended in the community
                      in the last month to support your recovery. These questions refer to any group – formal or informal – that you attend
                      that supports your recovery, including AA, NA, SMART Recovery, local peer groups, aftercare groups and any other types
                      of recovery group you belong to:"
                    />

                    <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                      {RECOVERY_GROUPS.map((item) => (
                        <Checkbox key={item.model} label={item.label} model={item.model} />
                      ))}
                    </CheckboxGroup>

                    <Textarea
                      label="7.2 Please specify what recovery groups you have attended in the last month"
                      model="answers.recovery_groups_domain.last_month_recovery_groups"
                    />

                    <Textarea
                      label="7.3 Please specify what online recovery groups you have accessed in the last month"
                      model="answers.recovery_groups_domain.online_recovery_groups"
                    />

                    <Label
                      label={
                        <>
                          7.4 Whether or not you are <u>currently</u> using any of the following, do you feel that you need additional
                          support from:
                        </>
                      }
                    />

                    <YesNoRadioGroup
                      label="7.4.1 Peer support"
                      model="answers.recovery_groups_domain.additional_support_from_peer_support"
                      layout="horizontal-dense"
                    />

                    <YesNoRadioGroup
                      label="7.4.2 12-step mutual aid program"
                      model="answers.recovery_groups_domain.additional_support_from_twelve_step_mutual_aid_program"
                      layout="horizontal-dense"
                    />

                    <YesNoRadioGroup
                      label="7.4.3 Other community recovery groups"
                      model="answers.recovery_groups_domain.additional_support_from_other_community_recovery_groups"
                      layout="horizontal-dense"
                    />

                    <YesNoRadioGroup
                      label="7.4.4 Online recovery groups"
                      model="answers.recovery_groups_domain.additional_support_from_online_recovery_groups"
                      layout="horizontal-dense"
                    />

                    <H3 className="mt-8">7.5 How much support do you get from other people?</H3>

                    <p>
                      For each of the questions below, please give a rating on the scale for how you are feeling about the question{' '}
                      <b>today</b>, where higher scores mean you receive more support, and lower scores mean you receive less support.
                      Indicate your score by selecting the number that best describes your feeling.
                    </p>

                    <div className="grid gap-5">
                      <Slider
                        label="7.6 Do you get the emotional support you need from other people?"
                        model="answers.recovery_groups_domain.emotional_support_met"
                        min={1}
                        max={7}
                        marks={{ 1: '1 (Not at All)', 7: '7 (Completely)' }}
                      />

                      <Slider
                        label="7.7 Do you get the help you need from other people?"
                        model="answers.recovery_groups_domain.help_from_others_met"
                        min={1}
                        max={7}
                        marks={{ 1: '1 (Not at All)', 7: '7 (Completely)' }}
                      />

                      <Slider
                        label="7.8 Do you get the resources you need from other people?"
                        model="answers.recovery_groups_domain.resources_from_others_met"
                        min={1}
                        max={7}
                        marks={{ 1: '1 (Not at All)', 7: '7 (Completely)' }}
                      />

                      <Slider
                        label="7.9 Do you get the advice you need from other people?"
                        model="answers.recovery_groups_domain.advice_from_others_met"
                        min={1}
                        max={7}
                        marks={{ 1: '1 (Not at All)', 7: '7 (Completely)' }}
                      />
                    </div>
                  </FormSection>
                </Section>

                <Divider />

                {/* S8: COMMITMENT */}
                <Section
                  headingType="h2"
                  title="S8: Commitment"
                  scrollview={{
                    id: 'commitment_domain',
                    name: 'Commitment',
                    parent: { id: 'domains', name: 'Domains' },
                  }}
                >
                  <FormSection layout="vertical">
                    <p>
                      For each of the questions below, please give a rating on the scale for how you are feeling about the question{' '}
                      <b>today</b>, where higher scores mean you strongly agree and lower scores mean you strongly disagree with this
                      statement. Indicate your score by circling the number that best describes your feeling.
                    </p>

                    <div className="grid gap-5">
                      <Slider
                        label="8.1 Staying sober/clean is the most important thing in my life"
                        model="answers.commitment_domain.sobriaty_importance"
                        min={1}
                        max={5}
                        marks={{ 1: '1 (Strongly Disagree)', 5: '5 (Strongly Agree)' }}
                      />

                      <Slider
                        label="8.2 I am totally committed to staying off of alcohol/drugs"
                        model="answers.commitment_domain.off_drugs_commitment"
                        min={1}
                        max={5}
                        marks={{ 1: '1 (Strongly Disagree)', 5: '5 (Strongly Agree)' }}
                      />

                      <Slider
                        label="8.3 I will do whatever it takes to recover from my addiction"
                        model="answers.commitment_domain.recovery_commitment"
                        min={1}
                        max={5}
                        marks={{ 1: '1 (Strongly Disagree)', 5: '5 (Strongly Agree)' }}
                      />

                      <Slider
                        label="8.4 I never want to return to alcohol/drug use again"
                        model="answers.commitment_domain.no_return_commitment"
                        min={1}
                        max={5}
                        marks={{ 1: '1 (Strongly Disagree)', 5: '5 (Strongly Agree)' }}
                      />

                      <Slider
                        label="8.5 I have had enough alcohol and drugs"
                        model="answers.commitment_domain.had_enough_commitment"
                        min={1}
                        max={5}
                        marks={{ 1: '1 (Strongly Disagree)', 5: '5 (Strongly Agree)' }}
                      />
                    </div>
                  </FormSection>
                </Section>

                <Divider />

                {/* S9: WHAT DO YOU SEE AS YOUR NEEDS */}
                <Section
                  headingType="h2"
                  title="S9: What do you see as your needs?"
                  scrollview={{
                    id: 'needs_domain',
                    name: 'Your Needs',
                    parent: { id: 'domains', name: 'Domains' },
                  }}
                >
                  <FormSection layout="vertical">
                    <p>Please respond to the following questions as fully as you want to</p>

                    <Textarea
                      label="9.1 Where do you see yourself in your recovery journey?"
                      model="answers.needs_domain.self_reflection_recovery_journey"
                    />

                    <Textarea label="9.2 What are your current life goals?" model="answers.needs_domain.current_life_goals" />

                    <Textarea
                      label="9.3 What do you need to help you get to the next goal in your life journey?"
                      model="answers.needs_domain.help_for_next_goal_in_life_journey"
                    />

                    <Textarea
                      label="9.4 Who do you rely on to help you with your recovery?"
                      model="answers.needs_domain.who_do_you_rely_on"
                    />
                  </FormSection>
                </Section>
              </Form>
            </PageGrid>
          </ScrollView>

          {data?.status !== 'signed_off' && !isPortal && (
            <Overlay.Footer withGradient={false}>
              {isEditable && (
                <>
                  <Button
                    label={isNew ? 'Save as Draft' : 'Save Changes'}
                    glyph="check"
                    type="primary"
                    color="green"
                    onClick={save}
                    isLoading={isSaving}
                    isDisabled={isSaving}
                    flex="100 1 auto"
                    permission={isBehave ? true : isNew ? 'recovery_capital_forms.create' : 'recovery_capital_forms.edit'}
                  />

                  {isInvalid && (
                    <Button
                      label="Highlight Required Fields"
                      glyph="view"
                      type="default"
                      color="orange"
                      onClick={onDisabledClick}
                      isDisabled={isSaving}
                    />
                  )}

                  {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
                </>
              )}

              {!isEditable && (
                <>
                  {data?.status === 'draft' && (
                    <>
                      <Button
                        glyph="edit"
                        label="Edit Form"
                        type="default"
                        isDisabled={isSaving}
                        onClick={edit}
                        flex="100 1 auto"
                        permission={isBehave ? true : 'recovery_capital_forms.edit'}
                      />

                      <DeleteDialog
                        title="Delete Recovery Capital Form?"
                        message={`Are you sure you want to delete this form? This action cannot be undone.`}
                        onYes={deleteRecord}
                        permission={isBehave ? true : 'recovery_capital_forms.delete'}
                      >
                        <Button
                          glyph="delete"
                          label="Delete Form…"
                          type="default"
                          color="red"
                          isDisabled={isSaving}
                          fullWidth
                          permission={isBehave ? true : 'recovery_capital_forms.delete'}
                        />
                      </DeleteDialog>
                    </>
                  )}
                </>
              )}
            </Overlay.Footer>
          )}
        </Tabs.Panel>
      </Tabs>
    </Overlay>
  )
}

const STYLES = {
  pageGrid: {
    flex: '1 1 auto',
  },

  nav: {
    [MEDIA_QUERY[3]]: {
      maxWidth: 300,
    },
  },

  formContract: {
    '&:last-of-type': { boxShadow: 'none !important' },
  },
}

export const RecoveryCapitalFormOverlayV1 = withOverlayError(RootRecoveryCapitalFormOverlayV1)
