import React from 'react'
import size from 'lodash/size'

import { usDateTime } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'
import { useSettings } from '../../../hooks/useSettings'
import withSettings from '../../../hocs/withSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import FormSection from '../../../components/Forms/FormSection'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { DataTable } from '../../../components/DataTable/DataTable'
import { MainCell } from '../../../components/DataTable/cells/MainCell'
import { ProgramAssignmentOverlay } from '../../Programs/ProgramAssignmentOverlay'
import { useDataTable } from '../../../components/DataTable/useDataTable'

const AddPhaseButton = ({ match, client }) => (
  <SummonOverlay overlay={<ProgramAssignmentOverlay match={match} dataID="new" initialData={{ reference: client }} position="right" />}>
    <Button label="Add Program Assignment" type="primary" glyph="add" size={300} display="inline-flex" permission="programs.edit" />
  </SummonOverlay>
)

const Programs = ({ match, setStatus }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id } = match.params

  const { timezone } = useSettings()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const [openItem, setOpenItem] = React.useState(null)

  const tableProps = useDataTable({
    name: ['client', resource_id, 'occupancies'],
    endpoint: `/residents/${resource_id}/occupancies`,
    params: { category: 'program_management' },
  })

  const isEmpty = size(tableProps?.data) === 0

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Program Name',
        model: 'place.program.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            isActive={openItem?.id === data.id}
            id={data.id}
            value={value}
            avatar={data?.place?.program?.avatar || ''}
            onClick={() => {
              setOpenItem(data)
            }}
          />
        ),
      },
      {
        title: 'List',
        model: 'place.phase.name',
      },
      {
        title: 'Seat',
        model: 'place.name',
      },
      {
        title: 'Start Date',
        model: 'started_at',
        type: 'date_time',
      },
      {
        title: 'End Date',
        model: 'ended_at',
        formatValue: ({ data, value }) => {
          if (data?.period_type === 'indefinite') return 'Until Discharge'
          return usDateTime(value, timezone)
        },
      },
    ]
  }, [openItem?.id])

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (isEmpty || tableProps?.isLoading) {
    return (
      <State
        isLoading={tableProps?.isLoading}
        isEmpty={isEmpty}
        title="Programs"
        icon="checklist"
        emptyDescription="No lists added yet"
        emptyActions={<AddPhaseButton match={match} client={client} />}
      />
    )
  }

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <DataTable title="Programs" icon="checklist" columns={columns} minHeight={200} {...tableProps} />

          <CardContent>
            <AddPhaseButton match={match} client={client} />
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        key={`summon-${openItem?.id}`}
        isOpen={!!openItem}
        onClose={() => setOpenItem(null)}
        overlay={<ProgramAssignmentOverlay dataID={openItem?.id} position="right" />}
      />
    </>
  )
}

export default withSettings(Programs)
