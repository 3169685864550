import React from 'react'

import { COLORS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Glyph from '../../components/Glyph'
import Loader from '../../components/Loader'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import SummonOverlay from '../../components/SummonOverlay'
import Value from '../../components/Value'
import EVOBNewStatus from '../../components/Statuses/EVOBNewStatus'

import { NewClaimMD } from './components/NewClaimMD'
import { AllClientsTable } from '../../components/Forms/Selectors/tables/AllClientsTable'

const RootEligibilityOverlay = (props: any) => {
  const { data, close, initialModel, isLoading, isSaving, isOverlayLoading, deleteRecord, isDeleting, saveWithData } = useOverlay({
    name: 'insurance_new_evobs',
    endpoint: '/insurance_new_evobs',
    invalidate: 'insurance_new_evobs',
    options: props,
  })

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={80} />
  }

  return (
    <Overlay maxWidth={80} closeOnBackdrop={props.closeOnBackdrop} showBackdrop={props.showBackdrop} onClose={close}>
      <Overlay.Header icon="eligibility" title="Eligibility Check" />

      <Overlay.SubHeader className="relative z-10 !px-3 !py-2 border-b border-0 border-solid border-divider shadow-hard-3">
        <div className="flex flex-nowrap items-center">
          <div className="flex flex-nowrap items-center mr-4">
            <Glyph glyph="selector" size={16} color={COLORS.gray} className="mr-1" />
            <div css={STYLES.triggerLabel}>Status: </div>
            <EVOBNewStatus status={data.status} />
          </div>

          <SummonOverlay
            overlay={
              <SelectorOverlay
                onSelect={async (client) => {
                  await saveWithData({ client_id: client.id })
                }}
              />
            }
          >
            <div css={STYLES.trigger}>
              <Glyph glyph="user_neutral" size={16} color={COLORS.gray} className="mr-1" />
              <div css={STYLES.triggerLabel}>Client: </div>
              {data?.client?.id ? (
                <Value value={data?.client?.name} avatar={data?.client?.avatar} />
              ) : (
                <div css={STYLES.triggerEmpty}>Select…</div>
              )}

              <Glyph glyph="triangle_down" size={10} css={STYLES.triggerTriangle} />
              {isSaving && <Loader size={12} color="violet" className="ml-2" />}
            </div>
          </SummonOverlay>
        </div>
      </Overlay.SubHeader>

      <NewClaimMD data={initialModel} />

      <Overlay.Footer>
        <DeleteDialog
          title="Delete Eligibility Check?"
          message="Are you sure you want to delete this Eligibility Check? This action cannot be undone."
          onYes={deleteRecord}
        >
          <Button
            glyph="delete"
            label="Delete"
            type="default"
            color="red"
            isLoading={isDeleting}
            fullWidth
            permission="shift_notes.delete"
          />
        </DeleteDialog>
      </Overlay.Footer>
    </Overlay>
  )
}

const SelectorOverlay = (props: any) => {
  const { onClose, onSelect } = props

  const handleSelect = (record: any) => {
    onSelect?.(record)
    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" maxWidth={80} onClose={onClose}>
      <Overlay.Header title="Select Client…" icon="clients" />

      <Overlay.Content className="!bg-white">
        <AllClientsTable onSelect={handleSelect} tabsListClassName="!px-3 relative !z-[3]" />
      </Overlay.Content>
    </Overlay>
  )
}

const STYLES = {
  trigger: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.95rem',
    cursor: 'pointer',
  },

  triggerLabel: {
    fontWeight: 600,
    marginRight: '0.5rem',
    display: 'inline-flex',
  },

  triggerEmpty: {
    color: COLORS.blue,
  },

  triggerTriangle: {
    marginLeft: '0.25rem',
  },

  value: {
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    lineHeight: 1,
  },

  valueGraphic: {
    marginRight: '0.35rem',
  },
}

export const EligibilityOverlay = withOverlayError(RootEligibilityOverlay)
