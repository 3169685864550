import create from 'zustand'

type Tab =
  | 'todo'
  | 'calendar'
  | 'meeting'
  | 'onboarding'
  | 'help_center'
  | 'rcm_help_center'
  | 'internal_center'
  | 'narr_center'
  | 'admin_help_center'
  | 'bed_dashboard'
  | 'bookmarks'
  | 'sticky_notes'
  | 'pins'
  | 'quick_text'
  | 'ai_assistant'
  | 'voice_memos'

export const useAppSidebarStore = create((set: any) => ({
  isOpen: false,
  activeTab: '',
  open: (tab?: Tab) => set({ isOpen: true, activeTab: tab || 'todo' }),
  close: () => set({ isOpen: false, activeTab: '' }),
}))
