import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

export const ClinicalMeasuresReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'client',
        model: 'client.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 200,
        id: 'score',
        model: 'score',
        title: 'Score',
      },
      {
        width: 200,
        id: 'created_at',
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Clinical Measures"
      icon="clinical_measurements"
      columns={columns}
      filtersConfig={FILTERS.live_reports.data_forms}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="clinical_measures"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=clinical_measures'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'clinical_measures' }} />
        </>
      }
      {...props}
    />
  )
}
