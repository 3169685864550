import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { COLORS } from '@behavehealth/theme'
import { countWord } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Button, Card, Flex, Glyph, Grid, Icon, Nav, NavGroup, NavItem, Page, PageGrid, State, TreeItem } from '@behavehealth/components'

import AlumniTable from '@behavehealth/components/Tables/AlumniTable'
import ApplicantsTable from '@behavehealth/components/Tables/ApplicantsTable'
import ClientsTable from '@behavehealth/components/Tables/ClientsTable'
import DeclinedClientsTable from '@behavehealth/components/Tables/DeclinedClientsTable'
import EmployeesTable from '@behavehealth/components/Tables/EmployeesTable'
import LocationsTable from '@behavehealth/components/Tables/LocationsTable'

const RESOURCES = {
  employees: {
    name: 'Staff',
    icon: 'employees',
    table: EmployeesTable,
    to: (id: string) => `/employees/${id}`,
  },
  residents: {
    name: 'Clients',
    icon: 'clients',
    table: ClientsTable,
    to: (id: string) => `/clients/${id}`,
  },
  alumni: {
    name: 'Past Client',
    icon: 'alumni_relations',
    table: AlumniTable,
    to: (id: string) => `/alumni/${id}`,
  },
  admissions: {
    name: 'Admissions',
    icon: 'admissions',
    table: ApplicantsTable,
    to: (id: string) => `/admissions/${id}`,
  },
  declined: {
    name: 'Declined Applicants',
    glyph: 'circle_error',
    table: DeclinedClientsTable,
    to: (id: string) => `/admissions/${id}`,
  },
  organizations: {
    name: 'Organizations',
    icon: 'organizations',
    table: ClientsTable,
    to: (id: string) => `/community/organizations/${id}`,
  },
  houses: {
    name: 'Locations',
    icon: 'locations',
    table: LocationsTable,
    to: (id: string) => `/locations/${id}`,
  },
}

const Tags = () => {
  const { data: tagGroups, isLoading: isLoadingTagGroups }: any = useGet({
    name: ['tag-groups'],
    url: `/tag_groups`,
  })

  const isTagGroupsEmpty = size(tagGroups) === 0

  if (isLoadingTagGroups || isTagGroupsEmpty) {
    return (
      <Page feature="tags" title="Tags Report" breakpoint={5}>
        <Card>
          <State
            title="Tags"
            icon="tags"
            isLoading={isLoadingTagGroups}
            isEmpty={isTagGroupsEmpty}
            emptyDescription="No tags have been created yet"
            emptyActions={<Button as={Link} label="Manage Tags" glyph="settings" link={'/settings/tags-manager'} />}
          />
        </Card>
      </Page>
    )
  }

  const firstID = tagGroups[0]?.id

  return (
    <PageGrid pinMobileHeader columns="320px 1fr" breakpoint={5}>
      <Nav title="Tags" icon="tags" breakpoint={5} headingSize={300}>
        {tagGroups.map((tagGroup: any) => (
          <NavGroup skipVisibleCheck key={tagGroup.id} label={tagGroup.name}>
            {size(tagGroup.tags) >= 1 &&
              tagGroup.tags.map((tag: any) => (
                <NavItem
                  as={NavLink}
                  key={tag.id}
                  glyph={tag.glyph}
                  label={tag.name}
                  to={tag.id}
                  glyphColor={tag.color}
                  color={tag.color}
                />
              ))}
          </NavGroup>
        ))}
      </Nav>

      <Routes>
        <Route index element={firstID && <Navigate to={firstID} replace />} />
        <Route path=":id" element={<TagPage />} />
      </Routes>
    </PageGrid>
  )
}

const TagPage = () => {
  const { id }: any = useParams()

  const { data, isLoading }: any = useGet({
    name: ['tag-live-reports', id],
    url: `/tags/${id}/live_reports`,
    options: { enabled: !!id },
  })

  let isEmpty = size(data?.residents) === 0

  return (
    <Page feature="tags" title="Tag Report" breakpoint={5}>
      <Grid>
        {isLoading || isEmpty || !data ? (
          <Card>
            <State
              isLoading={isLoading}
              isEmpty={!isLoading && isEmpty}
              icon="tags"
              title="Tags"
              emptyDescription="This tag hasn't been used anywhere yet"
            />
          </Card>
        ) : (
          <Card>
            {Object.keys(RESOURCES).map((resource) => {
              if (size(data[resource]) === 0) return null

              const config = RESOURCES[resource]
              const TableTag = config.table

              return (
                <TreeItem
                  isOpen
                  key={resource}
                  title={
                    <Flex centerY gap={12}>
                      {config.icon && <Icon icon={config.icon} size={18} />}
                      {config.glyph && <Glyph glyph={config.glyph} size={18} />}
                      {countWord(config.name, size(data[resource]))}
                    </Flex>
                  }
                  shouldIndent={false}
                  css={styles.treeItem}
                >
                  <TableTag
                    data={data[resource]}
                    title={null}
                    to={config.to}
                    hiddenColumns={['tags', 'color_code']}
                    showSettings={false}
                    localStorageKey={false}
                  />
                </TreeItem>
              )
            })}
          </Card>
        )}
      </Grid>
    </Page>
  )
}

const styles = {
  treeItem: {
    '& > button': {
      borderBottom: `1px solid ${COLORS.divider}`,
    },

    '&.is-open': {
      paddingBottom: '0.5rem',
      borderBottom: `2px solid ${COLORS.divider}`,
    },
  },
}

export default withPageError(Tags)
