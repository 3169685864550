import React from 'react'

import RadioGroup from '../RadioGroup'
import Radio from '../Radio'

const ProgramTypeRadioGroup = ({ label, model }) => (
  <RadioGroup label={label} model={model} layout="vertical-dense">
    <Radio label="Inpatient / Residential" value="inpatient" />
    <Radio label="PHP" value="php" />
    <Radio label="Outpatient / IOP" value="outpatient" />
    <Radio label="Sober Living / Chem-Free" value="sober_living" />
    <Radio label="Faith-Based Program" value="faith_based_program" />
    <Radio label="Other" value="other" />
  </RadioGroup>
)

ProgramTypeRadioGroup.defaultProps = {
  label: 'Program Type',
  model: 'program_type',
}

export default ProgramTypeRadioGroup
