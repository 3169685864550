import React from 'react'

import Status from '../../components/Status'
import TipAlert from '../../components/TipAlert'

export const ClaimsSpreadsheetTipAlert = () => {
  return (
    <TipAlert contrast glyph="info" localStorageKey="new_claims_spreadsheet">
      <h3 className="flex items-center text-[1.05rem]">
        Service Lines Spreadsheet
        <Status small label="NEW" color="violet" className="ml-2" />
      </h3>

      <div className="mt-1">
        <div className="text-[1.05rem] mb-1">The Service Lines grid is now a spreadsheet that allows you to:</div>
        <ul className="pl-6 grid gap-1">
          <li>
            <b>double-click</b> or hit{' '}
            <span className="inline-block text-[0.9em] px-[0.35em] rounded-[6px] border-[1px] border-solid border-blue-300 bg-blue-100">
              Enter
            </span>{' '}
            on a cell to edit it
          </li>
          <li>
            <b>navigate</b> using the keyboard arrows keys, or{' '}
            <span className="inline-block text-[0.9em] px-[0.35em] rounded-[6px] border-[1px] border-solid border-blue-300 bg-blue-100">
              Tab
            </span>{' '}
            and{' '}
            <span className="inline-block text-[0.9em] px-[0.35em] rounded-[6px] border-[1px] border-solid border-blue-300 bg-blue-100">
              Shift + Tab
            </span>
          </li>
          <li>
            <b>copy and paste</b> values using the keyboard shortcuts (<b>Windows:</b>{' '}
            <span className="inline-block text-[0.9em] px-[0.35em] rounded-[6px] border-[1px] border-solid border-blue-300 bg-blue-100">
              Ctrl + C
            </span>{' '}
            /{' '}
            <span className="inline-block text-[0.9em] px-[0.35em] rounded-[6px] border-[1px] border-solid border-blue-300 bg-blue-100">
              Ctrl + V
            </span>
            ; <b>Mac:</b>{' '}
            <span className="inline-block text-[0.9em] px-[0.35em] rounded-[6px] border-[1px] border-solid border-blue-300 bg-blue-100">
              Cmd + C
            </span>{' '}
            /{' '}
            <span className="inline-block text-[0.9em] px-[0.35em] rounded-[6px] border-[1px] border-solid border-blue-300 bg-blue-100">
              Cmd + V
            </span>
            )
          </li>
        </ul>
      </div>
    </TipAlert>
  )
}
