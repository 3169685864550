import { CLAIMMD_SERVICE_CODE_GROUP_KEYS } from './components/ClaimMDServiceCodesSelector'

export const formatServiceCodes = (serviceCodeIds = []) => {
  const groups: any = []
  const codes: any = []

  if (!serviceCodeIds || !serviceCodeIds.length) {
    return { groups, codes }
  }

  for (const serviceCodeId of serviceCodeIds) {
    if (CLAIMMD_SERVICE_CODE_GROUP_KEYS.includes(serviceCodeId)) {
      groups.push(serviceCodeId)
    } else {
      codes.push(serviceCodeId)
    }
  }

  return { groups, codes }
}
