import React from 'react'
import sortBy from 'lodash/sortBy'
import { DateTime } from 'luxon'

import { COLORS } from '../../theme'
import { DT, mapToArray } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Button from '../Button'
import Divider from '../Divider'
import Dropdown from '../Dropdown'
import DropdownItem from '../DropdownItem'
import Flex from '../Flex'
import Grid from '../Grid'
import SummonOverlay from '../SummonOverlay'

import MiniCalendar from '../Calendar/MiniCalendar'

import { AppSidebarContent } from './AppSidebarContent'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

const getDaysList = (date: any) => {
  let days = []
  const startDate = date.startOf('month')
  const daysCount = date.endOf('month').day

  for (let i = 0; i < daysCount; i++) {
    days.push(startDate.plus({ days: i }))
  }

  return days
}

const getEventsByDate = (events: any, timezone: any) => {
  const eventsByDate: any = {}

  sortBy(mapToArray(events), 'started_at').forEach((event) => {
    const startedAt = DT(event.started_at, timezone)
    const startedAtKey = startedAt?.toFormat('yyyy-MM-dd')

    if (!eventsByDate[startedAtKey]) eventsByDate[startedAtKey] = []

    eventsByDate[startedAtKey].push(event)
  })

  return eventsByDate
}

type Props = {
  addLabel: string
  builderOverlay: any
  data: any
  isLoading: boolean
  onDatesChange?: any
  recordOverlay: any
  renderEvent: any
  timezone?: string
  title: string
  templates: any
  permission: string
  icon: string
}

const AgendaView: React.FC<Props> = (props) => {
  const {
    addLabel = 'Add',
    builderOverlay,
    data,
    icon,
    isLoading,
    onDatesChange,
    permission,
    recordOverlay,
    renderEvent,
    templates,
    timezone,
    title,
  } = props

  const today = DateTime.local().setZone(timezone)

  const [isRecordOpen, setIsRecordOpen] = React.useState(false)
  const [isBuilderOpen, setIsBuilderOpen] = React.useState(false)
  const [activeRecord, setActiveRecord] = React.useState(null)
  const [initialData, setInitialData] = React.useState(null)
  const [currentDate, setCurrentDate] = React.useState(today)

  const days = getDaysList(currentDate)
  const eventsByDate = getEventsByDate(data, timezone)

  // const [todayRef, setTodayRef]: any = React.useState(null)

  const startDate = currentDate.startOf('month')
  const endDate = currentDate.endOf('month')

  const onNext = () => setCurrentDate(currentDate.plus({ months: 1 }))
  const onPrev = () => setCurrentDate(currentDate.minus({ months: 1 }))
  const onToday = () => setCurrentDate(today)

  const BuilderOverlayTag = builderOverlay
  const RecordOverlayTag = recordOverlay

  const closeRecordOverlay = () => {
    setIsRecordOpen(false)
    setActiveRecord(null)
  }

  const closeBuilderOverlay = () => {
    setIsBuilderOpen(false)
    setInitialData(null)
  }

  // Scroll to current day
  // React.useEffect(() => {
  //   if (!todayRef) return
  //   todayRef?.scrollIntoView?.(true)
  // }, [todayRef])

  React.useEffect(() => {
    // Return dates range for API requests
    if (!onDatesChange) return

    onDatesChange({
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
      currentDate: currentDate.toISODate(),
    })
  }, [onDatesChange, currentDate])

  if (!(renderEvent && recordOverlay && builderOverlay)) return null

  return (
    <>
      <AppSidebarView>
        <AppSidebarHeader title={title} icon={icon} />

        <div css={styles.actionsHeader}>
          <Dropdown label={`Add…`} glyph="add" buttonType="primary" buttonSize={100}>
            <DropdownItem
              label={addLabel}
              glyph="add"
              color="paleBlue"
              onClick={() => {
                setActiveRecord(null)
                setInitialData(null)
                setIsBuilderOpen(true)
              }}
              permission={permission}
            />

            <Divider />

            {templates &&
              mapToArray(templates).map((template) => (
                <DropdownItem
                  key={template.id}
                  label={template.title}
                  icon={icon}
                  color="paleBlue"
                  onClick={() => {
                    setInitialData(template)
                    setIsBuilderOpen(true)
                  }}
                  permission={permission}
                />
              ))}
          </Dropdown>
        </div>

        <AppSidebarContent>
          <div css={styles.subHeader}>
            <div css={styles.monthYear}>
              <span css={styles.month}>{currentDate.toFormat('LLLL')}</span>
              <span css={styles.year}> {currentDate.toFormat('kkkk')}</span>
            </div>

            <Flex css={styles.buttonsGroup}>
              <Button hideLabel color="text" size={100} glyph="chevron_left" glyphSize="1em" onClick={onPrev} isDisabled={isLoading} />
              <Button color="text" label="Today" size={100} onClick={onToday} className="w-auto" isDisabled={isLoading} />
              <Button hideLabel color="text" size={100} glyph="chevron_right" glyphSize="1em" onClick={onNext} isDisabled={isLoading} />
            </Flex>
          </div>

          <MiniCalendar today={today} mode="months" currentDate={currentDate} setCurrentDate={setCurrentDate} />

          <div css={styles.loading} className={isLoading ? 'is-loading' : ''}>
            {days.map((day: any, index) => {
              const isToday = today.toFormat('yyyy-MM-dd') === day.toFormat('yyyy-MM-dd')
              const events = eventsByDate[day.toFormat('yyyy-MM-dd')]

              return (
                <div
                  css={styles.dayBlock}
                  key={`day-${index}`}
                  // ref={isToday ? setTodayRef : undefined}
                >
                  <div css={styles.dateHeader}>
                    <div css={styles.date} className={isToday ? 'is-today' : ''}>
                      {day.toFormat('dd')}
                    </div>

                    <div css={styles.weekDay}>{day.weekdayShort}</div>
                  </div>

                  {events && (
                    <Grid gap="0.4rem" css={styles.eventsList}>
                      {events.map((record: any) => (
                        <React.Fragment key={record.id}>
                          {renderEvent({
                            record,
                            isActive: record.id === activeRecord?.id,
                            onClick: () => {
                              setIsRecordOpen(true)
                              setActiveRecord(record)
                            },
                          })}
                        </React.Fragment>
                      ))}
                    </Grid>
                  )}
                </div>
              )
            })}
          </div>
        </AppSidebarContent>
      </AppSidebarView>

      <SummonOverlay
        isOpen={isRecordOpen}
        onClose={closeRecordOverlay}
        overlay={<RecordOverlayTag dataID={activeRecord?.id || 'new'} initialData={activeRecord} />}
      />

      <SummonOverlay
        isOpen={isBuilderOpen}
        onClose={closeBuilderOverlay}
        overlay={<BuilderOverlayTag dataID="new" initialData={initialData} />}
      />
    </>
  )
}

const styles: any = {
  actionsHeader: {
    padding: '1rem 1rem 0.5rem',
  },

  subHeader: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },

  buttonsGroup: {
    '& > *': {
      marginLeft: -1,
      borderRadius: '0 !important',

      '&:first-child': {
        borderRadius: '5px 0 0 5px !important',
      },

      '&:last-child': {
        borderRadius: '0 5px 5px 0 !important',
      },
    },
  },

  monthYear: {
    fontSize: '1.1rem',
    fontWeight: 400,
  },

  month: {
    fontWeight: 700,
  },

  year: {
    fontSize: '0.95em',
    color: COLORS.textMuted,
  },

  loading: {
    '&.is-loading': {
      pointerEvents: 'none',
      opacity: 0.5,
    },
  },

  dayBlock: {
    display: 'grid',
    borderBottom: `1px solid ${COLORS.divider}`,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },

  eventsList: {
    marginTop: '0.5rem',
  },

  dateHeader: {
    display: 'flex',
    alignItems: 'baseline',
  },

  date: {
    display: 'inline-block',
    fontSize: '1.1rem',
    fontWeight: 600,
    marginRight: '0.4rem',

    '@media (min-width: 1024px)': {
      fontSize: '0.9rem',
    },

    '&.is-today': {
      fontSize: '1rem',
      color: COLORS.white,
      position: 'relative',
      zIndex: 0,
      textAlign: 'center',
      marginBottom: '0.25rem',

      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
        background: COLORS.red,
        width: '1.75rem',
        height: '1.75rem',
        zIndex: -1,
        borderRadius: '50%',
      },
    },
  },

  weekDay: {
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 500,
    color: COLORS.textMuted,
    opacity: 0.7,
  },
}

export default withSettings(AgendaView)
