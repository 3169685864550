import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { invalidateQueries, useGet } from '@behavehealth/hooks/useNewAPI'
import { sleep } from '@behavehealth/utils/functions'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import {
  Alert,
  Button,
  Dropdown,
  DropdownItem,
  Flex,
  HelpTagIframe,
  Icon,
  Link,
  Page,
  PageLayout,
  PageSection,
  SummonOverlay,
  TipAlert,
} from '@behavehealth/components'

import Transactions from '@behavehealth/components/Elements/transactions/Transactions'
import ClientPaymentMethodsSection from '@behavehealth/constructs/Stripe/ClientPaymentMethodsSection'
import OnlinePaymentsCollectionCards from '@behavehealth/constructs/Financials/OnlinePaymentsCollectionCards'
import FinancialPlanCard from '@behavehealth/constructs/Financials/FinancialPlanCard'

import ChargeStatus from '@behavehealth/components/Statuses/ChargeStatus'
import Notifications from '@behavehealth/modules/notifications'
import StripePaymentOverlay from '@behavehealth/components/Stripe/StripePaymentOverlay'

const pageConfig = {
  feature: 'financials',
  help: <HelpTagIframe id="client_financials" />,
  marketingID: 'client_financials',
}

type Props = {
  canCreate: boolean
}

const Financials: React.FC<Props> = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { url } = match
  const { tenant } = useSettings()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const onSuccess = async () => {
    Notifications.send('Refreshing…', 'positive')
    await sleep(5000)

    invalidateQueries(['client', client.id, 'financial_transactions'])
  }

  return (
    <Page {...pageConfig}>
      <PageLayout>
        <PageSection.Header graphic={<Icon icon="checklist" size={20} />} className="!m-0 !-mb-3">
          <PageSection.Title title="Financial Plan Details" />
        </PageSection.Header>

        <FinancialPlanCard client={client} />

        {tenant?.is_stripe_connected && client && <ClientPaymentMethodsSection title="Payment Methods" client={client} tenant={tenant} />}

        {/* Stripe Connection */}
        {!tenant?.is_stripe_connected && (
          <Alert type="warning" glyph="info">
            Set up <Link to="/settings/online-payments">Online Payments</Link> and share the Ledger Link with {client?.name} or family to
            automatically collect payments
          </Alert>
        )}

        <TipAlert contrast type="warning" localStorageKey="financials_changed">
          <b>Please note:</b> Based on feedback received from all of you, we made the next changes to Financials:
          <ol>
            <li>We use one Online Ledger Link per Client</li>
            <li>We added Online Payments to collect Payments without viewing transactions</li>
            <li>
              We replaced <strong>Payers</strong> with <strong>Payment Methods</strong>. It makes Payments and reconciliation easier
            </li>
            <li>Payments can be reconciled against Payment Methods</li>
            <li>
              <strong>Automatic Collection</strong> can be updated anytime, as long as the Status of the Charge is{' '}
              <ChargeStatus status="future" css={{ display: 'inline-block', lineHeight: '1rem' }} />
            </li>
            <li>
              We simplified the Email / Phone Number verification for accessing the Online Ledger, and made it more clear who's Ledger it is
            </li>
            <li>
              We added an Access List, viewable under "Who can access?". Update this by adding new Contacts to this Client, or set
              Organizations as Payers
            </li>
          </ol>
        </TipAlert>

        <PageSection>
          <PageSection.Header
            graphic={<Icon icon="cashbook_green" size={22} />}
            after={
              <Flex centerY gap="0.75rem">
                <SummonOverlay
                  overlay={
                    <StripePaymentOverlay
                      stripeConnectID={tenant?.stripe_account_id}
                      passFees={tenant?.financial_prefs?.pass_fees_to_payers}
                      customer={client}
                      source="EHR"
                      onSuccess={onSuccess}
                    />
                  }
                >
                  <Button
                    label="Collect a Payment"
                    type="primary"
                    glyph="dollar"
                    color="green"
                    size={200}
                    isDisabled={false}
                    isLoading={false}
                    permission="payment_methods.actions.collect_a_payment"
                  />
                </SummonOverlay>
                <Dropdown
                  label="Add New…"
                  testKey="add_new_transaction_dropdown"
                  glyph="add"
                  buttonType="primary"
                  buttonSize={200}
                  permission="ledger.create"
                >
                  <DropdownItem
                    icon="financials"
                    color="green"
                    label="Charges"
                    link={{
                      pathname: `${url}/charge-builder`,
                      parent: match,
                    }}
                  />
                  <DropdownItem
                    icon="financials"
                    color="green"
                    label="Payment"
                    link={{
                      pathname: `${url}/payments/new`,
                      parent: match,
                    }}
                  />
                  <DropdownItem
                    icon="financials"
                    color="green"
                    label="Credit"
                    link={{
                      pathname: `${url}/credits/new`,
                      parent: match,
                    }}
                  />
                  <DropdownItem
                    icon="financials"
                    color="green"
                    label="Refund"
                    link={{
                      pathname: `${url}/refunds/new`,
                      parent: match,
                    }}
                  />
                  <DropdownItem
                    icon="financials"
                    color="green"
                    label="Write-Off"
                    link={{
                      pathname: `${url}/write-offs/new`,
                      parent: match,
                    }}
                  />
                </Dropdown>
              </Flex>
            }
          >
            <PageSection.Title title="Ledger Transactions" />
          </PageSection.Header>

          <PageSection.Content>
            <div className="mb-4">
              <OnlinePaymentsCollectionCards client={client} />
            </div>

            <Transactions
              allowDuplicate
              showReportLinks
              name={['client', resource_id, 'financial-transactions']}
              url={`/residents/${resource_id}/financial_transactions`}
            />
          </PageSection.Content>
        </PageSection>
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(Financials, pageConfig))
