import React from 'react'
import { useParams } from 'react-router-dom'
import { useParams as useParamsV6 } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import FormSection from '../../../components/Forms/FormSection'
import SummonOverlay from '../../../components/SummonOverlay'
import TodoOverlay from '../../../components/Overlays/pages/Calendar/TodoOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { DataTable } from '../../../components/DataTable/DataTable'
import { MainCell } from '../../../components/DataTable/cells/MainCell'
import { TodoPageActions } from '../../Todos/TodoPageActions'
import { useDataTable } from '../../../components/DataTable/useDataTable'

const Todos = ({ client, setStatus, timezone, useV6Router }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = useV6Router ? useParamsV6() : useParams()

  const clientID = client?.id || resource_id

  const tableProps = useDataTable({
    name: ['client', clientID, 'todos'],
    endpoint: `/residents/${clientID}/events`,
    params: { event_type: 'task' },
  })

  const isEmpty = size(tableProps?.data) === 0

  const [openItem, setOpenItem] = React.useState(null)

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        id: 'title',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            isActive={openItem?.id === data.id}
            id={data.id}
            value={data.title}
            onClick={() => {
              setOpenItem(data)
            }}
          />
        ),
      },
      {
        model: 'started_at',
        title: 'Due Date',
        type: 'date',
      },
      {
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [openItem?.id])

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <DataTable title="To-Do's" icon="tasks" columns={columns} minHeight={200} {...tableProps} />

          <CardContent>
            <div className="flex">
              <TodoPageActions
                useSummonOverlay
                showManageLink={false}
                buttonSize={200}
                buttonDisplay="inline-flex"
                data={{ resident: client }}
                clientTrack={client?.client_track}
              />
            </div>
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        key={`summon-${openItem?.id}`}
        isOpen={!!openItem}
        onClose={() => setOpenItem(null)}
        overlay={<TodoOverlay dataID={openItem?.id} />}
      />
    </>
  )
}

export default Todos
