import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { DnDUploader, Card, CardContent, Permission } from '@behavehealth/components'
import { FilesDataTable } from '@behavehealth/constructs/Files/FilesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import Grid from '@behavehealth/components/Grid'

import { Tenant } from '@behavehealth/declarations'

const General: React.FC = () => {
  const match = useRouteMatch()
  const tenant: Tenant = useSelector((state: any) => state.me.tenant)

  const tableProps = useDataTable({
    name: ['facilities', tenant.id, 'files'],
    endpoint: `/facilities/${tenant.id}/documents`,
    updateDeleteEndpoint: '/documents',
    enabled: !!tenant.id,
    params: {
      category: 'general',
    },
    localStorageKey: 'app_files_v1',
  })

  return (
    <Grid gap="1rem">
      <Card>
        <FilesDataTable
          {...tableProps}
          to={(row: any) => `${match.url}/${row.id}`}
          title="Files"
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'companies.files.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />

        <Permission permission="companies.files.create">
          <CardContent>
            <DnDUploader isEditable category="general" resource={tenant} isDisabled={false} queryKey={tableProps.queryKey} />
          </CardContent>
        </Permission>
      </Card>
    </Grid>
  )
}

export default General
