import React from 'react'

import get from 'lodash/get'
import isEqual from 'react-fast-compare'
import lodashIsEqual from 'lodash/isEqual'
import produce from 'immer'

import { COLORS } from '../../../../theme'
import { apiGet } from '../../../../modules/api'
import { DEFAULT_EMPTY_VALUE } from '../../../../utils/constants'
import { encodeObjectToURL } from '../../../../utils/functions'

import { validate } from '../../validators'
import { withFormContext } from '../../context'

import Glyph from '../../../Glyph'
import Search from '../../../Search'
import SelectorBase from '../SelectorBase'
import SmartPortal from '../../../SmartPortal'
import Value from '../../../Value'

import InputSelectorMenu from './InputSelectorMenu'

import { getDefaultGroupBy } from '../SelectorBase'

class InputSelector extends SelectorBase {
  /*
    CUSTOM FUNCTIONS
  */
  queryData = async (query = '') => {
    const types = this.props.type.split('.')

    const params = {
      q: query,
      type: types[0],
      subtype: types[1],
    }

    const response = await apiGet({ url: `/search_by?${encodeObjectToURL(params)}` })

    return response.data.data
  }

  processUpdate = (queueEvent: any) => {
    const vs = { ...this.props.defaultValidations, ...this.props.validations }

    const newState = produce(this.state, (draft: any) => {
      draft.prevValue = draft.value

      draft.model = this.props.model
      draft.object = this.props.value

      if (this.props.modelSelector) {
        draft.value = this.props.value?.[this.props.modelSelector]
      } else {
        draft.value = this.props.value
      }

      draft.isDirty = !isEqual(draft.value, queueEvent?.prevState?.value)
      draft.isPristine = !draft.isDirty

      draft.isRequired = vs?.hasOwnProperty('presence')
      draft.defaultValue = this.props.defaultValue
    })

    this.setState({
      isHighlighted: false,
      model: this.props.model,
      object: newState.object,
      value: newState.value,
      prevValue: newState.prevValue,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
      isRequired: newState.isRequired,
      defaultValue: newState.defaultValue,
    })

    return newState
  }

  processChangeValue = (item: any) => {
    if (this.props.isDisabled) return

    const newState = produce(this.state, (draft: any) => {
      draft.object = item

      if (this.props.modelSelector) {
        draft.value = item?.[this.props.modelSelector]
        draft.search = item?.[this.props.modelSelector]
      } else {
        draft.value = item
        draft.search = item
      }

      draft.isOpen = false
      draft.isDirty = true
      draft.isPristine = false
    })

    this.setState({
      object: newState.object,
      value: newState.value,
      search: newState.search,
      isOpen: newState.isOpen,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
    })

    return newState
  }

  onSearch = async (search: string) => {
    this.setState({ isLoading: true, search: search })

    try {
      const result = await this.queryData(search)
      this.setState({ isLoading: false, apiData: result })
    } catch (error) {
      console.error(error)
      this.setState({ isLoading: false })
    }
  }

  /*
    RENDER
  */
  renderEdit = () => {
    return (
      <Search
        showGraphic={false}
        showClearButton={false}
        placeholder={this.props.placeholder}
        onChange={this.onSearch}
        onFocus={this.open}
        onBlur={() => {
          if (this.state.search === '') this.changeValue(null)
        }}
        value={this.state.value}
        after={
          <div css={styles.menuButton} onClick={this.open}>
            <Glyph glyph="triangle_down" color={COLORS.blue} size={12} />
          </div>
        }
      />
    )
  }

  renderReadOnly = () => {
    const { value } = this.state

    if (value) {
      return <Value value={value} />
    } else return DEFAULT_EMPTY_VALUE
  }

  renderSelector = () => {
    const { icon, type, selectTitle, selectDescription, showAvatars, emptyActions, groupBy } = this.props
    const { apiData } = this.state

    const groupByConfig = groupBy || getDefaultGroupBy(type)

    return (
      <SmartPortal useReferenceWidth portal="selector" position="bottom-start">
        <InputSelectorMenu
          icon={icon}
          type={type}
          items={apiData}
          showAvatars={showAvatars}
          selectTitle={selectTitle}
          selectDescription={selectDescription}
          emptyActions={emptyActions}
          onSelect={(item: any) => {
            this.changeValue(item)
            this.props.onSelect?.(item)
          }}
          onClose={this.close}
          groupBy={groupByConfig}
          isLoading={this.state.isLoading}
        />
      </SmartPortal>
    )
  }
}

const styles = {
  menuButton: {
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderLeft: `1px solid ${COLORS.divider}`,

    '&:hover': {
      background: COLORS.hover,
    },
  },
}

InputSelector.defaultProps = {
  showAs: 'inline',
  modelSelector: 'id',
  showAvatars: true,
  prefetch: false,
  isEditable: true,
  isDisabled: false,
  initialModelRequired: false,
  layout: 'vertical-dense',
  isOpen: false,
  placeholder: '',
  validateOn: 'blur-change',
}

export default withFormContext(InputSelector)
