import React from 'react'

import { countWord } from '../../utils/functions'
import { DEFAULT_FILTERS } from '../Filters/constants'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useSettings } from '../../hooks/useSettings'

import Glyph from '../../components/Glyph'

export const AutoRepeatingChargesDataTable = (props: any) => {
  const { to, isSelectable, ...rest } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={isSelectable ? to?.(data) : undefined} value={value} />
        },
      },
      {
        title: 'Amount',
        model: 'amount',
        hoverExpand: false,
        type: 'amount',
        width: 160,
      },
      {
        title: 'Automatic Collection',
        model: 'should_auto_charge',
        formatValue: ({ value }: any) => {
          return (
            <div>
              <Glyph glyph={value ? 'check' : 'cross'} size={14} /> {value ? 'Yes' : 'No'}
            </div>
          )
        },
      },
      {
        title: 'Payment Method',
        model: 'payment_method',
        formatValue: ({ value }: any) => value?.name || null,
      },
      {
        title: 'Next Renewal Date',
        model: 'transactioned_at',
        type: 'date',
      },
      {
        title: 'Charging Period',
        model: 'end_in_months',
        formatValue: ({ value }: any) => (value ? countWord('Months', value) : null),
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [to, isSelectable, timezone],
  )

  const filtersConfig = React.useMemo(() => {
    if (!props.hiddenColumnIds) return FILTERS_CONFIG

    const res: any = {}

    for (const key in FILTERS_CONFIG) {
      if (props.hiddenColumnIds?.includes?.(key)) continue
      res[key] = FILTERS_CONFIG[key]
    }

    return res
  }, [props.hiddenColumnIds])

  return <DataTable asCard title="Auto-Repeating Services" icon="financials" columns={columns} filtersConfig={filtersConfig} {...rest} />
}

const FILTERS_CONFIG = {
  name: { label: 'Name', type: 'string' },
  amount: { label: 'Amount', type: 'number' },
  should_auto_charge: { label: 'Automatic Collection', type: 'boolean' },
  // payment_method: {},
  transactioned_at: { label: 'Next Renewal Date', type: 'date', glyph: 'date' },
  end_in_months: { label: 'Charging Period', type: 'number' },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
