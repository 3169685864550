import React from 'react'
import size from 'lodash/size'

import { titleCase } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AccordionCard from '../../components/AccordionCard'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Chotomate from '../../components/Chotomate'
import ContextShow from '../../components/Forms/ContextShow'
import DataArray from '../../components/Forms/DataArray'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import DailyNoteStatus from '../../components/Statuses/DailyNoteStatus'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SignaturePad from '../../components/Forms/SignaturePad'
import State from '../../components/State'
import Switch from '../../components/Forms/Switch'
import Permission from '../../components/Permission'

import { COLORS } from '../../theme'
import { TreatmentPlanSelector } from '../../components/Forms/TreatmentPlanSelector'
import { NoteSignees } from '../NotesShared/NoteSignees'
import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'
import ClaimPlaceOfServiceSelect from '../RCM/components/ClaimPlaceOfServiceSelect'
import { InlineInsuranceBillingFormSection } from '../RCM/components/InlineInsuranceBillingFormSection'

import { DiagnosesSelector } from '../Diagnoses/DiagnosesSelector'

const GroupNotes = ({ model, tenant }: any) => (
  <DataArray model={model}>
    {({ orderedIds, add, remove }) => {
      const isEmpty = size(orderedIds) === 0

      return (
        <>
          {orderedIds?.map((id: string, idx: number) => (
            <GroupNote key={id} id={id} idx={idx} tenant={tenant} remove={remove} />
          ))}

          {isEmpty ? (
            <Section>
              <State
                isEmpty
                emptyDescription="No Notes added yet"
                emptyActions={<Button type="default" glyph="add" label="Add Session Note" onClick={add} />}
              />
            </Section>
          ) : (
            <Button type="default" glyph="add" label="Add Session Note" onClick={add} />
          )}
        </>
      )
    }}
  </DataArray>
)

const GroupNote = (props: any) => {
  const { id, idx, tenant, remove } = props

  const [clientId, setClientId] = React.useState<string | null>(null)

  return (
    <AccordionCard
      isOpen
      title={`Note #${idx + 1}`}
      css={{ background: COLORS.lightBackground }}
      aside={
        <Button
          size={200}
          type="minimal"
          glyph="delete"
          color="red"
          label="Remove"
          display="inline-flex"
          onClick={(event: any) => {
            event.preventDefault()
            remove(id)
          }}
        />
      }
    >
      <FormSection maxWidth="100%">
        <Flex gap="1rem">
          <OverlaySelector
            isEditable
            label="Client"
            icon="clients"
            type="clients.current"
            className="!flex-auto"
            model={`${id}.client`}
            selectTitle={(data: any) => data?.name}
            selectDescription={(data: any) => data?.category}
            validations={{
              presence: {
                message: 'Please select a Client',
              },
            }}
            onUpdate={({ object }) => {
              if (!object) return

              setClientId(object.id)
            }}
          />
        </Flex>

        {/* <ContextShow when={`${id}.is_billable`} is={true}>
          <ObjectSelector
            dependent="client_id"
            model={`${id}.insurance_service`}
            label="Rendered Insurance Service"
            type="client.insurance_services"
            icon="billing"
            searchKeys={['code', 'short_description', 'long_description']}
            selectTitle={(o: any) => `${o?.code} - ${o?.short_description}`}
            selectDescription={(o: any) => o?.long_description}
            groupBy={{
              accessor: 'service_type',
              sections: {
                in_network: 'In Network',
                out_of_network: 'Out of Network',
                global_oon: 'Global Out of Network',
              },
            }}
            validations={{
              presence: {
                message: 'Please select a service',
              },
            }}
          />

          <MiniRichTextEditor useDictation model={`${id}.billing_notes`} label="Billing Notes" />

          <Divider className="!m-0" />
        </ContextShow> */}

        <Permission featureFlagV2="diagnoses">
          <DiagnosesSelector baseModel={id} clientId={clientId} />
        </Permission>

        <TreatmentPlanSelector baseModel={id} clientId={clientId} allowed={['peer']} />

        <Flex fixChildrenX gap="1rem" alignItems="flex-end">
          <Input model={`${id}.treatment_goal`} label="Treatment Goal Addressed" className="!flex-auto" />

          <RadioGroup
            label="Presence"
            model={`${id}.is_attending`}
            layout="horizontal-dense"
            trueIcon="check"
            falseIcon="cross"
            validations={{
              presence: {
                message: 'Please set presence',
              },
            }}
          >
            <Radio label="Present" value={true} />
            <Radio label="Absent" value={false} />
          </RadioGroup>
        </Flex>

        <ContextShow when={`${id}.is_attending`} is={true}>
          <Checkbox
            defaultValue={false}
            label="Override Default Session Date & Time"
            model={`${id}.is_custom_time`}
            trueIcon="check"
            falseIcon="cross"
            css={{ flex: '1 1 auto !important' }}
          />
        </ContextShow>

        <ContextShow when={`${id}.is_custom_time`} is={true}>
          <Flex gap="1rem">
            <DateTimeInput
              defaultToNow
              className="!flex-auto"
              model={`${id}.custom_started_at`}
              label="Custom Date & Time"
              validations={{
                presence: {
                  message: 'Please enter a custom date and time',
                },
              }}
            />

            <Input
              model={`${id}.custom_duration`}
              label="Custom Duration"
              suffix="mins"
              type="number"
              min={1}
              size={4}
              validations={{
                presence: {
                  message: 'Please enter a custom duration',
                },
                numericality: {
                  greaterThanOrEqualTo: 1,
                  message: 'The duration must be higher than 0 minutes',
                },
              }}
            />
          </Flex>
        </ContextShow>

        <ContextShow when={`${id}.is_attending`} is={true}>
          <MiniRichTextEditor
            useDictation
            useQuickText
            model={`${id}.assessment`}
            label="Individual Assessment"
            validations={{
              presence: {
                message: 'Please provide an individual assessment',
              },
            }}
          />
        </ContextShow>

        <ContextShow when={`${id}.is_attending`} is={false}>
          <MiniRichTextEditor
            useDictation
            useQuickText
            model={`${id}.absentee_note`}
            label="Absentee Note"
            validations={{
              presence: {
                message: 'Please provide an absentee note',
              },
            }}
          />
        </ContextShow>

        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <h3 className="m-0">Insurance</h3>
            <div className="text-text-muted text-[0.95em]">Activate this section to add any Insurance related data</div>
          </div>
          <Switch horizontal size={200} withHover={false} label={null} model={`${id}.use_insurance`} />
        </Flex>

        <ContextShow when={`${id}.use_insurance`} is={true}>
          <FormSection maxWidth="100%">
            <CheckboxGroup label="Insurance" trueIcon="check" falseIcon="cross" falseStyle="faded">
              <Checkbox defaultValue={false} label="Set as Billable" model={`${id}.is_billable`} />
            </CheckboxGroup>

            <ContextShow when={`${id}.is_billable`} is={true}>
              <InlineInsuranceBillingFormSection isBillable />
            </ContextShow>

            <ClaimPlaceOfServiceSelect model={`${id}.place_of_service`} />

            <OverlaySelector maxWidth="100%" label="Location" type="properties.all" model={`${id}.location`} icon="gps_check_in_out" />
          </FormSection>
        </ContextShow>
      </FormSection>
    </AccordionCard>
  )
}

export const RootDailyNoteGroupBuilderOverlay = (props: any) => {
  const { position = 'right' } = props

  const { isNew, close, form, initialModel, isInvalid, isOverlayLoading, isSaving, onValidationUpdate, saveWithData } = useOverlay({
    name: 'daily_notes',
    endpoint: '/daily_notes/builder',
    invalidate: 'daily_notes',
    options: props,
    onDeleteSuccessful: props.onDeleteSuccessful,
    onSaveSuccessful: props.onSaveSuccessful,
  })

  const { isPortal, timezone, defaultSupervisor, tenant }: any = useSettings()

  const [supervisor, setSupervisor] = React.useState<any>(null)
  const [requireSupervisor, setRequireSupervisor] = React.useState(isNew ? true : !!data?.require_supervisor)

  const save = async () => {
    const formData = form.current.getFormValue()

    if (props.save) {
      await props.save(formData)
      props.onClose?.()
    } else {
      await saveWithData(formData)
    }
  }

  if (isOverlayLoading) {
    return <OverlayLoader position={position} />
  }

  return (
    <Overlay isDirty showBackdrop maxWidth={47} onClose={close} position={position}>
      <Overlay.Header
        icon="daily_notes"
        title="Group Daily Note"
        subtitle={<DailyNoteStatus status={initialModel?.status} />}
        help={<HelpTagIframe id="daily_notes_group" />}
      />

      <Overlay.Content>
        <Chotomate ready name="group_note_overlay" />

        <Form
          isEditable
          getForm={form}
          timezone={timezone}
          initialModel={{
            ...initialModel,
            ...(defaultSupervisor && { supervisor: defaultSupervisor }),
          }}
          onValidationUpdate={onValidationUpdate}
          linked={{ category: 'group_note' }}
        >
          {initialModel?.status === 'updates_required' && (
            <Section>
              <Alert type="negative" glyph="note">
                <MiniRichTextEditor useDictation fullwidth label="Updates Requested before Sign-Off" model="rejected_reason" />
              </Alert>
            </Section>
          )}

          <Section headingType="h2" title="Session Details">
            <FormSection maxWidth="100%">
              <Flex gap="1rem">
                <DateTimeInput
                  defaultToNow
                  className="!flex-auto"
                  model="started_at"
                  label="Default Session Date and Time"
                  validations={{
                    presence: {
                      message: 'Please enter a session date and time',
                    },
                  }}
                />

                <Input
                  model="duration"
                  label="Duration"
                  suffix="mins"
                  type="number"
                  min={1}
                  size={4}
                  validations={{
                    presence: {
                      message: 'Please enter a session duration',
                    },
                    numericality: {
                      greaterThanOrEqualTo: 1,
                      message: 'The duration must be higher than 0 minutes',
                    },
                  }}
                />
              </Flex>

              <FormFutureDateWarning dateLabel="Session Start Date and Time" model="started_at" />

              <Flex gap="1rem" justifyContent="space-between" alignItems="flex-end">
                <ObjectSelector
                  isPolymorphic
                  css={{ flex: '1 1 auto' }}
                  model="supervisor"
                  label="Supervisor"
                  type="employees"
                  icon="employees"
                  selectTitle={(data) => data?.name}
                  selectDescription={(data) => titleCase(data?.position)}
                  validations={
                    requireSupervisor && {
                      presence: {
                        message: 'Please select a supervisor',
                      },
                    }
                  }
                  onUpdate={({ object }) => {
                    setSupervisor(object)
                  }}
                  value={supervisor}
                />

                <CheckboxGroup label=" ">
                  <Checkbox
                    value={requireSupervisor}
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="none"
                    label="Require Supervisor"
                    model="require_supervisor"
                    onChange={(state: any) => {
                      const nextValue = state.value
                      setRequireSupervisor(nextValue)

                      if (nextValue === false) {
                        setSupervisor(null)
                      }
                    }}
                  />
                </CheckboxGroup>
              </Flex>

              <MultiOverlaySelector
                label="Additional Staff Members"
                blankLabel="Select Staff…"
                icon="employees"
                type="employees.active"
                model="employees"
                selectTitle={(data) => data.name}
                selectDescription={(data) => titleCase(data.position)}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section
            headingType="h2"
            title="Session Notes"
            description="Add all clients present to this session and write notes for each of them"
          >
            <FormSection maxWidth="100%">
              <Input model="topic" label="Group Topic" />
              <MiniRichTextEditor useDictation useQuickText model="description" label="Group Discussion" />
              <MiniRichTextEditor useDictation useQuickText model="notes" label="Notes" />

              <GroupNotes model="group_notes" tenant={tenant} />
            </FormSection>
          </Section>

          {!isPortal && (
            <>
              <Divider />

              <Section headingType="h2" title="Signatures">
                <FormSection maxWidth="100%" layout="vertical">
                  <SignaturePad
                    allowPin
                    label="Author Signature"
                    model="employee_signature"
                    signedAtModel="author_signed_at"
                    showAccept={false}
                  />

                  <SignaturePad
                    allowPin
                    isEditable={false}
                    label="Supervisor Signature"
                    model="supervisor_signature"
                    signedAtModel="supervisor_signed_at"
                    showAccept={false}
                  />
                </FormSection>
              </Section>
            </>
          )}

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this note">
            <FormSection maxWidth="100%">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save"
          glyph="check"
          type="primary"
          color="green"
          isLoading={props.isSaving || isSaving}
          onClick={save}
          isDisabled={isInvalid}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const DailyNoteGroupBuilderOverlay = withOverlayError(RootDailyNoteGroupBuilderOverlay)
