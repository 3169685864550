import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { AuthorityFormLevelStatus } from '../AuthorityFormLevelStatus'
import { SignatureUploader } from '../../../components/Forms/SignatureUploader'
import { useSettings } from '../../../hooks/useSettings'

import AddressInput from '../../../components/Forms/AddressInput'
import AmountInput from '../../../components/Forms/AmountInput'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import DateInput from '../../../components/Forms/DateInput'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import EmailInput from '../../../components/Forms/EmailInput'
import Flex from '../../../components/Flex'
import Input from '../../../components/Forms/Input'
import NumberInput from '../../../components/Forms/NumberInput'
import PhoneInput from '../../../components/Forms/PhoneInput'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import RichTextEditor from '../../../components/Forms/RichTextEditor'
import Status from '../../../components/Status'
import Textarea from '../../../components/Forms/Textarea'
import URLInput from '../../../components/Forms/URLInput'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'

import { AttachmentsInput } from './AttachmentsInput'
import { QuestionCard } from './QuestionCard'

export const QuestionRenderer = (props: any) => {
  const { isInfoVisible, question, statusesClassName } = props

  const { isBehave } = useSettings()

  if (!question) return null

  const Tag = isBehave ? 'div' : React.Fragment

  return (
    <Tag>
      {isBehave && isInfoVisible && (
        <div className={clsx('flex items-center flex-nowrap mt-3 flex-[0_0_auto]', statusesClassName)}>
          <Status small label={question.identifier} className="!bg-text-muted !text-white" />

          <AuthorityFormLevelStatus small status={question.level} className="ml-2" />

          {size(question.states) > 0 && (
            <div className="flex items-center">
              {question.states.map((state: any) => (
                <Status key={state} small label={state} color="vividBlue" className="ml-2" />
              ))}
            </div>
          )}

          <Status small color="gray" label={`Form: ${question.formId || '–'}`} className="ml-2" />
          <Status small color="gray" label={`Category: ${question.categoryId || '–'}`} className="ml-2" />
          <Status small color="gray" label={`Subcategory: ${question.subcategoryId || '–'}`} className="ml-2" />
        </div>
      )}

      <Question {...props} />
    </Tag>
  )
}

const Question = React.memo((props: any) => {
  const { authorityName = '[Organization Name]', isEvaluatorOnly, question, isEditable, validations } = props

  const { inputType, identifier } = question

  let label =
    isEvaluatorOnly && question.evaluatorCriteria
      ? question.evaluatorCriteria
      : typeof question.label === 'function'
      ? question.label({ authorityName })
      : question.label || '[…]'

  if (typeof label === 'string' && label.startsWith('"') && label.endsWith('"')) {
    label = label.slice(1, -1)
  }

  const QuestionInput = React.useMemo(() => {
    return QUESTION_COMPONENTS[inputType] || CUSTOM_RENDERERS[identifier] || null
  }, [inputType, identifier])

  if (!QuestionInput) {
    console.debug(`🚨 No renderer found for question identifier ${identifier}`)
    return null
  }

  return <QuestionInput isEditable={isEditable} validations={validations} label={label} model={identifier} question={question} />
})

const RichTextRenderer = React.memo((props: any) => {
  return (
    <div className="pb-2">
      <RichTextEditor
        withHover={false}
        isEditable={props.isEditable}
        validations={props.validations}
        label={props.label}
        model={props.model}
      />
    </div>
  )
})

const BooleanRenderer = React.memo((props: any) => {
  return (
    <YesNoRadioGroup
      withHover={false}
      isEditable={props.isEditable}
      validations={props.validations}
      label={props.label}
      model={props.model}
    />
  )
})

const AmountWithFrequencyRenderer = React.memo((props: any) => {
  return (
    <Flex gap="0.75rem">
      <AmountInput
        withHover={false}
        isEditable={props.isEditable}
        validations={props.validations}
        label={props.label}
        model={props.model}
      />

      <RadioGroup
        withHover={false}
        isEditable={props.isEditable}
        validations={props.validations}
        label="Frequency"
        model={`${props.model}_frequency`}
        layout="horizontal-dense"
      >
        <Radio value="weekly" label="Weekly" />
        <Radio value="biweekly" label="Biweekly" />
        <Radio value="monthly" label="Monthly" />
      </RadioGroup>
    </Flex>
  )
})

const AmountRenderer = React.memo((props: any) => {
  return (
    <AmountInput withHover={false} isEditable={props.isEditable} validations={props.validations} label={props.label} model={props.model} />
  )
})

const AttachmentsRenderer = React.memo((props: any) => {
  return (
    <AttachmentsInput
      withHover={false}
      isEditable={props.isEditable}
      validations={props.validations}
      label={props.label}
      model={props.model}
    />
  )
})

const RichTextAttachmentsRenderer = React.memo((props: any) => {
  return (
    <>
      <div className="grid gap-4 pb-2">
        <RichTextEditor
          withHover={false}
          isEditable={props.isEditable}
          validations={props.validations}
          label={props.label}
          model={props.model}
        />

        <AttachmentsInput
          withHover={false}
          isEditable={props.isEditable}
          validations={props.validations}
          label={props.label}
          model={`${props.model}_attachments`}
        />
      </div>
    </>
  )
})

const AddressRenderer = React.memo((props: any) => {
  return (
    <AddressInput withHover={false} isEditable={props.isEditable} validations={props.validations} label={props.label} model={props.model} />
  )
})

const InputRenderer = React.memo((props: any) => {
  return <Input withHover={false} isEditable={props.isEditable} validations={props.validations} label={props.label} model={props.model} />
})

const InitialsRenderer = React.memo((props: any) => {
  return (
    <Input
      withHover={false}
      isEditable={props.isEditable}
      validations={props.validations}
      label={props.label}
      model={props.model}
      size={6}
      description="Enter your initials"
    />
  )
})

const TextareaRenderer = React.memo((props: any) => {
  return (
    <Textarea withHover={false} isEditable={props.isEditable} validations={props.validations} label={props.label} model={props.model} />
  )
})

const RadioRenderer = React.memo((props: any) => {
  if (size(props.question?.options) === 0) return null

  return (
    <RadioGroup
      withHover={false}
      isEditable={props.isEditable}
      validations={props.validations}
      label={props.label}
      model={props.model}
      layout="vertical-dense"
    >
      {Object.entries(props.question.options).map(([value, label]: any) => (
        <Radio key={value} value={value} label={label} />
      ))}
    </RadioGroup>
  )
})

const CheckboxGroupRenderer = React.memo((props: any) => {
  if (size(props.question?.options) === 0) return null

  return (
    <CheckboxGroup
      withHover={false}
      isEditable={props.isEditable}
      validations={props.validations}
      label={props.label}
      layout="vertical-dense"
    >
      {Object.entries(props.question.options).map(([value, label]: any) => (
        <Checkbox
          key={value}
          label={label}
          model={`${props.model}.${value}`}
          isEditable={props.isEditable}
          trueIcon="check"
          falseIcon="empty_checkbox"
          falseStyle="faded"
        />
      ))}
    </CheckboxGroup>
  )
})

const CheckboxRenderer = React.memo((props: any) => {
  return (
    <Checkbox
      withHover={false}
      isEditable={props.isEditable}
      validations={props.validations}
      label={props.label}
      model={props.model}
      trueIcon="check"
      falseIcon="empty_checkbox"
      falseStyle="faded"
    />
  )
})

const DateRenderer = React.memo((props: any) => {
  return (
    <DateInput withHover={false} isEditable={props.isEditable} validations={props.validations} label={props.label} model={props.model} />
  )
})

const DateTimeRenderer = React.memo((props: any) => {
  return (
    <DateTimeInput
      withHover={false}
      isEditable={props.isEditable}
      validations={props.validations}
      label={props.label}
      model={props.model}
    />
  )
})

const NumberRenderer = React.memo((props: any) => {
  return (
    <NumberInput
      withHover={false}
      isEditable={props.isEditable}
      validations={props.validations}
      label={props.label}
      model={props.model}
      size={6}
    />
  )
})

const UrlRenderer = React.memo((props: any) => {
  return (
    <URLInput withHover={false} isEditable={props.isEditable} validations={props.validations} label={props.label} model={props.model} />
  )
})

const EmailRenderer = React.memo((props: any) => {
  return (
    <EmailInput withHover={false} isEditable={props.isEditable} validations={props.validations} label={props.label} model={props.model} />
  )
})

const PhoneRenderer = React.memo((props: any) => {
  return (
    <PhoneInput withHover={false} isEditable={props.isEditable} validations={props.validations} label={props.label} model={props.model} />
  )
})

const SignatureRenderer = React.memo((props: any) => {
  return (
    <SignatureUploader
      withHover={false}
      isEditable={props.isEditable}
      validations={props.validations}
      label={props.label}
      model={props.model}
      signedAtModel={`${props.model}_signed_at`}
      allowPin={false}
      showAccept={false}
      showSignedBy={false}
    />
  )
})

const QUESTION_COMPONENTS: any = {
  rich_text: RichTextRenderer,
  boolean: BooleanRenderer,
  amount_with_frequency: AmountWithFrequencyRenderer,
  amount: AmountRenderer,
  attachments: AttachmentsRenderer,
  rich_text_attachments: RichTextAttachmentsRenderer,
  address: AddressRenderer,
  input: InputRenderer,
  initials: InitialsRenderer,
  textarea: TextareaRenderer,
  radio: RadioRenderer,
  checkbox_group: CheckboxGroupRenderer,
  checkbox: CheckboxRenderer,
  date: DateRenderer,
  date_time: DateTimeRenderer,
  number: NumberRenderer,
  url: UrlRenderer,
  email: EmailRenderer,
  phone: PhoneRenderer,
  signature: SignatureRenderer,
}

const BH_GAN4TG = React.memo((props: any) => {
  const { label, model } = props

  return (
    <QuestionCard title={label}>
      <Input label="Name" model={`${model}_name`} />
      <Input label="Position title or duties" model={`${model}_position`} />
      <PhoneInput label="Phone" model={`${model}_phone_no`} />
      <EmailInput label="Email" model={`${model}_email`} />
    </QuestionCard>
  )
})

const BH_1Z1CCN = React.memo((props: any) => {
  const { label, model } = props

  return (
    <QuestionCard title={label}>
      <Input label="Name" model={`${model}_name`} />
      <Input label="Position title or duties" model={`${model}_position`} />
      <PhoneInput label="Phone" model={`${model}_phone_no`} />
      <EmailInput label="Email" model={`${model}_email`} />
    </QuestionCard>
  )
})

const BH_8ZFCDL = React.memo((props: any) => {
  const { label, model } = props

  return (
    <>
      <YesNoRadioGroup label={label} model={model} />

      <ContextShow when={model} is={false}>
        <AddressInput label="Mailing Address" model={`${model}_mailing_address`} />
      </ContextShow>
    </>
  )
})

const BH_WPAO7I = React.memo((props: any) => {
  const { label, model } = props

  return (
    <>
      <YesNoRadioGroup label={label} model={model} />

      <ContextShow when={model} is={true}>
        <Textarea label="Other Contacts" model={`${model}_other_contacts`} />
      </ContextShow>
    </>
  )
})

const CUSTOM_RENDERERS: any = {
  BH_GAN4TG: BH_GAN4TG,
  BH_1Z1CCN: BH_1Z1CCN,
  BH_8ZFCDL: BH_8ZFCDL,
  BH_WPAO7I: BH_WPAO7I,
}
