import React from 'react'
import produce from 'immer'

import Button from '../../components/Button'
import Dialog from '../../components/Dialog'
import Overlay from '../../components/Overlay'
import Status from '../../components/Status'
import Workflow from '../../components/Workflow/Workflow'

import { OrganizationWorkflowSubheader } from '../Organizations/OrganizationWorkflowSubheader'

import { AUTHORITY_WORKFLOW_STEPS } from '../OrganizationWorkflows/constants'
import { DataTable } from '../../components/DataTable/DataTable'

import { CommunicationsStep } from './WorkflowSteps/CommunicationsStep'
import { ContactsStep } from './WorkflowSteps/ContactsStep'
import { EventsStep } from './WorkflowSteps/EventsStep'
import { FormSubmissionsStep } from './WorkflowSteps/FormSubmissionsStep'
import { LeadContactFormsStep } from './WorkflowSteps/LeadContactFormsStep'
import { TodosStep } from './WorkflowSteps/TodosStep'
import { useSettings } from '../../hooks'

const stepsReducer = (state: any, payload: any) => {
  const { step, status } = payload
  if (!status || !state.hasOwnProperty(step)) return state

  return produce(state, (draft: any) => {
    draft[step] = status
  })
}

const initialData = {}

const initialSteps = Object.keys(AUTHORITY_WORKFLOW_STEPS).reduce((acc: any, step: any) => {
  acc[step] = 'todo'
  return acc
}, {})

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

export const AuthorityOrganizationWorkflowOverlay = (props: any) => {
  const { workflowConfig, organization, onClose } = props

  const { isBehave } = useSettings()

  const [steps, setSteps] = React.useReducer(stepsReducer, initialSteps)
  const [data, setData] = React.useReducer(dataReducer, initialData)

  if (!workflowConfig) throw new Error('WorkflowConfig is required')
  if (!organization) throw new Error('Organization is required')

  const options = organization.organization_workflow?.options

  if (!options) throw new Error('Organization workflow options are required')

  const { id: identifier, name, glyph } = workflowConfig

  return (
    <Overlay
      fullheight
      showBackdrop
      maxWidth={52}
      position="center"
      onClose={onClose}
      closeWrapper={(closeElement: any) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost if not saved on the Review & Save step"
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={onClose}
        >
          {closeElement}
        </Dialog>
      )}
    >
      <Overlay.Header glyph={glyph} title={name} />

      <OrganizationWorkflowSubheader organization={organization} />

      <Overlay.Content>
        <Workflow
          name={name}
          config={AUTHORITY_WORKFLOW_STEPS}
          visibilityOptions={options?.[`${identifier}`]}
          requiredOptions={options?.[`${identifier}_required_steps`]}
          stepInstructions={options?.[`${identifier}_instructions`]}
        >
          <Workflow.Panel step="organization_lead_forms">
            <Workflow.Header title="Lead Contact Forms" after={<Workflow.Status status={steps.organization_lead_forms} />} />
            <Workflow.Content>
              <LeadContactFormsStep
                organization={organization}
                setStatus={(status: string) => {
                  setSteps({ step: 'organization_lead_forms', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="certifications" permission={isBehave}>
            <Workflow.Header
              title={
                <div className="flex items-center">
                  Certifications <Status small label="Dev" color="gray" className="ml-1.5" />
                </div>
              }
              after={<Workflow.Status status={steps.certifications} />}
            />
            <Workflow.Content>
              <DataTable asCard title="Certifications" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="inspections" permission={isBehave}>
            <Workflow.Header
              title={
                <div className="flex items-center">
                  Site Reviews <Status small label="Dev" color="gray" className="ml-1.5" />
                </div>
              }
              after={<Workflow.Status status={steps.inspections} />}
            />
            <Workflow.Content>
              <DataTable asCard title="Site Reviews" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="grievance_forms" permission={isBehave}>
            <Workflow.Header
              title={
                <div className="flex items-center">
                  Grievance Forms <Status small label="Dev" color="gray" className="ml-1.5" />
                </div>
              }
              after={<Workflow.Status status={steps.grievance_forms} />}
            />
            <Workflow.Content>
              <DataTable asCard title="Grievance Forms" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="incident_reporting" permission={isBehave}>
            <Workflow.Header
              title={
                <div className="flex items-center">
                  Incident Reporting <Status small label="Dev" color="gray" className="ml-1.5" />
                </div>
              }
              after={<Workflow.Status status={steps.incident_reporting} />}
            />
            <Workflow.Content>
              <DataTable asCard title="Incident Reporting" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="studies" permission={isBehave}>
            <Workflow.Header
              title={
                <div className="flex items-center">
                  Studies <Status small label="Dev" color="gray" className="ml-1.5" />
                </div>
              }
              after={<Workflow.Status status={steps.studies} />}
            />
            <Workflow.Content>
              <DataTable asCard title="Studies" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="surveys" permission={isBehave}>
            <Workflow.Header
              title={
                <div className="flex items-center">
                  Surveys <Status small label="Dev" color="gray" className="ml-1.5" />
                </div>
              }
              after={<Workflow.Status status={steps.surveys} />}
            />
            <Workflow.Content>
              <DataTable asCard title="Surveys" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="todos">
            <Workflow.Header title="Todos" after={<Workflow.Status status={steps.todos} />} />
            <Workflow.Content>
              <TodosStep
                organization={organization}
                setStatus={(status: string) => {
                  setSteps({ step: 'todos', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="events">
            <Workflow.Header title="Events" after={<Workflow.Status status={steps.events} />} />
            <Workflow.Content>
              <EventsStep
                organization={organization}
                setStatus={(status: string) => {
                  setSteps({ step: 'events', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="contacts">
            <Workflow.Header title="Contacts" after={<Workflow.Status status={steps.contacts} />} />
            <Workflow.Content>
              <ContactsStep
                organization={organization}
                setStatus={(status: string) => {
                  setSteps({ step: 'contacts', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="communications">
            <Workflow.Header title="Communications" after={<Workflow.Status status={steps.communications} />} />
            <Workflow.Content>
              <CommunicationsStep
                organization={organization}
                setStatus={(status: string) => {
                  setSteps({ step: 'communications', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="financials" permission={isBehave}>
            <Workflow.Header
              title={
                <div className="flex items-center">
                  Financials <Status small label="Dev" color="gray" className="ml-1.5" />
                </div>
              }
              after={<Workflow.Status status={steps.financials} />}
            />
            <Workflow.Content>
              <DataTable asCard title="Financials" icon="financials" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="custom_forms">
            <Workflow.Header title="Custom Forms" after={<Workflow.Status status={steps.custom_forms} />} />
            <Workflow.Content>
              <FormSubmissionsStep
                organization={organization}
                setStatus={(status: string) => {
                  setSteps({ step: 'custom_forms', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {identifier === 'pre_approve' && (
            <Workflow.Panel step="pre_approve">
              <Workflow.Header title="Pre-Approve" />
              <Workflow.Content>
                <Button label="Pre-Approve" type="primary" color="green" glyph="tick" onClick={onClose} />
              </Workflow.Content>
            </Workflow.Panel>
          )}

          {identifier === 'inspect' && (
            <Workflow.Panel step="inspect">
              <Workflow.Header title="Complete Site Review" />
              <Workflow.Content>
                <Button label="Complete Site Review" type="primary" color="green" glyph="tick_circle" onClick={onClose} />
              </Workflow.Content>
            </Workflow.Panel>
          )}

          {identifier === 'approve' && (
            <Workflow.Panel step="approve">
              <Workflow.Header title="Approve" />
              <Workflow.Content>
                <Button label="Approve" type="primary" color="green" glyph="tick_circle" onClick={onClose} />
              </Workflow.Content>
            </Workflow.Panel>
          )}

          {identifier === 're_approve' && (
            <Workflow.Panel step="re_approve">
              <Workflow.Header title="Re-Approve" />
              <Workflow.Content>
                <Button label="Re-Approve" type="primary" color="green" glyph="reset" onClick={onClose} />
              </Workflow.Content>
            </Workflow.Panel>
          )}

          {identifier === 'deny' && (
            <Workflow.Panel step="deny">
              <Workflow.Header title="Deny" />
              <Workflow.Content>
                <Button label="Deny" type="primary" color="red" glyph="cross" onClick={onClose} />
              </Workflow.Content>
            </Workflow.Panel>
          )}

          {identifier === 'ban' && (
            <Workflow.Panel step="ban">
              <Workflow.Header title="Ban" />
              <Workflow.Content>
                <Button label="Ban" type="primary" color="red" glyph="decline" onClick={onClose} />
              </Workflow.Content>
            </Workflow.Panel>
          )}
        </Workflow>
      </Overlay.Content>
    </Overlay>
  )
}
