import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { apiUpdate } from '@behavehealth/modules/api'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'

import {
  Accordion,
  AddressSelectorInput,
  Chotomate,
  ContextShow,
  Divider,
  EmailInput,
  Flex,
  Form,
  FormSection,
  Grid,
  HelpTagIframe,
  Input,
  Option,
  Page,
  Permission,
  PhoneInput,
  Select,
  Status,
  Textarea,
  URLInput,
} from '@behavehealth/components'

import { ORGANIZATIONS_TYPE_VENDORS, ORGANIZATIONS_TYPE_RESOURCES, ORGANIZATIONS_TYPE_PROVIDERS } from '@behavehealth/utils/constants'

const RootGeneralInfo: React.FC = () => {
  const match: any = useRouteMatch()
  const id = match.params?.resource_id

  const { isBehave } = useSettings()

  const { data: organization, isLoading }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const { mutateAsync: updateOrganization }: any = useUpdate({
    name: ['organization', id],
    url: `/organizations/${id}`,
    invalidate: ['organizations'],
  })

  if (!organization || isLoading) return null

  const hasSharedInPartner = !!organization?.other_partner?.id

  return (
    <Page feature="general_info" help={<HelpTagIframe id="organization_general_info" />}>
      <Chotomate name="general_info" ready={organization} />

      <Grid gap="1.5rem">
        <div>
          {/* Organization Details */}
          <Accordion
            isOpen
            activateEditMode
            title="Organization Details"
            initialModel={organization}
            glyph="organization_details"
            permission="organizations.general_info.organization_details.view"
            editPermission="organizations.general_info.organization_details.edit"
            onSubmit={updateOrganization}
          >
            <Form>
              <FormSection labelWidth="150px">
                <Input label="Name" model="name" description="Partner name cannot be changed in an active partnership" />

                <Select
                  label="Type"
                  model="category"
                  defaultValue="vendor"
                  validations={{
                    presence: {
                      message: 'Please select an organization type',
                    },
                  }}
                >
                  <Option label="Vendor" value="vendor" />
                  <Option label="Community Resource" value="resource" />
                  <Option label="Provider" value="provider" />
                </Select>

                <ContextShow when="category" is="vendor">
                  <Select
                    label="Category"
                    model="subcategory"
                    defaultValue="billing_service"
                    validations={{
                      presence: {
                        message: 'Please select an organization category',
                      },
                    }}
                  >
                    {Object.keys(ORGANIZATIONS_TYPE_VENDORS).map((vendor) => (
                      <Option label={ORGANIZATIONS_TYPE_VENDORS[vendor]} value={vendor} />
                    ))}
                  </Select>
                </ContextShow>

                <ContextShow when="category" within="resource">
                  <Select
                    label="Category"
                    model="subcategory"
                    defaultValue="general_resource"
                    validations={{
                      presence: {
                        message: 'Please select an organization category',
                      },
                    }}
                  >
                    {Object.keys(ORGANIZATIONS_TYPE_RESOURCES).map((resource) => (
                      <Option label={ORGANIZATIONS_TYPE_RESOURCES[resource]} value={resource} />
                    ))}
                  </Select>
                </ContextShow>

                <ContextShow when="category" within="provider">
                  <Select
                    label="Category"
                    model="subcategory"
                    defaultValue="sober_living_home"
                    validations={{
                      presence: {
                        message: 'Please select an organization category',
                      },
                    }}
                  >
                    {Object.keys(ORGANIZATIONS_TYPE_PROVIDERS).map((provider) => (
                      <Option label={ORGANIZATIONS_TYPE_PROVIDERS[provider]} value={provider} />
                    ))}
                  </Select>
                </ContextShow>

                {isBehave && (
                  <Select
                    label="Relationship Type"
                    model="relationship"
                    validations={{
                      presence: {
                        message: 'Please select a relationship type',
                      },
                    }}
                    labelAfter={<Status small label="BH Only" glyph="behave_health" color="blue" />}
                  >
                    <Option label="Internal" value="internal" />
                    <Option label="Provider" value="provider" />
                    <Option label="Vendor" value="vendor" />
                    <Option label="Authority" value="authority" />
                  </Select>
                )}
              </FormSection>
            </Form>
          </Accordion>

          {/* Contact Details */}
          <Accordion
            glyph="contact_details"
            initialModel={organization}
            title="Contact Details"
            permission="organizations.general_info.contact_details.view"
            editPermission="organizations.general_info.contact_details.edit"
            activateEditMode
            onSubmit={updateOrganization}
          >
            <Form>
              <FormSection>
                <Flex gap="0.5rem" stretchChildrenX>
                  <Input label="Contact First Name" model="contact_first_name" />
                  <Input label="Contact Last Name" model="contact_last_name" />
                </Flex>

                <EmailInput label="Email Address" model="email" />
                <PhoneInput label="Phone Number" model="phone_no" />
                <Input glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />

                <Input glyph="fax" label="Fax" model="fax" />
                <URLInput glyph="website" label="Website" model="website_url" />

                <Permission newFeatureFlag="zoom_gmeet">
                  <Divider className="m-0" />
                  <h3>Social</h3>
                  <Grid vertical gap="1rem" columns="1fr 1fr 1fr">
                    <URLInput glyph="facebook" label="Facebook" model="facebook" />
                    <URLInput glyph="instagram" label="Instagram" model="instagram" />
                    <URLInput glyph="googleplus" label="Google Business" model="google_business" />
                  </Grid>

                  <Grid vertical gap="1rem" columns="1fr 1fr">
                    <URLInput glyph="whatsapp" label="WhatApp" model="whatsapp" />
                    <URLInput glyph="zoom" label="Zoom" model="zoom" />
                  </Grid>

                  <Grid vertical gap="1rem" columns="1fr 1fr">
                    <URLInput glyph="googleplus" label="Google Meet" model="google_meet" />
                    <URLInput glyph="skype" label="Skype" model="skype" />
                  </Grid>
                </Permission>
              </FormSection>
            </Form>
          </Accordion>

          {/* Address */}
          <Accordion title="Address" initialModel={organization} glyph="address" permission="organizations.general_info.address.view">
            <FormSection labelWidth="150px">
              <AddressSelectorInput
                actionLabel="Update Address"
                value={organization?.address}
                permission="organizations.general_info.address.edit"
                coords={{
                  lat: organization?.lat,
                  lon: organization?.lon,
                }}
                afterAddressSelect={(address) =>
                  apiUpdate({
                    name: 'organizations',
                    url: `/organizations/${organization.id}`,
                    params: { address: address, lat: address?.coords?.lat, lon: address?.coords?.lon },
                  })
                }
              />
            </FormSection>
          </Accordion>

          {/* Additional Information */}
          <Accordion
            activateEditMode
            title="Notes"
            initialModel={organization}
            glyph="additional_information"
            permission="organizations.general_info.notes.view"
            editPermission="organizations.general_info.notes.edit"
            onSubmit={updateOrganization}
          >
            <Form>
              <Textarea useQuickText label="Notes" model="notes" minRows={4} maxWidth="100%" />
            </Form>
          </Accordion>
        </div>
      </Grid>
    </Page>
  )
}

export const GeneralInfo = withPageError(RootGeneralInfo)
