import React from 'react'
import { DT, daysToWords, isPast } from '../../../utils/functions'

import Status from '../../../components/Status'

export const CountdownStatus = ({ status, color = 'gray', completedAt, lockStatus, timezone }: any) => {
  // when should
  const willLockAt = DT(completedAt, timezone).plus({ hours: 72 })
  const [lockingWhen, setLockingWhen] = React.useState(daysToWords(willLockAt))

  const isLocked = lockStatus === 'locked' || isPast(willLockAt)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setLockingWhen(daysToWords(willLockAt))
    }, 60 * 5 * 1000)

    return () => clearInterval(interval) // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  if (status !== 'completed') return null
  if (isLocked) return <Status color="green" label="Locked" />

  return <Status color={color} label={`Will lock ${lockingWhen}`} />
}
