import React from 'react'

import { daysBetween, countWord } from '../../../utils/functions'

import AmountInput from '../../../components/Forms/AmountInput'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContactInputs from '../../../components/Forms/elements/ContactInputs'
import Contacts from '../../../components/SmartContent/Contacts'
import ContextShow from '../../../components/Forms/ContextShow'
import DateInput from '../../../components/Forms/DateInput'
import FormCard from '../../../components/Forms/FormCard'
import FormSection from '../../../components/Forms/FormSection'
import Input from '../../../components/Forms/Input'
import Markup from '../../../components/Markup'
import Medications from '../../../components/SmartContent/Medications'
import Option from '../../../components/Forms/Option'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import RichTextEditor from '../../../components/Forms/RichTextEditor'
import Select from '../../../components/Forms/Select'
import SignaturePad from '../../../components/Forms/SignaturePad'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../../components/Forms/elements/YesNoRadioGroupWithTextarea'

import { SNAP_FIELDS } from './common'

export const DISCHARGE_SUMMARY_SECTIONS = [
  {
    title: 'Discharge Info',
    model: 'discharge_info',
    fields: [
      {
        label: 'Admission Date',
        model: 'admitted_at',
        component: ({ client }: any) => (
          <DateInput label="Admission Date" model="data.general.admitted_at" value={client?.current_admission?.admitted_at} />
        ),
      },
      {
        label: 'Discharge Date',
        model: 'discharged_at',
        component: ({ timezone }: any) => (
          <DateInput
            label="Discharge Date"
            model="data.general.discharged_at"
            smartDescription={(date, model) => {
              if (model?.data?.general?.admitted_at) {
                const length: any = daysBetween(model.data.general.admitted_at, date, timezone)
                return `Length of Stay: ${countWord('days', length)}`
              } else {
                return `Select an Admission and Discharge Date to calculate the stay`
              }
            }}
          />
        ),
      },
      {
        label: 'Date of Birth',
        model: 'dob',
        component: ({ client }: any) => <DateInput isEditable={false} label="Date of Birth" model="data.general.dob" value={client?.dob} />,
      },
      {
        label: 'Relapse Status',
        model: 'relapse_status',
        component: () => (
          <RadioGroup label="Relapse Status" model="data.general.relapse_status" layout="vertical-dense">
            <Radio label="Relapsed" value="relapsed" />
            <Radio label="Did not Relapse" value="not_relapsed" />
            <Radio label="Unknown" value="unknown" />
          </RadioGroup>
        ),
      },
      {
        label: 'Discharge Type',
        model: 'discharge_type',
        component: () => (
          <>
            <RadioGroup label="Discharge Type" model="data.general.discharge_type" layout="vertical-dense">
              <Radio label="Successful Program Completion" value="successfully_completed" />
              <Radio label="Successful Discharge" value="successful_discharge" />
              <Radio label="Unsuccessful Discharge With Option to Return" value="unsuccessful_discharge_with_option_to_return" />
              <Radio label="Unsuccessful Discharge Without Option to Return" value="unsuccessful_discharge_without_option_to_return" />
              <Radio label="Therapeutic Discharge" value="therapeutic_discharge" />
              <Radio label="Administrative Discharge" value="administrative_discharge" />
              <Radio label="Received Alternative Services" value="received_alternative_services" />
              <Radio label="Non-compliance With Program" value="non_compliance_with_program" />
              <Radio label="Needs Cannot Be Met by Program" value="needs_cannot_be_met_by_program" />
              <Radio label="Criminal Activity/Destruction of Property" value="criminal_activity_destruction_of_property" />
              <Radio label="Disagreement With Program Rules" value="disagreement_with_program_rules" />
              <Radio label="Whereabouts Unknown" value="whereabouts_unknown" />
              <Radio label="AMA" value="ama" />
              <Radio label="MIA" value="mia" />
              <Radio label="Death" value="death" />
              <Radio label="Other" value="other" />
            </RadioGroup>

            <ContextShow when="data.general.discharge_type" is="other">
              <SmartTextarea useDictation label="Other:" model="data.general.discharge_type_other" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Reason for placement',
        model: 'placement_reason',
        component: () => <SmartTextarea useDictation label="Reason for placement" model="data.general.placement_reason" />,
      },
      {
        label: 'Reason for discharge',
        model: 'discharge_reason',
        component: () => <SmartTextarea useDictation label="Reason for discharge" model="data.general.discharge_reason" />,
      },
      {
        label: 'Discharge Summary',
        model: 'discharge_summary',
        component: () => <SmartTextarea useDictation label="Discharge Summary" model="data.general.discharge_summary" />,
      },
      {
        label: 'Recommendations / Resources',
        model: 'recommendations_resources',
        component: () => <SmartTextarea useDictation label="Recommendations / Resources" model="data.general.recommendations_resources" />,
      },
      {
        label: 'Balance at Time of Discharge',
        model: 'balance_amount_at_discharge',
        component: () => <AmountInput label="Balance at Time of Discharge" model="data.general.balance_amount_at_discharge" />,
      },
      {
        label: 'Has the client collected all belongings?',
        model: 'did_collect_belongings',
        component: () => (
          <>
            <YesNoRadioGroup label="Has the client collected all belongings?" model="data.general.did_collect_belongings" />

            <ContextShow when="data.general.did_collect_belongings" is={false}>
              <DateInput
                label="What is the last date the client can come to receive their belongings?"
                model="data.general.belongings_last_collection_date"
              />
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Medications at Time of Discharge',
    model: 'medications',
    fields: [
      {
        label: 'Medications',
        model: 'medications',
        component: ({ client, isEditable }: any) => (
          <Medications
            model="data.medications.meds"
            isEditable={isEditable}
            client={client}
            icon="medications"
            title="Medications"
            emptyDescription="No medications added yet"
          />
        ),
      },
    ],
  },
  {
    title: 'Contacts',
    model: 'contacts',
    fields: [
      {
        label: 'Contacts',
        model: 'contacts',
        component: ({ client, isEditable }: any) => (
          <Contacts
            model="data.contacts.contacts"
            isEditable={isEditable}
            client={client}
            icon="contacts"
            title="contacts"
            emptyDescription="No contacts added yet"
          />
        ),
      },
    ],
  },
  {
    title: 'Strengths, Needs, Abilities, Preferences',
    model: 'snap',
    fields: SNAP_FIELDS,
  },
  {
    title: 'Summary of Provided Services',
    model: 'provided_services',
    fields: [
      {
        label: 'Summary of provided services',
        model: 'provided_services',
        component: () => (
          <>
            <CheckboxGroup label="Summary of provided services:" layout="vertical-dense">
              <Checkbox label="Supportive Housing" model="data.provided_services.supportive_housing" />
              <Checkbox label="Alternative Support Groups" model="data.provided_services.alternative_support_groups" />
              <Checkbox label="Holistic Services" model="data.provided_services.holistic_services" />

              <Checkbox label="Assessment and Evaluation" model="data.provided_services.assessment_and_evaluation" />
              <Checkbox label="Psychiatric Assessment" model="data.provided_services.psychiatric_assessment" />
              <Checkbox label="Medication Management" model="data.provided_services.medication_management" />
              <Checkbox label="Case Management" model="data.provided_services.case_management" />
              <Checkbox label="Group Therapy" model="data.provided_services.group_psychotherapy" />
              <Checkbox label="Family Therapy" model="data.provided_services.family_therapy" />
              <Checkbox label="Individual Therapy" model="data.provided_services.individual_counseling" />
              <Checkbox label="Co-occurring Disorder Education" model="data.provided_services.co_occurring_disorder_education" />
              <Checkbox label="Life Skills" model="data.provided_services.life_skills" />
              <Checkbox label="Nutrition Education" model="data.provided_services.nutrition_education" />
              <Checkbox label="Trauma Focused Therapy" model="data.provided_services.trauma_focused_therapy" />
              <Checkbox label="12 Step Meetings" model="data.provided_services.12_step_meetings" />
              <Checkbox label="Supported Education & Employment Program" model="data.provided_services.supported_education_and_employment_program" />
              <Checkbox label="Community-Based Psychiatric Rehabilitation and Support Services (CBPR&S)" model="data.provided_services.cbprss" />
              <Checkbox label="Parent-Child Interactive Therapy (PCIT)" model="data.provided_services.pcit" />
              <Checkbox label="Community Center" model="data.provided_services.community_center" />
              <Checkbox label="Other" model="data.provided_services.other" />
            </CheckboxGroup>

            <ContextShow when="data.provided_services.other" is={true}>
              <SmartTextarea useDictation label="Other:" model="data.provided_services.other_extra" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Summary',
        model: 'summary',
        component: () => (
          <SmartTextarea
            useDictation
            label="Summary (include any special needs or concerns):"
            model="data.provided_services.summary"
            maxWidth="500px"
          />
        ),
      },
      {
        label: 'Does client have any unresolved issues that could hinder continued recovery?',
        model: 'has_unresolved_issues',
        component: () => (
          <>
            <RadioGroup
              label="Does client have any unresolved issues that could hinder continued recovery?"
              model="data.provided_services.has_unresolved_issues"
              layout="horizontal"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.provided_services.has_unresolved_issues" is={true}>
              <SmartTextarea useDictation label="Please explain:" model="data.provided_services.has_unresolved_issues_extra" />
            </ContextShow>
          </>
        ),
      },
      {
        label: "Please rate the client's program compliance",
        model: 'client_overall_compliance',
        component: () => (
          <RadioGroup
            label="Please rate the client's program compliance:"
            model="data.provided_services.client_overall_compliance"
            layout="horizontal-wrap"
          >
            <Radio label="Poor" value="poor" />
            <Radio label="Fair" value="fair" />
            <Radio label="Good" value="good" />
            <Radio label="Excellent" value="excellent" />
          </RadioGroup>
        ),
      },
      {
        label: "Please rate the client's prognosis for motivation",
        model: 'client_motivation',
        component: () => (
          <RadioGroup
            label="Please rate the client's prognosis for motivation:"
            model="data.provided_services.client_motivation"
            layout="horizontal-wrap"
          >
            <Radio label="Poor" value="poor" />
            <Radio label="Fair" value="fair" />
            <Radio label="Good" value="good" />
            <Radio label="Excellent" value="excellent" />
          </RadioGroup>
        ),
      },
      {
        label: "Please rate the client's prognosis for medication compliance",
        model: 'client_medication_compliance',
        component: () => (
          <RadioGroup
            label="Please rate the client's prognosis for medication compliance:"
            model="data.provided_services.client_medication_compliance"
            layout="horizontal-wrap"
          >
            <Radio label="Poor" value="poor" />
            <Radio label="Fair" value="fair" />
            <Radio label="Good" value="good" />
            <Radio label="Excellent" value="excellent" />
          </RadioGroup>
        ),
      },
      {
        label: "Please rate the client's prognosis for continued recovery",
        model: 'client_continued_recovery',
        component: () => (
          <RadioGroup
            label="Please rate the client's prognosis for continued recovery:"
            model="data.provided_services.client_continued_recovery"
            layout="horizontal-wrap"
          >
            <Radio label="Poor" value="poor" />
            <Radio label="Fair" value="fair" />
            <Radio label="Good" value="good" />
            <Radio label="Excellent" value="excellent" />
          </RadioGroup>
        ),
      },
    ],
  },
  {
    title: 'Aftercare Plan',
    model: 'aftercare_plan',
    fields: [
      {
        label: 'Please select all that apply',
        model: 'applicable_aftercare_plan',
        component: () => (
          <>
            <CheckboxGroup label="Please select all that apply:" layout="vertical-dense">
              <Checkbox label="Transfer to another facility" model="data.aftercare_plan.transfer_to_another_facility" />
              <Checkbox label="Supportive Housing" model="data.aftercare_plan.supportive_housing" />
              <Checkbox label="Alternative Support Groups" model="data.aftercare_plan.alternative_support_groups" />
              <Checkbox label="Holistic Services" model="data.aftercare_plan.holistic_services" />
              <Checkbox label="Other Outpatient" model="data.aftercare_plan.other_outpatient" />
              <Checkbox label="Past Client Program Participation" model="data.aftercare_plan.alumni_program_participation" />
              <Checkbox label="Independent residence" model="data.aftercare_plan.independent_residence" />
              <Checkbox label="Involvement in 12 step meetings" model="data.aftercare_plan.involvement_in_12_step_meetings" />
              <Checkbox label="Outpatient psychiatric" model="data.aftercare_plan.outpatient_psychiatric" />
              <Checkbox label="Outpatient medical provider" model="data.aftercare_plan.outpatient_medical_provider" />
              <Checkbox label="Outpatient therapist" model="data.aftercare_plan.outpatient_therapist" />
              <Checkbox label="Other" model="data.aftercare_plan.other" />
            </CheckboxGroup>

            <ContextShow when="data.aftercare_plan.other" is={true}>
              <SmartTextarea useDictation label="Other:" model="data.aftercare_plan.other_extra" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Goals achieved while in the program',
        model: 'goals_achieved_while_in_program',
        component: () => (
          <SmartTextarea
            useDictation
            label="Goals achieved while in the program:"
            model="data.aftercare_plan.goals_achieved_while_in_program"
          />
        ),
      },
      {
        label: 'Goals for continuing care and recovery',
        model: 'continuing_care_and_recovery_goals',
        component: () => (
          <SmartTextarea
            useDictation
            label="Goals for continuing care and recovery:"
            model="data.aftercare_plan.continuing_care_and_recovery_goals"
          />
        ),
      },
      {
        label: 'Please list any other referrals that have been given',
        model: 'referrals_given',
        component: () => (
          <SmartTextarea
            useDictation
            label="Please list any other referrals that have been given:"
            model="data.aftercare_plan.referrals_given"
          />
        ),
      },
    ],
  },
  {
    title: 'Relapse Prevention Plan',
    model: 'relapse_prevention_plan',
    fields: [
      {
        label: 'Custom Plan',
        model: 'custom_plan',
        component: () => <SmartTextarea useDictation useQuickText label="Custom Plan:" model="data.relapse_prevention_plan.custom_plan" />,
      },
      {
        label: 'Five warning signs that I might use',
        model: 'five_warning_signs',
        component: () => (
          <SmartTextarea
            useDictation
            label="Five warning signs that I might use:"
            model="data.relapse_prevention_plan.five_warning_signs"
          />
        ),
      },
      {
        label: 'Five people who I can call to help me get through a craving',
        model: 'five_people_i_can_call',
        component: () => (
          <SmartTextarea
            useDictation
            label="Five people who I can call to help me get through a craving:"
            model="data.relapse_prevention_plan.five_people_i_can_call"
          />
        ),
      },
      {
        label: 'Five things I can do to get my mind off of using',
        model: 'five_things_i_can_do',
        component: () => (
          <SmartTextarea
            useDictation
            label="Five things I can do to get my mind off of using:"
            model="data.relapse_prevention_plan.five_things_i_can_do"
          />
        ),
      },
    ],
  },
  {
    title: 'Education & Employment',
    model: 'education_and_employment',
    fields: [
      {
        label: "What is the client's highest level of education completed?",
        model: 'education_level',
        component: () => (
          <>
            <Select
              allowEmpty
              label="What is the client's highest level of education completed?"
              model="data.education_and_employment.education_level"
            >
              <Option label="Elementary or High school, no diploma" value="elementary_or_high_school_no_diploma" />
              <Option label="Elementary or High school, GED" value="elementary_or_high_school_ged" />
              <Option label="High school diploma" value="high_school_diploma" />
              <Option label="College, no degree" value="college_no_degree" />
              <Option label="Associate's degree, vocational" value="associates_degree_vocational" />
              <Option label="Associate's degree, academic" value="associates_degree_academic" />
              <Option label="Bachelor's degree" value="bachelors_degree" />
              <Option label="Master's degree" value="masters_degree" />
              <Option label="Professional degree" value="professional_degree" />
              <Option label="Doctorate degree" value="doctorate_degree" />
              <Option label="Other" value="other" />
            </Select>

            <ContextShow when="data.education_and_employment.education_level" is="other">
              <Input label="Please add your highest level of education" model="data.education_and_employment.education_level_other" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Is the client currently employed?',
        model: 'is_employed',
        component: () => (
          <>
            <YesNoRadioGroup label="Is the client currently employed?" model="data.education_and_employment.is_employed" />

            <ContextShow when="data.education_and_employment.is_employed" is={true}>
              <Input label="What is the company's name?" model="data.education_and_employment.employment.company_name" />

              <FormCard title="Employer Contact Details">
                <ContactInputs model="data.education_and_employment.employment.employer_contact" relationship="employer" />
              </FormCard>

              <RadioGroup
                label="Is this permanent, temporary, or seasonal work?"
                model="data.education_and_employment.employment.work_type"
                layout="vertical-dense"
              >
                <Radio label="Permanent" value="permanent" />
                <Radio label="Temporary" value="temporary" />
                <Radio label="Seasonal" value="seasonal" />
              </RadioGroup>

              <Input
                label="How long has the client been employed there?"
                model="data.education_and_employment.employment.employment_length"
              />

              <Input
                label="How many hours does the client typically work per week?"
                model="data.education_and_employment.employment.weekly_work_hours"
                type="number"
                suffix="/ week"
                size={4}
              />

              <SmartTextarea
                useDictation
                label="What is the client's work schedule?"
                model="data.education_and_employment.employment.work_schedule"
              />

              <YesNoRadioGroup
                label="Does the client have an Employee Assistance Program (EAP)?"
                model="data.education_and_employment.employment.has_eap"
              />

              <ContextShow when="data.education_and_employment.employment.has_eap" is={true}>
                <FormCard title="EAP Contact">
                  <ContactInputs model="data.education_and_employment.employment.eap_contact" relationship="eap_contact" />
                </FormCard>
              </ContextShow>

              <YesNoRadioGroup
                label="Is the client a member of a union?"
                model="data.education_and_employment.employment.is_union_member"
              />

              <ContextShow when="data.education_and_employment.employment.is_union_member" is={true}>
                <Input label="Union Name" model="data.education_and_employment.employment.union_name" />
                <FormCard title="Union Representative">
                  <ContactInputs
                    model="data.education_and_employment.employment.union_representative"
                    relationship="union_representative"
                  />
                </FormCard>
              </ContextShow>

              <YesNoRadioGroup
                label="Does the client's employer know that they are in treatment?"
                model="data.education_and_employment.employment.is_employer_aware_of_treatment"
              />
            </ContextShow>

            <ContextShow when="data.education_and_employment.is_employed" is={false}>
              <YesNoRadioGroup
                label="Is the client currently seeking employment?"
                model="data.education_and_employment.employment.is_seeking_employment"
              />

              <YesNoRadioGroup
                label="Is the client willing to complete a minimum of two job applications a day?"
                model="data.education_and_employment.employment.is_willing_to_complete_two_job_applications_per_day"
              />

              <YesNoRadioGroupWithTextarea
                label="Does the client have any skills or industry experience that would assist them in finding a job?"
                model="data.education_and_employment.employment.has_industry_skills"
              />
            </ContextShow>

            <ContextShow when="data.education_and_employment.is_employed" is={true}>
              <AmountInput label="Current weekly income" model="data.education_and_employment.employment.weekly_income" suffix="/ week" />
            </ContextShow>

            <ContextShow when="data.education_and_employment.is_employed" is={false}>
              <AmountInput
                label="Anticipated weekly income"
                model="data.education_and_employment.employment.anticipated_weekly_income"
                suffix="/ week"
              />
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Courts & Criminal Justice',
    model: 'courts_criminal_justice',
    fields: [
      {
        label: 'Is client on Probation/Parole?',
        model: 'is_on_probation_parole',
        component: () => (
          <>
            <YesNoRadioGroup label="Is client on Probation/Parole?" model="data.courts_criminal_justice.is_on_probation_parole" />

            <ContextShow when="data.courts_criminal_justice.is_on_probation_parole" is={true}>
              <Input label="Jurisdiction DOC Number" model="data.courts_criminal_justice.jurisdiction_doc_number" />
              <Input label="Probation/Parole Officer Name" model="data.courts_criminal_justice.probation_parole_officer_name" />
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Client Review',
    model: 'client_review',
    fields: [
      {
        label: 'Client Confirmation',
        model: 'client_confirmation',
        builderComponent: () => (
          <FormSection maxWidth="100%">
            <RichTextEditor model="schema.client_review.data.client_confirmation_text" />
          </FormSection>
        ),
        component: ({ schema }) => {
          if (!schema?.client_review?.data?.client_confirmation_text) return null

          return <Markup value={schema?.client_review?.data?.client_confirmation_text} />
        },
      },
      {
        label: 'Client Signature',
        model: 'client_signature',
        component: ({ client }) => (
          <SignaturePad
            label="Client Signature"
            person={client}
            model="client_signature"
            signedAtModel="client_signed_at"
            allowPin={false}
          />
        ),
      },
    ],
  },
]
