import React from 'react'

import { useGet } from '../../hooks/useNewAPI'

import AgendaView from './AgendaView'
import CalendarEvent from '../Calendar/CalendarEvent'

import SidebarEventBuilderOverlay from './SidebarEventBuilderOverlay'
import SidebarEventOverlay from './SidebarEventOverlay'
import { useDataTable } from '../DataTable/useDataTable'

type CalendarDates = {
  currentDate: string
  endDate: string
  startDate: string
}

const CalendarView = () => {
  const [dates, setDates] = React.useState<CalendarDates | null>(null)

  const filters = {
    event_type: 'event',
    from: dates?.startDate,
    until: dates?.endDate,
  }

  const { data, isLoading }: any = useDataTable({
    name: ['sidebar', 'events'],
    endpoint: `/events/search`,
    params: filters,
    options: { enabled: !!dates },
  })

  const { data: templates }: any = useGet({
    name: 'event-templates',
    url: `/event_templates`,
    params: { event_type: 'event' },
  })

  return (
    <AgendaView
      title="Calendar"
      addLabel="Add Event"
      icon="calendar"
      onDatesChange={setDates}
      data={data}
      templates={templates}
      isLoading={isLoading}
      recordOverlay={SidebarEventOverlay}
      builderOverlay={SidebarEventBuilderOverlay}
      renderEvent={({ record, isActive, onClick }: any) => <CalendarEvent event={record} isActive={isActive} onClick={onClick} />}
      permission="events.create"
    />
  )
}

export default CalendarView
