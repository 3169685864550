import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { usDate } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { titleCase } from '../../utils/functions'

import ClaimStatus from './ClaimStatus'
import PayerNetworkStatus from '../../components/Statuses/PayerNetworkStatus'

import { DEFAULT_FILTERS } from '../Filters/constants'
import { AllClientsTable } from '../../components/Forms/Selectors/tables/AllClientsTable'

export const ClaimsDataTable = (props: any) => {
  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Claim',
        model: 'identifier',
        formatValue: ({ data, value }: any) => (
          <MainCell
            testKey="main_cell"
            id={data?.id}
            isActive={props.activeID === data.id}
            value={value}
            onClick={props.onClick ? () => props.onClick(data) : undefined}
          />
        ),
      },
      {
        title: 'Client',
        model: 'resident',
        type: 'profile',
      },
      {
        title: 'Primary Insurance',
        model: 'primary_insurance.insurance_name',
      },
      {
        title: 'Service From',
        model: 'from_date',
        type: 'date',
      },
      {
        title: 'Service To',
        model: 'to_date',
        type: 'date',
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <ClaimStatus status={value} />,
      },
      {
        title: 'Total Billed Amount',
        model: 'total_amount',
        width: 180,
        type: 'amount',
      },
      // {
      //   title: 'Total Remaining Balance',
      //   model: 'total_remaining_balance',
      //   width: 180,
      //   formatValue: () => 'TODO',
      // },
      {
        title: 'Claim Type',
        model: 'category',
        width: 130,
        type: 'title',
      },
      {
        title: 'Payer ID',
        width: 100,
        id: 'payer_id',
        model: 'insurance_local_payer.payer_id',
      },
      {
        title: 'Network Status',
        width: 140,
        id: 'network_status',
        model: 'insurance_local_payer.network_status',
        formatValue: ({ value }: any) => <PayerNetworkStatus status={value} />,
      },
      {
        title: 'Fee Schedule',
        id: 'fee_schedule',
        model: 'insurance_new_fee_schedule.name',
      },
      {
        title: 'Assignee',
        model: 'assignee',
        type: 'profile',
      },
      {
        title: 'Initial Bill Date',
        model: 'initial_submission_at',
        type: 'date_time',
      },
      {
        title: 'Last Bill Date',
        model: 'last_submission_at',
        type: 'date_time',
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [props.to, props.activeID, props.onClick, timezone],
  )

  return <DataTable asCard title="Claims" icon="accounting" columns={columns} filtersConfig={CLAIM_FILTERS_CONFIG} {...props} />
}

const CLAIM_FILTERS_CONFIG = {
  resident: {
    label: 'Client',
    type: 'multi_overlay',
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    icon: 'clients',
    table: <AllClientsTable defaultTab="current_clients" />,
  },
  primary_insurance: {
    label: 'Primary Insurance',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Draft', value: 'draft' },
      { label: 'Validated', value: 'validated' },
      { label: 'Validation Error', value: 'validation_error' },
      { label: 'Submitted', value: 'submitted' },
      { label: 'Submission Error', value: 'submission_error' },
      { label: 'Denied', value: 'denied' },
      { label: 'Balance Due Client', value: 'balance_due_client' },
      { label: 'Paid', value: 'paid' },
      { label: 'Written Off', value: 'written_off' },
    ],
  },
  payer_id: {
    label: 'Payer ID',
    type: 'string',
  },
  identifier: {
    label: 'Identifier',
    type: 'string',
  },
  network_status: {
    label: 'Network Status',
    type: 'string',
  },
  fee_schedule: {
    label: 'Fee Schedule',
    type: 'string',
  },
  category: {
    label: 'Category',
    type: 'string',
  },
  from_date: {
    type: 'date_time',
    label: 'Service From',
    glyph: 'date',
  },
  to_date: {
    type: 'date_time',
    label: 'Service To',
    glyph: 'date',
  },
  // total_remaining_balance: {
  //   label: 'Total Remaining Balance',
  //   type: 'number',
  // },
  control_number: {
    label: 'Control Number',
    type: 'string',
  },
  total_amount: {
    label: 'Total Billed Amount',
    type: 'number',
  },
  initial_submission_at: {
    type: 'date_time',
    label: 'Initial Submission At',
    glyph: 'date',
  },
  last_submission_at: {
    type: 'date_time',
    label: 'Last Submission At',
    glyph: 'date',
  },
  assignee: {
    type: 'multi_object',
    label: 'Assignee',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.position),
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
