import React from 'react'
import kebabCase from 'lodash/kebabCase'
import { NavLink, Route, useParams } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

import Button from '@behavehealth/components/Button'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { AuthorityFormAddDropdown } from '@behavehealth/constructs/Authority/AuthorityFormAddDropdown'
import { AuthorityFormStatus } from '@behavehealth/constructs/Authority/AuthorityFormStatus'
import { AuthorityFormSubmission } from '@behavehealth/constructs/Authority/AuthorityFormSubmission'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'

export const IncidentReporting = () => {
  const { url } = useRouteURL()

  return (
    <>
      <IncidentReportingIndex />

      <AnimatedRoutes>
        <Route path={`:id`} element={<AuthorityFormSubmission useV6Router back={url} permissionBase="authority_incident_reporting" />} />
        <Route
          path={`:formId/:id`}
          element={<AuthorityFormSubmission useV6Router back={url} permissionBase="authority_incident_reporting" />}
        />
      </AnimatedRoutes>
    </>
  )
}

const IncidentReportingIndex = () => {
  const { url } = useRouteURL()
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['organization', resource_id, 'authority_complaint_submissions'],
    endpoint: `/organizations/${resource_id}/authority_complaint_submissions`,
    updateDeleteEndpoint: '/authority_complaint_submissions',
    params: { category: 'issue' },
    enabled: !!resource_id,
    localStorageKey: 'authority_incident_reporting_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        id: 'name',
        model: 'name',
        title: 'Name',
        formatValue: ({ value, data }: any) => <MainCell as={NavLink} to={`${data.slug}/${data.id}`} value={value} />,
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <AuthorityFormStatus status={value} />,
      },
      {
        width: 200,
        model: 'assignee',
        title: 'Assignee',
        type: 'profile',
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'authority_incident_reporting.edit',
        disableSort: true,
      },
      {
        title: 'Date Submitted',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <Page
      title="Incident Reporting"
      icon="grievance_incident_forms"
      actions={
        <AuthorityFormAddDropdown
          category="issue"
          setLink={(form) => `${url}/${form.id}/new`}
          permission="authority_incident_reporting.create"
          featureFlagV2="authority_incident_reporting"
        />
      }
    >
      <Grid>
        <DataTable
          asCard
          title="Incident Reporting Forms"
          icon="grievance_incident_forms"
          columns={columns}
          {...tableProps}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'authority_incident_reporting.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}
