import React from 'react'
import size from 'lodash/size'

import { DataTable } from '../../../components/DataTable/DataTable'
import { MainCell } from '../../../components/DataTable/cells/MainCell'
import Flex from '../../../components/Flex'
import Status from '../../../components/Status'
import { niceAmount } from '../../../utils/functions'

export const ERAServiceLinesTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'ERA Service Line ID',
        model: 'identifier',
        disableSort: true,
        formatValue: ({ data, value }: any) => <MainCell testKey="main_cell" id={data?.id} value={value} />,
      },
      {
        title: 'From',
        model: 'from_date',
        disableSort: true,
        type: 'date_time',
        width: 180,
      },
      {
        title: 'Until',
        model: 'until_date',
        disableSort: true,
        type: 'date_time',
        width: 180,
      },
      {
        title: 'Procedure Code',
        model: 'proc_code',
        disableSort: true,
        width: 150,
      },
      {
        title: 'Modifier 1',
        model: 'mod1',
        disableSort: true,
        width: 150,
      },
      {
        title: 'Modifier 2',
        model: 'mod2',
        disableSort: true,
        width: 150,
      },
      {
        title: 'Modifier 3',
        model: 'mod3',
        disableSort: true,
        width: 150,
      },
      {
        title: 'Modifier 4',
        model: 'mod4',
        disableSort: true,
        width: 150,
      },
      {
        title: 'Units',
        model: 'units',
        disableSort: true,
        width: 150,
      },
      {
        title: 'Charge Amount',
        model: 'charge',
        disableSort: true,
        type: 'amount',
        width: 150,
      },
      {
        title: 'Allowed Amount',
        model: 'allowed',
        disableSort: true,
        type: 'amount',
        width: 150,
      },
      {
        title: 'Paid Amount',
        model: 'paid',
        disableSort: true,
        type: 'amount',
        width: 150,
      },
      {
        title: 'Adjustments',
        model: 'adjustments',
        disableSort: true,
        width: 300,
        formatValue: ({ value }: any) => {
          if (size(value) === 0) return null

          return (
            <Flex gap="0.25rem" className="w-full !flex-nowrap hover:!flex-wrap">
              {value.map((item: any, index: number) => (
                <Status
                  small
                  key={`${item.code}-${index}`}
                  color="blue"
                  label={`${niceAmount(item.amount)} (${item.code}, ${item.group})`}
                />
              ))}
            </Flex>
          )
        },
      },
      {
        title: 'Date Created',
        model: 'created_at',
        disableSort: true,
        type: 'date_time',
        width: 180,
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        disableSort: true,
        type: 'date_time',
        width: 180,
      },
    ],
    [],
  )

  return (
    <DataTable
      footerHidden
      title="ERA Service Lines"
      icon="accounting"
      columns={columns}
      meta={{ count: size(props.data) }}
      emptyStateMinHeight={150}
      {...props}
    />
  )
}
