import React from 'react'
import isUndefined from 'lodash/isUndefined'

import Flex from '../../Flex'

import { FilterCondition } from './FilterCondition'

export const BooleanFilter = (props: any) => {
  const { config, filter, onUpdate } = props

  const initialValue = isUndefined(filter?.value) ? true : filter.value

  const trueLabel = config?.trueLabel || 'Yes'
  const falseLabel = config?.falseLabel || 'No'

  const [value, setValue]: any = React.useState(initialValue)

  React.useEffect(() => {
    onUpdate?.({ value: value, condition: 'eq' })
  }, [value])

  return (
    <>
      <FilterCondition condition="is" />

      <Flex centerY gap="0.25rem">
        <label css={STYLES.label}>
          <input
            type="radio"
            css={STYLES.input}
            checked={value === true}
            onChange={() => {
              setValue(true)
            }}
          />
          {trueLabel}
        </label>

        <label css={STYLES.label}>
          <input
            type="radio"
            css={STYLES.input}
            checked={value === false}
            onChange={() => {
              setValue(false)
            }}
          />
          {falseLabel}
        </label>
      </Flex>
    </>
  )
}

const STYLES = {
  segment: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0 0.2rem',
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0 0.3rem',
    cursor: 'pointer',
    fontWeight: 500,

    input: {
      marginRight: '0.2rem',
      outline: 'none',
    },
  },

  input: {
    // display: 'none',
  },
}
