import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import FormSection from '../../../components/Forms/FormSection'
import Status from '../../../components/Status'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { ClientConnectionOverlay } from '../../ClientConnection/ClientConnectionOverlay'
import { DataTable } from '../../../components/DataTable/DataTable'
import { MainCell } from '../../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../../components/DataTable/useDataTable'

const AddButton = () => {
  const match = useRouteMatch()

  return (
    <SummonOverlay overlay={<ClientConnectionOverlay dataID="new" match={match} />}>
      <Button label="Add Connection" glyph="add" size={200} type="primary" display="inline-flex" permission="connections.create" />
    </SummonOverlay>
  )
}

const CareTeam = ({ setStatus }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = match.params

  const [openItem, setOpenItem] = React.useState(null)

  const tableProps = useDataTable({
    name: ['client', resource_id, 'connections'],
    endpoint: `/residents/${resource_id}/connections`,
  })

  const isEmpty = size(tableProps?.data) === 0

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'to.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            isActive={openItem?.id === data.id}
            id={data.id}
            value={value}
            avatar={data?.to?.avatar || ''}
            onClick={() => {
              setOpenItem(data)
            }}
          />
        ),
      },
      {
        title: 'Category',
        model: 'category',
        formatValue: ({ value }) => {
          if (value === 'care_team') return <Status small label="Care Team" color="orange" />
          if (value === 'organization') return <Status small label="Organization" color="purple" />
          return null
        },
      },
      {
        title: 'Relation Type',
        model: 'subcategory',
        type: 'title',
      },
      {
        title: 'Notes',
        model: 'notes',
      },
    ]
  }, [openItem?.id])

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <DataTable title="Connections" icon="connections" columns={columns} minHeight={200} {...tableProps} />

          <CardContent>
            <AddButton />
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        key={`summon-${openItem?.id}`}
        isOpen={!!openItem}
        onClose={() => setOpenItem(null)}
        overlay={<ClientConnectionOverlay dataID={openItem?.id} />}
      />
    </>
  )
}

export default CareTeam
