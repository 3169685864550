import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import OccupancyStatus from '../../components/Statuses/OccupancyStatus'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const BedAssignmentsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        id: 'reference',
        model: 'reference.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.reference?.name} avatar={data.reference?.avatar} to={to?.(data)} />
        ),
      },
      {
        width: 200,
        id: 'started_at',
        model: 'started_at',
        title: 'From',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 200,
        id: 'ended_at',
        model: 'ended_at',
        title: 'Until',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 220,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => (value ? <OccupancyStatus status={value} /> : '–'),
      },
      {
        width: 140,
        id: 'house',
        model: 'house.name',
        title: 'Location',
        type: 'profile',
      },
      {
        width: 140,
        id: 'floor',
        model: 'floor.name',
        title: 'Floor',
      },
      {
        width: 140,
        id: 'room',
        model: 'room.name',
        title: 'Room',
      },
      {
        width: 140,
        id: 'bed',
        model: 'bed.name',
        title: 'Bed',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Bed Assignments"
      icon="beds"
      columns={columns}
      filtersConfig={FILTERS.live_reports.bed_occupancies}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="bed_assignments"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=bed_assignments'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'bed_assignments' }} />
        </>
      }
      {...props}
    />
  )
}
