import pluralize from 'pluralize'
// import userflow from 'userflow.js'

import Sentry from '../vendors/sentry'
import LogRocket from '../vendors/logrocket'
import OpenReplay from '../vendors/openreplay'
import PostHog from '../vendors/posthog'
import AnnounceKit from '../vendors/announce_kit'
import { titleCase } from '../utils/functions'

const DO_NOT_TRACK = [
  'ben.weiss@behavehealth.com',
  'robert.eles@behavehealth.com',
  'andrei.ciursa@behavehealth.com',
  'george.chkhvirkia@behavehealth.com',
  'jennifer.spangler@behavehealth.com',
]

class ThirdParty {
  constructor() {
    this.config = {}
    this.isBehave = false
  }

  initialize = async (opts = {}) => {
    this.config = opts

    if (opts.openreplay?.id) OpenReplay.init(opts.openreplay.id, opts.openreplay.url)
    if (opts.posthog?.id) PostHog.init(opts.posthog.id, opts.posthog.url)
    if (opts.announcekit) AnnounceKit.init(opts.announcekit)
    if (opts.sentry) Sentry.init(opts.sentry, opts.version)
    if (opts.helpscout && window.Beacon) window.Beacon('init', opts.helpscout)
  }

  identify = ({ user, tenant }) => {
    if (DO_NOT_TRACK.includes(user.email)) {
      this.isBehave = true
    }

    // OPENREPLAY
    if (this.config.openreplay) {
      OpenReplay.client()?.start({
        userID: user.email,
        metadata: {
          externalId: user.external_id,
          name: user.name,
          email: user.email,
          phone_no: user.phone_no,
          tenant: tenant?.subdomain,
          company: tenant?.name,
        },
      })
    }

    // POSTHOG
    if (this.config.posthog) {
      PostHog.client()?.identify(user.email, {
        externalId: user.external_id,
        name: user.name,
        email: user.email,
        phone_no: user.phone_no,
        tenant: tenant?.subdomain,
        company: tenant?.name,
      })

      PostHog.client()?.group('tenant', tenant?.subdomain, {
        name: tenant?.name,
      })
    }

    // USERFLOW
    // if (this.config.userflow) {
    //   userflow.identify('behave-health', {
    //     name: user.name,
    //     email: user.email,
    //     position: user.position,
    //   })
    // }

    // SENTRY
    if (this.config.sentry) {
      Sentry.identify({
        id: user.external_id,
        email: user.email,
        phone_no: user.phone_no,
        tenant: tenant.subdomain,
      })
    }

    // HELPSCOUT
    if (this.config.helpscout) {     
      window.Beacon('identify', {
        ...(user.chat_hash && { signature: user.chat_hash }),
        name: user.name,
        email: user.email,
        Phone: user.phone_no,
        company: tenant.name,
        tenant: tenant.subdomain,
        'Plan Status': titleCase(tenant.plan_status),
        'User Link': `https://app.behavehealth.com/${pluralize(user.type)}/${user.id}/general-info`,
      })
    }
  }

  getConfig = () => this.config
}

export default new ThirdParty()
