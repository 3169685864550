import React from 'react'

import { getClientLink, daysBetween, countWord, titleCase } from '../../utils/functions'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import { LOC } from '../../utils/constants'

import { FILTERS } from '../Filters/config'

const RELAPSE_STATUSES = {
  relapsed: 'Relapsed',
  not_relapsed: 'Did not Relapse',
  unknown: 'Unknown',
}

const DISCHARGE_TYPES = {
  successfully_completed: "Successful Program Completion",
  successful_discharge: "Successful Discharge",
  unsuccessful_discharge_with_option_to_return: "Unsuccessful Discharge With Option to Return",
  unsuccessful_discharge_without_option_to_return: "Unsuccessful Discharge Without Option to Return",
  therapeutic_discharge: "Therapeutic Discharge",
  administrative_discharge: "Administrative Discharge",
  received_alternative_services: "Received Alternative Services",
  non_compliance_with_program: "Non-compliance With Program",
  needs_cannot_be_met_by_program: "Needs Cannot Be Met by Program",
  criminal_activity_destruction_of_property: "Criminal Activity/Destruction of Property",
  disagreement_with_program_rules: "Disagreement With Program Rules",
  whereabouts_unknown: "Whereabouts Unknown",
  ama: "AMA",
  mia: "MIA",
  death: "Death",
  other: "Other"
}

export const DischargesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value || titleCase(data.subcategory)} to={to?.(data)} />,
      },
      {
        title: 'Client',
        id: 'client',
        model: 'client',
        type: 'profile',
        config: {
          setLink: ({ rowData }: any) => getClientLink(rowData.client),
        },
      },
      {
        width: 220,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        width: 220,
        id: 'relapse_status',
        model: 'relapse_status',
        title: 'Relapse Status',
        formatValue: ({ value }: any) => (value ? RELAPSE_STATUSES[value] : '-'),
      },
      {
        width: 220,
        id: 'discharge_type',
        model: 'discharge_type',
        title: 'Discharge Type',
        formatValue: ({ value }: any) => (value ? DISCHARGE_TYPES[value] : '-'),
      },
      {
        width: 220,
        id: 'level_of_care',
        model: 'level_of_care',
        title: 'Level Of Care',
        formatValue: ({ value }: any) => (value ? LOC[value] : '-'),
      },
      {
        width: 220,
        id: 'placement_reason',
        model: 'placement_reason',
        title: 'Placement Reason',
      },
      {
        width: 220,
        id: 'discharge_reason',
        model: 'discharge_reason',
        title: 'Discharge Reason',
      },
      {
        width: 220,
        id: 'discharge_summary',
        model: 'discharge_summary',
        title: 'Discharge Summary',
      },
      {
        width: 220,
        id: 'is_employed',
        model: 'is_employed',
        title: 'Currently Employed',
        type: 'boolean',
      },
      {
        width: 150,
        id: 'admitted_at',
        model: 'admitted_at',
        title: 'Admission Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 150,
        id: 'discharged_at',
        model: 'discharged_at',
        title: 'Discharge Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 150,
        id: 'length_of_stay',
        title: 'Length of Stay',
        model: 'length_of_stay',
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Edited',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 180,
        id: 'author',
        model: 'author.name',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Discharges"
      icon="discharge_forms"
      columns={columns}
      filtersConfig={FILTERS.live_reports.discharges}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="discharges"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=discharges'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'discharges' }} />
        </>
      }
    />
  )
}
