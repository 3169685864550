import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import {
  OUTCOME_MEASURE_EMPLOYMENT_STATUS_TITLES,
  OUTCOME_MEASURE_MONTHLY_SERVICE_TITLES,
  OUTCOME_MEASURE_CURRENT_MEDICATIONS_TITLES,
  OUTCOME_MEASURE_LIFE_QUALITY_TITLES,
  OUTCOME_MEASURE_NICOTINE_USE_FREQUENCY_TITLES,
} from './constants'

import { useSettings } from '../../hooks/useSettings'
import { countWord } from '@behavehealth/utils/functions'

import DataFormStatus from '@behavehealth/components/Statuses/DataFormStatus'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

export const OutcomesSurveyReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'client',
        model: 'client.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 180,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }) => {
          if (!value) return null

          return <DataFormStatus status={value} />
        },
      },
      {
        width: 180,
        id: 'employment_status',
        model: 'employment_status',
        title: 'Employment Status',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_EMPLOYMENT_STATUS_TITLES[value]
        },
      },
      {
        width: 200,
        id: 'dated_at',
        model: 'dated_at',
        title: 'Date and Time',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'ended_at',
        model: 'ended_at',
        title: 'Session End Time',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'prior_month_services',
        model: 'prior_month_services',
        title: 'Prior Month Services',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_MONTHLY_SERVICE_TITLES[value]
        },
      },
      {
        width: 300,
        id: 'prior_month_recovery_support_meetings',
        model: 'prior_month_recovery_support_meetings',
        title: 'Prior Month Recovery Support Meetings',
      },
      {
        width: 220,
        id: 'prior_month_substance_use_days',
        model: 'prior_month_substance_use_days',
        title: 'Prior Month Substance Use',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 200,
        id: 'current_medications',
        model: 'current_medications',
        title: 'Current Medications',
        formatValue: ({ value }) => {
          if (!value) return null

          return value
            .split(',')
            .map((o) => OUTCOME_MEASURE_CURRENT_MEDICATIONS_TITLES[o])
            .join(', ')
        },
      },
      {
        width: 220,
        id: 'prior_month_hospital_visits',
        model: 'prior_month_hospital_visits',
        title: 'Prior Month Hospital Visits',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 300,
        id: 'prior_month_law_enforcement_incidents',
        model: 'prior_month_law_enforcement_incidents',
        title: 'Prior Month Law Enforcement Incidents',
      },
      {
        width: 200,
        id: 'did_relapse_since_discharge',
        model: 'did_relapse_since_discharge',
        title: 'Relapse Since Discharge',
        type: 'boolean',
      },
      {
        width: 200,
        id: 'quality_of_life_rating',
        model: 'quality_of_life_rating',
        title: 'Quality Of Life Rating',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_LIFE_QUALITY_TITLES[value]
        },
      },
      {
        width: 200,
        id: 'nicotine_use_frequency',
        model: 'nicotine_use_frequency',
        title: 'Nicotine use Frequency',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_NICOTINE_USE_FREQUENCY_TITLES[value]
        },
      },
      {
        width: 200,
        id: 'created_at',
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Outcomes Survey"
      icon="outcome_measures"
      columns={columns}
      filtersConfig={FILTERS.live_reports.outcomes_survey}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="outcomes_survey"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=outcomes_survey'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'outcomes_survey' }} />
        </>
      }
      {...props}
    />
  )
}
