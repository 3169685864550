import Tracker from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist'

class OpenReplay {
  constructor() {
    this.openreplay = null
  }

  init = (id, url) => {
    this.openreplay = new Tracker({
      projectKey: id,
      ingestPoint: url,
      defaultInputMode: 0,
      obscureTextNumbers: false,
      obscureTextEmails: false,
      crossdomain: {
        enabled: true,
      },
      network: {
        capturePayload: true, // Capture HTTP payload
        sanitizer: (data: any) => {
          if (data.url.includes('logrocket.behavehealth.com')) return null
          if (data.url.includes('errors.behavehealth.com')) return null
          if (data.url.includes('us.i.posthog.com')) return null

          return data
        },
      },
    })

    this.openreplay.use(trackerAssist())
  }

  client = () => this.openreplay

  abortOpenReplay = () => {
    this.openreplay = null
  }
}

export default new OpenReplay()
