import React from 'react'
import size from 'lodash/size'
import { useRouteMatch } from 'react-router-dom'

import { mapToArray } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Divider, Dropdown, DropdownItem, Page, HelpTagIframe } from '@behavehealth/components'
import { CustomNotesDataTable } from '@behavehealth/constructs/CustomNotes/CustomNotesDataTable'

const AddDropdown = ({ templates }: any) => {
  const match = useRouteMatch()

  return (
    <Dropdown label="Add Custom Note…" glyph="add" buttonType="primary" buttonSize={300} permission="staff_custom_notes.create">
      {mapToArray(templates).map((template) => (
        <DropdownItem
          key={template.id}
          label={template.name}
          glyph="documents"
          color="paleBlue"
          link={{
            pathname: `${match.url}/new`,
            parent: match,
            data: template,
          }}
        />
      ))}

      <Divider />

      <DropdownItem
        label="Empty Custom Note"
        glyph="add"
        color="paleBlue"
        link={{
          pathname: `${match.url}/new`,
          parent: match,
          data: {
            name: 'New Custom Note',
          },
        }}
      />
    </Dropdown>
  )
}

const pageConfig = {
  title: 'Staff Custom Notes',
  feature: 'custom_notes',
  marketingID: 'client_financials',
  help: <HelpTagIframe id="custom_notes" />,
}

type Props = {
  canCreate: boolean
}

const CustomNotes: React.FC<Props> = () => {
  const match = useRouteMatch()
  const id = match.params?.resource_id

  const tableProps = useDataTable({
    name: ['employee', id, 'custom_notes'],
    endpoint: `/employees/${id}/custom_notes`,
    params: { variant: 'staff' },
    updateDeleteEndpoint: '/custom_notes',
    localStorageKey: 'staff_custom_notes',
  })

  const { data: templates, isLoading: isLoadingTemplates } = useGet({
    name: ['custom_note_templates', { variant: 'staff' }],
    url: `/custom_note_templates`,
    params: { variant: 'staff' },
  })

  const isTemplatesEmpty = size(templates) === 0

  if (!isLoadingTemplates && isTemplatesEmpty) {
    return (
      <>
        <Page
          isEmpty={true}
          emptyDescription="No custom note templates have been added yet. Go to Settings to create the first template."
          actions={
            <Button
              size={300}
              label="Manage Custom Note Templates"
              glyph="settings"
              type="primary"
              link="/settings/custom-note-templates/staff"
              permission="settings.staff_custom_note_templates.view"
            />
          }
          {...pageConfig}
        />
      </>
    )
  }

  return (
    <Page
      plan="basic"
      actions={
        <>
          <Button
            size={300}
            glyph="settings"
            type="default"
            label="Manage Templates"
            link="/settings/custom-note-templates/staff"
            permission="settings.staff_custom_note_templates.view"
          />

          <AddDropdown templates={templates} />
        </>
      }
      {...pageConfig}
    >
      <CustomNotesDataTable
        {...tableProps}
        to={(data: any) => ({
          pathname: `${match.url}/${data.id}`,
          parent: match,
        })}
        duplicatePermission="staff_custom_notes.create"
        duplicateLink={(record: any) => {
          const {
            client,
            employees,
            ended_at,
            name,
            notes,
            office,
            organization,
            portal_settings,
            house,
            reference,
            sections,
            settings,
            started_at,
            supervisor,
            tags,
            variant,
          } = record

          return {
            pathname: `${match.url}/new`,
            parent: match,
            data: {
              status: 'draft',
              client,
              employees,
              ended_at,
              name,
              notes,
              office,
              organization,
              organization_id: organization?.id,
              portal_settings,
              house,
              house_id: house?.id,
              reference,
              sections,
              settings,
              started_at,
              supervisor,
              tags,
              variant,
            },
          }
        }}
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'staff_custom_notes.delete',
            action: async ({ ids }: any) => {
              await tableProps.deleteRecords(ids.join(','))
            },
          },
        ]}
      />
    </Page>
  )
}

export default withPageError(withMarketing(CustomNotes, pageConfig))
