import React from 'react'
import { BaseEditorComponent } from '@handsontable/react'
import { createRef, MouseEvent, RefObject } from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import Handsontable from 'handsontable'
import size from 'lodash/size'

import { COLORS } from '../../../theme'

import Badge from '../../Badge'
import Flex from '../../Flex'
import Button from '../../Button'
import Glyph from '../../Glyph'
import Loader from '../../Loader'
import State from '../../State'
import Status from '../../Status'
import Tooltip from '../../Tooltip'

import { useGet } from '../../../hooks/useNewAPI'
import { ValuesTooltip } from '../common/ValuesTooltip'
import { SpreadsheetDropdown } from '../common/SpreadsheetDropdown'

type MultiSearchSelectorRendererProps = {
  TD?: HTMLTableCellElement
  value?: any
  row?: number
  col?: number
  cellProperties?: Handsontable.CellProperties
  config?: any
  isEditable?: boolean
  readOnly?: boolean
  width?: number
}

export const MultiSearchSelectorRenderer = (props: MultiSearchSelectorRendererProps) => {
  const { value, readOnly, isEditable, TD, config = {}, spreadsheet, width } = props
  const { selectTitle, selectDescription } = config

  if (TD) {
    readOnly ? TD.classList.add('htDimmed') : TD.classList.remove('htDimmed')
  }

  const parsed = React.useMemo(() => {
    if (!value) return null

    try {
      return JSON.parse(value)
    } catch (error) {
      return null
    }
  }, [value])

  const hasValue = size(parsed) > 0

  return (
    <div className="flex items-center flex-nowrap h-[28px] relative">
      {hasValue && (
        <>
          {parsed.map((item: any) => (
            <div className="flex flex-nowrap items-center whitespace-nowrap m-0.5" key={item.id}>
              <Status small label={selectTitle ? selectTitle(item) : item?.name} color="blue" />
            </div>
          ))}
        </>
      )}

      <div
        className={clsx(
          'flex items-center justify-center absolute top-0 right-[-4px] w-8 bottom-0 z-[1]',
          isEditable && !readOnly && 'bg-gradient-to-l from-[white] to-[transparent]',
        )}
      >
        {hasValue ? (
          <ValuesTooltip
            width={width}
            trigger={
              <div>
                <Badge className="shadow-soft-5 shadow-vivid-blue-100">{size(parsed)}</Badge>
              </div>
            }
          >
            <div className="grid gap-0.5 text-[0.9rem] font-[600]">
              {parsed.map((item: any) => (
                <div
                  className="flex flex-nowrap items-center py-1 border-b border-0 border-solid border-divider last:border-b-0 first:pt-0 last:pb-0 truncate"
                  key={item.id}
                >
                  <div className="flex-[1_1_auto] truncate">
                    <div className="truncate">{selectTitle ? selectTitle(item) : item?.name}</div>
                    {selectDescription && (
                      <div className="text-[0.85rem] text-text-muted font-[400] truncate">{selectDescription(item)}</div>
                    )}
                  </div>

                  {spreadsheet?.hotInstance && isEditable && !readOnly && (
                    <Button
                      hideLabel
                      glyph="cross"
                      size={100}
                      type="minimal"
                      color="red"
                      onClick={() => {
                        const newValue = parsed?.filter?.((o: any) => o.id !== item.id) || []

                        spreadsheet?.hotInstance?.setDataAtRowProp(props.row, props.model, JSON.stringify(newValue))
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </ValuesTooltip>
        ) : (
          isEditable && !readOnly && <Glyph glyph="triangle_down" size={10} color={COLORS.blue} />
        )}
      </div>

      {config?.tooltip && <Tooltip content={config.tooltip} className="ml-auto relative z-[3]" />}
    </div>
  )
}

export class MultiSearchSelectorEditor extends BaseEditorComponent {
  mainElementRef: RefObject<HTMLDivElement>

  constructor(props: BaseEditorComponent['props']) {
    super(props)

    this.mainElementRef = createRef()
    this.state = {
      isOpen: false,
      value: null,
      record: null,
    }
  }

  setValue(value: any, callback: (() => void) | undefined) {
    const _value = value === '""' ? '[]' : value

    this.setState((_state, _props) => {
      return { value: _value }
    }, callback)
  }

  getValue() {
    return this.state.value
  }

  open() {
    this.setState({ isOpen: true })

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'block'
  }

  close() {
    this.setState({ isOpen: false })

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'none'

    this.finishEditing()
  }

  prepare(
    row: number,
    col: number,
    prop: string,
    td: HTMLTableColElement,
    originalValue: string,
    cellProperties: Handsontable.CellProperties,
  ) {
    super.prepare(row, col, prop, td, originalValue, cellProperties)

    const tdPosition = td.getBoundingClientRect()

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.left = `${tdPosition.left + window.pageXOffset}px`
    this.mainElementRef.current.style.top = `${tdPosition.top + window.pageYOffset}px`
  }

  stopMousedownPropagation(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
  }

  render() {
    return (
      <SpreadsheetDropdown ref={this.mainElementRef} onMouseDown={this.stopMousedownPropagation}>
        {this.state.isOpen && (
          <SelectorMenu
            {...this.props}
            value={this.state.value}
            onSelect={(selected) => {
              this.setState((state, props) => {
                const newValue = JSON.parse(state.value || '[]')

                if (size(selected) === 0) return { value: null }

                const index = newValue.findIndex((o: any) => o.id === selected.id)

                if (index === -1) {
                  newValue.push(selected)
                } else {
                  newValue.splice(index, 1)
                }

                return { value: JSON.stringify(newValue) }
              })
            }}
          />
        )}
      </SpreadsheetDropdown>
    )
  }
}

const SelectorMenu = (props: any) => {
  const { onSelect, config = {}, value } = props
  const { selectTitle, selectDescription, selectMenuTitle, selectMenuDescription, params } = config

  const [query, setQuery] = React.useState('')
  const [q, setQ] = React.useState('')
  const debouncedSetQ = React.useCallback(debounce(setQ, 300), [])

  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null)

  const parsedValue = React.useMemo(() => {
    if (!value) return null

    try {
      return JSON.parse(value)
    } catch (error) {
      console.error(error)
      return null
    }
  }, [value])

  const { data, isLoading, isRefetching } = useGet({
    name: ['multi-search-pill-selector-menu', props.model, { ...params, q }],
    url: '/search_by',
    params: { ...params, q },
    options: {
      keepPreviousData: true,
    },
  })

  React.useEffect(() => {
    debouncedSetQ(query)
  }, [query, debouncedSetQ])

  return (
    <div className="relative">
      {isLoading ? (
        <div className="py-12 px-4 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="sticky shadow-soft-2 top-[-3px] bg-white z-[1] border-b border-0 border-solid border-divider -m-[3px] !mb-0">
            <div className="!mb-0 bg-white z-[1] relative">
              <Glyph glyph="search" className="absolute top-[50%] translate-y-[-50%] left-2" size={16} />

              <input
                autoFocus
                ref={setInputRef}
                className=" w-full px-8 bg-white border-solid border-transparent h-10 !cursor-text outline-none rounded-[5px]"
                type="text"
                value={query}
                onChange={(e) => {
                  e.stopPropagation()
                  setQuery(e.target.value)
                }}
                onKeyDown={(e) => {
                  e.stopPropagation()
                }}
                placeholder="Search…"
                onClick={(e) => {
                  e.stopPropagation()
                  inputRef?.focus()
                }}
              />
            </div>

            {size(parsedValue) > 0 && (
              <div className="border-t border-0 border-solid border-divider px-2 py-1.5">
                <Flex gap="0.5rem">
                  {parsedValue.map((item: any) => (
                    <div
                      key={item.id}
                      className="inline-flex items-center border-solid border-divider rounded-[5px] text-[0.85rem] font-[600]"
                    >
                      <div className="px-1.5 py-0.25">{selectTitle ? selectTitle(item) : item?.name}</div>

                      <button
                        type="button"
                        onClick={() => onSelect(item)}
                        className="bg-transparent hover:bg-hover border-l border-0 border-solid border-divider inline-flex self-stretch items-center justify-center cursor-pointer"
                      >
                        <Glyph glyph="close" color="red" size={10} />
                      </button>
                    </div>
                  ))}
                </Flex>
              </div>
            )}
          </div>

          {!isRefetching && !isLoading && size(data) === 0 && (
            <State isEmpty glyph="search" emptyDescription="There are no records to display" minHeight={100} />
          )}

          <div className="grid gap-1">
            {data?.map?.((item) => {
              const isActive = !!parsedValue?.find?.((o: any) => o.id === item?.document?.id)

              return (
                <button
                  key={item.id}
                  css={STYLES.menuItem}
                  onClick={() => {
                    if (!item?.document) return

                    onSelect?.(item.document)
                  }}
                  className={clsx('text-left', isRefetching && 'opacity-0 pointer-events-none', isActive && 'is-active')}
                >
                  <div>
                    <div>
                      {selectMenuTitle
                        ? selectMenuTitle(item.document, item.highlights)
                        : selectTitle
                        ? selectTitle(item?.document)
                        : item?.document?.name}
                    </div>

                    {(selectMenuDescription || selectDescription) && (
                      <div className="text-[0.85rem] text-text-muted font-[400]">
                        {selectMenuDescription
                          ? selectMenuDescription(item?.document, item?.highlights)
                          : selectDescription(item?.document)}
                      </div>
                    )}
                  </div>
                  <div className="w-5 h-5 inline-flex ml-auto items-center justify-center">
                    {isActive && <Glyph glyph="tick_circle" size={16} color="green" />}
                  </div>
                </button>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

const STYLES = {
  menuItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '28px',
    padding: '0.1rem 0.6rem',
    fontSize: '0.9rem',
    fontWeight: 600,
    color: COLORS.text,
    border: 'none',
    background: 'transparent',
    borderRadius: 5,

    'svg, img': {
      marginRight: '0.4rem',
    },

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      background: tint(0.9, COLORS.green),
    },

    '& > *': {
      pointerEvents: 'none',
    },
  },

  dropdown: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& > svg': { marginLeft: '0.4rem' },
  },
}
