import React from 'react'
import { BaseEditorComponent } from '@handsontable/react'
import { createRef, MouseEvent, RefObject } from 'react'
import { tint } from 'polished'
import Handsontable from 'handsontable'

import { COLORS } from '../../../theme'
import Glyph from '../../Glyph'

import { SpreadsheetDropdown } from '../common/SpreadsheetDropdown'
import { Tiptap } from '../../Forms/RichTextEditor'
import { ValuesTooltip } from '../common/ValuesTooltip'

type RichTextRendererProps = {
  TD?: HTMLTableCellElement
  value?: string | number
  row?: number
  col?: number
  cellProperties?: Handsontable.CellProperties
  config?: any
  isEditable?: boolean
  readOnly?: boolean
  width?: number
}

export const RichTextRenderer = (props: RichTextRendererProps) => {
  const { value, readOnly, isEditable, TD, width } = props

  if (TD) {
    readOnly ? TD.classList.add('htDimmed') : TD.classList.remove('htDimmed')
  }

  return (
    <div className="flex items-center flex-nowrap h-[28px] justify-between">
      {value && (
        <div className="flex items-start h-full pt-3">
          <Tiptap key={`value-${value}`} isEditable={false} content={value} />
        </div>
      )}

      {value ? (
        <>
          <ValuesTooltip
            width={width}
            trigger={
              <div>
                <Glyph glyph="info" size={15} />
              </div>
            }
          >
            <div className="grid gap-0.5 text-[0.9rem] font-[500]">
              <Tiptap key={`value-${value}`} isEditable={false} content={value} />
            </div>
          </ValuesTooltip>
        </>
      ) : (
        isEditable && <Glyph glyph="highlight" size={10} color={COLORS.blue} className="ml-auto" />
      )}
    </div>
  )
}

export class RichTextEditor extends BaseEditorComponent {
  mainElementRef: RefObject<HTMLDivElement>

  constructor(props: BaseEditorComponent['props']) {
    super(props)

    this.mainElementRef = createRef()
    this.state = {
      value: '',
      instance: null,
    }
  }

  setValue(value: any, callback: (() => void) | undefined) {
    this.setState((_state, _props) => {
      return { value }
    }, callback)
  }

  getValue() {
    return this.state.value
  }

  open() {
    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'block'
    this.setState({ instance: Math.random() })
  }

  close() {
    this.finishEditing()
    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'none'
  }

  prepare(
    row: number,
    col: number,
    prop: string,
    td: HTMLTableColElement,
    originalValue: string,
    cellProperties: Handsontable.CellProperties,
  ) {
    super.prepare(row, col, prop, td, originalValue, cellProperties)

    const tdPosition = td.getBoundingClientRect()

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.left = `${tdPosition.left + window.pageXOffset}px`
    this.mainElementRef.current.style.top = `${tdPosition.top + window.pageYOffset}px`
  }

  stopMousedownPropagation(e: MouseEvent) {
    e.stopPropagation()
  }

  render() {
    return (
      <SpreadsheetDropdown ref={this.mainElementRef} onMouseDown={this.stopMousedownPropagation} style={{ width: 460, height: 'auto' }}>
        <div css={STYLES.editorWrapper}>
          <Tiptap
            isEditable
            useQuickText
            key={`instance-${this.state.instance}`}
            actions={['alignment', 'block', 'color', 'inline', 'list', 'undo']}
            content={this.state.value}
            onUpdate={({ editor }) => {
              const html = editor.getHTML()

              this.setState((state, props) => {
                return { value: html }
              })
            }}
          />
        </div>
      </SpreadsheetDropdown>
    )
  }
}

const STYLES = {
  editorWrapper: {
    '.ProseMirror': {
      padding: '1rem',
      cursor: 'text',
      minHeight: 200,
    },
  },

  menuItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '28px',
    padding: '0.1rem 0.6rem',
    fontSize: '0.9rem',
    fontWeight: 600,
    color: COLORS.text,
    border: 'none',
    background: 'transparent',
    borderRadius: 5,

    'svg, img': {
      marginRight: '0.4rem',
    },

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      background: tint(0.9, COLORS.paleBlue),
    },

    '& > *': {
      pointerEvents: 'none',
    },
  },
}
