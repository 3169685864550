import React from 'react'
import size from 'lodash/size'

import Flex from '../../../components/Flex'
import Status from '../../../components/Status'
import { niceAmount } from '../../../utils/functions'

import { Spreadsheet } from '../../../components/Spreadsheet/Spreadsheet'
import { useSpreadsheet } from '../../../components/Spreadsheet/useSpreadsheet'

export const ERAServiceLinesFullTable = (props: any) => {
  const { headerAfter, height = '100%' } = props

  const data = React.useMemo(() => {
    if (!props.data) return null

    const res: any = []

    for (const row of props.data) {
      res.push({
        ...row,
        insurance_era_claim: {
          ...row?.insurance_era_claim,
          client: row?.insurance_era_claim?.client || {},
          insurance_claim: row?.insurance_era_claim?.insurance_claim || {},
        },
      })
    }

    return res
  }, [props.data])

  const columns = React.useMemo(
    () => [
      {
        title: 'Client',
        model: 'insurance_era_claim.client.name',
        type: 'profile',
      },
      {
        title: 'Service Start Date',
        model: 'from_date',
        type: 'date_time',
      },
      {
        title: 'Service End Date',
        model: 'until_date',
        type: 'date_time',
      },
      {
        title: 'Procedure Code',
        model: 'proc_code',
      },
      {
        title: 'ERA Claim ID',
        model: 'insurance_era_claim.identifier',
      },
      {
        title: 'Claim ID',
        model: 'insurance_era_claim.insurance_claim.identifier',
      },
      {
        title: 'ERA Service Line ID',
        model: 'identifier',
      },
      {
        title: 'Charge Amount',
        model: 'charge',
        type: 'amount',
      },
      {
        title: 'Allowed Amount',
        model: 'allowed',
        type: 'amount',
      },
      {
        title: 'Modifier 1',
        model: 'mod1',
      },
      {
        title: 'Modifier 2',
        model: 'mod2',
      },
      {
        title: 'Modifier 3',
        model: 'mod3',
      },
      {
        title: 'Modifier 4',
        model: 'mod4',
      },
      {
        title: 'Units',
        model: 'units',
      },
      {
        title: 'Paid Amount',
        model: 'paid',
        type: 'amount',
      },
      {
        title: 'Adjustments',
        model: 'adjustments',
        config: {
          render: (value) => {
            if (size(value) === 0) return null

            return (
              <Flex gap="0.25rem" className="w-full !flex-nowrap hover:!flex-wrap">
                {value.map((item: any, index: number) => (
                  <Status
                    small
                    key={`${item.code}-${index}`}
                    color="blue"
                    label={`${niceAmount(item.amount)} (${item.code}, ${item.group})`}
                  />
                ))}
              </Flex>
            )
          },
        },
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  const spreadsheet = useSpreadsheet({ columns: columns })

  if (!data) return null

  return (
    <Spreadsheet
      title="ERA Service Lines"
      icon="accounting"
      columnSorting
      manualColumnMove
      canAdd={false}
      canDelete={false}
      isEditable={false}
      getSpreadsheet={spreadsheet.getSpreadsheet}
      columns={columns}
      getData={spreadsheet.getData}
      initialData={data}
      headerAfter={headerAfter}
      height={height}
    />
  )
}
