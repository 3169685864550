import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

export const ProgramListsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value} to={to?.(data)} />,
      },
      {
        title: 'Program',
        id: 'program',
        model: 'program.name',
        width: 260,
        type: 'profile',
      },
      {
        width: 140,
        id: 'residents_count',
        model: 'residents_count',
        title: '# Clients',
      },
      {
        width: 200,
        id: 'description',
        model: 'description',
        title: 'Description',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Program Lists"
      icon="checklist"
      columns={columns}
      filtersConfig={FILTERS.live_reports.program_lists}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="program_lists"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=program_lists'}
          />
          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'program_lists' }} />
        </>
      }
    />
  )
}
