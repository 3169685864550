import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid, TipAlert } from '@behavehealth/components'

import { TreatmentEpisodesReportDataTable } from '@behavehealth/constructs/LiveReports/TreatmentEpisodesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const TreatmentEpisodes = () => {
  const tableProps = useDataTable({
    name: ['reports', 'treatment-episodes'],
    endpoint: `/live_reports?category=treatment_episodes`,
    localStorageKey: 'report_treatment_episodes_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `/clients/${rowData.customer?.id}/treatment-episodes/${rowData?.id}`, [])

  return (
    <Page feature="treatment_episodes" title="Live Treatment Episodes Report">
      <Grid>
        <TreatmentEpisodesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(TreatmentEpisodes)
