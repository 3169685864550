import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { usDate, usTime } from '../../utils/functions'
import { useUpdate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks'

import EventStatus from '../../components/Statuses/EventStatus'
import DonutChart from '../../components/DonutChart'
import Flex from '../../components/Flex'
import Status from '../../components/Status'
import TaskStatus from '../../components/Statuses/TaskStatus'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { FILTERS } from '../Filters/config'

const STATUS_COLORS: any = {
  done: 'green',
  due_today: 'orange',
  to_do: 'blue',
  overdue: 'red',
}

const TASK_TYPES: any = {
  one_to_one: 'One-to-One',
  group_meeting: 'Group Task',
}

export const TodosReportDataTable = (props: any) => {
  const { to } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        id: 'title',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.title} to={to?.(data)} />,
      },
      {
        width: 140,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <EventStatus status={value} />,
      },
      {
        width: 170,
        id: 'subtasks_no',
        model: 'subtasks_no',
        title: 'Tasks',
        formatValue: ({ data, value }: any) => {
          if (value === 0) return '-'

          return (
            <Flex centerY nowrap>
              <DonutChart
                value={(data?.subtasks_completed_no / data?.subtasks_no) * 100}
                size={16}
                strokeWidth={3}
                labelSize="0px"
                css={styles.donut}
              />
              <div>
                {data?.subtasks_completed_no} / {data?.subtasks_no} Completed
              </div>
            </Flex>
          )
        },
        Filter: false,
      },
      {
        width: 90,
        id: 'color',
        model: 'color',
        title: 'Color',
        type: 'color',
      },
      {
        width: 160,
        id: 'started_at',
        model: 'started_at',
        title: 'Due Date',
        formatValue: ({ value }: any) => usDate(value, timezone),
      },
      {
        width: 120,
        id: 'is_all_day',
        model: 'is_all_day',
        title: 'All Day',
        type: 'boolean',
      },
      {
        width: 110,
        id: 'meeting_type',
        model: 'meeting_type',
        title: 'Type',
        formatValue: ({ value }: any) => TASK_TYPES[value],
      },      
      {
        width: 200,
        id: 'plain_public_description',
        model: 'plain_public_description',
        title: 'Public Description'
      },
    ]
  }, [to])

  const styles = {
    checkButton: {
      marginRight: 10,
    },
    donut: {
      marginRight: 8,
    },
  }

  return (
    <DataTable
      asCard
      title="To-Do's"
      icon="tasks"
      columns={columns}
      filtersConfig={FILTERS.todos}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="todos"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=todos'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'todos' }} />
        </>
      }
    />
  )
}
