import React from 'react'

import Card from '../../../components/Card'
import Overlay from '../../../components/Overlay'
import Flex from '../../../components/Flex'
import Loader from '../../../components/Loader'

import { useGet } from '../../../hooks/useNewAPI'
import { ERAClaims } from '../components/ERAClaims'

export const ClaimOverlayERAs = (props: any) => {
  const { id } = props

  const { data, isLoading }: any = useGet({
    name: ['insurance-claims', id, 'insurance-era-claims'],
    url: `/insurance_claims/${id}/insurance_era_claims`,
    options: { enabled: !!id },
  }) 

  return (
    <Overlay.Content css={{ zIndex: 0 }}>
      <div className="!p-4">
        <Card>
          {isLoading ? (
            <Flex centerX centerY className="min-h-[100px]">
              <Loader size={24} />
            </Flex>
          ) : <ERAClaims data={data} />}
        </Card>
      </div>
    </Overlay.Content>
  )
}
