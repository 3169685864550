import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { useSettings } from '../../../hooks/useSettings'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Checkbox from '../../../components/Forms/Checkbox'
import ContextShow from '../../../components/Forms/ContextShow'
import SmartRichTextEditor from '../../../components/Forms/SmartRichTextEditor'
import Status from '../../../components/Status'
import Switch from '../../../components/Forms/Switch'
import Tooltip from '../../../components/Tooltip'
import TreeItem from '../../../components/TreeItem'

import { AuthorityFormLevelStatus } from '../AuthorityFormLevelStatus'

export const AuthorityFormSection = (props: any) => {
  const {
    title,
    isRequired,
    isEditable,
    model,
    isOpen,
    sectionId,
    isPreviewVisible,
    authorityName,
    questions,
    isEvaluatorOnly,
    isInfoVisible,
    previewState,
  } = props

  const [open, setOpen] = React.useState(isOpen)

  const { isBehave } = useSettings()

  const scrollToPreviewSection = () => {
    const element = document?.getElementById(sectionId)

    if (!element) return

    element.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Card className="p-4 pt-3">
      <div className="flex items-center">
        <div className="flex items-center">
          <h3>{title}</h3>

          {isPreviewVisible && (
            <Button label="Preview" glyph="view" size={100} type="minimal" className="mx-1.5" onClick={scrollToPreviewSection} />
          )}
        </div>

        {isEditable && isRequired && <Status label="Required" color="red" css={{ marginLeft: 'auto' }} />}

        <div css={{ marginLeft: 'auto', display: isRequired || !isEditable ? 'none' : 'block' }}>
          <Switch
            withHover={false}
            size={100}
            label={false}
            value={open}
            model={`schema.${model}.show`}
            onUpdate={(state) => setOpen(state.value)}
          />
        </div>
      </div>

      <div style={{ overflow: open ? 'visible' : 'hidden', height: open ? 'auto' : 0, opacity: open ? 1 : 0 }}>
        <div className="grid gap-1.5 mt-3">
          <TreeItem title={<span className="text-[0.95rem] text-blue-500">Custom Text</span>} withHover={false} headerClassName="!p-0 mb-2">
            <SmartRichTextEditor model={`schema.${model}.custom_text_before`} withHover={false} />
          </TreeItem>

          {questions?.map?.((question: any) => {
            const classes = clsx(
              'grid gap-3',
              isBehave && isInfoVisible ? 'grid-cols-[1fr_120px_320px_20px] items-center' : 'grid-cols-[1fr_120px_20px]',
              !!previewState &&
                previewState !== 'all' &&
                size(question?.states) > 0 &&
                !question.states?.includes?.(previewState) &&
                'hidden',
            )

            return (
              <React.Fragment key={`${model}-${question.identifier}`}>
                <div className={classes}>
                  <div>
                    <Checkbox
                      defaultChecked
                      key={question.identifier}
                      label={
                        isEvaluatorOnly && question.evaluatorCriteria
                          ? question.evaluatorCriteria
                          : typeof question.label === 'function'
                          ? question.label({ authorityName })
                          : question.label || '[Question]'
                      }
                      model={`schema.${model}.fields.${question.identifier}.show`}
                      isDisabled={question.isDisabled}
                      trueIcon="check"
                      falseIcon="empty_checkbox"
                      falseStyle="faded"
                    />
                  </div>

                  <div>
                    {!question.hideRequired && (
                      <ContextShow when={`schema.${model}.fields.${question.identifier}.show`} is={true}>
                        <Checkbox
                          // defaultChecked
                          key={question.identifier}
                          label="Required"
                          model={`schema.${model}.fields.${question.identifier}.required`}
                          isDisabled={question.isDisabled || question.isRequiredDisabled}
                          trueIcon="check"
                          falseIcon="cross"
                          falseStyle="faded"
                        />
                      </ContextShow>
                    )}
                  </div>

                  {isBehave && isInfoVisible && (
                    <div className="flex justify-start items-start flex-wrap flex-[0_0_auto] -m-1 [&>*]:!m-1 pb-4">
                      <Status small label={question.identifier} className="!bg-text-muted !text-white min-w-[0]" />

                      <AuthorityFormLevelStatus small status={question.level} className="min-w-[0]" />

                      <div>
                        {size(question.states) > 0 && (
                          <div className="flex flex-wrap items-center -m-1 [&>*]:!m-1 min-w-[0]">
                            {question.states.map((state: any) => (
                              <Status key={state} small label={state} color="vividBlue" className="" />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div>{question.tooltip && <Tooltip content={question.tooltip} />}</div>
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </Card>
  )
}
