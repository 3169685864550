import React from 'react'
import { Route as RouteV6, Routes } from 'react-router-dom-v5-compat'
import { Route, Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { clearData, updateData } from '@behavehealth/actions/data'
import { fullname } from '@behavehealth/utils/functions'
import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { Status, PageGrid, NavGroup, NavItem, Nav, PageProfile } from '@behavehealth/components'
import { store } from '@behavehealth/setup/store'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Notifications from '@behavehealth/modules/notifications'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import withBackListener from '@behavehealth/hocs/withBackListener'

import ActivityTimeline from '../employees/pages/activity_timeline'
import Calendar from '../employees/pages/calendar'
import ClinicalAssessments from '../employees/pages/clinical_assessments'
import ClinicalMeasurements from '../employees/pages/clinical_measurements'
import ClinicalNotes from '../employees/pages/clinical_notes'
import ClientCustomNotes from '../employees/pages/client_custom_notes'
import CustomNotes from '../employees/pages/custom_notes'
import Files from '../employees/pages/files'
import GeneralInfo from '../employees/pages/general_info'
import MedicalAssessments from '../employees/pages/medical_assessments'
import Nursing from '../employees/pages/nursing'
import OutcomeMeasures from '../employees/pages/outcome_measures'
import PeerNotes from '../employees/pages/peer_notes'
import PhysicianOrders from '../employees/pages/physician_orders'
import ProgressNotes from '../employees/pages/progress_notes'
import ProgressReviews from '../employees/pages/progress_reviews'
import RecoveryCoaching from '../employees/pages/recovery_coaching'
import ShiftNotes from '../employees/pages/shift_notes'
import TestResults from '../employees/pages/test_results'
import Todos from '../employees/pages/todos/todos'
import TreatmentPlans from '../employees/pages/treatment_plans'

const User = () => {
  const history = useHistory()
  const match = useRouteMatch()

  const { user } = useSettings()

  const { data: employee, isLoading, isError, error }: any = useGet({
    name: ['employee', user?.id],
    url: `/employees/${user?.id}`,
    options: { enabled: !!user?.id },
  })

  const { isBehave, isManagement } = useSettings()
  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  React.useEffect(() => {
    if (!isError) return

    console.error(error)
    Notifications.send(error?.error?.data?.errors?.[0], 'negative')

    // go back to the Employees list if unauthorized
    if (error?.error?.status === 401) history.push('/employees')
  }, [isError])

  React.useEffect(() => {
    store.dispatch(updateData(`employees.data.${employee?.id}`, employee))

    return () => {
      store.dispatch(clearData('employees'))
    }
  }, [employee])

  if (!employee) return <PageLoader />

  return (
    <PageGrid pinMobileHeader breakpoint={3} columns="280px 1fr">
      <Nav
        breakpoint={3}
        headingSize={400}
        record={employee}
        title={fullname(employee)}
        avatar={employee.avatar}
        description={<Status label={employee && employee.position && employee.position.replace('_', ' ')} />}
        desktopProps={{
          icon: 'employees',
          title: 'My Profile',
          headingSize: 300,
          avatar: undefined,
          description: undefined,
        }}
        profile={
          <PageProfile
            record={employee}
            status={employee.is_blocked && <Status label="Blocked" color="red" />}
            title={fullname(employee)}
            description={<Status label={employee && employee.position && employee.position.replace('_', ' ')} />}
          />
        }
      >
        <NavGroup>
          <NavGroup label="General">
            <NavItem feature="general_info" label="General Info" to={`${match.url}/general-info`} plan="lite" />
            <NavItem feature="tasks" label="To-Do" to={`${match.url}/todo`} permission="todos.view" plan="basic" featureFlagV2="todos" />
            <NavItem
              label="Calendar"
              to={`${match.url}/calendar`}
              feature="calendar"
              featureFlag="events"
              permission="events.view"
              plan="pro"
              featureFlagV2="calendar"
            />
            <NavItem
              feature="files"
              label="Files"
              to={`${match.url}/files`}
              featureFlag="files"
              permission="employees.files.view"
              plan="plus"
              featureFlagV2="files"
            />
          </NavGroup>

          <NavGroup label="Notes">
            <NavItem
              feature="housing_notes"
              label="Shift Notes"
              to={`${match.url}/shift-notes`}
              featureFlag="shift_notes"
              permission="shift_notes.view"
              plan="plus"
              featureFlagV2="shift_notes"
            />
            <NavItem
              showMarketing
              feature="recovery_coaching"
              label="Recovery Notes"
              to={`${match.url}/recovery-coaching`}
              permission="recovery_coaching.view"
              featureFlagV2="recovery_coaching"
            />
            <NavItem
              feature="custom_notes"
              label="Client Custom Notes"
              to={`${match.url}/client-custom-notes`}
              permission="client_custom_notes.view"
              featureFlagV2="client_custom_notes"
            />
            <NavItem
              feature="custom_notes"
              label="Staff Custom Notes"
              to={`${match.url}/custom-notes`}
              permission="staff_custom_notes.view"
              featureFlagV2="staff_custom_notes"
            />
            <NavItem
              feature="peer_notes"
              label="Peer Notes"
              to={`${match.url}/peer-notes`}
              featureFlag="peer_notes"
              permission="peer_notes.view"
              featureFlagV2="peer_notes"
            />
          </NavGroup>

          <NavGroup label="Clinical">
            <NavItem
              showMarketing
              feature="clinical_assessments"
              label="Clinical Assessments"
              to={`${match.url}/clinical-assessments`}
              permission="clinical_assessments.view"
              featureFlagV2="clinical_assessments"
            />
            <NavItem
              isBeta
              showMarketing
              feature="treatment_plans"
              label="Treatment Plans"
              to={`${match.url}/treatment-plans`}
              featureFlagV2="treatment_plans"
              permission={viewSomeTreatmentPlans}
            />
            <NavItem
              showMarketing
              feature="progress_reviews"
              label="Progress Reviews"
              to={`${match.url}/progress-reviews`}
              permission="progress_reviews.view"
              featureFlagV2="progress_reviews"
            />
            <NavItem
              feature="clinical_notes"
              label="Clinical Notes"
              to={`${match.url}/clinical-notes`}
              featureFlag="clinical_notes"
              permission="clinical_notes.view"
              addon="ehr"
              featureFlagV2="clinical_notes"
            />
            <NavItem
              showMarketing
              feature="clinical_measurements"
              label="Clinical Measures"
              to={`${match.url}/clinical-measurements`}
              permission="clinical_measurements.view"
              featureFlagV2="clinical_measures"
            />
            <NavItem
              showMarketing
              feature="outcome_measures"
              label="Outcome Measures"
              to={`${match.url}/outcome-measures`}
              permission="outcome_measures.view"
              featureFlagV2="outcome_measures"
            />
          </NavGroup>

          <NavGroup label="Medical">
            <NavItem
              feature="test_results"
              label="Test Results"
              to={`${match.url}/test-results`}
              featureFlag="test_results"
              permission="test_results"
              plan="plus"
              featureFlagV2="test_results"
            />
            <NavItem
              showMarketing
              feature="medical_assessments"
              label="Medical Assessments"
              to={`${match.url}/medical-assessments`}
              featureFlagV2="medical_assessments"
              permission="medical_assessments.view"
            />
            <NavItem
              showMarketing
              feature="physician_orders"
              label="Physician Orders"
              to={`${match.url}/physician-orders`}
              permission="physician_orders.view"
              featureFlagV2="physician_orders"
            />
            <NavItem
              showMarketing
              feature="nursing"
              label="Nursing"
              to={`${match.url}/nursing`}
              featureFlagV2="nursing"
              permission="nursing.view"
            />
            <NavItem
              feature="progress_notes"
              label="Progress Notes"
              to={`${match.url}/progress-notes`}
              featureFlagV2="progress_notes"
              permission="progress_notes.view"
            />
          </NavGroup>
        </NavGroup>
      </Nav>

      <Switch>
        <ProtectedRoute path={`/me/:resource_id/activity-timeline`} component={ActivityTimeline} permission="activity_timeline.view" />

        <Route path={`/me/:resource_id/general-info`} component={GeneralInfo} />

        <ProtectedRoute
          path={`/me/:resource_id/shift-notes`}
          component={ShiftNotes}
          permission="shift_notes.view"
          featureFlagV2="shift_notes"
        />

        <ProtectedRoute
          path={`/me/:resource_id/clinical-notes`}
          component={ClinicalNotes}
          permission="clinical_notes.view"
          featureFlagV2="clinical_notes"
        />

        <ProtectedRoute
          path={`/me/:resource_id/peer-notes`}
          component={PeerNotes}
          permission="peer_notes.view"
          featureFlagV2="peer_notes"
        />

        <ProtectedRoute path={`/me/:resource_id/files`} component={Files} permission="employees.files.view" featureFlagV2="files" />

        <ProtectedRoute path={`/me/:resource_id/todo`} component={Todos} permission="todos.view" featureFlagV2="todos" />

        <ProtectedRoute path={`/me/:resource_id/calendar`} component={Calendar} permission="events.view" featureFlagV2="calendar" />

        <ProtectedRoute
          path={`/me/:resource_id/client-custom-notes`}
          component={ClientCustomNotes}
          permission="client_custom_notes.view"
          featureFlagV2="client_custom_notes"
        />

        <ProtectedRoute
          path={`/me/:resource_id/custom-notes`}
          component={CustomNotes}
          permission="staff_custom_notes.view"
          featureFlagV2="staff_custom_notes"
        />

        <ProtectedRoute
          path={`/me/:resource_id/medical-assessments`}
          component={MedicalAssessments}
          featureFlagV2="medical_assessments"
          permission="medical_assessments.view"
        />

        <ProtectedRoute
          path={`/me/:resource_id/outcome-measures`}
          component={OutcomeMeasures}
          permission="outcome_measures.view"
          featureFlagV2="outcome_measures"
        />

        <ProtectedRoute
          path={`/me/:resource_id/clinical-measurements`}
          component={ClinicalMeasurements}
          permission="clinical_measurements.view"
          featureFlagV2="clinical_measures"
        />

        <ProtectedRoute
          path={`/me/:resource_id/progress-reviews`}
          component={ProgressReviews}
          permission="progress_reviews.view"
          featureFlagV2="progress_reviews"
        />

        <ProtectedRoute
          path={`/me/:resource_id/clinical-assessments`}
          component={ClinicalAssessments}
          permission="clinical_assessments.view"
          featureFlagV2="clinical_assessments"
        />

        <ProtectedRoute
          path={`/me/:resource_id/recovery-coaching`}
          component={RecoveryCoaching}
          permission="recovery_coaching.view"
          featureFlagV2="recovery_coaching"
        />

        <ProtectedRoute path={`/me/:resource_id/nursing`} component={Nursing} featureFlagV2="nursing" permission="nursing.view" />

        <Redirect exact from={`/me/:resource_id`} to="/me/:resource_id/general-info" />
      </Switch>

      <Routes>
        <RouteV6
          path="/me/:resource_id/treatment-plans/*"
          element={
            <ProtectedRouteV6 featureFlagV2="treatment_plans" permission={viewSomeTreatmentPlans}>
              <TreatmentPlans />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/me/:resource_id/progress-notes/*"
          element={
            <ProtectedRouteV6 featureFlagV2="progress_notes" permission="progress_notes.view">
              <ProgressNotes />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/me/:resource_id/test-results/*"
          element={
            <ProtectedRouteV6 featureFlagV2="test_results" permission="test_results">
              <TestResults />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/me/:resource_id/physician-orders/*"
          element={
            <ProtectedRouteV6 featureFlagV2="physician_orders" permission="physician_orders.view">
              <PhysicianOrders />
            </ProtectedRouteV6>
          }
        />
      </Routes>
    </PageGrid>
  )
}

export default withBackListener(withPageError(User))
