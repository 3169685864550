import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { DocumentationReviewReportDataTable } from '@behavehealth/constructs/LiveReports/DocumentationReviewReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const DocumentationReview = () => {
  const tableProps = useDataTable({
    name: ['reports', 'documentation-review'],
    endpoint: `/live_reports/search?category=documentation_review`,
    localStorageKey: 'report_documentation_review_v1',
  })

  const to = React.useMemo(() => (rowData: any) => getResourceLink(rowData.author), [])

  return (
    <Page icon="patient_progress_measure" title="Documentation Review">
      <Grid>
        <DocumentationReviewReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(DocumentationReview)
