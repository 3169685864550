import React from 'react'
import startCase from 'lodash/startCase'

import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { COMMUNICABLE_DISEASE_TESTS } from '../../utils/constants'
import Status from '../../components/Status'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const CommunicableDiseaseTestsReportDataTable = (props: any) => {
  const { to } = props

  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Date & Time',
        id: 'recorded_at',
        model: 'recorded_at',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={usDateTime(value, timezone)} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'client',
        model: 'client.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 180,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ data, value }) => {
          if (value === 'refused') return <Status color="red" label="Refused Collection" />
          if (value === 'collected') return <Status color="blue" label="Collected" />

          // NOTE @Andrei: Implement NotApplicable component
          return 'N/A'
        },
      },
      {
        width: 220,
        id: 'category',
        model: 'category',
        title: 'Test Type',
        formatValue: ({ data, value }: any) => COMMUNICABLE_DISEASE_TESTS[value] || '-',
      },
      {
        width: 160,
        id: 'result',
        model: 'result',
        title: 'Result',
        formatValue: ({ data, value }) => {
          if (value === 'positive') return <Status color="red" label="Positive" />
          if (value === 'negative') return <Status color="green" label="Negative" />
          if (value === 'not_available') return <Status color="grey" label="N/A" />
          return '–'
        },
      },
      {
        width: 160,
        id: 'quantitative_measure',
        model: 'quantitative_measure',
        title: 'Quantitative Measure',
        formatValue: ({ value }: any) => (value ? startCase(value) : '-'),
      },
      {
        width: 180,
        id: 'author',
        model: 'author.name',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Communicable Disease Tests"
      icon="test_results"
      columns={columns}
      filtersConfig={FILTERS.communicable_disease_tests}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="communicable_disease_tests"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=communicable_disease_tests'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'communicable_disease_tests' }} />
        </>
      }
      {...props}
    />
  )
}
