import React, { useRef } from 'react'
import clsx from 'clsx'
import Handsontable from 'handsontable'

import { beautifulFloat, niceAmount } from '../../../utils/functions'
import Tooltip from '../../Tooltip'
import Button from '../../Button'
import RadioCheckElement from '../../Forms/RadioCheckElement'

type SelectRowRendererProps = {
  TD?: HTMLTableCellElement
  value?: string | number
  row?: number
  col?: number
  cellProperties?: Handsontable.CellProperties
  config?: any
  isEditable?: boolean
  readOnly?: boolean
  validator?: any
}

export const SelectRowRenderer = (props: SelectRowRendererProps) => {
  const { config, value, readOnly, isEditable, validator, TD, selectedRows } = props

  const [checked, setChecked] = React.useState(selectedRows?.includes?.(props.row) ? true : false)

  React.useEffect(() => {
    if (selectedRows) {
      setChecked(selectedRows?.includes?.(props.row) ? true : false)
    }
  }, [selectedRows])

  return (
    <div
      className={clsx('flex items-center flex-nowrap h-[29px] font-[600] justify-center tabular-nums mx-[-4px] px-[4px] will-animate', {
        htDimmed: readOnly,
      })}
    >
      {isEditable && (
        <RadioCheckElement
          type="checkbox"
          isChecked={checked}
          onClick={() => {
            const nextChecked = !checked

            setChecked(nextChecked)

            if (config?.onRowSelect) {
              config.onRowSelect(props.row, nextChecked)
            }
          }}
        />
      )}
    </div>
  )
}
