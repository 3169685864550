import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../theme'
import { beautifulFloat, titleCase } from '../../utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Status from '../../components/Status'

import { RecoveryCapitalFormStatus } from './RecoveryCapitalFormStatus'

const MAX_VALUES = {
  quality_of_life_score: 100,
  barriers_to_recovery_score: 5,
  services_and_needs_score: 8,
  personal_capital_score: 25,
  social_capital_score: 25,
  recovery_group_score: 14,
  other_support_score: 28,
  commitment_score: 30,
  negative_capital_summary_score: 100,
  positive_capital_summary_score: 100,
  recovery_capital_index_score: 100,
}

const GREEN = tint(0.02, COLORS.mintGreen)
const ORANGE = tint(0.45, COLORS.orange)
const RED = tint(0.1, COLORS.red)

export const RecoveryCapitalFormsDataTable = (props: any) => {
  const { to, duplicateColumn, mainLinkAs } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell as={mainLinkAs} id={data.id} value={data.name} avatar={data.avatar || ''} to={to?.(data)} />
        ),
      },
      {
        width: 130,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <RecoveryCapitalFormStatus status={value} />,
      },
      {
        width: 130,
        model: 'lock_status',
        title: 'Edit Status',
        formatValue: ({ value }: any) =>
          value === 'locked' ? <Status label="Locked" color="green" /> : <Status label="Can Edit" color="blue" />,
      },
      {
        width: 190,
        model: 'started_at',
        title: 'Start Date & Time',
        type: 'date_time',
      },
      {
        width: 120,
        id: '_scoring',
        title: 'Scoring',
        hoverExpand: false,
        type: 'popover',
        hoverSide: 'top',
        formatValue: ({ data }) => {
          return (
            <div className="flex items-end flex-nowrap h-[30px] max-w-[160px]">
              {Object.keys(MAX_VALUES).map((key) => {
                const value = data[key] || 0
                const height = (Math.abs(value) * 100) / MAX_VALUES[key]
                const color = height < 33 ? RED : height < 66 ? ORANGE : GREEN

                return (
                  <div
                    key={key}
                    className="flex-[1_1_auto] mr-[1px] bg-[red]"
                    style={{ height: height ? `${height}%` : 1, background: height ? color : COLORS.divider }}
                  />
                )
              })}
            </div>
          )
        },
        hoverContent: ({ data }: any) => {
          return (
            <div className="w-full">
              {Object.keys(MAX_VALUES).map((key, index) => {
                const value = data[key] || 0
                const width = (Math.abs(value) * 100) / MAX_VALUES[key]
                const color = width < 33 ? RED : width < 66 ? ORANGE : GREEN

                return (
                  <div key={key} className="grid grid-cols-[50px_150px_120px] mq480:grid-cols-[50px_200px_180px] gap-1 items-center">
                    <div className="text-[0.88rem] truncate font-[600] text-right pr-2">{beautifulFloat(parseFloat(value))}</div>
                    <div className="text-[0.88rem] truncate">{titleCase(key)}</div>
                    <div
                      className="h-4 opacity-0 scale-x-0 animate-scaleFromLeft"
                      style={{
                        width: width ? `${width}%` : 2,
                        background: width ? color : COLORS.divider,
                        animationDelay: `${index * 0.03}s`,
                      }}
                    />
                  </div>
                )
              })}
            </div>
          )
        },
      },
      {
        width: 200,
        model: 'negative_capital_summary_score',
        title: 'Negative Capital Summary',
        type: 'float',
      },
      {
        width: 200,
        model: 'positive_capital_summary_score',
        title: 'Positive Capital Summary',
        type: 'float',
      },
      {
        width: 200,
        model: 'recovery_capital_index_score',
        title: 'Overall Recovery Capital Score',
        type: 'float',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Recovery Capital Forms"
      icon="progress_reviews"
      columns={columns}
      filtersConfig={FILTERS.client_contacts}
      {...props}
    />
  )
}
