import React from 'react'
import size from 'lodash/size'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import FormSection from '../../../components/Forms/FormSection'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { AgreementsDataTable } from '../../Agreements/AgreementsDataTable'
import { ClientAgreementOverlay } from '../../Agreements/ClientAgreementOverlay'
import { ClientAgreementsPageActions } from '../../Agreements/ClientAgreementsPageActions'
import { useDataTable } from '../../../components/DataTable/useDataTable'

const Agreements = ({ client, setStatus, match }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id } = match.params

  const [openedAgreement, setOpenedAgreement] = React.useState(null)

  const tableProps: any = useDataTable({
    name: ['client', resource_id, 'agreements'],
    endpoint: `/residents/${resource_id}/agreements`,
    updateDeleteEndpoint: '/agreements',
    params: { variant: 'client' },
    localStorageKey: 'clients_agreements',
    enabled: !!resource_id,
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <>
      <FormSection maxWidth="100%">
        <Alert contrast glyph="info">
          Import agreements from templates and customize them if needed.
        </Alert>

        <Card>
          <AgreementsDataTable
            asCard={false}
            activeId={openedAgreement?.id}
            {...tableProps}
            onClick={(record) => setOpenedAgreement(record)}
          />

          <CardContent>
            <div className="flex">
              <ClientAgreementsPageActions
                useSummonOverlay
                showManageLink={false}
                buttonSize={200}
                buttonDisplay="inline-flex"
                client={client}
                clientTrack={client?.client_track}
              />
            </div>
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        key={`summon-${openedAgreement?.id}`}
        isOpen={!!openedAgreement}
        onClose={() => setOpenedAgreement(null)}
        overlay={<ClientAgreementOverlay dataID={openedAgreement?.id} />}
      />
    </>
  )
}

export default Agreements
