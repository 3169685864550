import React from 'react'

import ContextShow from '../../../components/Forms/ContextShow'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Input from '../../../components/Forms/Input'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Textarea from '../../../components/Forms/Textarea'

import { ReferralColumn } from '../components/ReferralColumn'

export const TransportDetails = (props: any) => {
  const { client, data, setData, timezone, initialModel } = props

  const form = React.useRef()
  const [valid, setValid] = React.useState()

  const handleUpdate = (value: any) => {
    setData(value)
  }

  return (
    <ReferralColumn icon={props.icon} title={props.title}>
      <Form
        getForm={form}
        initialModel={initialModel}
        onUpdate={handleUpdate}
        onValidationUpdate={setValid}
        timezone={timezone}
        defaultDebounce={300}
      >
        <FormSection>
          <DateTimeInput label="Pickup Date & Time" />
          <DateTimeInput label="Arrival Date & Time" />

          <Input label="Who is doing the transporting?" model="transport_person" />

          <RadioGroup label="Transport Method" layout="vertical-dense" model="transport_method">
            <Radio label="Company Vehicle" value="company_vehicle" />
            <Radio label="Personal Vehicle" value="personal_vehicle" />
            <Radio label="Medical Transport" value="medical_transport" />
            <Radio label="Public Transportation" value="public_transportation" />
            <Radio label="Other" value="other" />
          </RadioGroup>

          <ContextShow when="transport_method" not="null">
            <Textarea label="Transport Details" model="transport_notes" />
            <Textarea label="Transport Contact Details" model="transport_contact_details" />
          </ContextShow>
        </FormSection>
      </Form>
    </ReferralColumn>
  )
}
