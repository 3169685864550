import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Page, HelpTagIframe } from '@behavehealth/components'
import { CustomNotesDataTable } from '@behavehealth/constructs/CustomNotes/CustomNotesDataTable'
import { getClientLink } from '@behavehealth/utils/functions'
import Grid from '@behavehealth/components/Grid'

import { StaffDataFormsAlert } from '../components/StaffDataFormsAlert'

const pageConfig = {
  title: 'Client Custom Notes',
  feature: 'custom_notes',
  marketingID: 'client_financials',
  help: <HelpTagIframe id="custom_notes" />,
}

type Props = {
  canCreate: boolean
}

const ClientCustomNotes: React.FC<Props> = () => {
  const match = useRouteMatch()
  const id = match.params?.resource_id

  const tableProps = useDataTable({
    name: ['employee', id, 'custom_notes'],
    endpoint: `/employees/${id}/custom_notes`,
    params: { variant: 'client' },
    updateDeleteEndpoint: '/custom_notes',
    localStorageKey: 'client_custom_notes',
  })

  return (
    <Page
      plan="basic"
      actions={
        <Button
          size={300}
          glyph="settings"
          type="default"
          label="Manage Templates"
          link="/settings/custom-note-templates/clients"
          permission="settings.client_custom_note_templates.view"
        />
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <StaffDataFormsAlert name="Client Custom Notes" />
        <CustomNotesDataTable
          {...tableProps}
          to={(data: any) => ({
            pathname: `${match.url}/${data.id}`,
            parent: match,
          })}
          additionalColumns={[
            {
              title: 'Client',
              model: 'reference',
              type: 'reference',
            },
          ]}
          duplicatePermission="client_custom_notes.create"
          duplicateLink={(record: any) => {
            const {
              client,
              employees,
              ended_at,
              name,
              notes,
              office,
              organization,
              portal_settings,
              house,
              reference,
              sections,
              settings,
              started_at,
              supervisor,
              tags,
              variant,
            } = record

            return {
              pathname: `${match.url}/new`,
              parent: match,
              data: {
                status: 'draft',
                client,
                employees,
                ended_at,
                name,
                notes,
                office,
                organization,
                organization_id: organization?.id,
                portal_settings,
                house,
                house_id: house?.id,
                reference,
                sections,
                settings,
                started_at,
                supervisor,
                tags,
                variant,
              },
            }
          }}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'client_custom_notes.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ClientCustomNotes, pageConfig))
