import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'
import Status from '../../components/Status'
import ClientStatus from '../../components/Statuses/ClientStatus'

import { amount, usDate } from '../../utils/functions'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

export const WriteOffsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        id: 'name',
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        id: 'resident',
        width: 180,
        model: 'resident.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 180,
        id: 'resident_status',
        model: 'resident.status',
        title: 'Client Status',
        formatValue: ({ value }: any) => <ClientStatus status={value} />,
      },
      {
        title: 'Color',
        id: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker isDisabled clientID={data?.resident?.id} color={data?.resident?.color_code} />,
      },
      {
        id: 'amount',
        width: 180,
        model: 'amount',
        title: 'Amount',
        formatValue: ({ value }: any) => <Status color="green" label={`+$${amount(value)}`} />,
      },
      {
        id: 'transactioned_at',
        model: 'transactioned_at',
        title: 'Write-Off Date',
        type: 'date',
      },
      {
        id: 'notes',
        model: 'notes',
        title: 'Notes',
      },
      {
        id: 'client_location',
        model: 'client_location.name',
        title: 'Client Location',
        type: 'profile',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
      {
        width: 140,
        id: 'created_at',
        model: 'created_at',
        title: 'Create Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 140,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Update Date',
        type: 'date_time',
        disableSort: false,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Write-Offs"
      icon="financials"
      columns={columns}
      filtersConfig={FILTERS.live_reports.financial_transactions}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="write_offs"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=write_offs'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'write_offs' }} />
        </>
      }
      {...props}
    />
  )
}
