import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { DEFAULT_FILTERS } from '../Filters/constants'
import { titleCase } from '../../utils/functions'

export const SafetyChecksDataTable = (props) => {
  const { duplicateColumn } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Note',
        model: 'notes',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={props.to?.(data)} value={value?.replace?.(/<[^>]*>/g, ' ')} />
        },
      },
      {
        title: 'Staff',
        model: 'employees',
        type: 'profiles',
        disableSort: true,
      },
      {
        title: 'Location',
        model: 'house',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Organization',
        model: 'organization',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Date & Time',
        model: 'dated_at',
        type: 'date_time',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ],
    [props?.to],
  )

  return <DataTable asCard title="Safety Checks" icon="safety_checks" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
}

const FILTERS_CONFIG = {
  notes: {
    label: 'Note',
    type: 'string',
  },
  employees: {
    type: 'multi_object',
    label: 'Staff',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.position),
  },
  house: DEFAULT_FILTERS.house,
  organization: DEFAULT_FILTERS.organization,
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
