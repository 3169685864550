import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { Button, PageHeader, Tab, TabList, Tabs } from '@behavehealth/components'

import ActiveInsurancePayers from './active_insurance_payers'
import ArchivedInsurancePayers from './archived_insurance_payers'

const InsurancePayers: React.FC = () => {
  const match = useRouteMatch()

  const actions = (
    <>
      <Button cypress="button_import_payer" label="Import Payers" type="default" glyph="add" link={`${match.url}/import`} />

      <Button
        cypress="button_add_payer"
        label="Add Payer"
        type="primary"
        glyph="add"
        link={`${match.url}/new`}
        permission="insurance_local_payers.create"
      />
    </>
  )

  return (
    <div css={STYLES.root}>
      <PageHeader showFeedbackTool title="Insurance Payers" icon="bank_building" aside={actions} />

      <div>
        <Tabs>
          <TabList css={STYLES.tabList}>
            <Tab label="Active" to={`active`} />
            <Tab label="Archived" to={`archived`} />
          </TabList>
        </Tabs>
      </div>

      <Switch>
        <Route path={`/insurance-payers/active`} component={ActiveInsurancePayers} />
        <Route path={`/insurance-payers/archived`} component={ArchivedInsurancePayers} />
        <Redirect exact from="/insurance-payers" to="/insurance-payers/active" />
      </Switch>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'min-content min-content minmax(100px, 1fr)',
    overflow: 'hidden',
  },

  tabList: {
    paddingLeft: '1rem',
  },
}

export default withPageError(InsurancePayers)
