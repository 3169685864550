import React from 'react'
import startCase from 'lodash/startCase'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'

import Status from '@behavehealth/components/Status'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const OrganizationsContactsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} avatar={data.avatar} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'reference',
        model: 'reference.name',
        title: 'Organization',
        type: 'profile',
      },
      {
        width: 130,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ data, value }: any) => {
          if (value == 'active') return <Status color="green" label="Active" />
          else if (value == 'inactive') return <Status color="red" label="Inactive" />
        },
      },
      {
        width: 130,
        id: 'dob',
        model: 'dob',
        title: 'Date of Birth',
        type: 'date',
      },
      {
        width: 100,
        id: 'sex',
        model: 'sex',
        title: 'Sex',
        formatValue: ({ data, value }: any) => startCase(value),
        // filterOptions: [
        //   {
        //     value: 'male',
        //     label: 'Male',
        //   },
        //   {
        //     value: 'female',
        //     label: 'Female',
        //   },
        //   {
        //     value: 'decline_to_respond',
        //     label: 'Decline To Respond',
        //   },
        //   {
        //     value: 'other',
        //     label: 'Other',
        //   },
        // ],
      },
      {
        width: 130,
        id: 'role',
        model: 'role',
        title: 'Role',
        formatValue: ({ value }: any) => startCase(value),
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'phone_no',
        title: 'Phone',
      },
      {
        width: 240,
        id: 'email',
        model: 'email',
        title: 'Email',
      },
      {
        width: 160,
        id: 'notes',
        model: 'notes',
        title: 'Notes',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Organization Contacts"
      icon="contacts"
      columns={columns}
      filtersConfig={FILTERS.organization_contacts}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="organization_contacts"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=organization_contacts'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'organization_contacts' }} />
        </>
      }
      {...props}
    />
  )
}
