import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import produce from 'immer'
import size from 'lodash/size'

import { apiGet } from '../../../../../modules/api'
import { arrayToMapWithKeyPrefix, mapToArray } from '../../../../../utils/functions'
import { TabsContext } from '../../../../../hocs/withTabsContext'

import Button from '../../../../Button'
import Card from '../../../../Card'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import Form from '../../../../Forms/Form'
import FormSection from '../../../../Forms/FormSection'
import State from '../../../../State'
import Status from '../../../../Status'

import DiagnosisAccordion from '../components/DiagnosisAccordion'
import Footer from '../components/Footer'
import Main from '../components/Main'

import { icd10ToKey, keyToID } from './utils'

const setInitialPageData = (wileyData: any) => {
  const res: any = {}

  for (const icd10 of Object.keys(wileyData)) {
    res[icd10ToKey(icd10)] = {}
  }

  return res
}

const Behavior = ({ formData, setFormData, wileyData, setWileyData }: any) => {
  const { onSelect: selectTab }: any = React.useContext(TabsContext)

  const form: any = React.useRef()

  const initialPageData = setInitialPageData(wileyData)
  const [pageData, setPageData] = React.useState(initialPageData)

  const updatePageData = (icd10: any, data: any) => {
    setPageData(
      produce((draft: any) => {
        draft[icd10] = data
      }),
    )
  }

  const updateWileyData = () => {
    const icd10Keys = Object.keys(wileyData)
    const { selected_diagnoses } = form.current?.getFormValue()

    let newWileyData = cloneDeep(wileyData)

    for (const icd10 of icd10Keys) {
      const { selected_definitions } = selected_diagnoses[icd10] || {}

      const newDefinitions: any = {}

      for (const definition_id in selected_definitions) {
        // skip if definition is not selected
        if (!selected_definitions[definition_id]) continue

        const id = keyToID(definition_id)
        const description = pageData[icd10][definition_id].description

        newDefinitions[definition_id] = {
          definition_id: id,
          description: description,
        }
      }

      // map selected definitions to wiley data
      newWileyData = produce(newWileyData, (draft: any) => {
        draft[icd10].definitions = newDefinitions
      })
    }

    setWileyData(newWileyData)
  }

  const goNext = () => {
    updateWileyData()
    selectTab('goals')
  }

  const goBack = () => {
    selectTab('diagnoses')
  }

  if (!formData) return null

  return (
    <>
      <Main>
        <Form useFullModel getForm={form} initialModel={formData} onUpdate={setFormData}>
          <FormSection maxWidth="100%">
            {mapToArray(wileyData).map((diagnosis: any) => (
              <BehaviorSection
                key={diagnosis.icd10}
                icd10={diagnosis.icd10}
                icd10Description={diagnosis.description}
                problemGroupID={diagnosis.problem_group_id}
                problemID={diagnosis.problem_id}
                problemGroupDescription={diagnosis.problem_group_description}
                problemDescription={diagnosis.problem_description}
                updatePageData={updatePageData}
              />
            ))}
          </FormSection>
        </Form>
      </Main>

      <Footer>
        <Button label="Continue →" type="primary" onClick={goNext} />
      </Footer>
    </>
  )
}

const BehaviorSection: React.FC<any> = (props) => {
  const { goBack, icd10, icd10Description, problemDescription, problemGroupDescription, problemGroupID, problemID, updatePageData } = props

  const [data, setData]: any = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const isEmpty = size(data) === 0

  // Get Wiley Data
  React.useEffect(() => {
    if (!problemGroupID && !problemID) return

    const getAPIData = async () => {
      try {
        setLoading(true)

        const result = await apiGet({ url: `/wiley/behavioral_definitions?problem_group_id=${problemGroupID}&problem_id=${problemID}` })

        const data = result?.data?.data
        const pageData = arrayToMapWithKeyPrefix(data, 'definition_id', 'id_')

        setData(data)
        updatePageData(icd10ToKey(icd10), pageData)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getAPIData()
  }, [problemGroupID, problemID])

  if (!problemGroupID && !problemID) return null

  if (loading || isEmpty) {
    return (
      <Card>
        <State
          isLoading={loading}
          isEmpty={isEmpty}
          title="Behavioral Definitions"
          emptyDescription="Please select at least one diagnosis and problem"
          icon="treatment_plans"
          emptyActions={<Button label="← Go Back" type="minimal" size={200} onClick={goBack} />}
        />
      </Card>
    )
  }

  return (
    <DiagnosisAccordion
      icd10={icd10}
      icd10Description={icd10Description}
      problemGroupDescription={problemGroupDescription}
      problemDescription={problemDescription}
    >
      <Status label="Behavioral Definitions:" color="green" css={styles.status} />

      <CheckboxGroup layout="vertical-dense" withHover={false} css={styles.checkboxGroup}>
        {data.map((definition: any) => (
          <Checkbox
            key={definition.definition_id}
            label={definition.description}
            model={`selected_diagnoses.${icd10ToKey(icd10)}.selected_definitions.id_${definition.definition_id}`}
          />
        ))}
      </CheckboxGroup>
    </DiagnosisAccordion>
  )
}

const styles = {
  checkboxGroup: {
    paddingBottom: '1rem',
  },

  status: {
    marginTop: '0.75rem',
    marginBottom: '0.75rem',
  },
}

export default Behavior
