import React from 'react'
import clsx from 'clsx'
import Handsontable from 'handsontable'

import Tooltip from '../../Tooltip'

type TextRendererProps = {
  TD?: HTMLTableCellElement
  value?: string | number
  row?: number
  col?: number
  cellProperties?: Handsontable.CellProperties
  config?: any
  isEditable?: boolean
  readOnly?: boolean
  validator?: any
}

export const TextRenderer = (props: TextRendererProps) => {
  const { value, readOnly, isEditable, validator, TD, config = {} } = props
  const { render, animateOnChange } = config

  const [errors, setErrors] = React.useState([])
  const isValid = errors.length === 0

  const displayValue = render ? render(value) : value

  const ref = React.useRef<HTMLDivElement>(null)
  const currentValue = React.useRef(value)

  React.useEffect(() => {
    if (!validator) return

    validator(value, (valid, newErrors) => {
      if (newErrors) setErrors(newErrors)
    })
  }, [value])

  if (animateOnChange && currentValue.current !== value) {
    if (!ref.current) return

    ref.current.classList.add('animated')

    setTimeout(() => {
      ref.current.classList.remove('animated')
    }, 200)

    currentValue.current = value
  }

  if (TD) {
    readOnly ? TD.classList.add('htDimmed') : TD.classList.remove('htDimmed')

    if (isEditable) {
      isValid ? TD.classList.remove('htInvalid') : TD.classList.add('htInvalid')
    }
  }

  return (
    <div
      ref={ref}
      className={clsx('flex items-center flex-nowrap h-[29px] justify-between tabular-nums mx-[-4px] px-[4px] will-animate', {
        htDimmed: readOnly,
      })}
    >
      <div className="flex-[1_1_auto] truncate">{displayValue}</div>

      {isEditable && errors.length > 0 && (
        <div className="ml-2 inline-flex items-center">
          <Tooltip
            glyph="circle_error"
            color="red"
            content={
              <>
                {errors.map((error, index) => (
                  <div key={index}>{error?.message}</div>
                ))}
              </>
            }
          />
        </div>
      )}
    </div>
  )
}
