import React from 'react'

import { COLORS } from '../../theme'
import { titleCase } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Divider from '../Divider'
import NavGroup from '../NavGroup'
import NavItem from '../NavItem'

import useStore from '../../modules/store'

import { useTreatmentPlanPermissions } from '../../constructs/TreatmentPlans/useTreatmentPlanPermissions'

let version = process.env.BH_VERSION
let common_version = process.env.BH_COMMON_VERSION

const SettingsNavItems = () => {
  const tenant = useStore((state: any) => state.tenant)
  const user = useStore((state: any) => state.user)
  const isAdministrator = ['owner', 'administrator'].includes(user?.position)

  const { isBehave, isOwner, isSelfServiceBilling } = useSettings()

  const { viewSomeTreatmentPlanTemplates, viewSomeTreatmentBooks } = useTreatmentPlanPermissions()

  return (
    <>
      <NavGroup label="Onboarding Files">
        <NavItem
          icon="clinical_assessments"
          label="Organization Templates"
          to={`/settings/organization-templates`}
          featureFlagV2="onboarding_files"
        />
        <NavItem icon="web_form" label="CSV/Excel for Imports" to={`/settings/csv-excel-imports`} featureFlagV2="onboarding_files" />
        <NavItem icon="community" label="Clients Backup Records" to={`/settings/clients-backup-records`} featureFlagV2="onboarding_files" />
        <NavItem icon="files" label="Other Backup Records" to={`/settings/other-backup-records`} featureFlagV2="onboarding_files" />
      </NavGroup>

      <NavGroup label="Settings">
        <NavItem feature="your_settings" label="Your Settings" to={`/settings/your-profile`} />

        <NavItem
          feature="company_settings"
          label="Company Settings"
          to={`/settings/company-profile`}
          permission="settings.company_profile.company_settings.view"
        />

        {(isAdministrator || isBehave) && (
          <NavItem icon="files" label="Admin Files" to={`/settings/admin-files`} featureFlagV2="admin_files" />
        )}

        {isBehave && <NavItem icon="files" label="Behave Files" to={`/settings/behave-files`} />}

        {/* <NavItem feature="schedule" label="Event Manager" to={`/settings/event-manager`} permission={true} /> */}

        <NavItem feature="notifications" label="Notifications" to={`/settings/notifications`} permission={isBehave || isAdministrator} />

        <NavItem
          feature="company_credentials"
          label="Company Credentials"
          to={`/settings/company-credentials`}
          permission="settings.credentials.view"
          featureFlagV2="company_credentials"
        />

        <NavItem
          feature="company_integrations"
          label="Company Integrations"
          to={`/settings/company-integrations`}
          //
          permission={isBehave}
          // featureFlagV2="company_credentials"
        />
      </NavGroup>

      <NavGroup label="Admissions">
        <NavItem
          showMarketing
          showForCommunity
          feature="custom_application_form"
          label="Application Forms"
          to={`/settings/application-forms`}
          permission="settings.application_form.view"
          featureFlagV2="custom_application_form"
        />
        <NavItem
          showForCommunity
          feature="embeds"
          label="Embeds"
          to={`/settings/widgets`}
          featureFlagV2="admissions"
          permission="settings.embeds.view"
        />
      </NavGroup>

      <NavGroup label="Client Workflows">
        <NavItem
          feature="client_tracks"
          label="Client Tracks"
          to={`/settings/client-tracks`}
          featureFlagV2="client_tracks"
          permission="client_tracks.view"
        />
        <NavItem
          feature="client_workflows"
          label="Workflow Settings"
          to={`/settings/client-workflows`}
          featureFlagV2="client_workflows"
          permission="settings.client_workflows.view"
        />
        <NavItem
          feature="client_workflows"
          label="Message Templates"
          to={`/settings/client-workflows-message-templates`}
          featureFlagV2="client_workflows_message_templates"
          permission="settings.client_workflows_message_templates.view"
        />
      </NavGroup>

      <NavGroup label="Tools">
        <NavItem
          feature="measure_settings"
          label="Measure Settings"
          to={`/settings/measure-settings`}
          featureFlagV2="outcome_measures"
          permission="settings.form_settings.view"
        />

        <NavItem
          showForCommunity
          feature="forms_builder"
          label="Forms Builder"
          to={`/settings/forms-builder`}
          featureFlagV2="forms_builder"
          permission="forms_builder.view"
        />

        <NavItem
          feature="assessments_builder"
          label="Assessments Builder"
          to={`/settings/assessments-builder`}
          featureFlagV2="assessments_builder"
          permission="assessments_builder.view"
        />

        <NavItem
          feature="discharges_builder"
          label="Discharges Builder"
          to={`/settings/discharges-builder`}
          featureFlagV2="discharges_builder"
          permission="discharges_builder.view"
        />

        <NavItem
          feature="variables"
          label="Form Variables"
          to={`/settings/variables`}
          featureFlagV2="variables"
          permission="form_variables.view"
        />

        <NavItem
          feature="short_links"
          label="Short Links"
          to={`/settings/short-links`}
          featureFlagV2="short_links"
          permission="settings.short_links.view"
        />

        <NavItem feature="tags" label="Tags Manager" to={`/settings/tags-manager`} featureFlagV2="tags" permission="tag_groups.view" />
        <NavItem
          feature="bookmarks_manager"
          label="Bookmarks Manager"
          to={`/settings/bookmarks-manager`}
          featureFlagV2="bookmarks"
          permission="settings.bookmarks_manager.view"
        />
        <NavItem
          feature="quick_text"
          label="Quick Text"
          to={`/settings/quick-text`}
          featureFlagV2="quick_text"
          permission="settings.quick_text.view"
        />
      </NavGroup>

      <NavGroup label="Templates">
        <NavItem
          feature="rounds"
          label="Rounds"
          to={`/settings/rounds-templates`}
          permission="rounds_templates.view"
          featureFlagV2="rounds_templates"
        />
        <NavItem
          showForCommunity
          showMarketing
          feature="todo_templates"
          label="To-Do"
          to={`/settings/todo-templates`}
          permission="todo_templates.view"
          featureFlagV2="todo_templates"
        />
        <NavItem
          showForCommunity
          showMarketing
          feature="calendar_templates"
          label="Calendar"
          to={`/settings/calendar-templates`}
          permission="calendar_templates.view"
          featureFlagV2="calendar_templates"
        />
        <NavItem
          showMarketing
          feature="agreement_templates"
          label="Agreements"
          to={`/settings/agreement-templates`}
          featureFlagV2="client_agreement_templates"
        />
        <NavItem
          showMarketing
          feature="custom_note_templates"
          label="Custom Notes"
          to={`/settings/custom-note-templates`}
          featureFlagV2="client_custom_note_templates"
        />

        <NavItem
          feature="treatment_plan_templates"
          label="Treatment Plans"
          to={`/settings/treatment-plan-templates`}
          permission={viewSomeTreatmentPlanTemplates}
          featureFlagV2="treatment_plans"
        />

        <NavItem
          feature="treatment_plan_templates"
          label="Treatment Book"
          to={`/settings/treatment-plan-book`}
          permission={viewSomeTreatmentBooks}
          featureFlagV2="treatment_plans"
        />

        <NavItem
          showForCommunity
          icon="community_partners"
          label="Community Invites"
          to={`/settings/community/invite-templates`}
          color={COLORS.green}
          featureFlagV2="organizations"
          permission="settings.community_invite_templates.view"
        />

        <NavItem
          isBeta
          showForCommunity
          icon="referrals"
          label="Referral Consent Forms"
          to={`/settings/referral-consent-form-templates`}
          color={COLORS.violet}
          permission={isBehave}
        />
      </NavGroup>

      <NavGroup label="Organizations">
        <NavItem
          showMarketing
          showForCommunity
          feature="organization_lead_forms"
          label="Lead Contact Forms"
          to={`/settings/organization-lead-contact-forms`}
          permission={isBehave}
          featureFlagV2="organization_lead_forms"
        />

        <NavItem
          showForCommunity
          feature="authority_forms"
          label="Authority Forms"
          to={`/settings/authority-forms`}
          color={COLORS.blue}
          featureFlagV2="authority_forms"
          permission="settings.authority_forms.view"
        />

        <NavItem showForCommunity feature="directories" label="Directories" to={`/settings/directories`} featureFlagV2="directories" />
      </NavGroup>

      <NavGroup label="Organization Workflows">
        <NavItem
          feature="organization_tracks"
          label="Organization Tracks"
          to={`/settings/organization-tracks`}
          featureFlagV2="organization_tracks"
          permission="organization_tracks.view"
        />
        <NavItem
          feature="organization_workflows"
          label="Workflow Settings"
          to={`/settings/organization-workflows`}
          featureFlagV2="organization_workflows"
          permission="settings.organization_workflows.view"
        />
      </NavGroup>

      <NavGroup label="Templates Archived">
        <NavItem
          feature="treatment_plan_templates"
          label="Treatment Plans"
          to={`/settings/archived-treatment-plan-templates`}
          permission="settings.treatment_plan_templates.view"
          featureFlagV2="treatment_plan_templates"
        />
      </NavGroup>

      <NavGroup label="Financials">
        <NavItem
          showMarketing
          feature="services_book"
          label="Client Services"
          to={`/settings/client-services`}
          permission="settings.services_book.view"
          featureFlagV2="services_book"
        />
        <NavItem
          feature="services_book"
          label="Organization Services"
          to={`/settings/organization-services`}
          permission="settings.services_book.view"
          featureFlagV2="organization_services"
        />
        <NavItem
          feature="settings"
          glyph="settings"
          label="Settings"
          to={`/settings/online-payments`}
          permission="settings.online_payments.view"
          featureFlagV2="online_payments_collection_with_stripe"
        />
        <NavItem
          showMarketing
          feature="financials"
          label="Accounting"
          to={`/settings/accounting`}
          featureFlagV2="accounting"
          permission="settings.accounting.view"
        />
      </NavGroup>

      <NavGroup label="Insurance">
        <NavItem
          feature="company_credentials"
          label="Providers"
          to={`/settings/providers`}
          permission="settings.credentials.view"
          featureFlagV2="company_credentials"
        />
        <NavItem
          feature="insurance"
          label="Insurance Codes"
          to={`/settings/insurance-codes`}
          permission="settings.insurance_codes.view"
          featureFlagV2="insurance_services"
        />
        <NavItem
          feature="insurance"
          label="Fee Schedules"
          to={`/settings/fee-schedules`}
          featureFlagV2="fee_schedules"
          permission={isBehave ? true : isSelfServiceBilling ? 'settings.fee_schedules.view' : false}
        />
      </NavGroup>

      <NavGroup label="Archived Insurance">
        <NavItem
          feature="insurance"
          label="Insurance Services"
          to={`/settings/old-insurance-services-book`}
          permission={isBehave}
          featureFlagV2="insurance_services"
        />
        <NavItem
          feature="insurance"
          label="Insurance Codes"
          to={`/settings/old-insurance-codes`}
          permission={isBehave}
          featureFlagV2="insurance_services"
        />
        <NavItem
          feature="insurance"
          label="Fee Schedules"
          to={`/settings/old-fee-schedules`}
          featureFlagV2="fee_schedules"
          permission={isBehave ? true : isSelfServiceBilling ? 'settings.fee_schedules.view' : false}
        />
      </NavGroup>

      <NavGroup label="Security">
        <NavItem
          showMarketing
          feature="staff_clients_access"
          label="Staff Access"
          to={`/settings/staff-access`}
          permission="access_controls.view"
          featureFlagV2="staff_clients_access"
        />

        <NavItem
          showForCommunity
          showMarketing
          feature="permission_templates"
          label="Permission Templates"
          to={`/settings/permission-templates`}
          permission="settings.permissions.view"
          featureFlagV2="permission_templates"
        />

        <NavItem
          showMarketing
          feature="permissions"
          label="Client Portal Permissions"
          to={`/settings/clients-permissions`}
          permission="settings.client_portal_permissions.view"
          featureFlagV2="client_portal_permissions"
        />

        <NavItem
          showMarketing
          feature="staff_ip_restrictions"
          label="IP Restrictions"
          to={`/settings/ip-restrictions`}
          permission="access_controls.view"
          featureFlagV2="staff_ip_restrictions"
        />
      </NavGroup>

      <NavGroup label="Account">
        <NavItem
          showForCommunity
          feature="active_plans"
          label="Billing"
          to={`/settings/billing`}
          permission={isBehave || isAdministrator}
        />
        <NavItem isBeta feature="referrals" label="Customer Referrals" to={`/settings/referrals`} />

        <NavItem
          showForCommunity
          feature="legal_agreement"
          label="Signup Agreement"
          to={`/settings/legal-agreement`}
          permission="settings.legal_agreement.view"
        />

        <NavItem
          showForCommunity
          feature="legal_agreements"
          label="Legal Agreements"
          to={`/settings/legal-agreements`}
          permission="settings.legal_agreement.view"
        />

        {(isBehave || isOwner) && <NavItem icon="files" label="Behave Agreements" to={`/settings/behave-agreements`} />}
      </NavGroup>

      {isBehave && (
        <NavGroup label="Super Admin">
          <NavItem icon="behave_ai" label="AI Actions" to={`/settings/ai-actions`} featureFlagV2="behave_ai" />
          <NavItem feature="super_admin" label="Account" to={`/settings/account`} />
        </NavGroup>
      )}

      <Divider />

      <NavGroup>
        <>
          <div css={versionStyles}>{`Version ${version}`}</div>
          <div css={versionStyles}>{`Common ${common_version}`}</div>
          <div css={devStyles}>{`Environment: ${titleCase(process.env.NODE_ENV)}`}</div>
          <div css={devStyles}>{`Local: ${titleCase(process.env.BH_ENV)}`}</div>
          {isBehave && <div css={devStyles}>{`Tenant: ${tenant.subdomain}`}</div>}
        </>
      </NavGroup>
    </>
  )
}

const versionStyles: any = {
  opacity: 0.6,
  marginTop: 'auto',
  marginBottom: 10,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1,
}

const devStyles: any = {
  opacity: 0.3,
  marginTop: 'auto',
  marginBottom: 10,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1,
}

export default SettingsNavItems
