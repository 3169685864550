import React from 'react'

import { CLAIMMD_SERVICE_CODES } from '../../../utils/constants'
import MultiObjectSelector from '../../../components/Forms/Selectors/MultiObject/MultiObjectSelector'

const CLAIMMD_SERVICE_CODE_GROUPS = [
  {
    code: 'mental_health_treatment',
    name: 'Mental Health Treatment',
    description:
      'Mental Health, Psychiatric, Psychiatric - Inpatient, Psychiatric - Outpatient, Psychotherapy, Psychiatric Treatment Partial Hospitalization, Day Care (Psychiatric), Cognitive Therapy, Residential Psychiatric Treatment, Serious Mental Health',
    displayDescription: (
      <>
        <ul>
          <li>Mental Health</li>
          <li>Psychiatric</li>
          <li>Psychiatric - Inpatient</li>
          <li>Psychiatric - Outpatient</li>
          <li>Psychotherapy</li>
          <li>Psychiatric Treatment Partial Hospitalization</li>
          <li>Day Care (Psychiatric)</li>
          <li>Cognitive Therapy</li>
          <li>Residential Psychiatric Treatment</li>
          <li>Serious Mental Health</li>
          <li>Mental Health Coverage</li>
        </ul>
      </>
    ),
  },
  {
    code: 'substance_use_disorder_treatment',
    name: 'Substance Use Disorder Treatment',
    description: 'Substance Abuse, Alcoholism Treatment, Drug Addiction',
    displayDescription: (
      <>
        <ul>
          <li>Substance Abuse</li>
          <li>Alcoholism Treatment</li>
          <li>Drug Addiction</li>
        </ul>
      </>
    ),
  },
  {
    code: 'social_services_related',
    name: 'Social Services Related',
    description: 'Social Work, Case Management',
    displayDescription: (
      <>
        <ul>
          <li>Social Work</li>
          <li>Case Management</li>
        </ul>
      </>
    ),
  },
]

export const CLAIMMD_SERVICE_CODE_GROUP_KEYS = CLAIMMD_SERVICE_CODE_GROUPS.map((item) => item.code)

export const CLAIMMD_SERVICE_CODE_DATA = [
  ...CLAIMMD_SERVICE_CODE_GROUPS.map((item) => {
    return { id: item.code, ...item }
  }),
  ...CLAIMMD_SERVICE_CODES.map((item) => {
    return { id: item.code, ...item, name: `${item.code}: ${item.name}` }
  }),
]

export const ClaimMDServiceCodesSelector = (props) => {
  return (
    <MultiObjectSelector
      apiData={CLAIMMD_SERVICE_CODE_DATA}
      model="service_codes"
      label="Service Codes"
      icon="insurance"
      showAvatars={false}
      selectDescription={(o) => o?.displayDescription || o?.description}
      {...props}
    />
  )
}
