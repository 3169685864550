import React from 'react'
import * as HoverCard from '@radix-ui/react-hover-card'

import { COLORS, SHADOW } from '../../../theme'

import { usePortal } from '../../../hooks/usePortal'

export const ValuesTooltip: React.FC = ({ children, trigger, width }) => {
  const container = usePortal('default')
  const collisionBoundary = document?.getElementById?.('root')

  return (
    <HoverCard.Root openDelay={50} closeDelay={50}>
      <HoverCard.Trigger asChild>{trigger}</HoverCard.Trigger>

      <HoverCard.Portal container={container}>
        <HoverCard.Content
          css={STYLES.tooltip}
          collisionBoundary={collisionBoundary}
          align="end"
          side="top"
          style={width ? { width, minWidth: width, maxWidth: width } : undefined}
          sideOffset={-8}
          alignOffset={-8}
        >
          <HoverCard.Arrow width={14} height={8} css={STYLES.arrow} />
          {children}
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  )
}

const STYLES = {
  tooltip: {
    minWidth: 220,
    maxWidth: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 7,
    background: COLORS.white,
    boxShadow: SHADOW(10, COLORS.divider),
    border: 'none',
    padding: '0.55rem',

    '@media(min-width: 600px)': {
      minWidth: 360,
    },
  },

  arrow: {
    color: COLORS.white,
    margin: '0 1rem',
  },
}
