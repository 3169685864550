import React from 'react'
import ResourceQuickLink from '../../QuickLinks/ResourceQuickLink'

const MESSAGES: any = {
  create: (props: any) => (
      <>
        {props.author} added a new {props.record} titled <i>"{props.event.trackable?.service_name}"</i>.
      </>
    ),
    update: (props: any) => (
      <>
        {props.author} updated a {props.record} titled <i>"{props.event.trackable?.service_name}"</i>.
      </>
    ),
    delete: (props: any) => (
      <>
        {props.author} deleted a {props.record} titled <i>"{props.event.trackable?.service_name}"</i>.
      </>
    ),
}

export default (props: any) => MESSAGES[props.event?.action_type]?.(props) || null
