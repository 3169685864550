import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

import Status from '../../components/Status'
import PayerNetworkStatus from '../../components/Statuses/PayerNetworkStatus'
import PayerEnrollmentStatus from '../../components/Statuses/PayerEnrollmentStatus'

import { FILTERS } from '../Filters/config'
import { CLEARING_HOUSES } from '../../utils/constants'

export const ArchivedInsurancePayersDataTable = (props: any) => {
  const { cypress, localStorageKey = 'archived_insurance_payers_v1' } = props

  const tableProps = useDataTable({
    name: ['insurance_payers'],
    endpoint: '/insurance_local_payers',
    params: { status: 'archived' },
    localStorageKey,
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        // disableSort: true,
        formatValue: ({ data }: any) => {
          if (props.renderMainCell) {
            return props.renderMainCell(data)
          }

          return <MainCell id={data.id} value={data.name} avatar={data.avatar} to={`${data.id}`} />
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
      },
      {
        title: 'Network Status',
        model: 'network_status',
        formatValue: ({ value }) => <PayerNetworkStatus status={value} />,
      },
      {
        title: 'Enrollment Status',
        model: 'enrolled_status',
        formatValue: ({ value }) => <PayerEnrollmentStatus status={value} />,
      },
      {
        title: 'States Covered',
        model: 'insurance_clearing_house_config.states_covered',
        formatValue: ({ value }) => {
          if (!value) return '–'
          if (value.length >= 50) return 'National'

          return value?.join(', ') || '–'
        },
      },
      {
        title: 'Clearing House',
        model: 'insurance_clearing_house_config.clearing_house',
        formatValue: ({ value }) => CLEARING_HOUSES[value] || '–',
      },
      {
        title: 'Payer ID',
        model: 'insurance_clearing_house_config.payer_id',
        width: 110,
      },
      {
        title: 'eVOBs',
        model: 'supports.eligibility',
        type: 'boolean',
      },
      {
        title: 'Claims',
        model: 'supports.claims',
        type: 'boolean',
      },
      {
        title: 'Secondary Claims',
        model: 'supports.secondary_claims',
        type: 'boolean',
      },
      {
        title: 'Claim Status',
        model: 'supports.claim_status',
        type: 'boolean',
      },
      {
        title: 'Remittance',
        model: 'supports.remittance',
        type: 'boolean',
      },
      {
        title: 'Main Phone Line',
        type: 'phone',
        model: 'phone_no',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <>
      <div className="grid grid-cols-[100%] flex-grow">
        <DataTable
          {...tableProps}
          cypress={cypress}
          title="Archived Insurance Payers"
          icon="bank_building"
          columns={columns}
          canSelect={false}
          filtersConfig={FILTERS.insurance_local_payers}
          onRowSelect={() => {}}
          onDataInitialized={() => {}}
          updateKey="insurance_payer"
          updateDeleteEndpoint="/insurance_local_payers"
          invalidate={['insurance_payers', { status: 'archived' }]}
          {...props}
        />
      </div>
    </>
  )
}
