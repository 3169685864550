import React from 'react'

import Button from '../Button'
import Card from '../Card'
import Flex from '../Flex'
import Grid from '../Grid'
import Icon from '../Icon'

import { Heading } from '../Typography'
import { COLORS, FEATURES, MEDIA_QUERY } from '../../theme'

type Props = {
  feature: string
  help?: React.ReactNode
  marketingID?: string
  title?: string
  icon?: string
}

const MarketingCard = ({ feature, icon, help, marketingID, title }: Props) => {
  // if (!feature) return null
  // if (!FEATURES[feature]) return null

  const marketingIcon = icon || FEATURES?.[feature]?.icon
  const marketingTitle = title || FEATURES?.[feature]?.title

  return (
    <Grid gap="1.5rem">
      <Card css={styles.root}>
        <Grid css={styles.content} gap=".5rem">
          <Flex centerY css={{ marginTop: '0.6rem' }}>
            <Icon icon={marketingIcon} className="!mr-2" />

            <Heading is="h2" className="!mr-2">
              {marketingTitle}
            </Heading>

            {help}
          </Flex>

          <div css={styles.paragraph}>You must purchase {marketingTitle} to enable this feature.</div>

          {
            <Button
              glyph="behave_health_light"
              label={`Get ${marketingTitle} Today →`}
              type="primary"
              onClick={() => window.Beacon('toggle')}
            />
          }
        </Grid>
      </Card>
    </Grid>
  )
}

const MARKETING_SLUGS: any = {
  agreements: 'agreements',
  calendar: 'calendar',
  client_applications: 'client-applications',
  client_care_team: 'client-care-team',
  client_financials: 'client-financials',
  client_programs: 'client-programs',
  clients_chat: 'clients-chat',
  clinical_assessments: 'clinical-assessments',
  clinical_measurements: 'clinical-measurements',
  clinical_notes: 'clinical-notes',
  staff_chat: 'company-chat',
  company_files: 'company-files',
  company_todos: 'company-todos',
  files: 'files',
  insurance_and_vob: 'insurance-and-vob',
  insurance: 'insurance',
  location_check_in: 'location-check-in',
  medical_assessments: 'medical-assessments',
  medication_list_and_logs: 'medication-list-and-logs',
  nursing: 'nursing',
  onboarding_checklist: 'onboarding-checklist',
  organizations: 'organizations',
  out_of_network_services: 'out-of-network-services',
  payer_benefits: 'payer-benefits',
  payer_files: 'payer-files',
  payers: 'payers',
  physician_orders: 'physician-orders',
  programs: 'programs',
  progress_reviews: 'progress-reviews',
  rcm_reports: 'rcm-reports',
  recovery_coaching: 'recovery-coaching',
  reports: 'reports',
  settings_agreement_templates: 'settings-agreement-templates',
  settings_application_form: 'settings-application-form',
  settings_billing_settings: 'settings-billing-settings',
  settings_payments: 'settings-payments',
  shift_notes: 'shift-notes',
  test_results: 'test-results',
  todos: 'todos',
  treatment_plans: 'treatment-plans',
  services_book: 'settings-services-book',
}

const styles = {
  root: {
    position: 'relative',
    padding: '2rem 0',
    display: 'flex',
    justifyContent: 'center',

    [`${MEDIA_QUERY[2]}`]: {
      padding: '5rem 0',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      height: 4,
      backgroundColor: COLORS.blue,
    },
  },

  content: {
    width: '75%',

    [`${MEDIA_QUERY[2]}`]: {
      width: '50%',
      maxWidth: '25rem',
      minWidth: 390,
    },
  },

  paragraph: {
    fontSize: '1.1rem',
    marginBottom: '.5rem',
  },

  iframeWrapper: {
    alignItems: 'center',
    marginTop: '1rem',
  },

  iframe: {
    width: '100%',
    height: 500,
  },
}

export default MarketingCard
