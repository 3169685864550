import React from 'react'

import { TopBarMessage } from './TopBarMessage'
import Loader from './Loader'
import Flex from './Flex'

import useSettings from '../hooks/useSettings'

const isDev = process.env.NODE_ENV !== 'production'
const POLL_INTERVAL = isDev ? 10 * 1000 : 30 * 1000 // every 30 seconds check if it finished

export const SeedListener = ({ fetchRequest }: any) => {
  const { isPopulatingSeeds, isDeletingSeeds } = useSettings()
  const [shouldPoll, setShouldPoll] = React.useState(false)

  const timer = React.useRef(null)

  React.useEffect(() => {
    if (shouldPoll) {
      timer.current = setTimeout(() => fetchRequest(), POLL_INTERVAL)
    } else if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }

    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [shouldPoll])

  React.useEffect(() => {
    setShouldPoll(isPopulatingSeeds || isDeletingSeeds)
  }, [isPopulatingSeeds, isDeletingSeeds])

  if (isPopulatingSeeds)
    return (
      <TopBarMessage color="green" showClose={false}>
        <Flex centerX gap="1rem">
          <Loader color="green" />
          <span>We are currently populating seed data… Check back in a couple of minutes</span>
        </Flex>
      </TopBarMessage>
    )

  if (isDeletingSeeds)
    return (
      <TopBarMessage color="red" showClose={false}>
        <Flex centerX gap="1rem">
          <Loader color="red" />
          <span>We are currently deleting seed data… Check back in a couple of minutes</span>
        </Flex>
      </TopBarMessage>
    )

  return null
}
