import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useSettings } from '../../hooks/useSettings'

import { FILTERS } from '../Filters/config'
import { titleCase } from '../../utils/functions'

import DataFormStatus from '../../components/Statuses/DataFormStatus'
import DocumentationReviewSignatureStatus from '../../components/Statuses/DocumentationReviewSignatureStatus'
import { RoundStatus } from '../../constructs/Rounds/RoundStatus'

import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import Status from '../../components/Status'

import DocumentationReviewNoteType from '@behavehealth/components/Statuses/DocumentationReviewNoteType'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

const RECORD_TYPES = {
  clinical_note: 'Clinical Note',
  breathalyzer_test: 'Breathalyzer Test',
  communicable_disease: 'Communicable Disease',
  vital_sign: 'Vital Sign',
}

export const DocumentationReviewReportDataTable = (props: any) => {
  const { to } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        disableHide: true,
        width: 300,
      },
      {
        title: 'Note Type',
        id: 'note_type',
        model: 'note_type',
        width: 200,
        formatValue: ({ value }: any) => <DocumentationReviewNoteType status={value} />,
      },
      {
        title: 'Category',
        id: 'category',
        model: 'category',
        width: 330,
        formatValue: ({ value }: any) => <Status color="blue" label={titleCase(value)} />,
      },
      {
        title: 'Subcategory',
        id: 'subcategory',
        model: 'subcategory',
        width: 330,
        formatValue: ({ value }: any) => (value ? <Status color="gray" label={titleCase(value)} /> : null),
      },
      {
        title: 'Status',
        width: 230,
        id: 'status',
        model: 'status',
        formatValue: ({ data, value }: any) => <DataFormStatus status={value} />,
      },
      {
        title: 'Start Date & Time',
        id: 'started_at',
        model: 'started_at',
        type: 'date_time',
        width: 200,
      },
      {
        title: 'End Date & Time',
        id: 'ended_at',
        model: 'ended_at',
        type: 'date_time',
        width: 200,
      },
      {
        title: 'Record Added',
        id: 'created_at',
        model: 'created_at',
        type: 'date_time',
        width: 200,
      },
      {
        title: 'Record Last Updated',
        id: 'updated_at',
        model: 'updated_at',
        type: 'date_time',
        width: 200,
      },
      {
        title: 'Author',
        model: 'author',
        type: 'profile',
        width: 250,
      },
      {
        title: 'Client',
        model: 'client',
        type: 'profile',
        width: 250,
      },
      {
        title: 'Supervisors',
        model: 'supervisors',
        type: 'profile',
        width: 300,
      },
      {
        title: 'Additional Staff',
        model: 'additional_staff',
        type: 'profile',
        width: 300,
      },
      {
        title: 'Guardians / Contacts',
        model: 'guardians',
        type: 'profile',
        width: 300,
      },
      {
        title: 'Author Signature Status',
        model: 'author_signature_status',
        width: 240,
        formatValue: ({ data, value }: any) => (value ? <DocumentationReviewSignatureStatus status={value} /> : null),
      },
      {
        title: 'Author Signature Date & Time',
        id: 'author_signed_at',
        model: 'author_signed_at',
        type: 'date_time',
        width: 300,
      },
      {
        title: 'Client Signature Status',
        model: 'client_signature_status',
        width: 240,
        formatValue: ({ data, value }: any) => (value ? <DocumentationReviewSignatureStatus status={value} /> : null),
      },
      {
        title: 'Client Signature Date & Time',
        id: 'client_signed_at',
        model: 'client_signed_at',
        type: 'date_time',
        width: 300,
      },
      {
        title: 'Supervisors Signature Status',
        model: 'supervisors_signature_status',
        width: 240,
        formatValue: ({ data, value }: any) => (value ? <DocumentationReviewSignatureStatus status={value} /> : null),
      },
      {
        title: 'Supervisors Signature Date & Time',
        id: 'supervisors_signed_at',
        model: 'supervisors_signed_at',
        type: 'date_time',
        width: 300,
      },
      {
        title: 'Additional Staff Signature Status',
        model: 'staff_signature_status',
        width: 240,
        formatValue: ({ data, value }: any) => (value ? <DocumentationReviewSignatureStatus status={value} /> : null),
      },
      {
        title: 'Additional Staff Signature Date & Time',
        id: 'staff_signed_at',
        model: 'staff_signed_at',
        type: 'date_time',
        width: 300,
      },
      {
        title: 'Guardians Signature Status',
        model: 'guardians_signature_status',
        width: 240,
        formatValue: ({ data, value }: any) => (value ? <DocumentationReviewSignatureStatus status={value} /> : null),
      },
      {
        title: 'Guardians Signature Date & Time',
        id: 'guardians_signed_at',
        model: 'guardians_signed_at',
        type: 'date_time',
        width: 300,
      },
      {
        title: 'Event',
        model: 'event_name',
        width: 250,
      },
      {
        title: 'Event Status',
        model: 'event_status',
        width: 250,
        formatValue: ({ data, value }: any) => (value ? <DocumentationReviewSignatureStatus status={value} /> : null),
      },
      {
        title: 'Event Start Date & Time',
        id: 'event_started_at',
        model: 'event_started_at',
        type: 'date_time',
        width: 300,
      },
      {
        title: 'Event End Date & Time',
        id: 'event_ended_at',
        model: 'event_ended_at',
        type: 'date_time',
        width: 300,
      },
      {
        title: 'Round',
        model: 'round_name',
        width: 250,
      },
      {
        title: 'Round Status',
        model: 'round_status',
        width: 250,
        formatValue: ({ value }: any) => (value ? <RoundStatus status={value || ''} /> : null),
      },
      {
        title: 'Round Signature Status',
        model: 'round_signature_status',
        width: 250,
        formatValue: ({ value }: any) => (value ? <DocumentationReviewSignatureStatus status={value} /> : null),
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Documentation Review"
      icon="patient_progress_measure"
      columns={columns}
      filtersConfig={FILTERS.live_reports.documentation_review}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="documentation_review"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=documentation_review'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'documentation_review' }} />
        </>
      }
      {...props}
    />
  )
}
