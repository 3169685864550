import React from 'react'

import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Checkbox from '../../../components/Forms/Checkbox'
import FormSection from '../../../components/Forms/FormSection'
import OverlaySelector from '../../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../../components/Permission'

import { DiagnosesSelector } from '../../Diagnoses/DiagnosesSelector'

import { InlineInsuranceBillingFormSection } from './InlineInsuranceBillingFormSection'
import ClaimPlaceOfServiceSelect from './ClaimPlaceOfServiceSelect'

export const InsuranceBillingFormSection = ({ initialModel, record, isNew, feeScheduleModel, model, showPlaceOfService = false }: any) => {
  const [isBillable, setIsBillable] = React.useState(initialModel?.is_billable || false)

  return (
    <>
      <Permission featureFlagV2="diagnoses">
        <DiagnosesSelector clientId={record?.id} />
      </Permission>

      <CheckboxGroup label="Insurance" trueIcon="check" falseIcon="cross" falseStyle="faded">
        <Checkbox defaultValue={false} label="Set as Billable" model="is_billable" onUpdate={(state: any) => setIsBillable(state.value)} />
      </CheckboxGroup>

      {isBillable && (
        <>
          <InlineInsuranceBillingFormSection
            isBillable
            isNew={isNew}
            feeScheduleModel={feeScheduleModel}
            model={model}
            initialModel={initialModel}
          />

          {showPlaceOfService && (
            <FormSection maxWidth="100%">
              <ClaimPlaceOfServiceSelect model="place_of_service" />

              <OverlaySelector maxWidth="100%" label="Location" type="properties.all" model="location" icon="gps_check_in_out" />
            </FormSection>
          )}
        </>
      )}
    </>
  )
}
