import React from 'react'

import { titleCase } from '../../utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

const SOURCE: any = {
  internal: 'Current Facility',
  erx: 'eRX',
}

export const AllergiesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 240,
        id: 'reference',
        model: 'reference.name',
        title: 'Reference',
        type: 'profile',
      },
      {
        width: 130,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <MainCell value={titleCase(value)} />,
      },
      {
        width: 130,
        id: 'category',
        model: 'category',
        title: 'Category',
        formatValue: ({ value }: any) => <MainCell value={titleCase(value)} />,
      },
      {
        width: 130,
        id: 'criticality',
        model: 'criticality',
        title: 'Risk',
      },
      {
        width: 130,
        id: 'severity',
        model: 'severity',
        title: 'Severity',
      },
      {
        width: 130,
        id: 'source',
        model: 'source',
        title: 'Source',
        formatValue: ({ value }: any) => <MainCell value={SOURCE[value]} />,
      },
      {
        width: 130,
        id: 'reaction_type',
        model: 'reaction_type',
        title: 'Reaction Type',
        formatValue: ({ value }: any) => <MainCell value={titleCase(value)} />,
      },
      {
        width: 130,
        id: 'started_on',
        model: 'started_on',
        title: 'Onset On',
        type: 'date',
      },
      {
        width: 130,
        id: 'ended_on',
        model: 'ended_on',
        title: 'Resolved On',
        type: 'date',
      },
      {
        width: 140,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Reported Conditions"
      icon="reported_conditions"
      columns={columns}
      filtersConfig={FILTERS.live_reports.allergies}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="allergies"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=allergies'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'allergies' }} />
        </>
      }
      {...props}
    />
  )
}
