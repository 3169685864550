import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import LevelOfCareStatus from '../../components/Statuses/LevelOfCareStatus'
import { titleCase } from '../../utils/functions'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

import ServiceEpisodeStatus from '../../components/Statuses/ServiceEpisodeStatus'
import ServiceEpisodeTypeStatus from '../../components/Statuses/ServiceEpisodeTypeStatus'

import { SERVICE_EPISODE_CATEGORIES, SERVICE_EPISODE_LOC_CATEGORIES, SERVICE_EPISODE_LOC_SUBCATEGORIES } from '../../utils/constants'

export const ServiceEpisodesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        id: 'client',
        model: 'client.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.client?.name} avatar={data.client.avatar} to={to?.(data?.client)} />
        ),
      },
      {
        title: 'Name',
        id: 'name',
        model: 'name',
      },
      {
        title: 'Status',
        id: 'status',
        model: 'status',
        formatValue: ({ value }: any) => <ServiceEpisodeStatus status={value} />,
      },
      {
        title: 'Episode Type',
        id: 'category',
        model: 'category',
        formatValue: ({ value }: any) => <ServiceEpisodeTypeStatus status={value} />,
      },
      {
        title: 'Category',
        id: 'loc_category',
        model: 'loc_category',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return SERVICE_EPISODE_LOC_CATEGORIES[value]
        },
      },
      {
        title: 'Subcategory',
        id: 'loc_subcategory',
        model: 'loc_subcategory',
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          return SERVICE_EPISODE_LOC_SUBCATEGORIES[data.loc_category][value]
        },
      },
      {
        title: 'Episode From',
        id: 'started_at',
        model: 'started_at',
        type: 'date_time',
      },
      {
        title: 'Episode Until',
        id: 'ended_at',
        model: 'ended_at',
        type: 'date_time',
      },
      {
        title: 'Created at',
        id: 'created_at',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Updated at',
        id: 'updated_at',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        id: 'author',
        model: 'author',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Service Episodes"
      icon="care_level_1"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="service_episodes"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=service_episodes'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'service_episodes' }} />
        </>
      }
      {...props}
    />
  )
}

const ASAM_OPTIONS = Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['asam_substance_use_disorder_treatment']).map(([key, value]) => ({
  label: value,
  value: key,
}))

const OLD_ASAM_OPTIONS = Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['asam_substance_use_disorder_treatment_old']).map(
  ([key, value]) => ({
    label: value,
    value: key,
  }),
)

const MENTAL_HEALTH_OPTIONS = Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['mental_health']).map(([key, value]) => ({
  label: value,
  value: key,
}))

const NARR_LEVEL_OPTIONS = Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['narr_levels_of_support']).map(([key, value]) => ({
  label: value,
  value: key,
}))

const FILTERS_CONFIG = {
  client: {
    type: 'multi_object',
    label: 'Client',
    endpoint: '/residents',
    apiKey: 'residents',
    glyph: 'user_group',
    showAvatar: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.position),
  },
  author: {
    type: 'multi_object',
    label: 'Added By',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.position),
  },
  name: {
    label: 'Name',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
  category: {
    label: 'Type',
    type: 'multi_select',
    options: Object.keys(SERVICE_EPISODE_CATEGORIES).map((key) => ({ label: SERVICE_EPISODE_CATEGORIES[key], value: key })),
  },
  loc_category: {
    label: 'Category',
    type: 'multi_select',
    options: Object.keys(SERVICE_EPISODE_LOC_CATEGORIES).map((key) => ({ label: SERVICE_EPISODE_LOC_CATEGORIES[key], value: key })),
  },
  loc_subcategory: {
    label: 'Subcategory',
    type: 'multi_select',
    options: [
      { groupTitle: 'ASAM Substance Use Disorder Treatment' },
      ...ASAM_OPTIONS,

      { groupTitle: 'Old Substance Use Disorder Treatment' },
      ...OLD_ASAM_OPTIONS,

      { groupTitle: 'Mental Health' },
      ...MENTAL_HEALTH_OPTIONS,

      { groupTitle: 'NARR Levels of Support' },
      ...NARR_LEVEL_OPTIONS,
    ],
  },
  started_at: {
    label: 'Episode From',
    type: 'date_time',
  },
  ended_at: {
    label: 'Episode Until',
    type: 'date_time',
  },
  created_at: {
    label: 'Created At',
    type: 'date_time',
  },
  updated_at: {
    label: 'Updated At',
    type: 'date_time',
  },
}
