import React from 'react'
import { Link } from 'react-router-dom-v5-compat'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { useCreate } from '../../hooks/useNewAPI'

import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Alert from '../../components/Alert'
import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Nav from '../../components/Nav'
import NumberInput from '../../components/Forms/NumberInput'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import PageGrid from '../../components/PageGrid'
import PercentageInput from '../../components/Forms/PercentageInput'
import PhoneInput from '../../components/Forms/PhoneInput'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import ScrollMenu from '../../components/ScrollMenu'
import ScrollView from '../../components/ScrollView'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Slider from '../../components/Forms/Slider'
import State from '../../components/State'
import Textarea from '../../components/Forms/Textarea'
import URLInput from '../../components/Forms/URLInput'

import { DIRECTORIES_DATA } from '../AuthorityDirectories/data'
import { DIRECTORY_SCHEMA } from '../AuthorityDirectories/schema'
import { LocationTestimonials } from './LocationTestimonials'
import { AttachmentsInput } from '../Authority/components/AttachmentsInput'

import aarr_badge from '../../assets/badges/aarr_badge.png'
import gsarr_badge from '../../assets/badges/gsarr_badge.png'
import krhn_badge from '../../assets/badges/krhn_badge.png'
import marr_badge_me from '../../assets/badges/marr_badge_me.png'
import marr_badge_mi from '../../assets/badges/marr_badge_mi.png'
import narr_badge from '../../assets/badges/narr_badge.png'
import rram_badge from '../../assets/badges/rram_badge.png'
import varr_badge from '../../assets/badges/varr_badge.png'
import wvarr_badge from '../../assets/badges/wvarr_badge.png'

export const AuthorityOrganizationLocationOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'organization_directory_locations',
    endpoint: '/organization_directory_locations',
    invalidate: 'organization_directory_locations',
    options: props,
    disableParentRequest: true,
    onSaveSuccessful: props.onSaveSuccessful,
    onDeleteSuccessful: props.onDeleteSuccessful,
  })

  const { referenceID, referenceType } = props

  const { timezone } = useSettings()

  const [isGeneratingMap, setIsGeneratingMap] = React.useState(false)

  const generateMap = useCreate({
    name: ['organization_directory_locations', 'generate_map'],
    url: `/organization_directory_locations/${id}/generate_location_map`,
    invalidate: 'organization_directory_locations',
  })

  const handleGenerateMap = async () => {
    setIsGeneratingMap(true)

    try {
      await generateMap.mutateAsync({})
    } catch (error) {
      console.error(error)
    } finally {
      setIsGeneratingMap(false)
    }
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={70} />
  }

  return (
    <Overlay showBackdrop={isEditable} onClose={close} maxWidth={70}>
      <Overlay.Header title="Location" icon="locations" />

      <ScrollView>
        <PageGrid scroll breakpoint={3} className="overlay-page-grid flex-[1_1_auto]">
          <Nav
            top="0"
            breakpoint={3}
            title={' '}
            headingSize={300}
            desktopProps={{ title: null }}
            className="!bg-white shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!shadow-none [&_header]:!shadow-transparent [&_header]:!border-transparent"
          >
            <div className="opacity-80 text-[0.9rem] uppercase font-[700] tracking-[1px] mb-1">Table of Contents</div>
            <ScrollMenu />
          </Nav>

          <Overlay.Content>
            <Form
              getForm={form}
              timezone={timezone}
              initialModel={{ ...initialModel, ...(isNew && { location_radius: 5 }) }}
              isEditable={isEditable}
              onValidationUpdate={onValidationUpdate}
              linked={isNew && { organization_id: referenceID }}
            >
              <Section
                title="Gallery"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_gallery.icon}
                scrollview={{
                  id: 'gallery',
                  name: 'Gallery',
                  icon: DIRECTORY_SCHEMA.location_gallery.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <AttachmentsInput canUpload={isEditable} label="Gallery" model="gallery" labelAlign="top" labelJustify="top" />
                </FormSection>
              </Section>

              {!isEditable && (
                <>
                  <Divider />

                  <Section
                    title="Location Map"
                    headingType="h2"
                    icon="clients_journey"
                    scrollview={{
                      id: 'location_map',
                      name: 'Location Map',
                      icon: 'clients_journey',
                    }}
                    aside={<Button label="Generate Map" glyph="reset" size={200} isLoading={isGeneratingMap} onClick={handleGenerateMap} />}
                  >
                    <Card>
                      {data?.location_map_url ? (
                        <img className="block w-full h-auto" />
                      ) : (
                        <State isEmpty title="Location Map" icon="clients_journey" emptyDescription="No map has been generated yet" />
                      )}
                    </Card>
                  </Section>
                </>
              )}

              <Divider />

              <Section
                title="Location Details"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_details.icon}
                scrollview={{
                  id: 'location_details',
                  name: 'Location Details',
                  icon: DIRECTORY_SCHEMA.location_details.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <Input
                    label="Name"
                    model="name"
                    validations={{
                      presence: {
                        message: 'Please add a name',
                      },
                    }}
                  />

                  <CheckboxGroup withToggle trueIcon="check" falseIcon="cross" falseStyle="none">
                    <Checkbox
                      label="Share this Location in Directories"
                      description="Allow the location to be shared in directories"
                      model="is_shared_with_directories"
                    />
                  </CheckboxGroup>

                  <ContextShow when="is_shared_with_directories" is={true}>
                    <CheckboxGroup withToggle trueIcon="check" falseIcon="cross" falseStyle="none">
                      <Checkbox
                        label="Share this Location's Beds Availability in Directories"
                        description="Allow the beds availability data to be shared in directories"
                        model="is_beds_availability_shared"
                      />
                    </CheckboxGroup>

                    <CheckboxGroup
                      label="Certified Badge"
                      trueIcon="check"
                      falseIcon="empty_checkbox"
                      falseStyle="none"
                      layout="horizontal"
                    >
                      <Checkbox label="NARR" model="certified_badges.narr" />
                      <Checkbox label="AARR" model="certified_badges.aarr_badge" />
                      <Checkbox label="GSARR" model="certified_badges.gsarr_badge" />
                      <Checkbox label="KRHN" model="certified_badges.krhn_badge" />
                      <Checkbox label="MARR (ME)" model="certified_badges.marr_badge_me" />
                      <Checkbox label="MARR (MI)" model="certified_badges.marr_badge_mi" />
                      <Checkbox label="RRAM" model="certified_badges.rram_badge" />
                      <Checkbox label="VARR" model="certified_badges.varr" />
                      <Checkbox label="WVARR" model="certified_badges.wvarr" />
                    </CheckboxGroup>

                    <Flex gap="1rem">
                      <ContextShow when="certified_badges.narr" is={true}>
                        <img src={narr_badge} className="block w-32 h-32" />
                      </ContextShow>

                      <ContextShow when="certified_badges.varr" is={true}>
                        <img src={varr_badge} className="block w-32 h-32" />
                      </ContextShow>

                      <ContextShow when="certified_badges.wvarr" is={true}>
                        <img src={wvarr_badge} className="block w-32 h-32" />
                      </ContextShow>

                      <ContextShow when="certified_badges.aarr_badge" is={true}>
                        <img src={aarr_badge} className="block w-32 h-32" />
                      </ContextShow>

                      <ContextShow when="certified_badges.gsarr_badge" is={true}>
                        <img src={gsarr_badge} className="block w-32 h-32" />
                      </ContextShow>

                      <ContextShow when="certified_badges.krhn_badge" is={true}>
                        <img src={krhn_badge} className="block w-32 h-32" />
                      </ContextShow>

                      <ContextShow when="certified_badges.marr_badge_me" is={true}>
                        <img src={marr_badge_me} className="block w-32 h-32" />
                      </ContextShow>

                      <ContextShow when="certified_badges.marr_badge_mi" is={true}>
                        <img src={marr_badge_mi} className="block w-32 h-32" />
                      </ContextShow>

                      <ContextShow when="certified_badges.rram_badge" is={true}>
                        <img src={rram_badge} className="block w-32 h-32" />
                      </ContextShow>
                    </Flex>
                  </ContextShow>

                  <Alert small contrast icon="directories">
                    <b>Note:</b> go to{' '}
                    <Link to="/settings/directories" target="_blank">
                      Settings → Directories
                    </Link>{' '}
                    to manage your directories and the data points that are shared.
                  </Alert>

                  <MultiObjectSelector
                    label="Linked Directory Programs"
                    model="organization_directory_programs"
                    type="organization.organization_directory_programs"
                    icon="programs"
                    dependentValue={referenceID}
                    selectTitle={(data: any) => data.name}
                  />

                  <OverlaySelector
                    label="Linked EHR Location"
                    model="property"
                    type="properties.all"
                    icon={'locations'}
                    selectTitle={(data: any) => data.name}
                    description="Link an EHR location to get the latest bed availability data"
                  />

                  <Input label="Hours of Operation" model="hours_of_operation" />

                  <AddressSelectorInput label="Location Address" actionLabel="Select Address" model="address" />

                  <RadioGroup label="Location Precision" model="location_precision" layout="horizontal" defaultValue="approximate">
                    <Radio label="Approximate within radius" value="approximate" />
                    <Radio label="Exact location" value="exact" />
                  </RadioGroup>

                  <ContextShow when="location_precision" is="approximate">
                    <Slider withInputs label="Approximate Location Radius (miles)" model="location_radius" step={1} min={3} max={10} />
                  </ContextShow>

                  <ContextShow when="location_precision" is="exact">
                    <Alert contrast type="negative" glyph="warning">
                      <b>Please note: </b> when the location is published in a Directory and precision is set to "Exact location", the
                      address will be visible to the public.
                    </Alert>
                  </ContextShow>

                  <EmailInput label="Email" model="email" />
                  <PhoneInput label="Phone No" model="phone_no" />

                  <Input
                    label="Admissions Contact Name"
                    model="admissions_contact_name"
                    tooltip="The name of the person handling admissions"
                  />
                  <PhoneInput
                    label="Admissions Contact Phone"
                    model="admissions_contact_phone_no"
                    tooltip="The direct phone number for admissions"
                  />
                  <EmailInput
                    label="Admissions Contact Email"
                    model="admissions_contact_email"
                    tooltip="The email address for admissions inquiries"
                  />

                  <CheckboxGroup
                    withToggle
                    label="Languages Served"
                    tooltip={DIRECTORIES_DATA.languages_served.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                    model="languages_served"
                  >
                    {Object.entries(DIRECTORIES_DATA.languages_served.options).map(([model, label]) => (
                      <Checkbox key={model} model={`languages_served.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  {!isNew && (
                    <DateInput
                      isEditable={false}
                      label="Last Updated"
                      value={data?.updated_at}
                      tooltip={DIRECTORIES_DATA.updated_at.tooltip}
                    />
                  )}
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Financials"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_financials.icon}
                scrollview={{
                  id: 'financials',
                  name: 'Financials',
                  icon: DIRECTORY_SCHEMA.location_financials.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <CheckboxGroup
                    withToggle
                    label="Payment Schedule"
                    tooltip={DIRECTORIES_DATA.payment_schedule.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.payment_schedule.options).map(([model, label]) => (
                      <Checkbox key={model} model={`payment_schedule.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Payment Methods"
                    model="payment_methods"
                    tooltip={DIRECTORIES_DATA.payment_methods.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.payment_methods.options).map(([model, label]) => (
                      <Checkbox key={model} model={`payment_methods.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Accepted Insurance"
                    tooltip={DIRECTORIES_DATA.accepted_insurance.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.accepted_insurance.options).map(([model, label]) => (
                      <Checkbox key={model} model={`accepted_insurance.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <AmountInput label="Deposit Amount" model="deposit_amount" size={6} />
                  <Input label="Deposit Due Date" model="deposit_due_date" />
                  <Textarea label="Additional Fees" model="additional_fees" tooltip={DIRECTORIES_DATA.additional_fees.tooltip} />
                  <AmountInput label="Total Move-in Cost" model="total_move_in_cost" size={6} />
                  <AmountInput label="Monthly Cost Estimate" model="monthly_cost_estimate" size={6} />

                  <CheckboxGroup
                    withToggle
                    label="Financial Assistance Types"
                    tooltip={DIRECTORIES_DATA.financial_assistance_types.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.financial_assistance_types.options).map(([model, label]) => (
                      <Checkbox key={model} model={`financial_assistance_types.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <Textarea
                    label="Financial Assistance Details"
                    model="financial_assistance_details"
                    tooltip={DIRECTORIES_DATA.financial_assistance_details.tooltip}
                  />
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Certifications"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_certifications.icon}
                scrollview={{
                  id: 'certifications',
                  name: 'Certifications',
                  icon: DIRECTORY_SCHEMA.location_certifications.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <RadioGroup
                    label="NARR Level of Support"
                    tooltip={DIRECTORIES_DATA.narr_level_of_support.tooltip}
                    layout="vertical-dense"
                    model="narr_level_of_support"
                  >
                    {Object.entries(DIRECTORIES_DATA.narr_level_of_support.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <CheckboxGroup
                    withToggle
                    label="ASAM Level of Care"
                    tooltip={DIRECTORIES_DATA.asam_level_of_care.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.asam_level_of_care.options).map(([model, label]) => (
                      <Checkbox key={model} model={`asam_level_of_care.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="New ASAM/NARR Levels"
                    tooltip={DIRECTORIES_DATA.new_asam_narr_levels.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.new_asam_narr_levels.options).map(([model, label]) => (
                      <Checkbox key={model} model={`new_asam_narr_levels.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="LOCUS Level of Care"
                    tooltip={DIRECTORIES_DATA.locus_level_of_care.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.locus_level_of_care.options).map(([model, label]) => (
                      <Checkbox key={model} model={`locus_level_of_care.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="CALOCUS Level of Care"
                    tooltip={DIRECTORIES_DATA.calocus_level_of_care.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.calocus_level_of_care.options).map(([model, label]) => (
                      <Checkbox key={model} model={`calocus_level_of_care.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <Input
                    label="State Affiliate Certification"
                    model="state_affiliate_certification"
                    tooltip="Specifies the facility's certification status with the relevant state affiliate."
                  />

                  <RadioGroup
                    label="Certification Status"
                    tooltip={DIRECTORIES_DATA.certification_status.tooltip}
                    layout="vertical-dense"
                    model="certification_status"
                  >
                    {Object.entries(DIRECTORIES_DATA.certification_status.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <CheckboxGroup
                    withToggle
                    label="Licenses, Accreditations, & Certifications"
                    tooltip={DIRECTORIES_DATA.licenses_accreditations_certifications.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.licenses_accreditations_certifications.options).map(([model, label]) => (
                      <Checkbox key={model} model={`licenses_accreditations_certifications.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Demographics"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_demographics.icon}
                scrollview={{
                  id: 'demographics',
                  name: 'Demographics',
                  icon: DIRECTORY_SCHEMA.location_demographics.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <CheckboxGroup
                    withToggle
                    label="Age Groups"
                    tooltip={DIRECTORIES_DATA.age_groups.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.age_groups.options).map(([model, label]) => (
                      <Checkbox key={model} model={`age_groups.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Cultural/Ethnic"
                    tooltip={DIRECTORIES_DATA.cultural_ethnic.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.cultural_ethnic.options).map(([model, label]) => (
                      <Checkbox key={model} model={`cultural_ethnic.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Family Status"
                    tooltip={DIRECTORIES_DATA.family_status.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.family_status.options).map(([model, label]) => (
                      <Checkbox key={model} model={`family_status.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Accepted Gender/Identities"
                    tooltip={DIRECTORIES_DATA.gender_identity.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.gender_identity.options).map(([model, label]) => (
                      <Checkbox key={model} model={`gender_identity.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Legal Status"
                    tooltip={DIRECTORIES_DATA.legal_status.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.legal_status.options).map(([model, label]) => (
                      <Checkbox key={model} model={`legal_status.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Special Needs & Medical"
                    tooltip={DIRECTORIES_DATA.special_needs_medical.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.special_needs_medical.options).map(([model, label]) => (
                      <Checkbox key={model} model={`special_needs_medical.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Other Special Populations"
                    tooltip={DIRECTORIES_DATA.other_special_populations.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.other_special_populations.options).map(([model, label]) => (
                      <Checkbox key={model} model={`other_special_populations.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Professional Status"
                    tooltip={DIRECTORIES_DATA.professional_status.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.professional_status.options).map(([model, label]) => (
                      <Checkbox key={model} model={`professional_status.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Facility Details"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_facility_details.icon}
                scrollview={{
                  id: 'facility_details',
                  name: 'Facility Details',
                  icon: DIRECTORY_SCHEMA.location_facility_details.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <NumberInput label="Total Beds" model="total_beds" size={6} />
                  <NumberInput label="Total Bathrooms" model="total_bathrooms" size={6} />

                  <RadioGroup
                    label="Occupancy Status"
                    tooltip={DIRECTORIES_DATA.occupancy_status.tooltip}
                    layout="horizontal"
                    model="occupancy_status"
                  >
                    {Object.entries(DIRECTORIES_DATA.occupancy_status.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <CheckboxGroup withToggle label="Waitlist">
                    <Checkbox label="Waitlist Available" model="waitlist_available" />
                  </CheckboxGroup>

                  <Input label="Estimated Wait Time" model="estimated_wait_time" />

                  <CheckboxGroup
                    withToggle
                    label="Accessibility Features"
                    tooltip={DIRECTORIES_DATA.accessibility_features.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.accessibility_features.options).map(([model, label]) => (
                      <Checkbox key={model} model={`accessibility_features.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Security Measures"
                    tooltip={DIRECTORIES_DATA.security_measures.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.security_measures.options).map(([model, label]) => (
                      <Checkbox key={model} model={`security_measures.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <RadioGroup
                    label="Building Type"
                    model="building_type"
                    tooltip={DIRECTORIES_DATA.building_type.tooltip}
                    layout="vertical-dense"
                  >
                    {Object.entries(DIRECTORIES_DATA.building_type.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <CheckboxGroup
                    withToggle
                    label="Recreation & Wellness Facilities"
                    tooltip={DIRECTORIES_DATA.recreation_wellness_facilities.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.recreation_wellness_facilities.options).map(([model, label]) => (
                      <Checkbox key={model} model={`recreation_wellness_facilities.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Creative & Entertainment Spaces"
                    model="creative_entertainment_spaces"
                    tooltip={DIRECTORIES_DATA.creative_entertainment_spaces.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.creative_entertainment_spaces.options).map(([model, label]) => (
                      <Checkbox key={model} model={`creative_entertainment_spaces.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Living Accommodations & Essentials"
                    tooltip={DIRECTORIES_DATA.living_accommodations_essentials.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.living_accommodations_essentials.options).map(([model, label]) => (
                      <Checkbox key={model} model={`living_accommodations_essentials.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Family & Child-Friendly Amenities"
                    model="family_child_friendly_amenities"
                    tooltip={DIRECTORIES_DATA.family_child_friendly_amenities.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.family_child_friendly_amenities.options).map(([model, label]) => (
                      <Checkbox key={model} model={`family_child_friendly_amenities.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Technology & Connectivity"
                    tooltip={DIRECTORIES_DATA.technology_connectivity.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.technology_connectivity.options).map(([model, label]) => (
                      <Checkbox key={model} model={`technology_connectivity.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Health, Safety & Support Services"
                    model="health_safety_support_services"
                    tooltip={DIRECTORIES_DATA.health_safety_support_services.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.health_safety_support_services.options).map(([model, label]) => (
                      <Checkbox key={model} model={`health_safety_support_services.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Community & Gathering Spaces"
                    tooltip={DIRECTORIES_DATA.community_gathering_spaces.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.community_gathering_spaces.options).map(([model, label]) => (
                      <Checkbox key={model} model={`community_gathering_spaces.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <NumberInput label="Year Built" model="year_built" size={6} />
                  <DateInput label="Last Renovation Date" model="last_renovation_date" />
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Policies"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_policies.icon}
                scrollview={{
                  id: 'policies',
                  name: 'Policies',
                  icon: DIRECTORY_SCHEMA.location_policies.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <RadioGroup
                    label="Tobacco Use Policy"
                    model="tobacco_use_policy"
                    tooltip={DIRECTORIES_DATA.tobacco_use_policy.tooltip}
                    layout="vertical-dense"
                  >
                    {Object.entries(DIRECTORIES_DATA.tobacco_use_policy.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <RadioGroup
                    label="Marijuana Use Policy"
                    model="marijuana_use_policy"
                    tooltip={DIRECTORIES_DATA.marijuana_use_policy.tooltip}
                    layout="vertical-dense"
                  >
                    {Object.entries(DIRECTORIES_DATA.marijuana_use_policy.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <Input
                    label="Minimum Abstinence Requirement"
                    model="minimum_abstinence_requirement"
                    tooltip={DIRECTORIES_DATA.minimum_abstinence_requirement.tooltip}
                  />
                  <RadioGroup
                    label="MAT Policy Type"
                    model="mat_policy_type"
                    tooltip={DIRECTORIES_DATA.mat_policy_type.tooltip}
                    layout="vertical-dense"
                  >
                    {Object.entries(DIRECTORIES_DATA.mat_policy_type.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <CheckboxGroup
                    withToggle
                    label="Opioid Treatment Medications"
                    tooltip={DIRECTORIES_DATA.opioid_treatment_medications.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.opioid_treatment_medications.options).map(([model, label]) => (
                      <Checkbox key={model} model={`opioid_treatment_medications.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Alcohol Treatment Medications"
                    tooltip={DIRECTORIES_DATA.alcohol_treatment_medications.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.alcohol_treatment_medications.options).map(([model, label]) => (
                      <Checkbox key={model} model={`alcohol_treatment_medications.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="House Rules"
                    model="house_rules"
                    tooltip={DIRECTORIES_DATA.house_rules.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.house_rules.options).map(([model, label]) => (
                      <Checkbox key={model} model={`house_rules.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <Textarea
                    label="Employment Requirements"
                    model="employment_requirements"
                    tooltip={DIRECTORIES_DATA.employment_requirements.tooltip}
                  />
                  <Textarea
                    label="Technology Use Policy"
                    model="technology_use_policy"
                    tooltip={DIRECTORIES_DATA.technology_use_policy.tooltip}
                  />
                  <RadioGroup
                    label="Visitor Policy"
                    model="visitor_policy"
                    tooltip={DIRECTORIES_DATA.visitor_policy.tooltip}
                    layout="vertical-dense"
                  >
                    {Object.entries(DIRECTORIES_DATA.visitor_policy.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <RadioGroup
                    label="Overnight Guest Policy"
                    layout="vertical-dense"
                    model="overnight_guest_policy"
                    tooltip={DIRECTORIES_DATA.overnight_guest_policy.tooltip}
                  >
                    {Object.entries(DIRECTORIES_DATA.overnight_guest_policy.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <RadioGroup
                    label="Vehicle Policy"
                    model="vehicle_policy"
                    tooltip={DIRECTORIES_DATA.vehicle_policy.tooltip}
                    layout="vertical-dense"
                  >
                    {Object.entries(DIRECTORIES_DATA.vehicle_policy.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <CheckboxGroup
                    withToggle
                    label="Criminal Background Check Required"
                    tooltip={DIRECTORIES_DATA.criminal_background_check_required.tooltip}
                  >
                    <Checkbox label="Criminal Background Check Required" model="criminal_background_check_required" />
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Criminal History Policy"
                    tooltip={DIRECTORIES_DATA.criminal_history_policy.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.criminal_history_policy.options).map(([model, label]) => (
                      <Checkbox key={model} model={`criminal_history_policy.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <RadioGroup
                    label="Sex Offender Policy"
                    model="sex_offender_policy"
                    tooltip={DIRECTORIES_DATA.sex_offender_policy.tooltip}
                    layout="vertical-dense"
                  >
                    {Object.entries(DIRECTORIES_DATA.sex_offender_policy.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <Textarea
                    label="Sex Offender Conditions"
                    model="sex_offender_conditions"
                    tooltip={DIRECTORIES_DATA.sex_offender_conditions.tooltip}
                  />
                  <RadioGroup
                    label="Violent Offender Policy"
                    model="violent_offender_policy"
                    tooltip={DIRECTORIES_DATA.violent_offender_policy.tooltip}
                    layout="vertical-dense"
                  >
                    {Object.entries(DIRECTORIES_DATA.violent_offender_policy.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>

                  <Textarea
                    label="Violent Offender Conditions"
                    model="violent_offender_conditions"
                    tooltip={DIRECTORIES_DATA.violent_offender_conditions.tooltip}
                  />
                  <Textarea
                    label="COVID-19 Precautions"
                    model="covid_19_precautions"
                    tooltip={DIRECTORIES_DATA.covid_19_precautions.tooltip}
                  />
                  <Textarea
                    label="Medication Storage Policy"
                    model="medication_storage_policy"
                    tooltip={DIRECTORIES_DATA.medication_storage_policy.tooltip}
                  />
                  <Textarea label="Contraband Policy" model="contraband_policy" tooltip={DIRECTORIES_DATA.contraband_policy.tooltip} />
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Treatment"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_treatment.icon}
                scrollview={{
                  id: 'treatment',
                  name: 'Treatment',
                  icon: DIRECTORY_SCHEMA.location_treatment.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <CheckboxGroup
                    withToggle
                    label="On-site Services"
                    tooltip={DIRECTORIES_DATA.on_site_services.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.on_site_services.options).map(([model, label]) => (
                      <Checkbox key={model} model={`on_site_services.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Off-site Services"
                    tooltip={DIRECTORIES_DATA.off_site_services.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.off_site_services.options).map(([model, label]) => (
                      <Checkbox key={model} model={`off_site_services.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Referral Network"
                    tooltip={DIRECTORIES_DATA.referral_network.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.referral_network.options).map(([model, label]) => (
                      <Checkbox key={model} model={`referral_network.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Support Programs"
                    tooltip={DIRECTORIES_DATA.support_programs.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.support_programs.options).map(([model, label]) => (
                      <Checkbox key={model} model={`support_programs.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Transportation Options"
                    tooltip={DIRECTORIES_DATA.transportation_options.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.transportation_options.options).map(([model, label]) => (
                      <Checkbox key={model} model={`transportation_options.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Educational Programs"
                    tooltip={DIRECTORIES_DATA.educational_programs.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.educational_programs.options).map(([model, label]) => (
                      <Checkbox key={model} model={`educational_programs.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Service Format"
                    tooltip={DIRECTORIES_DATA.service_format.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.service_format.options).map(([model, label]) => (
                      <Checkbox key={model} model={`service_format.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Clinical Staff Types"
                    tooltip={DIRECTORIES_DATA.clinical_staff_types.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.clinical_staff_types.options).map(([model, label]) => (
                      <Checkbox key={model} model={`clinical_staff_types.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <Textarea label="Staff Credentials" model="staff_credentials" tooltip={DIRECTORIES_DATA.staff_credentials.tooltip} />

                  <CheckboxGroup
                    withToggle
                    label="Cultural Competency Training"
                    tooltip={DIRECTORIES_DATA.cultural_competency_training.tooltip}
                  >
                    <Checkbox label="Cultural Competency Training" model="cultural_competency_training" />
                  </CheckboxGroup>

                  <Textarea
                    label="Staff Specializations"
                    model="staff_specializations"
                    tooltip={DIRECTORIES_DATA.staff_specializations.tooltip}
                  />
                  <CheckboxGroup
                    withToggle
                    label="Substances Treated"
                    tooltip={DIRECTORIES_DATA.substances_treated.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.substances_treated.options).map(([model, label]) => (
                      <Checkbox key={model} model={`substances_treated.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <Input label="Recommended Stay Length" model="recommended_stay_length" />
                  <Input label="Maximum Stay Length" model="maximum_stay_length" />
                  <Input label="Average Length of Stay" model="average_length_of_stay" />

                  <CheckboxGroup
                    withToggle
                    label="Medical Conditions Accepted"
                    tooltip={DIRECTORIES_DATA.medical_conditions_accepted.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.medical_conditions_accepted.options).map(([model, label]) => (
                      <Checkbox key={model} model={`medical_conditions_accepted.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Success Metrics"
                    tooltip={DIRECTORIES_DATA.success_metrics.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.success_metrics.options).map(([model, label]) => (
                      <Checkbox key={model} model={`success_metrics.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Transitional Services"
                    tooltip={DIRECTORIES_DATA.transitional_services.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.transitional_services.options).map(([model, label]) => (
                      <Checkbox key={model} model={`transitional_services.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <PercentageInput label="Success Rate" model="success_rate" />
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Marketing"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_marketing.icon}
                scrollview={{
                  id: 'marketing',
                  name: 'Marketing',
                  icon: DIRECTORY_SCHEMA.location_marketing.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <AttachmentsInput
                    canUpload={isEditable}
                    label="Facility Brochure"
                    model="facility_brochures"
                    labelAlign="top"
                    labelJustify="top"
                  />

                  <AttachmentsInput canUpload={isEditable} label="FAQ Document" model="faq_documents" labelAlign="top" labelJustify="top" />

                  <URLInput label="Virtual Tour" model="virtual_tour" tooltip={DIRECTORIES_DATA.virtual_tour.tooltip} />

                  {/* TODO: @Andrei - to update to array */}
                  {/* <Textarea label="Testimonials" model="testimonials" tooltip={DIRECTORIES_DATA.testimonials.tooltip} /> */}
                  <URLInput
                    label="Reviews Integration"
                    model="reviews_integration"
                    tooltip={DIRECTORIES_DATA.reviews_integration.tooltip}
                  />
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Treatment Details"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_treatment_details.icon}
                scrollview={{
                  id: 'treatment_details',
                  name: 'Treatment Details',
                  icon: DIRECTORY_SCHEMA.location_treatment_details.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <Textarea
                    label="Abstinence Definition"
                    model="abstinence_definition"
                    tooltip={DIRECTORIES_DATA.abstinence_definition.tooltip}
                  />
                  <CheckboxGroup
                    withToggle
                    label="Treatment Intensity"
                    tooltip={DIRECTORIES_DATA.treatment_intensity.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.treatment_intensity.options).map(([model, label]) => (
                      <Checkbox key={model} model={`treatment_intensity.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Treatment Phases"
                    tooltip={DIRECTORIES_DATA.treatment_phases.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.treatment_phases.options).map(([model, label]) => (
                      <Checkbox key={model} model={`treatment_phases.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <Textarea label="Phase Requirements" model="phase_requirements" tooltip={DIRECTORIES_DATA.phase_requirements.tooltip} />
                  <Textarea label="Phase Privileges" model="phase_privileges" tooltip={DIRECTORIES_DATA.phase_privileges.tooltip} />
                  <Textarea
                    label="Advancement Criteria"
                    model="advancement_criteria"
                    tooltip={DIRECTORIES_DATA.advancement_criteria.tooltip}
                  />
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Documentation"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_documentation.icon}
                scrollview={{
                  id: 'documentation',
                  name: 'Documentation',
                  icon: DIRECTORY_SCHEMA.location_documentation.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <CheckboxGroup
                    withToggle
                    label="Intake Requirements"
                    tooltip={DIRECTORIES_DATA.intake_requirements.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.intake_requirements.options).map(([model, label]) => (
                      <Checkbox key={model} model={`intake_requirements.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Exclusion Criteria"
                    tooltip={DIRECTORIES_DATA.exclusion_criteria.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.exclusion_criteria.options).map(([model, label]) => (
                      <Checkbox key={model} model={`exclusion_criteria.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <Textarea label="Waitlist Process" model="waitlist_process" tooltip={DIRECTORIES_DATA.waitlist_process.tooltip} />
                  <Textarea label="Assessment Process" model="assessment_process" tooltip={DIRECTORIES_DATA.assessment_process.tooltip} />
                  <CheckboxGroup
                    withToggle
                    label="Progress Tracking"
                    tooltip={DIRECTORIES_DATA.progress_tracking.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.progress_tracking.options).map(([model, label]) => (
                      <Checkbox key={model} model={`progress_tracking.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Discharge Process"
                    tooltip={DIRECTORIES_DATA.discharge_process.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.discharge_process.options).map(([model, label]) => (
                      <Checkbox key={model} model={`discharge_process.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Required Forms"
                    tooltip={DIRECTORIES_DATA.required_forms.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.required_forms.options).map(([model, label]) => (
                      <Checkbox key={model} model={`required_forms.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Medication Management"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_medication_management.icon}
                scrollview={{
                  id: 'medication_management',
                  name: 'Medication Management',
                  icon: DIRECTORY_SCHEMA.location_medication_management.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <CheckboxGroup
                    withToggle
                    label="Medication Storage"
                    tooltip={DIRECTORIES_DATA.medication_storage.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.medication_storage.options).map(([model, label]) => (
                      <Checkbox key={model} model={`medication_storage.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <Input
                    label="Medication Administration Time"
                    model="medication_administration_times"
                    tooltip={DIRECTORIES_DATA.medication_administration_times.tooltip}
                  />
                  <CheckboxGroup
                    withToggle
                    label="Medication Policies"
                    tooltip={DIRECTORIES_DATA.medication_policies.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.medication_policies.options).map(([model, label]) => (
                      <Checkbox key={model} model={`medication_policies.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <RadioGroup
                    label="Medical Staff Coverage"
                    model="medical_staff_coverage"
                    tooltip={DIRECTORIES_DATA.medical_staff_coverage.tooltip}
                    layout="vertical-dense"
                  >
                    {Object.entries(DIRECTORIES_DATA.medical_staff_coverage.options).map(([value, label]) => (
                      <Radio key={value} value={value} label={label} />
                    ))}
                  </RadioGroup>
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Staff Development"
                headingType="h2"
                icon={DIRECTORY_SCHEMA.location_staff_development.icon}
                scrollview={{
                  id: 'staff_development',
                  name: 'Staff Development',
                  icon: DIRECTORY_SCHEMA.location_staff_development.icon,
                }}
              >
                <FormSection maxWidth="100%">
                  <CheckboxGroup
                    withToggle
                    label="Required Training"
                    tooltip={DIRECTORIES_DATA.required_training.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.required_training.options).map(([model, label]) => (
                      <Checkbox key={model} model={`required_training.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Ongoing Education"
                    model="ongoing_education"
                    tooltip={DIRECTORIES_DATA.ongoing_education.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.ongoing_education.options).map(([model, label]) => (
                      <Checkbox key={model} model={`ongoing_education.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Staff Support"
                    tooltip={DIRECTORIES_DATA.staff_support.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.staff_support.options).map(([model, label]) => (
                      <Checkbox key={model} model={`staff_support.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Staff Qualifications"
                    tooltip={DIRECTORIES_DATA.staff_qualifications.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.staff_qualifications.options).map(([model, label]) => (
                      <Checkbox key={model} model={`staff_qualifications.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>

                  <CheckboxGroup
                    withToggle
                    label="Staff Background Checks"
                    tooltip={DIRECTORIES_DATA.staff_background_checks.tooltip}
                    layout="vertical-dense"
                    trueIcon="check"
                  >
                    {Object.entries(DIRECTORIES_DATA.staff_background_checks.options).map(([model, label]) => (
                      <Checkbox key={model} model={`staff_background_checks.${model}`} label={label} />
                    ))}
                  </CheckboxGroup>
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Testomonials"
                headingType="h2"
                icon={'testimonials'}
                scrollview={{
                  id: 'testimonials',
                  name: 'Testomonials',
                  icon: 'testimonials',
                }}
              >
                <Alert small contrast type="warning" glyph="warning" className="mb-4">
                  Please be aware of HIPAA regulations when sharing testimonials. Ensure that all testimonials are anonymized and do not
                  contain any personal health information (PHI).
                </Alert>

                <LocationTestimonials model="testimonials" />
              </Section>
            </Form>
          </Overlay.Content>
        </PageGrid>
      </ScrollView>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Location"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              // permission="__PERMISSION__.edit"
            />

            <DeleteDialog
              title="Delete Location?"
              message="Are you sure you want to delete this Location? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                // permission="__PERMISSION__.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}
