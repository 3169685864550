import React from 'react'

import ContextShow from '../ContextShow'
import Flex from '../../Flex'
import Input from '../Input'
import Option from '../Option'
import Select from '../Select'

const SexSelect = ({ testKey, label, model, horizontal, isRequired, ...rest }: any) => (
  <div>
    <Flex gap="1rem" stretchChildrenX>
      <Select
        allowEmpty
        testKey={testKey}
        label={label}
        model={model}
        horizontal={horizontal}
        validations={
          isRequired && {
            presence: {
              message: 'Please select the Sex Assigned at Birth',
            },
          }
        }
        {...rest}
      >
        <Option label="Male" value="male" />
        <Option label="Female" value="female" />
        <Option label="Intersex" value="intersex" />
        <Option label="Decline to respond" value="decline_to_respond" />
        <Option label="Other" value="other" />
      </Select>

      <ContextShow when={model} is="other">
        <Input label="Other Sex" model={`${model}_other`} />
      </ContextShow>
    </Flex>
  </div>
)

SexSelect.defaultProps = {
  label: 'Sex Assigned at Birth',
  model: 'sex',
  validateOn: 'blur-change',
}

export default SexSelect
