import React from 'react'
import size from 'lodash/size'
import { useParams, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import pluralize from 'pluralize'
import dompurify from 'dompurify'

import AccordionAlert from '../../../components/AccordionAlert'
import Alert from '../../../components/Alert'
import TipAlert from '../../../components/TipAlert'
import ContextShow from '../../../components/Forms/ContextShow'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Form from '../../../components/Forms/Form'
import Overlay from '../../../components/Overlay'
import Button from '../../../components/Button'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import PageLoader from '../../../components/Loaders/PageLoader'

import { Spreadsheet } from '../../../components/Spreadsheet/Spreadsheet'
import Card from '../../../components/Card'
import PillTab from '../../../components/PillTab'
import PillTabList from '../../../components/PillTabList'
import State from '../../../components/State'
import TabPanel from '../../../components/TabPanel'
import TabPanels from '../../../components/TabPanels'
import Tabs from '../../../components/Tabs'
import Status from '../../../components/Status'

import ClaimGeneralDetails from '../components/ClaimGeneralDetails'
import ClaimDependent from '../components/ClaimDependent'
import ClaimDetails from '../components/ClaimDetails'
import ClaimInstitutionalProviders from '../components/ClaimInstitutionalProviders'
import ClaimInstitutionalValueCodes from '../components/ClaimInstitutionalValueCodes'
import ClaimInsurancePolicies from '../components/ClaimInsurancePolicies'
import ClaimPayerDetails from '../components/ClaimPayerDetails'
import ClaimProfessionalProviders from '../components/ClaimProfessionalProviders'
import { ClaimStatuses } from '../components/ClaimStatuses'

import { useSettings } from '../../../hooks/useSettings'
import { useGet, useCreate, useUpdate, useDelete } from '../../../hooks/useNewAPI'
import { get } from '../../../modules/api/requests'
import { countWord } from '../../../utils/functions'

import { Text } from '../../../components/Typography'

import ClaimStatus from '../ClaimStatus'
import Notifications from '../../../modules/notifications'
import { SectionCard } from '../../../components/SectionCard'

import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'

import { useSpreadsheet } from '../../../components/Spreadsheet/useSpreadsheet'
import { INSURANCE_CODE_TYPES } from '../../../utils/constants'
import { ClaimsSpreadsheetTipAlert } from '../ClaimsSpreadsheetTipAlert'

const sanitize = dompurify.sanitize

export const ClaimOverlayForm = (props: any) => {
  const form = React.useRef(null)

  const { canEdit = true } = props

  const history: any = useHistory()
  const location: any = useLocation()
  const match = useRouteMatch()
  const params = useParams()

  const id = props.id || params?.id
  const isNew = id === 'new' || !id

  const [client, setClient] = React.useState(null)
  const [isEditable, setIsEditable] = React.useState(isNew)
  const [isDeleted, setIsDeleted] = React.useState(false)

  const [validationErrors, setValidationErrors] = React.useState([])
  const [validationClearingHouseErrors, setValidationClearingHouseErrors] = React.useState([])

  const clientId = React.useRef(null)

  const { timezone, tenant, isBehave } = useSettings()

  const [isValid, setIsValid] = React.useState(false)
  const [claimCategory, setClaimCategory] = React.useState(null)
  const [feeSchedule, setFeeSchedule] = React.useState(null)

  const [insuranceProvider, setInsuranceProvider] = React.useState(null)

  const { data: claim, isLoading } = useGet({
    name: ['insurance_claims', id],
    url: `/insurance_claims/${id}`,
    options: { enabled: !isNew && !isDeleted },
  })

  const { mutateAsync: createAsync, isLoading: isCreating } = useCreate({
    name: ['insurance_claims', id, 'create'],
    url: `/insurance_claims`,
    invalidate: 'insurance_claims',
    invalidateKeys: ['insurance_roster_items'],
    onSuccess: () => {
      Notifications.send('Claim Created Successfully', 'positive')
    },
  })

  const { mutateAsync: updateAsync, isLoading: isUpdating } = useUpdate({
    name: ['insurance_claims', id, 'update'],
    url: `/insurance_claims/${id}`,
    invalidate: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Claim Updated Successfully', 'positive')
    },
  })

  const { mutateAsync: validateClaim, isLoading: isValidating }: any = useUpdate({
    name: ['insurance_claims', id, 'validate'],
    url: `/insurance_claims/${claim?.id}/validate`,
    invalidate: ['insurance_claims'],
    invalidateKeys: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Validation Check Ran Successfully', 'positive')
    },
    onError: (error: any) => {
      setValidationErrors(error?.data?.errors)
    },
  })

  const { mutateAsync: submitClaim, isLoading: isSubmitting }: any = useUpdate({
    name: ['insurance_claims', id, 'submit'],
    url: `/insurance_claims/${claim?.id}/submit`,
    invalidate: ['insurance_claims'],
    invalidateKeys: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Submission Ran Successfully', 'positive')
    },
  })

  const { mutateAsync: devValidateClaim, isLoading: isDevValidating }: any = useUpdate({
    name: ['insurance_claims', id, 'validate'],
    url: `/insurance_claims/${claim?.id}/dev_validate`,
    invalidate: ['insurance_claims'],
    invalidateKeys: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Validation Check Ran Successfully', 'positive')
    },
    onError: (error: any) => {
      setValidationErrors(error?.data?.errors)
    },
  })

  const { mutateAsync: devSubmitClaim, isLoading: isDevSubmitting }: any = useUpdate({
    name: ['insurance_claims', id, 'submit'],
    url: `/insurance_claims/${claim?.id}/dev_submit`,
    invalidate: ['insurance_claims'],
    invalidateKeys: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Submission Ran Successfully', 'positive')
    },
  })

  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useDelete({
    name: ['insurance_claims', id, 'delete'],
    url: `/insurance_claims/${claim?.id}`,
    invalidate: ['insurance_claims'],
    invalidateKeys: ['insurance_claims'],
    onSuccess: () => {
      setIsDeleted(true)
      close()
    },
  })

  const [primaryInsurance, setPrimaryInsurance] = React.useState(claim?.primary_insurance)
  const [secondaryInsurance, setSecondaryInsurance] = React.useState(claim?.secondary_insurance)
  const [tertiaryInsurance, setTertiaryInsurance] = React.useState(claim?.tertiary_insurance)

  const [insuranceAuthorization, setInsuranceAuthorization] = React.useState(claim?.insurance_authorization)
  const [treatmentEpisode, setTreatmentEpisode] = React.useState(claim?.treatment_episode)

  const [admittingDiagnosis, setAdmittingDiagnosis] = React.useState(claim?.admitting_diagnosis)
  const [principalDiagnosis, setPrincipalDiagnosis] = React.useState(claim?.principal_diagnosis)

  const findAmountInFeeSchedule = (insurance_new_code_id: string) => {
    if (!feeSchedule) return 0

    if (!feeSchedule.insurance_new_fee_schedule_services) return 0

    for (let i = 0; i < feeSchedule.insurance_new_fee_schedule_services.length; i++) {
      if (feeSchedule.insurance_new_fee_schedule_services[i].insurance_new_code.id === insurance_new_code_id) {
        return feeSchedule.insurance_new_fee_schedule_services[i].price || 0
      }
    }

    return 0
  }

  const professionalColumns = React.useMemo(() => {
    return [
      {
        title: 'ID',
        model: 'id',
      },
      {
        title: 'Start Date',
        model: 'service_date_start',
        type: 'date',
        width: 140,
      },
      {
        title: 'End Date',
        model: 'service_date_end',
        type: 'date',
        width: 140,
      },
      {
        title: 'Insurance Code *',
        model: 'insurance_new_code',
        width: 340,
        type: 'object_selector',
        onUpdate: ({ value, y, instance, get, set }: any) => {
          if (!value) return

          const parsed = JSON.parse(value)
          const price = findAmountInFeeSchedule(parsed.id)

          if (parsed.modifier_codes) {
            set('modifier_codes', JSON.stringify(parsed.modifier_codes))
          }

          set('unit_price', parseFloat(price))
        },
        config: {
          includeObject: true,
          endpoint: feeSchedule?.id && `/insurance_new_fee_schedules/${feeSchedule?.id}/insurance_new_codes`,
          dataType: 'insurance_new_code',
          params: { status: 'active' },
          selectTitle: (data: any) => `${data?.service_name} (${data?.procedure_code})`,
          selectDescription: (data: any) => `${INSURANCE_CODE_TYPES[data?.code_type]}`,
          tooltip: !feeSchedule?.id && 'Select a Fee Schedule to enable editing',
        },
      },
      {
        title: 'Modifier Codes',
        model: 'modifier_codes',
        type: 'multi_search_selector',
        config: {
          animateOnChange: true,
          includeObject: true,
          params: { type: 'insurance_codes', subtype: 'modifier_code' },
          selectTitle: (data: any) => data?.code,
          selectDescription: (data: any) => data?.description,
          selectMenuTitle: (data, highlights) => {
            let title = data?.code
            if (!highlights) return title

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'code') {
                title = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
          },
          selectMenuDescription: (data, highlights) => {
            let description = data?.description
            if (!highlights) return description

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'description') {
                description = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
          },
        },
      },
      {
        title: 'Diagnoses',
        model: 'diagnoses',
        type: 'multi_object_selector',
        readOnly: !client?.id,
        config: {
          idsModel: 'diagnoses_ids',
          endpoint: client?.id && `/residents/${client?.id}/diagnoses`,
          dataType: 'diagnosis',
          selectTitle: (data: any) => data.code,
          selectDescription: (data: any) => data.description,
          renderItem: (data: any) => <Status small color="vividBlue" label={data.code} />,
          tooltip: !client?.id && 'Select a client to enable editing',
        },
      },
      {
        title: 'Units',
        model: 'units',
        align: 'right',
        type: 'numeric',
        onUpdate: ({ get, set }) => {
          const units = get('units') || 0
          const unitPrice = get('unit_price') || 0

          set('total_amount', units * unitPrice)
        },
        config: {
          defaultValue: 1,
          validations: {
            greaterThanOrEqualTo: {
              value: 0,
              message: 'Value must be greater than or equal to 0',
            },
          },
        },
      },
      {
        title: 'Unit Price',
        model: 'unit_price',
        align: 'right',
        type: 'amount',
        config: {
          animateOnChange: true,
          validations: {
            greaterThanOrEqualTo: {
              value: 0,
              message: 'Value must be greater than or equal to 0',
            },
          },
        },
        onUpdate: ({ get, set }) => {
          const units = get('units') || 0
          const unitPrice = get('unit_price') || 0

          set('total_amount', units * unitPrice)
        },
      },
      {
        title: 'Total Charge',
        model: 'total_amount',
        align: 'right',
        type: 'amount',
        readOnly: true,
        config: {
          animateOnChange: true,
        },
      },
    ]
  }, [client?.id, feeSchedule?.id])

  const professionalSpreadsheetProps = useSpreadsheet({ columns: professionalColumns, manualRowDelete: true })

  const institutionalColumns = React.useMemo(() => {
    return [
      {
        title: 'ID',
        model: 'id',
      },
      {
        title: 'Service Date',
        model: 'service_date_start',
        type: 'date',
        width: 140,
      },
      {
        title: 'Insurance Code',
        model: 'insurance_new_code',
        width: 340,
        type: 'object_selector',
        onUpdate: ({ value, y, instance, get, set }: any) => {
          if (!value) return

          const parsed = JSON.parse(value)
          const price = findAmountInFeeSchedule(parsed.id)

          if (parsed.modifier_codes) {
            set('modifier_codes', JSON.stringify(parsed.modifier_codes))
          }

          set('unit_price', parseFloat(price))
        },
        config: {
          includeObject: true,
          endpoint: feeSchedule?.id && `/insurance_new_fee_schedules/${feeSchedule?.id}/insurance_new_codes`,
          dataType: 'insurance_new_code',
          params: { status: 'active' },
          selectTitle: (data: any) => `${data?.service_name} (${data?.procedure_code})`,
          selectDescription: (data: any) => `${INSURANCE_CODE_TYPES[data?.code_type]}`,
          tooltip: !feeSchedule?.id && 'Select a Fee Schedule to enable editing',
        },
      },
      {
        title: 'Revenue Code',
        model: 'revenue_code',
        type: 'input_search_selector',
        config: {
          params: { type: 'insurance_codes', subtype: 'revenue_code' },
          modelSelector: 'code',
          selectTitle: (data: any) => data?.code,
          selectDescription: (data: any) => data?.description,
          selectMenuTitle: (data, highlights) => {
            let title = data?.code
            if (!highlights) return title

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'code') {
                title = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
          },
          selectMenuDescription: (data, highlights) => {
            let description = data?.description
            if (!highlights) return description

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'description') {
                description = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
          },
        },
      },
      {
        title: 'Modifier Codes',
        model: 'modifier_codes',
        type: 'multi_search_selector',
        config: {
          includeObject: true,
          animateOnChange: true,
          params: { type: 'insurance_codes', subtype: 'modifier_code' },
          selectTitle: (data: any) => data?.code,
          selectDescription: (data: any) => data?.description,
          selectMenuTitle: (data, highlights) => {
            let title = data?.code
            if (!highlights) return title

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'code') {
                title = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
          },
          selectMenuDescription: (data, highlights) => {
            let description = data?.description
            if (!highlights) return description

            for (let i = 0; i < highlights?.length; i++) {
              if (highlights[i].field === 'description') {
                description = highlights[i].snippet
                break
              }
            }

            return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
          },
        },
      },
      {
        title: 'Units',
        model: 'units',
        align: 'right',
        type: 'numeric',
        onUpdate: ({ get, set }) => {
          const units = get('units') || 0
          const unitPrice = get('unit_price') || 0

          set('total_amount', units * unitPrice)
        },
        config: {
          defaultValue: 1,
          validations: {
            greaterThanOrEqualTo: {
              value: 0,
              message: 'Value must be greater than or equal to 0',
            },
          },
        },
      },
      {
        title: 'Unit Type',
        model: 'unit_type',
        type: 'select',
        config: {
          defaultValue: 'UN',
          options: [
            { label: 'Units', value: 'UN' },
            { label: 'Days', value: 'DA' },
          ],
        },
      },
      {
        title: 'Unit Price',
        model: 'unit_price',
        align: 'right',
        type: 'amount',
        config: {
          animateOnChange: true,
          validations: {
            greaterThanOrEqualTo: {
              value: 0,
              message: 'Value must be greater than or equal to 0',
            },
          },
        },
        onUpdate: ({ get, set }) => {
          const units = get('units') || 0
          const unitPrice = get('unit_price') || 0

          set('total_amount', units * unitPrice)
        },
      },
      {
        title: 'Total Charge',
        model: 'total_amount',
        align: 'right',
        type: 'amount',
        readOnly: true,
        config: {
          animateOnChange: true,
        },
      },
    ]
  }, [])

  const institutionalSpreadsheetProps = useSpreadsheet({ columns: institutionalColumns, manualRowDelete: true })

  React.useEffect(() => {
    if (!claim) return

    setClient(claim?.resident)

    if (!primaryInsurance && !!claim?.primary_insurance) setPrimaryInsurance(claim?.primary_insurance)
    if (!secondaryInsurance && !!claim?.secondary_insurance) setSecondaryInsurance(claim?.secondary_insurance)
    if (!tertiaryInsurance && !!claim?.tertiary_insurance) setTertiaryInsurance(claim?.tertiary_insurance)
    if (!insuranceAuthorization && !!claim?.insurance_authorization) setInsuranceAuthorization(claim?.insurance_authorization)
    if (!treatmentEpisode && !!claim?.treatment_episode) setTreatmentEpisode(claim?.treatment_episode)
    if (!admittingDiagnosis && !!claim?.admitting_diagnosis) setAdmittingDiagnosis(claim?.admitting_diagnosis)
    if (!principalDiagnosis && !!claim?.principal_diagnosis) setPrincipalDiagnosis(claim?.principal_diagnosis)

    // set clearing house validation errors
    if (claim.validation && size(claim.validation) > 0) {
      if (Array.isArray(claim.validation)) setValidationClearingHouseErrors(claim.validation)
    }
  }, [claim])

  React.useEffect(() => {
    // if (!client) {
    // 	setPrimaryInsurance(null)
    // 	setSecondaryInsurance(null)
    // 	setTertiaryInsurance(null)

    // 	return
    // }

    if (client?.id !== clientId?.current) {
      clientId.current = client?.id

      // setPrimaryInsurance(null)
      // setSecondaryInsurance(null)
      // setTertiaryInsurance(null)

      return
    }

    // if (client?.id === clientId?.current) {
    //   setPrimaryInsurance(client?.primary_insurance_policy)
    // }
  }, [clientId?.current, client])

  React.useEffect(() => {
    if (isNew) setIsEditable(true)
  }, [isNew])

  const close = () => {
    if (!!location?.parent) {
      history.push(location.parent)
    } else {
      const rootPath = match.url.substring(0, match.url.lastIndexOf('/')).substring(0, match.url.lastIndexOf('/'))
      history.push(rootPath)
    }
  }

  const cancel = () => {
    form.current?.resetForm()
    setIsEditable(false)
  }

  const save = async () => {
    let insurance_claim_service_lines_attributes: any = []

    if (claimCategory === 'professional') {
      insurance_claim_service_lines_attributes = professionalSpreadsheetProps.getData()
    } else if (claimCategory === 'institutional') {
      insurance_claim_service_lines_attributes = institutionalSpreadsheetProps.getData()
    }

    const apiServiceLineIds: any = initialData?.insurance_claim_service_lines?.map((line: any) => line.id) || []
    const deletedServiceLineIds: any = []

    for (const apiId of apiServiceLineIds) {
      const found = insurance_claim_service_lines_attributes.find((line: any) => line.id === apiId)

      if (!found) {
        deletedServiceLineIds.push(apiId)
      }
    }

    for (const deletedId of deletedServiceLineIds) {
      insurance_claim_service_lines_attributes.push({ id: deletedId, _destroy: true })
    }

    const data = {
      primary_insurance_id: primaryInsurance ? primaryInsurance.id : null,
      secondary_insurance_id: secondaryInsurance ? secondaryInsurance.id : null,
      tertiary_insurance_id: tertiaryInsurance ? tertiaryInsurance.id : null,
      admitting_diagnosis_id: admittingDiagnosis ? admittingDiagnosis.id : null,
      principal_diagnosis_id: principalDiagnosis ? principalDiagnosis.id : null,
      insurance_authorization_id: insuranceAuthorization ? insuranceAuthorization.id : null,
      treatment_episode_id: treatmentEpisode ? treatmentEpisode.id : null,
      ...form.current.getFormValue(),
      insurance_claim_service_lines_attributes,
    }

    if (isNew) {
      await createAsync(data)

      close()
    } else {
      await updateAsync(data)
      setIsEditable(false)
    }
  }

  const processClientForExtraData = async (client: any) => {
    if (client?.hasOwnProperty('current_primary_insurance')) {
      setPrimaryInsurance(client?.current_primary_insurance)
      setSecondaryInsurance(client?.current_secondary_insurance)
      setTertiaryInsurance(client?.current_tertiary_insurance)

      setInsuranceAuthorization(client?.current_insurance_authorization)
      setTreatmentEpisode(client?.current_treatment_episode)

      setAdmittingDiagnosis(client?.current_admitting_diagnosis || client?.current_primary_diagnosis)
      setPrincipalDiagnosis(client?.current_principal_diagnosis || client?.current_primary_diagnosis)
    } else if (isNew && isEditable) {
      const clientData = await get(`/residents/billable/${client?.id}`)

      setPrimaryInsurance(clientData?.data?.current_primary_insurance)
      setSecondaryInsurance(clientData?.data?.current_secondary_insurance)
      setTertiaryInsurance(clientData?.data?.current_tertiary_insurance)

      setInsuranceAuthorization(clientData?.data?.current_insurance_authorization)
      setTreatmentEpisode(clientData?.data?.current_treatment_episode)

      setAdmittingDiagnosis(clientData?.data?.current_admitting_diagnosis || clientData?.data?.current_primary_diagnosis)
      setPrincipalDiagnosis(clientData?.data?.current_principal_diagnosis || clientData?.data?.current_primary_diagnosis)
    }
  }

  const hasWarnings = size(claim?.warnings) > 0
  const hasValidationErrors = size(validationErrors) > 0
  const hasClearingHouseValidationErrors = size(validationClearingHouseErrors) > 0

  if (isLoading) return <PageLoader message="Loading Claim Data…" />

  const isSaving = isCreating || isUpdating
  const initialData = location.data || claim

  return (
    <>
      <Overlay.Content key={id} css={{ zIndex: 0 }}>
        <Form
          isCompact
          useFullModel
          getForm={form}
          initialModel={initialData}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={setIsValid}
          linked={{ version: 'v2' }}
          maxWidth="100%"
          key={`updated-${claim?.updated_at}`}
        >
          <Grid gap="0.4rem" css={styles.wrapper}>
            <Flex justifyContent="space-between">
              <RadioGroup
                model="category"
                layout="horizontal"
                defaultValue="professional"
                maxWidth="100%"
                withHover={false}
                isEditable={isNew}
                className={`${isNew ? '' : 'is-hidden'} w-[500px]`}
                onUpdate={(state: any) => {
                  setClaimCategory(state.value)
                }}
              >
                <Radio label="Professional Claim (CMS-1500)" value="professional" />
                <Radio label="Institutional Claim (UB-04)" value="institutional" />
              </RadioGroup>

              <TipAlert contrast glyph="info" localStorageKey="claims_navigation_tip">
                <b>Tip: </b>use Tab to navigate the form and Enter to open the selector inputs.
              </TipAlert>
            </Flex>

            <Grid horizontal templateColumns="1fr 1fr">
              {hasClearingHouseValidationErrors && (
                <AccordionAlert glyph="warning" type="negative" header={countWord('Issues', size(validationClearingHouseErrors))}>
                  The Clearing House reported the following issues on Validation:
                  <ul>
                    {validationClearingHouseErrors?.map((error: any) => (
                      <li>
                        <strong>{error.field}</strong>: {error.description}
                      </li>
                    ))}
                  </ul>
                </AccordionAlert>
              )}

              {hasWarnings && (
                <AccordionAlert glyph="warning" type="warning" header={`${size(claim?.warnings)} Warnings`}>
                  The next items could have a negative impact on getting your Insurance Claim approved:
                  <ul>
                    {claim?.warnings?.map((warning: any) => (
                      <li>{warning.message}</li>
                    ))}
                  </ul>
                </AccordionAlert>
              )}

              {hasValidationErrors && (
                <AccordionAlert glyph="warning" type="negative" header={countWord('Validation Error', size(validationErrors))}>
                  Please fix the next {pluralize('error', size(validationErrors))} and validate again:
                  <ul>
                    {validationErrors?.map((error: any) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </AccordionAlert>
              )}
            </Grid>

            {claim?.status === 'error_submission' && (
              <Alert type="negative">
                The Clearing House reported the following issues with the submission:
                <ul>
                  {claim?.response?.errors?.map((error: any) => (
                    <li>
                      <strong>{error.field}</strong>: {error.description}
                    </li>
                  ))}
                </ul>
              </Alert>
            )}

            <div>
              <Grid gap="0.4rem">
                {!isNew && (
                  <ClaimStatuses
                    header={
                      <Flex centerY justifyContent="space-between">
                        <Flex gap="0.5rem" horizontal>
                          <Text label="Current Status: " description={<ClaimStatus status={claim?.status} />} />
                        </Flex>

                        {canEdit && (
                          <Flex gap="0.5rem" horizontal>
                            <Button
                              size={200}
                              label={claim?.status === 'error_validation' ? 'Revalidate Claim' : 'Validate Claim'}
                              glyph="check"
                              type="default"
                              color="green"
                              flex="1 1 auto"
                              isLoading={isValidating}
                              isDisabled={claim?.status === 'validated'}
                              onClick={async () => {
                                await validateClaim()
                              }}
                            />

                            <Button
                              size={200}
                              label="Submit Claim"
                              glyph="check"
                              type="primary"
                              color="blue"
                              flex="1 1 auto"
                              isLoading={isSubmitting}
                              isDisabled={claim?.status !== 'validated'}
                              onClick={async () => {
                                await submitClaim()
                                close()
                              }}
                            />
                          </Flex>
                        )}
                      </Flex>
                    }
                    statuses={claim?.insurance_claim_statuses}
                  />
                )}

                <Flex gap="0.5rem" alignItems="normal">
                  <ClaimGeneralDetails isNew={isNew} claimID={claim?.id} />

                  <ClaimDependent
                    className="!flex-grow-2"
                    client={client}
                    onClientUpdate={(state: any) => {
                      if (!isEditable) return

                      if (!state?.object) {
                        setClient(null)
                        setPrimaryInsurance(null)
                        setSecondaryInsurance(null)
                        setTertiaryInsurance(null)

                        return
                      }

                      setClient(state.object)
                      processClientForExtraData(state.object)
                    }}
                    insurancePolicy={primaryInsurance}
                  />

                  <ClaimInsurancePolicies
                    className="!flex-grow-4"
                    client={client}
                    onClientUpdate={setClient}
                    primaryInsurance={primaryInsurance}
                    onPrimaryInsuranceUpdate={setPrimaryInsurance}
                    secondaryInsurance={secondaryInsurance}
                    tertiaryInsurance={tertiaryInsurance}
                    onSecondaryInsuranceUpdate={setSecondaryInsurance}
                    onTertiaryInsuranceUpdate={setTertiaryInsurance}
                  />

                  <ClaimPayerDetails insuranceProvider={insuranceProvider} onInsuranceProviderUpdate={setInsuranceProvider} />
                </Flex>

                <ClaimDetails
                  client={client}
                  feeSchedule={feeSchedule}
                  onUpdate={setFeeSchedule}
                  insuranceAuthorization={insuranceAuthorization}
                  onInsuranceAuthorizationUpdate={setInsuranceAuthorization}
                  treatmentEpisode={treatmentEpisode}
                  onTreatmentEpisodeUpdate={setTreatmentEpisode}
                  admittingDiagnosis={admittingDiagnosis}
                  onAdmittingDiagnosisUpdate={setAdmittingDiagnosis}
                  principalDiagnosis={principalDiagnosis}
                  onPrincipalDiagnosisUpdate={setPrincipalDiagnosis}
                />

                <ContextShow when="category" is="professional">
                  <ClaimProfessionalProviders tenant={tenant} />
                </ContextShow>

                <ContextShow when="category" is="institutional">
                  <Tabs defaultTab="providers">
                    <PillTabList css={{ marginTop: '0.25rem', marginBottom: '0.5rem' }}>
                      <PillTab label="Providers" name="providers" />
                      <PillTab label="Value Codes" name="value_codes" />
                    </PillTabList>

                    <TabPanels>
                      <TabPanel name="providers">
                        <ClaimInstitutionalProviders tenant={tenant} />
                      </TabPanel>

                      <TabPanel name="value_codes">
                        <ClaimInstitutionalValueCodes tenant={tenant} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </ContextShow>
              </Grid>
            </div>

            <ClaimsSpreadsheetTipAlert />

            {claimCategory === 'professional' && (
              <>
                {/* <ProfessionalServiceLines
                  key={`professional-${claim?.updated_at}`}
                  value={initialData?.insurance_claim_service_lines}
                  model="insurance_claim_service_lines"
                  isNew={isNew}
                  client={client}
                  tenant={tenant}
                  feeSchedule={feeSchedule}
                /> */}

                {!!feeSchedule?.id || size(claim?.insurance_claim_service_lines) > 0 ? (
                  <div>
                    <Spreadsheet
                      {...professionalSpreadsheetProps}
                      key={`spreadsheet-professional-${claim?.updated_at}`}
                      title="Professional Service Lines"
                      icon="outbox"
                      isEditable={isEditable}
                      initialData={professionalSpreadsheetProps.formatInitialData(
                        initialData?.insurance_claim_service_lines || [{ units: 1 }],
                      )}
                      hiddenColumns={{ columns: isEditable ? [1] : [0, 1] }}
                    />
                  </div>
                ) : (
                  <Card>
                    <State
                      isEmpty
                      icon="outbox"
                      title="Service Lines"
                      minHeight={200}
                      emptyDescription={
                        <Alert small type="warning" glyph="info" className="text-left">
                          You must select a <strong>Fee Schedule</strong> before adding any Service Lines
                        </Alert>
                      }
                    />
                  </Card>
                )}
              </>
            )}

            {claimCategory === 'institutional' && (
              <>
                {/* <InstitutionalServiceLines
                  key={`institutional-${claim?.updated_at}`}
                  value={initialData?.insurance_claim_service_lines}
                  model="insurance_claim_service_lines"
                  isNew={isNew}
                  client={client}
                  tenant={tenant}
                  feeSchedule={feeSchedule}
                /> */}

                {!!feeSchedule?.id || size(claim?.insurance_claim_service_lines) > 0 ? (
                  <div>
                    <Spreadsheet
                      {...institutionalSpreadsheetProps}
                      key={`spreadsheet-institutional-${claim?.updated_at}`}
                      title="Institutional Service Lines"
                      icon="outbox"
                      isEditable={isEditable}
                      initialData={institutionalSpreadsheetProps.formatInitialData(
                        initialData?.insurance_claim_service_lines || [{ units: 1, unit_type: 'UN' }],
                      )}
                      hiddenColumns={{ columns: isEditable ? [1] : [0, 1] }}
                    />
                  </div>
                ) : (
                  <Card>
                    <State
                      isEmpty
                      icon="outbox"
                      title="Service Lines"
                      minHeight={200}
                      emptyDescription={
                        <Alert small type="warning" glyph="info" className="text-left">
                          You must select a <strong>Fee Schedule</strong> before adding any Service Lines
                        </Alert>
                      }
                    />
                  </Card>
                )}
              </>
            )}

            {/* temp fix for dropdowns going outside viewport */}
            <div className="h-[400px]" />
          </Grid>
        </Form>
      </Overlay.Content>

      {canEdit && (
        <Overlay.Footer className="!px-2">
          {isEditable && (
            <Flex stretchChildrenX gap={8}>
              {claim?.status === 'validated' ? (
                <ConfirmDialog
                  title="Save Changes?"
                  message="Are you sure you want to save these changes? The Claim Status will be changed to Draft and you will be required to Re-Validate."
                  glyph="check"
                  yesColor="green"
                  yesLabel="Yes, Save Changes"
                  onYes={save}
                  isLoading={isSaving}
                >
                  <Button
                    label="Save"
                    glyph="check"
                    type="primary"
                    color="green"
                    isLoading={isSaving}
                    flex="3 1 auto"
                    permission="insurance_claims.create"
                  />
                </ConfirmDialog>
              ) : (
                <Button
                  label="Save"
                  glyph="check"
                  type="primary"
                  color="green"
                  isLoading={isSaving}
                  onClick={save}
                  flex="3 1 auto"
                  permission="insurance_claims.create"
                />
              )}

              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} flex="0 1 auto" />}
            </Flex>
          )}

          {!isEditable && (
            <Flex gap={8}>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={isSaving}
                onClick={() => setIsEditable(true)}
                flex="1 1 auto"
                permission="insurance_claims.edit"
              />

              <DeleteDialog
                title="Delete Claim?"
                message="Are you sure you want to delete this Claim? This action cannot be undone."
                onYes={deleteAsync}
                permission="insurance_claims.delete"
              >
                <Button
                  fullWidth
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  css={{ minWidth: 250 }}
                  permission="insurance_claims.delete"
                />
              </DeleteDialog>
            </Flex>
          )}
        </Overlay.Footer>
      )}
    </>
  )
}

const styles = {
  wrapper: {
    padding: '1rem',
  },

  tabsList: {
    padding: '0 1rem',
  },

  claimGrid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '1fr',

    '@media (min-width: 1000px)': {
      gridTemplateColumns: '360px 1fr',
    },

    '@media (min-width: 1500px)': {
      gridTemplateColumns: '360px 1fr 460px',
    },
  },

  gridColumn: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '1fr',

    '@media (min-width: 1000px)': {
      gridColumn: '-1 / 1',
      gridTemplateColumns: '1fr 1fr',
    },

    '@media (min-width: 1500px)': {
      gridTemplateColumns: '1fr',
      gridColumn: '3',
    },
  },
}
