import React from 'react'
import snakeCase from 'lodash/snakeCase'

import { UATests, WITHDRAWAL_SYMPTOMS } from '../../../utils/constants'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import DataArray from '../../../components/Forms/DataArray'
import DateInput from '../../../components/Forms/DateInput'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Diagnoses from '../../../components/SmartContent/Diagnoses'
import Divider from '../../../components/Divider'
import Field from '../../../components/Forms/Field'
import FormSection from '../../../components/Forms/FormSection'
import GenderIdentitySelect from '../../../components/Forms/elements/GenderIdentitySelect'
import Grid from '../../../components/Grid'
import Input from '../../../components/Forms/Input'
import Label from '../../../components/Label'
import LevelOfCareSelect from '../../../components/Forms/LevelOfCareSelect'
import Medications from '../../../components/SmartContent/Medications'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../../components/Forms/Option'
import PreferredPronounsSelect from '../../../components/Forms/elements/PreferredPronounsSelect'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Select from '../../../components/Forms/Select'
import SexSelect from '../../../components/Forms/elements/SexSelect'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupExtra from '../../../components/Forms/elements/YesNoRadioGroupExtra'
import YesNoRadioGroupWithTextarea from '../../../components/Forms/elements/YesNoRadioGroupWithTextarea'

import CIWASymptoms from '../../../components/Overlays/pages/DataForms/common/CIWASymptoms'
import COWSSymptoms from '../../../components/Overlays/pages/DataForms/common/COWSSymptoms'
import LevelOfRiskAndInterventions from '../../../components/Overlays/pages/DataForms/common/LevelOfRiskAndInterventions'
import MentalStatusForm from '../../../components/Overlays/pages/DataForms/common/MentalStatusForm'
import SNAP from '../../../components/Overlays/pages/DataForms/common/SNAP'
import ThoughtsPlansSuicidalIntent from '../../../components/Overlays/pages/DataForms/common/ThoughtsPlansSuicidalIntent'
import TreatmentHistoryForm from '../../../components/Overlays/pages/DataForms/common/TreatmentHistoryForm'

import { Heading } from '../../../components/Typography'
import { CSSRSScale } from '../components/CSSRSScale'

export const MEDICAL_EVALUATION_FIELDS = [
  {
    label: 'Cardiovascular Assessment',
    model: 'cardiovascular_assessment',
    component: () => (
      <>
        <Heading is="h3">Cardiovascular Assessment</Heading>
        <RadioGroup label="Heart rhythm" model="data.medical_evaluation.heart_rhythm" layout="horizontal">
          <Radio label="Regular" value="regular" />
          <Radio label="Irregular" value="irregular" />
        </RadioGroup>

        <RadioGroup label="Capillary refill" model="data.medical_evaluation.capillary_refill" layout="horizontal">
          <Radio label="Brisk (<3 secs)" value="brisk" />
          <Radio label="Sluggish (>3 secs)" value="sluggish" />
        </RadioGroup>

        <RadioGroup label="Edema" model="data.medical_evaluation.edema" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.medical_evaluation.edema" is={true}>
          <SmartTextarea useQuickText useDictation label="Where?" model="data.medical_evaluation.edema_where" />
          <RadioGroup label="Pitting?" model="data.medical_evaluation.edema_pitting" layout="horizontal-dense">
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Respiratory Assessment',
    model: 'respiratory_assessment',
    component: () => (
      <>
        <Heading is="h3">Respiratory Assessment</Heading>

        <RadioGroup label="Shortness of breath" model="data.medical_evaluation.shortness_of_breath" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <RadioGroup label="Lungs" model="data.medical_evaluation.lungs" layout="vertical-dense">
          <Radio label="Within normal limits" value="normal" />
          <Radio label="Diminished" value="diminished" />
          <Radio label="Crackles" value="crackles" />
          <Radio label="Wheezes" value="wheezes" />
          <Radio label="Rhonchi" value="rhonchi" />
          <Radio label="Other" value="other" />
        </RadioGroup>

        <ContextShow when="data.medical_evaluation.lungs" is="other">
          <SmartTextarea useQuickText useDictation label="Please explain:" model="data.medical_evaluation.lungs_extra" />
        </ContextShow>

        <RadioGroup label="Cough" model="data.medical_evaluation.cough" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.medical_evaluation.cough" is={true}>
          <RadioGroup label="Cough Type" model="data.medical_evaluation.cough_type" layout="horizontal">
            <Radio label="Productive" value="productive" />
            <Radio label="Non-productive" value="non_productive" />
          </RadioGroup>
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Gastrointestinal Assessment',
    model: 'gastrointestinal_assessment',
    component: () => (
      <>
        <Heading is="h3">Gastrointestinal Assessment</Heading>

        <RadioGroup label="Abdomen" model="data.medical_evaluation.abdomen" layout="vertical-dense">
          <Radio label="Within normal limits" value="normal" />
          <Radio label="Tender" value="tender" />
          <Radio label="Guarded" value="guarded" />
          <Radio label="Firm" value="firm" />
          <Radio label="Distended" value="distended" />
          <Radio label="Other" value="other" />
        </RadioGroup>

        <ContextShow when="data.medical_evaluation.abdomen" is="other">
          <SmartTextarea useQuickText useDictation label="Please explain:" model="data.medical_evaluation.abdomen_extra" />
        </ContextShow>

        <RadioGroup label="Bowels" model="data.medical_evaluation.bowels" layout="horizontal">
          <Radio label="Within normal limits" value="normal" />
          <Radio label="Diarrhea" value="diarrhea" />
          <Radio label="Constipation" value="constipation" />
        </RadioGroup>

        <DateInput label="Date of last bowel movement" model="data.medical_evaluation.bowel_date" />
      </>
    ),
  },
  {
    label: 'Genitourinary Assessment',
    model: 'genitourinary_assessment',
    component: () => (
      <>
        <Heading is="h3">Genitourinary Assessment</Heading>

        <RadioGroup label="Urination" model="data.medical_evaluation.urination" layout="vertical-dense">
          <Radio label="Within normal limits" value="normal" />
          <Radio label="Blood in urine" value="blood_in_urine" />
          <Radio label="Frequency" value="frequency" />
          <Radio label="Urgency" value="urgency" />
          <Radio label="Burning" value="burning" />
          <Radio label="Difficulty voiding" value="difficulty_voiding" />
        </RadioGroup>

        <DateInput
          label="Reproductive - When was the client's last menstrual period?"
          model="data.medical_evaluation.last_menstrual_period"
        />
      </>
    ),
  },
  {
    label: 'Skin Integrity',
    model: 'skin_integrity',
    component: () => (
      <>
        <Heading is="h3">Skin Integrity</Heading>

        <RadioGroup label="Skin integrity/abnormalities" model="data.medical_evaluation.skin_integrity" layout="vertical-dense">
          <Radio label="Within normal limits" value="normal" />
          <Radio label="Rash" value="rash" />
          <Radio label="Bruises" value="bruises" />
          <Radio label="Sores/Open areas" value="sores" />
          <Radio label="Redness/swelling" value="redness" />
          <Radio label="Other" value="other" />
        </RadioGroup>

        <ContextShow when="data.medical_evaluation.skin_integrity" is="other">
          <SmartTextarea useQuickText useDictation label="Please explain:" model="data.medical_evaluation.skin_integrity_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Mobility',
    model: 'mobility',
    component: () => (
      <>
        <Heading is="h3">Mobility</Heading>

        <RadioGroup
          label="Has the client been performing ADLs independently recently? (Showering, eating, getting out of bed, etc.)"
          model="data.medical_evaluation.performed_adl_independently"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.medical_evaluation.performed_adl_independently" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.medical_evaluation.performed_adl_independently_extra"
          />
        </ContextShow>

        <CheckboxGroup label="Has the client experienced any of the following recently?" layout="vertical-dense">
          <Checkbox label="Decreased motiviation" model="data.medical_evaluation.decreased_motiviation" />
          <Checkbox label="Decreased physical ability" model="data.medical_evaluation.decreased_physical_ability" />
          <Checkbox label="Decreased mobility" model="data.medical_evaluation.decreased_mobility" />
        </CheckboxGroup>

        <ContextShow when="data.medical_evaluation.decreased_motiviation" is={true}>
          <SmartTextarea useQuickText useDictation label="Please explain:" model="data.medical_evaluation.decreased_motiviation_extra" />
        </ContextShow>

        <ContextShow when="data.medical_evaluation.decreased_physical_ability" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.medical_evaluation.decreased_physical_ability_extra"
          />
        </ContextShow>

        <ContextShow when="data.medical_evaluation.decreased_mobility" is={true}>
          <SmartTextarea useQuickText useDictation label="Please explain:" model="data.medical_evaluation.decreased_mobility_extra" />
        </ContextShow>

        <RadioGroup label="Gait" model="data.medical_evaluation.gait" layout="vertical-dense">
          <Radio label="Steady" value="steady" />
          <Radio label="Shuffling" value="shuffling" />
          <Radio label="Limping" value="limping" />
          <Radio label="Other" value="other" />
        </RadioGroup>

        <ContextShow when="data.medical_evaluation.gait" is="other">
          <SmartTextarea useQuickText useDictation label="Please explain:" model="data.medical_evaluation.gait_extra" />
        </ContextShow>
      </>
    ),
  },
]

export const CARE_COORDINATION_FIELDS = [
  {
    label: 'Treatment goals include a decrease in the following',
    model: 'treatment_decrease_goals',
    component: () => (
      <>
        <CheckboxGroup label="Treatment goals include a decrease in the following:" layout="vertical-dense">
          <Checkbox label="Anger/irritability" model="data.care_coordination.treatment_decrease_goals.anger_irritability" />
          <Checkbox label="Anxiety" model="data.care_coordination.treatment_decrease_goals.anxiety" />
          <Checkbox label="Depressive symptoms" model="data.care_coordination.treatment_decrease_goals.depressive_symptoms" />
          <Checkbox label="Decrease or quit smoking" model="data.care_coordination.treatment_decrease_goals.decrease_or_quit_smoking" />
          <Checkbox label="Fatigue" model="data.care_coordination.treatment_decrease_goals.fatigue" />
          <Checkbox label="Hyperactivity" model="data.care_coordination.treatment_decrease_goals.hyperactivity" />
          <Checkbox label="Impulsivity" model="data.care_coordination.treatment_decrease_goals.impulsivity" />
          <Checkbox label="Inattention" model="data.care_coordination.treatment_decrease_goals.inattention" />
          <Checkbox label="Insomnia" model="data.care_coordination.treatment_decrease_goals.insomnia" />
          <Checkbox label="Mania" model="data.care_coordination.treatment_decrease_goals.mania" />
          <Checkbox label="Mood instability" model="data.care_coordination.treatment_decrease_goals.mood_instability" />
          <Checkbox label="Obsessions/compulsions" model="data.care_coordination.treatment_decrease_goals.obsessions_compulsions" />
          <Checkbox label="Psychosis" model="data.care_coordination.treatment_decrease_goals.psychosis" />
          <Checkbox label="Rumination" model="data.care_coordination.treatment_decrease_goals.rumination" />
          <Checkbox label="Self-harm" model="data.care_coordination.treatment_decrease_goals.self_harm" />
          <Checkbox label="Substance dependence" model="data.care_coordination.treatment_decrease_goals.substance_dependence" />
          <Checkbox label="Suicidal ideations" model="data.care_coordination.treatment_decrease_goals.suicidal_ideations" />
          <Checkbox label="Thought distortion" model="data.care_coordination.treatment_decrease_goals.thought_distortion" />
          <Checkbox label="Other" model="data.care_coordination.treatment_decrease_goals.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.treatment_decrease_goals.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other:" model="data.care_coordination.treatment_decrease_goals.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Potential medication side effects discussed include but are not limited to',
    model: 'medication_side_effects',
    component: () => (
      <>
        <CheckboxGroup label="Potential medication side effects discussed include but are not limited to:" layout="vertical-dense">
          <Checkbox
            label="Addictive potential/tolerance"
            model="data.care_coordination.medication_side_effects.addictive_potential_tolerance"
          />
          <Checkbox label="Agitation" model="data.care_coordination.medication_side_effects.agitation" />
          <Checkbox label="Agranulocytosis" model="data.care_coordination.medication_side_effects.agranulocytosis" />
          <Checkbox label="Akathesia/TD/EPS/NMS" model="data.care_coordination.medication_side_effects.akathesia_td_eps_nms" />
          <Checkbox label="Avoid ETOH/other drugs" model="data.care_coordination.medication_side_effects.avoid_etoh_other_drugs" />
          <Checkbox label="Cardiac impact" model="data.care_coordination.medication_side_effects.cardiac_impact" />
          <Checkbox label="Diabetes" model="data.care_coordination.medication_side_effects.diabetes" />
          <Checkbox label="Dietary restrictions" model="data.care_coordination.medication_side_effects.dietary_restrictions" />
          <Checkbox label="Dizziness/orthostasis" model="data.care_coordination.medication_side_effects.dizziness_orthostasis" />
          <Checkbox
            label="Driving caution/restrictions"
            model="data.care_coordination.medication_side_effects.driving_caution_restrictions"
          />
          <Checkbox label="Dry mouth" model="data.care_coordination.medication_side_effects.dry_mouth" />
          <Checkbox label="Electrolyte abnormalities" model="data.care_coordination.medication_side_effects.electrolyte_abnormalities" />
          <Checkbox label="Fall risk increased" model="data.care_coordination.medication_side_effects.fall_risk_increased" />
          <Checkbox label="Hepatic impairment" model="data.care_coordination.medication_side_effects.hepatic_impairment" />
          <Checkbox label="Hydration needs" model="data.care_coordination.medication_side_effects.hydration_needs" />
          <Checkbox label="Hyperlipidemia" model="data.care_coordination.medication_side_effects.hyperlipidemia" />
          <Checkbox label="Hypothyroidism" model="data.care_coordination.medication_side_effects.hypothyroidism" />
          <Checkbox label="Increased sun sensitivity" model="data.care_coordination.medication_side_effects.increased_sun_sensitivity" />
          <Checkbox label="Medication interactions" model="data.care_coordination.medication_side_effects.medication_interactions" />
          <Checkbox label="Nausea/GI upset" model="data.care_coordination.medication_side_effects.nausea_gi_upset" />
          <Checkbox label="Pancreatitis" model="data.care_coordination.medication_side_effects.pancreatitis" />
          <Checkbox label="Paresthesias" model="data.care_coordination.medication_side_effects.paresthesias" />
          <Checkbox label="Pregnancy/lactation effect" model="data.care_coordination.medication_side_effects.pregnancy_lactation_effect" />
          <Checkbox label="Priapism" model="data.care_coordination.medication_side_effects.priapism" />
          <Checkbox label="Rash" model="data.care_coordination.medication_side_effects.rash" />
          <Checkbox label="Sedative effects" model="data.care_coordination.medication_side_effects.sedative_effects" />
          <Checkbox label="Seizures" model="data.care_coordination.medication_side_effects.seizures" />
          <Checkbox label="Serotonin discontinuation" model="data.care_coordination.medication_side_effects.serotonin_discontinuation" />
          <Checkbox label="Sexual dysfunction" model="data.care_coordination.medication_side_effects.sexual_dysfunction" />
          <Checkbox label="Sleep disturbance" model="data.care_coordination.medication_side_effects.sleep_disturbance" />
          <Checkbox label="Suicidal ideation" model="data.care_coordination.medication_side_effects.suicidal_ideation" />
          <Checkbox label="Tremor" model="data.care_coordination.medication_side_effects.tremor" />
          <Checkbox label="Weight changes" model="data.care_coordination.medication_side_effects.weight_changes" />
          <Checkbox label="Other" model="data.care_coordination.medication_side_effects.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.medication_side_effects.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other:" model="data.care_coordination.medication_side_effects.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Side effects addressed by',
    model: 'side_effects_addressed_by',
    component: () => (
      <>
        <CheckboxGroup label="Side effects addressed by" layout="vertical-dense">
          <Checkbox label="Watchful waiting" model="data.care_coordination.side_effects_addressed_by.watchful_waiting" />
          <Checkbox label="Medication change" model="data.care_coordination.side_effects_addressed_by.medication_change" />
          <Checkbox label="Dose change" model="data.care_coordination.side_effects_addressed_by.dose_change" />
          <Checkbox label="N/A" model="data.care_coordination.side_effects_addressed_by.not_applicable" />
          <Checkbox label="Other" model="data.care_coordination.side_effects_addressed_by.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.side_effects_addressed_by.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other:" model="data.care_coordination.side_effects_addressed_by.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Refer to or continue with',
    model: 'refer_or_continue',
    component: () => (
      <>
        <CheckboxGroup label="Refer to or continue with:" layout="vertical-dense">
          <Checkbox
            label="Psychotherapeutic education/reading on illness"
            model="data.care_coordination.refer_or_continue.psychotherapeutic_education_reading_on_illness"
          />
          <Checkbox label="Family therapy" model="data.care_coordination.refer_or_continue.family_therapy" />
          <Checkbox label="Group therapy" model="data.care_coordination.refer_or_continue.group_therapy" />
          <Checkbox label="Individual therapy" model="data.care_coordination.refer_or_continue.individual_therapy" />
          <Checkbox label="Weight" model="data.care_coordination.refer_or_continue.weight" />
          <Checkbox label="Aims" model="data.care_coordination.refer_or_continue.aims" />
          <Checkbox label="Vitals" model="data.care_coordination.refer_or_continue.vitals" />
          <Checkbox label="Chemical dependency treatment" model="data.care_coordination.refer_or_continue.chemical_dependency_treatment" />
          <Checkbox label="MMSE" model="data.care_coordination.refer_or_continue.mmse" />
          <Checkbox label="Sober living" model="data.care_coordination.refer_or_continue.sober_living" />
          <Checkbox
            label="Admission to an inpatient psychiatric hospital was recommended/offered"
            model="data.care_coordination.refer_or_continue.admission_to_an_inpatient_psychiatric_hospital_was_recommended_offered"
          />
          <Checkbox label="N/A" model="data.care_coordination.refer_or_continue.not_applicable" />
          <Checkbox label="Other" model="data.care_coordination.refer_or_continue.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.refer_or_continue.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other:" model="data.care_coordination.refer_or_continue.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Education was provided to the client for the following',
    model: 'education_provided',
    component: () => (
      <>
        <CheckboxGroup label="Education was provided to the client for the following:" layout="vertical-dense">
          <Checkbox label="Illness" model="data.care_coordination.education_provided.illness" />
          <Checkbox label="Medications" model="data.care_coordination.education_provided.medications" />
          <Checkbox label="Co-Occurring Disorders" model="data.care_coordination.education_provided.co_occurring_disorders" />
          <Checkbox label="Coping skills" model="data.care_coordination.education_provided.coping_skills" />
          <Checkbox label="Other" model="data.care_coordination.education_provided.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.education_provided.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other:" model="data.care_coordination.education_provided.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Lab data ordered',
    model: 'lab_data_ordered',
    component: () => (
      <>
        <CheckboxGroup label="Lab data ordered" layout="vertical-dense">
          <Checkbox label="CBC" model="data.care_coordination.lab_data_ordered.cbc" />
          <Checkbox label="SMAC" model="data.care_coordination.lab_data_ordered.smac" />
          <Checkbox label="TFT’s" model="data.care_coordination.lab_data_ordered.tft_s" />
          <Checkbox label="Lipids" model="data.care_coordination.lab_data_ordered.lipids" />
          <Checkbox label="Serum level:" model="data.care_coordination.lab_data_ordered.serum_level" />
          <Checkbox label="Lithium" model="data.care_coordination.lab_data_ordered.lithium" />
          <Checkbox label="Tegretol" model="data.care_coordination.lab_data_ordered.tegretol" />
          <Checkbox label="Lamictal" model="data.care_coordination.lab_data_ordered.lamictal" />
          <Checkbox label="Valproic acid" model="data.care_coordination.lab_data_ordered.valproic_acid" />
          <Checkbox label="Pregnancy testing" model="data.care_coordination.lab_data_ordered.pregnancy_testing" />
          <Checkbox label="UDS" model="data.care_coordination.lab_data_ordered.uds" />
          <Checkbox label="EKG" model="data.care_coordination.lab_data_ordered.ekg" />
          <Checkbox label="UA" model="data.care_coordination.lab_data_ordered.ua" />
          <Checkbox label="LFTS" model="data.care_coordination.lab_data_ordered.lfts" />
          <Checkbox label="HIV" model="data.care_coordination.lab_data_ordered.hiv" />
          <Checkbox label="RPR" model="data.care_coordination.lab_data_ordered.rpr" />
          <Checkbox label="Vitamin D level" model="data.care_coordination.lab_data_ordered.vitamin_d_level" />
          <Checkbox label="Hepatitis panel" model="data.care_coordination.lab_data_ordered.hepatitis_panel" />
          <Checkbox label="N/A" model="data.care_coordination.lab_data_ordered.not_applicable" />
          <Checkbox label="Other" model="data.care_coordination.lab_data_ordered.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.lab_data_ordered.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other:" model="data.care_coordination.lab_data_ordered.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Reason for polypharmacy/ above PDR recommended dosing',
    model: 'polypharmacy_pdr_dosing_reason',
    component: () => (
      <>
        <CheckboxGroup label="Reason for polypharmacy/ above PDR recommended dosing:" layout="vertical-dense">
          <Checkbox
            label="Insufficient effect of lower doses"
            model="data.care_coordination.polypharmacy_pdr_dosing_reason.insufficient_effect_of_lower_doses"
          />
          <Checkbox label="Patient preference" model="data.care_coordination.polypharmacy_pdr_dosing_reason.patient_preference" />
          <Checkbox
            label="Insufficient improvement on other protocols"
            model="data.care_coordination.polypharmacy_pdr_dosing_reason.insufficient_improvement_on_other_protocols"
          />
          <Checkbox label="Evidence based practice" model="data.care_coordination.polypharmacy_pdr_dosing_reason.evidence_based_practice" />
          <Checkbox label="N/A" model="data.care_coordination.polypharmacy_pdr_dosing_reason.not_applicable" />
          <Checkbox label="Other" model="data.care_coordination.polypharmacy_pdr_dosing_reason.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.polypharmacy_pdr_dosing_reason.other" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Other:"
            model="data.care_coordination.polypharmacy_pdr_dosing_reason.other_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Emergency procedures reviewed',
    model: 'emergency_procedures_reviewed',
    component: () => (
      <>
        <RadioGroup
          label="Emergency procedures reviewed:"
          model="data.care_coordination.emergency_procedures_reviewed"
          layout="vertical-dense"
        >
          <Radio label="Yes" value="yes" />
          <Radio label="No" value="no" />
          <Radio label="N/A" value="not_applicable" />
          <Radio label="Other" value="other" />
        </RadioGroup>

        <ContextShow when="data.care_coordination.emergency_procedures_reviewed" is="other">
          <SmartTextarea useQuickText useDictation label="Other:" model="data.care_coordination.emergency_procedures_reviewed_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Return to clinic',
    model: 'return_to_clinic',
    component: () => (
      <>
        <RadioGroup label="Return to clinic:" model="data.care_coordination.return_to_clinic" layout="vertical-dense">
          <Radio label="Days" value="days" />
          <Radio label="Weeks" value="weeks" />
          <Radio label="Months" value="months" />
          <Radio label="As needed" value="as_needed" />
          <Radio label="Other" value="other" />
        </RadioGroup>

        <ContextShow when="data.care_coordination.return_to_clinic" is="days">
          <Input
            label="Number of days"
            model="data.care_coordination.return_to_clinic_extra.number_days"
            suffix="days"
            type="number"
            size={4}
          />
        </ContextShow>

        <ContextShow when="data.care_coordination.return_to_clinic" is="weeks">
          <Input
            label="Number of weeks"
            model="data.care_coordination.return_to_clinic_extra.number_weeks"
            suffix="weeks"
            type="number"
            size={4}
          />
        </ContextShow>

        <ContextShow when="data.care_coordination.return_to_clinic" is="months">
          <Input
            label="Number of months"
            model="data.care_coordination.return_to_clinic_extra.number_months"
            suffix="months"
            type="number"
            size={4}
          />
        </ContextShow>

        <ContextShow when="data.care_coordination.return_to_clinic" is="other">
          <SmartTextarea useQuickText useDictation label="Other:" model="data.care_coordination.return_to_clinic_extra.other_extra" />
        </ContextShow>
      </>
    ),
  },
]

export const TREATMENT_HISTORY_FIELDS = [
  {
    label: 'Has the client been treated for mental illness?',
    model: 'mental_illness',
    component: () => (
      <>
        <RadioGroup
          label="Has the client been treated for mental illness?"
          model="data.treatment_history.mental_illness"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.treatment_history.mental_illness" is={true}>
          <Input label="How many times?" model="data.treatment_history.mental_illness_times" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client been treated for substance abuse issues?',
    model: 'substance_abuse_issues',
    component: () => (
      <>
        <RadioGroup
          label="Has the client been treated for substance abuse issues?"
          model="data.treatment_history.substance_abuse_issues"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.treatment_history.substance_abuse_issues" is={true}>
          <Input label="How many times?" model="data.treatment_history.substance_abuse_issues_times" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client been treated for an eating disorder?',
    model: 'eating_disorder',
    component: () => (
      <>
        <RadioGroup
          label="Has the client been treated for an eating disorder?"
          model="data.treatment_history.eating_disorder"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.treatment_history.eating_disorder" is={true}>
          <Input label="How many times?" model="data.treatment_history.eating_disorder_times" />
        </ContextShow>
      </>
    ),
  },
]

export const PAST_MEDICAL_HISTORY_FIELDS = [
  {
    label: 'Does the client have a history of medical issues?',
    model: 'has_history_of_medical_issues',
    component: () => (
      <>
        <RadioGroup
          label="Does the client have a history of medical issues?"
          model="data.past_medical_history.has_history_of_medical_issues"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.past_medical_history.has_history_of_medical_issues" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.past_medical_history.has_history_of_medical_issues_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever had surgery?',
    model: 'has_had_surgery',
    component: () => (
      <>
        <RadioGroup label="Has the client ever had surgery?" model="data.past_medical_history.has_had_surgery" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.past_medical_history.has_had_surgery" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list surgery, approximate date and reason:"
            model="data.past_medical_history.has_had_surgery_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever had an injury to their head or neck?',
    model: 'has_had_head_or_neck_injury',
    component: () => (
      <>
        <RadioGroup
          label="Has the client ever had an injury to their head or neck?"
          model="data.past_medical_history.has_had_head_or_neck_injury"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.past_medical_history.has_had_head_or_neck_injury" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list circumstance of injury and approximate date:"
            model="data.past_medical_history.has_had_head_or_neck_injury_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever had a seizure?',
    model: 'has_had_seizure',
    component: () => (
      <>
        <RadioGroup label="Has the client ever had a seizure?" model="data.past_medical_history.has_had_seizure" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.past_medical_history.has_had_seizure" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list circumstance of seizure and approximate date:"
            model="data.past_medical_history.has_had_seizure_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever had a positive TB test?',
    model: 'positive_tb_test',
    component: () => (
      <>
        <RadioGroup
          label="Has the client ever had a positive TB test?"
          model="data.past_medical_history.positive_tb_test"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.past_medical_history.positive_tb_test" is={true}>
          <RadioGroup label="Has the client ever had a chest X-ray?" model="data.past_medical_history.chest_xray" layout="horizontal-dense">
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>

          <ContextShow when="data.past_medical_history.chest_xray" is={true}>
            <SmartTextarea
              useQuickText
              useDictation
              label="What were the results of the chest Xray?"
              model="data.past_medical_history.chest_xray_extra"
            />
          </ContextShow>
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Biopsychosocial History',
    model: 'biopsychosocial_history',
    component: () => (
      <SmartTextarea useQuickText useDictation label="Biopsychosocial History:" model="data.past_medical_history.biopsychosocial_history" />
    ),
  },
  {
    label: 'Family & Psychosocial History (abuse, neglect, substance abuse issues)',
    model: 'family_biopsychosocial_history',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="Family & Psychosocial History (abuse, neglect, substance abuse issues):"
        model="data.past_medical_history.family_biopsychosocial_history"
      />
    ),
  },
  {
    label: 'Is there any history or current symptoms of the following?',
    model: 'symptoms',
    component: () => (
      <>
        <CheckboxGroup label="Is there any history or current symptoms of the following?" layout="vertical-dense">
          <Checkbox label="Weight loss" model="data.past_medical_history.symptoms.weight_loss" />
          <Checkbox label="Weight gain" model="data.past_medical_history.symptoms.weight_gain" />
          <Checkbox label="Decreased appetite" model="data.past_medical_history.symptoms.decreased_appetite" />
          <Checkbox label="Increased appetite" model="data.past_medical_history.symptoms.increased_appetite" />
          <Checkbox label="Decreased sleep" model="data.past_medical_history.symptoms.decreased_sleep" />
          <Checkbox label="Increased sleep" model="data.past_medical_history.symptoms.increased_sleep" />
          <Checkbox label="Crying spells" model="data.past_medical_history.symptoms.crying_spells" />
          <Checkbox label="Anhedonia" model="data.past_medical_history.symptoms.anhedonia" />
          <Checkbox label="Anergia" model="data.past_medical_history.symptoms.anergia" />
          <Checkbox label="Preoccupation" model="data.past_medical_history.symptoms.preoccupation" />
          <Checkbox label="Other" model="data.past_medical_history.symptoms.other" />
        </CheckboxGroup>

        <ContextShow when="data.past_medical_history.symptoms.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other:" model="data.past_medical_history.symptoms.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Are there concerning physical symptoms present in any of the following areas?',
    model: 'physical_symptoms',
    component: () => (
      <>
        <CheckboxGroup label="Are there concerning physical symptoms present in any of the following areas?" layout="vertical-dense">
          <Checkbox label="General" model="data.past_medical_history.physical_symptoms.general" />
          <Checkbox label="Dermatologic" model="data.past_medical_history.physical_symptoms.dermatologic" />
          <Checkbox label="Gastrointenstinal" model="data.past_medical_history.physical_symptoms.gastrointenstinal" />
          <Checkbox label="Cardiovascular" model="data.past_medical_history.physical_symptoms.cardiovascular" />
          <Checkbox label="Genitourinary" model="data.past_medical_history.physical_symptoms.genitourinary" />
          <Checkbox label="Musculoskeletal" model="data.past_medical_history.physical_symptoms.musculoskeletal" />
          <Checkbox label="Eyes/Ears/Nose" model="data.past_medical_history.physical_symptoms.eyes_ears_nose" />
          <Checkbox label="Hematological" model="data.past_medical_history.physical_symptoms.hematological" />
          <Checkbox label="Respiratory" model="data.past_medical_history.physical_symptoms.respiratory" />
          <Checkbox label="Throat/Mouth" model="data.past_medical_history.physical_symptoms.throat_mouth" />
          <Checkbox label="Other" model="data.past_medical_history.physical_symptoms.other" />
        </CheckboxGroup>

        <ContextShow when="data.past_medical_history.physical_symptoms.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other:" model="data.past_medical_history.physical_symptoms.other_extra" />
        </ContextShow>
      </>
    ),
  },
]

export const SUICIDE_SAFETY_RISK_FIELDS = [
  {
    label: 'Does the client appear secretive?',
    model: 'is_secretive',
    component: () => (
      <>
        <RadioGroup model="data.suicide_safety_risk.is_secretive" label="Does the client appear secretive?" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.suicide_safety_risk.is_secretive" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.suicide_safety_risk.is_secretive_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Does the client appear grandiose?',
    model: 'is_grandiose',
    component: () => (
      <>
        <RadioGroup model="data.suicide_safety_risk.is_grandiose" label="Does the client appear grandiose?" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.suicide_safety_risk.is_grandiose" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.suicide_safety_risk.is_grandiose_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Is the client experiencing any homicidal ideation?',
    model: 'has_homicidal_ideation',
    component: () => (
      <>
        <RadioGroup
          model="data.suicide_safety_risk.has_homicidal_ideation"
          label="Is the client experiencing any homicidal ideation?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.suicide_safety_risk.has_homicidal_ideation" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.suicide_safety_risk.has_homicidal_ideation_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Does the client feel hopeless and worthless at times?',
    model: 'is_feeling_hopeless',
    component: () => (
      <>
        <RadioGroup
          model="data.suicide_safety_risk.is_feeling_hopeless"
          label="Does the client feel hopeless and worthless at times?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.suicide_safety_risk.is_feeling_hopeless" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.suicide_safety_risk.is_feeling_hopeless_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Is the client experiencing racing thoughts?',
    model: 'has_racing_thoughts',
    component: () => (
      <>
        <RadioGroup
          label="Is the client experiencing racing thoughts?"
          model="data.suicide_safety_risk.has_racing_thoughts"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.suicide_safety_risk.has_racing_thoughts" is={true}>
          <Select label="To what degree? (0 absent - 10 severe)" model="data.suicide_safety_risk.has_racing_thoughts_degree">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
            <Option label="10" value={10} />
          </Select>
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Is the client experiencing physical pain?',
    model: 'has_physical_pain',
    component: () => (
      <>
        <RadioGroup
          label="Is the client experiencing physical pain?"
          model="data.suicide_safety_risk.has_physical_pain"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.suicide_safety_risk.has_physical_pain" is={true}>
          <Select label="To what degree? (0 absent - 10 severe)" model="data.suicide_safety_risk.has_physical_pain_degree">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
            <Option label="10" value={10} />
          </Select>
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Is the client experiencing negative self-talk?',
    model: 'has_negative_self_talk',
    component: () => (
      <>
        <RadioGroup
          label="Is the client experiencing negative self-talk?"
          model="data.suicide_safety_risk.has_negative_self_talk"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.suicide_safety_risk.has_negative_self_talk" is={true}>
          <Select label="To what degree? (0 absent - 10 severe)" model="data.suicide_safety_risk.has_negative_self_talk_degree">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
            <Option label="10" value={10} />
          </Select>
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Is the client experiencing suicidal thoughts?',
    model: 'has_suicidal_thoughts',
    component: () => (
      <>
        <RadioGroup
          label="Is the client experiencing suicidal thoughts?"
          model="data.suicide_safety_risk.has_suicidal_thoughts"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.suicide_safety_risk.has_suicidal_thoughts" is={true}>
          <Select label="To what degree? (0 absent - 10 severe)" model="data.suicide_safety_risk.has_suicidal_thoughts_degree">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
            <Option label="10" value={10} />
          </Select>
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Notes',
    model: 'notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.suicide_safety_risk.notes" />,
  },
]

export const COWS_SYMPTOMS_FIELDS = [
  {
    label: 'COWS Symptoms',
    model: 'cows_symptoms',
    component: () => <COWSSymptoms />,
  },
]

export const CIWA_SYMPTOMS_FIELDS = [
  {
    label: 'CIWA Symptoms',
    model: 'ciwa_symptoms',
    component: () => <CIWASymptoms />,
  },
]

export const PHYSICAL_EXAM_FIELDS = [
  {
    label: 'General Appearance',
    model: 'general_appearance',
    component: () => (
      <FormSection>
        <Heading is="h3">General Appearance</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="General Appearance" model="data.physical_exam.general.general_appearance" />
          <Checkbox label="Well developed" model="data.physical_exam.general.well_developed" />
          <Checkbox label="Well nourished" model="data.physical_exam.general.well_nourished" />
          <Checkbox label="Underweight" model="data.physical_exam.general.underweight" />
          <Checkbox label="Severely Malnourished" model="data.physical_exam.general.severely_malnourished" />
          <Checkbox label="Obese" model="data.physical_exam.general.obese" />
          <Checkbox label="Alert" model="data.physical_exam.general.alert" />
          <Checkbox label="Drowsy" model="data.physical_exam.general.drowsy" />
          <Checkbox label="Nodding Off" model="data.physical_exam.general.nodding_off" />
          <Checkbox label="Repeatedly falling asleep" model="data.physical_exam.general.repeatedly_falling_asleep" />
          <Checkbox label="Responsive to name" model="data.physical_exam.general.responsive_to_name" />
          <Checkbox label="Responsive to shaking" model="data.physical_exam.general.responsive_to_shaking" />
          <Checkbox label="Unresponsive/Comatose" model="data.physical_exam.general.unresponsive_comatose" />
          <Checkbox label="No apparent distress" model="data.physical_exam.general.no_apparent_distress" />
          <Checkbox label="Mild distress" model="data.physical_exam.general.mild_distress" />
          <Checkbox label="Moderate distress" model="data.physical_exam.general.moderate_distress" />
          <Checkbox label="Extremis" model="data.physical_exam.general.extremis" />
        </CheckboxGroup>

        <SmartTextarea
          useQuickText
          useDictation
          label="Additional commentary for General"
          model="data.physical_exam.general.additional_info"
        />
      </FormSection>
    ),
  },
  {
    label: 'Head',
    model: 'head',
    component: () => (
      <FormSection>
        <Heading is="h3">Head</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Normocephalic" model="data.physical_exam.head.normocephalic" />
          <Checkbox label="Atraumatic" model="data.physical_exam.head.atraumatic" />
          <Checkbox label="Bruising" model="data.physical_exam.head.bruising" />
          <Checkbox label="Abrasions" model="data.physical_exam.head.abrasions" />
          <Checkbox label="No facial erythema" model="data.physical_exam.head.no_facial_erythema" />
          <Checkbox label="Facial erythema" model="data.physical_exam.head.facial_erythema" />
          <Checkbox label="No facial edema" model="data.physical_exam.head.no_facial_edema" />
          <Checkbox label="Facial edema" model="data.physical_exam.head.facial_edema" />
        </CheckboxGroup>
        <SmartTextarea useQuickText useDictation label="Additional commentary for Head" model="data.physical_exam.head.additional_info" />
      </FormSection>
    ),
  },
  {
    label: 'Eyes',
    model: 'eyes',
    component: () => (
      <FormSection>
        <Heading is="h3">Eyes</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Eyes and lids symmetric" model="data.physical_exam.eyes.eyes_and_lids_symmetric" />
          <Checkbox label="No tearing" model="data.physical_exam.eyes.no_tearing" />
          <Checkbox label="Tearing" model="data.physical_exam.eyes.tearing" />
          <Checkbox label="Conjunctiva clear" model="data.physical_exam.eyes.conjunctiva_clear" />
          <Checkbox label="Conjuntiva injected" model="data.physical_exam.eyes.conjuntiva_injected" />
          <Checkbox label="Conjuntiva injected with exudate" model="data.physical_exam.eyes.conjuntiva_injected_with_exudate" />
          <Checkbox label="Pupils normal for ambient light" model="data.physical_exam.eyes.pupils_normal_for_ambient_light" />
          <Checkbox label="Mydriasis" model="data.physical_exam.eyes.mydriasis" />
          <Checkbox label="Miosis" model="data.physical_exam.eyes.miosis" />
          <Checkbox
            label="PERRLA - Pupils are equal, round and reactive to light and accommodation"
            model="data.physical_exam.eyes.perrla"
          />
          <Checkbox label="EOMI - Extra-ocular movements are intact" model="data.physical_exam.eyes.eomi" />
        </CheckboxGroup>
        <SmartTextarea useQuickText useDictation label="Additional commentary for Eyes" model="data.physical_exam.eyes.additional_info" />
      </FormSection>
    ),
  },
  {
    label: 'ENT',
    model: 'ent',
    component: () => (
      <FormSection>
        <Heading is="h3">ENT</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Nasal mucosa normal" model="data.physical_exam.ent.nasal_mucosa_normal" />
          <Checkbox label="Nasal mucosa red, swollen" model="data.physical_exam.ent.nasal_mucosa_red_swollen" />
          <Checkbox label="Nasal mucosa pale, swollen" model="data.physical_exam.ent.nasal_mucosa_pale_swollen" />
          <Checkbox label="Nasal septal defect" model="data.physical_exam.ent.nasal_septal_defect" />
          <Checkbox label="No rhinorrhea" model="data.physical_exam.ent.no_rhinorrhea" />
          <Checkbox label="Rhinorrhea" model="data.physical_exam.ent.rhinorrhea" />
          <Checkbox label="Good dentition" model="data.physical_exam.ent.good_dentition" />
          <Checkbox label="Poor dentition" model="data.physical_exam.ent.poor_dentition" />
          <Checkbox label="Uvula rises in midline" model="data.physical_exam.ent.uvula_rises_in_midline" />
          <Checkbox label="Tongue protrusion normal" model="data.physical_exam.ent.tongue_protrusion_normal" />
        </CheckboxGroup>
        <SmartTextarea useQuickText useDictation label="Additional commentary for ENT" model="data.physical_exam.ent.additional_info" />
      </FormSection>
    ),
  },
  {
    label: 'Neck',
    model: 'neck',
    component: () => (
      <FormSection>
        <Heading is="h3">Neck</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Nontender, No masses or adenopathy" model="data.physical_exam.neck.nontender_no_masses_or_adenopathy" />
          <Checkbox label="Nontender, no masses, normal size" model="data.physical_exam.neck.nontender_no_masses_normal_size" />
        </CheckboxGroup>
        <SmartTextarea useQuickText useDictation label="Additional commentary for Neck" model="data.physical_exam.neck.additional_info" />
      </FormSection>
    ),
  },
  {
    label: 'Respiratory/Chest',
    model: 'respiratory_chest',
    component: () => (
      <FormSection>
        <Heading is="h3">Respiratory/Chest</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Respirations regular" model="data.physical_exam.respiratory_chest.respirations_regular" />
          <Checkbox label="Respirations irregular" model="data.physical_exam.respiratory_chest.respirations_irregular" />
          <Checkbox label="No respiratory distress" model="data.physical_exam.respiratory_chest.no_respiratory_distress" />
          <Checkbox label="Mild respiratory distress" model="data.physical_exam.respiratory_chest.mild_respiratory_distress" />
          <Checkbox label="Moderate respiratory distress" model="data.physical_exam.respiratory_chest.moderate_respiratory_distress" />
          <Checkbox label="Clear to auscultation" model="data.physical_exam.respiratory_chest.clear_to_auscultation" />
          <Checkbox label="Diffuse rhonchi" model="data.physical_exam.respiratory_chest.diffuse_rhonchi" />
          <Checkbox label="Diffuse wheezes" model="data.physical_exam.respiratory_chest.diffuse_wheezes" />
          <Checkbox label="Basal rales" model="data.physical_exam.respiratory_chest.basal_rales" />
        </CheckboxGroup>
        <SmartTextarea
          useQuickText
          useDictation
          label="Additional commentary for Respiratory/Chest"
          model="data.physical_exam.respiratory_chest.additional_info"
        />
      </FormSection>
    ),
  },
  {
    label: 'Heart',
    model: 'heart',
    component: () => (
      <FormSection>
        <Heading is="h3">Heart</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Regular rhythm" model="data.physical_exam.heart.regular_rhythm" />
          <Checkbox label="Normal to auscultation" model="data.physical_exam.heart.normal_to_auscultation" />
          <Checkbox label="No peripheral edema" model="data.physical_exam.heart.no_peripheral_edema" />
        </CheckboxGroup>
        <SmartTextarea useQuickText useDictation label="Additional commentary for Heart" model="data.physical_exam.heart.additional_info" />
      </FormSection>
    ),
  },
  {
    label: 'Abdomen',
    model: 'abdomen',
    component: () => (
      <FormSection>
        <Heading is="h3">Abdomen</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Soft, nontender" model="data.physical_exam.abdomen.soft_nontender" />
          <Checkbox label="No masses" model="data.physical_exam.abdomen.no_masses" />
          <Checkbox label="No organomegaly" model="data.physical_exam.abdomen.no_organomegaly" />
        </CheckboxGroup>
        <SmartTextarea
          useQuickText
          useDictation
          label="Additional commentary for Abdomen"
          model="data.physical_exam.abdomen.additional_info"
        />
      </FormSection>
    ),
  },
  {
    label: 'Skin',
    model: 'skin',
    component: () => (
      <FormSection>
        <Heading is="h3">Skin</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="No rash" model="data.physical_exam.skin.no_rash" />
          <Checkbox label="No diaphoresis" model="data.physical_exam.skin.no_diaphoresis" />
          <Checkbox label="No lesions, cellulitis or abscesses" model="data.physical_exam.skin.no_lesions_cellulitis_or_abscesses" />
          <Checkbox label="Recent tract marks" model="data.physical_exam.skin.recent_tract_marks" />
          <Checkbox label="Normal temperature" model="data.physical_exam.skin.normal_temperature" />
        </CheckboxGroup>
        <SmartTextarea useQuickText useDictation label="Additional commentary for Skin" model="data.physical_exam.skin.additional_info" />
      </FormSection>
    ),
  },
  {
    label: 'Neuro',
    model: 'neuro',
    component: () => (
      <FormSection>
        <Heading is="h3">Neuro</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="CN II to XII intact" model="data.physical_exam.neuro.cn_ii_to_xii_intact" />
          <Checkbox label="No tremor or involuntary movement" model="data.physical_exam.neuro.no_tremor_or_involuntary_movement" />
          <Checkbox label="Gait normal" model="data.physical_exam.neuro.gait_normal" />
        </CheckboxGroup>
        <SmartTextarea useQuickText useDictation label="Additional commentary for Neuro" model="data.physical_exam.neuro.additional_info" />
      </FormSection>
    ),
  },
  {
    label: 'Physical Exam Comments',
    model: 'additional_info',
    component: () => <SmartTextarea useQuickText useDictation label="Physical Exam Comments" model="data.physical_exam.additional_info" />,
  },
]

export const VITAL_SIGNS_FIELDS = [
  {
    label: 'Date & Time',
    model: 'recorded_at',
    component: () => <DateTimeInput label="Date & Time" model="data.vital_signs.recorded_at" defaultToNow />,
  },
  {
    label: 'Blood Pressure',
    model: 'blood_pressure',
    component: () => (
      <Field label="Blood Pressure" labelWidth="120px" labelAlign="right">
        <Grid columns="repeat(3, max-content)" css={{ width: 'fit-content', alignItems: 'center' }}>
          <Input type="number" model="data.vital_signs.blood_pressure_systolic" size={4} />
          <div css={{ paddingLeft: '0.75rem', paddingRight: '0.75rem' }}>/</div>
          <Input type="number" model="data.vital_signs.blood_pressure_diastolic" suffix="mm Hg" size={4} />
        </Grid>
      </Field>
    ),
  },
  {
    label: 'Temperature',
    model: 'temperature',
    component: () => <Input label="Temp." type="number" model="data.vital_signs.temperature" suffix="°F" size={4} />,
  },
  {
    label: 'O2 Sat',
    model: 'oxygen_saturation',
    component: () => <Input label="O2 Sat" type="number" model="data.vital_signs.oxygen_saturation" suffix="%" size={4} />,
  },
  {
    label: 'Pulse',
    model: 'pulse',
    component: () => <Input label="Pulse" type="number" model="data.vital_signs.pulse" suffix="beats / min" size={4} />,
  },
  {
    label: 'Respiration',
    model: 'respiration',
    component: () => <Input label="Respiration" type="number" model="data.vital_signs.respiration" suffix="breaths / min" size={4} />,
  },
  {
    label: 'Pain Scale',
    model: 'pain_scale',
    component: () => (
      <Input label="Pain Scale" type="number" model="data.vital_signs.pain_scale" suffix="/ 10" step={1} min={0} max={10} size={4} />
    ),
  },
  {
    label: 'Weight',
    model: 'weight',
    component: () => <Input label="Weight" model="data.vital_signs.weight" suffix="pounds" size={4} />,
  },
  {
    label: 'Height',
    model: 'height',
    component: () => (
      <Field label="Height" labelWidth="120px" labelAlign="right">
        <Grid columns="repeat(2, max-content)" gapX={0.75} css={{ width: 'fit-content', alignItems: 'center' }}>
          <Input type="number" model="data.vital_signs.height_ft" suffix="ft" size={4} />
          <Input type="number" model="data.vital_signs.height_in" suffix="in" size={4} />
        </Grid>
      </Field>
    ),
  },
  {
    label: 'BMI',
    model: 'bmi',
    component: () => <Input label="BMI" model="data.vital_signs.bmi" size={10} />,
  },
  {
    label: 'Comments',
    model: 'comments',
    component: () => <SmartTextarea useQuickText useDictation label="Comments" model="data.vital_signs.comments" />,
  },
]

export const MEDICAL_SYSTEMS_REVIEW_FIELDS = [
  {
    label: 'Constitutional',
    model: 'constitutional',
    component: () => (
      <>
        <CheckboxGroup label="Constitutional:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.constitutional.none" />
          <Checkbox label="Flu-like illness" model="data.medical_systems_review.constitutional.flu_like_illness" />
          <Checkbox label="Dysphoria" model="data.medical_systems_review.constitutional.dysphoria" />
          <Checkbox label="Craving" model="data.medical_systems_review.constitutional.craving" />
          <Checkbox label="Sweats" model="data.medical_systems_review.constitutional.sweats" />
          <Checkbox label="Chills" model="data.medical_systems_review.constitutional.chills" />
          <Checkbox label="Insomnia" model="data.medical_systems_review.constitutional.insomnia" />
          <Checkbox label="Fatigue" model="data.medical_systems_review.constitutional.fatigue" />
          <Checkbox label="Weight loss" model="data.medical_systems_review.constitutional.weight_loss" />
          <Checkbox label="Weight gain" model="data.medical_systems_review.constitutional.weight_gain" />
          <Checkbox label="Other" model="data.medical_systems_review.constitutional.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.constitutional.other" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Other Constitutional:"
            model="data.medical_systems_review.constitutional.other_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Eyes',
    model: 'eyes',
    component: () => (
      <>
        <CheckboxGroup label="Eyes:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.eyes.none" />
          <Checkbox label="Tearing" model="data.medical_systems_review.eyes.tearing" />
          <Checkbox label="Photophobia" model="data.medical_systems_review.eyes.photophobia" />
          <Checkbox label="Other" model="data.medical_systems_review.eyes.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.eyes.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other Eyes:" model="data.medical_systems_review.eyes.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'ENT',
    model: 'ent',
    component: () => (
      <>
        <CheckboxGroup label="ENT:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.ent.none" />
          <Checkbox label="Rhinorrhea" model="data.medical_systems_review.ent.rhinorrhea" />
          <Checkbox label="Sneezing" model="data.medical_systems_review.ent.sneezing" />
          <Checkbox label="Nasal congestion" model="data.medical_systems_review.ent.nasal_congestion" />
          <Checkbox label="Dental pain/problems" model="data.medical_systems_review.ent.dental_pain_problems" />
          <Checkbox label="Other" model="data.medical_systems_review.ent.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.ent.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other ENT:" model="data.medical_systems_review.ent.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Cardiovascular',
    model: 'cardiovascular',
    component: () => (
      <>
        <CheckboxGroup label="Cardiovascular:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.cardiovascular.none" />
          <Checkbox label="Palpitations" model="data.medical_systems_review.cardiovascular.palpitations" />
          <Checkbox label="Tachycardia" model="data.medical_systems_review.cardiovascular.tachycardia" />
          <Checkbox label="Murmur" model="data.medical_systems_review.cardiovascular.murmur" />
          <Checkbox label="Heart disease" model="data.medical_systems_review.cardiovascular.heart_disease" />
          <Checkbox label="Chest pain" model="data.medical_systems_review.cardiovascular.chest_pain" />
          <Checkbox label="Other" model="data.medical_systems_review.cardiovascular.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.cardiovascular.other" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Other Cardiovascular:"
            model="data.medical_systems_review.cardiovascular.other_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Respiratory',
    model: 'respiratory',
    component: () => (
      <>
        <CheckboxGroup label="Respiratory:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.respiratory.none" />
          <Checkbox label="Asthma" model="data.medical_systems_review.respiratory.asthma" />
          <Checkbox label="Dyspena" model="data.medical_systems_review.respiratory.dyspena" />
          <Checkbox label="COPD" model="data.medical_systems_review.respiratory.copd" />
          <Checkbox label="Other" model="data.medical_systems_review.respiratory.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.respiratory.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other Respiratory:" model="data.medical_systems_review.respiratory.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Gastrointestinal',
    model: 'gastrointestinal',
    component: () => (
      <>
        <CheckboxGroup label="Gastrointestinal:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.gastrointestinal.none" />
          <Checkbox label="Nausea" model="data.medical_systems_review.gastrointestinal.nausea" />
          <Checkbox label="Diarrhea" model="data.medical_systems_review.gastrointestinal.diarrhea" />
          <Checkbox label="Abdominal cramping" model="data.medical_systems_review.gastrointestinal.abdominal_cramping" />
          <Checkbox label="GERD" model="data.medical_systems_review.gastrointestinal.gerd" />
          <Checkbox label="Vomiting blood" model="data.medical_systems_review.gastrointestinal.vomiting_blood" />
          <Checkbox label="Blood in stool" model="data.medical_systems_review.gastrointestinal.blood_in_stool" />
          <Checkbox label="Other" model="data.medical_systems_review.gastrointestinal.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.gastrointestinal.other" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Other Gastrointestinal:"
            model="data.medical_systems_review.gastrointestinal.other_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Genitourinary',
    model: 'genitourinary',
    component: () => (
      <>
        <CheckboxGroup label="Genitourinary:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.genitourinary.none" />
          <Checkbox label="Problem voiding" model="data.medical_systems_review.genitourinary.problem_voiding" />
          <Checkbox label="Kidney disease" model="data.medical_systems_review.genitourinary.kidney_disease" />
          <Checkbox label="Other" model="data.medical_systems_review.genitourinary.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.genitourinary.other" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Other Genitourinary:"
            model="data.medical_systems_review.genitourinary.other_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Musculoskeletal',
    model: 'musculoskeletal',
    component: () => (
      <>
        <CheckboxGroup label="Musculoskeletal:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.musculoskeletal.none" />
          <Checkbox label="Arthritis" model="data.medical_systems_review.musculoskeletal.arthritis" />
          <Checkbox label="Deformities" model="data.medical_systems_review.musculoskeletal.deformities" />
          <Checkbox label="Chronic pain" model="data.medical_systems_review.musculoskeletal.chronic_pain" />
          <Checkbox
            label="Withdrawal muscle or bone pain"
            model="data.medical_systems_review.musculoskeletal.withdrawal_muscle_or_bone_pain"
          />
          <Checkbox label="Other" model="data.medical_systems_review.musculoskeletal.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.musculoskeletal.other" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Other Musculoskeletal:"
            model="data.medical_systems_review.musculoskeletal.other_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Skin',
    model: 'skin',
    component: () => (
      <>
        <CheckboxGroup label="Skin:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.skin.none" />
          <Checkbox label="Piloerection" model="data.medical_systems_review.skin.piloerection" />
          <Checkbox label="Rash" model="data.medical_systems_review.skin.rash" />
          <Checkbox label="Infection/abscess" model="data.medical_systems_review.skin.infection_abscess" />
          <Checkbox label="Other" model="data.medical_systems_review.skin.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.skin.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other Skin:" model="data.medical_systems_review.skin.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Neuro',
    model: 'neuro',
    component: () => (
      <>
        <CheckboxGroup label="Neuro:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.neuro.none" />
          <Checkbox label="Yawning" model="data.medical_systems_review.neuro.yawning" />
          <Checkbox label="Tremor" model="data.medical_systems_review.neuro.tremor" />
          <Checkbox label="Gait" model="data.medical_systems_review.neuro.gait" />
          <Checkbox label="Myoclonic jerks" model="data.medical_systems_review.neuro.myoclonic_jerks" />
          <Checkbox label="Seizures" model="data.medical_systems_review.neuro.seizures" />
          <Checkbox label="Other" model="data.medical_systems_review.neuro.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.neuro.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other Neuro:" model="data.medical_systems_review.neuro.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Psych',
    model: 'psych',
    component: () => (
      <>
        <CheckboxGroup label="Psych:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.psych.none" />
          <Checkbox label="Restlessness" model="data.medical_systems_review.psych.restlessness" />
          <Checkbox label="Anxiety" model="data.medical_systems_review.psych.anxiety" />
          <Checkbox label="Fatigue and depression" model="data.medical_systems_review.psych.fatigue_and_depression" />
          <Checkbox label="DTS" model="data.medical_systems_review.psych.dts" />
          <Checkbox label="DTO" model="data.medical_systems_review.psych.dto" />
          <Checkbox label="Hallucinations" model="data.medical_systems_review.psych.hallucinations" />
          <Checkbox label="Phono-phobia" model="data.medical_systems_review.psych.phono_phobia" />
          <Checkbox label="Photophobia" model="data.medical_systems_review.psych.photophobia" />
          <Checkbox label="Other" model="data.medical_systems_review.psych.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.psych.other" is={true}>
          <SmartTextarea useQuickText useDictation label="Other Psych:" model="data.medical_systems_review.psych.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Allergies',
    model: 'allergies',
    component: () => (
      <>
        <RadioGroup label="Allergies" model="data.medical_systems_review.allergies.has_allergies" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.medical_systems_review.allergies.has_allergies" is={true}>
          <SmartTextarea useQuickText useDictation label="Please explain:" model="data.medical_systems_review.allergies.description" />
        </ContextShow>
      </>
    ),
  },
]

export const WITHDRAWAL_SYMPTOMS_FIELDS = [
  ...WITHDRAWAL_SYMPTOMS.map((symptom) => ({
    label: symptom.label,
    model: symptom.model,
    component: () => (
      <RadioGroup label={symptom.label} model={`data.withdrawal_symptoms.${symptom.model}`} layout="horizontal" defaultValue={null}>
        <Radio testKey={`${symptom.model}_low_radio_button`} label="Low" value="low" />
        <Radio testKey={`${symptom.model}_moderate_radio_button`} label="Moderate" value="moderate" />
        <Radio testKey={`${symptom.model}_severe_radio_button`} label="Severe" value="severe" />
      </RadioGroup>
    ),
  })),
  {
    label: "What is the client's current craving level? (1 = low, 10 = high)",
    model: 'craving_level',
    component: () => (
      <Select
        allowEmpty
        label="What is the client's current craving level? (1 = low, 10 = high)"
        model="data.withdrawal_symptoms.craving_level"
      >
        <Option label="1" value="1" />
        <Option label="2" value="2" />
        <Option label="3" value="3" />
        <Option label="4" value="4" />
        <Option label="5" value="5" />
        <Option label="6" value="6" />
        <Option label="7" value="7" />
        <Option label="8" value="8" />
        <Option label="9" value="9" />
        <Option label="10" value="10" />
      </Select>
    ),
  },
  {
    label: 'Notes',
    model: 'notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.withdrawal_symptoms.notes" />,
  },
]

export const SUBSTANCE_USE_HISTORY = UATests.map((substance) => {
  return {
    label: substance.name,
    model: substance.model,
    component: () => (
      <FormSection key={substance.model} heading={substance.name} layout="vertical">
        <RadioGroup
          testKey={`used_${snakeCase(substance.name)}_radio_group`}
          layout="horizontal-dense"
          label={`Has the client ever used ${substance.name?.toLowerCase()}?`}
          model={`data.substance_use_history.${substance.model}.used`}
          defaultValue={null}
        >
          <Radio testKey={`used_${snakeCase(substance.name)}_radio_button`} label="Yes" value={true} />
          <Radio testKey={`did_not_use_${snakeCase(substance.name)}_radio_button`} label="No" value={false} />
        </RadioGroup>

        <ContextShow when={`data.substance_use_history.${substance.model}.used`} is={true}>
          {['amphetamines', 'barbituates', 'benzodiazepines'].includes(substance.model) && (
            <SmartTextarea
              useQuickText
              useDictation
              testKey={`${snakeCase(substance.name)}_substance_type_input`}
              label="What types of substances has the client used?"
              model={`data.substance_use_history.${substance.model}.substance_type`}
            />
          )}

          <SmartTextarea
            useQuickText
            useDictation
            testKey={`${snakeCase(substance.name)}_acquire_method_input`}
            label="How did the client acquire this substance?"
            model={`data.substance_use_history.${substance.model}.acquire_method`}
          />

          {substance.is_prescriptible && (
            <>
              <YesNoRadioGroup
                testKey={`${snakeCase(substance.name)}_is_prescribed_radio_group`}
                label="Is this medication prescribed?"
                model={`data.substance_use_history.${substance.model}.is_prescribed`}
              />

              <ContextShow when={`data.substance_use_history.${substance.model}.is_prescribed`} is={true}>
                <YesNoRadioGroup
                  testKey={`${snakeCase(substance.name)}_is_following_prescription_directions`}
                  label="Is the client taking the substance as directed?"
                  model={`data.substance_use_history.${substance.model}.is_following_prescription_directions`}
                />
              </ContextShow>
            </>
          )}

          <Input
            type="number"
            testKey={`${snakeCase(substance.name)}_first_use_age_input`}
            label="At what age did the client use this substance for the first time?"
            suffix="y/o"
            size={4}
            model={`data.substance_use_history.${substance.model}.first_use_age`}
          />
          <Input
            type="number"
            testKey={`${snakeCase(substance.name)}_daily_use_age_input`}
            label="At what age did the client begin daily use of this substance?"
            suffix="y/o"
            size={4}
            model={`data.substance_use_history.${substance.model}.daily_use_age`}
          />

          <RadioGroup
            testKey={`${snakeCase(substance.name)}_use_frequency_radio_group`}
            label="During the client's heaviest use, how often did they use?"
            model={`data.substance_use_history.${substance.model}.use_frequency`}
            layout="vertical-dense"
          >
            <Radio testKey={`${snakeCase(substance.name)}_daily_use_frequency_radio_button`} label="Every day" value="daily" />
            <Radio
              testKey={`${snakeCase(substance.name)}_4_6_times_per_week_use_frequency_radio_button`}
              label="4-6 times per week"
              value="4_6_times_per_week"
            />
            <Radio
              testKey={`${snakeCase(substance.name)}_1_3_times_per_week_use_frequency_radio_button`}
              label="1-3 times per week"
              value="1_3_times_per_week"
            />
          </RadioGroup>

          <SmartTextarea
            useQuickText
            useDictation
            testKey={`${snakeCase(substance.name)}_heaviest_use_quantity_input`}
            label="During the client's heaviest use, how much did they use per day/week?"
            model={`data.substance_use_history.${substance.model}.use_quantity`}
          />

          <SmartTextarea
            useQuickText
            useDictation
            testKey={`${snakeCase(substance.name)}_current_use_frequency_input`}
            label="What is the client's current frequency of use?"
            model={`data.substance_use_history.${substance.model}.current_use_frequency`}
          />

          <SmartTextarea
            useQuickText
            useDictation
            testKey={`${snakeCase(substance.name)}_current_use_frequency_length_input`}
            label="For how long at this rate?"
            model={`data.substance_use_history.${substance.model}.current_use_frequency_length`}
          />

          <CheckboxGroup
            testKey={`${snakeCase(substance.name)}_method_of_use_checkbox_group`}
            label="What was the method of use?"
            layout="vertical-dense"
          >
            <Checkbox
              testKey={`${snakeCase(substance.name)}_use_method_oral`}
              label="Oral"
              model={`data.substance_use_history.${substance.model}.use_method.oral`}
            />
            <Checkbox
              testKey={`${snakeCase(substance.name)}_use_method_smoke`}
              label="Smoke"
              model={`data.substance_use_history.${substance.model}.use_method.smoke`}
            />
            <Checkbox
              testKey={`${snakeCase(substance.name)}_use_method_snort`}
              label="Snort"
              model={`data.substance_use_history.${substance.model}.use_method.snort`}
            />
            <Checkbox
              testKey={`${snakeCase(substance.name)}_use_method_inhalant`}
              label="Inhalant"
              model={`data.substance_use_history.${substance.model}.use_method.inhalant`}
            />
            <Checkbox
              testKey={`${snakeCase(substance.name)}_use_method_intravenous`}
              label="IV (intravenous)"
              model={`data.substance_use_history.${substance.model}.use_method.intravenous`}
            />
            <Checkbox
              testKey={`${snakeCase(substance.name)}_use_method_intramuscular`}
              label="IM (intramuscular)"
              model={`data.substance_use_history.${substance.model}.use_method.intramuscular`}
            />
          </CheckboxGroup>

          <RadioGroup
            layout="horizontal-dense"
            testKey={`${snakeCase(substance.name)}_last_use_date_known_radio_group`}
            label="Does the client know the exact date they last used this substance?"
            model={`data.substance_use_history.${substance.model}.last_use_date_known`}
          >
            <Radio testKey={`${snakeCase(substance.name)}_last_use_date_known`} label="Yes" value={true} />
            <Radio testKey={`${snakeCase(substance.name)}_last_use_date_unknown`} label="No" value={false} />
          </RadioGroup>

          <ContextShow when={`data.substance_use_history.${substance.model}.last_use_date_known`} is={true}>
            <DateInput
              testKey={`${snakeCase(substance.name)}_last_use_date_input`}
              label="Date of last use:"
              model={`data.substance_use_history.${substance.model}.last_use_date`}
            />
          </ContextShow>

          <ContextShow when={`data.substance_use_history.${substance.model}.last_use_date_known`} is={false}>
            <Input
              testKey={`${snakeCase(substance.name)}_last_use_date_approx_input`}
              label="Approximately how long ago?"
              model={`data.substance_use_history.${substance.model}.last_use_date_approx`}
            />
          </ContextShow>

          <Divider className="!m-0" />
        </ContextShow>
      </FormSection>
    ),
  }
})

export const MENTAL_STATUS_EXAM_FIELDS = [
  {
    label: 'Appearance',
    model: 'appearance',
    component: () => (
      <>
        <CheckboxGroup label="Appearance" layout="vertical-dense">
          <Checkbox label="Well groomed" model="data.mental_status.appearance.well_groomed" />
          <Checkbox label="Dresses in work attire" model="data.mental_status.appearance.dresses_in_work_attire" />
          <Checkbox label="Disheveled" model="data.mental_status.appearance.disheveled" />
          <Checkbox label="Inappropriate attire" model="data.mental_status.appearance.inappropriate" />
          <Checkbox label="Casual attire" model="data.mental_status.appearance.casual" />
          <Checkbox label="Unkempt" model="data.mental_status.appearance.unkempt" />
          <Checkbox label="Bizzarre" model="data.mental_status.appearance.bizzarre" />
          <Checkbox label="Other" model="data.mental_status.appearance.other" />
        </CheckboxGroup>
        <ContextShow when="data.mental_status.appearance.other" is={true}>
          <SmartTextarea useQuickText useDictation placeholder="Other" rows={2} model="data.mental_status.appearance.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Notes on Appearance',
    model: 'appearance_notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes on Appearance" model="data.mental_status.appearance.notes" />,
  },
  {
    label: 'Affect',
    model: 'affect',
    component: () => (
      <>
        <CheckboxGroup label="Affect" layout="vertical-dense">
          <Checkbox label="Normal" model="data.mental_status.affect.normal" />
          <Checkbox label="Congruent to mood" model="data.mental_status.affect.congruent_to_mood" />
          <Checkbox label="Constricted" model="data.mental_status.affect.constricted" />
          <Checkbox label="Blunted" model="data.mental_status.affect.blunted" />
          <Checkbox label="Flat" model="data.mental_status.affect.flat" />
          <Checkbox label="Inappropriate to content" model="data.mental_status.affect.inappropriate_to_content" />
          <Checkbox label="Labile" model="data.mental_status.affect.labile" />
          <Checkbox label="Stable" model="data.mental_status.affect.stable" />
          <Checkbox label="Other" model="data.mental_status.affect.other" />
        </CheckboxGroup>
        <ContextShow when="data.mental_status.affect.other" is={true}>
          <SmartTextarea useQuickText useDictation placeholder="Other" rows={2} model="data.mental_status.affect.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Notes on Affect',
    model: 'affect_notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes on Affect" model="data.mental_status.affect.notes" />,
  },
  {
    label: 'Thought Process',
    model: 'thought_process',
    component: () => (
      <>
        <CheckboxGroup label="Thought Process" layout="vertical-dense">
          <Checkbox label="Logical and sequential" model="data.mental_status.thought_process.logical_and_sequential" />
          <Checkbox label="Flight of ideas" model="data.mental_status.thought_process.flight_of_ideas" />
          <Checkbox label="Goal directed" model="data.mental_status.thought_process.goal_directed" />
          <Checkbox label="Circumstantial" model="data.mental_status.thought_process.circumstantial" />
          <Checkbox label="Loose associations" model="data.mental_status.thought_process.loose_associations" />
          <Checkbox label="Thought blocking" model="data.mental_status.thought_process.thought_blocking" />
          <Checkbox label="Word salad" model="data.mental_status.thought_process.word_salad" />
          <Checkbox label="Fragmented" model="data.mental_status.thought_process.fragmented" />
          <Checkbox label="Other" model="data.mental_status.thought_process.other" />
        </CheckboxGroup>
        <ContextShow when="data.mental_status.thought_process.other" is={true}>
          <SmartTextarea useQuickText useDictation placeholder="Other" rows={2} model="data.mental_status.thought_process.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Notes on Thought Process',
    model: 'thought_process_notes',
    component: () => (
      <SmartTextarea useQuickText useDictation label="Notes on Thought Process" model="data.mental_status.thought_process.notes" />
    ),
  },
  {
    label: 'Judgment and Insight',
    model: 'judgement_and_insight',
    component: () => (
      <>
        <CheckboxGroup label="Judgment and Insight" layout="vertical-dense">
          <Checkbox label="Good" model="data.mental_status.judgement_and_insight.good" />
          <Checkbox label="Fair" model="data.mental_status.judgement_and_insight.fair" />
          <Checkbox label="Impaired by illness" model="data.mental_status.judgement_and_insight.impaired_by_illness" />
          <Checkbox label="Poor" model="data.mental_status.judgement_and_insight.poor" />
          <Checkbox label="Denies illness" model="data.mental_status.judgement_and_insight.denies_illness" />
          <Checkbox label="Good insight" model="data.mental_status.judgement_and_insight.good_insight" />
          <Checkbox label="Adequate insight" model="data.mental_status.judgement_and_insight.adequate_insight" />
          <Checkbox label="Limited insight" model="data.mental_status.judgement_and_insight.limited_insight" />
          <Checkbox label="Other" model="data.mental_status.judgement_and_insight.other" />
        </CheckboxGroup>
        <ContextShow when="data.mental_status.judgement_and_insight.other" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            placeholder="Other"
            rows={2}
            model="data.mental_status.judgement_and_insight.other_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Notes on Judgment and Insight',
    model: 'judgement_and_insight_notes',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="Notes on Judgment and Insight"
        model="data.mental_status.judgement_and_insight.notes"
      />
    ),
  },
  {
    label: 'Behavior',
    model: 'behavior',
    component: () => (
      <>
        <CheckboxGroup label="Behavior" layout="vertical-dense">
          <Checkbox label="Calm" model="data.mental_status.behavior.calm" />
          <Checkbox label="Relaxed" model="data.mental_status.behavior.relaxed" />
          <Checkbox label="Tics" model="data.mental_status.behavior.tics" />
          <Checkbox label="Agitated" model="data.mental_status.behavior.agitated" />
          <Checkbox label="Poor eye contact" model="data.mental_status.behavior.poor_eye_contact" />
          <Checkbox label="Rigidity" model="data.mental_status.behavior.rigidity" />
          <Checkbox label="Restless" model="data.mental_status.behavior.restless" />
          <Checkbox label="Tremors" model="data.mental_status.behavior.tremors" />
          <Checkbox label="Unsteady gait" model="data.mental_status.behavior.unsteady_gait" />
          <Checkbox label="Hyperactive" model="data.mental_status.behavior.hyperactive" />
          <Checkbox label="Tearful" model="data.mental_status.behavior.tearful" />
          <Checkbox label="Other" model="data.mental_status.behavior.other" />
        </CheckboxGroup>
        <ContextShow when="data.mental_status.behavior.other" is={true}>
          <SmartTextarea useQuickText useDictation placeholder="Other" rows={2} model="data.mental_status.behavior.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Notes on Behavior',
    model: 'behavior_notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes on Behavior" model="data.mental_status.behavior.notes" />,
  },
  {
    label: 'Mood',
    model: 'mood',
    component: () => (
      <>
        <CheckboxGroup label="Mood" layout="vertical-dense">
          <Checkbox label="Euthymic" model="data.mental_status.mood.euthymic" />
          <Checkbox label="Depressed" model="data.mental_status.mood.depressed" />
          <Checkbox label="Fearful" model="data.mental_status.mood.fearful" />
          <Checkbox label="Anxious" model="data.mental_status.mood.anxious" />
          <Checkbox label="Euphoric" model="data.mental_status.mood.euphoric" />
          <Checkbox label="Dysphoric" model="data.mental_status.mood.dysphoric" />
          <Checkbox label="Stable" model="data.mental_status.mood.stable" />
          <Checkbox label="Angry/Irritable" model="data.mental_status.mood.angry_irritable" />
          <Checkbox label="Ashamed" model="data.mental_status.mood.ashamed" />
          <Checkbox label="Hostile" model="data.mental_status.mood.hostile" />
          <Checkbox label="Hopeless" model="data.mental_status.mood.hopeless" />
          <Checkbox label="Other" model="data.mental_status.mood.other" />
        </CheckboxGroup>
        <ContextShow when="data.mental_status.mood.other" is={true}>
          <SmartTextarea useQuickText useDictation placeholder="Other" rows={2} model="data.mental_status.mood.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Notes on Mood',
    model: 'mood_notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes on Mood" model="data.mental_status.mood.notes" />,
  },
  {
    label: 'Speech',
    model: 'speech',
    component: () => (
      <>
        <CheckboxGroup label="Speech" layout="vertical-dense">
          <Checkbox label="Clear" model="data.mental_status.speech.clear" />
          <Checkbox label="Coherent" model="data.mental_status.speech.coherent" />
          <Checkbox label="Dysarthric" model="data.mental_status.speech.dysarthric" />
          <Checkbox label="Emotional" model="data.mental_status.speech.emotional" />
          <Checkbox label="Hesitant" model="data.mental_status.speech.hesitant" />
          <Checkbox label="Loud" model="data.mental_status.speech.loud" />
          <Checkbox label="Pressured" model="data.mental_status.speech.pressured" />
          <Checkbox label="Monotonous" model="data.mental_status.speech.monotonous" />
          <Checkbox label="Rapid" model="data.mental_status.speech.rapid" />
          <Checkbox label="Slurred" model="data.mental_status.speech.slurred" />
          <Checkbox label="Soft" model="data.mental_status.speech.soft" />
          <Checkbox label="Spontaneous" model="data.mental_status.speech.spontaneous" />
          <Checkbox label="Difficult to understand" model="data.mental_status.speech.difficult_to_understand" />
          <Checkbox label="Other" model="data.mental_status.speech.other" />
        </CheckboxGroup>
        <ContextShow when="data.mental_status.speech.other" is={true}>
          <SmartTextarea useQuickText useDictation placeholder="Other" rows={2} model="data.mental_status.speech.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Notes on Speech',
    model: 'speech_notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes on Speech" model="data.mental_status.speech.notes" />,
  },
  {
    label: 'Thought Content',
    model: 'thought_content',
    component: () => (
      <>
        <CheckboxGroup label="Thought Content" layout="vertical-dense">
          <Checkbox label="Paranoia" model="data.mental_status.thought_content.paranoia" />
          <Checkbox label="Delusions" model="data.mental_status.thought_content.delusions" />
          <Checkbox label="Compulsions" model="data.mental_status.thought_content.compulsions" />
          <Checkbox label="Suicidal ideations" model="data.mental_status.thought_content.suicidal_ideations" />
          <Checkbox label="Homicidal ideations" model="data.mental_status.thought_content.homicidal_ideations" />
          <Checkbox label="Thoughts of dying" model="data.mental_status.thought_content.thoughts_of_dying" />
          <Checkbox label="Hallucinations" model="data.mental_status.thought_content.hallucinations" />
          <Checkbox label="Preoccupied" model="data.mental_status.thought_content.preoccupied" />
          <Checkbox label="Thought derailment" model="data.mental_status.thought_content.thought_derailment" />
          <Checkbox label="Impulsivity" model="data.mental_status.thought_content.impulsivity" />
          <Checkbox label="Indifference" model="data.mental_status.thought_content.indifference" />
          <Checkbox label="Other" model="data.mental_status.thought_content.other" />
        </CheckboxGroup>
        <ContextShow when="data.mental_status.thought_content.other" is={true}>
          <SmartTextarea useQuickText useDictation placeholder="Other" rows={2} model="data.mental_status.thought_content.other_extra" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Notes on Thought Content',
    model: 'thought_content_notes',
    component: () => (
      <SmartTextarea useQuickText useDictation label="Notes on Thought Content" model="data.mental_status.thought_content.notes" />
    ),
  },
  {
    label: 'Cognition and Orientation',
    model: 'cognition_and_orientation',
    component: () => (
      <>
        <CheckboxGroup label="Cognition and Orientation" layout="vertical-dense">
          <Checkbox label="Alert" model="data.mental_status.cognition_and_orientation.alert" />
          <Checkbox label="Able to abstract" model="data.mental_status.cognition_and_orientation.able_to_abstract" />
          <Checkbox
            label="Able to maintain concentration"
            model="data.mental_status.cognition_and_orientation.able_to_maintain_concentration"
          />
          <Checkbox label="Distracted" model="data.mental_status.cognition_and_orientation.distracted" />
          <Checkbox label="Immediate recall intact" model="data.mental_status.cognition_and_orientation.immediate_recall_intact" />
          <Checkbox label="Recent recall intact" model="data.mental_status.cognition_and_orientation.recent_recall_intact" />
          <Checkbox label="Above average intelligence" model="data.mental_status.cognition_and_orientation.above_average_intelligence" />
          <Checkbox label="Below average intelligence" model="data.mental_status.cognition_and_orientation.below_average_intelligence" />
          <Checkbox label="Oriented to person" model="data.mental_status.cognition_and_orientation.oriented_to_person" />
          <Checkbox label="Oriented to time" model="data.mental_status.cognition_and_orientation.oriented_to_time" />
          <Checkbox label="Oriented to place" model="data.mental_status.cognition_and_orientation.oriented_to_place" />
          <Checkbox label="Oriented to situation" model="data.mental_status.cognition_and_orientation.oriented_to_situation" />
          <Checkbox label="Other" model="data.mental_status.cognition_and_orientation.other" />
        </CheckboxGroup>
        <ContextShow when="data.mental_status.cognition_and_orientation.other" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            placeholder="Other"
            rows={2}
            model="data.mental_status.cognition_and_orientation.other_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Notes on Cognition and Orientation',
    model: 'cognition_and_orientation_notes',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="Notes on Cognition and Orientation"
        model="data.mental_status.cognition_and_orientation.notes"
      />
    ),
  },
]

export const SNAP_FIELDS = [
  {
    label: 'S.N.A.P. form',
    model: 'snap_form',
    component: ({ isEditable }: any) => <SNAP isEditable={isEditable} />,
  },
]

export const SUICIDE_RISK_DOCUMENTATION_FIELDS = [
  {
    label: 'Suicide Risk Level',
    model: 'suicide_risk_level',
    component: () => (
      <RadioGroup model="data.suicide_risk_documentation.risk_level" label="Suicide Risk Level" layout="horizontal">
        <Radio label="High Risk" value="high" />
        <Radio label="Moderate Risk" value="moderate" />
        <Radio label="Low Risk" value="low" />
      </RadioGroup>
    ),
  },
  {
    label: 'Your Clinical Observation',
    model: 'clinical_observation',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="Your Clinical Observation"
        model="data.suicide_risk_documentation.clinical_observation"
      />
    ),
  },
  {
    label: 'Relevant Mental Status Information',
    model: 'mental_status_information',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="Relevant Mental Status Information"
        model="data.suicide_risk_documentation.mental_status_information"
      />
    ),
  },
  {
    label: 'Methods of Suicide Risk Evaluation',
    model: 'methods_of_suicide_risk_evaluation',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="Methods of Suicide Risk Evaluation"
        model="data.suicide_risk_documentation.methods_of_suicide_risk_evaluation"
      />
    ),
  },
  {
    label: 'Nutrition referral needed?',
    model: 'needs_nutrition_referral',
    component: () => (
      <YesNoRadioGroup label="Nutrition referral needed?" model="data.suicide_risk_documentation.does_require_nutrition_referral" />
    ),
  },
  {
    label: 'Brief Evaluation Summary',
    model: 'brief_evaluation_summary',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="Brief Evaluation Summary"
        description="Please include Warning Signs, Risk Indicators, Protective Factors, Access to Lethal Means, Collateral Sources Used and Relevant Information Obtained, Specific Assessment Data to Support Risk Determination, Rationale for Actions Taken and Not Taken"
        model="data.suicide_risk_documentation.evaluation_summary"
      />
    ),
  },
  {
    label: 'Provision of Crisis Line 1-800-273-TALK(8255)',
    model: 'provision_of_crisis_line',
    component: () => (
      <Checkbox
        trueIcon="tick"
        falseIcon="cross"
        falseStyle="faded"
        label="Provision of Crisis Line 1-800-273-TALK(8255)"
        model="data.suicide_risk_documentation.provision_of_crisis_line"
      />
    ),
  },
  {
    label: 'Implementation of Safety Plan (If Applicable)',
    model: 'safety_plan_implementation',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="Implementation of Safety Plan (If Applicable)"
        model="data.suicide_risk_documentation.safety_plan"
      />
    ),
  },
]

export const LEVEL_OF_RISK_AND_INTERVENTIONS_FIELDS = [
  {
    label: 'Level of Risk and Interventions Form',
    model: 'level_of_risk_and_interventions_form',
    component: () => <LevelOfRiskAndInterventions />,
  },
]

export const PROTECTIVE_FACTORS_FIELDS = [
  {
    label: 'Internal',
    model: 'internal',
    component: () => (
      <CheckboxGroup label="Internal" layout="vertical-dense" trueIcon="tick">
        <Checkbox label="Ability to cope with stress" model="data.protective_factors.internal.ability_to_cope_with_stress" />
        <Checkbox label="Frustration tolerance" model="data.protective_factors.internal.frustration_tolerance" />
        <Checkbox label="Religious beliefs" model="data.protective_factors.internal.religious_beliefs" />
        <Checkbox label="Fear of death or the actual act of killing self" model="data.protective_factors.internal.fear_of_death" />
        <Checkbox label="Identifies reasons for living" model="data.protective_factors.internal.identifies_reasons_for_living" />
      </CheckboxGroup>
    ),
  },
  {
    label: 'External',
    model: 'external',
    component: () => (
      <CheckboxGroup label="External" layout="vertical-dense" trueIcon="tick">
        <Checkbox
          label="Cultural, spiritual and/or moral attitudes against suicide"
          model="data.protective_factors.external.cultural_spiritual_moral_attitudes_against_suicide"
        />
        <Checkbox label="Responsibility to children" model="data.protective_factors.external.responsibility_to_children" />
        <Checkbox label="Beloved pets" model="data.protective_factors.external.beloved_pets" />
        <Checkbox
          label="Supportive social network of family or friends"
          model="data.protective_factors.external.supportive_social_network"
        />
        <Checkbox label="Positive therapeutic relationships" model="data.protective_factors.external.positive_relationships" />
        <Checkbox label="Engaged in work or school" model="data.protective_factors.external.engaged_in_work_or_school" />
      </CheckboxGroup>
    ),
  },
  {
    label: 'Notes',
    model: 'notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.protective_factors.notes" />,
  },
]

export const RISK_FACTORS_FIELDS = [
  {
    label: 'C-SSRS Suicidal Ideation Severity',
    model: 'suicidal_ideation_severity',
    component: () => (
      <>
        <CSSRSScale />

        <CheckboxGroup
          label="C-SSRS Suicidal Ideation Severity"
          layout="vertical-dense"
          trueIcon="check"
          falseIcon="cross"
          falseStyle="faded"
        >
          <Checkbox
            reverse
            label="1. Wish to be dead"
            description="Has the client wished they were dead or wished they could go to sleep and not wake up?"
            model="data.risk_factors.suicidal_ideation_severity.wish_to_be_dead"
          />
          <Checkbox
            reverse
            label="2. Current suicidal thoughts"
            description="Has the client actually had any thoughts of killing themselves?"
            model="data.risk_factors.suicidal_ideation_severity.current_suicidal_thoughts"
          />
          <Checkbox
            reverse
            label="3.	Suicidal thoughts w/ Method (w/no specific Plan or Intent or act)"
            description="Has the client been thinking about how they might do this?"
            model="data.risk_factors.suicidal_ideation_severity.suicidal_thoughts"
          />
          <Checkbox
            reverse
            label="4.	Suicidal Intent without Specific Plan"
            description="Has the client had these thoughts and had some intention of acting on them?"
            model="data.risk_factors.suicidal_ideation_severity.suicidal_intent"
          />
          <Checkbox
            reverse
            label="5.	Intent with Plan"
            description="Has the client started to work out or worked out the details of how to kill themselves? Do they intend to carry out this plan?"
            model="data.risk_factors.suicidal_ideation_severity.intent_with_plan"
          />
        </CheckboxGroup>
      </>
    ),
  },
  {
    label:
      'C-SSRS Suicidal Behavior: Has the client ever done anything, started to do anything, or prepared to do anything to end their life?',
    model: 'suicidal_behavior',
    component: () => (
      <>
        <RadioGroup
          model="data.risk_factors.suicidal_behavior"
          label="6. C-SSRS Suicidal Behavior: Has the client ever done anything, started to do anything, or prepared to do anything to end their life?"
          description="Examples: 
    Collecting pills for the purpose of overdose.
    Obtaining a gun or other lethal means. Giving away valuables or personal belongings.
    Writing a will or a suicide note.
    Holding a gun or engaging in self-harm but changing one's mind or having the weapon taken away.
    Going to a high place with the intention of jumping but deciding not to.
    Actively attempting suicide through the ingestion of pills, attempting to shoot oneself, self-cutting, or attempting hanging. etc."
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.risk_factors.suicidal_behavior" is={true}>
          <RadioGroup
            model="data.risk_factors.suicidal_behavior_past_three_months"
            label="Was it within the past 3 months?"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Current and Past Psychiatric Dx',
    model: 'psychiatric_dx',
    component: () => (
      <CheckboxGroup label="Current and Past Psychiatric Dx" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
        <Checkbox label="Mood disorder" model="data.risk_factors.psychiatric_dx.mood_disorder" />
        <Checkbox label="Psychotic disorder" model="data.risk_factors.psychiatric_dx.psychotic_disorder" />
        <Checkbox label="Alcohol/substance abuse disorders" model="data.risk_factors.psychiatric_dx.alcohol_substance_abuse_disorders" />
        <Checkbox label="PTSD" model="data.risk_factors.psychiatric_dx.ptsd" />
        <Checkbox label="ADHD" model="data.risk_factors.psychiatric_dx.adhd" />
        <Checkbox label="TBI" model="data.risk_factors.psychiatric_dx.tbi" />
        <Checkbox
          label="Cluster B Personality disorders or traits (i.e., Borderline, Antisocial, Histrionic, Narcissistic)"
          model="data.risk_factors.psychiatric_dx.cluster_b_personality_disorders"
        />
        <Checkbox
          label="Conduct problems (antisocial behavior, aggression, impulsivity)"
          model="data.risk_factors.psychiatric_dx.conduct_problems"
        />
        <Checkbox label="Recent onset" model="data.risk_factors.psychiatric_dx.recent_onset" />
      </CheckboxGroup>
    ),
  },
  {
    label: 'Presenting Symptoms',
    model: 'presenting_symptoms',
    component: () => (
      <CheckboxGroup label="Presenting Symptoms" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
        <Checkbox label="Anhedonia" model="data.risk_factors.presenting_symptoms.anhedonia" />
        <Checkbox label="Impulsivity" model="data.risk_factors.presenting_symptoms.impulsivity" />
        <Checkbox label="Hopelessness or despair" model="data.risk_factors.presenting_symptoms.hopelessness_or_despair" />
        <Checkbox label="Anxiety and/or panic" model="data.risk_factors.presenting_symptoms.anxiety_and_or_panic" />
        <Checkbox label="Insomnia" model="data.risk_factors.presenting_symptoms.insomnia" />
        <Checkbox label="Command hallucinations" model="data.risk_factors.presenting_symptoms.command_hallucinations" />
        <Checkbox label="Psychosis" model="data.risk_factors.presenting_symptoms.psychosis" />
      </CheckboxGroup>
    ),
  },
  {
    label: 'Family History',
    model: 'family_history',
    component: () => (
      <CheckboxGroup label="Family History" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
        <Checkbox label="Suicide" model="data.risk_factors.family_history.suicide" />
        <Checkbox label="Suicidal behavior" model="data.risk_factors.family_history.suicidal_behavior" />
        <Checkbox label="Axis I psychiatric diagnoses requiring hospitalization" model="data.risk_factors.family_history.axis" />
      </CheckboxGroup>
    ),
  },
  {
    label: 'Precipitants / Stressors',
    model: 'precipitants_stressors',
    component: () => (
      <CheckboxGroup label="Precipitants / Stressors" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
        <Checkbox
          label="Triggering events leading to humiliation, shame, and/or despair (e.g. Loss of relationship, financial or health status) (real or anticipated)"
          model="data.risk_factors.precipitants.triggering_events"
        />
        <Checkbox
          label="Chronic physical pain or other acute medical problem (e.g. CNS disorders)"
          model="data.risk_factors.precipitants.chronic_physical_pain"
        />
        <Checkbox label="Sexual/physical abuse" model="data.risk_factors.precipitants.sexual_physical_abuse" />
        <Checkbox label="Substance intoxication or withdrawal" model="data.risk_factors.precipitants.substance_intoxication" />
        <Checkbox label="Pending incarceration or homelessness" model="data.risk_factors.precipitants.pending_incarceration" />
        <Checkbox label="Legal problems" model="data.risk_factors.precipitants.legal_problems" />
        <Checkbox label="Inadequate social supports" model="data.risk_factors.precipitants.inadequate_social_supports" />
        <Checkbox label="Social isolation" model="data.risk_factors.precipitants.social_isolation" />
        <Checkbox label="Perceived burden on others" model="data.risk_factors.precipitants.perceived_burden" />
      </CheckboxGroup>
    ),
  },
  {
    label: 'Change in treatment',
    model: 'change_in_treatment',
    component: () => (
      <CheckboxGroup label="Change in treatment" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
        <Checkbox label="Recent inpatient discharge" model="data.risk_factors.change_in_treatment.recent_inpatient_discharge" />
        <Checkbox
          label="Change in provider or treatment (i.e., medications, psychotherapy, milieu)"
          model="data.risk_factors.change_in_treatment.change_in_provider_or_treatment"
        />
        <Checkbox
          label="Hopeless or dissatisfied with provider or treatment"
          model="data.risk_factors.change_in_treatment.hopeless_or_dissatisfied"
        />
        <Checkbox
          label="Non-compliant or not receiving treatment"
          model="data.risk_factors.change_in_treatment.non_compliant_or_not_receiving_treatment"
        />
      </CheckboxGroup>
    ),
  },
  {
    label: 'Access to lethal methods',
    model: 'access_to_lethal_methods',
    component: () => (
      <CheckboxGroup label="Access to lethal methods" trueIcon="check" falseIcon="cross" falseStyle="faded">
        <Checkbox
          label="Ask specifically about presence or absence of a firearm in the home or ease of accessing"
          model="data.risk_factors.access_to_lethal_methods"
        />
      </CheckboxGroup>
    ),
  },
  {
    label: 'Notes',
    model: 'notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.risk_factors.notes" />,
  },
]

export const RECOVERY_ENVIRONMENT_FIELDS = [
  {
    label: 'Who primarily raised the client?',
    model: 'primary_raiser',
    component: () => <Input label="Who primarily raised the client?" model="data.recovery_environment.who_raised_you" />,
  },
  {
    label: 'Does the client get along with their parents and/or siblings?',
    model: 'is_getting_along_with_parents_and_siblings',
    component: () => (
      <>
        <RadioGroup
          label="Does the client get along with their parents and/or siblings?"
          model="data.recovery_environment.is_getting_along_with_parents_and_siblings"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.recovery_environment.is_getting_along_with_parents_and_siblings" is={false}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.recovery_environment.is_getting_along_with_parents_and_siblings_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label:
      'Where was the client living prior to coming here? If transferred from another facility, please specify where they lived prior to treatment',
    model: 'living_prior_coming',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="Where was the client living prior to coming here? If transferred from another facility, please specify where they lived prior to treatment."
        model="data.recovery_environment.living_prior_coming"
      />
    ),
  },
  {
    label: 'Did any of the people the client lived with use drugs/alcohol?',
    model: 'have_others_you_lived_with_used',
    component: () => (
      <>
        <RadioGroup
          label="Did any of the people the client lived with use drugs/alcohol?"
          model="data.recovery_environment.have_others_you_lived_with_used"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.recovery_environment.have_others_you_lived_with_used" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.recovery_environment.have_others_you_lived_with_used_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Does the client have children?',
    model: 'has_children',
    component: () => (
      <>
        <RadioGroup label="Does the client have children?" model="data.recovery_environment.has_children" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.recovery_environment.has_children" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Number of children, ages and genders:"
            model="data.recovery_environment.has_children_number"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
          <SmartTextarea
            useQuickText
            useDictation
            label="Where do they live?"
            model="data.recovery_environment.has_children_location"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Is the client employed?',
    model: 'is_employed',
    component: () => (
      <>
        <RadioGroup label="Is the client employed?" model="data.recovery_environment.is_employed" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.recovery_environment.is_employed" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Employer and type of work:"
            model="data.recovery_environment.employer_and_work"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
          <RadioGroup
            label="Is the client on approved leave from their job?"
            model="data.recovery_environment.approved_leave_from_job"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>

          <RadioGroup
            label="Does the client plan to return to this job?"
            model="data.recovery_environment.plan_to_return_to_job"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>
        </ContextShow>

        <ContextShow when="data.recovery_environment.is_employed" is={false}>
          <SmartTextarea
            useQuickText
            useDictation
            label="How long has the client been unemployed?"
            model="data.recovery_environment.how_long_unemployed"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever been arrested?',
    model: 'has_been_arrested',
    component: () => (
      <>
        <RadioGroup
          label="Has the client ever been arrested?"
          model="data.recovery_environment.has_been_arrested"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.recovery_environment.has_been_arrested" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list past arrests, charges and results of charges:"
            model="data.recovery_environment.arrests_and_charges"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Does the client have any pending charges or upcoming court dates?',
    model: 'has_pending_charges_or_upcoming_court_dates',
    component: () => (
      <>
        <RadioGroup
          label="Does the client have any pending charges or upcoming court dates?"
          model="data.recovery_environment.has_pending_charges_or_upcoming_court_dates"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.recovery_environment.has_pending_charges_or_upcoming_court_dates" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list:"
            model="data.recovery_environment.has_pending_charges_or_upcoming_court_dates_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever been incarcerated?',
    model: 'has_been_incarcerated',
    component: () => (
      <>
        <RadioGroup
          label="Has the client ever been incarcerated?"
          model="data.recovery_environment.has_been_incarcerated"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.recovery_environment.has_been_incarcerated" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list incarcerations (include location and length of incarceration):"
            model="data.recovery_environment.has_been_incarcerated_list"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Name of parole or probation officer, if applicable',
    model: 'parole_or_probation_officer',
    component: () => (
      <Input label="Name of parole or probation officer, if applicable:" model="data.recovery_environment.parole_or_probation_officer" />
    ),
  },
  {
    label: 'Is the client currently employed?',
    model: 'is_currently_employed',
    component: () => (
      <YesNoRadioGroupWithTextarea
        label="Is the client currently employed?"
        model="data.recovery_environment.is_currently_employed"
        textareaLabel="By whom is the client employed, and is their position secure while they're in treatment?"
      />
    ),
  },
  {
    label: 'Employment History',
    model: 'employment_history',
    component: ({ isEditable }: any) => (
      <>
        <Grid gap="0.25rem">
          <Label label="Please provide information about the client's employment history?" />

          <DataArray model="data.recovery_environment.employment_history">
            {({ orderedIds, add, remove }: any) => (
              <Grid gap="0.8rem">
                {orderedIds.map((id: any, index: number) => (
                  <Card key={id}>
                    <CardHeader
                      after={
                        isEditable && (
                          <Button
                            size={200}
                            type="minimal"
                            glyph="delete"
                            color="red"
                            testKey={`delete_employment_history_${index + 1}`}
                            label="Remove"
                            display="inline-flex"
                            onClick={(event: any) => {
                              event.preventDefault()
                              remove(id)
                            }}
                          />
                        )
                      }
                    >
                      <CardTitle title={`Employment #${index + 1}`} />
                    </CardHeader>

                    <CardContent>
                      <FormSection>
                        <Input testKey={`input_employment_${index + 1}_history_employer`} label="Employer" model={`${id}.employer`} />
                        <Input testKey={`input_employment_${index + 1}_history_dates`} label="Dates" model={`${id}.dates`} />
                        <Input
                          testKey={`input_employment_${index + 1}_history_position`}
                          label="Type of position"
                          model={`${id}.position`}
                        />
                        <SmartTextarea
                          useQuickText
                          useDictation
                          testKey={`input_employment_${index + 1}_reason_for_leaving`}
                          label="Reason for leaving"
                          model={`${id}.reason_for_leaving`}
                        />
                      </FormSection>
                    </CardContent>
                  </Card>
                ))}

                {isEditable && (
                  <div>
                    <Button label="Add Employment" size={200} glyph="add" onClick={add} display="inline-flex" />
                  </div>
                )}
              </Grid>
            )}
          </DataArray>
        </Grid>
      </>
    ),
  },
  {
    label: 'Notes',
    model: 'notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.recovery_environment.notes" />,
  },
]

export const RELAPSE_POTENTIAL_FIELDS = [
  {
    label: 'Current Progress Barriers',
    model: 'current_progress_barriers',
    component: () => (
      <>
        <CheckboxGroup layout="vertical-dense" label="Current Progress Barriers:">
          <Checkbox label="Anxiety" model="data.relapse_potential.anxiety" />
          <Checkbox label="Anger management" model="data.relapse_potential.anger_management" />
          <Checkbox label="Impulsivity" model="data.relapse_potential.impulsivity" />
          <Checkbox label="Depression" model="data.relapse_potential.depression" />
          <Checkbox
            label="Home environment not conducive to recovery"
            model="data.relapse_potential.home_environment_not_conducive_to_recovery"
          />
          <Checkbox label="Poor engagement" model="data.relapse_potential.poor_engagement" />
          <Checkbox label="Unresolved trauma" model="data.relapse_potential.unresolved_trauma" />
          <Checkbox label="Inability to admit past trauma" model="data.relapse_potential.inability_to_admit_past_trauma" />
          <Checkbox label="Limited insight" model="data.relapse_potential.limited_insight" />
          <Checkbox label="Lack of sober support" model="data.relapse_potential.lack_of_sober_support" />
          <Checkbox label="Non-compliance with prior treatment" model="data.relapse_potential.non_compliance_with_prior_treatment" />
          <Checkbox label="Defiant attitude" model="data.relapse_potential.defiant_attitude" />
          <Checkbox label="Family dysfunction" model="data.relapse_potential.family_dysfunction" />
          <Checkbox label="Co-occurring disorders" model="data.relapse_potential.co_occurring_disorders" />
          <Checkbox label="Poor judgment" model="data.relapse_potential.poor_judgment" />
          <Checkbox label="Difficulty managing distractions" model="data.relapse_potential.difficulty_managing_distractions" />
          <Checkbox label="Limited knowledge of recovery" model="data.relapse_potential.limited_knowledge_of_recovery" />
          <Checkbox
            label="Non-compliance with prescribed psychotropic medication"
            model="data.relapse_potential.non_compliance_with_prescribed_psychotropic_medication"
          />
          <Checkbox label="Denial" model="data.relapse_potential.denial" />
          <Checkbox label="Co-dependence issues" model="data.relapse_potential.co_dependence_issues" />
          <Checkbox label="Other" model="data.relapse_potential.other" />
        </CheckboxGroup>

        <ContextShow when="data.relapse_potential.other" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Other"
            model="data.relapse_potential.other_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever tried to quit using drugs/alcohol on their own?',
    model: 'has_tried_using_on_their_own',
    component: () => (
      <>
        <RadioGroup
          label="Has the client ever tried to quit using drugs/alcohol on their own?"
          model="data.relapse_potential.has_tried_using_on_their_own"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.relapse_potential.has_tried_using_on_their_own" is={true}>
          <Input label="How many times?" model="data.relapse_potential.has_tried_using_on_their_own_times" size={4} suffix="times" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client had any significant periods of sobriety, either on their own or with treatment?',
    model: 'has_significant_lengths_of_sobriety',
    component: () => (
      <>
        <RadioGroup
          label="Has the client had any significant periods of sobriety, either on their own or with treatment?"
          model="data.relapse_potential.has_significant_lengths_of_sobriety"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.relapse_potential.has_significant_lengths_of_sobriety" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please describe the reasons the client believes these attempts at sobriety failed."
            model="data.relapse_potential.has_significant_lengths_of_sobriety_reasons"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Is the client aware of any situations or feelings that they think would provoke a relapse?',
    model: 'has_relapse_feelings',
    component: () => (
      <>
        <RadioGroup
          label="Is the client aware of any situations or feelings that they think would provoke a relapse?"
          model="data.relapse_potential.has_relapse_feelings"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.relapse_potential.has_relapse_feelings" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.relapse_potential.has_relapse_feelings_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Is the client interested in anti-craving medication?',
    model: 'is_interested_in_anti_craving_medication',
    component: () => (
      <>
        <RadioGroup
          label="Is the client interested in anti-craving medication?"
          model="data.relapse_potential.is_interested_in_anti_craving_medication"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.relapse_potential.is_interested_in_anti_craving_medication" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="What medications would the client consider?"
            model="data.relapse_potential.anti_craving_medications_considered"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <ContextShow when="data.relapse_potential.is_interested_in_anti_craving_medication" is={false}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Why not?"
            model="data.relapse_potential.reason_for_no_interest_in_anti_craving_medication"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Does the client identify with any of the following addictive behaviors?',
    model: 'addictive_behaviors',
    component: () => (
      <>
        <Grid gap="0.25rem">
          <h4 style={{ marginTop: '0.5rem', fontSize: '1.05rem' }}>
            Does the client identify with any of the following addictive behaviors?
          </h4>

          <Grid gap="0.75rem" className="!pl-4">
            <YesNoRadioGroupWithTextarea
              label="Gambling"
              model="data.relapse_potential.addictive_behaviors.gambling"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />

            <YesNoRadioGroupWithTextarea
              label="Food"
              model="data.relapse_potential.addictive_behaviors.food"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />

            <YesNoRadioGroupWithTextarea
              label="Exercise"
              model="data.relapse_potential.addictive_behaviors.exercise"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />

            <YesNoRadioGroupWithTextarea
              label="Sex"
              model="data.relapse_potential.addictive_behaviors.sex"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />

            <YesNoRadioGroupWithTextarea
              label="Codependency"
              model="data.relapse_potential.addictive_behaviors.codependency"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />

            <YesNoRadioGroupWithTextarea
              label="Self-injurious behaviors"
              model="data.relapse_potential.addictive_behaviors.self_injurious_behaviors"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />

            <YesNoRadioGroupWithTextarea
              label="Caffeine"
              model="data.relapse_potential.addictive_behaviors.caffeine"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />

            <YesNoRadioGroupWithTextarea
              label="Nicotine"
              model="data.relapse_potential.addictive_behaviors.nicotine"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />

            <YesNoRadioGroupWithTextarea
              label="Shopping"
              model="data.relapse_potential.addictive_behaviors.shopping"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />

            <YesNoRadioGroupWithTextarea
              label="Gaming"
              model="data.relapse_potential.addictive_behaviors.gaming"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />

            <YesNoRadioGroupWithTextarea
              label="Work"
              model="data.relapse_potential.addictive_behaviors.work"
              textareaLabel="What challenges or consequences has the client faced as a result?"
            />
          </Grid>
        </Grid>
      </>
    ),
  },
  {
    label: 'Notes',
    model: 'notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.relapse_potential.notes" />,
  },
]

export const MOTIVATION_FOR_CHANGE_FIELDS = [
  {
    label: 'Contemplation stage?',
    model: 'contemplation_stage',
    component: () => (
      <CheckboxGroup label="Contemplation stage?" layout="vertical-dense">
        <Checkbox label="Precontemplation" model="data.motivation_for_change.contemplation_stage.precontemplation" />
        <Checkbox label="Contemplation" model="data.motivation_for_change.contemplation_stage.contemplation" />
        <Checkbox label="Preparation" model="data.motivation_for_change.contemplation_stage.preparation" />
        <Checkbox label="Action" model="data.motivation_for_change.contemplation_stage.action" />
        <Checkbox label="Maintenance" model="data.motivation_for_change.contemplation_stage.maintenance" />
      </CheckboxGroup>
    ),
  },
  {
    label: "What are the client's internal factors (for example, client wants to change but doesn't know how)?",
    model: 'internal_factors',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="What are the client's internal factors (for example, client wants to change but doesn't know how)?"
        model="data.motivation_for_change.internal_factors"
      />
    ),
  },
  {
    label: "What are the client's external factors?",
    model: 'external_factors',
    component: () => (
      <>
        <CheckboxGroup label="What are the client's external factors?" layout="vertical-dense">
          <Checkbox label="Unemployment" model="data.motivation_for_change.external_factors.unemployment" />
          <Checkbox label="Financial Strain" model="data.motivation_for_change.external_factors.financial_strain" />
          <Checkbox label="Legal Issues" model="data.motivation_for_change.external_factors.legal_issues" />
          <Checkbox label="Job Jeopardy" model="data.motivation_for_change.external_factors.job_jeopardy" />
          <Checkbox label="Family Strain" model="data.motivation_for_change.external_factors.family_strain" />
          <Checkbox label="Environmental / Location" model="data.motivation_for_change.external_factors.environmental_location" />
        </CheckboxGroup>

        <SmartTextarea
          useQuickText
          useDictation
          label="Notes on external factors"
          model="data.motivation_for_change.external_factors_extra"
        />
      </>
    ),
  },
  {
    label: 'Engagement in Sessions',
    model: 'engagement_in_sessions',
    component: () => (
      <>
        <CheckboxGroup label="Engagement in Sessions:" layout="vertical-dense">
          <Checkbox label="Fully engaged" model="data.motivation_for_change.engagement.fully_engaged" />
          <Checkbox label="Requires frequent prompting" model="data.motivation_for_change.engagement.requires_frequent_prompting" />
          <Checkbox label="Provides appropriate feedback" model="data.motivation_for_change.engagement.provides_appropriate_feedback" />
          <Checkbox label="Inappropriate" model="data.motivation_for_change.engagement.inappropriate" />
          <Checkbox label="Attentive" model="data.motivation_for_change.engagement.attentive" />
          <Checkbox label="Interrupts" model="data.motivation_for_change.engagement.interrupts" />
          <Checkbox label="Deceptive" model="data.motivation_for_change.engagement.deceptive" />
          <Checkbox label="Late more than twice per week" model="data.motivation_for_change.engagement.late_more_than_twice_per_week" />
          <Checkbox label="Distracted" model="data.motivation_for_change.engagement.distracted" />
          <Checkbox label="On time" model="data.motivation_for_change.engagement.on_time" />
          <Checkbox label="Honest" model="data.motivation_for_change.engagement.honest" />
          <Checkbox label="Completes assignments" model="data.motivation_for_change.engagement.completes_assignments" />
          <Checkbox label="Respectful" model="data.motivation_for_change.engagement.respectful" />
          <Checkbox label="Vulnerable" model="data.motivation_for_change.engagement.vulnerable" />
          <Checkbox label="Aggressive" model="data.motivation_for_change.engagement.aggressive" />
          <Checkbox label="Poor eye contact" model="data.motivation_for_change.engagement.poor_eye_contact" />
          <Checkbox label="Restless" model="data.motivation_for_change.engagement.restless" />
          <Checkbox label="Invested" model="data.motivation_for_change.engagement.invested" />
          <Checkbox label="Falling asleep" model="data.motivation_for_change.engagement.falling_asleep" />
          <Checkbox label="Avoidant" model="data.motivation_for_change.engagement.avoidant" />
        </CheckboxGroup>

        <SmartTextarea useQuickText useDictation placeholder="Other" model="data.motivation_for_change.engagement.other" rows={2} />
      </>
    ),
  },
  {
    label: "Pease list client's involvement with 12 step meetings or community",
    model: 'client_involvement_with_12_step_meetings_or_community',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="Please list client's involvement with 12 step meetings or community:"
        model="data.motivation_for_change.client_involvement_with_12_step_meetings_or_community"
      />
    ),
  },
  {
    label: 'Does the client have a sponsor?',
    model: 'has_sponsor',
    component: () => (
      <>
        <RadioGroup label="Does the client have a sponsor?" model="data.motivation_for_change.has_sponsor" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.motivation_for_change.has_sponsor" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="When was the client's last meeting with a sponsor and what step are they currently on?"
            model="data.motivation_for_change.last_time_met_with_sponsor_and_what_step_at"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <ContextShow when="data.motivation_for_change.has_sponsor" is={false}>
          <SmartTextarea
            useQuickText
            useDictation
            label="What is the plan for obtaining a sponsor? Include deadline, if one exists:"
            model="data.motivation_for_change.plan_for_obtaining_sponsor"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Does the client identify with any particular religion or spiritual group?',
    model: 'does_identify_with_religious_or_spiritual_group',
    component: () => (
      <YesNoRadioGroupWithTextarea
        label="Does the client identify with any particular religion or spiritual group?"
        model="data.motivation_for_change.does_identify_with_religious_or_spiritual_group"
        textareaLabel="Which one?"
      />
    ),
  },
  {
    label:
      'Are there any religious, cultural, or spiritual needs that the client would like to be taken into consideration to enhance their treatment experience?',
    model: 'religious_or_spiritual_needs',
    component: () => (
      <YesNoRadioGroupWithTextarea
        label="Are there any religious, cultural, or spiritual needs that the client would like to be taken into consideration to enhance their treatment experience?"
        model="data.motivation_for_change.religious_or_spiritual_needs"
      />
    ),
  },
  {
    label:
      "How has the client's value system (i.e., honesty, integrity, respect, etc.) been impacted by their substance use, and what are the related personal consequences (i.e., decreased self-worth, shame, guilt)?",
    model: 'substance_use_impact_on_value_system',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="How has the client's value system (i.e., honesty, integrity, respect, etc.) been impacted by their substance use, and what are the related personal consequences (i.e., decreased self-worth, shame, guilt)?"
        model="data.motivation_for_change.substance_use_impact_on_value_system"
      />
    ),
  },
  {
    label: "List client's short and long term goals",
    model: 'client_goals',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="List client's short and long term goals:"
        model="data.motivation_for_change.client_goals"
      />
    ),
  },
  {
    label: 'What leisure activities and/or hobbies does the client enjoy?',
    model: 'leisure_activities_and_or_hobbies',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="What leisure activities and/or hobbies does the client enjoy?"
        model="data.motivation_for_change.leisure_activities_and_or_hobbies"
      />
    ),
  },
  {
    label: "How has the client's substance use affected their ability to participate in these activities/hobbies?",
    model: 'usage_affection_on_activities_and_hobbies',
    component: () => (
      <SmartTextarea
        useQuickText
        useDictation
        label="How has the client's substance use affected their ability to participate in these activities/hobbies?"
        model="data.motivation_for_change.usage_affection_on_activities_and_hobbies"
      />
    ),
  },
  {
    label: 'Notes',
    model: 'notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.motivation_for_change.notes" />,
  },
]

export const COGNITIVE_EMOTIONAL_BEHAVIORAL_FIELDS = [
  {
    label: 'Has the client ever been hospitalized for mental illness?',
    model: 'has_ben_hospitalized_for_mental_illness',
    component: () => (
      <>
        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_ben_hospitalized_for_mental_illness"
          label="Has the client ever been hospitalized for mental illness?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_ben_hospitalized_for_mental_illness" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list the reason, duration and location of treatment:"
            model="data.cognitive_emotional_behavioral.has_ben_hospitalized_for_mental_illness_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever suffered from an eating disorder?',
    model: 'has_suffered_eating_disorder',
    component: () => (
      <>
        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_suffered_eating_disorder"
          label="Has the client ever suffered from an eating disorder?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_suffered_eating_disorder" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list dates and duration of symptoms:"
            model="data.cognitive_emotional_behavioral.has_suffered_eating_disorder_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever been the victim of physical abuse?',
    model: 'has_suffered_physical_abuse',
    component: () => (
      <>
        {' '}
        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_suffered_physical_abuse"
          label="Has the client ever been the victim of physical abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
        <ContextShow when="data.cognitive_emotional_behavioral.has_suffered_physical_abuse" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.has_suffered_physical_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever been the victim of emotional abuse?',
    model: 'has_been_victim_of_emotional_abuse',
    component: () => (
      <>
        {' '}
        <RadioGroup
          model="data.cognitive_emotional_behavioral.is_victim_of_emotional_abuse"
          label="Has the client ever been the victim of emotional abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_emotional_abuse" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.is_victim_of_emotional_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever been the victim of verbal abuse?',
    model: 'has_been_victim_of_verbal_abuse',
    component: () => (
      <>
        <RadioGroup
          model="data.cognitive_emotional_behavioral.is_victim_of_verbal_abuse"
          label="Has the client ever been the victim of verbal abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_verbal_abuse" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.is_victim_of_verbal_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever been the victim of sexual abuse?',
    model: 'has_been_victim_of_sexual_abuse',
    component: () => (
      <>
        <RadioGroup
          model="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse"
          label="Has the client ever been the victim of sexual abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever been a victim of exploitation?',
    model: 'has_been_victim_of_exploit',
    component: () => (
      <>
        <YesNoRadioGroup
          label="Has the client ever been a victim of exploitation?"
          model="data.cognitive_emotional_behavioral.is_victim_of_exploit"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_exploit" is={true}>
          <Select
            label="What type of abuse has the client been a victim of?"
            model="data.cognitive_emotional_behavioral.is_victim_of_exploit_type"
          >
            <Option label="Sexually" value="sexually" />
            <Option label="Drugs and alcohol" value="drugs_and_alcohol" />
            <Option label="Economic" value="economic" />
          </Select>

          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.cognitive_emotional_behavioral.is_victim_of_exploit_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever been a part of an environmental trauma?',
    model: 'has_been_part_of_environmental_trauma',
    component: () => (
      <YesNoRadioGroupExtra
        label="Has the client ever been a part of an environmental trauma?"
        model="data.cognitive_emotional_behavioral.is_part_of_environmental_trauma"
      />
    ),
  },
  {
    label: 'Has the client ever been a victim of domestic violence?',
    model: 'has_been_victim_of_domestic_violence',
    component: () => (
      <YesNoRadioGroupExtra
        label="Has the client ever been a victim of domestic violence?"
        model="data.cognitive_emotional_behavioral.is_victim_of_domestic_violence"
      />
    ),
  },
  {
    label: 'Has the client ever been a victim of neglect?',
    model: 'has_been_victim_of_neglect',
    component: () => (
      <YesNoRadioGroupExtra
        label="Has the client ever been a victim of neglect?"
        model="data.cognitive_emotional_behavioral.is_victim_of_neglect"
      />
    ),
  },
  {
    label: 'Has the client ever experienced a traumatic death or loss?',
    model: 'has_experienced_traumatic_death_loss',
    component: () => (
      <YesNoRadioGroupExtra
        label="Has the client ever experienced a traumatic death or loss?"
        model="data.cognitive_emotional_behavioral.has_experienced_traumatic_death_loss"
      />
    ),
  },
  {
    label: 'Has the client ever tried to harm another person?',
    model: 'has_harmed_another_person',
    component: () => (
      <>
        {' '}
        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_harmed_another_person"
          label="Has the client ever tried to harm another person?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
        <ContextShow when="data.cognitive_emotional_behavioral.has_harmed_another_person" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.cognitive_emotional_behavioral.has_harmed_another_person_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Does the client have a history of causing harm to animals?',
    model: 'has_animals_harm_history',
    component: () => (
      <>
        <YesNoRadioGroup
          label="Does the client have a history of causing harm to animals?"
          model="data.cognitive_emotional_behavioral.has_animals_harm_history"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.has_animals_harm_history" is={true}>
          <Input label="Last episode:" model="data.cognitive_emotional_behavioral.has_animals_harm_history_last_episode" />
          <SmartTextarea
            useQuickText
            useDictation
            label="Please describe:"
            model="data.cognitive_emotional_behavioral.has_animals_harm_history_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Does the client have a history of setting fires?',
    model: 'has_setting_fires_history',
    component: () => (
      <>
        <YesNoRadioGroup
          label="Does the client have a history of setting fires?"
          model="data.cognitive_emotional_behavioral.has_setting_fires_history"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.has_setting_fires_history" is={true}>
          <Input label="Last episode:" model="data.cognitive_emotional_behavioral.has_setting_fires_history_last_episode" />
          <SmartTextarea
            useQuickText
            useDictation
            label="Please describe:"
            model="data.cognitive_emotional_behavioral.has_setting_fires_history_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: "Is there a history of mental illness in the client's family?",
    model: 'has_family_mental_illness_history',
    component: () => (
      <>
        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_family_mental_illness_history"
          label="Is there a history of mental illness in the client's family?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_family_mental_illness_history" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list family member and explain:"
            model="data.cognitive_emotional_behavioral.has_family_mental_illness_history_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: "Is there a history of substance abuse in the client's family?",
    model: 'has_family_substance_abuse_history',
    component: () => (
      <>
        <YesNoRadioGroup
          label="Is there a history of substance abuse in the client's family?"
          model="data.cognitive_emotional_behavioral.has_family_substance_abuse_history"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.has_family_substance_abuse_history" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Family Member(s)"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.family_members"
          />

          <YesNoRadioGroup
            label="Are they actively using now?"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.actively_using"
          />
          <SmartTextarea
            useQuickText
            useDictation
            label="More Details"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.actively_using_extra"
          />

          <YesNoRadioGroup
            label="Are they in recovery?"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.in_recovery"
          />
          <SmartTextarea
            useQuickText
            useDictation
            label="More Details"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.in_recovery_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: "Is there a history of suicide in the client's family?",
    model: 'has_family_suicide_history',
    component: () => (
      <>
        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_family_suicide_history"
          label="Is there a history of suicide in the client's family?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_family_suicide_history" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.cognitive_emotional_behavioral.has_family_suicide_history_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Mental Status Form',
    model: 'mental_status_form',
    component: () => <MentalStatusForm />,
  },
  {
    label: 'Notes',
    model: 'notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.cognitive_emotional_behavioral.notes" />,
  },
]

export const BIO_MEDICAL_FIELDS = [
  ...VITAL_SIGNS_FIELDS,
  {
    label: 'Will the client be seen by a psychologist, psychiatrist or other outside physician during treatment?',
    model: 'will_be_seen_by_physician',
    component: () => (
      <>
        <RadioGroup
          label="Will the client be seen by a psychologist, psychiatrist or other outside physician during treatment?"
          model="data.bio_medical.assignments.has_appointments"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.bio_medical.assignments.has_appointments" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list provider name and reason for appointment:"
            model="data.bio_medical.assignments.provider_name_and_reason"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />

          <RadioGroup
            label="Has an appointment already been scheduled?"
            model="data.bio_medical.assignments.is_physician_appointment_scheduled"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>

          <ContextShow when="data.bio_medical.assignments.is_physician_appointment_scheduled" is={true}>
            <SmartTextarea
              useQuickText
              useDictation
              label="Please list provider name and date of appointment:"
              model="data.bio_medical.assignments.provider_name_and_appointment"
              validations={{
                presence: {
                  message: 'Please provide this information',
                },
              }}
            />
          </ContextShow>
        </ContextShow>
      </>
    ),
  },
  {
    label: "What is the general condition of the client's health?",
    model: 'general_health_condition',
    component: () => (
      <RadioGroup
        model="data.bio_medical.general_condition"
        label="What is the general condition of the client's health?"
        layout="horizontal"
      >
        <Radio label="Poor" value="poor" />
        <Radio label="Average" value="average" />
        <Radio label="Good" value="good" />
      </RadioGroup>
    ),
  },
  {
    label: 'Does the client have any ongoing medical conditions? (i.e. asthma, high blood pressure, diabetes, etc.)',
    model: 'has_ongoing_conditions',
    component: () => (
      <Input
        model="data.bio_medical.ongoing_conditions"
        label="Does the client have any ongoing medical conditions? (i.e. asthma, high blood pressure, diabetes, etc.)"
      />
    ),
  },
  {
    label: 'Does the client have any undiagnosed medical complaints?',
    model: 'has_undiagnosed_complaints',
    component: () => (
      <Input model="data.bio_medical.undiagnosed_medical_complaints" label="Does the client have any undiagnosed medical complaints?" />
    ),
  },
  {
    label: 'Is the client experiencing any chronic physical pain?',
    model: 'has_chronic_physical_pain',
    component: () => (
      <>
        <YesNoRadioGroup label="Is the client experiencing any chronic physical pain?" model="data.bio_medical.has_chronic_physical_pain" />

        <ContextShow when="data.bio_medical.has_chronic_physical_pain" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label=""
            placeholder="Refer to get a chronic pain physical assessment"
            model="data.bio_medical.has_chronic_physical_pain_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever had a seizure as a result of substance abuse?',
    model: 'had_had_seizures',
    component: () => (
      <>
        <RadioGroup
          model="data.bio_medical.had_seizure"
          label="Has the client ever had a seizure as a result of substance abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.bio_medical.had_seizure" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="In the box list the circumstance that precipitated the seizure, the amount of episodes, approximate date(s) and if hospitalization was needed:"
            model="data.bio_medical.had_seizure_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: "Has the client ever experienced DT's (delirium tremens)?",
    model: 'has_experienced_dts',
    component: () => (
      <>
        <RadioGroup
          model="data.bio_medical.had_dts"
          label="Has the client ever experienced DT's (delirium tremens)?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.bio_medical.had_dts" is={true}>
          <CheckboxGroup label="Check all that apply:" layout="vertical-dense">
            <Checkbox label="Tremors" model="data.bio_medical.dts.dt_tremors" />
            <Checkbox label="Nightmares" model="data.bio_medical.dts.dt_nightmares" />
            <Checkbox label="Confusion" model="data.bio_medical.dts.dt_confusion" />
            <Checkbox label="Paranoia" model="data.bio_medical.dts.dt_paranoia" />
            <Checkbox label="Other" model="data.bio_medical.dts.dt_other" />
          </CheckboxGroup>
        </ContextShow>

        <ContextShow when="data.bio_medical.dts.dt_other" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Other"
            model="data.bio_medical.dts.dt_other_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client ever overdosed?',
    model: 'has_overdosed',
    component: () => (
      <>
        <RadioGroup model="data.bio_medical.has_overdosed" label="Has the client ever overdosed?" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.bio_medical.has_overdosed" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="What substance did the client overdose on?"
            model="data.bio_medical.has_overdosed_on"
          />

          <SmartTextarea
            useQuickText
            useDictation
            label="How many times has the client experienced an overdose?"
            model="data.bio_medical.has_overdosed_frequency"
          />

          <YesNoRadioGroupExtra
            label="Was this intentional or unintentional?"
            yesLabel="Intentional"
            noLabel="Unintentional"
            model="data.bio_medical.has_overdosed_intention"
          />

          <SmartTextarea useQuickText useDictation label="What caused the overdose?" model="data.bio_medical.has_overdosed_cause" />

          <YesNoRadioGroupExtra label="Did it require hospitalization?" model="data.bio_medical.has_overdosed_hospitalization" />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Was the client chemically revived (with Narcan, etc.)?',
    model: 'has_been_chemically_revived',
    component: () => (
      <>
        <RadioGroup
          label="Was the client chemically revived (with Narcan, etc.)?"
          model="data.bio_medical.has_been_chemically_revived"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.bio_medical.has_been_chemically_revived" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please give a short description of event:"
            model="data.bio_medical.has_been_chemically_revived_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: "How is the client's sleep without alcohol, drugs, or medication?",
    model: 'sleep_without_substances',
    component: () => (
      <RadioGroup
        model="data.bio_medical.sleep_without_substances"
        label="How is the client's sleep without alcohol, drugs, or medication?"
        layout="horizontal"
      >
        <Radio label="Poor" value="poor" />
        <Radio label="Average" value="average" />
        <Radio label="Good" value="good" />
      </RadioGroup>
    ),
  },
  {
    label: 'How many hours does the client sleep in a night?',
    model: 'sleep_hours',
    component: () => (
      <Input model="data.bio_medical.sleep_hours" label="How many hours does the client sleep in a night?" size={2} suffix="hours" />
    ),
  },
  {
    label: 'Does the client have trouble falling asleep or staying asleep?',
    model: 'sleep_troubles',
    component: () => (
      <CheckboxGroup label="Does the client have trouble falling asleep or staying asleep?" layout="vertical-dense">
        <Checkbox label="Falling asleep" model="data.bio_medical.sleep.trouble_falling_asleep" />
        <Checkbox label="Staying asleep" model="data.bio_medical.sleep.trouble_staying_asleep" />
        <Checkbox label="Waking up frequently" model="data.bio_medical.sleep.trouble_waking_up_frequently" />
        <Checkbox label="Nightmares" model="data.bio_medical.sleep.trouble_nightmares" />
        <Checkbox label="Using dreams" model="data.bio_medical.sleep.trouble_using_dreams" />
      </CheckboxGroup>
    ),
  },
  {
    label: 'Has the client ever been hospitalized?',
    model: 'has_ben_hospitalized',
    component: () => (
      <>
        <RadioGroup model="data.bio_medical.has_ben_hospitalized" label="Has the client ever been hospitalized?" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.bio_medical.has_ben_hospitalized" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please provide the details of all hospitalizations (include dates, reasons and any other relevant context)"
            model="data.bio_medical.has_ben_hospitalized_extra"
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Does the client have any food allergies?',
    model: 'has_food_allergies',
    component: () => (
      <YesNoRadioGroupExtra label="Does the client have any food allergies?" model="data.bio_medical.sleep.has_food_allergies" />
    ),
  },
  {
    label: 'How many meals per day is the client currently eating?',
    model: 'meals_per_day',
    component: () => (
      <Input
        model="data.bio_medical.meals_per_day"
        label="How many meals per day is the client currently eating?"
        suffix="meals"
        size={2}
      />
    ),
  },
  {
    label: 'What percentage of meals is the client currently eating?',
    model: 'percentage_meals_eating',
    component: () => (
      <Input
        model="data.bio_medical.percentage_meals_eating"
        label="What percentage of meals is the client currently eating?"
        suffix="%"
        size={2}
      />
    ),
  },
  {
    label: 'Has the client experienced weight gain or loss of 10 pounds or more in the last 3 months?',
    model: 'has_significant_weight_changes',
    component: () => (
      <>
        <RadioGroup
          model="data.bio_medical.has_significant_weight_changes"
          label="Has the client experienced weight gain or loss of 10 pounds or more in the last 3 months?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.bio_medical.has_significant_weight_changes" is={true}>
          <SmartTextarea
            useQuickText
            useDictation
            label="Please explain:"
            model="data.bio_medical.has_significant_weight_changes_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    ),
  },
  {
    label: 'Has the client experienced a decrease in food intake or appetite?',
    model: 'has_food_intake_appetite_decrease',
    component: () => (
      <YesNoRadioGroupExtra
        label="Has the client experienced a decrease in food intake or appetite?"
        model="data.bio_medical.sleep.has_food_intake_appetite_decrease"
      />
    ),
  },
  {
    label: 'Is the client experiencing any dental problems?',
    model: 'has_dental_problems',
    component: () => (
      <YesNoRadioGroupExtra label="Is the client experiencing any dental problems?" model="data.bio_medical.sleep.has_dental_problems" />
    ),
  },
  {
    label: 'Are there any habits or behaviors indicating an eating disorder (such as binging or induced vomiting)?',
    model: 'has_eating_disorder',
    component: () => (
      <YesNoRadioGroupExtra
        label="Are there any habits or behaviors indicating an eating disorder (such as binging or induced vomiting)?"
        model="data.bio_medical.sleep.has_eating_disorder"
      />
    ),
  },
  {
    label: 'Nutrition referral needed?',
    model: 'does_require_nutrition_referral',
    component: () => <YesNoRadioGroup label="Nutrition referral needed?" model="data.bio_medical.does_require_nutrition_referral" />,
  },
  {
    label: 'Notes',
    model: 'notes',
    component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.bio_medical.notes" />,
  },
]
