import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  not_signed: {
    label: 'Not Signed',
    color: 'gray',
  },
  partially_signed: {
    label: 'Partially Signed',
    color: 'blue',
  },
  signed: {
    label: 'Signed',
    color: 'green',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
