import React from 'react'
import { useMedia } from 'use-media'
import clsx from 'clsx'

import { COLORS, BREAKPOINT, MEDIA_QUERY } from '../theme'

import Button from './Button'
import Dropdown from './Dropdown'
import Flex from './Flex'
import Header from './Header'
import Scroll from './Scroll'
import Wrapper from './Wrapper'

const Nav: React.FC<any> = (props) => {
  const {
    aside,
    avatar,
    background,
    breakpoint,
    children,
    className,
    description,
    desktopProps,
    headingSize,
    help,
    icon,
    glyph,
    glyphColor,
    menuGlyph = 'menu',
    menuLabel = 'Menu',
    profile,
    record,
    showBack,
    title,
    titleAside,
    top,
    zIndex,
    useWrapper = true,
    back,
    isHeaderSticky = true,
  } = props

  const isBreakpointActive = useMedia({ minWidth: BREAKPOINT[breakpoint] })
  const isDesktop = useMedia({ minWidth: BREAKPOINT[3] })
  const isMobile = useMedia({ maxWidth: 480 })

  const dynamicStyles = {
    top,
    background,
    borderRight: isBreakpointActive && `1px solid ${COLORS.divider}`,
    zIndex,
  }

  const classNames = clsx('Scroll', {
    'no-breakpoint': !isBreakpointActive,
    [className]: className,
  })

  const WrapperTag = useWrapper ? Wrapper : React.Fragment

  const content = (
    <>
      {profile}
      <WrapperTag>{children}</WrapperTag>
    </>
  )

  const header = (
    <Header
      css={styles.header}
      isSticky={isHeaderSticky}
      descriptionNowrap
      nowrap={isBreakpointActive}
      icon={icon}
      title={title}
      titleAside={titleAside}
      avatar={avatar}
      help={help}
      headingSize={headingSize}
      description={description}
      showBack={showBack}
      back={back}
      record={record}
      menuLabel={menuLabel}
      menuGlyph={menuGlyph}
      aside={aside}
      glyph={glyph}
      glyphColor={glyphColor}
      nowrapAside={
        !isBreakpointActive && (
          <Dropdown
            css={styles.mobileDropdown}
            trigger={
              <Button hideLabel={isMobile} glyph={menuGlyph} label={menuLabel} glyphColor={COLORS.blue} color="text" type="default" />
            }
          >
            {content}
          </Dropdown>
        )
      }
      {...(isDesktop && desktopProps)}
    />
  )

  return (
    <Scroll isActive={isBreakpointActive} css={[styles.scroll, dynamicStyles]} className={classNames}>
      <Flex nowrap stretchChildrenX css={{ display: isBreakpointActive ? 'block' : 'flex' }}>
        {header}
        {isBreakpointActive && <>{content}</>}
      </Flex>
    </Scroll>
  )
}

const styles = {
  mobileDropdown: {
    paddingLeft: '0.5rem',
    marginLeft: 'auto !important',
  },

  scroll: {
    position: 'sticky',
    minWidth: 240,
    zIndex: 5,

    '&.no-breakpoint': {
      display: 'block',
      minHeight: 'auto',
      overflow: 'visible',
    },
  },

  header: {
    [`${MEDIA_QUERY[3]}`]: { top: 0 },
  },
}

Nav.defaultProps = {
  isEditable: true,
  breakpoint: 1,
  more: false,
  background: COLORS.lightBackground,
  top: 0,
  headingSize: 400,
}

export default Nav
