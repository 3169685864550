import React from 'react'
import size from 'lodash/size'

import DataFormStatus from '../../components/Statuses/DataFormStatus'
import TreatmentPlanStatus from '../../components/Statuses/TreatmentPlanStatus'
import LevelOfCareStatus from '../../components/Statuses/LevelOfCareStatus'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Avatar from '../../components/Avatar'
import Flex from '../../components/Flex'
import Tooltip from '../../components/Tooltip'

import { FILTERS } from '../Filters/config'
import { LOC } from '../../utils/constants'
import { useSettings } from '../../hooks/useSettings'
import { titleCase } from '@behavehealth/utils/functions'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

const processSigneesToSign = ({ signees = [], type }: any) => {
  let signed: any[] = []
  let notSigned: any[] = []

  if (size(signees) === 0) return { signed, notSigned }

  for (const signee of signees) {
    if (type === 'initial') {
      signee.initial_signed_at ? signed.push(signee) : notSigned.push(signee)
      continue
    }

    if (type === 'updates') {
      signee.last_signed_at ? signed.push(signee) : notSigned.push(signee)
      continue
    }
  }

  return { signed, notSigned }
}

export const TreatmentPlansReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'client',
        model: 'client.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 200,
        id: 'category',
        model: 'category',
        title: 'Category',
        formatValue: ({ value }: any) => titleCase(value),
      },
      {
        width: 200,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <TreatmentPlanStatus status={value} />,
      },
      {
        width: 180,
        id: 'initial_signatures',
        model: 'signees',
        title: 'Signatures',
        disableSort: true,
        formatValue: ({ data }: any) => {
          const signeesCount = size(data?.signees)

          if (signeesCount === 0) return null

          const { signed, notSigned } = processSigneesToSign({ type: 'initial', signees: data?.signees })

          const notSignedCount = size(notSigned)
          const signedCount = size(signed)

          return (
            <div className="flex items-center">
              <div className="mr-1.5">
                {signedCount} / {signeesCount}
              </div>

              <Tooltip
                content={
                  <>
                    {signedCount > 0 && (
                      <div>
                        <div>The following signees have signed this Treatment Plan:</div>
                        <div>
                          <b>{signed?.map((o) => o.signee_name)?.join(', ')}</b>
                        </div>
                      </div>
                    )}

                    {notSignedCount > 0 && (
                      <div className="mt-1.5">
                        <div>The following signees are yet to sign this Treatment Plan:</div>
                        <div>
                          <b>{notSigned?.map((o) => o.signee_name)?.join(', ')}</b>
                        </div>
                      </div>
                    )}
                  </>
                }
              />
            </div>
          )
        },
      },
      {
        width: 200,
        id: 'level_of_care',
        model: 'level_of_care',
        title: 'Level of Care',
        formatValue: ({ value }: any) => <LevelOfCareStatus status={value} />,
      },
      {
        width: 200,
        id: 'appointment_frequency',
        model: 'appointment_frequency',
        title: 'Frequency of Appointments',
      },
      {
        width: 200,
        id: 'next_appointment',
        model: 'next_appointment',
        title: 'Next Appointment',
      },
      {
        width: 200,
        id: 'next_appointment_at',
        model: 'next_appointment_at',
        title: 'Next Appointment Date',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'estimated_length',
        model: 'estimated_length',
        title: 'Estimated Length of Treatment',
      },
      {
        width: 200,
        id: 'started_at',
        model: 'started_at',
        title: 'Start Date and Time',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'last_reviewed_at',
        model: 'last_reviewed_at',
        title: 'Review Date',
        type: 'date',
      },
      {
        width: 250,
        id: 'employees',
        model: 'employees',
        title: 'Employees',
        formatValue: ({ data, value }: any) => {
          if (size(data.employees) === 0) return null

          return (
            <Flex centerY nowrap gap="0.75rem">
              {data.employees.map((o: any) => (
                <Flex key={o.id} centerY nowrap gap="0.5rem">
                  <Avatar src={o.avatar} initials={o.name} size={20} />
                  <div>{o.name}</div>
                </Flex>
              ))}
            </Flex>
          )
        },
      },
      {
        width: 250,
        id: 'supervisors',
        model: 'supervisors',
        title: 'Supervisors',
        formatValue: ({ data, value }: any) => {
          if (size(data.supervisors) === 0) return null

          return (
            <Flex centerY nowrap gap="0.75rem">
              {data.supervisors.map((o: any) => (
                <Flex key={o.id} centerY nowrap gap="0.5rem">
                  <Avatar src={o.avatar} initials={o.name} size={20} />
                  <div>{o.name}</div>
                </Flex>
              ))}
            </Flex>
          )
        },
      },
      {
        width: 200,
        id: 'created_at',
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Edited',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Treatment Plans"
      icon="treatment_plans"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="treatment_plans"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=treatment_plans'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'treatment_plans' }} />
        </>
      }
      {...props}
    />
  )
}

const FILTERS_CONFIG = {
  client: {
    type: 'multi_object',
    label: 'Client',
    endpoint: '/residents',
    apiKey: 'residents',
    glyph: 'user_group',
    showAvatar: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.position),
  },
  supervisors: {
    type: 'multi_object',
    label: 'Supervisors',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.position),
  },
  employees: {
    type: 'multi_object',
    label: 'Employees',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.position),
  },
  author: {
    type: 'multi_object',
    label: 'Added By',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.position),
  },
  name: {
    label: 'Name',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Closed', value: 'closed' },
    ],
  },
  category: {
    label: 'Category',
    type: 'multi_select',
    options: [
      { label: 'Clinical', value: 'clinical' },
      { label: 'Medical', value: 'medical' },
      { label: 'Case Management', value: 'case_management' },
      { label: 'Peer', value: 'peer' },
    ],
  },
  level_of_care: {
    label: 'Level Of Care',
    type: 'multi_select',
    options: Object.keys(LOC).map((level: any) => ({ label: LOC[level], value: level })),
  },
  appointment_frequency: {
    label: 'Frequency of Appointments',
    type: 'string',
  },
  next_appointment: {
    label: 'Next Appointment',
    type: 'string',
  },
  next_appointment_at: {
    label: 'Next Appointment At',
    type: 'date_time',
  },
  estimated_length: {
    label: 'Estimated Length',
    type: 'string',
  },
  started_at: {
    label: 'Start Date and Time',
    type: 'date_time',
  },
  ended_at: {
    label: 'Episode Until',
    type: 'date_time',
  },
  last_reviewed_at: {
    label: 'Review Date',
    type: 'date_time',
  },
  created_at: {
    label: 'Created At',
    type: 'date_time',
  },
  updated_at: {
    label: 'Updated At',
    type: 'date_time',
  },
}
