import React from 'react'
import { DateTime } from 'luxon'
import { opacify } from 'polished'
import { v4 as uuid } from 'uuid'

import { COLORS } from '../../theme'
import { isDefined, usDate, usTime } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import { useFormField } from './hooks/useFormField'
import { withFormContext } from './context'

import Button from '../Button'
import Card from '../Card'
import CardHeader from '../CardHeader'
import CardMeta from '../CardMeta'
import CardSubtitle from '../CardSubtitle'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Field from '../Forms/Field'
import Glyph from '../Glyph'
import SignatureDialog from '../Dialogs/SignatureDialog'
import Text from '../Typography/Text'

export const SignatureUploader = withFormContext((props: any) => {
  const { form, isEditable, timezone, environment, model } = props

  const [data, setData]: any = React.useState(form?.getField(model) || form?.getInitialInputFieldValue(model))

  const [signedAt, setSignedAt] = React.useState(
    form?.getField(`${model}_signed_at`) || form?.getInitialInputFieldValue(`${model}_signed_at`),
  )

  const { mutateAsync: upload, isLoading: isUploading }: any = useCreate({
    name: ['create-signature-attachment'],
    url: `/file_attachments`,
  })

  const { initialValue, formActions } = useFormField({
    model,
    form: form,
    isValid: true,
    defaultEmptyValue: null,
    afterReset: () => {
      setData(form?.getInitialInputFieldValue(model))
    },
  })

  const addSignature = async (signature: any) => {
    if (!signature?.value) return

    const newSignedAt = DateTime.local().setZone(timezone).toISO()

    const file = await upload({
      attachment_name: `signature-${uuid()}`,
      attachment_data: await signature.value,
    })

    if (file?.data?.url) {
      setData(file.data.url)
      setSignedAt(newSignedAt)

      form.setFieldValue(model, file.data.url)
      form.setFieldValue(`${model}_signed_at`, newSignedAt)
    }
  }

  const clearSignature = () => {
    setData(null)
    setSignedAt(null)

    form.setFieldValue(model, null)
    form.setFieldValue(`${model}_signed_at`, null)
  }

  const isSigned = isDefined(data)

  React.useEffect(() => {
    if (data) form.setFieldValue(model, data)
    if (signedAt) form.setFieldValue(`${model}_signed_at`, signedAt)
  }, [model, data, signedAt])

  return (
    <Field alwaysShowDescription isEditable={isEditable} isValid={isSigned}>
      <Card>
        {isSigned && (
          <>
            {signedAt && (
              <CardHeader
                paddingY="0.4rem"
                css={{ borderRadius: 0, background: COLORS.white, boxShadow: `0 0 0 1px ${opacify(-0.05, COLORS.divider)}` }}
              >
                <CardMeta>
                  <Text glyph="date" description={usDate(signedAt, timezone)} />
                  <Text glyph="time" description={usTime(signedAt, timezone)} />
                </CardMeta>
              </CardHeader>
            )}

            <div css={STYLES.signature}>
              <img src={data} alt="signature" />
            </div>
          </>
        )}

        {!isSigned && (
          <CardHeader graphic={<Glyph glyph="signature" size={18} />}>
            <CardSubtitle subtitle="No signature added yet…" />
          </CardHeader>
        )}

        {isEditable && environment !== 'builder' && (
          <div className="flex border-t border-0 border-solid border-divider">
            <div className="!flex-[3_1_auto] border-r border-0 border-solid border-divider">
              <SignatureDialog
                title={isSigned ? 'Update Signature' : 'Add Signature'}
                allowPin={false}
                onYes={addSignature}
                yesLabel={isSigned ? 'Update Signature' : 'Add Signature'}
              >
                <Button
                  label={isSigned ? 'Update Signature' : 'Add Signature'}
                  glyph="signature"
                  type="minimal"
                  size={300}
                  className="!rounded-none !border-none !shadow-none"
                  css={STYLES.button}
                />
              </SignatureDialog>
            </div>

            {isSigned && (
              <div className="!flex-[1_0_auto]">
                <DeleteDialog
                  title="Remove Signature?"
                  message="Are you sure you want to remove this signature?"
                  onYes={clearSignature}
                  yesLabel="Remove Signature"
                >
                  <Button
                    label="Remove"
                    glyph="cross"
                    type="minimal"
                    color="red"
                    size={300}
                    className="!rounded-none !border-none !shadow-none"
                    css={STYLES.button}
                  />
                </DeleteDialog>
              </div>
            )}
          </div>
        )}
      </Card>
    </Field>
  )
})

const STYLES = {
  signature: {
    padding: '1rem 1rem 1rem 2rem',

    img: {
      display: 'block',
      width: '100%',
      height: 'auto',
      maxWidth: 400,
      maxHeight: 200,
    },
  },

  button: {
    boxShadow: 'none !important',
  },
}
