import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import ApplicationStatus from '../../components/Statuses/ApplicationStatus'

import { FILTERS } from '../Filters/config'

import { useSettings } from '../../hooks/useSettings'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const ApplicationsReportDataTable = (props: any) => {
  const { to } = props

  const { isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        id: 'reference',
        model: 'reference',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.reference?.name} avatar={data.reference?.avatar} to={to?.(data)} />
        ),
      },
      {
        title: 'Status',
        width: 220,
        id: 'status',
        model: 'status',
        formatValue: ({ data, value }: any) => (value ? <ApplicationStatus status={value} /> : '–'),
        // filterOptions: [
        //   { value: 'lead', label: '0. Application Not Sent' },
        //   { value: 'sent', label: '1. Application Sent' },
        //   { value: 'opened', label: '2. Application Opened' },
        //   { value: 'partial_completion', label: '3. Partial Completion' },
        //   { value: 'submitted', label: '4. Application Submitted' },
        //   { value: 'intake', label: 'Accepted For Intake' },
        //   { value: 'declined', label: 'Application Declined' },
        //   { value: 'archived', label: 'Application Archived' },
        // ],
      },
      {
        title: 'Added By',
        width: 220,
        id: 'author',
        model: 'author.name',
        type: 'profile',
      },
      {
        title: 'Added At',
        width: 200,
        id: 'applied_at',
        model: 'applied_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated',
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Applications"
      icon="checklist"
      columns={columns}
      filtersConfig={FILTERS.live_reports.applications}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="applications"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=applications'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'applications' }} />
        </>
      }
    />
  )
}
