import size from 'lodash/size'
import { CATEGORIES, SUBCATEGORIES } from './forms'

export const FORM_IDS = [
  'accreditation_application',
  'agreement_form',
  'application_form',
  'certification_application',
  'contact_house_information',
  'document_review',
  'grievance_form',
  'site_review',
]

export const CATEGORY_IDS = [
  'informational',
  'administrative_operations',
  'physical_environment',
  'recovery_support',
  'good_neighbor',
  'uncategorized',
]

export const SUBCATEGORY_IDS = [
  'basic_details',
  'operate_with_integrity',
  'uphold_residents_rights',
  'create_culture_of_empowerment',
  'develop_staff_abilities',
  'home_like_environment',
  'safe_and_healthy_environment',
  'facilitate_active_recovery',
  'model_prosocial_behaviors',
  'cultivate_sense_of_belonging',
  'good_neighbor',
  'uncategorized',
]

export const getOrderedQuestions = (fieldsOrder: any[], fields: any) => {
  if (!fieldsOrder || !fields) return null

  const res: any = []

  for (const fieldId of fieldsOrder) {
    const field = fields[fieldId]

    if (!field) continue

    res.push(field.data)
  }

  return res
}

export const getFormSubmissionSchema = (schema) => {
  if (!schema || size(schema) === 0) return null

  const mainSections: any = []

  // construct main sections by category
  for (const categoryId of CATEGORY_IDS) {
    if (!schema[categoryId]) continue

    const category = schema[categoryId]
    const categoryConfig = CATEGORIES[categoryId] || { name: '' }
    const subsections: any = []

    let allSubsectionsHidden = true

    // construct sub-sections by subcategory
    for (const subcategoryId of SUBCATEGORY_IDS) {
      if (!category[subcategoryId]) continue

      const subcategory = category[subcategoryId]
      const subcategoryConfig = SUBCATEGORIES[subcategoryId] || { name: '' }

      if (!subcategory.show) continue

      // update visible flag for subsections
      allSubsectionsHidden = false

      const fields = subcategory?.fields
      const fieldsOrder = subcategory?.fieldsOrder

      if (!fields || !fieldsOrder) continue

      // construct visible questions
      const questions: any = []
      let allQuestionsHidden = true

      for (const identifier of fieldsOrder) {
        if (!fields[identifier]) continue

        const field = fields[identifier]

        if (!field.show) continue

        // update visible flag for questions
        allQuestionsHidden = false
        questions.push(field.data)
      }

      // skip adding the subsection if all questions are hidden
      if (allQuestionsHidden) continue

      subsections.push({
        name: subcategoryConfig.name,
        questions,
      })
    }

    // skip adding the main section if all subsections are hidden
    if (allSubsectionsHidden) continue

    mainSections.push({
      name: categoryConfig.name,
      subsections,
    })
  }

  return mainSections
}
