import React from 'react'
import size from 'lodash/size'

import { usDate } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import Card from '../../../components/Card'
import CardHeader from '../../../components/CardHeader'
import CardContent from '../../../components/CardContent'
import GridTable from '../../../components/GridTable'
import InsuranceClaimStatus from '../../../components/Statuses/InsuranceClaimStatus'

export const ClaimStatuses = ({ header, statuses }: any) => {
  const { timezone } = useSettings()

  const tableConfig = React.useMemo(
    () => [
      {
        title: 'Date',
        width: '100px',
        render: (data) => <GridTable.Cell centerY>{usDate(data?.dated_at, timezone)}</GridTable.Cell>,
      },
      {
        title: 'Status',
        width: '140px',
        render: (data) => (
          <GridTable.Cell centerY>
            <InsuranceClaimStatus small status={data?.status} />
          </GridTable.Cell>
        ),
      },
      {
        title: 'Description',
        width: 'minmax(300px, 1fr)',
        render: (data) => (
          <GridTable.Cell centerY>{data?.clearing_house_messages?.map?.((message: any) => message?.message).join('; ')}</GridTable.Cell>
        ),
      },
    ],
    [statuses, timezone],
  )

  const templateColumns = React.useMemo(() => {
    return tableConfig.map((col: any) => col.width).join(' ')
  }, [tableConfig])

  return (
    <Card>
      <CardHeader>{header || <div className="font-[600]">{`Claim Statuses (${size(statuses)})`}</div>}</CardHeader>

      {size(statuses) > 0 && (
        <CardContent padding={'0'}>
          <div className="text-[0.92rem]">
            <GridTable useBanding={false} templateColumns={templateColumns} useRowHover={false}>
              <GridTable.Header>
                {tableConfig.map((col: any) => (
                  <GridTable.Cell key={col.title}>{col.title}</GridTable.Cell>
                ))}
              </GridTable.Header>

              {statuses?.map?.((row: any) => {
                return (
                  <GridTable.Row key={`row-${row.id}`}>
                    {tableConfig.map((col: any) => (
                      <React.Fragment key={`${row.id}-${col.title}`}>{col.render?.(row)}</React.Fragment>
                    ))}
                  </GridTable.Row>
                )
              })}
            </GridTable>
          </div>
        </CardContent>
      )}
    </Card>
  )
}
