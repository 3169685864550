import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import produce from 'immer'
import size from 'lodash/size'

import { apiGet } from '../../../../../modules/api'
import { mapToArray, arrayToMapWithKeyPrefix } from '../../../../../utils/functions'
import { TabsContext } from '../../../../../hocs/withTabsContext'

import Button from '../../../../Button'
import Card from '../../../../Card'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import Form from '../../../../Forms/Form'
import FormSection from '../../../../Forms/FormSection'
import State from '../../../../State'
import Status from '../../../../Status'
import TreeItem from '../../../../TreeItem'

import DiagnosisAccordion from '../components/DiagnosisAccordion'
import Footer from '../components/Footer'
import Main from '../components/Main'

import { icd10ToKey, keyToID } from './utils'

const setInitialPageData = (wileyData: any) => {
  const res: any = {}

  for (const icd10 of Object.keys(wileyData)) {
    res[icd10ToKey(icd10)] = {}
  }

  return res
}

const Objectives = ({ formData, setFormData, wileyData, setWileyData }: any) => {
  const { onSelect: selectTab }: any = React.useContext(TabsContext)

  const form: any = React.useRef()

  const initialPageData = setInitialPageData(wileyData)
  const [pageData, setPageData] = React.useState(initialPageData)

  const updatePageData = (icd10: any, data: any) => {
    setPageData(
      produce((draft: any) => {
        draft[icd10] = data
      }),
    )
  }

  const updateWileyData = () => {
    const icd10Keys = Object.keys(wileyData)
    const { selected_diagnoses } = form.current?.getFormValue()

    let newWileyData = cloneDeep(wileyData)

    for (const icd10 of icd10Keys) {
      const { selected_goals, selected_objectives } = selected_diagnoses[icd10] || {}

      for (const goal_id in selected_goals) {
        // skip if goal is not selected
        if (!selected_goals[goal_id]) continue

        const newObjectives: any = {}

        for (const objective_id in selected_objectives?.[goal_id] || {}) {
          // skip if objective is not selected
          if (!selected_objectives?.[goal_id]?.[objective_id]) continue

          const id = keyToID(objective_id)
          const description = pageData[icd10][objective_id].description
          const isEvidenceBased = pageData[icd10][objective_id].is_evidence_based

          newObjectives[objective_id] = {
            objective_id: id,
            description: description,
            is_evidence_based: isEvidenceBased,
          }
        }

        // map selected objectives to goals
        newWileyData = produce(newWileyData, (draft: any) => {
          draft[icd10].goals[goal_id].objectives = newObjectives
        })
      }
    }

    setWileyData(newWileyData)
  }

  const goNext = () => {
    updateWileyData()
    selectTab('interventions')
  }

  if (!formData) return null

  return (
    <>
      <Main>
        <Form useFullModel getForm={form} initialModel={formData} onUpdate={setFormData}>
          <FormSection maxWidth="100%">
            {mapToArray(wileyData).map((diagnosis: any) => (
              <ObjectiveSection
                key={diagnosis.icd10}
                icd10={diagnosis.icd10}
                icd10Description={diagnosis.description}
                problemGroupID={diagnosis.problem_group_id}
                problemID={diagnosis.problem_id}
                problemGroupDescription={diagnosis.problem_group_description}
                problemDescription={diagnosis.problem_description}
                updatePageData={updatePageData}
                goals={diagnosis.goals}
              />
            ))}
          </FormSection>
        </Form>
      </Main>

      <Footer>
        <Button label="Continue →" type="primary" onClick={goNext} />
      </Footer>
    </>
  )
}

const ObjectiveSection: React.FC<any> = (props) => {
  const { icd10, icd10Description, problemDescription, problemGroupDescription, problemGroupID, problemID, updatePageData, goals } = props

  const { onSelect: selectTab }: any = React.useContext(TabsContext)

  const [data, setData]: any = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const emptyGoals = size(goals) === 0

  const goBack = () => {
    selectTab('goals')
  }

  // Get Wiley Data
  React.useEffect(() => {
    if (!problemGroupID && !problemID) return

    const getAPIData = async () => {
      try {
        setLoading(true)

        const result = await apiGet({ url: `/wiley/objectives?problem_group_id=${problemGroupID}&problem_id=${problemID}` })

        const data = result?.data?.data
        const pageData = arrayToMapWithKeyPrefix(data, 'objective_id', 'id_')

        setData(data)
        updatePageData(icd10ToKey(icd10), pageData)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getAPIData()
  }, [problemGroupID, problemID])

  if (!problemGroupID && !problemID) return null

  if (loading || emptyGoals) {
    return (
      <Card>
        <State
          isLoading={loading}
          isEmpty={emptyGoals}
          icon="treatment_plans"
          title="No Goals Selected"
          emptyDescription="Please add at least one goal to view objectives"
          emptyActions={<Button label="← Select Goals" type="minimal" size={200} onClick={goBack} />}
          minHeight={200}
        />
      </Card>
    )
  }

  const baseModel = `selected_diagnoses.${icd10ToKey(icd10)}.selected_objectives`

  return (
    <DiagnosisAccordion
      icd10={icd10}
      icd10Description={icd10Description}
      problemGroupDescription={problemGroupDescription}
      problemDescription={problemDescription}
    >
      {mapToArray(goals).map((goal: any, index: number) => (
        <TreeItem
          showDivider
          isOpen
          key={goal.goal_id}
          title={
            <>
              <Status inline small label="Goal" color="blue" css={styles.inlineStatus} />
              {goal.description}
            </>
          }
        >
          <Status label="Objectives:" color="purple" css={styles.blockStatus} />

          <CheckboxGroup layout="vertical-dense" withHover={false} css={styles.checkboxGroup}>
            {data.map((objective: any) => (
              <Checkbox
                key={objective.objective_id}
                label={objective.description}
                model={`${baseModel}.id_${goal.goal_id}.id_${objective.objective_id}`}
              />
            ))}
          </CheckboxGroup>
        </TreeItem>
      ))}
    </DiagnosisAccordion>
  )
}

const styles = {
  inlineStatus: {
    marginRight: '0.5rem',
  },

  blockStatus: {
    margin: '0.75rem 0',
  },

  checkboxGroup: {
    paddingBottom: '1rem',
  },
}

export default Objectives
