import React from 'react'

import Button from '../Button'
import { WorkflowContext } from './context'

const WorkflowSaveContinueButton = ({ onClick, isLoading, isDisabled, label, color, glyph, type = 'primary', ...rest }: any) => {
  const { goNext }: any = React.useContext(WorkflowContext)

  return (
    <Button
      testKey="continue_button"
      label={label}
      color={color}
      glyph={glyph}
      type={type}
      onClick={onClick ? onClick : goNext}
      isDisabled={isDisabled}
      isLoading={isLoading}
      size={300}
      css={{ minWidth: '10rem' }}
      {...rest}
    />
  )
}

WorkflowSaveContinueButton.defaultProps = {
  label: 'Save & Continue →',
  color: 'green',
  glyph: 'tick_circle',
}

export default WorkflowSaveContinueButton
