import React from 'react'
import { DateTime } from 'luxon'
import { tint } from 'polished'
import { useQueryClient } from 'react-query'
import clsx from 'clsx'
import compact from 'lodash/compact'
import produce from 'immer'
import size from 'lodash/size'
import startCase from 'lodash/startCase'
import TextareaAutosize from 'react-autosize-textarea'

import { age, arrayToMap, countWord, getClientLink, usDateTime } from '../../utils/functions'
import { CARE_TEAM_RELATION_TYPES } from '../../utils/constants'
import { COLORS, INPUT_STYLES, INPUT_FOCUS_STYLES } from '../../theme'
import { getPrefix, useGet, useCreate, useUpdate, invalidateQueries } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { usePermissions } from '../../hooks/usePermissions'
import { useSettings } from '../../hooks/useSettings'
import { useVerifyPermission } from '../../hooks/useVerifyPermission'
import { withOverlayError } from '../../hocs/withOverlayError'

import Accordion from '../../components/Accordion'
import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DataList from '../../components/DataList'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import GridTable from '../../components/GridTable'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Link from '../../components/Link'
import Loader from '../../components/Loader'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Radio from '../../components/Forms/Radio'
import RadioCheckElement from '../../components/Forms/RadioCheckElement'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import State from '../../components/State'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'
import Tooltip from '../../components/Tooltip'

import { AllClientsTable } from '../../components/Forms/Selectors/tables/AllClientsTable'
import { DropdownMenu, DropdownMenuItem } from '../../components/DropdownMenu'
import { Filters } from '../Filters/Filters'
import { useDataTable } from '../../components/DataTable/useDataTable'

import { ROUNDS_CATEGORIES } from './constants'
import { RoundSignatureStatus } from './RoundSignatureStatus'
import { RoundsSignatureOverlay } from './RoundsSignatureOverlay'
import { RoundStatus } from './RoundStatus'
import { RoundStatusSelector } from './RoundStatusSelector'

const CLIENTS_IMPORT_TYPES = {
  all: 'All Clients',
  manual: 'Manual from Clients List',
  dynamic: 'Automatic from Locations / Programs',
}

const PROGRAMS_IMPORT_TYPES = {
  programs: 'Programs',
  program_lists: 'Program Lists',
}

const RootRoundsOverlay = (props: any) => {
  const { close, data, id, isOverlayLoading, updateAsync, deleteRecord, isDeleting, saveWithData, refetch } = useOverlay({
    name: 'rounds',
    endpoint: '/rounds',
    invalidate: 'rounds',
    options: props,
  })

  const { data: signees, isLoading: isLoadingSignees } = useGet({
    name: ['rounds', id, 'round_signees'],
    url: `/rounds/${id}/round_signees`,
  })

  const { mutateAsync: closeRound }: any = useCreate({
    name: ['rounds', id, 'close'],
    url: `/rounds/${id}/close`,
    invalidate: 'rounds',
  })

  const { mutateAsync: signOffRound }: any = useCreate({
    name: ['rounds', id, 'sign_off'],
    url: `/rounds/${id}/sign_off`,
    invalidate: 'rounds',
  })

  const { mutateAsync: pendingReviewRound }: any = useCreate({
    name: ['rounds', id, 'pending_review'],
    url: `/rounds/${id}/pending_review`,
    invalidate: 'rounds',
  })

  const canSignOff = React.useMemo(() => {
    if (size(signees) === 0) return false

    if (data?.signature_status === 'signed') return true

    for (const signee of signees) {
      if (!signee.last_signature || !signee.last_signed_at) return false
    }

    return true
  }, [signees, data])

  const { user, timezone } = useSettings()

  const template = React.useMemo(() => {
    return data?.saved_settings || null
  }, [data])

  const [status, setStatus] = React.useState(data?.status)

  const [closeOffDialogOpen, setCloseOffDialogOpen] = React.useState(false)
  const [signoffDialogOpen, setSignoffDialogOpen] = React.useState(false)
  const [updatesDialogOpen, setUpdatesDialogOpen] = React.useState(false)

  const isSupervisor = React.useMemo(() => {
    if (!data?.supervisors) return false

    for (const supervisor of data.supervisors) {
      if (supervisor.id === user.id && supervisor.type === user.type) return true
    }

    return false
  }, [data, user])

  const isSigneesEmpty = size(signees) === 0

  const initialModel = React.useMemo(() => {
    if (!data) return {}

    return {
      name: data.name,
      status: data.status,
      started_at: data.started_at,
      ended_at: data.ended_at,
      supervisor: data.supervisor,
      employees: data.employees,
    }
  }, [data])

  React.useEffect(() => {
    setStatus(data?.status)
  }, [data?.status])

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={160} />
  }

  const isSignedOff = data?.status === 'signed_off'
  const isClosedOff = data?.status === 'closed'
  const isFinalStatus = isSignedOff || isClosedOff

  const canEdit = !isFinalStatus

  return (
    <>
      <SummonOverlay
        isOpen={updatesDialogOpen}
        onClose={() => {
          setUpdatesDialogOpen(false)
        }}
        overlay={<RequestUpdatesOverlay roundId={id} value={data?.requested_updates || ''} refetch={refetch} />}
      />

      <ConfirmDialog
        setOpen={closeOffDialogOpen}
        title="Close Round?"
        message={
          <div className="grid gap-4 text-text">
            <Alert contrast glyph="warning" type="warning" className="text-text">
              <b>Please note,</b> upon closing this Round, the following will occur:
            </Alert>

            <Card className="px-4 py-3 grid gap-3 leading-normal">
              <div className="flex items-center flex-nowrap">
                <Glyph glyph="lock" size={18} color={COLORS.text} className="flex-0 mr-3" />
                <div>Only owners and administrators will be able to make any further edits</div>
              </div>
            </Card>
          </div>
        }
        yesColor="green"
        yesLabel="Yes, Close Round"
        onYes={async () => {
          await closeRound()
          await refetch()
          setCloseOffDialogOpen(false)
        }}
        onClose={() => {
          setCloseOffDialogOpen(false)
        }}
      />

      <ConfirmDialog
        setOpen={signoffDialogOpen}
        title="Sign Off Round?"
        message={
          <div className="grid gap-4 text-text">
            <Alert contrast glyph="warning" type="warning" className="text-text">
              <b>Please note,</b> upon signing-off this Round, the following will occur:
            </Alert>

            <Card className="px-4 py-3 grid gap-3 leading-normal">
              <div className="flex items-center flex-nowrap">
                <Glyph glyph="lock" size={18} color={COLORS.text} className="flex-0 mr-3" />
                <div>Only owners and administrators will be able to make any further edits</div>
              </div>
            </Card>
          </div>
        }
        yesColor="green"
        yesLabel="Yes, Sign-Off Round"
        onYes={async () => {
          await signOffRound()
          await refetch()
          setSignoffDialogOpen(false)
        }}
        onClose={() => {
          setSignoffDialogOpen(false)
        }}
      />

      <Overlay onClose={close} maxWidth={160}>
        <Overlay.Header title="Round" icon="rounds" />

        {(isSignedOff || isClosedOff) && (
          <Overlay.SubHeader className="relative z-10 !px-0 !py-0 border-b border-0 border-solid border-divider shadow-hard-3">
            <Alert small glyph="info" type="warning" className="!rounded-none !px-4">
              This Round is now {isSignedOff ? 'signed-off' : isClosedOff ? 'closed' : ''}. Only Owners and Administrators are allowed to
              make further changes.
            </Alert>
          </Overlay.SubHeader>
        )}

        <Overlay.Content className="mq1024:grid mq1024:grid-rows-[100%] mq1024:grid-cols-[440px_1fr] mq1024:!overflow-hidden">
          <div className="p-4 mq1024:pr-2 grid grid-cols-[100%] gap-4 content-start mq1024:overflow-y-auto">
            <Card className="flex items-center px-3 py-2 min-h-[2.8rem]">
              <RoundStatusSelector
                isSupervisor={isSupervisor}
                cannotSignOffReasons={!canSignOff && ['All staff must add their signatures to sign off']}
                isFinalStatus={isFinalStatus}
                canSignOff={canSignOff}
                value={status}
                onUpdate={async (newStatus: any) => {
                  if (newStatus === 'signed_off' && canSignOff) {
                    setSignoffDialogOpen(true)
                    return
                  }

                  if (newStatus === 'closed') {
                    setCloseOffDialogOpen(true)
                    return
                  }

                  if (newStatus === 'updates_requested') {
                    setUpdatesDialogOpen(true)
                    return
                  }

                  if (newStatus === 'pending_review') {
                    await pendingReviewRound()
                    return
                  }

                  setStatus(newStatus)

                  await saveWithData({ status: newStatus })
                  await refetch()
                }}
              />
            </Card>

            <Accordion
              isOpen
              activateEditMode={canEdit}
              initialModel={initialModel}
              size={200}
              title="Round Details"
              icon="general_info"
              rootClassNames="!m-0 !border-solid !border-divider"
              editPermission={canEdit && 'rounds.edit'}
              onSubmit={updateAsync}
              footerAfter={
                <div className="!ml-auto">
                  <DeleteDialog
                    title="Delete Round?"
                    message={`Are you sure you want to delete this Round? This action cannot be undone.`}
                    yesLabel="Delete"
                    onYes={deleteRecord}
                    permission={canEdit && 'rounds.edit'}
                  >
                    <Button
                      label="Delete"
                      glyph="delete"
                      color="red"
                      size={200}
                      isDisabled={isDeleting}
                      permission={canEdit && 'rounds.edit'}
                    />
                  </DeleteDialog>
                </div>
              }
            >
              <Form isCompact>
                <FormSection maxWidth="100%">
                  {!isFinalStatus && data?.requested_updates && (
                    <Alert small glyph="warning" type="negative">
                      <div className="flex items-center">
                        <div className="flex-[1_1_auto]">
                          <div className="font-[700]">Updates requested:</div>
                          <div>{data?.requested_updates}</div>
                        </div>

                        <SummonOverlay overlay={<RequestUpdatesOverlay roundId={id} value={data?.requested_updates || ''} />}>
                          <Button label="Edit" glyph="edit" size={100} />
                        </SummonOverlay>
                      </div>
                    </Alert>
                  )}

                  <Input
                    label="Name"
                    model="name"
                    validations={{
                      presence: {
                        message: 'Please enter a name',
                      },
                    }}
                  />

                  <DateTimeInput
                    model="started_at"
                    label="Start Date and Time"
                    className="!flex-auto min-w-[400px]"
                    validations={{
                      presence: {
                        message: 'Please enter a date and time',
                      },
                    }}
                  />

                  {isFinalStatus && (
                    <DateTimeInput isEditable={false} model="ended_at" label="End Date and Time" className="!flex-auto min-w-[400px]" />
                  )}

                  <OverlaySelector
                    isCompact
                    className="!flex-auto"
                    label="Supervisor"
                    blankLabel="Select Supervisor…"
                    icon="employees"
                    type="employees.active"
                    model="supervisor"
                    selectTitle={(data) => data?.name}
                    selectDescription={() => null}
                  />

                  <MultiOverlaySelector
                    isCompact
                    className="!flex-auto"
                    label="Staff"
                    blankLabel="Select Staff…"
                    icon="employees"
                    type="employees.active"
                    model="employees"
                    selectTitle={(data) => data?.name}
                    selectDescription={() => null}
                  />
                </FormSection>
              </Form>
            </Accordion>

            <Accordion
              isOpen
              minimal
              size={200}
              title="Signatures"
              titleAfter={
                size(signees) > 0 && <RoundSignatureStatus small status={canSignOff ? 'signed' : data?.signature_status} className="ml-2" />
              }
              icon="signature"
              rootClassNames="!m-0 !border-solid !border-divider"
            >
              {isLoadingSignees || isSigneesEmpty ? (
                <State
                  isLoading={isLoadingSignees}
                  isEmpty={isSigneesEmpty}
                  glyph="signature"
                  emptyDescription="No supervisor or staff added yet"
                  minHeight={100}
                />
              ) : (
                signees?.map?.((signee) => {
                  const didSign = !!(signee.last_signature && signee.last_signed_at)

                  return (
                    <div className="flex items-center [&+&]:border-t border-0 border-solid border-divider py-1.5 px-3 text-[0.9rem]">
                      <div className="flex items-center font-[500] flex-nowrap flex-[1_1_auto] truncate min-w-0">
                        <Avatar src={signee.reference?.avatar || ''} initials={signee.reference?.name} size={18} className="mr-1.5" />
                        <div className="whitespace-nowrap truncate min-w-0">{signee.reference?.name}</div>
                      </div>

                      {didSign ? (
                        <div className="flex flex-nowrap items-center pl-3">
                          <Status small label="Signed" color="green" glyph="tick_circle" className="mr-1" />
                          <Status
                            small
                            label={usDateTime(signee.last_signed_at, timezone)}
                            color="lightGray"
                            glyph="date"
                            className="!bg-transparent"
                          />

                          {canEdit && (
                            <div className="ml-auto pl-2">
                              <SummonOverlay overlay={<RoundsSignatureOverlay dataID={signee.id} />} permission={canEdit && 'rounds.edit'}>
                                <Button
                                  label="Edit"
                                  glyph="edit"
                                  size={100}
                                  css={{ svg: { margin: '0 !important' } }}
                                  permission={canEdit && 'rounds.edit'}
                                />
                              </SummonOverlay>
                            </div>
                          )}
                        </div>
                      ) : (
                        <SummonOverlay
                          overlay={<RoundsSignatureOverlay dataID={signee.id} signatureType="initial" />}
                          permission={canEdit && 'rounds.edit'}
                        >
                          <Button label="Add Signature" glyph="signature" size={100} permission={canEdit && 'rounds.edit'} />
                        </SummonOverlay>
                      )}
                    </div>
                  )
                })
              )}
            </Accordion>

            <Accordion isOpen minimal size={200} title="Template Details" icon="rounds" rootClassNames="!m-0 !border-solid !border-divider">
              <DataList isCompact className="px-4 py-2">
                <DataList.Item
                  label="Template Name"
                  value={
                    <Link
                      target="_blank"
                      to={`/settings/rounds-templates/${template.id}`}
                      className="inline-flex flex-nowrap items-center font-[500]"
                    >
                      {template.name}
                      <Glyph glyph="external_link" size={12} className="ml-1" color={COLORS.blue} />
                    </Link>
                  }
                />
                <DataList.Item
                  label="Documentation"
                  value={
                    <div className="grid gap-2">
                      {Object.entries(ROUNDS_CATEGORIES).map(([key, category]) => {
                        if (!template?.documentation?.[key]) return null

                        return (
                          <div className="flex items-center font-[500]">
                            <Icon icon={category.icon} size={18} className="mr-1.5" />
                            {category.label}
                          </div>
                        )
                      })}
                    </div>
                  }
                />

                <DataList.Item label="Clients Import" value={CLIENTS_IMPORT_TYPES[template.client_import_type]} />

                {template.client_import_type === 'dynamic' && (
                  <>
                    <DataList.Item
                      label="Import from Locations"
                      value={
                        size(template.imported_houses) === 0 ? null : (
                          <div className="grid gap-2">
                            {template.imported_houses.map((house) => {
                              return (
                                <div className="flex items-center font-[500]">
                                  <Avatar src={house.avatar} initials={house.name} size={18} className="mr-1.5" />
                                  {house.name}
                                </div>
                              )
                            })}
                          </div>
                        )
                      }
                    />

                    <DataList.Item label="Programs Import Type" value={PROGRAMS_IMPORT_TYPES[template.programs_import_type]} />

                    {template.programs_import_type === 'programs' && (
                      <DataList.Item
                        label="Import from Programs"
                        value={
                          size(template.imported_programs) === 0 ? null : (
                            <div className="grid gap-2">
                              {template.imported_programs.map((program) => {
                                return (
                                  <div className="flex items-center font-[500]">
                                    <Avatar src={program.avatar} initials={program.name} size={18} className="mr-1.5" />
                                    {program.name}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        }
                      />
                    )}

                    {template.programs_import_type === 'program_lists' && (
                      <DataList.Item
                        label="Import from Program Lists"
                        value={
                          size(template.imported_phases) === 0 ? null : (
                            <div className="grid gap-2">
                              {template.imported_phases.map((phase) => {
                                return (
                                  <div className="flex items-center font-[500]">
                                    <Avatar src={phase.avatar} initials={phase.name} size={18} className="mr-1.5" />
                                    {phase.name}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        }
                      />
                    )}
                  </>
                )}
              </DataList>
            </Accordion>
          </div>

          <RoundLinesTable
            isFinalStatus={isFinalStatus}
            canEdit={canEdit}
            roundName={data?.name || template?.name}
            roundId={id}
            template={template}
          />
        </Overlay.Content>
      </Overlay>
    </>
  )
}

const RoundLinesTable = (props: any) => {
  const { canEdit, roundId, template, roundName, isFinalStatus } = props

  const tableProps = useDataTable({
    name: ['rounds', roundId, 'round_lines'],
    endpoint: `/rounds/${roundId}/round_lines`,
    updateDeleteEndpoint: '/round_lines',
    pageSize: 15,
  })

  const { mutateAsync: batchUpdateRoundLines, isLoading: isSavingBatch } = useUpdate({
    name: ['rounds', roundId, 'round_lines', 'batch_create'],
    url: `/round_lines/batch`,
    invalidate: 'round_lines',
  })

  const { verifyPermission } = useVerifyPermission()

  const {
    data: roundLines,
    deleteRecords,
    filters,
    isDeleting,
    isLoading,
    isRefetching,
    meta,
    onCurrentPageChange,
    onFiltersUpdate,
    queryKey,
  } = tableProps

  const hasFilters = size(filters) > 0
  const isEmpty = size(roundLines) === 0

  const [selectedIds, setSelectedIds] = React.useState<any>([])
  const [selectedRoundLines, setSelectedRoundLines] = React.useState<any>([])

  const roundLinesMap: any = React.useMemo(() => {
    return arrayToMap(roundLines)
  }, [roundLines])

  // sync selected IDs with selected round lines
  React.useEffect(() => {
    setSelectedRoundLines((current) => {
      const updated: any = []

      for (const id of selectedIds) {
        // get latest value from roundLinesMap
        if (roundLinesMap[id]) {
          updated.push(roundLinesMap[id])

          continue
        }

        // get existing value from current
        const roundLine = current.find((o: any) => o.id === id)

        if (roundLine) {
          updated.push(roundLine)
        }
      }

      return updated
    })
  }, [selectedIds, roundLinesMap])

  const pagesArray = React.useMemo(() => {
    return Array.from({ length: meta?.pages || 0 }, (_, i) => i + 1)
  }, [meta?.pages])

  const isAllSelected = React.useMemo(() => {
    if (!roundLines || size(selectedIds) === 0) return false

    for (const roundLine of roundLines) {
      if (!selectedIds.includes(roundLine.id)) return false
    }

    return true
  }, [selectedIds, roundLines])

  const isSomeSelected = size(selectedIds) > 0

  const handleBatchAddDocumentation = async ({ documentation, recordType }) => {
    const saveData: any = []
    const config = ROUNDS_CATEGORIES[recordType]

    for (let i = 0; i < selectedRoundLines.length; i++) {
      const roundLine = selectedRoundLines[i]

      saveData.push({
        id: roundLine.id,
        [`${recordType}_attributes`]: documentation[i],
      })
    }

    await batchUpdateRoundLines(saveData)

    if (config?.invalidate) {
      invalidateQueries(config.invalidate)
    }
  }

  const handleBatchDelete = async () => {
    if (size(selectedIds) === 0) return

    await deleteRecords(selectedIds.join(','))

    setSelectedIds([])
  }

  const tableConfig = React.useMemo(() => {
    if (!template) return []

    const documentationColumns: any = []

    for (const recordType in ROUNDS_CATEGORIES) {
      const config = ROUNDS_CATEGORIES[recordType]

      const hasFeatureFlag = verifyPermission({ featureFlag: config.featureFlag })
      const hasCreatePermission = verifyPermission({ permission: `${config.permission}.create` })
      const hasEditPermission = verifyPermission({ permission: `${config.permission}.edit` })

      if (!hasFeatureFlag) continue

      if (!config) {
        console.error(`No config found for recordType "${recordType}"`)
        continue
      }

      if (!template.documentation[recordType]) continue

      documentationColumns.push({
        title: config.label,
        width: 'minmax(220px, 1fr)',
        getClassName: (roundLine) => (!!roundLine[recordType] ? '!bg-[#f2fdf2]' : ''),
        render: (roundLine) => (
          <RoundLineRecord
            canEdit={canEdit}
            roundLine={roundLine}
            roundName={roundName}
            record={roundLine[recordType]}
            recordType={recordType}
            roundLineId={roundLine.id}
            updateQueryKey={queryKey}
            hasCreatePermission={hasCreatePermission}
            hasEditPermission={hasEditPermission}
          />
        ),
      })
    }

    const result: any = [
      !isFinalStatus && {
        title: (
          <div className="w-full flex justify-center">
            <RadioCheckElement
              type="checkbox"
              className="cursor-pointer"
              isChecked={isAllSelected}
              isIndeterminate={isSomeSelected && !isAllSelected}
              onClick={() => {
                if (isAllSelected) {
                  setSelectedIds((current) => {
                    const updated = [...current]
                    const pageIds = roundLines.map((o: any) => o.id)

                    return updated.filter((id: any) => !pageIds.includes(id))
                  })
                } else {
                  setSelectedIds((current) => {
                    const updated: any = [...current]

                    for (const roundLine of roundLines) {
                      if (!current.includes(roundLine.id)) {
                        updated.push(roundLine.id)
                      }
                    }

                    return updated
                  })
                }
              }}
            />
          </div>
        ),
        width: '40px',
        className: 'mq800:sticky mq800:left-0 mq800:z-[3] mq800:!bg-white',
        render: (roundLine: any) => (
          <div className="w-full flex justify-center">
            <RadioCheckElement
              type="checkbox"
              className="cursor-pointer"
              isChecked={selectedIds.includes(roundLine.id)}
              onClick={() => {
                if (selectedIds.includes(roundLine.id)) {
                  setSelectedIds(selectedIds.filter((id: any) => id !== roundLine.id))
                } else {
                  setSelectedIds([...selectedIds, roundLine.id])
                }
              }}
            />
          </div>
        ),
      },
      {
        title: 'Clients',
        width: 'minmax(280px, 1fr)',
        className: clsx('mq800:sticky mq800:z-[3] mq800:!bg-white mq800:!shadow-right-hard-3', isFinalStatus ? 'left-0' : 'left-[40px]'),
        render: ({ reference }: any) => (
          <div>
            <div className="flex flex-nowrap items-center">
              <Avatar src={reference.avatar} initials={reference.name} size={28} className="mr-2" />

              <div>
                <Link
                  to={`${getClientLink(reference)}/rounds`}
                  target="_blank"
                  className="font-[600] items-center decoration-none inline-flex flex-nowrap [&:hover_svg]:!fill-blue-500 [&:hover_svg]:!opacity-100"
                >
                  {reference.name}

                  <Glyph glyph="external_link" size={12} className="ml-1 opacity-0" />
                </Link>

                <div className="text-text-muted opacity-80">
                  {startCase(reference?.sex) || '–'}, {age(reference?.dob)} y/o, #{reference?.behave_id}
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'Round Presence',
        width: 'minmax(200px, 1fr)',
        render: (roundLine) => (
          <RoundLinePresence
            roundLineId={roundLine.id}
            clientId={roundLine.reference.id}
            datedAt={roundLine.round_note?.dated_at}
            value={roundLine.round_note?.presence}
            updateQueryKey={queryKey}
            canEdit={canEdit}
          />
        ),
      },
      {
        title: 'Round Note',
        width: 'minmax(270px, 1fr)',
        render: (roundLine) => (
          <RoundLineNote
            roundLineId={roundLine.id}
            clientId={roundLine.reference.id}
            datedAt={roundLine.round_note?.dated_at}
            value={roundLine.round_note?.notes || ''}
            updateQueryKey={queryKey}
            canEdit={canEdit}
          />
        ),
      },
      ...documentationColumns,
      {
        title: 'Bed Assignment',
        width: 'minmax(350px, 1fr)',
        render: ({ client_current_bed_assignment }: any) => {
          if (!client_current_bed_assignment) return null

          return (
            <Flex centerY gap="0.25rem">
              <div className="flex items-center flex-nowrap">
                <Avatar
                  src={client_current_bed_assignment.place?.house.avatar}
                  initials={client_current_bed_assignment.place?.house?.name}
                  size={20}
                  className="mr-1.5"
                />
                <div className="text-[0.96rem] flex-[1_1_auto] truncate">{client_current_bed_assignment.place?.house?.name}</div>
              </div>

              <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

              <div className="flex items-center flex-nowrap">
                <Icon icon="rooms" size={18} className="mr-1" />
                <div className="text-[0.96rem] flex-[1_1_auto] truncate">{client_current_bed_assignment.place?.room?.name}</div>
              </div>

              <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

              <div className="flex items-center flex-nowrap">
                <Icon icon="beds" size={18} className="mr-1" />
                <div className="text-[0.96rem] flex-[1_1_auto] truncate">{client_current_bed_assignment.place?.name}</div>
              </div>
            </Flex>
          )
        },
      },
      {
        title: 'Program Assignments',
        width: 'minmax(400px, 1fr)',
        render: ({ client_program_assignments }: any) => {
          const programsCount = size(client_program_assignments)

          if (programsCount === 0) return null

          return (
            <div className="flex items-center flex-nowrap justify-between">
              {client_program_assignments.map((program, index: number) => {
                if (index > 0) return null

                return (
                  <Flex centerY gap="0.25rem">
                    <div className="flex items-center flex-nowrap">
                      <Icon icon="programs" size={18} className="mr-1" />
                      <div className="text-[0.96rem] flex-[1_1_auto] font-[500]">{program?.name}</div>
                    </div>

                    <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                    <div className="text-[0.96rem] flex-[1_1_auto]">{program?.list}</div>
                  </Flex>
                )
              })}

              {programsCount > 1 && (
                <Tooltip
                  content={
                    <div className="grid gap-1.5">
                      {client_program_assignments.map((program, index: number) => {
                        if (index === 0) return null

                        return (
                          <Flex centerY gap="0.25rem">
                            <div className="flex items-center flex-nowrap">
                              <Icon icon="programs" size={18} className="mr-1" />
                              <div className="text-[0.96rem] flex-[1_1_auto] font-[500]">{program?.name}</div>
                            </div>

                            <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                            <div className="text-[0.96rem] flex-[1_1_auto]">{program?.list}</div>
                          </Flex>
                        )
                      })}
                    </div>
                  }
                >
                  <Status small color="lightGray" glyphColor={COLORS.blue} glyph="info" label={`+${programsCount - 1} more`} />
                </Tooltip>
              )}
            </div>
          )
        },
      },
      {
        title: 'Care Team',
        width: 'minmax(400px, 1fr)',
        render: ({ client_care_team }: any) => {
          const careTeamCount = size(client_care_team)

          if (careTeamCount === 0) return null

          return (
            <div className="flex items-center flex-nowrap justify-between">
              {client_care_team.map((connection, index: number) => {
                if (index > 0) return null

                return (
                  <Flex centerY gap="0.25rem">
                    <div className="flex items-center flex-nowrap">
                      <Avatar src={connection.to?.avatar} initials={connection.to?.name} size={18} className="mr-1" />
                      <div className="text-[0.96rem] flex-[1_1_auto] font-[500]">{connection.to?.name}</div>
                    </div>

                    {CARE_TEAM_RELATION_TYPES[connection?.subcategory] && (
                      <div className="text-[0.96rem] flex-[1_1_auto]">({CARE_TEAM_RELATION_TYPES[connection.subcategory]})</div>
                    )}
                  </Flex>
                )
              })}

              {careTeamCount > 1 && (
                <Tooltip
                  content={
                    <div className="grid gap-1.5">
                      {client_care_team.map((connection, index: number) => {
                        if (index === 0) return null

                        return (
                          <Flex centerY gap="0.25rem">
                            <div className="flex items-center flex-nowrap">
                              <Avatar src={connection.to?.avatar} initials={connection.to?.name} size={18} className="mr-1" />
                              <div className="text-[0.96rem] flex-[1_1_auto] font-[500]">{connection.to?.name}</div>
                            </div>

                            {CARE_TEAM_RELATION_TYPES[connection?.subcategory] && (
                              <div className="text-[0.96rem] flex-[1_1_auto]">({CARE_TEAM_RELATION_TYPES[connection.subcategory]})</div>
                            )}
                          </Flex>
                        )
                      })}
                    </div>
                  }
                >
                  <Status small color="lightGray" glyphColor={COLORS.blue} glyph="info" label={`+${careTeamCount - 1} more`} />
                </Tooltip>
              )}
            </div>
          )
        },
      },
      {
        title: 'Service Assignments',
        width: 'minmax(400px, 1fr)',
        render: ({ client_service_episodes }: any) => {
          const serviceAssignmentsCount = size(client_service_episodes)

          if (serviceAssignmentsCount === 0) return null

          return (
            <div className="flex items-center flex-nowrap justify-between truncate">
              {client_service_episodes.map((assignment, index: number) => {
                if (index > 0) return null

                return (
                  <Flex centerY nowrap gap="0.25rem" className="!truncate">
                    <div className="flex items-center flex-nowrap truncate min-w-0">
                      <Icon icon="treatment_episodes" size={18} className="mr-1" />
                      <div className="text-[0.96rem] flex-[1_1_auto] font-[500] truncate">{assignment?.name}</div>
                    </div>

                    <div className="text-[0.96rem] flex-[1_1_auto] truncate">({assignment?.type})</div>
                  </Flex>
                )
              })}

              {serviceAssignmentsCount > 1 && (
                <Tooltip
                  content={
                    <div className="grid gap-1.5">
                      {client_service_episodes.map((assignment, index: number) => {
                        if (index === 0) return null

                        return (
                          <Flex centerY gap="0.25rem">
                            <div className="flex items-center flex-nowrap">
                              <Icon icon="treatment_episodes" size={18} className="mr-1" />
                              <div className="text-[0.96rem] flex-[1_1_auto] font-[500]">{assignment?.name}</div>
                            </div>

                            <div className="text-[0.96rem] flex-[1_1_auto]">({assignment?.type})</div>
                          </Flex>
                        )
                      })}
                    </div>
                  }
                >
                  <Status small color="lightGray" glyphColor={COLORS.blue} glyph="info" label={`+${serviceAssignmentsCount - 1} more`} />
                </Tooltip>
              )}
            </div>
          )
        },
      },
    ]

    return compact(result)
  }, [
    canEdit,
    isAllSelected,
    isFinalStatus,
    isSomeSelected,
    queryKey,
    roundLines,
    roundName,
    selectedIds,
    selectedRoundLines,
    template,
    ROUNDS_CATEGORIES,
  ])

  const templateColumns = React.useMemo(() => {
    return tableConfig.map((col: any) => col.width).join(' ')
  }, [tableConfig])

  return (
    <Form
      isCompact
      className="p-4 pt-0 mq1024:pt-4 mq1024:pl-2 mq1024:grid gap-4 grid-cols-1 grid-rows-[auto_1fr] overflow-hidden"
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <Card className="px-3 py-2">
        <Filters config={FILTERS_CONFIG} onUpdate={onFiltersUpdate} localStorageKey="calendar_v2" />
      </Card>

      {isLoading || isEmpty ? (
        <Card key="loading-state">
          <State
            isLoading={isLoading}
            isEmpty={isEmpty}
            icon="clients"
            title="Clients"
            emptyDescription={hasFilters ? 'No clients founds for selected filters' : 'No clients added to this round'}
          />
        </Card>
      ) : (
        <Card key="data-state" className="grid grid-cols-1 grid-rows-[auto_1fr_auto] mt-4 mq1024:mt-0">
          <div className="flex items-center px-3 py-1.5 border-b border-0 border-solid border-divider">
            <Flex centerY gap="0.5rem">
              <div className="text-[1.05rem] font-[600]">
                {countWord('Clients', isSomeSelected ? size(selectedIds) : tableProps?.meta?.count || 0)}
                {isSomeSelected && (
                  <span className="font-[400] text-[0.92em] inline-block ml-1.5 text-text-muted opacity-80">selected</span>
                )}
              </div>

              {!isFinalStatus && (
                <>
                  <SummonOverlay overlay={<AllClientsSelectorOverlay roundId={roundId} />} permission={canEdit && 'rounds.edit'}>
                    <Button label="Add More Clients" glyph="add" type="primary" size={100} permission={canEdit && 'rounds.edit'} />
                  </SummonOverlay>

                  <div className="w-[1px] h-5 bg-gray opacity-20" />

                  {Object.keys(ROUNDS_CATEGORIES).map((recordType) => {
                    const config = ROUNDS_CATEGORIES[recordType]

                    if (!template.documentation[recordType] || !config.worksheetOverlay) return null

                    const WorksheetOverlayTag = config.worksheetOverlay

                    return (
                      <SummonOverlay
                        overlay={
                          <WorksheetOverlayTag
                            allow=""
                            editDisabledColumns={config?.worksheetEditDisabledColumns}
                            initialData={config?.getWorksheetInitialData?.(selectedRoundLines) || []}
                            save={async (documentation: any) => {
                              await handleBatchAddDocumentation({ documentation, recordType })
                            }}
                            isSaving={isSavingBatch}
                          />
                        }
                        permission={canEdit && 'rounds.edit'}
                      >
                        <Button
                          label={size(selectedIds) > 0 ? `Edit ${countWord(config.label, size(selectedIds))}` : `Edit ${config.label}`}
                          glyph="stack"
                          size={100}
                          type="default"
                          isDisabled={!isSomeSelected}
                          permission={canEdit && 'rounds.edit'}
                        />
                      </SummonOverlay>
                    )
                  })}

                  <div className="w-[1px] h-5 bg-gray opacity-10" />

                  <DeleteDialog
                    title="Delete Round Lines?"
                    message={`Are you sure you want to delete ${countWord(
                      'Round Lines',
                      size(selectedIds),
                    )}? This action cannot be undone.`}
                    yesLabel={`Delete ${countWord('Round Lines', size(selectedIds))}`}
                    onYes={handleBatchDelete}
                    isDisabled={!isSomeSelected}
                    permission={canEdit && 'rounds.edit'}
                  >
                    <Button
                      label={size(selectedIds) > 0 ? `Delete ${countWord('Round Lines', size(selectedIds))}` : `Delete Round Lines`}
                      glyph="delete"
                      color="red"
                      size={100}
                      isDisabled={!isSomeSelected || isDeleting}
                      permission={canEdit && 'rounds.edit'}
                    />
                  </DeleteDialog>
                </>
              )}
            </Flex>

            <div className="mx-auto" />

            {isRefetching && (
              <div className="ml-4">
                <Loader size={20} />
              </div>
            )}
          </div>

          <div className=" overflow-y-auto">
            <GridTable
              className={clsx('min-h-full text-[0.92rem]', isRefetching && 'opacity-60')}
              useBanding={false}
              templateColumns={templateColumns}
            >
              <GridTable.Header className="relative z-[10]">
                {tableConfig.map((column: any) => (
                  <GridTable.Cell centerY key={column.title} className={column.className}>
                    {column.title}
                  </GridTable.Cell>
                ))}
              </GridTable.Header>

              {roundLines?.map?.((roundLine: any) => {
                return (
                  <GridTable.Row
                    key={roundLine.id}
                    paddingY="0.1rem"
                    className="last:!border-b last:!border-solid last:!border-0 last:!border-divider"
                  >
                    {tableConfig.map((column: any) => (
                      <GridTable.Cell
                        key={`${roundLine.id}-${column.title}`}
                        className={clsx(
                          'relative flex items-center [&>*]:flex-[1_1_auto]',
                          column?.getClassName?.(roundLine),
                          column.className,
                        )}
                      >
                        {column.render?.(roundLine) || <span className="text-text-muted opacity-60">–</span>}
                      </GridTable.Cell>
                    ))}
                  </GridTable.Row>
                )
              })}
            </GridTable>
          </div>

          <div className="flex items-center justify-between px-3 py-1.5 border-t border-0 border-solid border-divider">
            {meta && (
              <Flex centerY gap="0.5rem">
                <Button
                  label="← Back"
                  isDisabled={!meta?.prev}
                  display="inline-flex"
                  type="minimal"
                  size={200}
                  onClick={() => {
                    if (meta?.prev) onCurrentPageChange(meta?.prev)
                  }}
                />

                {meta?.pages >= 2 ? (
                  <DropdownMenu
                    trigger={
                      <div css={STYLES.dropdown}>
                        <div>
                          <span className="!font-[600]">Page {meta?.page}</span> of{' '}
                          <span className="!font-[400] !text-text-muted">{meta?.pages}</span>
                        </div>
                        <Glyph glyph="triangle_down" size={12} />
                      </div>
                    }
                  >
                    {pagesArray.map((pageNumber) => (
                      <DropdownMenuItem
                        key={pageNumber}
                        isActive={pageNumber === meta?.page}
                        label={`Page ${pageNumber}`}
                        onClick={() => onCurrentPageChange(pageNumber)}
                      />
                    ))}
                  </DropdownMenu>
                ) : (
                  <div>
                    <span className="!font-[600]">Page {meta?.page}</span> of{' '}
                    <span className="!font-[400] !text-text-muted">{meta?.pages}</span>
                  </div>
                )}

                <Button
                  label="Next →"
                  isDisabled={!meta?.next}
                  display="inline-flex"
                  type="minimal"
                  size={200}
                  onClick={() => {
                    if (meta?.next) onCurrentPageChange(meta?.next)
                  }}
                />
              </Flex>
            )}
          </div>
        </Card>
      )}
    </Form>
  )
}

const RoundLinePresence = (props: any) => {
  const { timezone } = useSettings()
  const queryClient = useQueryClient()

  const { allowed: hasEditPermission } = usePermissions({ permission: 'rounds.edit' })

  const { roundLineId, clientId, value, updateQueryKey, datedAt, canEdit } = props

  const { mutateAsync, isLoading } = useUpdate({
    name: ['update_round_line_presence'],
    url: `/round_lines/${roundLineId}`,
    onSuccess: ({ data }) => {
      const queryKey = [getPrefix(), updateQueryKey].flat()

      if (!queryKey) return

      queryClient.setQueryData(queryKey, (oldData: any) => {
        return produce(oldData, (draft) => {
          if (!draft?.data) return

          const index = draft.data?.findIndex((item: any) => item.id === data.id)

          if (index === -1) return

          draft.data[index] = data
        })
      })
    },
  })

  const handleUpdate = async (input: any) => {
    if (isLoading || !input?.value || input.value === value) return

    await mutateAsync({
      round_note_attributes: {
        client_id: clientId,
        presence: input.value,
        dated_at: datedAt || getNowDT(timezone).toISO(),
      },
    })
  }

  return (
    <div className={isLoading ? '!pointer-events-none' : ''}>
      <RadioGroup
        isCompact
        isEditable={canEdit && hasEditPermission}
        model={`${roundLineId}_presence`}
        layout="horizontal-dense"
        value={value}
        onChange={handleUpdate}
        isDisabled={isLoading}
      >
        <Radio label="Present" value="present" />
        <Radio label="Absent" value="absent" />
      </RadioGroup>
    </div>
  )
}

export const RoundLineNote = (props: any) => {
  const [value, setValue] = React.useState(props.value || '')

  const { timezone } = useSettings()
  const { allowed: hasEditPermission } = usePermissions({ permission: 'rounds.edit' })

  const queryClient = useQueryClient()

  const { roundLineId, clientId, updateQueryKey, datedAt, canEdit } = props

  const { mutateAsync, isLoading } = useUpdate({
    name: ['update_round_line_presence'],
    url: `/round_lines/${roundLineId}`,
    onSuccess: ({ data }) => {
      const queryKey = [getPrefix(), updateQueryKey].flat()

      if (!queryKey) return

      queryClient.setQueryData(queryKey, (oldData: any) => {
        return produce(oldData, (draft) => {
          if (!draft?.data) return

          const index = draft.data?.findIndex((item: any) => item.id === data.id)

          if (index === -1) return

          draft.data[index] = data
        })
      })
    },
  })

  const handleBlur = async (e: any) => {
    const newValue = e.target.value

    if (isLoading || newValue === props.value) return

    await mutateAsync({
      round_note_attributes: {
        client_id: clientId,
        notes: newValue,
        dated_at: datedAt || getNowDT(timezone).toISO(),
      },
    })
  }

  if (!hasEditPermission || !canEdit) {
    return <div className="whitespace-pre">{value || <div className="text-text-muted opacity-60">–</div>}</div>
  }

  return (
    <>
      <TextareaAutosize
        rows={2}
        css={STYLES.textarea}
        className={clsx('my-[-0.1rem] -mx-2', isLoading && 'is-disabled')}
        value={value}
        onChange={(e) => {
          setValue(e.target.value || '')
        }}
        onBlur={handleBlur}
        disabled={isLoading}
      />

      {isLoading && <Loader size={16} className="absolute top-2 right-2" />}
    </>
  )
}

export const RoundLineRecord = (props: any) => {
  const {
    before,
    hasCreatePermission,
    hasEditPermission,
    invalidateQueryKey,
    record,
    recordType,
    roundLine,
    roundLineId,
    roundName,
    updateQueryKey,
    canEdit,
  } = props

  const queryClient = useQueryClient()

  const { timezone } = useSettings()
  const { allowed: canEditRound } = usePermissions({ permission: 'rounds.edit' })

  const config = React.useMemo(() => {
    return ROUNDS_CATEGORIES[recordType]
  }, [ROUNDS_CATEGORIES, recordType])

  const { mutateAsync: updateAsync, isLoading: isSaving } = useUpdate({
    name: ['update_round_line_record'],
    url: `/round_lines/${roundLineId}`,
    invalidate: invalidateQueryKey,
    onSuccess: ({ data }) => {
      const queryKey = [getPrefix(), updateQueryKey].flat()

      if (!queryKey) return

      queryClient.setQueryData(queryKey, (oldData: any) => {
        return produce(oldData, (draft) => {
          if (!draft?.data) return

          const index = draft.data?.findIndex((item: any) => item.id === data.id)

          if (index === -1) return

          draft.data[index] = data
        })
      })
    },
  })

  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useUpdate({
    name: ['update_round_line_record'],
    url: `/round_lines/${roundLineId}`,
    invalidate: invalidateQueryKey,
    onSuccess: ({ data }) => {
      const queryKey = [getPrefix(), updateQueryKey].flat()

      if (!queryKey) return

      queryClient.setQueryData(queryKey, (oldData: any) => {
        return produce(oldData, (draft) => {
          if (!draft?.data) return

          const index = draft.data?.findIndex((item: any) => item.id === data.id)

          if (index === -1) return

          if (!draft.data[index]?.[recordType]) return

          draft.data[index][recordType] = null
        })
      })
    },
  })

  const handleSave = async (saveData: any) => {
    if (!ROUNDS_CATEGORIES[recordType]) {
      console.error('Invalid record type', recordType)
      return
    }

    await updateAsync({
      [`${recordType}_attributes`]: saveData,
    })
  }

  const handleDelete = async () => {
    if (!ROUNDS_CATEGORIES[recordType]) {
      console.error('Invalid record type', recordType)
      return
    }

    await deleteAsync({
      [`${recordType}_attributes`]: {
        _destroy: true,
      },
    })
  }

  const OverlayTag = config?.overlay

  if (!OverlayTag) return

  const isNew = !record?.id
  const hasRecord = !!record
  const dataID = record?.id || 'new'

  if (isNew && !hasCreatePermission) {
    return <MissingPermission roundName={roundName} recordType={recordType} permissionType="create" />
  }

  if (!isNew && !hasEditPermission) {
    return <MissingPermission roundName={roundName} recordType={recordType} permissionType="edit" />
  }

  return (
    <div className="flex items-center w-full">
      {before}

      <div className="flex items-center flex-[1_1_auto] pr-3">
        {hasRecord ? (
          <div>
            <Status small label="Done" color="green" glyph="tick_circle" className="mr-1 !bg-transparent" />
            <Status
              small
              label={usDateTime(record?.created_at, timezone)}
              glyph="date"
              className="mt-0.75 !bg-transparent [&_span]:!text-text-muted [&_span]:!font-[400]"
            />
          </div>
        ) : canEdit ? (
          <Status small label="To-Do" color="blue" />
        ) : (
          <span className="text-text-muted opacity-60">–</span>
        )}
      </div>

      <SummonOverlay
        key={`data-id-${dataID}`}
        permission={canEditRound && (isNew ? hasCreatePermission : hasEditPermission)}
        overlay={
          <OverlayTag
            showBackdrop
            dataID={record?.id || 'new'}
            initialData={!!record?.id ? undefined : config.getOverlayInitialData?.(roundLine)}
            save={handleSave}
            isSaving={isSaving}
            deleteRecord={handleDelete}
            isDeleting={isDeleting}
          />
        }
      >
        {!!record?.id ? (
          <Button
            label={canEdit ? 'Edit' : 'View'}
            glyph={canEdit ? 'edit' : 'view'}
            type="default"
            size={100}
            permission={canEditRound && hasEditPermission}
          />
        ) : (
          <Button label="Add" glyph="add" type="default" size={100} permission={canEdit && canEditRound && hasCreatePermission} />
        )}
      </SummonOverlay>
    </div>
  )
}

const AllClientsSelectorOverlay = (props: any) => {
  const { roundId, onClose } = props

  const [selected, setSelected] = React.useState<any>([])

  const { mutateAsync, isLoading } = useCreate({
    name: ['create_round_lines'],
    url: `/round_lines/batch`,
    invalidate: 'round_lines',
  })

  const handleAddSelected = async () => {
    if (size(selected) === 0) return

    const newRoundLines = selected.map((client: any) => ({
      reference_id: client.id,
      reference_type: client.type,
      round_id: roundId,
    }))

    await mutateAsync(newRoundLines)

    onClose()
  }

  const isEmpty = size(selected) === 0

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} maxWidth={80}>
      <Overlay.Header title="Add More Clients" />

      <Overlay.Content className="py-2 px-5">
        <AllClientsTable
          asCard
          canBatchSelect
          defaultTab="current_clients"
          tabsListClassName="!ml-0 mb-3"
          onRowSelectionUpdate={setSelected}
        />
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={isEmpty ? 'Select Clients to Add' : `Add ${countWord('Clients', size(selected))}`}
          type="primary"
          color="blue"
          glyph={isEmpty ? 'info' : 'add'}
          onClick={handleAddSelected}
          isDisabled={isEmpty}
          isLoading={isLoading}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const RequestUpdatesOverlay = (props: any) => {
  const { value, roundId, onClose, refetch } = props

  const { mutateAsync: requestRoundUpdates, isLoading }: any = useCreate({
    name: ['rounds', roundId, 'request_updates'],
    url: `/rounds/${roundId}/request_updates`,
    invalidate: 'rounds',
  })

  const form = React.useRef(null)
  const [isValid, setIsValid] = React.useState(false)

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    await requestRoundUpdates({
      requested_updates: formData.requested_updates,
    })

    await refetch()

    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={onClose}>
      <Overlay.Header title="Request Updates" />

      <Overlay.Content>
        <Section>
          <Form getForm={form} onValidationUpdate={setIsValid} initialModel={{ requested_updates: value || '' }}>
            <Textarea
              label="Updates Requested"
              model="requested_updates"
              validations={{
                presence: {
                  message: 'Please enter the updates requested',
                },
              }}
            />
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Request Updates"
          type="primary"
          color="red"
          glyph="tick_circle"
          onClick={handleSave}
          isDisabled={!isValid}
          isLoading={isLoading}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const getNowDT = (timezone: any) => {
  let localTime = DateTime.local().set({ millisecond: 0 })

  return DateTime.fromObject({
    year: localTime.year,
    month: localTime.month,
    day: localTime.day,
    hour: localTime.hour,
    minute: localTime.minute,
    second: localTime.second,
    millisecond: localTime.millisecond,
    zone: timezone,
  })
}

const MissingPermission = (props: any) => {
  const { roundName, permissionType = 'create', recordType } = props

  const { tenant, user } = useSettings()

  const config = ROUNDS_CATEGORIES[recordType]

  if (!config) return null

  const contactEmail = tenant?.contacts?.roles_permissions?.email || tenant?.contacts?.owner?.email

  const emailSubject = `Permission request in ${tenant?.name ? `"${tenant.name}" Behave Health EHR` : `Behave Health EHR`}`

  const emailBody = getNoPermissionMessage({
    roundName,
    tenant,
    user,
    permissionType,
    featureName: config.label,
  })

  return (
    <Tooltip
      color={COLORS.orange}
      content={
        <>
          <div>You do not have permission to {permissionType} this type of record.</div>
          <div>
            {contactEmail ? (
              <>
                Please contact your administrator (
                <a href={`mailto:${contactEmail}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}>
                  {contactEmail}
                </a>
                ) to enable this for you.
              </>
            ) : (
              'Please contact your administrator to enable this for you.'
            )}
          </div>
        </>
      }
    >
      <Status small glyph="warning" color="orange" label="Missing Permission"></Status>
    </Tooltip>
  )
}

const getNoPermissionMessage = ({ roundName, tenant, user, permissionType, featureName }: any) => `Hello,

I am trying to complete ${roundName ? `the "${roundName}" round` : `a round`} in ${
  tenant?.name ? `"${tenant.name}" Behave Health EHR` : `Behave Health EHR`
} but I do not have permission to ${permissionType} ${featureName}.

Please enable this permission for me.

Thank you,
${user?.name || ''}
`

const FILTERS_CONFIG = {
  resident_name: {
    label: 'Client Name',
    type: 'string',
    glyph: 'user_neutral',
  },
  first_name: {
    type: 'string',
    label: 'First Name',
    glyph: 'user_neutral',
  },
  last_name: {
    type: 'string',
    label: 'Last Name',
    glyph: 'user_neutral',
  },
  dob: {
    type: 'date_time',
    label: 'Date of Birth',
    glyph: 'date',
  },
  behave_id: {
    label: 'Client ID',
    type: 'string',
  },
  sex: {
    type: 'multi_select',
    label: 'Sex',
    options: [
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
      { label: 'Decline to respond', value: 'decline_to_respond' },
      { label: 'Other', value: 'other' },
      { label: 'Empty', value: null },
    ],
  },
  presence: {
    type: 'multi_select',
    label: 'Round Presence',
    options: [
      { label: 'Present', value: 'present' },
      { label: 'Absent', value: 'absent' },
      { label: 'Unset', value: null },
    ],
  },
  round_note: {
    label: 'Round Note',
    type: 'string',
  },
  house: {
    label: 'Location',
    type: 'multi_object',
    endpoint: '/houses',
    apiKey: 'houses',
    glyph: 'organizations',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  program: {
    label: 'Program',
    type: 'multi_object',
    endpoint: '/programs',
    apiKey: 'programs',
    glyph: 'intake_application',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  program_list: {
    label: 'Program List',
    type: 'multi_object',
    endpoint: '/phases',
    apiKey: 'phases',
    glyph: 'intake_application',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  // care_team: {
  //   label: 'Care Team',
  //   type: 'multi_object',
  //   endpoint: '/employees',
  //   apiKey: 'employees',
  //   glyph: 'user_group',
  //   showAvatar: true,
  //   polymorphic: false,
  //   selectTitle: (data: any) => data.name,
  // },
  service_assignments: {
    label: 'Service Assignment',
    type: 'string',
  },
}

const STYLES = {
  dropdown: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& > svg': { marginLeft: '0.4rem' },
  },

  textarea: {
    padding: INPUT_STYLES.padding,
    fontSize: INPUT_STYLES.fontSize,
    border: INPUT_STYLES.border,
    background: COLORS.transparent,
    borderColor: 'transparent',
    outline: 'none',
    transition: 'border-color 30ms ease-in-out',

    '&:hover': {
      background: tint(0.9, COLORS.vividBlue),
      boxShadow: `0 0 0 1px ${tint(0.7, COLORS.blue)}`,
    },

    '&:focus': {
      ...INPUT_FOCUS_STYLES,
      background: COLORS.white,
      borderRadius: INPUT_STYLES.borderRadius,
      zIndex: 10,
    },

    '&.is-disabled': {
      background: `${COLORS.hover} important`,
      pointerEvents: 'none',
    },
  },
}

export const RoundsOverlay = withOverlayError(RootRoundsOverlay)
