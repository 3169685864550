import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const statuses = {
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  in_progress: {
    label: 'In Progress',
    color: 'yellow',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
}

export const RecoveryCapitalFormStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}
