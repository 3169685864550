import React from 'react'

import SmartStatus from '../SmartStatus'
import Status from '../Status'

const statuses = {
  attend_partial:{
    label:"Attend Partial" 
  },
  attend_partial_due_to_emotional_distress:{
    label:"Attend Partial/Due to Emotional Distress" 
  },
  call_out:{
    label:"Call-Out" 
  },
  call_out_by_provider:{
    label:"Call-Out by Provider" 
  },
  late:{
    label:"Late" 
  },
  make_up_session:{
    label:"Make-up Session" 
  },
  medication_management:{
    label:"Medication Management" 
  },
  non_participatory:{
    label:"Non-Participatory" 
  },
  partial_day:{
    label:"Partial Day" 
  },
  participated_actively:{
    label:"Participated Actively" 
  },
  telehealth_remote:{
    label:"Telehealth/Remote" 
  },
  under_influence:{
    label:"Under Influence" 
  },
  with_support_person:{
    label:"With Support Person" 
  },
  left_early_excused:{
    label:"Left Early - Excused" 
  },
  left_early_unexcused:{
    label:"Left Early - Unexcused" 
  },
}

const EventAttendancePresentStatus = ({ ...rest }: any) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default EventAttendancePresentStatus