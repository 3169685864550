import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'

import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import Icon from '@behavehealth/components/Icon'
import PageSection from '@behavehealth/components/PageSection/PageSection'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { setDuplicateColumnV6 } from '@behavehealth/components/DataTable/functions'
import { useSettings } from '@behavehealth/hooks/useSettings'

export const TreatmentPlanBookInterventions = ({ category }: any) => {
  const { isBehave }: any = useSettings()
  const permissionBase = `${category}_treatment_book`

  const tableProps = useDataTable({
    name: ['treatment-book-interventions'],
    endpoint: `/treatment_book_interventions`,
    params: { category },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 120,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} id={data.id} value={value} to={`${data?.id}`} />,
      },
      {
        title: 'Description',
        model: 'description',
        width: 180,
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      setDuplicateColumnV6({
        link: 'new',
        permission: `${permissionBase}.create`,
      }),
    ],
    [],
  )

  return (
    <PageSection>
      <PageSection.Header
        after={
          <Flex gap="0.5rem">
            {isBehave && <Button as={Link} label="Import Behave Templates" type="default" glyph="add" link="import" size={200} />}

            <Button
              as={Link}
              label="Add Intervention"
              type="primary"
              glyph="add"
              size={200}
              link={`new`}
              permission={`${permissionBase}.create`}
            />
          </Flex>
        }
      >
        <Flex centerY gap="0.5rem">
          <Icon icon="treatment_plans" size={22} />
          <PageSection.Title title="Interventions" />
        </Flex>
      </PageSection.Header>

      <PageSection.Content>
        <DataTable {...tableProps} asCard title="Treatment Book Interventions" icon="treatment_plans" columns={columns} />
      </PageSection.Content>
    </PageSection>
  )
}
