import React from 'react'
import size from 'lodash/size'

import { useSettings } from '../../hooks/useSettings'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Avatar from '../../components/Avatar'
import Flex from '../../components/Flex'
import ClinicalNoteStatus from '../../components/Statuses/ClinicalNoteStatus'
import EventStatus from '../../components/Statuses/EventStatus'

import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

const CATEGORIES: any = {
  individual_therapy: 'Individual Therapy',
  group_therapy: 'Group Therapy',
  case_management: 'Case Management',
  individual_peer_note: 'Individual Peer Note',
  group_peer_notes: 'Group Peer Notes',
  individual_daily_note: 'Individual Daily Note',
  group_daily_notes: 'Group Daily Notes',
}

export const EventDocumentationsReportDataTable = (props: any) => {
  const { to } = props

  const { tenant, isBehave, timezone } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        id: 'title',
        model: 'title',
        title: 'Event Title',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.title} to={to?.(data)} />,
      },
      {
        width: 150,
        id: 'status',
        model: 'status',
        title: 'Event Status',
        formatValue: ({ value }: any) => <EventStatus status={value} />,
      },
      {
        width: 160,
        id: 'category',
        model: 'category',
        title: 'Category',
        formatValue: ({ value }: any) => {
          if (!(value in CATEGORIES)) return '-'

          return CATEGORIES[value]
        },
      },
      {
        width: 250,
        id: 'employees',
        model: 'employees',
        title: 'Staff',
        formatValue: ({ data, value }: any) => {
          if (size(data.employees) === 0) return null

          return (
            <Flex centerY nowrap gap="0.75rem">
              {data.employees.map((o: any) => (
                <Flex key={o.id} centerY nowrap gap="0.5rem">
                  <Avatar src={o.avatar} initials={o.name} size={20} />
                  <div>{o.name}</div>
                </Flex>
              ))}
            </Flex>
          )
        },
      },
      {
        width: 150,
        id: 'event_note_link',
        model: 'event_note_link',
        title: 'Event Note Link',
      },
      {
        width: 160,
        id: 'client',
        model: 'client',
        title: 'Note Client',
        type: 'profiles',
      },
      {
        width: 160,
        id: 'note_status',
        model: 'note_status',
        title: 'Note Status',
        formatValue: ({ value }: any) => <ClinicalNoteStatus status={value} />,
      },
      {
        width: 160,
        id: 'started_at',
        model: 'started_at',
        title: 'Session Date',
        type: 'date_time',
      },
      {
        width: 150,
        id: 'duration',
        model: 'duration',
        title: 'Duration',
      },
      {
        width: 160,
        id: 'ended_at',
        model: 'ended_at',
        title: 'Session End Date',
        type: 'date_time',
      },
      {
        width: 150,
        id: 'created_at',
        model: 'created_at',
        title: 'Note Added At',
        type: 'date_time',
      },
      {
        width: 150,
        id: 'supervisor',
        model: 'supervisor',
        title: 'Note Supervisor',
        type: 'profile',
      },
      {
        width: 150,
        id: 'author',
        model: 'author',
        title: 'Note Author',
        type: 'profile',
      },
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Event Documentations"
      icon="calendar"
      columns={columns}
      filtersConfig={FILTERS}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="event_documentations"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=event_documentations'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'event_documentations' }} />
        </>
      }
    />
  )
}

const FILTERS = {
  title: {
    label: 'Title',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Completed', value: 'completed' },
      { label: 'Cancelled', value: 'cancelled' },
    ],
  },
  event_note_link: {
    label: 'Event Note Link',
    type: 'string',
  },
  note_status: {
    label: 'Note Status',
    type: 'multi_select',
    options: [
      { label: 'Draft', value: 'draft' },
      { label: 'To Complete', value: 'to_complete' },
      { label: 'Rejected', value: 'rejected' },
      { label: 'Pending Review', value: 'pending_review' },
      { label: 'Updates Required', value: 'updates_required' },
      { label: 'Pre-Approved', value: 'pre_approved' },
      { label: 'Signed Off', value: 'signed_off' },
      { label: 'Closed', value: 'closed' },
      { label: 'Empty', value: 'empty' },
    ],
  },
  category: {
    label: 'Category',
    type: 'multi_select',
    options: Object.entries(CATEGORIES).map(([value, label]) => ({ value, label })),
  },
  started_at: {
    label: 'Session Start Date',
    type: 'date_time',
  },
  ended_at: {
    label: 'Session End Date',
    type: 'date_time',
  },
  duration: {
    label: 'Duration',
    type: 'number',
  },
  employees: {
    label: 'Staff',
    type: 'multi_object',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_neutral',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  client: {
    label: 'Client',
    type: 'multi_object',
    endpoint: '/residents',
    apiKey: 'residents',
    glyph: 'user_neutral',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  author: DEFAULT_FILTERS.author,
  supervisor: DEFAULT_FILTERS.author,
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
