import React from 'react'
import { Route, Switch, Redirect, useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { APP_URLS } from '@behavehealth/constructs/MyAccount/utils'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useCopyToClipboard from '@behavehealth/hooks/useCopyToClipboard'

import { Alert, Button, Card, Dropdown, DropdownItem, Flex, Glyph, Grid, Page, PageSection, Portal, Status } from '@behavehealth/components'

import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import CardsGrid from '@behavehealth/components/CardsGrid'
import ClientPaymentMethodsSection from '@behavehealth/constructs/Stripe/ClientPaymentMethodsSection'
import FinancialCard from '@behavehealth/components/Cards/FinancialCard'
import Tabs from '@behavehealth/components/Tabs'

import { ClientInvoiceBuilderOverlay } from '@behavehealth/constructs/ClientInvoices/ClientInvoiceBuilderOverlay'
import { ClientInvoiceOverlay } from '@behavehealth/constructs/ClientInvoices/ClientInvoiceOverlay'
import { ClientRecurringInvoiceOverlay } from '@behavehealth/constructs/ClientInvoices/ClientRecurringInvoiceOverlay'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { InsuranceInvoiceOverlay } from '@behavehealth/constructs/RCM/InsuranceInvoiceOverlay'
import { InsuranceInvoicesDataTable } from '@behavehealth/constructs/RCM/InsuranceInvoicesDataTable'
import { InvoiceStatus } from '@behavehealth/constructs/InvoiceElements/InvoiceStatus'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const InvoicesStatements = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  return (
    <>
      <Page
        feature="accounting"
        title="Invoices & Statements"
        actions={
          <Dropdown label="Add New…" glyph="add" buttonType="primary" buttonSize={300} permission="ledger.create">
            <DropdownItem
              icon="invoices"
              color="green"
              label="Single Invoice"
              link={{
                pathname: `${match.url}/invoices/new`,
                parent: match,
              }}
            />
            <DropdownItem
              label="Multiple Invoices"
              glyph="stack"
              color="green"
              size={200}
              link={{
                pathname: `${match.url}/invoices/batch`,
                parent: match,
              }}
            />
            <DropdownItem
              label="Recurring Invoices"
              glyph="reset"
              color="green"
              size={200}
              link={{
                pathname: `${match.url}/recurring/new`,
                parent: match,
              }}
            />
          </Dropdown>
        }
      >
        <Tabs.List className="-mt-4 mb-5">
          <Tabs.Item label="Invoices" to={`${match.url}/invoices`} />
          <Tabs.Item label="Statements" to={`${match.url}/statements`} />
          <Tabs.Item label="Recurring Invoices" to={`${match.url}/recurring`} />
        </Tabs.List>

        <Switch>
          <Route path={`${match.url}/invoices`}>
            <InvoicesTab client={client} />
          </Route>
          <Route path={`${match.url}/statements`} component={StatementsTab} />
          <Route path={`${match.url}/recurring`} component={RecurringTab} />

          <Redirect exact from={match.url} to={`${match.url}/invoices`} />
        </Switch>
      </Page>

      <Portal>
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <Route path={`${match.path}/recurring/:id`} component={ClientRecurringInvoiceOverlay} />
          <Route path={`${match.path}/invoices/batch`} component={ClientInvoiceBuilderOverlay} />
          <Route path={`${match.path}/invoices/:id`} component={ClientInvoiceOverlay} />
          <Route path={`${match.path}/statements/:id`} component={InsuranceInvoiceOverlay} />
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

const InvoicesTab = (props: any) => {
  const match = useRouteMatch()

  const { client } = props

  const { tenant } = useSettings()

  const invoicesColumns = React.useMemo(
    () => [
      {
        title: 'Invoice #',
        model: 'invoice_number',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={`${match.url}/${data.id}`} value={value} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <InvoiceStatus small status={value} />,
      },
      {
        title: 'Invoice Date',
        model: 'invoiced_at',
        type: 'date_time',
      },
      {
        title: 'Due Date',
        model: 'due_at',
        type: 'date_time',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [],
  )

  const invoicesLink = `${APP_URLS.mini_apps}/invoices/${tenant.subdomain}/client/${client?.external_id}`

  const { copy: copyInvoiceLink, didCopy: invoiceLinkCopied } = useCopyToClipboard({
    text: invoicesLink,
    notification: 'Online Invoices Link copied!',
  })

  return (
    <PageSection.Content>
      {tenant?.is_stripe_connected && client && <ClientPaymentMethodsSection title="Payment Methods" client={client} tenant={tenant} />}

      <div className="my-4">
        <div className="grid gap-4 grid-cols-1">
          <Card className="px-3 py-2">
            <Grid gap="1rem">
              <Flex gap="1rem" justifyContent="space-between">
                <h3 className="text-[1.1rem]">
                  <Flex gap="0.5rem" alignItems="center">
                    <Glyph glyph="ledger" size={24} className="" />
                    <span>Online Invoices</span>
                  </Flex>
                </h3>
              </Flex>

              <div className="pl-[32px] -mt-[12px] -mb-1.5 text-[0.95rem]">
                Use the <strong>Online Invoices</strong> to securely <strong>share</strong> some of the invoices details and collect
                Payments.
              </div>

              <Flex gap="0.25rem" className="pl-[32px]">
                <Button
                  size={100}
                  glyph={invoiceLinkCopied ? 'check' : 'copy'}
                  label={invoiceLinkCopied ? 'Link Copied!' : 'Copy Invoice Link'}
                  onClick={copyInvoiceLink}
                />
                <Button size={100} type="link" label="Open in New Tab" target="_blank" href={invoicesLink} />
              </Flex>
            </Grid>
          </Card>
        </div>
      </div>

      <CardsGrid className="mb-4">
        <FinancialCard title="Invoices Balance" value={parseFloat('0')} />
        <FinancialCard title="Amount Invoiced" value={parseFloat('10000')} valueColor="red" />
        <FinancialCard title="Amount Paid" value={parseFloat('10000')} prefix="+" />
        <FinancialCard title="Amount Due" value={parseFloat('0')} valueColor="gray" />
      </CardsGrid>

      <DataTable asCard title="Invoices" icon="invoices_blue" data={INVOICES} columns={invoicesColumns} meta={{ count: size(INVOICES) }} />
    </PageSection.Content>
  )
}

const StatementsTab = (props) => {
  const match = useRouteMatch()

  const { client } = props
  const clientId = client?.id

  const tableProps = useDataTable({
    name: ['client', clientId, 'invoices'],
    endpoint: `/residents/${clientId}/invoices`,
    params: { category: 'insurance' },
    localStorageKey: 'client_insurance_invoices_v1',
  })

  return (
    <div className="grid">
      <InsuranceInvoicesDataTable asCard to={(data) => `${match.url}/${data.id}`} {...tableProps} />
    </div>
  )
}

const RecurringTab = () => {
  const match = useRouteMatch()

  const recurringInvoicesColumns = React.useMemo(
    () => [
      {
        title: 'Invoice #',
        model: 'invoice_number',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={`${match.url}/${data.id}`} value={value} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) =>
          value === 'active' ? <Status small color="green" label="Active" /> : <Status small color="red" label="Stopped" />,
      },
      {
        title: 'Next Invoice Date',
        model: 'next_invoiced_at',
        type: 'date_time',
      },
      {
        title: 'Next Due Date',
        model: 'next_due_at',
        type: 'date_time',
      },
      {
        title: 'Generated',
        model: 'created_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <PageSection.Content>
      <div className="mb-4">
        <div className="grid gap-4 grid-cols-1">
          <Alert small contrast glyph="info">
            <h3 className="text-[1rem]">About Recurring Invoices</h3>

            <ul className="m-0 mt-1 pl-5">
              <li>
                Use this feature to <b>automatically generate Invoices</b> for your Clients on a regular basis
              </li>
              <li>
                Invoices will be generated on the <b>next due date</b> based on the recurrence settings you choose
              </li>
              <li>
                You can set Recurring Invoices to <b>stop generating</b> after a certain number of <b>occurrences</b>, on a{' '}
                <b>specific date</b>, or on the client's <b>discharge date</b>
              </li>
            </ul>
          </Alert>
        </div>
      </div>

      <DataTable
        asCard
        title="Recurring Invoices"
        glyph="reset"
        data={RECURRING_INVOICES}
        columns={recurringInvoicesColumns}
        meta={{ count: size(INVOICES) }}
      />
    </PageSection.Content>
  )
}

const INVOICES = [
  {
    id: '1',
    invoice_number: 'DEMO_INV_1234',
    external_id: '142a7c1f-6e4d-4be9-94db-05334f368553',
    invoiced_at: '2021-07-06T06:30:42.474-04:00',
    due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    updated_at: '2021-07-06T06:30:42.474-04:00',
    status: 'paid',
    payment_method: null,
    url: 'https://behavehealth.com',
  },
  {
    id: '2',
    invoice_number: 'DEMO_INV_1235',
    external_id: '6ea4dca9-dcf0-43cf-90af-cff07d17183f',
    invoiced_at: '2021-07-06T06:30:42.474-04:00',
    due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    updated_at: '2021-07-06T06:30:42.474-04:00',
    status: 'paid',
    payment_method: null,
    url: 'https://behavehealth.com',
  },
  {
    id: '3',
    invoice_number: 'DEMO_INV_1236',
    external_id: '60abb8e2-35a7-48a6-bbca-2f95af34e505',
    invoiced_at: '2021-07-06T06:30:42.474-04:00',
    due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    updated_at: '2021-07-06T06:30:42.474-04:00',
    status: 'cancelled',
    payment_method: 'Visa **** 5172',
    url: 'https://behavehealth.com',
  },
]

const RECURRING_INVOICES = [
  {
    id: '1',
    invoice_number: 'REC_INV_1234',
    external_id: '142a7c1f-6e4d-4be9-94db-05334f368553',
    next_invoiced_at: '2021-07-06T06:30:42.474-04:00',
    next_due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    status: 'active',
    payment_method: null,
    url: 'https://behavehealth.com',
  },
  {
    id: '2',
    invoice_number: 'REC_INV_1235',
    external_id: '6ea4dca9-dcf0-43cf-90af-cff07d17183f',
    next_invoiced_at: '2021-07-06T06:30:42.474-04:00',
    next_due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    status: 'active',
    payment_method: 'Visa **** 5172',
    url: 'https://behavehealth.com',
  },
  {
    id: '3',
    invoice_number: 'REC_INV_1236',
    external_id: '60abb8e2-35a7-48a6-bbca-2f95af34e505',
    next_invoiced_at: '2021-07-06T06:30:42.474-04:00',
    next_due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    status: 'stopped',
    payment_method: null,
    url: 'https://behavehealth.com',
  },
]

export default withPageError(InvoicesStatements)
