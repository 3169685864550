import React from 'react'

import SmartStatus from '../SmartStatus'
import Status from '../Status'

const statuses = {
  authorized_leave:{
    label:"Authorized Leave" 
  },
  crisis_intervention:{
    label:"Crisis Intervention" 
  },
  excused:{
    label:"Excused" 
  },
  inpatient_residential_admission:{
    label:"Inpatient/Residential Admission" 
  },
  legal_court_situation:{
    label:"Legal/Court Situation" 
  },
  medical_situation:{
    label:"Medical Situation" 
  },
  relapse:{
    label:"Relapse" 
  },
  unexcused_no_call_no_show:{
    label:"Unexcused (No-call, No-show" 
  },
  cancelled_by_client:{
    label:"Cancelled - by Client" 
  },
  cancelled_by_provider:{
    label:"Cancelled - by Provider" 
  },
  rescheduled:{
    label:"Rescheduled" 
  },
}

const EventAttendanceAbsentStatus = ({ ...rest }: any) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default EventAttendanceAbsentStatus