import React from 'react'
import size from 'lodash/size'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Avatar from '../../components/Avatar'
import Flex from '../../components/Flex'
import ReportRefetchButton from '../../components/Buttons/ReportRefetchButton'
import Status from '../../components/Status'
import Markup from '../../components/Table/Cells/Markup'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'

export const ShiftNotesReportDataTable = (props: any) => {
  const { to } = props
  const { isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Note',
        id: 'description',
        model: 'description',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={<Markup value={value} />} to={to?.(data)} />,
      },
      {
        width: 160,
        id: 'category',
        model: 'category',
        title: 'Category',
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          return <Status label={value.replace('_', ' ')} color={value === 'incident_report' ? 'red' : 'blue'} />
        },
      },
      {
        width: 250,
        id: 'residents',
        model: 'residents',
        title: 'Clients',
        formatValue: ({ data, value }: any) => {
          if (size(data.residents) === 0) return null

          return (
            <Flex centerY nowrap gap="0.75rem">
              {data.residents.map((o: any) => (
                <Flex key={o.id} centerY nowrap gap="0.5rem">
                  <Avatar src={o.avatar} initials={o.name} size={20} />
                  <div>{o.name}</div>
                </Flex>
              ))}
            </Flex>
          )
        },
      },
      {
        width: 250,
        id: 'employees',
        model: 'employees',
        title: 'Employees',
        formatValue: ({ data, value }: any) => {
          if (size(data.employees) === 0) return null

          return (
            <Flex centerY nowrap gap="0.75rem">
              {data.employees.map((o: any) => (
                <Flex key={o.id} centerY nowrap gap="0.5rem">
                  <Avatar src={o.avatar} initials={o.name} size={20} />
                  <div>{o.name}</div>
                </Flex>
              ))}
            </Flex>
          )
        },
      },
      {
        width: 200,
        id: 'dated_at',
        model: 'dated_at',
        title: 'Dated At',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Shift Notes"
      icon="housing_notes"
      columns={columns}
      filtersConfig={FILTERS.live_reports.shift_notes}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="shift_notes"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=shift_notes'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'shift_notes' }} />
        </>
      }
      {...props}
    />
  )
}
