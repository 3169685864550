import React from 'react'
import { BaseEditorComponent } from '@handsontable/react'
import { createRef, MouseEvent, RefObject } from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import Handsontable from 'handsontable'
import size from 'lodash/size'

import { COLORS } from '../../../theme'

import Avatar from '../../Avatar'
import Badge from '../../Badge'
import Button from '../../Button'
import Card from '../../Card'
import Flex from '../../Flex'
import Glyph from '../../Glyph'
import Loader from '../../Loader'
import Overlay from '../../Overlay'
import State from '../../State'
import Tooltip from '../../Tooltip'

import { DropdownMenu, DropdownMenuItem } from '../../DropdownMenu'
import { useDataTable } from '../../DataTable/useDataTable'
import { ValuesTooltip } from '../common/ValuesTooltip'
import { SpreadsheetDropdown } from '../common/SpreadsheetDropdown'

type MultiObjectSelectorRendererProps = {
  TD?: HTMLTableCellElement
  value?: any
  row?: number
  col?: number
  cellProperties?: Handsontable.CellProperties
  config?: any
  isEditable?: boolean
  readOnly?: boolean
  width?: number
}

export const MultiObjectSelectorRenderer = (props: MultiObjectSelectorRendererProps) => {
  const { value, readOnly, isEditable, TD, config = {}, spreadsheet, width } = props
  const { selectTitle, selectDescription, showAvatars, renderItem } = config

  if (TD) {
    readOnly ? TD.classList.add('htDimmed') : TD.classList.remove('htDimmed')
  }

  const parsed = React.useMemo(() => {
    if (!value) return null

    try {
      return JSON.parse(value)
    } catch (error) {
      return null
    }
  }, [value])

  const hasValue = size(parsed) > 0

  return (
    <div className="flex items-center flex-nowrap h-[28px] relative">
      {hasValue && (
        <>
          {parsed.map((item: any) => {
            if (renderItem)
              return (
                <div key={item.id} className="m-0.5">
                  {renderItem(item)}
                </div>
              )

            return (
              <div className="flex flex-nowrap items-center whitespace-nowrap m-1.5" key={item.id}>
                {showAvatars && <Avatar initials={item?.name} size={20} className="mr-1.5" />}
                <div>{selectTitle ? selectTitle(item) : item?.name}</div>
              </div>
            )
          })}
        </>
      )}

      <div
        className={clsx(
          'flex items-center justify-center absolute top-0 right-[-4px] w-8 bottom-0 z-[1]',
          isEditable && !readOnly && 'bg-gradient-to-l from-[white] to-[transparent]',
        )}
      >
        {hasValue ? (
          <ValuesTooltip
            width={width}
            trigger={
              <div>
                <Badge className="shadow-soft-5 shadow-vivid-blue-100">{size(parsed)}</Badge>
              </div>
            }
          >
            <div className="grid gap-0.5 text-[0.9rem] font-[600]">
              {parsed.map((item: any) => (
                <div
                  className="flex flex-nowrap items-center py-1 border-b border-0 border-solid border-divider last:border-b-0 first:pt-0 last:pb-0 truncate"
                  key={item.id}
                >
                  {showAvatars && <Avatar initials={item?.name} size={20} className="mr-1.5" />}
                  <div className="flex-[1_1_auto] truncate">
                    <div className="truncate">{selectTitle ? selectTitle(item) : item?.name}</div>
                    {selectDescription && (
                      <div className="text-[0.85rem] text-text-muted font-[400] truncate">{selectDescription(item)}</div>
                    )}
                  </div>
                  {spreadsheet?.hotInstance && isEditable && !readOnly && (
                    <Button
                      hideLabel
                      glyph="cross"
                      size={100}
                      type="minimal"
                      color="red"
                      onClick={() => {
                        const newValue = parsed?.filter?.((o: any) => o.id !== item.id) || []

                        spreadsheet?.hotInstance?.setDataAtRowProp(props.row, props.model, JSON.stringify(newValue))
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </ValuesTooltip>
        ) : (
          isEditable && !readOnly && <Glyph glyph="triangle_down" size={10} color={COLORS.blue} />
        )}
      </div>

      {config?.tooltip && <Tooltip content={config.tooltip} className="ml-auto relative z-[3]" />}
    </div>
  )
}

export class MultiObjectSelectorEditor extends BaseEditorComponent {
  mainElementRef: RefObject<HTMLDivElement>

  constructor(props: BaseEditorComponent['props']) {
    super(props)

    this.mainElementRef = createRef()
    this.state = {
      isOpen: false,
      value: null,
      record: null,
    }
  }

  setValue(value: any, callback: (() => void) | undefined) {
    const _value = value === '""' ? '[]' : value

    this.setState((_state, _props) => {
      return { value: _value }
    }, callback)
  }

  getValue() {
    return this.state.value
  }

  open() {
    this.setState({ isOpen: true })

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'block'
  }

  close() {
    this.setState({ isOpen: false })

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'none'

    this.finishEditing()
  }

  prepare(
    row: number,
    col: number,
    prop: string,
    td: HTMLTableColElement,
    originalValue: string,
    cellProperties: Handsontable.CellProperties,
  ) {
    super.prepare(row, col, prop, td, originalValue, cellProperties)

    const tdPosition = td.getBoundingClientRect()

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.left = `${tdPosition.left + window.pageXOffset}px`
    this.mainElementRef.current.style.top = `${tdPosition.top + window.pageYOffset}px`
  }

  stopMousedownPropagation(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
  }

  render() {
    const { isOpen } = this.state

    return (
      <div>
        {this.props?.config?.tableSelector ? (
          <SelectorOverlay
            isOpen={isOpen}
            table={this.props.config.tableSelector}
            onMouseDown={this.stopMousedownPropagation}
            onSelect={(selected) => {
              this.setState(
                (state, props) => {
                  return { value: JSON.stringify(selected) }
                },
                () => {
                  this.finishEditing()
                },
              )

              this.close()
            }}
            onClose={() => {
              this.close()
            }}
          />
        ) : (
          <SpreadsheetDropdown ref={this.mainElementRef} onMouseDown={this.stopMousedownPropagation}>
            <SelectorMenu
              {...this.props}
              value={this.state.value}
              onSelect={(selected) => {
                this.setState((state, props) => {
                  const newValue = JSON.parse(state.value || '[]')

                  if (size(selected) === 0) return { value: null }

                  const index = newValue.findIndex((o: any) => o.id === selected.id)

                  if (index === -1) {
                    newValue.push(selected)
                  } else {
                    newValue.splice(index, 1)
                  }

                  return { value: JSON.stringify(newValue) }
                })
              }}
            />
          </SpreadsheetDropdown>
        )}
      </div>
    )
  }
}

const SelectorMenu = (props: any) => {
  const { onSelect, config = {} } = props
  const { selectTitle, selectDescription, showAvatars } = config

  const parsedValue = React.useMemo(() => {
    if (!props.value) return null

    try {
      return JSON.parse(props.value)
    } catch (error) {
      console.error(error)
      return null
    }
  }, [props.value])

  const tableProps = useDataTable({
    name: ['spreadsheet-menu', props.model],
    endpoint: props.config?.endpoint,
    params: props.config?.params,
    enabled: !!props.config?.endpoint,
  })

  const { meta, currentPage, onCurrentPageChange: setCurrentPage } = tableProps
  const pagesArray = Array.from({ length: meta?.pages || 0 }, (_, i) => i + 1)

  return (
    <div className="relative">
      {tableProps.isLoading ? (
        <div className="py-12 px-4 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          {tableProps.isRefetching && <Loader className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />}

          {!tableProps.isRefetching && !tableProps.isLoading && size(tableProps.data) === 0 && (
            <State isEmpty glyph="search" emptyDescription="There are no records to display" minHeight={100} />
          )}

          <div className="sticky shadow-soft-2 top-[-3px] bg-white z-[1] border-b border-0 border-solid border-divider -m-[3px] !mb-0">
            {size(parsedValue) > 0 && (
              <div className="border-t border-0 border-solid border-divider px-2 py-1.5">
                <Flex gap="0.5rem">
                  {parsedValue.map((item: any) => (
                    <div
                      key={item.id}
                      className="inline-flex items-center border-solid border-divider rounded-[5px] text-[0.85rem] font-[600]"
                    >
                      <div className="px-1.5 py-0.25">{selectTitle ? selectTitle(item) : item?.name}</div>

                      <button
                        type="button"
                        onClick={() => onSelect(item)}
                        className="bg-transparent hover:bg-hover border-l border-0 border-solid border-divider inline-flex self-stretch items-center justify-center cursor-pointer"
                      >
                        <Glyph glyph="close" color="red" size={10} />
                      </button>
                    </div>
                  ))}
                </Flex>
              </div>
            )}
          </div>

          <div className="grid gap-[1px]">
            {tableProps.data?.map?.((item) => {
              const isActive = !!parsedValue?.find?.((o: any) => o.id === item.id)

              return (
                <button
                  key={item.id}
                  css={STYLES.menuItem}
                  onClick={() => {
                    onSelect?.(item)
                  }}
                  className={clsx('text-left', tableProps.isRefetching && 'opacity-0 pointer-events-none', isActive && 'is-active')}
                >
                  {showAvatars && <Avatar initials={item.name} src={item.avatar} size={20} className="mr-1.5" />}
                  <div>
                    <div>{selectTitle ? selectTitle(item) : item?.name}</div>
                    {selectDescription && <div className="text-[0.85rem] text-text-muted font-[400]">{selectDescription(item)}</div>}
                  </div>
                  <div className="w-5 h-5 inline-flex ml-auto items-center justify-center">
                    {isActive && <Glyph glyph="tick_circle" size={16} color="green" />}
                  </div>
                </button>
              )
            })}
          </div>

          {meta && size(pagesArray) > 1 && size(tableProps.data) > 0 && (
            <div className={clsx('sticky bottom-[-3px] w-full shadow border-t border-0 border-solid border-divider bg-white z-[1]')}>
              <Flex centerY justifyContent="space-between" gap="0.5rem">
                <Button
                  label="← Back"
                  isDisabled={!meta?.prev}
                  display="inline-flex"
                  type="minimal"
                  size={200}
                  onClick={() => {
                    if (meta?.prev) setCurrentPage?.(meta?.prev)
                  }}
                />

                {meta?.pages >= 2 ? (
                  <DropdownMenu
                    trigger={
                      <div css={STYLES.dropdown}>
                        <div>
                          <span className="!font-[600]">Page {meta?.page}</span> of{' '}
                          <span className="!font-[400] !text-text-muted">{meta?.pages}</span>
                        </div>
                        <Glyph glyph="triangle_down" size={12} />
                      </div>
                    }
                  >
                    {pagesArray.map((pageNumber) => (
                      <DropdownMenuItem
                        key={pageNumber}
                        isActive={pageNumber === meta?.page}
                        label={`Page ${pageNumber}`}
                        onClick={() => setCurrentPage?.(pageNumber)}
                      />
                    ))}
                  </DropdownMenu>
                ) : (
                  <div>
                    <span className="!font-[600]">Page {meta?.page}</span> of{' '}
                    <span className="!font-[400] !text-text-muted">{meta?.pages}</span>
                  </div>
                )}

                <Button
                  label="Next →"
                  isDisabled={!meta?.next}
                  display="inline-flex"
                  type="minimal"
                  size={200}
                  onClick={() => {
                    if (meta?.next) setCurrentPage?.(meta?.next)
                  }}
                />
              </Flex>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const SelectorOverlay = ({ table, isOpen, onSelect, onClose, onClear, title, icon, onMouseDown }: any) => {
  const [selectedRows, setSelectedRows] = React.useState([])

  const handleApplyAll = () => {
    onSelect(selectedRows)
    onClose()
  }

  if (!isOpen) return null

  const Table = table

  return (
    <div onMouseDown={onMouseDown}>
      <Overlay animate showBackdrop stopPropagation position="center" maxWidth={100} onClose={onClose}>
        <Overlay.Header title={`Select ${title || 'records…'}`} icon={icon} />

        <Overlay.Content>
          <Card className="m-4">{Table && <Table useMainCellSelect canBatchSelect onRowSelectionUpdate={setSelectedRows} />}</Card>
        </Overlay.Content>

        <Overlay.Footer>
          <Button label="Add" type="primary" color="green" flex="3 1 auto" onClick={handleApplyAll} />
        </Overlay.Footer>
      </Overlay>
    </div>
  )
}

const STYLES = {
  menuItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '28px',
    padding: '0.1rem 0.6rem',
    fontSize: '0.9rem',
    fontWeight: 600,
    color: COLORS.text,
    border: 'none',
    background: 'transparent',
    borderRadius: 5,

    'svg, img': {
      marginRight: '0.4rem',
    },

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      background: tint(0.9, COLORS.green),
    },

    '& > *': {
      pointerEvents: 'none',
    },
  },

  dropdown: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& > svg': { marginLeft: '0.4rem' },
  },
}
