import React from 'react'

import ContextShow from '../ContextShow'
import Input from '../Input'
import Option from '../Option'
import Select from '../Select'

const HighestEducationLevelSelect = ({ label, model, isRequired }) => {
  return (
    <>
      <Select
        allowEmpty
        label={label}
        model={model}
        validations={
          isRequired && {
            presence: {
              message: 'Please select the highest level of education',
            },
          }
        }
      >
        <Option label="Elementary or High school, no diploma" value="elementary_or_high_school_no_diploma" />
        <Option label="Elementary or High school, GED" value="elementary_or_high_school_ged" />
        <Option label="7th Grade" value="seventh_grade" />
        <Option label="8th Grade" value="eighth_grade" />
        <Option label="9th Grade" value="ninth_grade" />
        <Option label="10th Grade" value="tenth_grade" />
        <Option label="11th Grade" value="eleventh_grade" />
        <Option label="12th Grade (no diploma)" value="twelfth_grade" />
        <Option label="High school diploma" value="high_school_diploma" />
        <Option label="College degree" value="college" />
        <Option label="College, no degree" value="college_no_degree" />
        <Option label="Undergraduate/Graduate Degrees" value="undergraduate_graduate_degrees" />
        <Option label="Associate's degree, vocational" value="associates_degree_vocational" />
        <Option label="Associate's degree, academic" value="associates_degree_academic" />
        <Option label="Bachelor's degree" value="bachelors_degree" />
        <Option label="Master's degree" value="masters_degree" />
        <Option label="Professional degree" value="professional_degree" />
        <Option label="Doctorate degree" value="doctorate_degree" />
        <Option label="Other" value="other" />
      </Select>

      <ContextShow when={model} is="other">
        <Input
          label="Please add your highest level of education"
          model={`${model}_other`}
          validations={
            isRequired && {
              presence: {
                message: 'Please enter the highest level of education',
              },
            }
          }
        />
      </ContextShow>
    </>
  )
}

HighestEducationLevelSelect.defaultProps = {
  label: 'Highest Level of Education',
  model: 'highest_education_level',
}

export default HighestEducationLevelSelect
