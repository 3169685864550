import React from 'react'
import { useParams } from 'react-router-dom'
import size from 'lodash/size'

import withSettings from '../../../hocs/withSettings'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Grid from '../../../components/Grid'
import State from '../../../components/State'
import Status from '../../../components/Status'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { ContactOverlay } from '../../Contacts/ContactOverlay'
import { DataTable } from '../../../components/DataTable/DataTable'
import { MainCell } from '../../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../../components/DataTable/useDataTable'

const AddContactButton = ({ client }: any) => {
  return (
    <SummonOverlay overlay={<ContactOverlay dataID="new" reference={client} />}>
      <Button label="Add Contact" glyph="add" type="primary" display="inline-flex" permission="contacts.create" />
    </SummonOverlay>
  )
}

const Contacts = ({ client, setStatus }: any) => {
  const { resource_id }: any = useParams()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { isRequired }: any = React.useContext(Workflow.PanelContext)

  const [openItem, setOpenItem] = React.useState(null)

  const tableProps = useDataTable({
    name: ['client', resource_id, 'contacts'],
    endpoint: `/residents/${resource_id}/contacts`,
  })

  const isEmpty = size(tableProps?.data) === 0

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            isActive={openItem?.id === data.id}
            id={data.id}
            value={data.name}
            avatar={data.avatar || ''}
            onClick={() => {
              setOpenItem(data)
            }}
          />
        ),
      },
      {
        title: 'Relationship',
        model: 'relationship',
        type: 'title',
      },
      {
        title: 'Emergency Contact',
        model: 'is_emergency',
        formatValue: ({ value }) => {
          if (!value) return 'Non-emergency'
          return <Status small color="yellow" label="Emergency Contact" />
        },
      },
      {
        title: 'Release of Information',
        model: 'roi',
        formatValue: ({ value }) => {
          if (!value) return 'Not allowed'
          return <Status small color="green" label="R.O.I. Allowed" />
        },
      },
      {
        title: 'Preferred Contact Method',
        model: 'preferred_contact_method',
        type: 'title',
      },
    ]
  }, [openItem?.id])

  const onContinue = async () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (isEmpty || tableProps.isLoading) {
    return (
      <State
        isLoading={tableProps.isLoading}
        isEmpty={isEmpty}
        title="Contacts"
        icon="contacts"
        emptyDescription="To have additional contacts as signers or payers please add them as a contact."
        emptyActions={<AddContactButton client={client} />}
      />
    )
  }

  return (
    <>
      <Grid gap="1rem">
        <Alert contrast glyph="info">
          To have additional contacts as signers or payers please add them as a contact.
        </Alert>

        <Card>
          <DataTable title="Contacts" icon="contacts" columns={columns} minHeight={200} {...tableProps} />

          <CardContent>
            <AddContactButton />
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          {!isRequired && <Workflow.SkipButton onClick={onSkip} />}
        </Workflow.Buttons>
      </Grid>

      <SummonOverlay
        key={`summon-${openItem?.id}`}
        isOpen={!!openItem}
        onClose={() => setOpenItem(null)}
        overlay={<ContactOverlay dataID={openItem?.id} />}
      />
    </>
  )
}

export default withSettings(Contacts)
