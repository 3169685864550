import React from 'react'
import { transparentize } from 'polished'
import get from 'lodash/get'
import isEqual from 'react-fast-compare'
import size from 'lodash/size'

import { setIdleSaving } from '../../../../actions/common'
import { TIMEZONE } from '../../../../utils/constants'
import { titleCase } from '../../../../utils/functions'

import Button from '../../../Button'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Dialog from '../../../Dialog'
import Divider from '../../../Divider'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Input from '../../../Forms/Input'
import Nav from '../../../Nav'
import NoInternet from '../../../Alerts/NoInternet'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import PageGrid from '../../../PageGrid'
import ScrollMenu from '../../../ScrollMenu'
import ScrollView from '../../../ScrollView'
import Section from '../../../Section'
import Timeline from '../../../Timeline/Timeline'

import { apiCreate, apiUpdate, apiDelete } from '../../../../modules/api'

export class DataFormTemplateBase extends React.Component {
  constructor(props) {
    super(props)

    const dataID = props.dataID || props.match?.params?.id
    const isNew = dataID === 'new'
    let data = {}

    const defaultData = {
      status: 'draft',
      category: props.category,
      subcategory: props.subcategory,
      data: {},
      author: props.author,
      supervisor: null,
    }

    if (isNew) data = Object.assign({}, defaultData, props.location.data)
    else data = get(props, `data.${dataID}`, {})

    this.state = {
      id: dataID,
      form: { isInvalid: true },
      data: data,
      origin: { ...data },
      isValid: false,
      isInvalid: true,
      $new: isNew,
      $editable: isNew,
      dated_at: null,
    }

    this.form = React.createRef()
  }

  /*
    LIFECYCLE
  */
  shouldComponentUpdate = (nextProps, nextState) => {
    if (!isEqual(nextProps.data_form, this.props.data_form)) return true
    if (nextProps.loading !== this.props.loading) return true
    if (nextProps.idle !== this.props.idle) return true
    if (nextState.$editable !== this.state.$editable) return true
    if (nextState.isValid !== this.state.isValid) return true
    if (nextState.dated_at !== this.state.dated_at) return true

    return false
  }

  componentDidUpdate = (prevProps) => {
    // if (!prevProps.idle && this.props.idle) this.onIdle()
  }

  /*
    CUSTOM FUNCTIONS
  */
  renderHeader = () => null
  renderContent = () => null
  renderSidebar = () => <ScrollMenu />

  onIdle = async () => {
    const { match, data_form, category, subcategory } = this.props
    const { $new, isValid } = this.state

    if (isValid) {
      this.props.idleSaving()

      const data = {
        id: data_form.id,
        ...this.form.current.getFormValue(),
        category: category,
        subcategory: subcategory,
      }

      if ($new) await apiCreate({ name: 'data_form_templates', url: `/data_form_templates`, params: data })
      else await apiUpdate({ name: 'data_form_templates', url: `/data_form_templates/${match.params.id}`, params: data })
    }
  }

  onDisabledClick = () => {
    this.form.current.highlightInvalid()
  }

  onDateUpdate = (date) => {
    this.setState({ dated_at: date.value })
  }

  close = () => {
    if (this.props.onClose) this.props.onClose()

    if (this.props.location.parent) {
      this.props.history.push(this.props.location.parent.url)
    } else {
      const path = this.props.location.pathname
      this.props.history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  edit = () => this.setState({ $editable: true })

  cancel = () => {
    this.form.current.resetForm()
    this.setState({ $editable: false })
  }

  save = async () => {
    const { match, data_form, category, subcategory } = this.props
    const { $new } = this.state

    const data = {
      id: data_form.id,
      ...this.form.current.getFormValue(),
      category: category,
      subcategory: subcategory,
    }

    if ($new) await apiCreate({ name: 'data_form_templates', url: `/data_form_templates`, params: data })
    else await apiUpdate({ name: 'data_form_templates', url: `/data_form_templates/${match.params.id}`, params: data })

    if ($new) this.close()

    this.setState({ $editable: false, origin: data })
  }

  delete = async () => {
    await apiDelete({ name: 'data_form_templates', url: `/data_form_templates/${this.props.match.params.id}` })
    this.close()
  }

  /*
    BUSINESS LOGIC
  */
  buildPermission = (permission_name, type) => {
    return permission_name ? `${permission_name}.${type}` : true
  }

  /*
    RENDER
  */
  render() {
    const { $new, $editable, isInvalid, closing } = this.state
    const { allowEditing = true, data_form, dataID, defaultDebounce, initialData, loading, location, online, timezone, title } = this.props

    const permission_name = location && location.permission_name

    const hasInitialData = initialData && dataID

    if (!$new && !hasInitialData && size(data_form) === 0) {
      return <OverlayLoader position="right" maxWidth={82} />
    }

    return (
      <Overlay
        position="right"
        onClose={this.close}
        showBackdrop={this.state.$editable}
        maxWidth={82}
        isDirty={$editable}
        closeWrapper={(element, onClose) => (
          <Dialog
            glyph="check"
            title="Close without saving?"
            message="All changes will be lost. This action cannot be undone."
            yesColor="red"
            yesLabel="Yes, Close Without Saving"
            skip={!$editable || !onClose}
            onYes={onClose}
          >
            {element}
          </Dialog>
        )}
      >
        {this.renderHeader()}

        <ScrollView>
          <PageGrid scroll breakpoint={3}>
            <Nav top="0" breakpoint={3} headingSize={300} title="Table of Contents" background={transparentize(0.4, 'white')}>
              {this.renderSidebar()}
            </Nav>

            <Overlay.Content preventScroll={closing} style={{ padding: 1 }}>
              <Form
                useFullModel
                getForm={this.form}
                initialModel={{ ...initialData, ...data_form }}
                timezone={timezone}
                defaultDebounce={defaultDebounce}
                isEditable={$editable}
                onValidationUpdate={(valid) => this.setState({ isValid: valid, isInvalid: !valid })}
              >
                <Section headingType="h2" title="Name">
                  <FormSection maxWidth="100%">
                    <Input
                      autoFocus
                      label="Template Name"
                      model="name"
                      validations={{
                        presence: {
                          message: 'Please enter a template name',
                        },
                      }}
                    />

                    <ObjectSelector
                      isPolymorphic
                      model="supervisor"
                      label="Supervisor"
                      type="clinical_supervisors"
                      icon="employees"
                      selectTitle={(data) => data.name}
                      selectDescription={(data) => titleCase(data.position)}
                    />
                  </FormSection>
                </Section>

                <Divider />

                {this.renderContent()}

                {!$new && (
                  <>
                    <Divider />

                    <Section
                      headingType="h2"
                      title="Timeline"
                      scrollview={{
                        id: 'timeline',
                        name: 'Timeline',
                      }}
                    >
                      <Timeline isLoadingRecord={loading} recordID={data_form.id} recordType={data_form.type} />
                    </Section>
                  </>
                )}
              </Form>
            </Overlay.Content>
          </PageGrid>
        </ScrollView>

        {allowEditing && (
          <Overlay.Footer>
            {$editable && online && (
              <>
                <Button
                  label="Save Template"
                  glyph="check"
                  type="primary"
                  color="green"
                  onClick={this.save}
                  isLoading={loading}
                  isDisabled={loading || isInvalid}
                  flex="100 1 auto"
                  permission={this.buildPermission(permission_name, 'create')}
                />
                {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
              </>
            )}

            {!$editable && online && (
              <>
                <Button
                  glyph="edit"
                  label={`Edit ${title}`}
                  type="default"
                  isDisabled={loading}
                  onClick={this.edit}
                  flex="100 1 auto"
                  permission={this.buildPermission(permission_name, 'edit')}
                />
                <DeleteDialog
                  title={`Delete ${title}?`}
                  message={`Are you sure you want to delete this ${title}? This action cannot be undone.`}
                  onYes={this.delete}
                >
                  <Button
                    glyph="delete"
                    label={`Delete ${title}…`}
                    type="default"
                    color="red"
                    isDisabled={loading}
                    fullWidth
                    permission={this.buildPermission(permission_name, 'delete')}
                  />
                </DeleteDialog>
              </>
            )}

            {!online && <NoInternet />}
          </Overlay.Footer>
        )}
      </Overlay>
    )
  }
}

DataFormTemplateBase.defaultProps = {
  title: 'Form',
  defaultDebounce: 300,
}

export const mapDispatchToProps = (dispatch) => ({
  idleSaving: () => dispatch(setIdleSaving(true)),
})

export const mapStateToProps = (state, props) => ({
  user: state.me.user,
  loading: get(state, `data.data_form_templates.loading`),
  data: get(state, `data.data_form_templates.data`),
  data_form: get(state, `data.data_form_templates.data.${props.match?.params?.id}`, {}),
  online: state.common.global.online,
  idle: state.common.global.idle,
  timezone: state.me.tenant.timezone || TIMEZONE,
})
