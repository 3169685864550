import React from 'react'

import { apiCreate } from '../../../modules/api'
import Notifications from '../../../modules/notifications'
import withSettings from '../../../hocs/withSettings'
import { withOverlayError } from '../../../hocs/withOverlayError'

import Button from '../../Button'
import Form from '../../Forms/Form'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Grid from '../../Grid'
import Alert from '../../Alert'
import FormSection from '../../Forms/FormSection'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import ServiceCodesSelector from '../../RCM/components/ServiceCodesSelector'
import ServiceCodeGroupsSelector from '../../RCM/components/ServiceCodeGroupsSelector'
import RadioGroup from '../../Forms/RadioGroup'
import Radio from '../../Forms/Radio'
import ContextShow from '../../Forms/ContextShow'

import { PVERIFY_PRACTICE_TYPE_CODES, CLAIMMD_SERVICE_CODES } from '../../../utils/constants'

const isDevelopment = process.env.NODE_ENV === 'development'

const RequestEvobOverlay = ({ insurancePolicy, onClose, tenant }: any) => {
  const form = React.useRef()

  const [valid, setValid] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const isPV = insurancePolicy.clearing_house === 'pverify'
  const isChangeHealth = insurancePolicy.clearing_house === 'change_health'
  const isClaimMD = insurancePolicy.clearing_house === 'claim_md'

  const requestEVOB = async () => {
    setLoading(true)

    const data = form.current?.getFormValue()

    try {
      await apiCreate({
        name: 'insurance_evobs',
        url: '/insurance_evobs',
        params: {
          insurance_policy_id: insurancePolicy.id,
          credential_id: data.npi_id,
          ...(isPV && { practice_type_code: data.practice_type_code }),
          ...(isChangeHealth && { service_codes: data.service_codes.map((o: any) => o?.code.split(',')).flat() }),
          ...(isClaimMD && { service_code: data.service_code }),
        },
        notify: false,
      })
      Notifications.send('eVOB request sent successfully', 'positive')
    } catch (error) {
      Notifications.send('eVOB request could not be sent. If the issue persists, please contact us.', 'negative')
    } finally {
      setLoading(false)
      onClose()
    }
  }

  return (
    <Overlay showBackdrop onClose={onClose} position="center">
      <Overlay.Header icon="insurance" title="Request eVOB" />

      <Overlay.Content>
        <Form getForm={form} onValidationUpdate={setValid}>
          <Section>
            <FormSection>
              <ObjectSelector
                label="NPI:"
                icon="staff_credentials"
                type="company.npis"
                model="npi"
                dependentValue={tenant.id}
                selectTitle={(data) => `${data.credential} (${data.license_number})`}
                selectDescription={(data) => (data.level === 'organization' ? data.organization : `${data.first_name} ${data.last_name}`)}
                validations={{
                  presence: {
                    message: 'Please select a valid NPI',
                  },
                }}
              />

              {isPV && (
                <Select
                  label="Please select the Practice Code you would like to use for the eVOB:"
                  defaultValue={isDevelopment ? '18' : '66'}
                  model="practice_type_code"
                >
                  {isDevelopment && (
                    <>
                      <Option label="Development Testing PV" value="18" />
                      <Option disabled label="──────────" />
                    </>
                  )}

                  {Object.entries(PVERIFY_PRACTICE_TYPE_CODES).map(([key, value]: any) => (
                    <Option label={value.label} description={value.description} value={key} />
                  ))}
                </Select>
              )}

              {isChangeHealth && (
                <Grid gap="1rem">
                  <Alert type="warning">
                    We include <strong>Plan Coverage and General Benefits</strong> (30) by default
                  </Alert>

                  <RadioGroup
                    model="category"
                    layout="horizontal-dense"
                    description="Use Grouped Codes to run eVOBs for specific categories of coverage"
                    defaultValue="grouped"
                  >
                    <Radio label="Grouped Service Codes" value="grouped" />
                    <Radio label="Individual Service Codes" value="individual" />
                  </RadioGroup>

                  <ContextShow when="category" is="grouped">
                    <ServiceCodeGroupsSelector label="Select Groups" model="service_codes" />
                  </ContextShow>

                  <ContextShow when="category" is="individual">
                    <ServiceCodesSelector label="Service Codes" model="service_codes" />
                  </ContextShow>
                </Grid>
              )}

              {isClaimMD && (
                <Select
                  label="Please select the Service Code you would like to use for the eVOB:"
                  defaultValue="30"
                  model="service_code"
                >
                  {CLAIMMD_SERVICE_CODES.map((serviceCode: any) => (
                    <Option label={`${serviceCode.code}: ${serviceCode.name}`} description={serviceCode.description} value={serviceCode.code} />
                  ))}
                </Select>
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Request eVOB"
          glyph="check"
          type="primary"
          color="green"
          onClick={requestEVOB}
          isLoading={loading}
          isDisabled={!valid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(withSettings(RequestEvobOverlay))
