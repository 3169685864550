import React from 'react'
import clsx from 'clsx'
import { BaseEditorComponent } from '@handsontable/react'
import { createRef, MouseEvent, RefObject } from 'react'
import Handsontable from 'handsontable'

import { COLORS } from '../../../theme'
import { usDate } from '../../../utils/functions'
import Glyph from '../../Glyph'

import { SpreadsheetDropdown } from '../common/SpreadsheetDropdown'
import { DatePicker } from '../../DatePicker'
import DateInput from '../../Forms/DateInput'

type DateRendererProps = {
  TD?: HTMLTableCellElement
  value?: string | number
  row?: number
  col?: number
  cellProperties?: Handsontable.CellProperties
  config?: any
  isEditable?: boolean
  timezone?: any
  readOnly?: boolean
}

export const DateRenderer = (props: DateRendererProps) => {
  const { value, timezone, readOnly, isEditable, TD } = props

  if (TD) {
    readOnly ? TD.classList.add('htDimmed') : TD.classList.remove('htDimmed')
  }

  return (
    <div
      className={clsx('flex items-center flex-nowrap h-[29px] justify-end tabular-nums mx-[-4px] px-[4px]', {
        htDimmed: readOnly,
      })}
    >
      {readOnly && <div className={clsx('absolute top-0 left-0 right-0 bottom-0', isEditable ? 'bg-[#f7f8fa]' : 'bg-white')} />}

      <div className="flex w-full items-center flex-nowrap h-[28px] justify-between relative z-1">
        {value && usDate(value, timezone)}
        {isEditable && <Glyph glyph="calendar" size={10} color={COLORS.blue} className="ml-auto" />}
      </div>
    </div>
  )
}

export class DateEditor extends BaseEditorComponent {
  mainElementRef: RefObject<HTMLDivElement>

  constructor(props: BaseEditorComponent['props']) {
    super(props)

    this.mainElementRef = createRef()
    this.state = {
      value: '',
    }
  }

  setValue(value: any, callback: (() => void) | undefined) {
    this.setState((_state, _props) => {
      return { value }
    }, callback)
  }

  getValue() {
    return this.state.value
  }

  open() {
    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'block'
  }

  close() {
    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'none'
  }

  prepare(
    row: number,
    col: number,
    prop: string,
    td: HTMLTableColElement,
    originalValue: string,
    cellProperties: Handsontable.CellProperties,
  ) {
    super.prepare(row, col, prop, td, originalValue, cellProperties)

    const tdPosition = td.getBoundingClientRect()

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.left = `${tdPosition.left + window.pageXOffset}px`
    this.mainElementRef.current.style.top = `${tdPosition.top + window.pageYOffset}px`
  }

  stopMousedownPropagation(e: MouseEvent) {
    e.stopPropagation()
  }

  render() {
    const { timezone } = this.props

    return (
      <SpreadsheetDropdown ref={this.mainElementRef} onMouseDown={this.stopMousedownPropagation} style={{ width: 440, padding: 0 }}>
        <div className="p-3 border-b border-0 border-solid border-divider is-compact">
          <DateInput
            autoFocus
            isCompact
            withHover={false}
            withDatePicker={false}
            value={this.state.value}
            timezone={timezone}
            onUpdate={({ value }) => {
              this.setState({ value })
            }}
          />
        </div>

        <DatePicker
          isInline
          stopPropagation
          timezone={timezone}
          date={this.state.value}
          maxYear={2030}
          minYear={1950}
          onSelect={(date) => {
            if (!date) return

            this.setState(
              (state, props) => {
                return { value: date.set({ seconds: 0, milliseconds: 0 }).toISO() }
              },
              () => {
                this.finishEditing()
              },
            )
          }}
        />
      </SpreadsheetDropdown>
    )
  }
}
