import React from 'react'
import { useParams } from 'react-router-dom'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { usDateTime } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'
import { useSettings } from '../../../hooks/useSettings'

import Card from '../../../components/Card'
import ContextShow from '../../../components/Forms/ContextShow'
import DataList from '../../../components/DataList'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Grid from '../../../components/Grid'
import Icon from '../../../components/Icon'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import State from '../../../components/State'
import TreeItem from '../../../components/TreeItem'
import Workflow from '../../../components/Workflow/Workflow'

import { ProgramAssignmentInfo } from '../../Programs/ProgramAssignmentInfo'

const ProgramsDischarge = ({ setStatus, setData, data }: any) => {
  const { resource_id }: any = useParams()
  const { goNext }: any = React.useContext(Workflow.Context)

  const { timezone } = useSettings()
  const [valid, setValid] = React.useState(false)

  const queryParams = { category: 'program_management' }

  const { data: occupancies, isLoading }: any = useGet({
    name: ['client', resource_id, 'occupancies', queryParams],
    url: `/residents/${resource_id}/occupancies`,
    params: queryParams,
  })

  const isEmpty = size(occupancies) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  if (isEmpty || isLoading) {
    return (
      <State
        isLoading={isLoading}
        isEmpty={isEmpty}
        title="Programs"
        icon="checklist"
        emptyDescription="This client doesn't have any active programs"
      />
    )
  }

  return (
    <Form useLocalModel onValidationUpdate={setValid} onUpdate={setData}>
      <FormSection maxWidth="100%">
        <Card>
          <Flex centerY gap="0.75rem" css={{ padding: '0.5rem 1rem 0.2rem' }}>
            <Icon icon="checklist" />
            <div css={{ fontWeight: 700, fontSize: '1.15rem' }}>Current Program Assignments</div>
          </Flex>

          {occupancies.map((occupancy: any, index: number) => (
            <div key={occupancy.id} css={styles.occupancy}>
              <TreeItem isOpen={index === 0} title={occupancy?.place?.program?.name}>
                <ProgramAssignmentInfo data={occupancy} />
              </TreeItem>
            </div>
          ))}

          <Grid gap="0.75rem" css={{ padding: '1rem 1rem 0.75rem' }}>
            <RadioGroup
              label="When would you like to discharge from all the Programs?"
              model="should_end_current_program_occupancies"
              layout="horizontal-dense"
              defaultValue="on_discharge_date"
            >
              <Radio label="On Discharge Date" value="on_discharge_date" />
              <Radio label="Another Time" value="another_time" />
            </RadioGroup>

            <ContextShow when="should_end_current_program_occupancies" is="on_discharge_date">
              <DataList>
                <DataList.Item label="Discharged On" value={usDateTime(data?.discharged_at, timezone)} />
              </DataList>
            </ContextShow>

            <ContextShow when="should_end_current_program_occupancies" is="another_time">
              <DateTimeInput
                defaultToNow
                label="Programs Discharge Date"
                model="current_program_occupancies_ended_at"
                validations={{
                  presence: {
                    message: 'Please enter an end date',
                  },
                }}
              />
            </ContextShow>
          </Grid>
        </Card>

        <Workflow.Buttons>
          <Workflow.SaveContinueButton label="Save & Continue →" onClick={onContinue} isDisabled={!valid} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

const styles = {
  occupancy: {
    '& + &': {
      borderTop: `1px solid ${COLORS.divider}`,
    },
  },
}

export default ProgramsDischarge
