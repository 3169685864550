import React from 'react'

import { COLORS, SHADOW, keyframes } from '../../../theme'

export const SpreadsheetDropdown = React.forwardRef((props, ref) => {
  const { children, style, ...rest } = props

  return (
    <div ref={ref} style={{ ...STYLES, ...style }} {...rest}>
      {children}
    </div>
  )
})

const animation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale3d(0.96, 0.96, 0.96)',
  },
  '100%': {
    opacity: 1,
    transform: 'scale3d(1, 1, 1)',
  },
})

const STYLES = {
  display: 'none',
  position: 'absolute',
  background: 'white',
  boxShadow: SHADOW(10, COLORS.divider),
  borderRadius: 7,
  left: 0,
  top: 0,
  padding: 3,
  zIndex: 1000,
  width: 320,
  height: 'auto',
  maxHeight: 400,
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  transformOrigin: '0% 0%',
  outline: `0 !important`,
  animation: `${animation} 120ms cubic-bezier(0.39, 0.575, 0.565, 1)`,
}
