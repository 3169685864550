import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useSettings } from '../../hooks/useSettings'

import { FILTERS } from '../Filters/config'
import { titleCase } from '../../utils/functions'
import DataFormStatus from '../../components/Statuses/DataFormStatus'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

const DOCUMENTATION_TYPES = {
  clinical_note: 'Clinical Note',
  breathalyzer_test: 'Breathalyzer Test',
  communicable_disease: 'Communicable Disease',
  vital_sign: 'Vital Sign',
}

export const DocumentationTimeReportDataTable = (props: any) => {
  const { to } = props

  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Author',
        id: 'author',
        model: 'author',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data?.author?.name} avatar={data?.author?.avatar} to={to?.(data)} />,
      },
      {
        title: 'Supervisor',
        width: 330,
        id: 'supervisor',
        model: 'supervisor.name',
      },
      {
        title: 'Type',
        width: 200,
        id: 'documentation_type',
        model: 'documentation_type',
        formatValue: ({ data, value }: any) => {
          if (value === 'data_form') return <MainCell value={titleCase(value)} />

          return <MainCell value={DOCUMENTATION_TYPES[value]} />
        },
      },
      {
        title: 'Status',
        width: 160,
        id: 'status',
        model: 'status',
        formatValue: ({ data, value }: any) => <DataFormStatus status={value} />,
      },
      {
        title: 'Session Start',
        width: 250,
        id: 'start_at',
        model: 'start_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Author Create Date',
        width: 250,
        id: 'author_create_date_time',
        model: 'author_create_date_time',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Time Between Start & Create',
        width: 250,
        id: 'time_between_start_create',
        model: 'time_between_start_create',
      },
      {
        title: 'Signed Off Date',
        width: 250,
        id: 'signed_off_date_time',
        model: 'signed_off_date_time',
      },
      {
        title: 'Author Signed Off Date',
        width: 250,
        id: 'author_signed_off_date_time',
        model: 'author_signed_off_date_time',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Author Submit Date',
        width: 250,
        id: 'author_submit_date_time',
        model: 'author_submit_date_time',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Time Between Create & Submit',
        width: 250,
        id: 'time_btwn_create_submit',
        model: 'time_btwn_create_submit',
      },
      {
        title: 'Time Between Submit & Sign Off',
        width: 250,
        id: 'time_btwn_submit_sign_off',
        model: 'time_btwn_submit_sign_off',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Documentation Time Reports"
      icon="patient_progress_measure"
      columns={columns}
      filtersConfig={FILTERS.live_reports.timeliness}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="timeliness"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=timeliness'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'timeliness' }} />
        </>
      }
      {...props}
    />
  )
}
