import React from 'react'
import clsx from 'clsx'
import get from 'lodash/get'
import size from 'lodash/size'

import { age, beautifulFloat } from '../../utils/functions'
import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'
import { H3 } from '../../components/Typography'
import { MEDIA_QUERY } from '../../theme'
import { useGet, useCreate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardsGrid from '../../components/CardsGrid'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import ContextShow from '../../components/Forms/ContextShow'
import DataArray from '../../components/Forms/DataArray'
import DataFormStatus from '../../components/Statuses/DataFormStatus'
import DateInput from '../../components/Forms/DateInput'
import DateOfBirthInput from '../../components/Forms/DateOfBirthInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Nav from '../../components/Nav'
import NewEthnicityCheckboxGroup from '../../components/Forms/elements/NewEthnicityCheckboxGroup'
import NumberInput from '../../components/Forms/NumberInput'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PageGrid from '../../components/PageGrid'
import RaceCheckboxGroup from '../../components/Forms/elements/RaceCheckboxGroup'
import Radio from '../../components/Forms/Radio'
import Select from '../../components/Forms/Select'
import Option from '../../components/Forms/Option'
import RadioGroup from '../../components/Forms/RadioGroup'
import ScrollMenu from '../../components/ScrollMenu'
import ScrollView from '../../components/ScrollView'
import Section from '../../components/Section'
import SexSelect from '../../components/Forms/elements/SexSelect'
import Slider from '../../components/Forms/Slider'
import Tabs from '../../components/Tabs'
import Textarea from '../../components/Forms/Textarea'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../components/Forms/elements/YesNoRadioGroupWithTextarea'

import { BarChart } from './components/Charts'
import { Pill } from './components/Pill'
import { RecoveryCapitalHistoricDataV2 } from './RecoveryCapitalHistoricDataV2'
import { ResultCard } from './components/ResultCard'
import { CountdownStatus } from './components/CountdownStatus'
import CapitalCard from './CapitalCard'

import {
  GREEN,
  MAX_VALUES,
  PERSONAL_RECOVERY_CAPITAL,
  RECOVERY_GROUPS,
  RED,
  SERVICES_V2,
  SOCIAL_RECOVERY_CAPITAL,
  SUBSTANCES_V2,
} from './constants'

const ANIMATION_DELAY = 100
const INITIAL_GOALS = [{ goal: '' }, { goal: '' }, { goal: '' }]

const RootRecoveryCapitalFormOverlayDrugCourt = (props: any) => {
  const { isBehave, isPortal, timezone, defaultSupervisor } = useSettings()

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    parent,
    save,
    record,
    id,
  } = useOverlay({
    name: 'recovery_capital_form',
    endpoint: '/recovery_capital_forms',
    invalidate: 'recovery_capital_forms',
    parentType: props.parentType,
    parentRequest: props.parentRequest,
    options: props,
    onSaveSuccessful: props.onSaveSuccessful,
    onDeleteSuccessful: props.onDeleteSuccessful,
    openAfterCreate: true,
  })

  const [tab, setTab] = React.useState(isNew ? 'form' : 'results')

  const [hasClearGoals, setHasClearGoals] = React.useState(false)
  const [hasLongTermGoals, setHasLongTermGoals] = React.useState(false)

  const referenceID = props?.referenceID || parent?.id

  const { data: client, isLoading: isLoadingClient }: any = useGet({
    name: ['client', referenceID],
    url: `/residents/${referenceID}`,
    options: { enabled: !!referenceID },
  })

  const { mutateAsync: completeForm, isLoading: isCompleting }: any = useCreate({
    name: ['recovery_capital_forms', id],
    url: `/recovery_capital_forms/${id}/complete`,
    invalidate: 'recovery_capital_forms',
  })

  const barriers = React.useMemo(() => {
    const res = {
      housing: 0,
      substance_use: 0,
      criminal_justice: 0,
      work_training_volunteering: 0,
      finances_transport: 0,
    }

    if (!data) return res

    if (get(data, 'answers.barriers_to_recovery_domain.at_risk_of_eviction') || get(data, 'answers.barriers_to_recovery_domain.homeless')) {
      res.housing = 1
    }

    if (get(data, 'answers.barriers_to_recovery_domain.used_substances_in_the_last_ninety_days')) {
      res.substance_use = 1
    }

    if (
      get(data, 'answers.barriers_to_recovery_domain.criminal_justice.offended_in_the_last_ninety_days') ||
      get(data, 'answers.barriers_to_recovery_domain.criminal_justice.arrested_or_charged_in_the_last_ninety_days')
    ) {
      res.criminal_justice = 1
    }

    if (
      get(data, 'answers.barriers_to_recovery_domain.work_training_volunteering.working_full_time') ||
      get(data, 'answers.barriers_to_recovery_domain.work_training_volunteering.working_part_time') ||
      get(data, 'answers.barriers_to_recovery_domain.work_training_volunteering.attending_school') ||
      get(data, 'answers.barriers_to_recovery_domain.work_training_volunteering.volunteering')
    ) {
      res.work_training_volunteering = 0
    } else {
      res.work_training_volunteering = 1
    }

    if (
      get(data, 'answers.barriers_to_recovery_domain.finances_transport.is_significantly_in_debt') ||
      get(data, 'answers.barriers_to_recovery_domain.finances_transport.has_access_to_reliable_transportation')
    ) {
      res.finances_transport = 1
    }

    return res
  }, [data])

  const services = React.useMemo(() => {
    const res: any = {
      engaged: [],
      not_engaged: [],
    }

    if (!data) return res

    for (const serviceKey in SERVICES_V2) {
      const name = SERVICES_V2[serviceKey]
      const answers = get(data, `answers.services_and_needs_domain.${serviceKey}`)

      if (answers?.engaged === true) {
        res.engaged.push({ name, need_help: answers.need_help })
      } else if (answers?.engaged === false) {
        res.not_engaged.push({ name, need_help: answers.need_help })
      }
    }

    return res
  }, [data])

  const otherServices = React.useMemo(() => {
    if (!data) return null

    const answers = get(data, 'answers.services_and_needs_domain.other_help')

    if (!answers?.engagement && !answers?.need_help) return null

    return answers
  }, [data])

  if (isOverlayLoading || isLoadingClient) {
    return <OverlayLoader position="right" maxWidth={tab === 'results' ? 80 : 110} />
  }

  const validateForm = () => {
    form.current.highlightInvalid()
  }

  return (
    <Overlay onClose={close} position="right" maxWidth={tab === 'results' ? 80 : 110}>
      <Overlay.Header
        icon="progress_reviews"
        title={data?.name || 'Recovery Capital (Drug Court)'}
        titleAside={
          <>
            <DataFormStatus status={data?.status} />
            {data?.completed_at && (
              <CountdownStatus status={data?.status} completedAt={data?.completed_at} lockStatus={data?.lock_status} timezone={timezone} />
            )}
          </>
        }
        description={<ClientProfileHeader client={data?.client || record} />}
      />

      <Tabs activeTab={tab} onChange={setTab} className={clsx('grid grow overflow-hidden', !isNew && 'grid-rows-[auto_1fr]')}>
        <Overlay.SubHeader className={clsx('!p-0 relative z-[3]', isNew && '!hidden')}>
          <Tabs.List className={clsx('!border-none px-4')}>
            <Tabs.Item label="Results" name="results" glyph="chart" />
            <Tabs.Item label="Form" name="form" glyph="document" />
          </Tabs.List>
        </Overlay.SubHeader>

        <Tabs.Panel name="results" className="p-4 bg-[#F5F6FA] overflow-auto">
          {data && (
            <div className="grid items-start content-start gap-4">
              <CardsGrid>
                <CapitalCard
                  title="Negative Recovery Capital"
                  value={data?.negative_capital_summary_score ? beautifulFloat(parseFloat(data?.negative_capital_summary_score)) : 0}
                  valueColor="red"
                  className="animate-scaleFadeIn opacity-0 scale-x-[0.95] scale-y-[0.95]"
                  animationDelay={1 * ANIMATION_DELAY}
                />
                <CapitalCard
                  title="Positive Recovery Capital"
                  value={data?.positive_capital_summary_score ? beautifulFloat(parseFloat(data?.positive_capital_summary_score)) : 0}
                  valueColor="green"
                  className="animate-scaleFadeIn opacity-0 scale-x-[0.95] scale-y-[0.95]"
                  animationDelay={2 * ANIMATION_DELAY}
                />
                <CapitalCard
                  title="Overall Recovery Capital Score"
                  value={data?.recovery_capital_index_score ? beautifulFloat(parseFloat(data?.recovery_capital_index_score)) : 0}
                  prefix="-"
                  className="animate-scaleFadeIn opacity-0 scale-x-[0.95] scale-y-[0.95]"
                  animationDelay={3 * ANIMATION_DELAY}
                />
              </CardsGrid>

              <ResultCard
                title="Quality of Life and Satisfaction"
                score={data?.quality_of_life_score}
                maxScore={MAX_VALUES['quality_of_life_score']}
                color={data?.quality_of_life_score < 33 ? 'red' : data?.quality_of_life_score < 66 ? 'orange' : 'green'}
                animationDelay={4 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricDataV2 clientId={referenceID} dimension="quality_of_life" />
                  </Card>
                }
              >
                <BarChart
                  max={20}
                  labels={['Support Network', 'Housing Quality', 'Life Quality', 'Physical Health', 'Psychological Health']}
                  values={[
                    get(data, 'answers.quality_of_life_domain.support_network'),
                    get(data, 'answers.quality_of_life_domain.quality_of_housing'),
                    get(data, 'answers.quality_of_life_domain.overall_quality_of_life'),
                    get(data, 'answers.quality_of_life_domain.physical_health'),
                    get(data, 'answers.quality_of_life_domain.psychological_health'),
                  ]}
                />
              </ResultCard>

              <ResultCard
                title="Barriers to Human Flourishing"
                score={data?.barriers_to_recovery_score}
                maxScore={MAX_VALUES['barriers_to_recovery_score']}
                color={data?.barriers_to_recovery_score < 1 ? 'green' : data?.barriers_to_recovery_score < 3 ? 'orange' : 'red'}
                animationDelay={5 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricDataV2 clientId={referenceID} dimension="barriers_to_recovery" />
                  </Card>
                }
              >
                <BarChart
                  max={1}
                  showTooltip={false}
                  labels={[
                    'Finances and transport',
                    'Work, Training, Volunteering',
                    'Criminal Justice System Involvement',
                    'Substance Use',
                    'Housing',
                  ]}
                  values={[
                    {
                      value: 1,
                      itemStyle: {
                        color: barriers.finances_transport ? RED : GREEN,
                      },
                    },
                    {
                      value: 1,
                      itemStyle: {
                        color: barriers.work_training_volunteering ? RED : GREEN,
                      },
                    },
                    {
                      value: 1,
                      itemStyle: {
                        color: barriers.criminal_justice ? RED : GREEN,
                      },
                    },
                    {
                      value: 1,
                      itemStyle: {
                        color: barriers.substance_use ? RED : GREEN,
                      },
                    },
                    {
                      value: 1,
                      itemStyle: {
                        color: barriers.housing ? RED : GREEN,
                      },
                    },
                  ]}
                  label={{
                    show: true,
                    position: 'insideRight',
                    color: 'white',
                    formatter: (params) => {
                      const values = [
                        barriers.finances_transport,
                        barriers.work_training_volunteering,
                        barriers.criminal_justice,
                        barriers.substance_use,
                        barriers.housing,
                      ]
                      return values[params.dataIndex]
                    },
                  }}
                />
              </ResultCard>

              <ResultCard
                title="Services Involvement and Needs"
                maxScore={MAX_VALUES['services_and_needs_score']}
                score={data?.services_and_needs_score}
                color={data?.services_and_needs_score < 4 ? 'green' : data?.services_and_needs_score < 6 ? 'orange' : 'red'}
                animationDelay={6 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricDataV2 clientId={referenceID} dimension="services_and_needs" />
                  </Card>
                }
              >
                <div className="my-4 grid grid-cols-1 mq900:grid-cols-2">
                  <div className="grid gap-2 content-start pr-4 border-r border-0 border-solid border-divider">
                    <div className="grid grid-cols-[1fr_100px]">
                      <h4>Engaged</h4>
                      <h4>Needs Help?</h4>
                    </div>

                    {size(services.engaged) === 0 && <div className="italic text-text-strongly-muted text-center py-4">No services</div>}

                    {services.engaged.map((service: any, index) => (
                      <div key={`${service.name}-${index}`} className="grid grid-cols-[1fr_100px] items-center">
                        <div className="font-[500]">{service.name}</div>
                        <Pill
                          label={service.need_help ? 'Yes' : 'No'}
                          glyph={service.need_help ? 'check' : 'cross'}
                          color={service.need_help ? 'red' : 'green'}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="grid gap-2 content-start pl-4 border-0 border-0 border-solid border-divider">
                    <div className="grid grid-cols-[1fr_100px]">
                      <h4>Not Engaged</h4>
                      <h4>Needs Help?</h4>
                    </div>

                    {size(services.not_engaged) === 0 && (
                      <div className="italic text-text-strongly-muted text-center py-4">No services</div>
                    )}

                    {services.not_engaged.map((service: any, index) => (
                      <div key={`${service.name}-${index}`} className="grid grid-cols-[1fr_100px] items-center">
                        <div className="font-[500]">{service.name}</div>
                        <Pill
                          label={service.need_help ? 'Yes' : 'No'}
                          glyph={service.need_help ? 'check' : 'cross'}
                          color={service.need_help ? 'red' : 'green'}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {otherServices && (
                  <div className="border-t border-0 border-solid border-divider py-4">
                    <div className="grid grid-cols-[1fr_100px]">
                      <h4>Other specialist help or support</h4>
                    </div>

                    <div className="grid gap-2 mt-2">
                      <div>
                        <b>Engagement: </b> {otherServices.engagement || '–'}
                      </div>

                      <div>
                        <b>Need Help: </b> {otherServices.need_help || '–'}
                      </div>
                    </div>
                  </div>
                )}
              </ResultCard>

              <ResultCard
                title="Personal Capital"
                maxScore={MAX_VALUES['personal_capital_score']}
                score={data?.personal_capital_score}
                animationDelay={7 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricDataV2 clientId={referenceID} dimension="personal_capital" />
                  </Card>
                }
              >
                <div className="my-4 grid grid-cols-1 mq480:grid-cols-2 mq800:grid-cols-3 mq1000:grid-cols-4 gap-2">
                  {PERSONAL_RECOVERY_CAPITAL.map((item) => {
                    const isChecked = get(data, item.model)

                    return <Pill key={item.model} label={item.shortLabel} tooltip={item.label} type={isChecked ? 'positive' : 'negative'} />
                  })}
                </div>
              </ResultCard>

              <ResultCard
                title="Social Capital"
                maxScore={MAX_VALUES['social_capital_score']}
                score={data?.social_capital_score}
                animationDelay={8 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricDataV2 clientId={referenceID} dimension="social_capital" />
                  </Card>
                }
              >
                <div className="my-4 grid grid-cols-1 mq480:grid-cols-2 mq800:grid-cols-3 mq1000:grid-cols-4 gap-2">
                  {SOCIAL_RECOVERY_CAPITAL.map((item) => {
                    const isChecked = get(data, item.model)

                    return <Pill key={item.model} label={item.shortLabel} tooltip={item.label} type={isChecked ? 'positive' : 'negative'} />
                  })}
                </div>
              </ResultCard>

              <ResultCard
                title="Involvement with Recovery Community"
                maxScore={MAX_VALUES['recovery_group_score']}
                score={data?.recovery_group_score}
                animationDelay={9 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricDataV2 clientId={referenceID} dimension="recovery_capital" />
                  </Card>
                }
              >
                <div className="my-4 grid grid-cols-1 mq480:grid-cols-2 mq800:grid-cols-3 mq1000:grid-cols-4 gap-2">
                  {RECOVERY_GROUPS.map((item) => {
                    const isChecked = get(data, item.model)

                    return <Pill key={item.model} label={item.shortLabel} tooltip={item.label} type={isChecked ? 'positive' : 'negative'} />
                  })}
                </div>
              </ResultCard>

              <ResultCard
                title="Commitment"
                maxScore={MAX_VALUES['commitment_score']}
                score={data?.commitment_score}
                animationDelay={11 * ANIMATION_DELAY}
                overlayContent={
                  <Card className="pt-4">
                    <RecoveryCapitalHistoricDataV2 clientId={referenceID} dimension="commitment" />
                  </Card>
                }
              >
                <BarChart
                  min={1}
                  max={6}
                  labels={['Done with drugs', 'No more using', 'Recovery focused', 'Stay clean', 'Sobriety first']}
                  values={[
                    get(data, 'answers.commitment_domain.had_enough_commitment'),
                    get(data, 'answers.commitment_domain.no_return_commitment'),
                    get(data, 'answers.commitment_domain.recovery_commitment'),
                    get(data, 'answers.commitment_domain.off_drugs_commitment'),
                    get(data, 'answers.commitment_domain.sobriaty_importance'),
                  ]}
                />
              </ResultCard>
            </div>
          )}
        </Tabs.Panel>

        <Tabs.Panel name="form" className="[&.is-active]:!grid grid-cols-1 grid-rows-[1fr_auto] w-full h-full overflow-hidden">
          <ScrollView>
            <PageGrid scroll breakpoint={3} className="overlay-page-grid" css={STYLES.pageGrid}>
              <Nav
                top="0"
                breakpoint={3}
                title={' '}
                headingSize={300}
                desktopProps={{ title: null }}
                className="!bg-white shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!shadow-none [&_header]:!shadow-transparent [&_header]:!border-transparent"
              >
                <div className="opacity-80 text-[0.9rem] uppercase font-[700] tracking-[1px] mb-1">Table of Contents</div>
                <ScrollMenu />
              </Nav>

              <Overlay.Content>
                <Form
                  getForm={form}
                  initialModel={{
                    ...initialModel,
                    ...(isNew && defaultSupervisor && { supervisor: defaultSupervisor }),
                    ...(isNew &&
                      client && {
                        answers: {
                          demographics: {
                            sex: client.sex,
                            dob: client?.dob,
                            age: age(client?.dob),
                            race: client.new_race,
                            ethnicity: client.new_ethnicity,
                            part_of_priority_populations: client.part_of_priority_populations,
                            gender: {
                              [client.gender_identity]: true,
                            },
                          },
                        },
                      }),
                  }}
                  timezone={timezone}
                  isEditable={isEditable}
                  onValidationUpdate={onValidationUpdate}
                  key={`updated-${data?.updated_at}`}
                  linked={
                    isNew && {
                      client_id: referenceID,
                      version: '2.0',
                      variant: 'drug_court',
                    }
                  }
                >
                  <Section
                    title="General"
                    scrollview={{
                      id: 'general',
                      name: 'General',
                    }}
                    headingType="h2"
                  >
                    <FormSection>
                      <Input
                        label="Name"
                        model="name"
                        defaultValue="Recovery Capital Form"
                        isEditable={isEditable && !isPortal}
                        validations={{
                          presence: {
                            message: 'Please enter a form name',
                          },
                        }}
                      />

                      <DateTimeInput
                        defaultToNow
                        model="started_at"
                        label="Start Date and Time"
                        isEditable={isEditable && !isPortal}
                        validations={{
                          presence: {
                            message: 'Please enter a date and time',
                          },
                        }}
                      />

                      <FormFutureDateWarning dateLabel="Start Date and Time" model="started_at" />

                      <DateTimeInput model="ended_at" label="End Date and Time" isEditable={isEditable && !isPortal} />
                    </FormSection>
                  </Section>

                  <Divider />

                  {/* S1: DEMOGRAPHICS CHARACTERISTICS */}
                  <Section
                    title="S1: Demographic Characteristics"
                    scrollview={{
                      id: 'demographic_characteristics',
                      name: 'Demographic Characteristics',
                    }}
                    headingType="h2"
                  >
                    <FormSection>
                      <DateOfBirthInput isEditable={false} label="1.1 Date of Birth" model="answers.demographics.dob" />

                      <div>
                        <Flex gap="1rem" stretchChildrenX>
                          <Select
                            allowEmpty
                            label="1.2 Sex Assigned at Birth"
                            model="answers.demographics.sex"
                            validations={{
                              presence: {
                                message: 'Please select the Sex Assigned at Birth',
                              },
                            }}
                          >
                            <Option label="Male" value="male" />
                            <Option label="Female" value="female" />
                          </Select>
                        </Flex>
                      </div>

                      <RaceCheckboxGroup isRequired label="1.3 Race" model="answers.demographics.race" falseStyle="faded" />

                      <NumberInput isEditable={false} label="1.4 Age" model="answers.demographics.age" />

                      <NewEthnicityCheckboxGroup
                        isRequired
                        label="1.5 Ethnicity"
                        model="answers.demographics.ethnicity"
                        falseStyle="faded"
                      />

                      <CheckboxGroup
                        label="1.6 Part of Priority Populations"
                        layout="vertical-dense"
                        trueIcon="check"
                        falseStyle="hidden"
                        validations={{
                          presence: {
                            message: 'Please select at least one item',
                          },
                        }}
                      >
                        <Checkbox label="IV Drug Users" model="answers.demographics.part_of_priority_populations.iv_drug_users" />
                        <Checkbox
                          label="Pregnant Individuals"
                          model="answers.demographics.part_of_priority_populations.pregnant_individuals"
                        />
                        <Checkbox
                          label="Mothers with Dependent Children"
                          model="answers.demographics.part_of_priority_populations.mothers_with_dependent_children"
                        />
                        <Checkbox
                          label="Fathers with Dependent Children"
                          model="answers.demographics.part_of_priority_populations.fathers_with_dependent_children"
                        />
                        <Checkbox label="Veterans" model="answers.demographics.part_of_priority_populations.veterans" />
                        <Checkbox
                          label="Co-Occurring Disorders"
                          model="answers.demographics.part_of_priority_populations.co_occurring_disorders"
                        />
                        <Checkbox label="Justice-Involved" model="answers.demographics.part_of_priority_populations.justice_involved" />
                        <Checkbox label="Homeless" model="answers.demographics.part_of_priority_populations.homeless" />
                        <Checkbox label="LGBTQ+" model="answers.demographics.part_of_priority_populations.lgbtq" />
                        <Checkbox label="Indigenous" model="answers.demographics.part_of_priority_populations.indigenous" />
                        <Checkbox label="Disabled" model="answers.demographics.part_of_priority_populations.disabled" />
                        <Checkbox
                          label="DV / Human Trafficking Victims"
                          model="answers.demographics.part_of_priority_populations.dv_human_trafficking_victims"
                        />
                      </CheckboxGroup>

                      <div>
                        <Label label="1.7 How long do you consider yourself to have been in recovery for?" />

                        <Flex gap="1rem">
                          <DateInput size={4} suffix="years" model="answers.demographics.started_recovery_at" />
                        </Flex>
                      </div>
                    </FormSection>
                  </Section>

                  <Divider />

                  {/* S2: QUALITY OF LIFE */}
                  <Section
                    headingType="h2"
                    title="S2: Quality of Life and Satisfaction"
                    scrollview={{
                      id: 'quality_of_life_domain',
                      name: 'Quality of Life and Satisfaction',
                      parent: { id: 'domains', name: 'Domains' },
                    }}
                  >
                    <FormSection layout="vertical" width="100%">
                      <p>
                        For each of the questions below, please give a rating on the scale for how you are feeling <b>today</b>, where
                        higher scores mean you are feeling better and lower scores that you are not so satisfied with this part of your
                        life. Indicate your score by marking on the “rulers.”
                      </p>

                      <div className="grid gap-5">
                        <Slider
                          label="2.1 How good is your psychological health?"
                          model="answers.quality_of_life_domain.psychological_health"
                          min={0}
                          max={20}
                          marks={{ 1: '1 (Poor)', 10: '10 (Acceptable)', 20: '20 (Good)' }}
                          validations={{
                            presence: {
                              message: 'Please select an answer',
                            },
                            greaterThanOrEqualTo: {
                              value: 1,
                              message: 'Please select an answer greater than 0',
                            },
                            lessThanOrEqualTo: {
                              value: 20,
                              message: 'Please select an answer less than or equal to 20',
                            },
                          }}
                        />

                        <Slider
                          label="2.2 How good is your physical health?"
                          model="answers.quality_of_life_domain.physical_health"
                          min={0}
                          max={20}
                          marks={{ 1: '1 (Poor)', 10: '10 (Acceptable)', 20: '20 (Good)' }}
                          validations={{
                            presence: {
                              message: 'Please select an answer',
                            },
                            greaterThanOrEqualTo: {
                              value: 1,
                              message: 'Please select an answer greater than 0',
                            },
                            lessThanOrEqualTo: {
                              value: 20,
                              message: 'Please select an answer less than or equal to 20',
                            },
                          }}
                        />

                        <Slider
                          label="2.3 How would you rate your overall quality of life?"
                          model="answers.quality_of_life_domain.overall_quality_of_life"
                          min={0}
                          max={20}
                          marks={{ 1: '1 (Poor)', 10: '10 (Acceptable)', 20: '20 (Good)' }}
                          validations={{
                            presence: {
                              message: 'Please select an answer',
                            },
                            greaterThanOrEqualTo: {
                              value: 1,
                              message: 'Please select an answer greater than 0',
                            },
                            lessThanOrEqualTo: {
                              value: 20,
                              message: 'Please select an answer less than or equal to 20',
                            },
                          }}
                        />

                        <Slider
                          label="2.4 How would you rate the quality of your housing?"
                          model="answers.quality_of_life_domain.quality_of_housing"
                          min={0}
                          max={20}
                          marks={{ 1: '1 (Poor)', 10: '10 (Acceptable)', 20: '20 (Good)' }}
                          validations={{
                            presence: {
                              message: 'Please select an answer',
                            },
                            greaterThanOrEqualTo: {
                              value: 1,
                              message: 'Please select an answer greater than 0',
                            },
                            lessThanOrEqualTo: {
                              value: 20,
                              message: 'Please select an answer less than or equal to 20',
                            },
                          }}
                        />

                        <Slider
                          label="2.5 How would you rate your support network?"
                          model="answers.quality_of_life_domain.support_network"
                          min={0}
                          max={20}
                          marks={{ 1: '1 (Poor)', 10: '10 (Acceptable)', 20: '20 (Good)' }}
                          validations={{
                            presence: {
                              message: 'Please select an answer',
                            },
                            greaterThanOrEqualTo: {
                              value: 1,
                              message: 'Please select an answer greater than 0',
                            },
                            lessThanOrEqualTo: {
                              value: 20,
                              message: 'Please select an answer less than or equal to 20',
                            },
                          }}
                        />
                      </div>
                    </FormSection>
                  </Section>

                  <Divider />

                  {/* S3: BARRIERS TO HUMAN FLOURISHING  */}
                  <Section
                    headingType="h2"
                    title="S3: Barriers to Human Flourishing"
                    scrollview={{
                      id: 'barriers_to_recovery_domain',
                      name: 'Barriers to Human Flourishing',
                      parent: { id: 'domains', name: 'Domains' },
                    }}
                  >
                    <FormSection layout="vertical" maxWidth="800px">
                      <H3 className="mt-4">3.1 Housing</H3>

                      <Label label="At any point in the last month have you been:" className="!-mb-3" />

                      <YesNoRadioGroup
                        isRequired
                        label="At risk of eviction"
                        model="answers.barriers_to_recovery_domain.at_risk_of_eviction"
                        layout="horizontal-dense"
                      />

                      <YesNoRadioGroup
                        isRequired
                        label="Homeless"
                        model="answers.barriers_to_recovery_domain.homeless"
                        layout="horizontal-dense"
                      />

                      <H3 className="mt-4">3.2 Substance use</H3>

                      <YesNoRadioGroup
                        isRequired
                        label="Have you used any drugs or alcohol other than tobacco in the last 90 days?"
                        model="answers.barriers_to_recovery_domain.used_substances_in_the_last_ninety_days"
                        layout="horizontal-dense"
                      />

                      <ContextShow when="answers.barriers_to_recovery_domain.used_substances_in_the_last_ninety_days" is={true}>
                        <div>If yes, please complete the grid below:</div>

                        {SUBSTANCES_V2.map((substance: any) => (
                          <React.Fragment key={substance.model}>
                            <FormSection heading={substance.name} layout="vertical" className="ml-4 !gap-0">
                              <Flex gap="1.5rem">
                                <YesNoRadioGroup
                                  isRequired
                                  label="Used in the last 90 days?"
                                  model={`answers.barriers_to_recovery_domain.substances.${substance.model}.has_used_in_the_last_ninety_days`}
                                  layout="horizontal-dense"
                                />

                                <ContextShow
                                  when={`answers.barriers_to_recovery_domain.substances.${substance.model}.has_used_in_the_last_ninety_days`}
                                  is={true}
                                >
                                  {substance.specify === true && (
                                    <Input
                                      label="Please specify the substance"
                                      model={`answers.barriers_to_recovery_domain.substances.${substance.model}.specific_substance`}
                                      validations={{
                                        presence: {
                                          message: 'Please enter your answer',
                                        },
                                      }}
                                    />
                                  )}
                                </ContextShow>
                              </Flex>
                            </FormSection>

                            <Divider className="!m-0" />
                          </React.Fragment>
                        ))}
                      </ContextShow>

                      <H3 className="mt-4">3.3 Involvement with the Criminal Justice System</H3>

                      <YesNoRadioGroup
                        isRequired
                        label="Have you been concerned that you could have been arrested and charged in the last 90 days?"
                        model="answers.barriers_to_recovery_domain.criminal_justice.offended_in_the_last_ninety_days"
                        layout="horizontal-dense"
                      />

                      <YesNoRadioGroup
                        isRequired
                        label="Have you been arrested or charged for a new offence in the last 90 days?"
                        model="answers.barriers_to_recovery_domain.criminal_justice.arrested_or_charged_in_the_last_ninety_days"
                        layout="horizontal-dense"
                      />

                      <H3 className="mt-4">3.4 Work, training, and volunteering</H3>

                      <YesNoRadioGroup
                        isRequired
                        label="Are you currently working full-time?"
                        model="answers.barriers_to_recovery_domain.work_training_volunteering.working_full_time"
                        layout="horizontal-dense"
                      />

                      <YesNoRadioGroup
                        isRequired
                        label="Are you currently working part-time?"
                        model="answers.barriers_to_recovery_domain.work_training_volunteering.working_part_time"
                        layout="horizontal-dense"
                      />

                      <YesNoRadioGroup
                        isRequired
                        label="Are you currently attending school?"
                        model="answers.barriers_to_recovery_domain.work_training_volunteering.attending_school"
                        layout="horizontal-dense"
                      />

                      <YesNoRadioGroup
                        isRequired
                        label="Are you currently volunteering?"
                        model="answers.barriers_to_recovery_domain.work_training_volunteering.volunteering"
                        layout="horizontal-dense"
                      />

                      <H3 className="mt-4">3.5 Finances and transport</H3>

                      <YesNoRadioGroup
                        isRequired
                        label="Are you significantly in debt?"
                        model="answers.barriers_to_recovery_domain.finances_transport.is_significantly_in_debt"
                        layout="horizontal-dense"
                      />

                      <YesNoRadioGroup
                        isRequired
                        label="Do you have access to reliable transportation?"
                        model="answers.barriers_to_recovery_domain.finances_transport.has_access_to_reliable_transportation"
                        layout="horizontal-dense"
                      />
                    </FormSection>
                  </Section>

                  <Divider />

                  {/* S4: SERVICES AND NEEDS DOMAIN  */}
                  <Section
                    headingType="h2"
                    title="S4: Services Involvement and Needs"
                    scrollview={{
                      id: 'services_and_needs_domain',
                      name: 'Services Involvement and Needs',
                      parent: { id: 'domains', name: 'Domains' },
                    }}
                  >
                    <FormSection layout="vertical" maxWidth="100%">
                      <p>
                        The following table assesses what services you are engaged with and whether your current level of service
                        involvement is meeting your needs.
                      </p>

                      <div className="py-4 overflow-x-auto">
                        <div className={`hidden font-[700] mq800:grid grid-cols-3 gap-4 bg-[#EBEDF5] text-gray-700 p-4 rounded`}>
                          <div>Service</div>
                          <div>Are you currently engaged with this kind of services?</div>
                          <div>Do you need help or additional help in this area?</div>
                        </div>

                        {/* Drug or alcohol treatment services */}
                        <div
                          className={`grid grid-cols-1 mq800:grid-cols-3 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                        >
                          <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Drug or alcohol treatment services</div>

                          {/* Engagement */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Are you currently engaged with this kind of services?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.drug_alcohol_treatment_services.engaged"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>

                          {/* Need Help */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Do you need help or additional help in this area?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.drug_alcohol_treatment_services.need_help"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>
                        </div>

                        {/* Debt or credit counseling services */}
                        <div
                          className={`grid grid-cols-1 mq800:grid-cols-3 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                        >
                          <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Debt or credit counseling services</div>

                          {/* Engagement */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Are you currently engaged with this kind of services?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.debt_credit_counseling_services.engaged"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>

                          {/* Need Help */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Do you need help or additional help in this area?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.debt_credit_counseling_services.need_help"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>
                        </div>

                        {/* Mental health services */}
                        <div
                          className={`grid grid-cols-1 mq800:grid-cols-3 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                        >
                          <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Mental health services</div>

                          {/* Engagement */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Are you currently engaged with this kind of services?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.mental_health_services.engaged"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>

                          {/* Need Help */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Do you need help or additional help in this area?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.mental_health_services.need_help"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>
                        </div>

                        {/* Housing support */}
                        <div
                          className={`grid grid-cols-1 mq800:grid-cols-3 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                        >
                          <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Housing support</div>

                          {/* Engagement */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Are you currently engaged with this kind of services?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.housing_support.engaged"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>

                          {/* Need Help */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Do you need help or additional help in this area?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.housing_support.need_help"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>
                        </div>

                        {/* Employment services */}
                        <div
                          className={`grid grid-cols-1 mq800:grid-cols-3 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                        >
                          <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Employment services</div>

                          {/* Engagement */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Are you currently engaged with this kind of services?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.employment_services.engaged"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>

                          {/* Need Help */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Do you need help or additional help in this area?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.employment_services.need_help"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>
                        </div>

                        {/* Primary healthcare services (GP, medical services) */}
                        <div
                          className={`grid grid-cols-1 mq800:grid-cols-3 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                        >
                          <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">
                            Primary Healthcare Services (GP, medical services)
                          </div>

                          {/* Engagement */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Are you currently engaged with this kind of services?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.primary_healthcare.engaged"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>

                          {/* Need Help */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Do you need help or additional help in this area?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.primary_healthcare.need_help"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>
                        </div>

                        {/* Family relationships */}
                        <div
                          className={`grid grid-cols-1 mq800:grid-cols-3 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                        >
                          <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Family relationships</div>

                          {/* Engagement */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Are you currently engaged with this kind of services?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.family_relationships.engaged"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>

                          {/* Need Help */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Do you need help or additional help in this area?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.family_relationships.need_help"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>
                        </div>

                        {/* Dental services */}
                        <div
                          className={`grid grid-cols-1 mq800:grid-cols-3 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                        >
                          <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">Dental services</div>

                          {/* Engagement */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Are you currently engaged with this kind of services?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.dental_services.engaged"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>

                          {/* Need Help */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Do you need help or additional help in this area?" />
                            <RadioGroup
                              model="answers.services_and_needs_domain.dental_services.need_help"
                              layout="horizontal-dense"
                              validations={{
                                presence: {
                                  message: 'Please enter your answer',
                                },
                              }}
                            >
                              <Radio label="Yes" value={true} />
                              <Radio label="No" value={false} />
                            </RadioGroup>
                          </div>
                        </div>

                        {/* Other specialist help or support */}
                        <div
                          className={`grid grid-cols-1 mq800:grid-cols-3 gap-4 items-center border-b border-solid border-0 border-divider py-4`}
                        >
                          <div className="text-[1.2rem] font-[700] mq800:pl-4 mq800:text-[1rem]">
                            Other specialist help or support (please specify):
                          </div>

                          {/* Engagement */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Are you currently engaged with this kind of services?" />
                            <Textarea className="w-full" model="answers.services_and_needs_domain.other_help.engagement" />
                          </div>

                          {/* Need Help */}
                          <div className="grid gap-0">
                            <Label className="mq800:!hidden" label="Do you need help or additional help in this area?" />
                            <Textarea className="w-full" model="answers.services_and_needs_domain.other_help.need_help" />
                          </div>
                        </div>
                      </div>
                    </FormSection>
                  </Section>

                  <Divider />

                  {/* S5: PERSONAL CAPITAL */}
                  <Section
                    headingType="h2"
                    title="S5: Personal Capital"
                    scrollview={{
                      id: 'personal_recovery_domain',
                      name: 'Personal Capital',
                      parent: { id: 'domains', name: 'Domains' },
                    }}
                  >
                    <FormSection layout="vertical">
                      <div>
                        Please read the following statements and check a statement only if you agree with it entirely and unreservedly. Do
                        not linger over the question but give your initial feeling at this moment. If you disagree or are unsure, leave it
                        blank. These should reflect how you currently feel about things that have happened to you in the last 3 months (90
                        days).
                      </div>

                      <CheckboxGroup
                        layout="vertical-dense"
                        label="Check if you agree with this statement:"
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded"
                      >
                        {PERSONAL_RECOVERY_CAPITAL.map((item) => (
                          <Checkbox key={item.model} label={item.label} model={item.model} />
                        ))}
                      </CheckboxGroup>
                    </FormSection>
                  </Section>

                  <Divider />

                  {/* S6: SOCIAL CAPITAL */}
                  <Section
                    headingType="h2"
                    title="S6: Social Capital"
                    scrollview={{
                      id: 'social_recovery_domain',
                      name: 'Social Capital',
                      parent: { id: 'domains', name: 'Domains' },
                    }}
                  >
                    <FormSection layout="vertical">
                      <div>
                        Please read the following statements and check a statement only if you agree with it completely. Do not linger over
                        the question but give your initial feeling at this moment. If you disagree or are unsure, leave it blank. These
                        statements are about how you currently feel and about things that have happened to you in the last 3 months (90
                        days).
                      </div>

                      <CheckboxGroup
                        layout="vertical-dense"
                        label="Check if you agree with this statement:"
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded"
                      >
                        {SOCIAL_RECOVERY_CAPITAL.map((item) => (
                          <Checkbox key={item.model} label={item.label} model={item.model} />
                        ))}
                      </CheckboxGroup>
                    </FormSection>
                  </Section>

                  <Divider />

                  {/* S7: INVOLVEMENT WITH YOUR RECOVERY COMMUNITY */}
                  <Section
                    headingType="h2"
                    title="S7: Involvement with Your Recovery Community"
                    scrollview={{
                      id: 'recovery_groups_domain',
                      name: 'Involvement with Your Recovery Community',
                      parent: { id: 'domains', name: 'Domains' },
                    }}
                  >
                    <FormSection layout="vertical">
                      <div>
                        Please check if you agree with any of the following statements about any group you have attended in the community in
                        the last month to support your recovery. These questions refer to any group – formal or informal – that you attend
                        that supports your recovery, including AA, NA, SMART Recovery, local peer groups, aftercare groups and any other
                        types of recovery group you belong to:
                      </div>

                      <CheckboxGroup
                        layout="vertical-dense"
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded"
                        label="Check if you agree with this statement:"
                      >
                        {RECOVERY_GROUPS.map((item) => (
                          <Checkbox key={item.model} label={item.label} model={item.model} />
                        ))}
                      </CheckboxGroup>
                    </FormSection>
                  </Section>

                  <Divider />

                  {/* S8: COMMUNITY GROUP INVOLVEMENT */}
                  <Section
                    headingType="h2"
                    title="S8: Community Group Involvement"
                    scrollview={{
                      id: 'community_group_involvement_domain',
                      name: 'Community Group Involvement',
                      parent: { id: 'domains', name: 'Domains' },
                    }}
                  >
                    <FormSection layout="vertical">
                      <CheckboxGroup
                        label="8.1 Please tick if you have attended any of these community groups at least once in the last 30 days. If you attend a group once a week or more than once a week, please tick in the appropriate box."
                        layout="vertical-dense"
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded"
                        validations={{
                          presence: {
                            message: 'Please select at least one item',
                          },
                        }}
                      >
                        <Checkbox
                          label="None"
                          model="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.none"
                        />
                        <Checkbox
                          label="Community Center"
                          model="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.community_center"
                        />
                        <Checkbox
                          label="Sports Club"
                          model="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.sports_club"
                        />
                        <Checkbox
                          label="Arts or recreation center"
                          model="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.arts_or_recreation_center"
                        />
                        <Checkbox
                          label="Church or religious institution"
                          model="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.church_or_religious_institution"
                        />
                        <Checkbox
                          label="College or university"
                          model="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.college_or_university"
                        />
                        <Checkbox
                          label="Training center"
                          model="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.training_center"
                        />
                        <Checkbox
                          label="Volunteering groups"
                          model="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.volunteering_groups"
                        />
                        <Checkbox
                          label="Other"
                          model="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.other"
                        />
                      </CheckboxGroup>

                      <ContextShow when="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.other" is={true}>
                        <Textarea
                          label="Please specify the other community group(s):"
                          model="answers.community_group_involvement_domain.community_groups_in_last_thirty_days.other_specify"
                          validations={{
                            presence: {
                              message: 'Please enter your answer',
                            },
                          }}
                        />
                      </ContextShow>

                      <YesNoRadioGroup
                        isRequired
                        label="8.2 Are you satisfied with your level of community engagement?"
                        model="answers.community_group_involvement_domain.satisfied_with_level_of_community_engagement"
                        layout="horizontal-dense"
                      />

                      <YesNoRadioGroup
                        isRequired
                        label="8.3 Do you view yourself as an active citizen?"
                        model="answers.community_group_involvement_domain.view_self_as_active_citizen"
                        layout="horizontal-dense"
                      />
                    </FormSection>
                  </Section>

                  <Divider />

                  {/* S9: COMMITMENT */}
                  <Section
                    headingType="h2"
                    title="S9: Commitment"
                    scrollview={{
                      id: 'commitment_domain',
                      name: 'Commitment',
                      parent: { id: 'domains', name: 'Domains' },
                    }}
                  >
                    <FormSection layout="vertical">
                      <p>
                        For each of the questions below, please give a rating on the scale for how you are feeling about the question{' '}
                        <b>today</b>, where higher scores mean you strongly agree and lower scores mean you strongly disagree with this
                        statement. Indicate your score by circling the number that best describes your feeling.
                      </p>

                      <div className="grid gap-5">
                        <Slider
                          label="9.1 Staying sober/clean is the most important thing in my life"
                          model="answers.commitment_domain.sobriaty_importance"
                          min={0}
                          max={6}
                          marks={{ 1: '1 (Strongly Disagree)', 6: '6 (Strongly Agree)' }}
                          validations={{
                            presence: {
                              message: 'Please select an answer',
                            },
                            greaterThanOrEqualTo: {
                              value: 1,
                              message: 'Please select an answer greater than 0',
                            },
                            lessThanOrEqualTo: {
                              value: 6,
                              message: 'Please select an answer less than or equal to 6',
                            },
                          }}
                        />

                        <Slider
                          label="9.2 I am totally committed to staying off of alcohol/drugs"
                          model="answers.commitment_domain.off_drugs_commitment"
                          min={0}
                          max={6}
                          marks={{ 1: '1 (Strongly Disagree)', 6: '6 (Strongly Agree)' }}
                          validations={{
                            presence: {
                              message: 'Please select an answer',
                            },
                            greaterThanOrEqualTo: {
                              value: 1,
                              message: 'Please select an answer greater than 0',
                            },
                            lessThanOrEqualTo: {
                              value: 6,
                              message: 'Please select an answer less than or equal to 6',
                            },
                          }}
                        />

                        <Slider
                          label="9.3 I will do whatever it takes to recover from my addiction"
                          model="answers.commitment_domain.recovery_commitment"
                          min={0}
                          max={6}
                          marks={{ 1: '1 (Strongly Disagree)', 6: '6 (Strongly Agree)' }}
                          validations={{
                            presence: {
                              message: 'Please select an answer',
                            },
                            greaterThanOrEqualTo: {
                              value: 1,
                              message: 'Please select an answer greater than 0',
                            },
                            lessThanOrEqualTo: {
                              value: 6,
                              message: 'Please select an answer less than or equal to 6',
                            },
                          }}
                        />

                        <Slider
                          label="9.4 I never want to return to alcohol/drug use again"
                          model="answers.commitment_domain.no_return_commitment"
                          min={0}
                          max={6}
                          marks={{ 1: '1 (Strongly Disagree)', 6: '6 (Strongly Agree)' }}
                          validations={{
                            presence: {
                              message: 'Please select an answer',
                            },
                            greaterThanOrEqualTo: {
                              value: 1,
                              message: 'Please select an answer greater than 0',
                            },
                            lessThanOrEqualTo: {
                              value: 6,
                              message: 'Please select an answer less than or equal to 6',
                            },
                          }}
                        />

                        <Slider
                          label="9.5 I have had enough alcohol and drugs"
                          model="answers.commitment_domain.had_enough_commitment"
                          min={0}
                          max={6}
                          marks={{ 1: '1 (Strongly Disagree)', 6: '6 (Strongly Agree)' }}
                          validations={{
                            presence: {
                              message: 'Please select an answer',
                            },
                            greaterThanOrEqualTo: {
                              value: 1,
                              message: 'Please select an answer greater than 0',
                            },
                            lessThanOrEqualTo: {
                              value: 6,
                              message: 'Please select an answer less than or equal to 6',
                            },
                          }}
                        />
                      </div>
                    </FormSection>
                  </Section>

                  <Divider />

                  {/* S10: WHAT DO YOU SEE AS YOUR NEEDS? */}
                  <Section
                    headingType="h2"
                    title="S10: What do you see as your needs?"
                    scrollview={{
                      id: 'needs_domain',
                      name: 'What do you see as your needs?',
                      parent: { id: 'domains', name: 'Domains' },
                    }}
                  >
                    <FormSection layout="vertical">
                      <Label label="10.1 What do you see as your main strengths in the following areas?" />

                      <div className="grid gap-4 pl-5 -mt-2">
                        <Textarea
                          label="Personal skills, qualities, knowledge and experience:"
                          model="answers.needs_domain.main_strengths.personal_skills_qualities_knowledge_and_experience"
                          validations={{
                            presence: {
                              message: 'Please enter your answer',
                            },
                          }}
                        />

                        <Textarea
                          label="Social networks and supports"
                          model="answers.needs_domain.main_strengths.social_networks_and_supports"
                          validations={{
                            presence: {
                              message: 'Please enter your answer',
                            },
                          }}
                        />

                        <Textarea
                          label="Memberships of groups and societies"
                          model="answers.needs_domain.main_strengths.memberships_of_groups_and_societies"
                          validations={{
                            presence: {
                              message: 'Please enter your answer',
                            },
                          }}
                        />
                      </div>

                      <YesNoRadioGroupWithTextarea
                        isRequired
                        label="10.2 Do you have a clear mission or purpose in life?"
                        model="answers.needs_domain.has_clear_mission_or_purpose_in_life"
                        textareaLabel="If yes, what is it?"
                        layout="horizontal-dense"
                      />

                      <YesNoRadioGroup
                        isRequired
                        label="10.3 Do you have clear goals for the next three months?"
                        model="answers.needs_domain.has_clear_goals_for_next_three_months"
                        layout="horizontal-dense"
                        onUpdate={({ value }) => setHasClearGoals(value)}
                      />

                      {hasClearGoals && (
                        <DataArray model="answers.needs_domain.goals_for_next_three_months" initialValue={INITIAL_GOALS}>
                          {({ orderedIds, add, remove }: any) => (
                            <>
                              {orderedIds.map((id: any, index: number) => (
                                <div key={id} className="pl-4 pt-2 pb-3">
                                  <Textarea
                                    label={`Goal #${index + 1}`}
                                    labelAfter={
                                      isEditable && (
                                        <Button
                                          hideLabel
                                          glyph="delete"
                                          size={100}
                                          color="text"
                                          type="minimal"
                                          onClick={() => {
                                            remove(id)
                                          }}
                                          css={{
                                            width: '16px !important',
                                            height: '16px !important',
                                            svg: { margin: '0 !important' },
                                          }}
                                        />
                                      )
                                    }
                                    withHover={false}
                                    model={`${id}.goal`}
                                    validations={{
                                      presence: {
                                        message: 'Please enter your answer',
                                      },
                                    }}
                                  />
                                </div>
                              ))}

                              {isEditable && (
                                <div>
                                  <Button label="Add Goal" glyph="add" type="primary" display="inline-flex" size={200} onClick={add} />
                                </div>
                              )}
                            </>
                          )}
                        </DataArray>
                      )}

                      <YesNoRadioGroup
                        isRequired
                        label="10.4 Do you have clear long-term goals in your recovery?"
                        model="answers.needs_domain.has_clear_long_term_goals_in_recovery"
                        layout="horizontal-dense"
                        onUpdate={({ value }) => setHasLongTermGoals(value)}
                      />

                      {hasLongTermGoals && (
                        <DataArray model="answers.needs_domain.long_term_goals_in_recovery" initialValue={INITIAL_GOALS}>
                          {({ orderedIds, add, remove }: any) => (
                            <>
                              {orderedIds.map((id: any, index: number) => (
                                <div key={id} className="pl-4 pt-2 pb-3">
                                  <Textarea
                                    label={`Goal #${index + 1}`}
                                    labelAfter={
                                      isEditable && (
                                        <Button
                                          hideLabel
                                          glyph="delete"
                                          size={100}
                                          color="text"
                                          type="minimal"
                                          onClick={() => {
                                            remove(id)
                                          }}
                                          css={{
                                            width: '16px !important',
                                            height: '16px !important',
                                            svg: { margin: '0 !important' },
                                          }}
                                        />
                                      )
                                    }
                                    withHover={false}
                                    model={`${id}.goal`}
                                    validations={{
                                      presence: {
                                        message: 'Please enter your answer',
                                      },
                                    }}
                                  />
                                </div>
                              ))}

                              {isEditable && (
                                <div>
                                  <Button label="Add Goal" glyph="add" type="primary" display="inline-flex" size={200} onClick={add} />
                                </div>
                              )}
                            </>
                          )}
                        </DataArray>
                      )}
                    </FormSection>
                  </Section>
                </Form>
              </Overlay.Content>
            </PageGrid>
          </ScrollView>

          {data?.status !== 'signed_off' && !isPortal && (
            <Overlay.Footer withGradient={false}>
              {isEditable && (
                <>
                  <Button
                    label={isNew ? 'Save as Draft' : 'Save Changes'}
                    glyph="check"
                    type="primary"
                    color="green"
                    onClick={save}
                    isLoading={isSaving}
                    isDisabled={isSaving}
                    flex="100 1 auto"
                    permission={isBehave ? true : isNew ? 'recovery_capital_forms.create' : 'recovery_capital_forms.edit'}
                  />

                  {isInvalid && (
                    <Button
                      label="Highlight Required Fields"
                      glyph="view"
                      type="default"
                      color="orange"
                      onClick={validateForm}
                      isDisabled={isSaving}
                    />
                  )}

                  {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
                </>
              )}

              {!isEditable && (
                <>
                  {data?.lock_status !== 'locked' && (
                    <>
                      <Button
                        glyph="edit"
                        label="Edit Form"
                        type="default"
                        isDisabled={isSaving}
                        onClick={edit}
                        flex="100 1 auto"
                        permission={isBehave ? true : 'recovery_capital_forms.edit'}
                      />

                      {data?.status !== 'completed' && (
                        <ConfirmDialog
                          title="Complete Recovery Capital Form?"
                          message={`Are you sure you want to complete this form?`}
                          onYes={completeForm}
                          isDisabled={isInvalid}
                        >
                          <Button
                            glyph="check"
                            label="Complete"
                            type="primary"
                            color="green"
                            isDisabled={isInvalid}
                            flex="100 1 auto"
                            permission={isBehave ? true : 'recovery_capital_forms.edit'}
                          />
                        </ConfirmDialog>
                      )}

                      <DeleteDialog
                        title="Delete Recovery Capital Form?"
                        message={`Are you sure you want to delete this form? This action cannot be undone.`}
                        onYes={deleteRecord}
                        permission={isBehave ? true : 'recovery_capital_forms.delete'}
                      >
                        <Button
                          glyph="delete"
                          label="Delete Form…"
                          type="default"
                          color="red"
                          isDisabled={isSaving}
                          fullWidth
                          permission={isBehave ? true : 'recovery_capital_forms.delete'}
                        />
                      </DeleteDialog>
                    </>
                  )}
                </>
              )}
            </Overlay.Footer>
          )}
        </Tabs.Panel>
      </Tabs>
    </Overlay>
  )
}

const STYLES = {
  pageGrid: {
    flex: '1 1 auto',
  },

  nav: {
    [MEDIA_QUERY[3]]: {
      maxWidth: 300,
    },
  },

  formContract: {
    '&:last-of-type': { boxShadow: 'none !important' },
  },
}

export const RecoveryCapitalFormOverlayDrugCourt = withOverlayError(RootRecoveryCapitalFormOverlayDrugCourt)
