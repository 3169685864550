import React from 'react'
import { tint } from 'polished'
import isUndefined from 'lodash/isUndefined'

import { COLORS, FEATURES, HARD_SHADOW } from '../../theme'
import { useAppSidebarStore } from '../../hooks/useAppSidebarStore'
import { usePinsStore } from '../../components/AppSidebar/pinsStore'
import { useSettings } from '../../hooks/useSettings'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Link from '../../components/Link'
import NavItem from '../../components/NavItem'

import { ResourceTag } from '../Resources/ResourceTag'
import { SearchHighlight } from './SearchHighlight'
import { SearchResultContext } from './context'
import { useSearchStore } from './store'

export const SearchResult: React.FC<any> = ({ result }) => {
  const setActiveResult = useSearchStore((state: any) => state.setActiveResult)

  const handleClick = () => {
    setActiveResult(result.document)
  }

  const Tag = getTag(result.document)

  if (!Tag) return null

  return (
    <SearchResultContext.Provider value={{ document: result.document, highlights: result.highlights }}>
      <Tag data={result.document} onClick={handleClick} />
    </SearchResultContext.Provider>
  )
}

const getTag = (document: any) => {
  if (!document?.type) return null

  let Tag = null

  if (typeof RESULTS[document.type] === 'function') Tag = RESULTS[document.type]
  else if (RESULTS[document.type][document.status]) Tag = RESULTS[document.type][document.status]

  return Tag
}

const RootResult: React.FC<any> = ({ data = {}, avatar, icon, title, description, onClick, quickLinks, quickLinkBase }) => {
  const { tenant } = useSettings()

  const activeResult = useSearchStore((state: any) => state.activeResult)
  const openAppSidebar = useAppSidebarStore((state: any) => state.open)
  const resultsView = useSearchStore((state: any) => state.resultsView)
  const close = useSearchStore((state: any) => state.close)

  const pins = usePinsStore((state: any) => state.pins)
  const togglePin = usePinsStore((state: any) => state.togglePin)

  const id = data.document_id || data.id
  const type = data.type

  if (!type && !id) return null

  const handlePinClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    openAppSidebar('pins')
    togglePin(tenant.subdomain, pinID, data)
  }

  const tenantPins = pins[tenant.subdomain] || {}
  const pinID = `${data.type}-${data.document_id}`
  const isPinned = !!tenantPins[pinID]
  const isOpen = `${activeResult.document_id}-${activeResult.type}` === `${data.document_id}-${data.type}`

  return (
    <>
      <Link css={styles.result} to={quickLinkBase} onClick={close} className={isOpen ? 'is-open' : ''}>
        <div css={styles.graphic}>
          {icon && <Icon icon={icon} size={20} />}
          {!isUndefined(avatar) && <Avatar src={avatar} initials={title} size={'2rem'} />}
        </div>

        <div css={styles.content}>
          <h3 className="title">{title}</h3>

          <Flex centerY gap={8} css={styles.description}>
            <ResourceTag isSmall data={data} />
            {description}
          </Flex>
        </div>

        <Flex centerY nowrap gap={12}>
          <Button
            rounded
            hideLabel
            icon="pins"
            size={200}
            onClick={handlePinClick}
            css={styles.pinButton}
            className={isPinned ? 'is-pinned' : ''}
          />

          <Glyph glyph="chevron" size={14} color={COLORS.textMuted} />
        </Flex>
      </Link>

      {quickLinks && resultsView === 'details' && (
        <div css={styles.quickLinksWrapper}>
          {quickLinks.map((o: any) => (
            <NavItem
              icon={o.icon}
              label={o.label}
              iconSize={20}
              to={`${quickLinkBase}${o.to}`}
              onClick={close}
              css={styles.quickLink}
              size={200}
              permission={o.permission}
            />
          ))}
        </div>
      )}
    </>
  )
}

const ClientResult = ({ data, onClick }: any) => (
  <RootResult
    data={data}
    title={<SearchHighlight fieldName="name" />}
    description={
      <div>
        <SearchHighlight fieldName="behave_id" prefix="#" />
        {', '}
        <SearchHighlight fieldName="gender" capitalize />
        {', '}
        <SearchHighlight fieldName="email" />
        {', '}
        <SearchHighlight fieldName="phone_no" />
      </div>
    }
    avatar={data.avatar || ''}
    onClick={onClick}
    quickLinkBase={`/clients/${data?.document_id}`}
    quickLinks={[
      {
        icon: FEATURES.general_info.icon,
        label: FEATURES.general_info.title,
        to: '/general-info',
      },
      {
        icon: FEATURES.tasks.icon,
        label: FEATURES.tasks.title,
        to: '/todo',
        permission: 'todos.view',
      },
      {
        icon: FEATURES.files.icon,
        label: FEATURES.files.title,
        to: '/files',
        permission: 'clients.files.view',
      },
      {
        icon: FEATURES.agreements.icon,
        label: FEATURES.agreements.title,
        to: '/agreements',
        permission: 'agreements.view',
      },
      {
        icon: FEATURES.shift_notes.icon,
        label: FEATURES.shift_notes.title,
        to: '/shift-notes',
        permission: 'shift_notes.view',
      },
      {
        icon: FEATURES.financials.icon,
        label: FEATURES.financials.title,
        to: '/financials',
        permission: 'ledger.view',
      },
      {
        icon: FEATURES.communications.icon,
        label: FEATURES.communications.title,
        to: '/communications',
        permission: 'communications.view',
      },
      {
        icon: FEATURES.test_results.icon,
        label: FEATURES.test_results.title,
        to: '/test-results',
      },
      {
        icon: FEATURES.medications.icon,
        label: FEATURES.medications.title,
        to: '/medications',
        permission: 'medications.view',
      },
      {
        icon: FEATURES.custom_notes.icon,
        label: FEATURES.custom_notes.title,
        to: '/custom-notes',
        permission: 'client_custom_notes.view',
      },
      {
        icon: FEATURES.form_submissions.icon,
        label: FEATURES.form_submissions.title,
        to: '/form-submissions',
        permission: 'form_submissions.view',
      },
    ]}
  />
)

const LeadResult = ({ data, onClick }: any) => (
  <RootResult
    data={data}
    title={<SearchHighlight fieldName="name" />}
    description={
      <div>
        <SearchHighlight fieldName="behave_id" prefix="#" />
        {', '}
        <SearchHighlight fieldName="gender" capitalize />
        {', '}
        <SearchHighlight fieldName="email" />
        {', '}
        <SearchHighlight fieldName="phone_no" />
      </div>
    }
    avatar={data.avatar || ''}
    onClick={onClick}
    quickLinkBase={`/admissions/${data?.document_id}`}
    quickLinks={[
      {
        icon: FEATURES.general_info.icon,
        label: FEATURES.general_info.title,
        to: '/general-info',
      },
      {
        icon: FEATURES.tasks.icon,
        label: FEATURES.tasks.title,
        to: '/todo',
        permission: 'todos.view',
      },
      {
        icon: FEATURES.files.icon,
        label: FEATURES.files.title,
        to: '/files',
        permission: 'clients.files.view',
      },
      {
        icon: FEATURES.agreements.icon,
        label: FEATURES.agreements.title,
        to: '/agreements',
        permission: 'agreements.view',
      },
      {
        icon: FEATURES.financials.icon,
        label: FEATURES.financials.title,
        to: '/financials',
        permission: 'ledger.view',
      },
      {
        icon: FEATURES.communications.icon,
        label: FEATURES.communications.title,
        to: '/communications',
        permission: 'communications.view',
      },
      {
        icon: FEATURES.test_results.icon,
        label: FEATURES.test_results.title,
        to: '/test-results',
      },
      {
        icon: FEATURES.medications.icon,
        label: FEATURES.medications.title,
        to: '/medications',
        permission: 'medications.view',
      },
      {
        icon: FEATURES.custom_notes.icon,
        label: FEATURES.custom_notes.title,
        to: '/custom-notes',
        permission: 'client_custom_notes.view',
      },
      {
        icon: FEATURES.form_submissions.icon,
        label: FEATURES.form_submissions.title,
        to: '/form-submissions',
        permission: 'form_submissions.view',
      },
    ]}
  />
)

const IntakeResult = ({ data, onClick }: any) => (
  <RootResult
    data={data}
    title={<SearchHighlight fieldName="name" />}
    description={
      <div>
        <SearchHighlight fieldName="behave_id" prefix="#" />
        {', '}
        <SearchHighlight fieldName="gender" capitalize />
        {', '}
        <SearchHighlight fieldName="email" />
        {', '}
        <SearchHighlight fieldName="phone_no" />
      </div>
    }
    avatar={data.avatar || ''}
    onClick={onClick}
    quickLinkBase={`/admissions/${data?.document_id}`}
    quickLinks={[
      {
        icon: FEATURES.general_info.icon,
        label: FEATURES.general_info.title,
        to: '/general-info',
      },
      {
        icon: FEATURES.tasks.icon,
        label: FEATURES.tasks.title,
        to: '/todo',
        permission: 'todos.view',
      },
      {
        icon: FEATURES.files.icon,
        label: FEATURES.files.title,
        to: '/files',
        permission: 'clients.files.view',
      },
      {
        icon: FEATURES.agreements.icon,
        label: FEATURES.agreements.title,
        to: '/agreements',
        permission: 'agreements.view',
      },
      {
        icon: FEATURES.financials.icon,
        label: FEATURES.financials.title,
        to: '/financials',
        permission: 'ledger.view',
      },
      {
        icon: FEATURES.communications.icon,
        label: FEATURES.communications.title,
        to: '/communications',
        permission: 'communications.view',
      },
      {
        icon: FEATURES.test_results.icon,
        label: FEATURES.test_results.title,
        to: '/test-results',
      },
      {
        icon: FEATURES.medications.icon,
        label: FEATURES.medications.title,
        to: '/medications',
        permission: 'medications.view',
      },
      {
        icon: FEATURES.custom_notes.icon,
        label: FEATURES.custom_notes.title,
        to: '/custom-notes',
        permission: 'client_custom_notes.view',
      },
      {
        icon: FEATURES.form_submissions.icon,
        label: FEATURES.form_submissions.title,
        to: '/form-submissions',
        permission: 'form_submissions.view',
      },
    ]}
  />
)

const AlumniResult = ({ data, onClick }: any) => (
  <RootResult
    data={data}
    title={<SearchHighlight fieldName="name" />}
    description={
      <div>
        <SearchHighlight fieldName="behave_id" prefix="#" />
        {', '}
        <SearchHighlight fieldName="gender" capitalize />
        {', '}
        <SearchHighlight fieldName="email" />
        {', '}
        <SearchHighlight fieldName="phone_no" />
      </div>
    }
    avatar={data.avatar || ''}
    onClick={onClick}
    quickLinkBase={`/alumni/${data?.document_id}`}
    quickLinks={[
      {
        icon: FEATURES.general_info.icon,
        label: FEATURES.general_info.title,
        to: '/general-info',
      },
      {
        icon: FEATURES.tasks.icon,
        label: FEATURES.tasks.title,
        to: '/todo',
        permission: 'todos.view',
      },
      {
        icon: FEATURES.files.icon,
        label: FEATURES.files.title,
        to: '/files',
        permission: 'clients.files.view',
      },
      {
        icon: FEATURES.agreements.icon,
        label: FEATURES.agreements.title,
        to: '/agreements',
        permission: 'agreements.view',
      },
      {
        icon: FEATURES.shift_notes.icon,
        label: FEATURES.shift_notes.title,
        to: '/shift-notes',
        permission: 'shift_notes.view',
      },
      {
        icon: FEATURES.financials.icon,
        label: FEATURES.financials.title,
        to: '/financials',
        permission: 'ledger.view',
      },
      {
        icon: FEATURES.communications.icon,
        label: FEATURES.communications.title,
        to: '/communications',
        permission: 'communications.view',
      },
      {
        icon: FEATURES.test_results.icon,
        label: FEATURES.test_results.title,
        to: '/test-results',
      },
      {
        icon: FEATURES.medications.icon,
        label: FEATURES.medications.title,
        to: '/medications',
        permission: 'medications.view',
      },
      {
        icon: FEATURES.custom_notes.icon,
        label: FEATURES.custom_notes.title,
        to: '/custom-notes',
        permission: 'client_custom_notes.view',
      },
      {
        icon: FEATURES.form_submissions.icon,
        label: FEATURES.form_submissions.title,
        to: '/form-submissions',
        permission: 'form_submissions.view',
      },
    ]}
  />
)

const DeclinedApplicantResult = ({ data, onClick }: any) => (
  <RootResult
    data={data}
    title={<SearchHighlight fieldName="name" />}
    description={
      <div>
        <SearchHighlight fieldName="behave_id" prefix="#" />
        {', '}
        <SearchHighlight fieldName="gender" capitalize />
        {', '}
        <SearchHighlight fieldName="email" />
        {', '}
        <SearchHighlight fieldName="phone_no" />
      </div>
    }
    avatar={data.avatar || ''}
    onClick={onClick}
    quickLinkBase={`/admissions/${data?.document_id}`}
    quickLinks={[
      {
        icon: FEATURES.general_info.icon,
        label: FEATURES.general_info.title,
        to: '/general-info',
      },
      {
        icon: FEATURES.tasks.icon,
        label: FEATURES.tasks.title,
        to: '/todo',
      },
      {
        icon: FEATURES.files.icon,
        label: FEATURES.files.title,
        to: '/files',
      },
      {
        icon: FEATURES.shift_notes.icon,
        label: FEATURES.shift_notes.title,
        to: '/shift-notes',
      },
      {
        icon: FEATURES.recovery_coaching.icon,
        label: FEATURES.recovery_coaching.title,
        to: '/recovery-coaching',
      },
      {
        icon: FEATURES.location_checkin.icon,
        label: FEATURES.location_checkin.title,
        to: '/location-checkin',
      },
    ]}
  />
)

const RESULTS: any = {
  resident: {
    current: ClientResult,
    lead: LeadResult,
    intake: IntakeResult,
    alumni: AlumniResult,
    declined: DeclinedApplicantResult,
  },
}

const styles = {
  result: {
    display: 'grid',
    alignItems: 'center',
    gridGap: '0.25rem',
    gridTemplateColumns: '2.5rem 1fr min-content',
    padding: '0.3rem 0.5rem',
    borderBottom: `1px solid ${COLORS.divider}`,
    cursor: 'pointer',
    boxShadow: HARD_SHADOW(2),
    zIndex: 1,

    mark: {
      backgroundColor: tint(0.7, COLORS.gold),
    },

    '&:hover': {
      backgroundColor: COLORS.hover,

      '.title': {
        color: COLORS.blue,
        textDecoration: 'underline',
      },
    },

    '&.is-open': {
      backgroundColor: tint(0.92, COLORS.blue),
      boxShadow: `inset 3px 0 0 ${tint(0.2, COLORS.blue)}`,
    },
  },

  graphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  content: {
    display: 'grid',
  },

  title: {
    margin: 0,
    fontSize: '1rem',
    display: 'block',
    // color: COLORS.text,
    // width: 'fit-content',
  },

  description: {
    margin: 0,
    fontSize: '0.9rem',
    color: COLORS.textMuted,
  },

  pinButton: {
    width: '1.5rem',
    height: '1.5rem',

    img: {
      width: '1rem',
      height: '1rem',
      transform: 'rotate3d(1, 1, 1, 0)',
      transition: 'transform 200ms ease',
    },

    '&.is-pinned': {
      backgroundColor: `${tint(0.85, COLORS.violet)} !important`,
      boxShadow: `0 0 0 1px ${tint(0.2, COLORS.violet)}, 0 0 0 4px ${tint(0.6, COLORS.violet)}`,

      img: {
        transform: 'rotate3d(0, 0, 1, -45deg)',
      },
    },
  },

  infoButton: {
    width: '1.5rem',
    height: '1.5rem',

    img: {
      width: '1rem',
      height: '1rem',
    },

    '&.is-open': {
      backgroundColor: `${tint(0.85, COLORS.blue)} !important`,
      boxShadow: `0 0 0 1px ${tint(0.2, COLORS.blue)}, 0 0 0 4px ${tint(0.6, COLORS.blue)}`,
    },
  },

  quickLinksWrapper: {
    paddingTop: 4,
    paddingBottom: 24,
    paddingLeft: 12,
    paddingRight: 12,
    display: 'grid',
    gridTemplateColumns: '1fr',
    boxShadow: `0 1px 0 ${COLORS.divider}`,
    backgroundColor: 'rgba(250, 250, 252, 0.96)',
    gridColumnGap: '0.75rem',

    '@media (min-width: 600px)': {
      paddingLeft: 50,
      gridTemplateColumns: '1fr 1fr',
    },
  },

  quickLink: {
    margin: 0,
    minHeight: 32,
    borderRadius: 0,
    boxShadow: `0 1px 0 ${COLORS.divider}`,

    '&:hover': {
      color: COLORS.blue,
    },
  },
}
