import React from 'react'
import { useRouteMatch, useLocation, useParams } from 'react-router-dom'

import { initials } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import HelpTagIframe from '@behavehealth/components/Help/HelpTagIframe'

import { ClinicalNotesGroupDataTable } from '@behavehealth/constructs/ClinicalNotes/ClinicalNotesGroupDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

type Props = {
  canCreate: boolean
}

const GroupClinicalNotes: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const location = useLocation()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const tableProps = useDataTable({
    name: ['client', resource_id, 'clinical-notes'],
    endpoint: `/residents/${resource_id}/clinical_notes`,
    updateDeleteEndpoint: `/clinical_notes`,
    params: { category: 'clinical_group' },
    localStorageKey: 'client_clinical_notes_group_v2',
  })

  const { deleteRecords, batchUpdateRecords } = tableProps

  const to = React.useMemo(
    () => (id: string, category: string) => ({
      pathname: `${match.url}/${initials(category)}/${id}`,
      parent: match,
    }),
    [match.url],
  )

  return (
    <Grid gap="1rem" columns="100%">
      <ClinicalNotesGroupDataTable
        {...tableProps}
        title="Group Notes"
        to={to}
        hiddenColumnIds={['client']}
        help={<HelpTagIframe id="clinical_notes_group" />}
        getCanSelect={(data: any) =>
          data?.status === 'signed_off' || data?.status === 'closed'
            ? { canSelect: false, message: 'Signed Off / Closed records cannot be edited' }
            : { canSelect: true }
        }
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/clinical-notes',
          },
        ]}
        batchActionsConfig={[
          // {
          //   type: 'delete',
          //   permission: 'clinical_notes.delete',
          //   action: async ({ ids }: any) => {
          //     await deleteRecords(ids.join(','))
          //   },
          // },
          {
            type: 'employee_sign_off',
            permission: 'clinical_notes.edit',
            action: async ({ ids, data: signature }: any) => {
              await batchUpdateRecords(ids, { [signature.model]: signature.value })
            },
          },
        ]}
        emptyActions={
          canCreate && (
            <Button
              label="Add Group Notes"
              glyph="stack"
              type="primary"
              link={{
                pathname: `${location.pathname}/cg/new`,
                parent: { url: location.pathname },
                data: { local_clinical_notes: [{ client, client_id: client?.id }] },
              }}
              permission="clinical_notes.create"
            />
          )
        }
      />
    </Grid>
  )
}

export default GroupClinicalNotes
