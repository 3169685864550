import React from 'react'
import { NavLink, Route, Routes, useParams, Navigate, useMatch } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { singular, titleCase } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Status from '@behavehealth/components/Status'
import Tabs from '@behavehealth/components/Tabs'

import { AuthorityFormBuilder } from '@behavehealth/constructs/Authority/AuthorityFormBuilder'
import { AuthorityFormsImportOverlay } from '@behavehealth/constructs/Authority/AuthorityFormsImportOverlay'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { FILTERS } from '@behavehealth/constructs/Filters/config'

const RootAuthorityForms = () => {
  const { url } = useRouteURL()
  const { isBehave } = useSettings()

  return (
    <>
      <AuthorityFormsIndex />

      <AnimatedRoutes>
        <Route
          path=":category/import"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <AuthorityFormsImportOverlay useV6Router back={url} />
            </ProtectedRouteV6>
          }
        />
        <Route path=":category/:id" element={<AuthorityFormBuilder useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const AuthorityFormsIndex = () => {
  const match = useMatch('/settings/authority-forms/:category')
  const { isBehave } = useSettings()

  return (
    <Page
      title="Authority Forms"
      icon="authority_forms"
      actions={
        isBehave && (
          <>
            <Button
              as={NavLink}
              label="Import Authority Forms"
              glyph="add"
              type="default"
              link={`${match?.params?.category}/import`}
              permission="settings.authority_forms.create"
            />
          </>
        )
      }
    >
      <Grid>
        <Tabs>
          <Tabs.List className="-mt-4 mb-4">
            <Tabs.Item as={NavLink} label="Certifications" to="certification" />
            <Tabs.Item as={NavLink} label="Site Review" to="inspection" />
            <Tabs.Item as={NavLink} label="Complaints" to="complaint" />
            <Tabs.Item as={NavLink} label="Issues" to="issue" />
            <Tabs.Item as={NavLink} label="Studies" to="study" />
            <Tabs.Item as={NavLink} label="Surveys" to="survey" />
          </Tabs.List>
        </Tabs>

        <Routes>
          <Route index element={<Navigate replace to="certification" />} />
          <Route path=":category/*" element={<TableWrapper key={`tab-${match?.params.category}`} />} />
        </Routes>
      </Grid>
    </Page>
  )
}

const ENDPOINTS = {
  certification: 'authority_forms',
  inspection: 'authority_forms',
  complaint: 'authority_complaints',
  issue: 'authority_complaints',
  study: 'authority_studies',
  survey: 'authority_studies',
}

const TableWrapper = () => {
  const { category }: any = useParams()

  const tableProps = useDataTable({
    name: [ENDPOINTS?.[category]],
    endpoint: `/${ENDPOINTS?.[category]}`,
    params: { category },
    localStorageKey: `${category}_authority_forms_v1`,
    keepPreviousData: false,
  })

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        id: 'name',
        model: 'name',
        title: 'Name',
        formatValue: ({ data, value }: any) => <MainCell id={data.id} as={NavLink} to={data.id} value={value} />,
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        formatValue: () => <Status label="Active" color="green" />,
      },
      {
        width: 200,
        model: 'category',
        title: 'Category',
        type: 'title',
      },
      {
        width: 200,
        model: 'subcategory',
        title: 'Subcategory',
        type: 'title',
      },
      {
        width: 200,
        model: 'form_type',
        title: 'Form Type',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return titleCase(value).toUpperCase()
        },
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable
      key={`table-${category}`}
      asCard
      {...tableProps}
      columns={columns}
      icon="authority_forms"
      title={
        category === 'all' ? `Authority Forms` : category === 'inspection' ? 'Site Review Forms' : `${singular(titleCase(category))} Forms`
      }
      filtersConfig={FILTERS.authority_forms}
      batchActionsConfig={[
        {
          type: 'delete',
          action: async ({ ids }: any) => {
            await tableProps.deleteRecords(ids.join(','))
          },
        },
      ]}
    />
  )
}

export const AuthorityForms = withPageError(RootAuthorityForms)
