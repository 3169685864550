import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Status from '../../components/Status'

import { STATUSES } from './components/TreatmentPlanReviews'

export const TreatmentPlanReviewsDataTable = (props: any) => {
  const { to, referenceLinkTo } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 200,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} id={data.id} value={value} to={to ? to(data) : undefined} />,
      },
      {
        title: 'Score',
        model: 'score',
        formatValue: ({ value }: any) => {
          const status = STATUSES?.[value]

          if (!status) return null

          return <Status label={status?.label?.replace?.('Score: ', '')} color={status.color} />
        },
      },
      {
        title: 'Added To',
        model: 'reference',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return (
            <LinkCell
              as={Link}
              value={
                <div className="flex flex-nowrap items-center justify-between truncate">
                  <div className="truncate">{value.name}</div>
                  {value?.type && <Status small label={value.type.replace('treatment_', '')} color="gray" className="!text-[0.65rem]" />}
                </div>
              }
              to={referenceLinkTo ? referenceLinkTo(value) : undefined}
            />
          )
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ],
    [to, referenceLinkTo],
  )

  return (
    <DataTable testKey="treatment_plan_reviews_table" title="Treatment Plan Reviews" icon="treatment_plans" columns={columns} {...props} />
  )
}
