import COLORS from './colors'

import ic_behave_health from '../../assets/icons/ic_behave_health.svg'
import ic_lists from '../../assets/icons/ic_lists.svg'
import ic_checklist from '../../assets/icons/ic_checklist.svg'
import ic_leads from '../../assets/icons/ic_leads.svg'
import ic_authorizations from '../../assets/icons/ic_authorizations.svg'
import ic_bridge from '../../assets/icons/ic_bridge.svg'
import ic_bed_management from '../../assets/icons/ic_bed_management.svg'
import ic_schedule from '../../assets/icons/ic_schedule.svg'
import care_level_1 from '../../assets/icons/care_level_1.svg'
import care_level_2 from '../../assets/icons/care_level_2.svg'
import care_level_3 from '../../assets/icons/care_level_3.svg'
import care_level_4 from '../../assets/icons/care_level_4.svg'
import care_level_5 from '../../assets/icons/care_level_5.svg'
import care_level_6 from '../../assets/icons/care_level_6.svg'
import ic_compass from '../../assets/icons/ic_compass.svg'
import ic_client_tracks from '../../assets/icons/ic_client_tracks.svg'
import ic_chat from '../../assets/icons/ic_chat.svg'
import ic_clients_chat from '../../assets/icons/ic_clients_chat.svg'
import ic_company_chat from '../../assets/icons/ic_company_chat.svg'
import ic_access from '../../assets/icons/ic_access.svg'
import ic_add_patient from '../../assets/icons/ic_add_patient.svg'
import ic_admissions_department from '../../assets/icons/ic_admissions_department.svg'
import ic_alumni_relations from '../../assets/icons/ic_alumni_relations.svg'
import ic_billing from '../../assets/icons/ic_billing.svg'
import ic_breathalyzer from '../../assets/icons/ic_breathalyzer.svg'
import ic_certificate from '../../assets/icons/ic_certificate.svg'
import ic_clients from '../../assets/icons/ic_clients.svg'
import ic_clinical_assessments from '../../assets/icons/ic_clinical_assessments.svg'
import ic_clinical_department from '../../assets/icons/ic_clinical_department.svg'
import ic_clinical_notes from '../../assets/icons/ic_clinical_notes.svg'
import ic_patient_progress_measure from '../../assets/icons/ic_patient_progress_measure.svg'
import ic_communication_logs from '../../assets/icons/ic_communication_logs.svg'
import ic_community from '../../assets/icons/ic_community.svg'
import ic_company_profile from '../../assets/icons/ic_company_profile.svg'
import ic_connections from '../../assets/icons/ic_connections.svg'
import ic_contacts from '../../assets/icons/ic_contacts.svg'
import ic_dashboard from '../../assets/icons/ic_dashboard.svg'
import ic_diagnosis from '../../assets/icons/ic_diagnosis.svg'
import ic_discharge_forms from '../../assets/icons/ic_discharge_forms.svg'
import ic_drug_test from '../../assets/icons/ic_drug_test.svg'
import ic_electronic_prescription from '../../assets/icons/ic_electronic_prescription.svg'
import ic_employees from '../../assets/icons/ic_employees.svg'
import ic_enterprise from '../../assets/icons/ic_enterprise.svg'
import ic_files from '../../assets/icons/ic_files.svg'
import ic_financials from '../../assets/icons/ic_financials.svg'
import ic_general_info from '../../assets/icons/ic_general_info.svg'
import ic_gps_check_in_out from '../../assets/icons/ic_gps_check_in_out.svg'
import ic_housing_department from '../../assets/icons/ic_housing_department.svg'
import ic_housing_notes from '../../assets/icons/ic_housing_notes.svg'
import ic_housing_shifts from '../../assets/icons/ic_housing_shifts.svg'
import ic_insurance from '../../assets/icons/ic_insurance.svg'
import ic_intake_forms from '../../assets/icons/ic_intake_forms.svg'
import ic_invoices from '../../assets/icons/ic_invoices.svg'
import ic_invoices_blue from '../../assets/icons/ic_invoices_blue.svg'
import ic_keyboard from '../../assets/icons/ic_keyboard.svg'
import ic_legal_agreement_alt from '../../assets/icons/ic_legal_agreement_alt.svg'
import ic_legal_agreement_circle from '../../assets/icons/ic_legal_agreement_circle.svg'
import ic_legal_agreement from '../../assets/icons/ic_legal_agreement.svg'
import ic_licence from '../../assets/icons/ic_licence.svg'
import ic_log_out from '../../assets/icons/ic_log_out.svg'
import ic_management_department from '../../assets/icons/ic_management_department.svg'
import ic_med_pass from '../../assets/icons/ic_med_pass.svg'
import ic_medical_assessments from '../../assets/icons/ic_medical_assessments.svg'
import ic_medical_department from '../../assets/icons/ic_medical_department.svg'
import ic_medication_inventory from '../../assets/icons/ic_medication_inventory.svg'
import ic_medication from '../../assets/icons/ic_medication.svg'
import ic_mobile from '../../assets/icons/ic_mobile.svg'
import ic_nursing from '../../assets/icons/ic_nursing.svg'
import ic_offices from '../../assets/icons/ic_offices.svg'
import ic_online_payment_alt from '../../assets/icons/ic_online_payment_alt.svg'
import ic_online_payment_circle from '../../assets/icons/ic_online_payment_circle.svg'
import ic_online_payment from '../../assets/icons/ic_online_payment.svg'
import ic_operations_department from '../../assets/icons/ic_operations_department.svg'
import ic_optimization from '../../assets/icons/ic_optimization.svg'
import ic_organizations_providers from '../../assets/icons/ic_organizations_providers.svg'
import ic_organizations_resident_resources from '../../assets/icons/ic_organizations_resident_resources.svg'
import ic_organizations_vendors from '../../assets/icons/ic_organizations_vendors.svg'
import ic_organizations from '../../assets/icons/ic_organizations.svg'
import ic_phone from '../../assets/icons/ic_phone.svg'
import ic_phone_call from '../../assets/icons/ic_phone_call.svg'
import ic_patient_outcome from '../../assets/icons/ic_patient_outcome.svg'
import ic_patient from '../../assets/icons/ic_patient.svg'
import ic_permissions from '../../assets/icons/ic_permissions.svg'
import ic_physician_orders from '../../assets/icons/ic_physician_orders.svg'
import ic_profile from '../../assets/icons/ic_profile.svg'
import ic_progress_reviews from '../../assets/icons/ic_progress_reviews.svg'
import ic_properties from '../../assets/icons/ic_properties.svg'
import ic_recovery_coaching from '../../assets/icons/ic_recovery_coaching.svg'
import ic_recovery_navigation from '../../assets/icons/ic_recovery_navigation.svg'
import ic_rooms from '../../assets/icons/ic_rooms.svg'
import ic_roster from '../../assets/icons/ic_roster.svg'
import ic_settings from '../../assets/icons/ic_settings.svg'
import ic_search from '../../assets/icons/ic_search.svg'
import ic_sober_living_app from '../../assets/icons/ic_sober_living_app.svg'
import ic_startup from '../../assets/icons/ic_startup.svg'
import ic_tasks from '../../assets/icons/ic_tasks.svg'
import ic_test_results from '../../assets/icons/ic_test_results.svg'
import ic_timeline from '../../assets/icons/ic_timeline.svg'
import ic_transportation from '../../assets/icons/ic_transportation.svg'
import ic_treatment_data from '../../assets/icons/ic_treatment_data.svg'
import ic_treatment_insight from '../../assets/icons/ic_treatment_insight.svg'
import ic_treatment_plans from '../../assets/icons/ic_treatment_plans.svg'
import ic_vehicles from '../../assets/icons/ic_vehicles.svg'
import ic_vital_signs from '../../assets/icons/ic_vital_signs.svg'
import ic_web_form from '../../assets/icons/ic_web_form.svg'
import ic_amex from '../../assets/icons/ic_amex.svg'
import ic_discover from '../../assets/icons/ic_discover.svg'
import ic_mastercard from '../../assets/icons/ic_mastercard.svg'
import ic_visa from '../../assets/icons/ic_visa.svg'
import ic_video_call from '../../assets/icons/ic_video_call.svg'
import ic_verified from '../../assets/icons/ic_verified.svg'
import ic_help_center from '../../assets/icons/ic_help_center.svg'
import ic_bookmarks from '../../assets/icons/ic_bookmarks.svg'
import ic_pins from '../../assets/icons/ic_pins.svg'
import ic_notes from '../../assets/icons/ic_notes.svg'
import ic_tags from '../../assets/icons/ic_tags.svg'
import ic_quick_text from '../../assets/icons/ic_quick_text.svg'
import ic_templates from '../../assets/icons/ic_templates.svg'
import ic_timeline_view from '../../assets/icons/ic_timeline_view.svg'
import ic_bed_management_board from '../../assets/icons/ic_bed_management_board.svg'
import ic_bed_management_calendar from '../../assets/icons/ic_bed_management_calendar.svg'
import ic_bed_management_locations from '../../assets/icons/ic_bed_management_locations.svg'
import ic_bed_management_timeline from '../../assets/icons/ic_bed_management_timeline.svg'
import ic_floors from '../../assets/icons/ic_floors.svg'
import ic_clients_journey from '../../assets/icons/ic_clients_journey.svg'
import ic_programs_table from '../../assets/icons/ic_programs_table.svg'
import ic_programs_timeline from '../../assets/icons/ic_programs_timeline.svg'
import ic_floor_plan from '../../assets/icons/ic_floor_plan.svg'
import ic_program_plan from '../../assets/icons/ic_program_plan.svg'
import ic_program_lists from '../../assets/icons/ic_program_lists.svg'
import ic_outreach from '../../assets/icons/ic_outreach.svg'
import ic_star from '../../assets/icons/ic_star.svg'
import ic_community_partners from '../../assets/icons/ic_community_partners.svg'
import ic_community_profile from '../../assets/icons/ic_community_profile.svg'
import ic_community_all from '../../assets/icons/ic_community_all.svg'
import ic_community_invites from '../../assets/icons/ic_community_invites.svg'
import ic_community_outreach from '../../assets/icons/ic_community_outreach.svg'
import ic_global_pages from '../../assets/icons/ic_global_pages.svg'
import ic_myaccount_pages from '../../assets/icons/ic_myaccount_pages.svg'
import ic_company_pages from '../../assets/icons/ic_company_pages.svg'
import ic_blank_pages from '../../assets/icons/ic_blank_pages.svg'
import ic_smart_flags from '../../assets/icons/ic_smart_flags.svg'
import ic_ehr_pages from '../../assets/icons/ic_ehr_pages.svg'
import ic_community_pages from '../../assets/icons/ic_community_pages.svg'
import ic_hq_pages from '../../assets/icons/ic_hq_pages.svg'
import ic_ai_assistant from '../../assets/icons/ic_ai_assistant.svg'
import ic_ai_prompts from '../../assets/icons/ic_ai_prompts.svg'
import ic_ai_assistant_template from '../../assets/icons/ic_ai_assistant_template.svg'
import ic_app_messages from '../../assets/icons/ic_app_messages.svg'
import ic_login_messages from '../../assets/icons/ic_login_messages.svg'
import ic_microphone from '../../assets/icons/ic_microphone.svg'
import ic_microphone_recording from '../../assets/icons/ic_microphone_recording.svg'
import ic_behave_ai from '../../assets/icons/ic_behave_ai.svg'
import ic_accounting from '../../assets/icons/ic_accounting.svg'
import ic_personal_inventory from '../../assets/icons/ic_personal_inventory.svg'
import ic_personal_journal from '../../assets/icons/ic_personal_journal.svg'
import ic_gratitude_journal from '../../assets/icons/ic_gratitude_journal.svg'
import ic_passcode from '../../assets/icons/ic_passcode.svg'
import ic_magic_link from '../../assets/icons/ic_magic_link.svg'
import ic_non_healthcare_services from '../../assets/icons/ic_non_healthcare_services.svg'
import ic_healthcare_services from '../../assets/icons/ic_healthcare_services.svg'
import ic_gallery from '../../assets/icons/ic_gallery.svg'
import ic_assets from '../../assets/icons/ic_assets.svg'
import ic_client_workflows from '../../assets/icons/ic_client_workflows.svg'
import ic_admin_contacts from '../../assets/icons/ic_admin_contacts.svg'
import ic_hubspot from '../../assets/icons/ic_hubspot.svg'
import ic_google from '../../assets/icons/ic_google.svg'
import ic_helpscout from '../../assets/icons/ic_helpscout.svg'
import ic_gmail from '../../assets/icons/ic_gmail.svg'
import ic_outlook from '../../assets/icons/ic_outlook.svg'
import ic_google_calendar from '../../assets/icons/ic_google_calendar.svg'
import ic_postmark from '../../assets/icons/ic_postmark.svg'
import ic_addendums from '../../assets/icons/ic_addendums.svg'
import ic_signature from '../../assets/icons/ic_signature.svg'
import ic_variables from '../../assets/icons/ic_variables.svg'
import ic_bank_building from '../../assets/icons/ic_bank_building.svg'
import ic_binary_file from '../../assets/icons/ic_binary_file.svg'
import ic_call_transfer from '../../assets/icons/ic_call_transfer.svg'
import ic_cashbook from '../../assets/icons/ic_cashbook.svg'
import ic_cashbook_green from '../../assets/icons/ic_cashbook_green.svg'
import ic_day_view from '../../assets/icons/ic_day_view.svg'
import ic_deposit from '../../assets/icons/ic_deposit.svg'
import ic_exchange from '../../assets/icons/ic_exchange.svg'
import ic_id_verified from '../../assets/icons/ic_id_verified.svg'
import ic_lightning_bolt from '../../assets/icons/ic_lightning_bolt.svg'
import ic_online_card_payment from '../../assets/icons/ic_online_card_payment.svg'
import ic_outbox from '../../assets/icons/ic_outbox.svg'
import ic_safe_in from '../../assets/icons/ic_safe_in.svg'
import ic_sell from '../../assets/icons/ic_sell.svg'
import ic_short_links from '../../assets/icons/ic_short_links.svg'
import ic_grievance_notes from '../../assets/icons/ic_grievance_notes.svg'
import ic_company_integrations from '../../assets/icons/ic_company_integrations.svg'
import ic_assessments_builder from '../../assets/icons/ic_assessments_builder.svg'
import ic_measure_settings from '../../assets/icons/ic_measure_settings.svg'
import ic_embeds from '../../assets/icons/ic_embeds.svg'
import ic_organization_tracks from '../../assets/icons/ic_organization_tracks.svg'
import ic_organization_workflows from '../../assets/icons/ic_organization_workflows.svg'
import ic_authority_forms from '../../assets/icons/ic_authority_forms.svg'
import ic_organization_lead_forms from '../../assets/icons/ic_organization_lead_forms.svg'
import ic_certifications_inspections from '../../assets/icons/ic_certifications_inspections.svg'
import ic_grievance_incident_forms from '../../assets/icons/ic_grievance_incident_forms.svg'
import ic_studies_surveys from '../../assets/icons/ic_studies_surveys.svg'
import ic_directories from '../../assets/icons/ic_directories.svg'
import ic_testimonials from '../../assets/icons/ic_testimonials.svg'
import ic_reported_conditions from '../../assets/icons/ic_reported_conditions.svg'
import ic_narr from '../../assets/icons/ic_narr.svg'
import ic_roi from '../../assets/icons/ic_roi.svg'

export const getIcon = (icon) => {
  if (icon in ICONS) return ICONS[icon]
  return ICONS.default
}

const ICONS = {
  default: {
    id: 'default',
    file: ic_behave_health,
    color: COLORS.violet,
    alt: 'Behave Health',
  },
  access_lists: {
    id: 'access_lists',
    file: ic_lists,
    color: COLORS.blue,
    alt: 'Access Lists',
  },
  applications: {
    id: 'applications',
    file: ic_checklist,
    color: COLORS.green,
    alt: 'Applications',
  },
  applicants: {
    id: 'applicants',
    file: ic_leads,
    color: COLORS.green,
    alt: 'Applicants',
  },
  authorizations: {
    id: 'authorizations',
    file: ic_authorizations,
    color: COLORS.green,
    alt: 'Authorizations',
  },
  bridge: {
    id: 'bridge',
    file: ic_bridge,
    color: COLORS.blue,
    alt: 'Bridge',
  },
  beds: {
    id: 'beds',
    file: ic_bed_management,
    color: COLORS.paleBlue,
    alt: 'Beds',
  },
  bed_management: {
    id: 'bed_management',
    file: ic_bed_management,
    color: COLORS.paleBlue,
    alt: 'Bed Management',
  },
  calendar: {
    id: 'calendar',
    file: ic_schedule,
    color: COLORS.red,
    alt: 'Calendar',
  },
  care_level_1: {
    id: 'care_level_1',
    file: care_level_1,
    color: COLORS.blue,
    alt: 'Care Level 1',
  },
  care_level_2: {
    id: 'care_level_2',
    file: care_level_2,
    color: COLORS.vividBlue,
    alt: 'Care Level 2',
  },
  care_level_3: {
    id: 'care_level_3',
    file: care_level_3,
    color: COLORS.green,
    alt: 'Care Level 3',
  },
  care_level_4: {
    id: 'care_level_4',
    file: care_level_4,
    color: COLORS.orange,
    alt: 'Care Level 4',
  },
  care_level_5: {
    id: 'care_level_5',
    file: care_level_5,
    color: COLORS.orange,
    alt: 'Care Level 5',
  },
  care_level_6: {
    id: 'care_level_6',
    file: care_level_6,
    color: COLORS.red,
    alt: 'Care Level 6',
  },
  chat: {
    id: 'chat',
    file: ic_chat,
    color: COLORS.blue,
    alt: 'Chat',
  },
  clients_chat: {
    id: 'clients_chat',
    file: ic_clients_chat,
    color: COLORS.vividBlue,
    alt: 'Clients Chat',
  },
  company_chat: {
    id: 'company_chat',
    file: ic_company_chat,
    color: COLORS.paleBlue,
    alt: 'Staff Chat',
  },
  access: {
    id: 'access',
    file: ic_access,
    color: COLORS.violet,
    alt: 'Access',
  },
  add_patient: {
    id: 'add_patient',
    file: ic_add_patient,
    color: COLORS.purple,
    alt: 'Add Patient',
  },
  admissions: {
    id: 'admissions',
    file: ic_admissions_department,
    color: COLORS.green,
    alt: 'Admissions',
  },
  admissions_department: {
    id: 'admissions_department',
    file: ic_admissions_department,
    color: COLORS.green,
    alt: 'Admissions Department',
  },
  alumni_relations: {
    id: 'alumni_relations',
    file: ic_alumni_relations,
    color: COLORS.yellow,
    alt: 'Bed Management',
  },
  behave_health: {
    id: 'behave_health',
    file: ic_behave_health,
    color: COLORS.violet,
    alt: 'Behave Health',
  },
  billing: {
    id: 'billing',
    file: ic_billing,
    color: COLORS.orange,
    alt: 'Billing',
  },
  breathalyzer: {
    id: 'breathalyzer',
    file: ic_breathalyzer,
    color: COLORS.green,
    alt: 'Breathalyzer',
  },
  certificate: {
    id: 'certificate',
    file: ic_certificate,
    color: COLORS.blue,
    alt: 'Certificate',
  },
  checklist: {
    id: 'checklist',
    file: ic_checklist,
    color: COLORS.green,
    alt: 'Checklist',
  },
  clients: {
    id: 'clients',
    file: ic_clients,
    color: COLORS.vividBlue,
    alt: 'Clients',
  },
  clinical_assessments: {
    id: 'clinical_assessments',
    file: ic_clinical_assessments,
    color: COLORS.vividBlue,
    alt: 'Clinical Assessments',
  },
  clinical_department: {
    id: 'clinical_department',
    file: ic_clinical_department,
    color: COLORS.vividBlue,
    alt: 'Clinical Department',
  },
  clinical_notes: {
    id: 'clinical_notes',
    file: ic_clinical_notes,
    color: COLORS.red,
    alt: 'Clinical Notes',
  },
  peer_notes: {
    id: 'peer_notes',
    file: ic_personal_journal,
    color: COLORS.vividBlue,
    alt: 'Peer Notes',
  },
  daily_notes: {
    id: 'daily_notes',
    file: ic_personal_journal,
    color: COLORS.vividBlue,
    alt: 'Daily Notes',
  },
  clinical_measurements: {
    id: 'clinical_measurements',
    file: ic_patient_progress_measure,
    color: COLORS.red,
    alt: 'Clinical Measures',
  },
  communications: {
    id: 'communications',
    file: ic_communication_logs,
    color: COLORS.orange,
    alt: 'Communication Logs',
  },
  community: {
    id: 'community',
    file: ic_community,
    color: COLORS.vividBlue,
    alt: 'Community',
  },
  company_profile: {
    id: 'company_profile',
    file: ic_company_profile,
    color: COLORS.violet,
    alt: 'Company Profile',
  },
  connections: {
    id: 'connections',
    file: ic_connections,
    color: COLORS.orange,
    alt: 'Connections',
  },
  contacts: {
    id: 'contacts',
    file: ic_contacts,
    color: COLORS.orange,
    alt: 'Contacts',
  },
  dashboard: {
    id: 'dashboard',
    file: ic_dashboard,
    color: COLORS.vividBlue,
    alt: 'Dashboard',
  },
  diagnosis: {
    id: 'diagnosis',
    file: ic_diagnosis,
    color: COLORS.vividBlue,
    alt: 'Diagnosis',
  },
  discharge_forms: {
    id: 'discharge_forms',
    file: ic_discharge_forms,
    color: COLORS.orange,
    alt: 'Discharge Forms',
  },
  discharges_builder: {
    id: 'discharges_builder',
    file: ic_discharge_forms,
    color: COLORS.orange,
    alt: 'Discharges Builder',
  },
  drug_test: {
    id: 'drug_test',
    file: ic_drug_test,
    color: COLORS.yellow,
    alt: 'Drug Test',
  },
  electronic_prescription: {
    id: 'electronic_prescription',
    file: ic_electronic_prescription,
    color: COLORS.red,
    alt: 'Electronic Prescription',
  },
  employees: {
    id: 'employees',
    file: ic_employees,
    color: COLORS.paleBlue,
    alt: 'Staff',
  },
  enterprise: {
    id: 'enterprise',
    file: ic_enterprise,
    color: COLORS.blue,
    alt: 'Enterprise',
  },
  files: {
    id: 'files',
    file: ic_files,
    color: COLORS.vividBlue,
    alt: 'Files',
  },
  financials: {
    id: 'financials',
    file: ic_financials,
    color: COLORS.green,
    alt: 'Financials',
  },
  general_info: {
    id: 'general_info',
    file: ic_general_info,
    color: COLORS.blue,
    alt: 'General Info',
  },
  gps_check_in_out: {
    id: 'gps_check_in_out',
    file: ic_gps_check_in_out,
    color: COLORS.red,
    alt: 'GPS Check In Out',
  },
  housing_department: {
    id: 'housing_department',
    file: ic_housing_department,
    color: COLORS.purple,
    alt: 'Housing Department',
  },
  housing_notes: {
    id: 'housing_notes',
    file: ic_housing_notes,
    color: COLORS.paleBlue,
    alt: 'Shift Notes',
  },
  housing_shifts: {
    id: 'housing_shifts',
    file: ic_housing_shifts,
    color: COLORS.paleBlue,
    alt: 'Housing Shifts',
  },
  insurance: {
    id: 'insurance',
    file: ic_insurance,
    color: COLORS.green,
    alt: 'Insurance',
  },
  intake_forms: {
    id: 'intake_forms',
    file: ic_intake_forms,
    color: COLORS.green,
    alt: 'Intake Forms',
  },
  invoices: {
    id: 'invoices',
    file: ic_invoices,
    color: COLORS.green,
    alt: 'Invoices',
  },
  keyboard: {
    id: 'keyboard',
    file: ic_keyboard,
    color: COLORS.green,
    alt: 'Keyboard',
  },
  legal_agreement_alt: {
    id: 'legal_agreement_alt',
    file: ic_legal_agreement_alt,
    color: COLORS.orange,
    alt: 'Legal Agreement Alt',
  },
  legal_agreement_circle: {
    id: 'legal_agreement_circle',
    file: ic_legal_agreement_circle,
    color: COLORS.purple,
    alt: 'Legal Agreement Circle',
  },
  legal_agreement: {
    id: 'legal_agreement',
    file: ic_legal_agreement,
    color: COLORS.orange,
    alt: 'Legal Agreement',
  },
  licence: {
    id: 'licence',
    file: ic_licence,
    color: COLORS.paleBlue,
    alt: 'Licence',
  },
  lists: {
    id: 'lists',
    file: ic_lists,
    color: COLORS.blue,
    alt: 'Lists',
  },
  log_out: {
    id: 'log_out',
    file: ic_log_out,
    color: COLORS.red,
    alt: 'Log Out',
  },
  management_department: {
    id: 'management_department',
    file: ic_management_department,
    color: COLORS.blue,
    alt: 'Management Department',
  },
  med_pass: {
    id: 'med_pass',
    file: ic_med_pass,
    color: COLORS.red,
    alt: 'Med Pass',
  },
  medical_assessments: {
    id: 'medical_assessments',
    file: ic_medical_assessments,
    color: COLORS.red,
    alt: 'Medical Assessments',
  },
  medical_department: {
    id: 'medical_department',
    file: ic_medical_department,
    color: COLORS.red,
    alt: 'Medical Department',
  },
  medication_inventory: {
    id: 'medication_inventory',
    file: ic_medication_inventory,
    color: COLORS.red,
    alt: 'Medication Inventory',
  },
  medication_incidents: {
    id: 'medication_incidents',
    file: ic_medication_inventory,
    color: COLORS.red,
    alt: 'Medication Incident',
  },
  medication: {
    id: 'medication',
    file: ic_medication,
    color: COLORS.red,
    alt: 'Medication',
  },
  medications: {
    id: 'medications',
    file: ic_medication,
    color: COLORS.red,
    alt: 'Medication',
  },
  mobile: {
    id: 'mobile',
    file: ic_mobile,
    color: COLORS.vividBlue,
    alt: 'Mobile',
  },
  nursing: {
    id: 'nursing',
    file: ic_nursing,
    color: COLORS.red,
    alt: 'Nursing',
  },
  offices: {
    id: 'offices',
    file: ic_offices,
    color: COLORS.yellow,
    alt: 'Offices',
  },
  online_payment_alt: {
    id: 'online_payment_alt',
    file: ic_online_payment_alt,
    color: COLORS.green,
    alt: 'Online Payment Alt',
  },
  online_payment_circle: {
    id: 'online_payment_circle',
    file: ic_online_payment_circle,
    color: COLORS.red,
    alt: 'Online Payment Circle',
  },
  online_payment: {
    id: 'online_payment',
    file: ic_online_payment,
    color: COLORS.green,
    alt: 'Online Payment',
  },
  operations_department: {
    id: 'operations_department',
    file: ic_operations_department,
    color: COLORS.orange,
    alt: 'Operations Department',
  },
  optimization: {
    id: 'optimization',
    file: ic_optimization,
    color: COLORS.violet,
    alt: 'Optimization',
  },
  organizations_providers: {
    id: 'organizations_providers',
    file: ic_organizations_providers,
    color: COLORS.purple,
    alt: 'Organizations Providers',
  },
  organizations_resident_resources: {
    id: 'organizations_resident_resources',
    file: ic_organizations_resident_resources,
    color: COLORS.paleBlue,
    alt: 'Organizations Resident Resources',
  },
  organizations_vendors: {
    id: 'organizations_vendors',
    file: ic_organizations_vendors,
    color: COLORS.blue,
    alt: 'Organizations Vendors',
  },
  organizations: {
    id: 'organizations',
    file: ic_organizations,
    color: COLORS.purple,
    alt: 'Organizations',
  },
  phone: {
    id: 'phone',
    file: ic_phone,
    color: COLORS.green,
    alt: 'Phone',
  },
  phone_call: {
    id: 'phone',
    file: ic_phone_call,
    color: COLORS.green,
    alt: 'Phone',
  },
  patient_outcome: {
    id: 'patient_outcome',
    file: ic_patient_outcome,
    color: COLORS.purple,
    alt: 'Patient Outcome',
  },
  patient_progress_measure: {
    id: 'patient_progress_measure',
    file: ic_patient_progress_measure,
    color: COLORS.blue,
    alt: 'Patient Progress Measure',
  },
  patient: {
    id: 'patient',
    file: ic_patient,
    color: COLORS.violet,
    alt: 'Patient',
  },
  permissions: {
    id: 'permissions',
    file: ic_permissions,
    color: COLORS.violet,
    alt: 'Permissions',
  },
  physician_orders: {
    id: 'physician_orders',
    file: ic_physician_orders,
    color: COLORS.red,
    alt: 'Physician Orders',
  },
  profile: {
    id: 'profile',
    file: ic_profile,
    color: COLORS.violet,
    alt: 'Profile',
  },
  progress_reviews: {
    id: 'progress_reviews',
    file: ic_progress_reviews,
    color: COLORS.violet,
    alt: 'Progress Reviews',
  },
  progress_notes: {
    id: 'progress_notes',
    file: ic_progress_reviews,
    color: COLORS.violet,
    alt: 'Progress Notes',
  },
  properties: {
    id: 'properties',
    file: ic_properties,
    color: COLORS.paleBlue,
    alt: 'Locations',
  },
  recovery_coaching: {
    id: 'recovery_coaching',
    file: ic_recovery_coaching,
    color: COLORS.orange,
    alt: 'Recovery Notes',
  },
  recovery_navigation: {
    id: 'recovery_navigation',
    file: ic_recovery_navigation,
    color: COLORS.blue,
    alt: 'Recovery Navigation',
  },
  residents: {
    id: 'residents',
    file: ic_clients,
    color: COLORS.vividBlue,
    alt: 'Clients',
  },
  rooms: {
    id: 'rooms',
    file: ic_rooms,
    color: COLORS.yellow,
    alt: 'Rooms',
  },
  roster: {
    id: 'roster',
    file: ic_roster,
    color: COLORS.blue,
    alt: 'Roster',
  },
  schedule: {
    id: 'schedule',
    file: ic_schedule,
    color: COLORS.red,
    alt: 'Schedule',
  },
  settings: {
    id: 'settings',
    file: ic_settings,
    color: COLORS.violet,
    alt: 'Settings',
  },
  search: {
    id: 'search',
    file: ic_search,
    color: COLORS.violet,
    alt: 'Search',
  },
  service_episodes: {
    id: 'care_level_1',
    file: care_level_1,
    color: COLORS.blue,
    alt: 'Service Episodes',
  },
  sober_living_app: {
    id: 'sober_living_app',
    file: ic_sober_living_app,
    color: COLORS.violet,
    alt: 'Behave Health App',
  },
  startup: {
    id: 'startup',
    file: ic_startup,
    color: COLORS.purple,
    alt: 'Startup',
  },
  tasks: {
    id: 'tasks',
    file: ic_tasks,
    color: COLORS.green,
    alt: 'Tasks',
  },
  test_results: {
    id: 'test_results',
    file: ic_test_results,
    color: COLORS.blue,
    alt: 'Test Results',
  },
  timeline: {
    id: 'timeline',
    file: ic_timeline,
    color: COLORS.green,
    alt: 'Timeline',
  },
  transportation: {
    id: 'transportation',
    file: ic_transportation,
    color: COLORS.blue,
    alt: 'Transportation',
  },
  allergies: {
    id: 'allergies',
    file: ic_reported_conditions,
    color: COLORS.orange,
    alt: 'Reported Conditions',
  },
  reported_conditions: {
    id: 'reported_conditions',
    file: ic_reported_conditions,
    color: COLORS.orange,
    alt: 'Reported Conditions',
  },
  treatment_data: {
    id: 'treatment_data',
    file: ic_treatment_data,
    color: COLORS.vividBlue,
    alt: 'Treatment Data',
  },
  treatment_episodes: {
    id: 'treatment_episodes',
    file: ic_treatment_data,
    color: COLORS.blue,
    alt: 'Treatment Episodes',
  },
  treatment_insight: {
    id: 'treatment_insight',
    file: ic_treatment_insight,
    color: COLORS.red,
    alt: 'Treatment Insight',
  },
  treatment_plans: {
    id: 'treatment_plans',
    file: ic_treatment_plans,
    color: COLORS.red,
    alt: 'Treatment Plans',
  },
  vehicles: {
    id: 'vehicles',
    file: ic_vehicles,
    color: COLORS.blue,
    alt: 'Vehicles',
  },
  vital_signs: {
    id: 'vital_signs',
    file: ic_vital_signs,
    color: COLORS.red,
    alt: 'Vital Signs',
  },
  web_form: {
    id: 'web_form',
    file: ic_web_form,
    color: COLORS.blue,
    alt: 'Web Form',
  },
  amex: {
    id: 'amex',
    file: ic_amex,
    color: COLORS.blue,
    alt: 'American Express',
  },
  discover: {
    id: 'discover',
    file: ic_discover,
    color: COLORS.blue,
    alt: 'Discover Card',
  },
  mastercard: {
    id: 'mastercard',
    file: ic_mastercard,
    color: COLORS.blue,
    alt: 'MasterCard',
  },
  visa: {
    id: 'visa',
    file: ic_visa,
    color: COLORS.blue,
    alt: 'Visa',
  },
  vob: {
    id: 'vob',
    file: ic_insurance,
    color: COLORS.green,
    alt: 'VOB',
  },
  eligibility: {
    id: 'eligibility',
    file: ic_insurance,
    color: COLORS.green,
    alt: 'Eligibility',
  },
  reports: {
    id: 'reports',
    file: ic_patient_progress_measure,
    color: COLORS.blue,
    alt: 'Reports',
  },
  outcome_measures: {
    id: 'outcome_measures',
    file: ic_patient_outcome,
    color: COLORS.purple,
    alt: 'Outcome Measures',
  },
  referrals: {
    id: 'referrals',
    file: ic_patient_outcome,
    color: COLORS.purple,
    alt: 'Referrals',
  },
  staff_credentials: {
    id: 'staff_credentials',
    file: ic_certificate,
    color: COLORS.blue,
    alt: 'Staff Credentials',
  },
  programs: {
    file: ic_checklist,
    color: COLORS.green,
    alt: 'Programs',
  },
  notifications: {
    id: 'notifications',
    file: care_level_4,
    color: COLORS.orange,
    alt: 'Notifications',
  },
  video_call: {
    id: 'video_call',
    file: ic_video_call,
    color: COLORS.green,
    alt: 'Video Call',
  },
  telehealth: {
    id: 'telehealth',
    file: ic_video_call,
    color: COLORS.green,
    alt: 'Telehealth',
  },
  custom_notes: {
    id: 'custom_notes',
    file: ic_clinical_assessments,
    color: COLORS.blue,
    alt: 'Custom Notes',
  },
  grievance_notes: {
    id: 'Grievance_notes',
    file: ic_grievance_notes,
    color: COLORS.orange,
    alt: 'Grievance Notes',
  },
  rcm_verifications: {
    id: 'rcm_verifications',
    file: ic_verified,
    color: COLORS.green,
    alt: 'Verifications',
  },
  rcm_utilization_review: {
    id: 'rcm_utilization_review',
    file: ic_authorizations,
    color: COLORS.green,
    alt: 'Utilization Review',
  },
  rcm_billing: {
    id: 'rcm_billing',
    file: ic_financials,
    color: COLORS.green,
    alt: 'Billing',
  },
  rcm_claim_status: {
    id: 'rcm_claim_status',
    file: ic_checklist,
    color: COLORS.green,
    alt: 'Claim Status',
  },
  rcm_posting: {
    id: 'rcm_posting',
    file: ic_intake_forms,
    color: COLORS.green,
    alt: 'Posting',
  },
  claims: {
    id: 'claims',
    file: ic_invoices,
    color: COLORS.green,
    alt: 'Claims',
  },
  claim_notes: {
    id: 'claim_notes',
    file: ic_clinical_assessments,
    color: COLORS.green,
    alt: 'Claim Notes',
  },
  help_center: {
    id: 'help_center',
    file: ic_help_center,
    color: COLORS.blue,
    alt: 'Help Center',
  },
  bookmarks: {
    id: 'bookmarks',
    file: ic_bookmarks,
    color: COLORS.blue,
    alt: 'Bookmarks',
  },
  pins: {
    id: 'pins',
    file: ic_pins,
    color: COLORS.violet,
    alt: 'Pins',
  },
  locations: {
    id: 'locations',
    file: ic_gps_check_in_out,
    color: COLORS.red,
    alt: 'locations',
  },
  sticky_notes: {
    id: 'sticky_notes',
    file: ic_notes,
    color: COLORS.gold,
    alt: 'Sticky Notes',
  },
  tags: {
    id: 'tags',
    file: ic_tags,
    color: COLORS.blue,
    alt: 'Tags',
  },
  quick_text: {
    id: 'quick_text',
    file: ic_quick_text,
    color: COLORS.orange,
    alt: 'Quick Text',
  },
  templates: {
    id: 'templates',
    file: ic_templates,
    color: COLORS.yellow,
    alt: 'Templates',
  },
  timeline_view: {
    id: 'timeline_view',
    file: ic_timeline_view,
    color: COLORS.yellow,
    alt: 'Timeline View',
  },
  bed_management_board: {
    id: 'bed_management_board',
    file: ic_bed_management_board,
    color: COLORS.yellow,
    alt: 'Bed Management Board',
  },
  bed_management_calendar: {
    id: 'bed_management_calendar',
    file: ic_bed_management_calendar,
    color: COLORS.yellow,
    alt: 'Bed Management Calendar',
  },
  bed_management_locations: {
    id: 'bed_management_locations',
    file: ic_bed_management_locations,
    color: COLORS.yellow,
    alt: 'Bed Management Locations',
  },
  bed_management_timeline: {
    id: 'bed_management_timeline',
    file: ic_bed_management_timeline,
    color: COLORS.yellow,
    alt: 'Bed Management Timeline',
  },
  floors: {
    id: 'floors',
    file: ic_floors,
    color: COLORS.yellow,
    alt: 'Floors',
  },
  occupancies: {
    id: 'occupancies',
    file: ic_bed_management,
    color: COLORS.paleBlue,
    alt: 'Bed Assignments',
  },
  client_journey: {
    id: 'client_journey',
    file: ic_clients_journey,
    color: COLORS.paleBlue,
    alt: 'Client Timeline',
  },
  clients_journey: {
    id: 'clients_journey',
    file: ic_clients_journey,
    color: COLORS.paleBlue,
    alt: 'Clients Journey',
  },
  programs_table: {
    id: 'programs_table',
    file: ic_programs_table,
    color: COLORS.paleBlue,
    alt: 'Programs Table',
  },
  programs_timeline: {
    id: 'programs_timeline',
    file: ic_programs_timeline,
    color: COLORS.paleBlue,
    alt: 'Programs Timeline',
  },
  floor_plan: {
    id: 'floor_plan',
    file: ic_floor_plan,
    color: COLORS.paleBlue,
    alt: 'Floor Plan',
  },
  program_plan: {
    id: 'program_plan',
    file: ic_program_plan,
    color: COLORS.green,
    alt: 'Program Plan',
  },
  program_lists: {
    id: 'program_lists',
    file: ic_program_lists,
    color: COLORS.green,
    alt: 'Program Lists',
  },
  staff_journey: {
    id: 'staff_journey',
    file: ic_clients_journey,
    color: COLORS.paleBlue,
    alt: 'Staff Journey',
  },
  partners: {
    id: 'partners',
    file: ic_organizations_vendors,
    color: COLORS.paleBlue,
    alt: 'Partners',
  },
  outreach: {
    id: 'outreach',
    file: ic_outreach,
    color: COLORS.orange,
    alt: 'Outreach',
  },
  star: {
    id: 'star',
    file: ic_star,
    color: COLORS.orange,
    alt: 'Star',
  },
  community_partners: {
    id: 'community_partners',
    file: ic_community_partners,
    color: COLORS.green,
    alt: 'Community Partners',
  },
  community_profile: {
    id: 'community_profile',
    file: ic_community_profile,
    color: COLORS.blue,
    alt: 'Community profile',
  },
  community_all: {
    id: 'community_all',
    file: ic_community_all,
    color: COLORS.violet,
    alt: 'Community All',
  },
  community_invites: {
    id: 'community_invites',
    file: ic_community_invites,
    color: COLORS.purple,
    alt: 'Community Invites',
  },
  community_outreach: {
    id: 'community_outreach',
    file: ic_community_outreach,
    color: COLORS.orange,
    alt: 'Community Outreach',
  },
  global_pages: {
    id: 'global_pages',
    file: ic_global_pages,
    color: COLORS.blue,
    alt: 'Global Pages',
  },
  sales_pages: {
    id: 'sales_pages',
    file: ic_global_pages,
    color: COLORS.blue,
    alt: 'Sales Pages',
  },
  myaccount_pages: {
    id: 'myaccount_pages',
    file: ic_myaccount_pages,
    color: COLORS.blue,
    alt: 'Myaccount Pages',
  },
  company_pages: {
    id: 'company_pages',
    file: ic_company_pages,
    color: COLORS.blue,
    alt: 'Company Pages',
  },
  blank_pages: {
    id: 'blank_pages',
    file: ic_blank_pages,
    color: COLORS.blue,
    alt: 'Custom Pages',
  },
  smart_flags: {
    id: 'smart_flags',
    file: ic_smart_flags,
    color: COLORS.blue,
    alt: 'Smart Flags',
  },
  feature_flags: {
    id: 'feature_flags',
    file: ic_smart_flags,
    color: COLORS.blue,
    alt: 'Feature Flags',
  },
  ehr_pages: {
    id: 'ehr_pages',
    file: ic_ehr_pages,
    color: COLORS.blue,
    alt: 'EHR Pages',
  },
  hq_pages: {
    id: 'hq_pages',
    file: ic_hq_pages,
    color: COLORS.blue,
    alt: 'HQ Pages',
  },
  community_pages: {
    id: 'community_pages',
    file: ic_community_pages,
    color: COLORS.blue,
    alt: 'Community Pages',
  },
  community_admin_pages: {
    id: 'community_admin_pages',
    file: ic_community_pages,
    color: COLORS.blue,
    alt: 'Community Admin Pages',
  },
  ai_assistant: {
    id: 'ai_assistant',
    file: ic_ai_assistant,
    color: COLORS.blue,
    alt: 'AI Assistant',
  },
  ic_ai_assistant_template: {
    id: 'ai_assistant_template',
    file: ic_ai_assistant_template,
    color: COLORS.gray,
    alt: 'AI Assistant Template',
  },
  ai_prompts: {
    id: 'ai_prompts',
    file: ic_ai_prompts,
    color: COLORS.blue,
    alt: 'AI Prompts',
  },
  app_messages: {
    id: 'app_messages',
    file: ic_app_messages,
    color: COLORS.vividBlue,
    alt: 'App Messages',
  },
  login_messages: {
    id: 'login_messages',
    file: ic_login_messages,
    color: COLORS.vividBlue,
    alt: 'Login Messages',
  },
  microphone: {
    id: 'microphone',
    file: ic_microphone,
    color: COLORS.blue,
    alt: 'Microphone',
  },
  microphone_recording: {
    id: 'microphone_recording',
    file: ic_microphone_recording,
    color: COLORS.red,
    alt: 'Microphone Recording',
  },
  behave_ai: {
    id: 'behave_ai',
    file: ic_behave_ai,
    color: COLORS.blue,
    alt: 'Behave AI',
  },
  accounting: {
    id: 'financials',
    file: ic_accounting,
    color: COLORS.green,
    alt: 'Accounting',
  },
  personal_inventory: {
    id: 'personal_inventory',
    file: ic_personal_inventory,
    color: COLORS.violet,
    alt: 'Personal Inventory',
  },
  personal_journal: {
    id: 'personal_journal',
    file: ic_personal_journal,
    color: COLORS.vividBlue,
    alt: 'Personal Journal',
  },
  gratitude_journal: {
    id: 'gratitude_journal',
    file: ic_gratitude_journal,
    color: COLORS.orange,
    alt: 'Gratitude Journal',
  },
  passcode: {
    id: 'passcode',
    file: ic_passcode,
    color: COLORS.blue,
    alt: 'Passcode',
  },
  magic_link: {
    id: 'magic_link',
    file: ic_magic_link,
    color: COLORS.blue,
    alt: 'Magic Link',
  },

  // RCM Icons
  bank_building: {
    id: 'bank_building',
    file: ic_bank_building,
    color: COLORS.green,
    alt: 'Bank Building',
  },
  binary_file: {
    id: 'binary_file',
    file: ic_binary_file,
    color: COLORS.blue,
    alt: 'Binary File',
  },
  call_transfer: {
    id: 'call_transfer',
    file: ic_call_transfer,
    color: COLORS.green,
    alt: 'Call Transfer',
  },
  cashbook: {
    id: 'cashbook',
    file: ic_cashbook,
    color: COLORS.violet,
    alt: 'Cashbook',
  },
  cashbook_green: {
    id: 'cashbook_green',
    file: ic_cashbook_green,
    color: COLORS.green,
    alt: 'Cashbook',
  },
  day_view: {
    id: 'day_view',
    file: ic_day_view,
    color: COLORS.vividBlue,
    alt: 'Day View',
  },
  deposit: {
    id: 'deposit',
    file: ic_deposit,
    color: COLORS.yellow,
    alt: 'Deposit',
  },
  exchange: {
    id: 'exchange',
    file: ic_exchange,
    color: COLORS.green,
    alt: 'Exchange',
  },
  id_verified: {
    id: 'id_verified',
    file: ic_id_verified,
    color: COLORS.green,
    alt: 'Id Verified',
  },
  lightning_bolt: {
    id: 'lightning_bolt',
    file: ic_lightning_bolt,
    color: COLORS.orange,
    alt: 'Lightning Bolt',
  },
  online_card_payment: {
    id: 'online_card_payment',
    file: ic_online_card_payment,
    color: COLORS.vividBlue,
    alt: 'Online Card Payment',
  },
  client_statements: {
    id: 'client_statements',
    file: ic_online_card_payment,
    color: COLORS.vividBlue,
    alt: 'Client Statements',
  },
  outbox: {
    id: 'outbox',
    file: ic_outbox,
    color: COLORS.blue,
    alt: 'Outbox',
  },
  safe_in: {
    id: 'safe_in',
    file: ic_safe_in,
    color: COLORS.blue,
    alt: 'Safe In',
  },
  sell: {
    id: 'sell',
    file: ic_sell,
    color: COLORS.vividBlue,
    alt: 'Sell',
  },
  non_healthcare_services: {
    id: 'non_healthcare_services',
    file: ic_non_healthcare_services,
    color: COLORS.red,
    alt: 'Non-Healthcare Services',
  },
  healthcare_services: {
    id: 'healthcare_services',
    file: ic_healthcare_services,
    color: COLORS.red,
    alt: 'Healthcare Services',
  },
  gallery: {
    id: 'gallery',
    file: ic_gallery,
    color: COLORS.gray,
    alt: 'Gallery',
  },
  assets: {
    id: 'assets',
    file: ic_assets,
    color: COLORS.gray,
    alt: 'Assets',
  },
  client_workflows: {
    id: 'client_workflows',
    file: ic_client_workflows,
    color: COLORS.vividBlue,
    alt: 'Client Workflows',
  },
  client_workflows_message_templates: {
    id: 'client_workflows_message_templates',
    file: ic_client_workflows,
    color: COLORS.vividBlue,
    alt: 'Client Workflows Message Templates',
  },
  admin_contacts: {
    id: 'admin_contacts',
    file: ic_admin_contacts,
    color: COLORS.blue,
    alt: 'Contacts',
  },
  hubspot: {
    id: 'hubspot',
    file: ic_hubspot,
    color: COLORS.orange,
    alt: 'Hubspot',
  },
  helpscout: {
    id: 'helpscout',
    file: ic_helpscout,
    color: COLORS.orange,
    alt: 'Helpscout',
  },
  google: {
    id: 'google',
    file: ic_google,
    color: COLORS.blue,
    alt: 'Google',
  },
  message_board: {
    id: 'message_board',
    file: care_level_4,
    color: COLORS.orange,
    alt: 'Messages',
  },
  gmail: {
    id: 'gmail',
    file: ic_gmail,
    color: COLORS.red,
    alt: 'Gmail',
  },
  outlook: {
    id: 'outlook',
    file: ic_outlook,
    color: COLORS.blue,
    alt: 'Outlook',
  },
  google_calendar: {
    id: 'google_calendar',
    file: ic_google_calendar,
    color: COLORS.blue,
    alt: 'Google Calendar',
  },
  postmark: {
    id: 'postmark',
    file: ic_postmark,
    color: COLORS.yellow,
    alt: 'Postmark',
  },
  admin_hr_notes: {
    id: 'admin_hr_notes',
    file: ic_clinical_assessments,
    color: COLORS.blue,
    alt: 'Admin HR Notes',
  },
  short_links: {
    id: 'short_links',
    file: ic_short_links,
    color: COLORS.blue,
    alt: 'Short Links',
  },
  addendums: {
    id: 'addendums',
    file: ic_addendums,
    color: COLORS.blue,
    alt: 'Addendums',
  },
  signature: {
    id: 'signature',
    file: ic_signature,
    color: COLORS.orange,
    alt: 'Signature',
  },
  variables: {
    id: 'variables',
    file: ic_variables,
    color: COLORS.paleBlue,
    alt: 'Variables',
  },
  rounds: {
    id: 'rounds',
    file: ic_addendums,
    color: COLORS.paleBlue,
    alt: 'Rounds',
  },
  safety_checks: {
    id: 'safety_checks',
    file: ic_addendums,
    color: COLORS.paleBlue,
    alt: 'Safety Checks',
  },
  company_integrations: {
    id: 'company_integrations',
    file: ic_company_integrations,
    color: COLORS.paleBlue,
    alt: 'Company Integrations',
  },
  assessments_builder: {
    id: 'assessments_builder',
    file: ic_assessments_builder,
    color: COLORS.vividBlue,
    alt: 'Assessments Builder',
  },
  measure_settings: {
    id: 'measure_settings',
    file: ic_measure_settings,
    color: COLORS.gray,
    alt: 'Measure Settings',
  },
  embeds: {
    id: 'embeds',
    file: ic_embeds,
    color: COLORS.gray,
    alt: 'Measure Settings',
  },
  invoices_blue: {
    id: 'invoices_blue',
    file: ic_invoices_blue,
    color: COLORS.blue,
    alt: 'Invoices',
  },
  quotes: {
    id: 'quotes',
    file: ic_invoices_blue,
    color: COLORS.blue,
    alt: 'Quotes',
  },
  notification_configs: {
    id: 'notification_configs',
    file: ic_operations_department,
    color: COLORS.orange,
    alt: 'Notification Configs',
  },
  notification_logs: {
    id: 'notification_logs',
    file: care_level_4,
    color: COLORS.orange,
    alt: 'Notification Logs',
  },
  client_tracks: {
    id: 'client_tracks',
    file: ic_client_tracks,
    color: COLORS.orange,
    alt: 'Client Tracks',
  },
  organization_tracks: {
    id: 'organization_tracks',
    file: ic_organization_tracks,
    color: COLORS.purple,
    alt: 'Organization Tracks',
  },
  organization_workflows: {
    id: 'organization_workflows',
    file: ic_organization_workflows,
    color: COLORS.purple,
    alt: 'Organization Workflows',
  },
  authority_forms: {
    id: 'authority_forms',
    file: ic_authority_forms,
    color: COLORS.purple,
    alt: 'Authority Forms',
  },
  organization_lead_forms: {
    id: 'organization_lead_forms',
    file: ic_organization_lead_forms,
    color: COLORS.green,
    alt: 'Lead Contact Forms',
  },
  certifications_inspections: {
    id: 'certifications_inspections',
    file: ic_certifications_inspections,
    color: COLORS.blue,
    alt: 'Certifications & Inspections',
  },
  grievance_incident_forms: {
    id: 'grievance_incident_forms',
    file: ic_grievance_incident_forms,
    color: COLORS.orange,
    alt: 'Grievance & Incident Forms',
  },
  studies_surveys: {
    id: 'studies_surveys',
    file: ic_studies_surveys,
    color: COLORS.purple,
    alt: 'Studies & Surveys',
  },
  directories: {
    id: 'directories',
    file: ic_directories,
    color: COLORS.blue,
    alt: 'Directories',
  },
  testimonials: {
    id: 'testimonials',
    file: ic_testimonials,
    color: COLORS.blue,
    alt: 'Testimonials',
  },
  recovery_capital_forms: {
    id: 'recovery_capital_forms',
    file: ic_progress_reviews,
    color: COLORS.blue,
    alt: 'Recovery Capital Forms',
  },
  narr: {
    id: 'narr',
    file: ic_narr,
    color: COLORS.narr,
    alt: 'NARR',
  },
  roi: {
    id: 'roi',
    file: ic_roi,
    color: COLORS.roi,
    alt: 'ROI',
  },
}

export default ICONS
