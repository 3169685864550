import React from 'react'
import { tint, saturate } from 'polished'
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import { COLORS, findFeature } from '../theme'
import { PLANS } from '../utils/constants'

import Flex from './Flex'
import Graphic from './Graphic'
import NavLink from './NavLink'
import Status from './Status'
import PlanTag from './PlanTag'

import withScrollContext from '../hocs/withScrollContext'
import withPermissions from '../hocs/withPermissions'

import { PopoverContext } from './PopoverMenu'

const NavItem = (props: any) => {
  const {
    aside,
    asLink,
    avatar,
    avatarGlyph,
    avatarMagnify,
    center,
    className,
    color,
    testKey,
    description,
    feature,
    glyph,
    glyphColor,
    glyphSize = 20,
    hideLabel,
    icon,
    isActive,
    isBeta,
    isDev,
    isComingSoon,
    isDisabled,
    label,
    onClick,
    avatarInitials,
    showAvatarInitials,
    showEmptyAvatar,
    skipActive,
    to,
    type,
    featureFlagV2,
    showNotifications,
    notifications,
    graphic,
    href,
    size = 300,
    after,
  } = props

  let sIcon = icon
  let sColor = saturate(0.16, tint(0.75, color))
  let showTag = false

  const currentTenant = useSelector((state) => state.me?.tenant)
  const featureFlagsV2 = currentTenant?.feature_flags
  const isTrialing = currentTenant?.plan_status === 'trialing'

  const popoverContext: any = React.useContext(PopoverContext)

  const handleClick = (e) => {
    if (onClick) onClick(e)
    if (popoverContext && popoverContext.closeOnItemClick && popoverContext.setIsOpen) {
      popoverContext.setIsOpen(false)
    }
  }

  if (isTrialing && featureFlagV2 && !get(featureFlagsV2, featureFlagV2)) showTag = true

  if (feature) {
    let foundFeature = findFeature(feature)
    sIcon = foundFeature.icon
    sColor = saturate(0.16, tint(0.75, foundFeature.icon.color))
  }

  const classes = clsx(`size-${size}`, {
    'is-active': isActive,
    'no-active': skipActive,
    'hide-label': hideLabel,
    'type-scroll-list': type === 'scroll-list',
    'is-centered': center,
    'is-disabled': isDisabled,
    [className]: className,
  })

  const dynamicStyles = {
    '&.is-active': { background: sColor },
    '&.active': { background: sColor },
  }

  const content = (
    <>
      <Graphic
        css={{ marginRight: !hideLabel && size === 300 ? '0.75em' : '0.5em' }}
        icon={sIcon}
        glyph={glyph}
        iconSize={size === 300 ? 20 : 18}
        glyphColor={glyphColor}
        glyphSize={glyphSize}
        avatar={avatar}
        avatarSize={24}
        avatarGlyph={avatarGlyph || 'user_neutral'}
        avatarMagnify={avatarMagnify}
        avatarInitials={showAvatarInitials && (avatarInitials || label)}
        showEmptyAvatar={showEmptyAvatar || showAvatarInitials}
        graphic={graphic}
        className="nav-item-graphic"
      />

      {!hideLabel && (
        <>
          <Flex.Item stretchSelf css={textWrapperStyles}>
            <div css={styles.label}>{label}</div>
            {description && <div css={styles.description}>{description}</div>}
          </Flex.Item>

          <Flex css={{ marginLeft: 'auto', paddingLeft: '0.5em' }} gap={4} nowrap>
            {isBeta && <Status color="gray" small label="Beta" />}
            {isDev && <Status color="gray" small label="Dev" />}
            {isComingSoon && <Status small glyph="star" color="gold" label="Coming Soon" />}
            {/* {isDisabled && <Status color="gray" small label="Disabled" />} */}
            {showTag && <PlanTag />}
            {showNotifications && <Status color="gray" small label={notifications || 0} />}

            {aside}
          </Flex>

          {after}
        </>
      )}
    </>
  )

  if (!asLink || isDisabled) {
    return (
      <div css={[styles, dynamicStyles]} className={classes} onClick={handleClick}>
        {content}
      </div>
    )
  }

  const Tag = props.as || (href ? 'a' : to ? NavLink : 'div')

  return (
    <Tag
      testKey={testKey}
      css={[styles, dynamicStyles]}
      to={to}
      className={classes}
      onClick={handleClick}
      href={href}
      data-type="popover-menu-item"
    >
      {content}
    </Tag>
  )
}

const textWrapperStyles = {
  overflow: 'hidden',
}

const styles = {
  display: 'flex',
  alignItems: 'center',
  color: COLORS.text,
  fontWeight: 500,
  minHeight: 36,

  margin: '0 -0.4rem',
  borderRadius: 5,
  whiteSpace: 'nowrap',
  position: 'relative',

  '&.size-300': {
    padding: '0.1rem 0.4rem',
  },

  '&.size-200': {
    minHeight: 32,
    padding: '0.05rem 0.4rem',
    fontSize: '0.96rem',
  },

  // add hit-area so the root element is always the clicked target
  // required for data-type="popover-menu-item" to work
  '&::before': {
    content: '""',
    position: 'absolute',
    background: COLORS.transparent,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 3,
  },

  '@media(max-width: 599px)': {
    minHeight: 42,
    padding: '0.2rem 0.5rem',
  },

  '.nav-item-graphic': {
    transition: 'transform 70ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    willChange: 'transform',
  },

  '&:hover': {
    background: COLORS.hover,
    outline: 'none',
    cursor: 'pointer',

    '.nav-item-graphic': {
      transform: 'scale3d(1.2, 1.2, 1.2)',
    },
  },

  '&:active': {
    outline: 'none',
  },

  '&.is-active': {
    outline: 'none',
    color: '#111',
  },

  '&.no-active': {
    background: 'none !important',
    color: 'auto !important',
  },

  '&.hide-label': {
    minWidth: 40,
    justifyContent: 'center',
  },

  '&.is-disabled': {
    opacity: 0.5,
    cursor: 'default !important',
    background: 'none !important',

    img: {
      transform: 'none !important',
      filter: 'grayscale(0.8)',
    },
  },

  '&.type-scroll-list': {
    borderRadius: '0',
    transform: 'none',

    '&.is-active': {
      background: tint(0.92, COLORS.blue),
      boxShadow: `-3px 0 0 ${tint(0.25, COLORS.blue)}`,
    },
  },

  '&.is-centered': {
    justifyContent: 'center',
  },

  label: {
    fontWeight: 500,
    // TODO (Andrei): check if truncation needs to be conditional via props and classes
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  description: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontWeight: 400,
    color: COLORS.textMuted,
  },
}

NavItem.defaultProps = {
  featureFlag: true,
  permission: true,
  asLink: true,
  skipActive: false,
  color: COLORS['blue'],
}

export default withPermissions(withScrollContext(NavItem))
