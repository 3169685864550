import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { RecoveryCapitalFormsDataTable } from '@behavehealth/constructs/RecoveryCapitalForms/RecoveryCapitalFormsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { getVersionSlug } from '@behavehealth/utils/functions'

import Card from '@behavehealth/components/Card'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Tabs from '@behavehealth/components/Tabs'

import { DIMENSIONS } from '@behavehealth/constructs/RecoveryCapitalForms/constants'
import { RecoveryCapitalHistoricData } from '@behavehealth/constructs/RecoveryCapitalForms/RecoveryCapitalHistoricData'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { setDuplicateColumn } from '@behavehealth/components/DataTable/functions'

type Props = {
  canCreate: boolean
}

const pageConfig = {
  feature: 'recovery_capital_forms',
  title: 'Recovery Capital Forms',
}

const RootRecoveryCapitalForms: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()
  const { isBehave }: any = useSettings()

  const tableProps = useDataTable({
    name: ['client', resource_id, 'recovery_capital_forms'],
    endpoint: `/residents/${resource_id}/recovery_capital_forms`,
    enabled: !!resource_id,
    localStorageKey: 'client_recovery_capital_forms_v1',
  })

  const [tab, setTab] = React.useState(Object.keys(DIMENSIONS)[0])

  return (
    <Page
      feature="recovery_capital_forms"
      actions={
        <>
          {canCreate && (
            <Dropdown label="Add Recovery Capital Form…" glyph="add" buttonType="primary" permission="recovery_capital_forms.create">
              <DropdownItem
                label="Recovery Capital Form (Archived)"
                icon="recovery_capital_forms"
                color="blue"
                link={`${match.url}/10/new`}
                featureFlagV2={isBehave}
                permission="recovery_capital_forms.create"
              />
              <DropdownItem
                label="Recovery Capital Form"
                icon="recovery_capital_forms"
                color="blue"
                link={`${match.url}/20/new`}
                featureFlagV2="recovery_capital_forms_default_form"
                permission="recovery_capital_forms.create"
              />
              <DropdownItem
                label="Recovery Capital (Drug Court)"
                icon="recovery_capital_forms"
                color="blue"
                link={`${match.url}/drug-court/new`}
                featureFlagV2="recovery_capital_forms_drug_court"
                permission="recovery_capital_forms.create"
              />
            </Dropdown>
          )}
        </>
      }
    >
      <Grid gap="1rem" columns="100%">
        <Card className="grid grid-cols-[100%] overflow-hidden">
          <Tabs activeTab={tab} onChange={setTab}>
            <Tabs.List className="px-4">
              {Object.entries(DIMENSIONS).map(([key, dimension]) => (
                <Tabs.Item key={key} label={dimension.name} name={key} />
              ))}
            </Tabs.List>

            {Object.entries(DIMENSIONS).map(([key, dimension]) => (
              <Tabs.Panel key={key} type="mount" label={dimension.name} name={key} className="mt-4">
                <RecoveryCapitalHistoricData collapsibleTable clientId={resource_id} dimension={key} />
              </Tabs.Panel>
            ))}
          </Tabs>
        </Card>

        <RecoveryCapitalFormsDataTable
          {...tableProps}
          to={(row: any) => {
            if (row?.variant === 'drug_court') {
              return {
                pathname: `${match.url}/drug-court/${row.id}`,
                parent: match,
              }
            } else {
              return {
                pathname: `${match.url}/${getVersionSlug(row.version)}/${row.id}`,
                parent: match,
              }
            }
          }}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: isBehave ? true : 'recovery_capital_forms.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          duplicateColumn={setDuplicateColumn({
            link: `${match.url}/new`,
            parent: match,
            permission: isBehave ? true : 'recovery_capital_forms.create',
          })}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(RootRecoveryCapitalForms, pageConfig))
