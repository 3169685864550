import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  draft: {
    label: 'draft',
    color: 'blue',
  },
  processing: {
    label: 'Processing…',
    color: 'orange',
  },
  archived: {
    label: 'Archived',
    color: 'gray',
  },
  processed: {
    label: 'Processed',
    color: 'green',
  },
}

const EVOBNewStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default EVOBNewStatus
