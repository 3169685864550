import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../theme'

const Divider: React.FC<any> = ({ invisible, vertical, className }) => {
  const classNames = clsx({
    'is-invisible': invisible,
    'is-vertical': vertical,
    [className]: className,
  })

  return <hr css={styles} className={classNames} />
}

const styles = {
  height: 1,
  width: '100%',
  border: 'none',
  flex: ' 0 0 auto',
  background: COLORS.divider,

  margin: '1rem 0',

  '&.is-invisible': {
    background: 'transparent',
  },

  '&.is-vertical': {
    width: 1,
    height: 'auto',
    margin: 0,
  },
}

export default Divider
