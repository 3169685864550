import React from 'react'
import ResourceQuickLink from '../../QuickLinks/ResourceQuickLink'

const MESSAGES: any = {
  batch_create: (props: any) => (
    <>
      {props.author} added a new {props.record}.
    </>
  ),
create: (props: any) => (
    <>
      {props.author} added a new {props.record} titled <i>"{props.event.trackable?.name}"</i> for <ResourceQuickLink resource={props.event?.recipient} disableLink={props.disableLink} />.
    </>
  ),
  update: (props: any) => (
    <>
      {props.author} updated a {props.record} titled <i>"{props.event.trackable?.name}"</i> for <ResourceQuickLink resource={props.event?.recipient} disableLink={props.disableLink} />.
    </>
  ),
  delete: (props: any) => (
    <>
      {props.author} deleted a {props.record} titled <i>"{props.event.trackable?.name}"</i> for <ResourceQuickLink resource={props.event?.recipient} disableLink={props.disableLink} />.
    </>
  ),
}

export default (props: any) => MESSAGES[props.event?.action_type]?.(props) || null
