import React from 'react'

import { COLORS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'

import NavGroup from '../NavGroup'
import NavItem from '../NavItem'

type Props = {
  organization: object
  isPartner: boolean
}

export const CommunityOrganizationAuthorityNavItems = (props: Props) => {
  const { organization } = props

  const { isBehave } = useSettings()

  const basePath = `/community/organizations/${organization?.id}`

  return (
    <>
      <NavGroup label="General">
        <NavItem label="General Info" icon="general_info" to={`${basePath}/authority/general-info`} color={COLORS.blue} />
        <NavItem
          showMarketing
          feature="todos"
          label="To-Do"
          to={`${basePath}/authority/todo`}
          permission="todos.view"
          featureFlagV2="todos"
        />
        <NavItem
          showMarketing
          label="Calendar"
          to={`${basePath}/authority/calendar`}
          feature="calendar"
          permission="events.view"
          featureFlagV2="calendar"
        />
        <NavItem
          showMarketing
          feature="files"
          label="Files"
          to={`${basePath}/authority/files`}
          permission="organizations.files.view"
          featureFlagV2="files"
        />
        <NavItem
          showMarketing
          feature="contacts"
          label="Contacts"
          to={`${basePath}/authority/contacts`}
          permission="contacts.view"
          featureFlagV2="contacts"
        />
        <NavItem
          showMarketing
          feature="communications"
          label="Communications"
          to={`${basePath}/authority/communications`}
          permission="communications.view"
          featureFlagV2="communication_logs"
        />
      </NavGroup>

      <NavGroup label="Directories">
        <NavItem label="Directory Info" icon="general_info" to={`${basePath}/authority/directory-info`} color={COLORS.blue} />
        <NavItem showMarketing feature="properties" label="Locations" to={`${basePath}/authority/locations`} />
        <NavItem showMarketing feature="programs" label="Programs" to={`${basePath}/authority/programs`} />
      </NavGroup>

      <NavGroup label="Forms">
        <NavItem
          label="Lead Contact Forms"
          feature="organization_lead_forms"
          to={`${basePath}/authority/lead-contact-forms`}
          permission="organization_lead_forms.view"
          featureFlagV2="organization_lead_forms"
        />
        <NavItem
          label="Certifications"
          feature="certifications_inspections"
          to={`${basePath}/authority/certification-forms`}
          permission="authority_certifications.view"
          featureFlagV2="authority_certifications"
        />
        <NavItem
          label="Site Reviews"
          feature="certifications_inspections"
          to={`${basePath}/authority/site-review-forms`}
          permission="authority_inspections.view"
          featureFlagV2="authority_inspections"
        />
        <NavItem
          feature="form_submissions"
          label="Form Inbox"
          to={`${basePath}/authority/form-submissions`}
          featureFlagV2="form_submissions"
          permission="form_submissions.view"
        />
      </NavGroup>

      <NavGroup label="Financials">
        <NavItem
          feature="financials"
          label="Financials"
          to={`${basePath}/authority/financials`}
          permission="ledger.view"
          featureFlagV2="organization_financials"
        />
      </NavGroup>

      <NavGroup label="Complaints & Issues">
        <NavItem
          isDev
          label="Grievance Forms"
          feature="grievance_incident_forms"
          to={`${basePath}/authority/grievance-forms`}
          permission={isBehave}
          // permission="authority_grievance_forms.view"
          // featureFlagV2="authority_grievance_forms"
        />
        <NavItem
          isDev
          label="Incident Reporting"
          feature="grievance_incident_forms"
          to={`${basePath}/authority/incident-reporting`}
          permission={isBehave}
          // permission="authority_incident_reporting.view"
          // featureFlagV2="authority_incident_reporting"
        />
      </NavGroup>

      <NavGroup label="Studies & Surveys">
        <NavItem
          isDev
          label="Studies"
          feature="studies_surveys"
          to={`${basePath}/authority/studies`}
          permission={isBehave}
          // permission="authority_studies.view"
          // featureFlagV2="authority_studies"
        />
        <NavItem
          isDev
          label="Surveys"
          feature="studies_surveys"
          to={`${basePath}/authority/surveys`}
          permission={isBehave}
          // permission="authority_surveys.view"
          // featureFlagV2="authority_surveys"
        />
      </NavGroup>
    </>
  )
}
