import React from 'react'
import compact from 'lodash/compact'

import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import SummonOverlay from '../../components/SummonOverlay'
import Button from '../../components/Button'

import { DiagnosisOverlay } from './DiagnosisOverlay'

export const DiagnosesSelector = ({ isEditable, isRequired, baseModel, clientId }: any) => {
  if (!clientId) return null

  return (
    <div>
      <MultiOverlaySelector
        label="Diagnoses"
        model={compact([baseModel, 'diagnoses']).join('.')}
        type="client.diagnoses"
        icon="diagnosis"
        dependentValue={clientId}
        selectTitle={(data: any) => data?.code}
        selectDescription={(data: any) => data?.description}
        labelAfter={
          isEditable && (
            <SummonOverlay key={`client-${clientId}`} overlay={<DiagnosisOverlay dataID="new" clientId={clientId} />}>
              <Button label={`Add New`} size={100} glyph="add" type="minimal" className="-mr-1.5" />
            </SummonOverlay>
          )
        }
        validations={isRequired && { presence: { message: 'Please select a Diagnosis' } }}
      />
    </div>
  )
}
