import React from 'react'
import { darken, tint, transparentize } from 'polished'
import { Global } from '@emotion/react'
import { useMedia } from 'use-media'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS, HARD_SHADOW } from '../../theme'
import { create } from '../../modules/api/requests'
import { titleCase } from '../../utils/functions'
import useStore from '../../modules/store'

import { BannerMessage } from '../../components/BannerMessage'
import { PopoverMenu, PopoverMenuItem, CustomPopoverMenuItem } from '../../components/PopoverMenu'
import Button from '../../components/Button'
import Glyph from '../../components/Glyph'
import NavGroup from '../../components/NavGroup'
import NavItem from '../../components/NavItem'
import SummonOverlay from '../../components/SummonOverlay'
import Tabs from '../../components/Tabs'
import TenantSwitcherOverlay, { useTenantSwitcherOverlay } from '../../components/TenantSwitcherOverlay'

import behaveCircleLogo from '../../assets/accounts/ic_bh_logo.svg'
import communityCircleLogo from '../../assets/accounts/ic_community_logo.svg'
import logoMyAccountImg from '../../assets/accounts/logo_myaccount.svg'
import notificationsEmptyImg from '../../assets/accounts/notifications_empty.svg'
import notificationsImg from '../../assets/accounts/notifications.svg'
import searchImg from '../../assets/accounts/search.svg'
import settingsImg from '../../assets/accounts/settings.svg'

import { AppsHeaderButton } from './AppsHeaderButton'
import { AppsHeaderFeedbackTool } from './AppsHeaderFeedbackTool'
import { AppsHeaderUser } from './AppsHeaderUser'
import { AppsSidebarHeaderButton } from './AppsSidebarHeaderButton'

import { APP_URLS, generateLoginURL } from '../MyAccount/utils'
import { NewInviteCard } from '../MyAccount/NewInviteCard'
import { AppInviteOverlay } from '../MyAccount/AppInviteOverlay'

import { useGet } from '../../hooks/useNewAPI'
import { useAppSidebarStore } from '../../hooks/useAppSidebarStore'
import usePermissions from '@behavehealth/hooks/usePermissions'
import { AppsHeaderHelp } from './AppsHeaderHelp'

const IS_MY_ACCOUNT = process.env.BH_APP === 'myaccount'

export const AppsHeader = (props: any) => {
  const { isBehave, tenant, mobileNavItems, settingsNavItems, onSearchClick, showAppSidebar, settingsLinkTo, showHelp, afterLogo } = props

  const [invite, setInvite] = React.useState<any>(null)
  const { open: openTenantSwitcher } = useTenantSwitcherOverlay()

  const isDesktop = useMedia({ minWidth: 600 })
  const showDesktopSearch = useMedia({ minWidth: 1024 })
  const isMobile = !isDesktop

  const updateStore = useStore((state) => state.update)
  const notifications: any = useStore((state) => state.notifications)
  const appMessages = notifications?.app_messages

  const tenantLogoImg = tenant?.logo
  const tenantName = tenant?.name
  const tenantCategory = tenant?.category

  const [bannerMessage, setBannerMessage] = React.useState<any>(null)

  const appSidebarStore = useAppSidebarStore()

  const { allowed: showVoiceMemos } = usePermissions({ featureFlag: 'voice_memos' })

  const openBannerMessage = async (message: any) => {
    if (!message) return

    setBannerMessage(message)

    if (message?.is_viewed) return

    // Update notification viewed status
    try {
      await create(`/notification_statuses`, { record_type: message.type, record_id: message.id })

      const newNotifications = produce(notifications, (draft: any) => {
        if (!draft?.app_messages) return

        const index = draft.app_messages.findIndex((o: any) => o.id === message.id)

        if (index !== -1) draft.app_messages[index].is_viewed = true
      })

      updateStore({ notifications: newNotifications })
    } catch (error) {
      console.error(error)
    }
  }

  const { data: accessData, isLoading: isLoadingAccess } = useGet({
    name: ['access'],
    url: `/me/access`,
    options: { enabled: !isBehave },
  })

  const hasEHRApps = size(accessData?.access?.ehr) > 0
  const hasClientPortalApps = size(accessData?.access?.client_portal) > 0
  const hasMembersApps = size(accessData?.access?.members) > 0

  const hasAppMessages = size(appMessages) > 0
  const hasInvites = size(accessData?.invites) > 0

  const isNotificationsEmpty = React.useMemo(() => {
    if (!hasAppMessages && !hasInvites) return true

    const appMessagesNotViewed = appMessages?.filter((o: any) => !o.is_viewed)

    return size(appMessagesNotViewed) === 0
  }, [notifications, hasAppMessages, hasInvites])

  const showNotificationsMenu = hasAppMessages || hasInvites

  const logoTrigger = isDesktop ? (
    <div css={STYLES.logoButtonDesktop} onClick={isBehave ? openTenantSwitcher : undefined}>
      {IS_MY_ACCOUNT ? (
        <>
          <img key="my-account-logo" src={logoMyAccountImg} alt="Behave Health Logo" />
          <Glyph glyph="triangle_down" size={9} />
        </>
      ) : (
        <div key="tenant-logo" css={STYLES.tenantLogo}>
          {tenantLogoImg ? (
            <img src={tenantLogoImg} alt="Logo" css={STYLES.tenantLogoImg} />
          ) : (
            <img src={behaveCircleLogo} alt="Behave Health Logo" />
          )}
          <div css={STYLES.tenantLogoContent}>
            <div css={STYLES.tenantLogoName}>
              {tenantName} <Glyph glyph="triangle_down" size={9} />
            </div>
            {tenantCategory && <div css={STYLES.tenantLogoDescription}>{titleCase(tenantCategory)}</div>}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div css={STYLES.logoButtonMobile}>
      {IS_MY_ACCOUNT ? (
        <img src={behaveCircleLogo} alt="Behave Health Logo" />
      ) : (
        <img src={tenantLogoImg || behaveCircleLogo} alt="Logo" css={STYLES.tenantLogoImg} />
      )}
      <div>Menu</div>
    </div>
  )

  return (
    <>
      {isBehave && <TenantSwitcherOverlay />}

      <SummonOverlay isOpen={!!invite} overlay={<AppInviteOverlay data={invite} />} onClose={() => setInvite(null)} />

      <header css={STYLES.root}>
        <Global styles={GLOBAL_STYLES} />

        <PopoverMenu key="mobile-menu" trigger={logoTrigger} maxHeight="calc(95vh - 50px)">
          {!isBehave && (
            <div className="!px-4 !py-2">
              <NavItem
                skipActive={!IS_MY_ACCOUNT}
                isActive={IS_MY_ACCOUNT}
                label="My Account"
                href={APP_URLS.my_account}
                graphic={
                  <div css={STYLES.appNavGraphic}>
                    <img src={behaveCircleLogo} alt="Logo" css={STYLES.appNavLogo} />
                  </div>
                }
              />

              {hasEHRApps && (
                <NavGroup label="EHR Apps">
                  {accessData?.access?.ehr.map((o: any) => (
                    <NavItem
                      isActive={o.app?.id === tenant?.id}
                      skipActive={o.app?.id !== tenant?.id}
                      label={o.app?.name}
                      description={titleCase(o.app?.category)}
                      href={generateLoginURL(o.app, 'ehr')}
                      graphic={
                        <div css={STYLES.appNavGraphic}>
                          <img src={o.app?.logo || behaveCircleLogo} alt="Logo" css={STYLES.appNavLogo} />
                        </div>
                      }
                    />
                  ))}
                </NavGroup>
              )}

              {hasClientPortalApps && (
                <NavGroup label="Client Portals">
                  {accessData?.access?.client_portal.map((o: any) => (
                    <NavItem
                      isActive={o.app?.id === tenant?.id}
                      skipActive={o.app?.id !== tenant?.id}
                      label={o.app?.name}
                      description={titleCase(o.app?.category)}
                      href={generateLoginURL(o, 'client_portal')}
                      graphic={
                        <div css={STYLES.appNavGraphic}>
                          <img src={o.app?.logo || behaveCircleLogo} alt="Logo" css={STYLES.appNavLogo} />
                        </div>
                      }
                    />
                  ))}
                </NavGroup>
              )}
            </div>
          )}

          {isMobile && (
            <div className="!px-4 !py-2">
              {isBehave && (
                <>
                  <NavItem skipActive label="Switch Account" icon="enterprise" onClick={openTenantSwitcher} />
                </>
              )}

              {mobileNavItems}
            </div>
          )}
        </PopoverMenu>

        {afterLogo}

        {showDesktopSearch && !!onSearchClick && (
          <div css={STYLES.desktopSearchWrapper}>
            <Button
              onClick={onSearchClick}
              css={STYLES.desktopSearch}
              label="Search…"
              glyph="search"
              glyphColor={COLORS.textMuted}
              glyphSize={20}
            />
          </div>
        )}

        <AppsHeaderFeedbackTool isDesktop={isDesktop} />

        {showHelp && <AppsHeaderHelp isDesktop={isDesktop} />}

        {!!onSearchClick && !showDesktopSearch && (
          <AppsHeaderButton isDesktop={isDesktop} onClick={onSearchClick}>
            <img src={searchImg} alt="Search" css={STYLES.headerGlyph} />
          </AppsHeaderButton>
        )}

        {showVoiceMemos && appSidebarStore && (
          <AppsHeaderButton isDesktop={isDesktop} onClick={() => appSidebarStore?.open?.('voice_memos')}>
            <Glyph glyph="microphone" color={COLORS.blue} css={STYLES.headerGlyph} />
          </AppsHeaderButton>
        )}

        {showNotificationsMenu && (
          <PopoverMenu
            trigger={
              <AppsHeaderButton isDesktop={isDesktop}>
                <img src={isNotificationsEmpty ? notificationsEmptyImg : notificationsImg} alt="Notifications" css={STYLES.headerGlyph} />
              </AppsHeaderButton>
            }
          >
            <Tabs defaultTab={hasInvites ? 'invites' : hasAppMessages ? 'messages' : null}>
              {hasInvites && hasAppMessages && (
                <Tabs.List css={STYLES.tabsList}>
                  {hasInvites && <Tabs.Item label="Invites" glyph="star" name="invites" />}
                  {hasAppMessages && <Tabs.Item label="Messages" glyph="text_message" name="messages" />}
                </Tabs.List>
              )}

              <Tabs.Panels>
                <Tabs.Panel name="invites">
                  {accessData?.invites?.map?.((invite: any) => (
                    <CustomPopoverMenuItem
                      key={invite.id}
                      onClick={() => {
                        setInvite(invite)
                      }}
                    >
                      <NewInviteCard data={invite} variant="variant-list" />
                    </CustomPopoverMenuItem>
                  ))}
                </Tabs.Panel>

                <Tabs.Panel name="messages">
                  {hasAppMessages &&
                    appMessages?.map?.((message: any) => (
                      <PopoverMenuItem
                        key={message.id}
                        label={message.title}
                        graphic={<Glyph glyph="circle" size={8} color={COLORS.blue} className={message.is_viewed && 'opacity-0'} />}
                        onClick={() => {
                          openBannerMessage(message)
                        }}
                      />
                    ))}
                </Tabs.Panel>
              </Tabs.Panels>
            </Tabs>
          </PopoverMenu>
        )}

        {settingsNavItems && (
          <PopoverMenu
            maxHeight="calc(90vh - 50px)"
            trigger={
              <AppsHeaderButton isDesktop={isDesktop}>
                <img src={settingsImg} alt="Settings" css={STYLES.headerGlyph} />
              </AppsHeaderButton>
            }
          >
            <div css={{ padding: '0.5rem 0.75rem' }}>{settingsNavItems}</div>
          </PopoverMenu>
        )}

        {!isDesktop && showAppSidebar && <AppsSidebarHeaderButton isDesktop={isDesktop} />}

        <AppsHeaderUser isDesktop={isDesktop} settingsLinkTo={settingsLinkTo} />
      </header>

      {bannerMessage && (
        <BannerMessage
          isLarge
          enlargeEnabled={false}
          color={bannerMessage.color}
          glyph={bannerMessage.glyph}
          title={bannerMessage.title}
          richText={bannerMessage.content}
          iframeURL={bannerMessage.iframe_url}
          onDismissClick={() => {
            setBannerMessage(null)
          }}
        />
      )}
    </>
  )
}

const DESKTOP_MQ = '@media(min-width: 600px)'

const GLOBAL_STYLES: any = {
  '#app': {
    paddingTop: '50px !important',

    [DESKTOP_MQ]: {
      paddingTop: '0 !important',
    },
  },
}

const STYLES = {
  root: {
    width: '100%',
    flex: ' 0 0 auto',
    background: COLORS.white,
    display: 'flex',
    alignItems: 'stretch',
    height: 50,
    position: 'fixed',
    top: 0,
    zIndex: 10,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),

    [DESKTOP_MQ]: {
      position: 'static',
    },
  },

  appNavLogo: {
    maxWidth: 38,
    maxHeight: 38,
    position: 'relative',
    top: 1,
  },

  appNavGraphic: {
    width: 38,
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  tenantLogoContent: {
    marginLeft: '0.3rem',
  },

  tenantLogo: {
    maxHeight: 36,
    display: 'flex',
    alignItems: 'center',
  },

  tenantLogoName: {
    fontWeight: 600,
    color: COLORS.blue,
    fontSize: '0.94rem',
    lineHeight: 1,
  },

  tenantLogoDescription: {
    fontWeight: 400,
    color: COLORS.textMuted,
    fontSize: '0.88rem',
    marginTop: '0.1rem',
    lineHeight: 1,
  },

  tenantLogoImg: {
    maxHeight: 38,
    marginLeft: '0.5rem',

    '@media (max-width: 599px)': {
      maxWidth: 38,
    },
  },

  logoButtonDesktop: {
    minWidth: 28,
    minHeight: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 'auto',

    svg: {
      marginLeft: '0.25rem',
      position: 'relative',
      top: -1,
    },
  },

  logoButtonMobile: {
    minWidth: 28,
    minHeight: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 'auto',
    paddingLeft: '0.25rem',
    paddingRight: '0.75rem',
    fontWeight: 700,
    borderRight: `1px solid ${COLORS.divider}`,
    color: COLORS.blue,
    textTransform: 'uppercase',
    fontSize: '0.88rem',
    letterSpacing: 1,

    img: {
      display: 'block',
      flex: '0 0 auto',
      maxWidth: 38,
      maxHeight: 38,
      position: 'relative',
      top: 1,
    },
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 28,
    borderRadius: 4,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    marginRight: '0.75rem',
    fontWeight: 500,
    color: COLORS.text,

    img: {
      width: 16,
      height: 16,
      display: 'inline-flex',
      marginRight: '0.25rem',
    },

    '&:hover': {
      background: COLORS.hover,
    },
  },

  squareButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    borderRadius: 4,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    marginRight: '0.75rem',

    '&:hover': {
      background: COLORS.hover,
    },
  },

  mobileSearchWrapper: {
    display: 'block',
    marginLeft: '0.75rem',

    [DESKTOP_MQ]: {
      display: 'none',
    },
  },

  headerGlyph: {
    width: 20,
    height: 20,
  },

  desktopSearchWrapper: {
    padding: '0 1rem',
    flex: '1 1 auto',
    alignSelf: 'center',
  },

  desktopSearch: {
    border: 0,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    margin: '0 auto',
    flex: '1 1 auto',
    maxWidth: 300,

    color: COLORS.text,
    fontWeight: 500,
    background: darken(0.02, COLORS.lightBackground),
    boxShadow: 'none',

    '&:hover': {
      transform: 'none',
      color: COLORS.blue,
      background: tint(0.92, COLORS.blue),
      boxShadow: `
        inset 0 0 0 1px ${transparentize(0.75, COLORS.blue)}
      `,

      '& svg': {
        fill: `${COLORS.blue}`,
      },
    },

    '&.is-mobile': {
      display: 'none',
    },

    '& > *': {
      width: '100%',
    },
  },

  tabsList: {
    paddingLeft: '1rem',
  },
}
