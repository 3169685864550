import React from 'react'

import { amount } from '../../utils/functions'

import Status from '../../components/Status'
import ClientStatus from '../../components/Statuses/ClientStatus'

import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'
import ReportRefetchButton from '../../components/Buttons/ReportRefetchButton'

import Glyph from '../../components/Glyph'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { PAYMENT_METHODS } from '../../utils/constants'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

export const PaymentsReportDataTable = (props: any) => {
  const { to } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data?.id} value={data?.name} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'resident',
        model: 'resident.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 180,
        id: 'resident_status',
        model: 'resident.status',
        title: 'Client Status',
        formatValue: ({ value }: any) => <ClientStatus status={value} />,
      },
      {
        title: 'Color',
        id: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker isDisabled clientID={data?.resident?.id} color={data?.resident?.color_code} />,
      },
      {
        width: 180,
        id: 'amount',
        model: 'amount',
        title: 'Amount',
        formatValue: ({ data, value }: any) => <Status color="green" label={`+$${amount(value)}`} />,
      },
      {
        id: 'reference',
        model: 'reference.name',
        title: 'Payer',
        // formatValue: ({ data, value }: any) => {
        //   if (data?.reference?.type === 'contact') {
        //     return `${value} (${data?.reference?.relationship})`
        //   }

        //   return value || '–'
        // },
      },
      {
        width: 250,
        id: 'payed_with',
        model: 'payed_with',
        title: 'Expected Payment',
        formatValue: ({ data, value }: any) => {
          if (!value) return '–'

          return (
            <>
              <Glyph glyph={PAYMENT_METHODS[value]?.glyph} /> {PAYMENT_METHODS[value]?.label}
            </>
          )
        },
      },
      {
        id: 'payment_method',
        model: 'payment_method.name',
        title: 'Payment Method',
      },
      {
        id: 'transactioned_at',
        model: 'transactioned_at',
        title: 'Payment Date',
        type: 'date_time',
      },
      {
        id: 'payment_reference',
        model: 'payment_reference',
        title: 'Payment Reference',
      },
      {
        id: 'client_location',
        model: 'client_location.name',
        title: 'Client Location',
        type: 'profile',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
      {
        width: 140,
        id: 'created_at',
        model: 'created_at',
        title: 'Create Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 140,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Update Date',
        type: 'date_time',
        disableSort: false,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Payments"
      icon="financials"
      columns={columns}
      filtersConfig={PAYMENTS_FILTERS}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="payments"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=payments'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'payments' }} />
        </>
      }
      {...props}
    />
  )
}

const PAYMENTS_FILTERS = {
  name: {
    label: 'Name',
    type: 'string',
  },
  resident_status: {
    label: 'Client Status',
    type: 'multi_select',
    options: [
      { label: 'Applicant', value: 'lead' },
      { label: 'Intake', value: 'intake' },
      { label: 'Current', value: 'current' },
      { label: 'Past', value: 'past' },
      { label: 'Past Client', value: 'alumni' },
      { label: 'Declined', value: 'declined' },
    ],
  },
  amount: {
    label: 'Amount',
    type: 'number',
  },
  total_amount: {
    label: 'Total Amount',
    type: 'number',
  },
  notes: {
    label: 'Notes',
    type: 'string',
  },
  payed_with: {
    label: 'Expected Payment',
    type: 'string',
  },
  payment_reference: {
    label: 'Payer',
    type: 'string',
  },
  should_auto_charge: {
    label: 'Auto Collection',
    type: 'boolean',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Charged', value: 'charged' },
      { label: 'Future Charge', value: 'future' },
      { label: 'Cancelled', value: 'cancelled' },
    ],
  },
  service: {
    label: 'Category',
    type: 'multi_select',
    options: [
      { label: 'Custom', value: 'custom' },
      { label: 'Intake Fee', value: 'intake_fee' },
      { label: 'Security Deposit', value: 'security_deposit' },
      { label: 'Sobriety Deposit', value: 'sobriety_deposit' },
      { label: 'Rent', value: 'rent' },
      { label: 'Program Fee', value: 'program_fee' },
      { label: 'Admin Fee', value: 'admin_fee' },
      { label: 'Late Fee', value: 'late_fee' },
      { label: 'Recovery Coaching', value: 'recovery_coaching' },
      { label: 'Career Coaching', value: 'career_coaching' },
      { label: 'Life Coaching', value: 'life_coaching' },
    ],
  },
  transactioned_at: {
    label: 'Due Date / Payment Date',
    type: 'date_time',
    glyph: 'date',
  },
  client_location: {
    label: 'Client Location',
    type: 'multi_object',
    endpoint: '/houses',
    apiKey: 'locations',
    glyph: 'user_neutral',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  author: {
    label: 'Added By',
    type: 'multi_object',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_neutral',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  created_at: {
    label: 'Create Date',
    type: 'date_time',
    glyph: 'date',
  },
  updated_at: {
    label: 'Last Update Date',
    type: 'date_time',
    glyph: 'date',
  },
}
