import Card from '../../../components/Card'

export const CSSRSScale = () => {
  return (
    <Card className="px-4 py-3 grid gap-2">
      <h4>Columbia Suicide Severity Rating Scale</h4>
      <ul className="grid gap-2 pl-5">
        <li>
          <div className="font-[600]">Low Risk</div>
          <ul className="pl-5">
            <li>Consider outpatient referral for community services</li>
          </ul>
        </li>
        <li>
          <div className="font-[600]">Medium Risk</div>
          <ul className="pl-5">
            <li>Item 3 - Psychiatric consult (Psychiatric Nurse/Social Worker) and consider patient safety precautions</li>
          </ul>
        </li>
        <li>
          <div className="font-[600]">High Risk</div>
          <ul className="pl-5">
            <li>Item 4 and 5 - Psychiatric consultation and patient safety precautions</li>
            <li>Inpatient - Immediate notification of physician and/or psychiatric consultation and patient safety precautions</li>
          </ul>
        </li>
        <li>
          <div className="font-[600]">Medium Risk</div>
          <ul className="pl-5">
            <li>Item 6 - Over 3 months ago. Psychiatric consult and consider patient safety precautions</li>
          </ul>
        </li>
        <li>
          <div className="font-[600]">High Risk</div>
          <ul className="pl-5">
            <li>
              Item 6 three months ago or less: Immediate notification of physician and/or psychiatric consultation and patient safety
              precautions
            </li>
          </ul>
        </li>
      </ul>
    </Card>
  )
}
