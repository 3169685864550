import React from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import merge from 'lodash/merge'
import produce from 'immer'

import { usDateTime } from '../../utils/functions'
import { useCreate, useGet } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'
import withSettings from '../../hocs/withSettings'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DataList from '../../components/DataList'
import Dialog from '../../components/Dialog'
import FormSection from '../../components/Forms/FormSection'
import NoInternet from '../../components/Alerts/NoInternet'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Workflow from '../../components/Workflow/Workflow'

import AdmissionDetails from './common/AdmissionDetails'
import Agreements from './common/Agreements'
import BedAssignment from './common/BedAssignment'
import CareTeam from './common/CareTeam'
import ClinicalMeasures from './common/ClinicalMeasures'
import ContactDetails from './common/ContactDetails'
import Contacts from './common/Contacts'
import Events from './common/Events'
import Financials from './common/Financials'
import InsuranceAuthorizations from './common/InsuranceAuthorizations'
import InsuranceEvobs from './common/InsuranceEvobs'
import InsuranceFullVobs from './common/InsuranceFullVobs'
import InsurancePolicies from './common/InsurancePolicies'
import NotifySignees from './common/NotifySignees'
import OutcomeMeasures from './common/OutcomeMeasures'
import Programs from './common/Programs'
import RecoveryNotes from './common/RecoveryNotes'
import ReferralDetails from './common/ReferralDetails'
import ServiceEpisodes from './common/ServiceEpisodes'
import Todos from './common/Todos'
import PersonalDetails from './common/PersonalDetails'
import HomeAddress from './common/HomeAddress'
import TreatmentEpisodes from './common/TreatmentEpisodes'

import { SecureMessage } from './common/SecureMessage'
import { ClientWorkflowSubheader } from './ClientWorkflowSubheader'

export const READMIT_STEPS: any = {
  admission_details: {
    label: 'Admission Details',
    isRequired: true,
  },
  bed_assignment: {
    label: 'Bed Assignment',
    isRequiredOptional: true,
    featureFlagV2: 'bed_management',
  },
  treatment_episodes: {
    label: 'Treatment Episodes',
    isRequiredOptional: true,
  },
  service_episodes: {
    label: 'Service Episodes',
    isRequiredOptional: true,
    featureFlagV2: 'service_episodes',
  },
  programs_phases: {
    label: 'Programs Phases',
    isRequiredOptional: true,
    featureFlagV2: 'programs',
  },
  personal_details: {
    label: 'Personal Details',
    isRequiredOptional: true,
  },
  contact_details: {
    label: 'Contact Details',
  },
  home_address: {
    label: 'Home Address',
    isRequiredOptional: true,
  },
  contacts: {
    label: 'Contacts',
    isRequiredOptional: true,
    featureFlagV2: 'contacts',
  },
  referral_details: {
    label: 'Referral Details',
  },
  care_team: {
    label: 'Care Team',
    isRequiredOptional: true,
    featureFlagV2: 'care_team',
  },
  client_portal: {
    label: 'Client Portal',
  },
  agreements: {
    label: 'Agreements',
    isRequiredOptional: true,
    featureFlagV2: 'client_agreements',
  },
  notify_signees: {
    label: 'Notify Signees',
    isRequiredOptional: true,
    featureFlagV2: 'client_agreements',
  },
  financials: {
    label: 'Financials',
    isRequiredOptional: true,
    featureFlagV2: 'financials',
  },
  notify_payers: {
    label: 'Notify Payers',
  },
  todos: {
    label: 'Todos',
    isRequiredOptional: true,
    featureFlagV2: 'todos',
  },
  events: {
    label: 'Events',
    isRequiredOptional: true,
    featureFlagV2: 'calendar',
  },
  insurance_policies: {
    label: 'Insurance Policies',
    isRequiredOptional: true,
    featureFlagV2: 'insurance_policies',
  },
  insurance_authorizations: {
    label: 'Insurance Authorizations',
    isRequiredOptional: true,
    featureFlagV2: 'insurance_authorizations',
  },
  insurance_evobs: {
    label: 'Insurance eVOBs',
    featureFlagV2: 'evobs',
  },
  insurance_full_vobs: {
    label: 'Insurance Full VOBs',
    featureFlagV2: 'full_vobs',
  },
  recovery_notes: {
    label: 'Recovery Notes',
    isRequiredOptional: true,
    featureFlagV2: 'recovery_coaching',
  },
  clinical_measures: {
    label: 'Clinical Measures',
    isRequiredOptional: true,
    featureFlagV2: 'clinical_measures',
  },
  outcome_measures: {
    label: 'Outcome Measures',
    isRequiredOptional: true,
    featureFlagV2: 'outcome_measures',
  },
  admit: {
    label: 'Admit',
    isMandatory: true,
  },
}

const stepsReducer = (state: any, payload: any) => {
  const { step, status } = payload
  if (!status || !state.hasOwnProperty(step)) return state

  return produce(state, (draft: any) => {
    draft[step] = status
  })
}

const initialData = {}

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const RootIntakeReadmit = ({ online, timezone }: any) => {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()

  const { resource_id }: any = match.params

  const { tenant, isBehave, systemPrefs } = useSettings()

  const initialSteps = React.useMemo(() => {
    const result: any = {}

    for (const step of Object.keys(READMIT_STEPS)) {
      result[step] = 'todo'
    }

    return result
  }, [READMIT_STEPS])

  const [steps, setSteps] = React.useReducer(stepsReducer, initialSteps)
  const [data, setData] = React.useReducer(dataReducer, initialData)

  const { data: client, isLoading }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const { mutateAsync, isLoading: isReadmitting }: any = useCreate({
    name: ['client', resource_id],
    url: `/residents/${resource_id}/readmit`,
    invalidateKeys: ['admissions', 'clients', ['client', resource_id]],
  })

  const clientName = client?.first_name
  const canAdmit = data?.admitted_at

  const updateData = (newValue: any) => {
    const newData = produce(data, (draft: any) => {
      draft = merge(draft, newValue)
    })

    setData(newData)
  }

  React.useEffect(() => {
    if (!tenant) return

    // if the tenant has the right feature flags, load up the client as billable
    if (tenant.feature_flags?.['insurance_claims'] === true) {
      updateData({ is_insurance_billable: systemPrefs?.default_clients_to_billable || false })
    }
  }, [tenant])

  if (!client || isLoading) return <OverlayLoader />

  const close = () => {
    if (location.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const readmit = async () => {
    const payload = produce(data, (draft: any) => {
      // remove bed object
      delete draft.bed

      // remove bed id if skip bed assignment is true
      if (draft.has_no_bed) {
        delete draft.bed_id
      }

      if (draft?.referral_in) delete draft.referral_in
      if (draft?.referral_out) delete draft.referral_out
      if (draft?.referral_in_contact) delete draft.referral_in_contact
      if (draft?.referral_out_contact) delete draft.referral_out_contact
    })

    await mutateAsync(payload)

    close()
    history.push(`/clients/${resource_id}/general-info`) // go to the Client
  }

  const options = client?.client_workflow?.options || tenant?.client_workflow_options

  return (
    <Overlay
      fullheight
      showBackdrop
      maxWidth={52}
      position="center"
      onClose={close}
      closeWrapper={(closeElement: any) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost if not saved on the Review & Save step"
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={close}
        >
          {closeElement}
        </Dialog>
      )}
    >
      <Overlay.Header glyph="readmit" title={`Re-Admit ${clientName}`} />
      <ClientWorkflowSubheader client={client} />

      <Overlay.Content>
        <Workflow
          name="Readmit Past Client"
          config={READMIT_STEPS}
          stepStatuses={steps}
          visibilityOptions={options?.readmit}
          requiredOptions={options?.readmit_required_steps}
          stepInstructions={options?.readmit_instructions}
        >
          {/* Admission Details */}
          <Workflow.Panel step="admission_details">
            <Workflow.Header title="Admission Details" after={<Workflow.Status status={steps.admission_details} />} />
            <Workflow.Content>
              <AdmissionDetails
                data={{ ...data }}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'admission_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Bed Assignment */}
          <Workflow.Panel showForCommunity step="bed_assignment" permission="bed_management.view" featureFlagV2="bed_management">
            <Workflow.Header title="Bed Assignment" after={<Workflow.Status status={steps.bed_assignment} />} />
            <Workflow.Content>
              <BedAssignment
                client={client}
                data={{
                  ...data,
                  occupancy: data?.occupancy || client.current_bed_occupancy || client.next_bed_occupancy,
                }}
                hasCurrentOccupancy={!!client.current_bed_occupancy || !!client.next_bed_occupancy}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'bed_assignment', status })
                }}
                defaultStatus="occupied"
                defaultFromDate={data?.admitted_at}
                defaultUntilDate={data?.estimated_discharge}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Treatment Episodes */}
          <Workflow.Panel step="treatment_episodes" permission={isBehave ? true : 'treatment_episodes.view'}>
            <Workflow.Header title="Treatment Episodes" after={<Workflow.Status status={steps.treatment_episodes} />} />
            <Workflow.Content>
              <TreatmentEpisodes
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'treatment_episodes', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Service Episodes */}
          <Workflow.Panel step="service_episodes" permission={isBehave ? true : 'service_episodes.view'} featureFlagV2="service_episodes">
            <Workflow.Header title="Service Episodes" after={<Workflow.Status status={steps.service_episodes} />} />
            <Workflow.Content>
              <ServiceEpisodes
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'service_episodes', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Programs & Phases */}
          <Workflow.Panel step="programs_phases" featureFlagV2="programs">
            <Workflow.Header title="Programs" after={<Workflow.Status status={steps.programs_phases} />} />
            <Workflow.Content>
              <Programs
                match={match}
                setStatus={(status: string) => {
                  setSteps({ step: 'programs_phases', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Personal Details */}
          <Workflow.Panel step="personal_details" permission="clients.general_info.personal_details.view">
            <Workflow.Header title="Personal Details" after={<Workflow.Status status={steps.personal_details} />} />
            <Workflow.Content>
              <PersonalDetails
                client={client}
                data={data}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'personal_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Contact Details */}
          <Workflow.Panel step="contact_details">
            <Workflow.Header title="Contact Details" after={<Workflow.Status status={steps.contact_details} />} />
            <Workflow.Content>
              <ContactDetails
                client={client}
                data={data}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'contact_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Home Address */}
          <Workflow.Panel step="home_address" permission="clients.general_info.home_address.view">
            <Workflow.Header title="Home Address" after={<Workflow.Status status={steps.home_address} />} />
            <Workflow.Content>
              <HomeAddress
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'home_address', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Contacts */}
          <Workflow.Panel step="contacts" permission="contacts.view" featureFlagV2="contacts">
            <Workflow.Header title="Contacts" after={<Workflow.Status status={steps.contacts} />} />
            <Workflow.Content>
              <Contacts
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'contacts', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Referral Details */}
          <Workflow.Panel showForCommunity step="referral_details">
            <Workflow.Header title="Referrals Details" after={<Workflow.Status status={steps.referral_details} />} />
            <Workflow.Content>
              <ReferralDetails
                client={client}
                timezone={timezone}
                setStatus={(status: string) => {
                  setSteps({ step: 'referral_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* <Workflow.Panel  step="referral_details">
            <Workflow.Header title="Referrals Details" after={<Workflow.Status status={steps.referral_details} />} />
            <Workflow.Content>
              <ReferralDetails
                client={client}
                timezone={timezone}
                data={data}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'referral_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel> */}

          {/* Care Team */}
          <Workflow.Panel showForCommunity step="care_team" featureFlagV2="care_team">
            <Workflow.Header title="Care Team" after={<Workflow.Status status={steps.care_team} />} />
            <Workflow.Content>
              <CareTeam
                setStatus={(status: string) => {
                  setSteps({ step: 'care_team', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Client Portal */}
          {/* <Workflow.Panel  step="client_portal" featureFlagV2="client_portal">
            <Workflow.Header title="Client Portal" after={<Workflow.Status status={steps.client_portal} />} />
            <Workflow.Content>
              <ClientPortal
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'client_portal', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel> */}

          {/* Agreements */}
          <Workflow.Panel step="agreements" featureFlagV2="client_agreements">
            <Workflow.Header title="Agreements" after={<Workflow.Status status={steps.agreements} />} />
            <Workflow.Content>
              <Agreements
                match={match}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'agreements', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Notify Signees */}
          <Workflow.Panel step="notify_signees" featureFlagV2="client_agreements">
            <Workflow.Header title="Notify Signees" after={<Workflow.Status status={steps.notify_signees} />} />
            <Workflow.Content>
              <NotifySignees
                match={match}
                setStatus={(status: string) => {
                  setSteps({ step: 'notify_signees', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Financials */}
          <Workflow.Panel step="financials" permission="ledger.view" featureFlagV2="financials">
            <Workflow.Header title="Financials" after={<Workflow.Status status={steps.financials} />} />
            <Workflow.Content>
              <Financials
                match={match}
                location={location}
                setStatus={(status: string) => {
                  setSteps({ step: 'financials', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Todos */}
          <Workflow.Panel showForCommunity step="todos" permission="todos.view" featureFlagV2="todos">
            <Workflow.Header title="To-Do's" after={<Workflow.Status status={steps.todos} />} />
            <Workflow.Content>
              <Todos
                client={client}
                match={match}
                timezone={timezone}
                setStatus={(status: string) => {
                  setSteps({ step: 'todos', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Events */}
          <Workflow.Panel showForCommunity step="events" permission="events.view" featureFlagV2="calendar">
            <Workflow.Header title="Events" after={<Workflow.Status status={steps.events} />} />
            <Workflow.Content>
              <Events
                client={client}
                match={match}
                timezone={timezone}
                setStatus={(status: string) => {
                  setSteps({ step: 'events', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Insurance Policies */}
          <Workflow.Panel step="insurance_policies" permission="insurance_policies.view" featureFlagV2="insurance_policies">
            <Workflow.Header title="Insurance Policies" after={<Workflow.Status status={steps.insurance_policies} />} />
            <Workflow.Content>
              <InsurancePolicies
                match={match}
                timezone={timezone}
                client={client}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'insurance_policies', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Insurance Authorizations */}
          <Workflow.Panel
            step="insurance_authorizations"
            permission="insurance_authorizations.view"
            featureFlagV2="insurance_authorizations"
          >
            <Workflow.Header title="Insurance Authorizations" after={<Workflow.Status status={steps.insurance_authorizations} />} />
            <Workflow.Content>
              <InsuranceAuthorizations
                match={match}
                timezone={timezone}
                client={client}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'insurance_authorizations', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* eVOBs */}
          <Workflow.Panel step="insurance_evobs" permission="insurance_evobs.view" featureFlagV2="evobs">
            <Workflow.Header title="eVOBs" after={<Workflow.Status status={steps.insurance_evobs} />} />
            <Workflow.Content>
              <InsuranceEvobs
                match={match}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'insurance_evobs', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Full VOBs */}
          <Workflow.Panel step="insurance_full_vobs" permission="insurance_evobs.view" featureFlagV2="full_vobs">
            <Workflow.Header title="Full VOBs" after={<Workflow.Status status={steps.insurance_full_vobs} />} />
            <Workflow.Content>
              <InsuranceFullVobs
                match={match}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'insurance_full_vobs', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Recovery Notes */}
          <Workflow.Panel step="recovery_notes" permission="recovery_coaching.view" featureFlagV2="recovery_coaching">
            <Workflow.Header title="Recovery Notes" after={<Workflow.Status status={steps.recovery_notes} />} />
            <Workflow.Content>
              <RecoveryNotes
                match={match}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'recovery_notes', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Outcome Measures */}
          <Workflow.Panel step="clinical_measures" permission="clinical_measurements.view" featureFlagV2="clinical_measures">
            <Workflow.Header title="Clinical Measures" after={<Workflow.Status status={steps.clinical_measures} />} />
            <Workflow.Content>
              <ClinicalMeasures
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'clinical_measures', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Outcome Measures */}
          <Workflow.Panel step="outcome_measures" permission="outcome_measures.view" featureFlagV2="outcome_measures">
            <Workflow.Header title="Outcome Measures" after={<Workflow.Status status={steps.outcome_measures} />} />
            <Workflow.Content>
              <OutcomeMeasures
                match={match}
                timezone={timezone}
                client={client}
                subcategories={['forse_admit_survey', 'forse_demographic_information']}
                setStatus={(status: string) => {
                  setSteps({ step: 'outcome_measures', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Readmit */}
          <Workflow.Panel step="admit">
            <Workflow.Header title={`Re-Admit ${clientName}`} after={<Workflow.Status status="todo" />} />
            <Workflow.Content>
              <ReadmitStep
                canAdmit={canAdmit}
                data={data}
                setData={updateData}
                isReadmitting={isReadmitting}
                readmit={readmit}
                steps={steps}
                timezone={timezone}
              />
            </Workflow.Content>
          </Workflow.Panel>
        </Workflow>
      </Overlay.Content>
    </Overlay>
  )
}

const ReadmitStep = (props: any) => {
  const { canAdmit, data, setData, isReadmitting, readmit, timezone } = props

  const { isAllCompleted }: any = React.useContext(Workflow.Context)

  return (
    <FormSection maxWidth="100%">
      {!canAdmit && (
        <Alert contrast glyph="warning" type="warning">
          Please set an admission date.
        </Alert>
      )}

      {!isAllCompleted && (
        <Alert contrast glyph="warning" type="warning">
          Please complete all required steps.
        </Alert>
      )}

      {canAdmit && isAllCompleted && (
        <>
          <DataList>
            <DataList.Item label="Re-Admit Date" value={usDateTime(data?.admitted_at, timezone)} />
            <DataList.Item label="Est. Discharge Date" value={usDateTime(data?.estimated_discharge, timezone)} />

            {data?.occupancy?.place ? (
              <>
                <DataList.Item label="Location" value={data.occupancy.place?.house?.name} />
                <DataList.Item label="Floor" value={data.occupancy.place?.floor?.name} />
                <DataList.Item label="Room" value={data.occupancy.place?.room?.name} />
                <DataList.Item label="Bed" value={data.occupancy.place?.name} />
              </>
            ) : (
              <DataList.Item label="Bed Assignment" value="N/A" />
            )}
          </DataList>

          <SecureMessage data={data} setData={setData} category="readmit" />

          <Button
            label="Re-Admit Client"
            glyph="check_in"
            type="primary"
            color="green"
            onClick={readmit}
            isLoading={isReadmitting}
            isDisabled={!canAdmit}
          />
        </>
      )}
    </FormSection>
  )
}

export const IntakeReadmit = withOverlayError(withSettings(RootIntakeReadmit))
