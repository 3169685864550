import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import size from 'lodash/size'
import isFinite from 'lodash/isFinite'
import 'handsontable/dist/handsontable.full.min.css'

import { registerAllModules } from 'handsontable/registry'
import { HotTable, HotColumn } from '@handsontable/react'

import { COLORS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'
import { validate } from '../../components/Forms/validators'
import { countWord } from '../../utils/functions'

import Button from '../Button'
import Card from '../Card'
import CardTitle from '../CardTitle'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Divider from '../Divider'
import Flex from '../Flex'
import Icon from '../Icon'
import Json from '../Json'
import State from '../State'

import { AmountRenderer } from './cells/AmountCell'
import { DateRenderer, DateEditor } from './cells/DateCell'
import { DateTimeRenderer, DateTimeEditor } from './cells/DateTimeCell'
import { MultiObjectSelectorRenderer, MultiObjectSelectorEditor } from './cells/MultiObjectSelectorCell'
import { NumericRenderer } from './cells/NumericCell'
import { ObjectSelectorRenderer, ObjectSelectorEditor } from './cells/ObjectSelectorCell'
import { RichTextRenderer, RichTextEditor } from './cells/RichTextCell'
import { SelectRenderer, SelectEditor } from './cells/SelectCell'
import { SelectRowRenderer } from './cells/SelectRowCell'
import { TextRenderer } from './cells/TextCell'
import { MultiSearchSelectorRenderer, MultiSearchSelectorEditor } from './cells/MultiSearchSelectorCell'
import { SearchSelectorRenderer, SearchSelectorEditor } from './cells/SearchSelectorCell'

const BH_HANDSONTABLE_KEY = process.env['BH_HANDSONTABLE_KEY']

const DEBUG = false

registerAllModules()

const NATIVE_CELL_TYPES = ['numeric', 'text', 'checkbox']

const COLUMNS: any = {
  select_row: {
    editor: null,
    readOnly: true,
    renderer: SelectRowRenderer,
  },
  checkbox: {
    type: 'checkbox',
  },
  text: {
    type: 'text',
    renderer: TextRenderer,
    createValidator: (column) => {
      return (value: string, callback: (value: boolean, errors: any) => void) => {
        const errors: any = []

        if (!column?.config || value === null) return callback(true, errors)

        const validations = column?.config?.validations

        if (validations) {
          errors.push(...validate(value, validations))
        }

        return callback(errors.length === 0, errors)
      }
    },
  },
  amount: {
    type: 'numeric',
    renderer: AmountRenderer,
    createValidator: (column) => {
      return (value: string, callback: (value: boolean, errors: any) => void) => {
        const errors: any = []

        if (value === null) return callback(true, errors)

        if (value && !isFinite(parseFloat(value))) {
          errors.push({ message: 'Amount must be a number' })
        }

        const validations = column?.config?.validations

        if (validations) {
          errors.push(...validate(value, validations))
        }

        return callback(errors.length === 0, errors)
      }
    },
  },
  numeric: {
    type: 'numeric',
    renderer: NumericRenderer,
    createValidator: (column) => {
      return (value: string, callback: (value: boolean, errors: any) => void) => {
        const errors: any = []

        if (value === null) return callback(true, errors)

        if (value && !isFinite(parseFloat(value))) {
          errors.push({ message: 'Amount must be a number' })
        }

        const validations = column?.config?.validations

        if (validations) {
          errors.push(...validate(value, validations))
        }

        return callback(errors.length === 0, errors)
      }
    },
  },
  object_selector: {
    renderer: ObjectSelectorRenderer,
    editor: ObjectSelectorEditor,
  },
  multi_object_selector: {
    renderer: MultiObjectSelectorRenderer,
    editor: MultiObjectSelectorEditor,
  },
  select: {
    renderer: SelectRenderer,
    editor: SelectEditor,
  },
  rich_text: {
    renderer: RichTextRenderer,
    editor: RichTextEditor,
  },
  date: {
    renderer: DateRenderer,
    editor: DateEditor,
  },
  date_time: {
    renderer: DateTimeRenderer,
    editor: DateTimeEditor,
  },
  multi_search_selector: {
    renderer: MultiSearchSelectorRenderer,
    editor: MultiSearchSelectorEditor,
  },
  input_search_selector: {
    renderer: SearchSelectorRenderer,
    editor: SearchSelectorEditor,
  },
}

export const Spreadsheet = (props: any) => {
  const {
    canAdd = true,
    canDelete = true,
    className,
    columns,
    columnSorting = true,
    editActions,
    fixedColumnsStart,
    getData,
    getSpreadsheet,
    headerAfter,
    height = 'auto',
    hiddenColumns,
    icon,
    isEditable,
    manualColumnMove = true,
    manualRowDelete,
    minRows,
    onAfterChange,
    selectedRowIndexes,
    selectedRows,
    setSelectedRowIndexes,
    title,
  } = props

  const ref = React.useRef()

  const settings = useSettings()
  const [debugData, setDebugData] = React.useState(null)

  const [deleteRowsDialogOpen, setDeleteRowsDialogOpen] = React.useState(false)

  const [rowsCount, setRowsCount] = React.useState(ref.current?.hotInstance ? ref.current.hotInstance.countRows() : 1)

  const addRow = () => {
    if (!ref?.current?.hotInstance) return

    ref.current.hotInstance.batchRender(() => {
      ref.current.hotInstance.alter('insert_row_below')

      const currentRowsCount = ref.current.hotInstance.countRows()

      for (const column of columns) {
        if (column?.config?.defaultValue) {
          ref.current.hotInstance.setDataAtRowProp(currentRowsCount - 1, column.model, column.config.defaultValue)
        }
      }
    })

    setRowsCount(ref.current.hotInstance.countRows())
  }

  const removeRow = () => {
    const selected = ref?.current?.hotInstance?.getSelected()

    DEBUG && console.debug('removeRow', { selected })
    ref?.current?.hotInstance.alter('remove_row')

    if (!selected) return

    const [startRow, startCol, endRow, endCol] = selected
  }

  const deleteSelectedRows = () => {
    if (!ref?.current?.hotInstance || !selectedRowIndexes) return

    let increment = 0

    const orderedRows = selectedRowIndexes.sort((a: number, b: number) => a - b)

    for (const index of orderedRows) {
      ref.current.hotInstance.alter('remove_row', index - increment)
      increment++
    }

    setSelectedRowIndexes([])
    setRowsCount(ref.current.hotInstance.countRows())
  }

  const initialData = React.useMemo(() => {
    if (!props.initialData) return

    return JSON.parse(JSON.stringify(props.initialData))
  }, [])

  React.useEffect(() => {
    if (!getSpreadsheet || !ref.current) return

    getSpreadsheet(ref.current)
    setRowsCount(ref.current.hotInstance.countRows())
  }, [ref.current])

  return (
    <>
      <Card
        className={clsx('Spreadsheet relative z-[0] grid', title && 'grid-rows-[auto_1fr]', isEditable && 'is-editable', className)}
        baseline="2.75rem"
        css={STYLES}
      >
        {title && (
          <div className="flex items-center p-2 border-b border-0 border-solid border-divider shadow-hard-3 relative z-[1] ">
            <div className="flex items-center flex-nowrap">
              {icon && <Icon icon={icon} size={20} />}
              <CardTitle title={title} className="!ml-1.5 !text-[1rem] !font-[700] whitespace-nowrap" />
            </div>

            <Flex gap="0.25rem" className="justify-start flex-[1_1_auto] !ml-4">
              {isEditable && (
                <>
                  {canAdd && (
                    <>
                      <Button
                        label="Add Row"
                        glyph="add"
                        color="blue"
                        type="primary"
                        size={100}
                        onClick={addRow}
                        className="!min-w-[120px]"
                      />
                      {/* <Divider vertical className="!mx-3 !h-5 self-center" /> */}
                    </>
                  )}

                  {/* <Button label="Undo" glyph="undo" color="text" size={100} onClick={() => ref?.current?.hotInstance?.undo()} />
                  <Button label="Redo" glyph="redo" color="text" size={100} onClick={() => ref?.current?.hotInstance?.redo()} /> */}

                  {editActions && (
                    <>
                      <Divider vertical className="!mx-3 !h-5 self-center" />
                      {editActions}
                    </>
                  )}

                  {manualRowDelete && size(selectedRowIndexes) > 0 && (
                    <>
                      <Divider vertical className="!mx-3 !h-5 self-center" />

                      <Button
                        size={100}
                        label={`Delete ${countWord('Selected Row', size(selectedRowIndexes))}`}
                        glyph="delete"
                        color="text"
                        glyphColor="red"
                        onClick={() => setDeleteRowsDialogOpen(true)}
                      />
                    </>
                  )}

                  {/* {canDelete && (
                      <>
                        <Divider vertical className="!mx-3 !h-5 self-center" />
                        <Button
                          label="Delete Row"
                          glyph="delete"
                          color="text"
                          glyphColor="red"
                          type="default"
                          size={100}
                          onClick={removeRow}
                        />
                      </>
                    )} */}

                  {DEBUG && (
                    <>
                      <Divider vertical className="!mx-3 !h-5 self-center" />
                      <Button label="Get Data" glyph="table" color="text" size={100} onClick={() => setDebugData(getData())} />
                    </>
                  )}
                </>
              )}
            </Flex>

            {headerAfter && <div>{headerAfter}</div>}
          </div>
        )}

        <div className={clsx('relative z-[0] overflow-y-auto', rowsCount === 0 && 'hidden')}>
          <HotTable
            rowHeaders
            colHeaders
            autoWrapRow
            autoWrapCol
            ref={ref}
            data={initialData}
            readOnly={!isEditable}
            columnSorting={columnSorting}
            manualColumnMove={manualColumnMove}
            fixedColumnsStart={fixedColumnsStart}
            hiddenColumns={hiddenColumns}
            fixedRowsTop={0}
            height={height}
            autoRowSize={false}
            autoColumnSize={false}
            licenseKey={BH_HANDSONTABLE_KEY}
            rowHeights={30}
            className="htMiddle"
            minRows={minRows}
            beforeChange={(changes, source) => {
              if (!isEditable) return

              DEBUG && console.debug('beforeChange', { changes, source })

              if (size(changes) === 0 || source === 'edit') return

              for (const change of changes) {
                const [y, model, _, value] = change

                const column = columns.find((column) => column.model === model)

                if (!column) continue

                if (column.type === 'object_selector') {
                  try {
                    const parsed = JSON.parse(value)

                    if (parsed?.type !== column?.config?.dataType) {
                      DEBUG && console.debug('🚨 [Spreadsheet:beforeChange] Object data types do not match, change cancelled')
                      return false
                    }
                  } catch (error) {
                    DEBUG && console.debug('🚨 [Spreadsheet:beforeChange] Invalid JSON, change cancelled')
                    return false
                  }
                }

                if (column.type === 'multi_object_selector') {
                  try {
                    const parsed = JSON.parse(value)

                    for (const item of parsed) {
                      if (item?.type !== column?.config?.dataType) {
                        DEBUG && console.debug('🚨 [Spreadsheet:beforeChange] Object data types do not match, change cancelled')
                        return false
                      }
                    }
                  } catch (error) {
                    DEBUG && console.debug('🚨 [Spreadsheet:beforeChange] Invalid JSON, change cancelled')
                    return false
                  }
                }
              }
            }}
            afterChange={(changes, source) => {
              if (!isEditable) return

              if (onAfterChange) onAfterChange({ changes, source, spreadsheet: ref.current })

              if (size(changes) === 0) return

              ref.current.hotInstance.batchRender(() => {
                for (const change of changes) {
                  const [y, model, _, value] = change

                  const column = columns.find((column) => column.model === model)

                  if (!column) continue

                  if (column.onUpdate) {
                    column.onUpdate({
                      value,
                      y,
                      instance: ref.current.hotInstance,
                      get: (getModel) => ref.current.hotInstance.getDataAtRowProp(y, getModel),
                      set: (setModel, setValue) => ref.current.hotInstance.setDataAtRowProp(y, setModel, setValue),
                    })
                  }
                }
              })
            }}
            beforePaste={(data, coords) => {
              if (!isEditable) return

              DEBUG && console.debug('beforePaste', { data, coords })
              const startIndex = coords[0]?.startCol
              const endIndex = coords[0]?.endCol

              if (startIndex !== endIndex) return false
            }}
          >
            {columns.map((column: any, index) => {
              const align = column.align || 'left'
              const CONFIG = COLUMNS[column.type] || COLUMNS.text

              const type = NATIVE_CELL_TYPES.includes(column.type)
                ? column.type
                : NATIVE_CELL_TYPES.includes(CONFIG?.type)
                ? CONFIG.type
                : 'text'

              const Editor = column.editor || CONFIG?.editor
              const Renderer = column.renderer || CONFIG?.renderer
              const validatorFn = column.validator || CONFIG?.validator
              const validator = CONFIG.createValidator ? CONFIG.createValidator(column) : undefined
              const width = column.width || 200

              return (
                <HotColumn
                  key={`${column.model}-${index}`}
                  title={column.title}
                  width={width}
                  type={type}
                  data={column.model}
                  model={column.model}
                  readOnly={column.readOnly || CONFIG?.readOnly || !isEditable}
                  validator={validator}
                  headerClassName={align === 'center' ? 'htCenter' : align === 'right' ? 'htRight' : 'htLeft'}
                >
                  {Editor && (
                    <Editor
                      hot-editor
                      isEditable={isEditable}
                      width={width}
                      config={column.config || CONFIG?.config}
                      model={column.model}
                      timezone={props.timezone || settings.timezone}
                      readOnly={column.readOnly || CONFIG?.readOnly || !isEditable}
                      validator={validator}
                      onUpdate={column.onUpdate}
                    />
                  )}
                  {Renderer && (
                    <Renderer
                      hot-renderer
                      isEditable={isEditable}
                      width={width}
                      config={column.config || CONFIG?.config}
                      model={column.model}
                      timezone={props.timezone || settings.timezone}
                      readOnly={column.readOnly || CONFIG?.readOnly || !isEditable}
                      validator={validator}
                      className="RENDERER"
                      spreadsheet={ref.current}
                      selectedRows={manualRowDelete ? selectedRowIndexes : selectedRows} // TODO: update to use only selectedRowIndexes
                    />
                  )}
                </HotColumn>
              )
            })}
          </HotTable>
        </div>

        {rowsCount === 0 && <State isEmpty title={title} icon={icon} emptyDescription="No rows added yet" />}

        {DEBUG && debugData && (
          <div>
            <Json data={debugData} />
          </div>
        )}
      </Card>

      <DeleteDialog
        setOpen={deleteRowsDialogOpen}
        onClose={() => setDeleteRowsDialogOpen(false)}
        message="Are you sure you want to delete the selected rows?"
        onYes={deleteSelectedRows}
      />
    </>
  )
}

const STYLES = {
  thead: {
    th: {
      borderTop: 'none !important',
    },

    tr: {
      th: {
        borderRight: `1px solid ${COLORS.divider} !important`,
      },

      '&:first-child th': { borderTop: `none !important` },

      'th:nth-child(1)': {
        borderRight: `none !important`,
      },

      'th:nth-child(2)': {
        borderLeft: `1px solid ${COLORS.divider} !important`,
        borderRight: `1px solid ${COLORS.divider} !important`,
      },
    },
  },

  tbody: {
    tr: {
      'th:first-child': { borderBottom: `1px solid ${COLORS.divider} !important` },
      '&:first-child th': { borderTop: `1px solid ${COLORS.divider} !important` },
      '&:first-child td': { borderTop: `1px solid ${COLORS.divider} !important` },
    },
  },

  th: {
    fontWeight: 600,
    background: `${COLORS.hover} !important`,

    '&:first-child': {
      borderLeft: 'none !important',
    },
  },

  td: {
    position: 'relative',
    borderRight: `1px solid ${COLORS.divider} !important`,
    borderBottom: `1px solid ${COLORS.divider} !important`,

    '&:first-of-type': {
      borderLeft: `1px solid ${COLORS.divider} !important`,
    },
  },

  '.htDimmed': {
    background: `#fff !important`,
  },

  '&.is-editable': {
    '.htDimmed': {
      background: `#f7f8fa !important`,
    },
  },

  '.animated': {
    // transform: 'scale3d(1.03, 1.03, 1.03)',
    background: `${tint(0.8, COLORS.orange)} !important`,
    transition: 'background 100ms',
  },

  '.will-animate': {
    transition: 'background 100ms',
  },
}
