import React from 'react'

import { minutesBetween, titleCase } from '../../utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import LocationCheckStatus from '../../components/Statuses/LocationCheckStatus'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { ProfileCellInner } from '../../components/DataTable/cells/ProfileCell'

import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

export const LocationCheckinsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 140,
        id: 'category',
        model: 'category',
        title: 'Category',
        formatValue: ({ value }: any) => titleCase(value),
      },
      {
        width: 180,
        id: 'resident',
        model: 'resident',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 140,
        id: 'location',
        model: 'location.name',
        title: 'Location',
        formatValue: ({ value }: any) => titleCase(value),
      },
      {
        width: 180,
        id: 'address_full',
        model: 'address_full',
        title: 'Address',
      },
      {
        title: 'Bed Assignment at Check-In',
        id: 'bed_assignment_at_checkin_full',
        model: 'bed_assignment_at_checkin.place',
        width: 300,
        hoverExpand: false,
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          const avatar = value?.house?.avatar
          const houseName = value?.house?.name
          const floorName = value?.floor?.name
          const roomName = value?.room?.name

          return <ProfileCellInner value={`${houseName}, ${floorName}, ${roomName}`} avatar={avatar || ''} />
        },
      },
      {
        title: 'Program Assignment at Check-In',
        id: 'program_assignment_at_checkin_full',
        model: 'program_assignment_at_checkin.place',
        width: 300,
        hoverExpand: false,
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          const avatar = value?.program?.avatar
          const programName = value?.program?.name
          const phaseName = value?.phase?.name
          const seatName = value?.name

          return <ProfileCellInner value={`${programName}, ${phaseName}, ${seatName}`} avatar={avatar || ''} />
        },
      },
      {
        width: 160,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <LocationCheckStatus status={value} />,
        // Filter: TableFilter.Select,
        // filterOptions: [
        //   {
        //     value: 'in',
        //     label: 'Checked-In',
        //   },
        //   {
        //     value: 'out',
        //     label: 'Checked-Out',
        //   },
        //   {
        //     value: 'late',
        //     label: 'Late Checkout',
        //   },
        // ],
      },
      {
        width: 200,
        id: 'checkin_at',
        model: 'checkin_at',
        title: 'Check-in Time',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'checkout_at',
        model: 'checkout_at',
        title: 'Check-out Time',
        type: 'date_time',
      },
      {
        width: 120,
        id: 'duration',
        model: 'duration',
        title: 'Duration',
        formatValue: ({ value }: int) => `${value || '–'} minutes`,
      },
      {
        width: 200,
        id: 'notes',
        model: 'notes',
        title: 'Notes',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Location Check-In's"
      icon="recovery_navigation"
      columns={columns}
      filtersConfig={FILTERS.live_reports.location_checks}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="location_checks"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=location_checks'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'location_checks' }} />
        </>
      }
      {...props}
    />
  )
}
