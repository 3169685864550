import React from 'react'
import isUndefined from 'lodash/isUndefined'

import Flex from '../../Flex'
import Divider from '../../Divider'

import { FilterDropdown } from './FilterDropdown'
import { PopoverItem } from './PopoverItem'

import { useStore } from '../useStore'

export const BooleanFilter = (props: any) => {
  const { config, filter, filterKey, onUpdate } = props

  const [isOpen, setIsOpen] = React.useState(false)
  const removeFilter: any = useStore((state: any) => state.removeFilter)

  const trueLabel = config?.trueLabel || 'Yes'
  const falseLabel = config?.falseLabel || 'No'

  const [value, setValue]: any = React.useState(filter?.value)

  const applyFilter = (newValue?: boolean) => {
    if (newValue !== filter?.value) {
      onUpdate?.({ value: newValue, condition: 'eq' })
    }

    setValue(newValue)
    setIsOpen(false)
  }

  const handleClear = () => {
    setValue()
    removeFilter(filterKey)
    setIsOpen(false)
  }

  React.useEffect(() => {
    if (value !== filter?.value) setValue(filter?.value)
  }, [filter?.value])

  return (
    <FilterDropdown
      testKey="boolean_filter_dropdown"
      open={isOpen}
      onOpenChange={setIsOpen}
      label={value === true ? trueLabel : value === false ? falseLabel : 'Select…'}
      hasActiveFilters={!isUndefined(value)}
    >
      <PopoverItem
        title={trueLabel}
        isActive={value === true}
        onClick={() => {
          applyFilter(true)
        }}
      />
      <PopoverItem
        title={falseLabel}
        isActive={value === false}
        onClick={() => {
          applyFilter(false)
        }}
      />
      <Divider />
      <PopoverItem
        title="Unset"
        // isActive={value === undefined}
        onClick={() => {
          handleClear()
        }}
      />
    </FilterDropdown>
  )

  return (
    <>
      <Flex centerY gap="0.25rem">
        <label css={STYLES.label}>
          <input
            data-test="yes_radio_button"
            type="radio"
            css={STYLES.input}
            checked={value === true}
            onChange={() => {
              setValue(true)
            }}
          />
          {trueLabel}
        </label>

        <label css={STYLES.label}>
          <input
            data-test="no_radio_button"
            type="radio"
            css={STYLES.input}
            checked={value === false}
            onChange={() => {
              setValue(false)
            }}
          />
          {falseLabel}
        </label>
      </Flex>
    </>
  )
}

const STYLES = {
  segment: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0 0.2rem',
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0 0.3rem',
    cursor: 'pointer',
    fontWeight: 500,

    input: {
      marginRight: '0.2rem',
      outline: 'none',
    },
  },

  input: {
    // display: 'none',
  },
}
