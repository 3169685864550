import React from 'react'

import DataFormStatus from '../../components/Statuses/DataFormStatus'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { getResourceLink } from '../../utils/functions'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'
import ReportRefetchButton from '../../components/Buttons/ReportRefetchButton'

export const ProgressNotesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 260,
        id: 'client',
        model: 'client',
        title: 'Client',
        formatValue: ({ data, value }: any) => {
          if (!value) return '-'

          return <MainCell id={value.id} value={value.name} avatar={value.avatar} to={getResourceLink?.(value)} />
        },
      },
      {
        width: 180,
        id: 'supervisor',
        model: 'supervisor',
        title: 'Supervisor',
        type: 'profile',
      },
      {
        width: 140,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        width: 200,
        id: 'created_at',
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Edited',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Progress Notes"
      icon="progress_reviews"
      columns={columns}
      filtersConfig={FILTERS.live_reports.data_forms}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="progress_notes"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=progress_notes'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'progress_notes' }} />
        </>
      }
      {...props}
    />
  )
}
