import React from 'react'
import size from 'lodash/size'

import Divider from '../../../components/Divider'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Markup from '../../../components/Markup'
import Section from '../../../components/Section'
import State from '../../../components/State'

import { CATEGORIES, SUBCATEGORIES } from '../forms'
import { CustomQuestion } from './CustomQuestion'
import { QuestionRenderer } from './QuestionRenderer'

export const AuthorityFormPreview = ({ schema, settings, authorityName, isEvaluatorOnly, isInfoVisible }: any) => {
  const previewForm = React.useRef(null)

  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])

  if (isLoading || !schema) return <State isLoading />

  return (
    <Form isCompact getForm={previewForm} className="[--field-max-width:100%] [&_label]:!whitespace-pre-line">
      {Object.keys(schema).map((categoryId: any, index) => {
        const section = schema[categoryId]
        const category = CATEGORIES[categoryId]
        const sectionSettings = settings?.[categoryId]

        let allSubsectionHidden = true
        let allQuestionsHidden = true

        for (const subcategoryId in sectionSettings) {
          if (sectionSettings[subcategoryId].show) {
            allSubsectionHidden = false
          } else {
            continue
          }

          const fields = sectionSettings[subcategoryId]?.fields

          if (!fields) continue

          for (const identifier in fields) {
            if (fields[identifier].show) {
              allQuestionsHidden = false
              break
            }
          }
        }

        if (allSubsectionHidden || allQuestionsHidden) return null

        return (
          <React.Fragment key={categoryId}>
            <Section title={category?.name} headingType="h2">
              <div className="grid gap-5 grid-cols-1">
                {Object.keys(section).map((subcategoryId: any) => {
                  const fields = section[subcategoryId]?.fields
                  if (size(fields) === 0) return null

                  const questions = Object.values(fields).map((field: any) => field.data)
                  const model = `${categoryId}.${subcategoryId}`
                  const subcategory = SUBCATEGORIES[subcategoryId]
                  const sectionSettings = settings?.[categoryId]?.[subcategoryId]

                  if (!questions || !sectionSettings || !sectionSettings.show) return null

                  return (
                    <FormSection key={model}>
                      <h3 id={subcategoryId}>{subcategory?.name || 'No Subcategory'}</h3>

                      {sectionSettings?.custom_text_before && <Markup value={sectionSettings?.custom_text_before} />}

                      {sectionSettings?.custom_questions_before?.map?.((question: any) => {
                        return <CustomQuestion key={question._id} question={question} sectionModel={section.model} />
                      })}

                      {questions?.map?.((question) => {
                        const fieldSettings = sectionSettings?.fields?.[question.identifier]

                        if (!fieldSettings?.show) return null

                        return (
                          <QuestionRenderer
                            isEditable
                            key={question.identifier}
                            authorityName={authorityName}
                            isEvaluatorOnly={isEvaluatorOnly}
                            question={question}
                            validations={fieldSettings?.required ? { presence: { message: 'This field is required' } } : undefined}
                            isInfoVisible={isInfoVisible}
                          />
                        )
                      })}

                      {sectionSettings?.custom_questions_after?.map?.((question: any) => {
                        return <CustomQuestion key={question._id} question={question} sectionModel={section.model} />
                      })}
                    </FormSection>
                  )
                })}
              </div>
            </Section>

            {index > 0 && <Divider />}
          </React.Fragment>
        )
      })}
    </Form>
  )
}
