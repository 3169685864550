import React from 'react'
import size from 'lodash/size'

import { countWord } from '@behavehealth/utils/functions'
import { Spreadsheet } from '@behavehealth/components/Spreadsheet/Spreadsheet'
import { useCreate } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Notifications from '@behavehealth/modules/notifications'
import withSettings from '@behavehealth/hocs/withSettings'

import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import Status from '@behavehealth/components/Status'
import Card from '@behavehealth/components/Card'

import { AllClientsTable } from '@behavehealth/components/Forms/Selectors/tables/AllClientsTable'
import { EmployeesTable } from '@behavehealth/components/Forms/Selectors/tables/EmployeesTable'
import { ShiftNoteStatus } from '@behavehealth/constructs/ShiftNotes/ShiftNoteStatus'
import { useSpreadsheet } from '@behavehealth/components/Spreadsheet/useSpreadsheet'

const KitchenSink = () => {
  return (
    <div className="grid gap-5 p-5 content-start">
      <ShiftNotes />
    </div>
  )
}

const ShiftNotes = () => {
  const columns = React.useMemo(() => {
    return [
      {
        title: 'Text',
        model: 'text',
        width: 200,
        onUpdate: ({ value, y, set }) => {
          set('text_2', value + ' ' + value)
          set('amount', value.length)
        },
        config: {
          validations: {
            presence: {
              message: 'Please type something',
            },
            length: {
              minimum: 3,
              message: 'Must be at least 3 characters',
            },
          },
        },
      },
      {
        title: 'Text 2',
        model: 'text_2',
        width: 200,
        config: {
          validations: {
            presence: {
              message: 'Please type something',
            },
            length: {
              minimum: 3,
              message: 'Must be at least 3 characters',
            },
          },
        },
      },
      {
        title: 'Amount',
        model: 'amount',
        type: 'amount',
        width: 200,
        config: {
          validations: {
            presence: {
              message: 'Please enter an amount',
            },
            lessThan: {
              value: 10,
              message: 'Amount must be less than $10',
            },
          },
        },
      },
      {
        title: 'Checkbox',
        model: 'checkbox',
        type: 'checkbox',
        width: 200,
        // config: {},
      },
      {
        title: 'Category',
        model: 'category',
        width: 200,
        type: 'select',
        config: {
          options: [
            { label: 'General Note', value: 'general_note' },
            { label: 'Incident Report', value: 'incident_report' },
          ],
          render: (value: any) => {
            if (!value) return null

            return <Status small label={value?.replace?.('_', ' ')} color={value === 'incident_report' ? 'red' : 'blue'} />
          },
        },
      },
      {
        title: 'Status',
        model: 'status',
        width: 200,
        type: 'select',
        config: {
          options: [
            { label: 'Draft', value: 'draft' },
            { label: 'Pending Review', value: 'pending_review' },
            { label: 'Updated Required', value: 'updates_required' },
            { label: 'Signed Off', value: 'signed_off' },
            { label: 'Completed', value: 'completed' },
            { label: 'Closed', value: 'closed' },
          ],
          render: (value: any) => <ShiftNoteStatus small status={value} />,
        },
      },
      {
        title: 'Note',
        model: 'description',
        type: 'rich_text',
        width: 200,
      },
      {
        title: 'Date & Time',
        model: 'dated_at',
        type: 'date_time',
        width: 200,
      },
      {
        title: 'Staff',
        model: 'employees',
        width: 200,
        type: 'multi_object_selector',
        config: {
          endpoint: '/employees',
          queryKey: 'employees',
          dataType: 'employee',
          queryParams: { status: 'active' },
          tableSelector: EmployeesTable,
          selectTitle: (data: any) => data?.name,
          selectDescription: (data: any) => data?.name,
          selectAvatar: (data: any) => data?.name,
        },
      },
      {
        title: 'Clients',
        model: 'residents',
        width: 200,
        type: 'multi_object_selector',
        config: {
          endpoint: '/residents',
          queryKey: 'residents',
          dataType: 'resident',
          queryParams: { status: 'current' },
          tableSelector: AllClientsTable,
          selectTitle: (data: any) => data?.name,
          selectDescription: (data: any) => data?.name,
          selectAvatar: (data: any) => data?.name,
        },
      },
      {
        title: 'Location',
        model: 'house',
        width: 200,
        type: 'object_selector',
        config: {
          endpoint: '/houses',
          queryKey: 'locations',
          dataType: 'house',
          queryParams: { category: 'housing,mixed_category' },
          selectTitle: (data: any) => data.name,
        },
      },
      {
        title: 'Organization',
        model: 'organization',
        width: 200,
        type: 'object_selector',
        config: {
          endpoint: '/organizations',
          queryKey: 'organizations',
          dataType: 'organization',
          selectTitle: (data: any) => data.name,
        },
      },
    ]
  }, [])

  const { spreadsheet, getData, getSpreadsheet, clear, isEditable, edit, cancel } = useSpreadsheet({
    columns,
  })

  const { mutateAsync: save, isLoading: isSaving }: any = useCreate({
    name: ['create-shift-notes'],
    url: `/housing_notes/batch`,
    invalidate: 'shift-notes-demo',
    onSuccess: () => {
      Notifications.send(`Successfully created ${countWord('Shift Notes', size(getData()))}`, 'positive')
    },
  })

  const handleSave = async () => {
    const data = getData()

    await save(data)

    cancel()
    clear()
  }

  const handleRandom = async () => {
    const data = getData()

    spreadsheet.hotInstance.batchRender(() => {
      for (let i = 0; i < data.length; i++) {
        spreadsheet.hotInstance.setDataAtCell(i, 0, `${(Math.random() * 1000).toFixed(2)}`)
      }
    })
  }

  return (
    <div className="p-4">
      <Card className="p-2 mb-4">
        <Button
          label="Random Data"
          type="default"
          glyph="reset"
          size={200}
          onClick={handleRandom}
          isDisabled={!isEditable}
          display="inline-flex"
        />
      </Card>

      <Spreadsheet
        title="Shift Notes"
        icon="housing_notes"
        isEditable={isEditable}
        getSpreadsheet={getSpreadsheet}
        columns={columns}
        getData={getData}
        headerAfter={
          isEditable ? (
            <Flex nowrap gap="0.25rem" className="flex flex-nowrap items-center w-full justify-end">
              <Button label="Cancel" type="default" glyph="cross" size={200} onClick={cancel} isDisabled={isSaving} />
              <Button
                label="Save"
                type="primary"
                glyph="tick_circle"
                color="green"
                size={200}
                className="ml-1.5 !min-w-[120px]"
                onClick={handleSave}
                isLoading={isSaving}
              />
            </Flex>
          ) : (
            <Button label="Edit" glyph="edit" type="primary" size={200} onClick={edit} className="!min-w-[120px]" />
          )
        }
      />
    </div>
  )
}

export default withPageError(withSettings(KitchenSink))
