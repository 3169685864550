import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import AmountInput from '../../../components/Forms/AmountInput'
import Avatar from '../../../components/Avatar'
import Card from '../../../components/Card'
import State from '../../../components/State'
import CardTreeItem from '../../../components/CardTreeItem'
import DateInput from '../../../components/Forms/DateInput'
import Flex from '../../../components/Flex'
import Input from '../../../components/Forms/Input'
import TreeItem from '../../../components/TreeItem'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'

import { ERAServiceLinesTable } from './ERAServiceLinesTable'
import { SectionCardTitle } from '../../../components/SectionCard'

export const ERAClaims = ({ asCard, data }: any) => {
  const isEmpty = size(data) === 0

  const Tag = asCard ? CardTreeItem : TreeItem
  const EmptyWrapper = asCard ? Card : React.Fragment

  if (isEmpty) {
    return (
      <EmptyWrapper>
        <State isEmpty icon="accounting" title="Claims" emptyDescription="No claims available to display" minHeight={180} />
      </EmptyWrapper>
    )
  }

  return (
    <>
      {data?.map((eraClaim: any) => (
        <Tag
          isOpen
          key={eraClaim?.id}
          shouldIndent={false}
          className={clsx(!asCard && '[&+&]:border-t border-solid border-0 border-divider')}
          title={
            <div>
              <Flex centerY gap="0.75rem">
                <div>{eraClaim?.identifier}</div>

                {eraClaim?.client && (
                  <div className="flex items-center text-text">
                    <div className="flex items-center flex-nowrap">
                      <Avatar src={eraClaim?.client?.avatar} initials={eraClaim?.client?.name} size={20} className="mr-1" />
                      <span className="font-[600]">{eraClaim?.client?.name}</span>
                      <span className="text-text-muted ml-1.5 text-[0.9em] font-[400]">(#{eraClaim?.client?.behave_id})</span>
                    </div>
                  </div>
                )}
              </Flex>
            </div>
          }
        >
          <div className="px-3 pt-2 pb-4">
            <SectionCardTitle title="Claim Info" />
            <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2 mq1024:grid-cols-3 mq1440:grid-cols-4">
              <Input isEditable={false} label="ID" value={eraClaim?.identifier} />              
              <Input isEditable={false} label="Payer Claim Number" value={eraClaim?.payer_icn} />

              <Input
                isEditable={false}
                label="Claim"
                value={eraClaim?.insurance_claim?.identifier ? `${eraClaim?.insurance_claim?.identifier} →` : '–'}
                link={`/claims/claims/all/${eraClaim?.insurance_claim?.id}/form`}
              />
              <Input
                isEditable={false}
                label="Client"
                value={eraClaim?.client?.name ? `${eraClaim?.client?.name} →` : '–'}
                link={`/clients/${eraClaim?.client?.id}/general-info`}
              />
              <Input
                isEditable={false}
                label="Insurance Policy"
                value={
                  eraClaim?.insurance_policy?.insurance_local_payer?.name
                    ? `${eraClaim?.insurance_policy?.member_id} (${eraClaim?.insurance_policy?.insurance_local_payer?.name}) →`
                    : eraClaim?.insurance_policy?.member_id
                }
                link={`/clients/${eraClaim?.client?.id}/insurance/${eraClaim?.insurance_policy?.id}`}
              />

              <Input isEditable={false} label="Status Code" value={eraClaim?.status_code} />

              <DateInput isEditable={false} label="From" value={eraClaim?.from_date} />
              <DateInput isEditable={false} label="Until" value={eraClaim?.until_date} />

              <AmountInput isEditable={false} label="Total Charge" value={eraClaim?.total_charge} />
              <AmountInput isEditable={false} label="Total Paid" value={eraClaim?.total_paid} />
              <AmountInput isEditable={false} label="Patient Responsibility" value={eraClaim?.patient_responsibility || 0} />
            </div>
          </div>

          <ERAServiceLinesTable mainHeaderHidden data={eraClaim?.insurance_era_service_lines} />
        </Tag>
      ))}
    </>
  )
}
