import React from 'react'
import { keyframes } from '@emotion/react'
import { saturate, transparentize, tint } from 'polished'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { COLORS } from '../../theme'
import { useAppSidebarStore } from '../../hooks/useAppSidebarStore'
import { useSettings } from '../../hooks/useSettings'
import { usePermissions } from '../../hooks/usePermissions'

import withPermissions from '../../hocs/withPermissions'

import { MeetingContext } from '../Meeting/context'
import DropdownItem from '../DropdownItem'
import Glyph from '../Glyph'
import Icon from '../Icon'

export const AppSidebarNavItems: React.FC<any> = ({ checkVisibility = () => {}, asDropdown }) => {
  const { activeTab, open } = useAppSidebarStore()
  const { isMeetingActive } = React.useContext(MeetingContext)
  const { isBehave } = useSettings()

  const { allowed: hasRCFFlag } = usePermissions({ featureFlag: 'recovery_capital_forms' })
  const { allowed: hasRCFDefaultFlag } = usePermissions({ featureFlag: 'recovery_capital_forms_default_form' })
  const { allowed: hasRCFDrugCourtFlag } = usePermissions({ featureFlag: 'recovery_capital_forms_drug_court' })
  const { allowed: hasRCFPortalFlag } = usePermissions({ featureFlag: 'client_portal_recovery_capital_forms' })

  const Tag = asDropdown ? DropdownItem : SidebarNavItem
  const history = useHistory()

  return (
    <>
      {/* <Tag
        icon="search"
        label="Search"
        isActive={activeTab === 'search'}
        className="search"
        onClick={() => open('search')}
        isVisible={checkVisibility}
      /> */}

      <Tag
        icon="tasks"
        label="To-Do"
        isActive={activeTab === 'todo'}
        className="todo"
        onClick={() => {
          open('todo')
          history.push(`?sidebar=todo`)
        }}
        isVisible={checkVisibility}
        permission="todos.view"
        featureFlagV2="todos"
      />

      <Tag
        icon="calendar"
        label="Calendar"
        isActive={activeTab === 'calendar'}
        className="calendar"
        onClick={() => {
          open('calendar')
          history.push(`?sidebar=calendar`)
        }}
        isVisible={checkVisibility}
        permission="events.view"
        featureFlagV2="calendar"
      />

      {/* <Tag
        icon="video_call"
        label="Meeting Rooms"
        isActive={activeTab === 'meeting'}
        className={`meeting ${isMeetingActive ? 'is-call-active' : ''}`}
        onClick={() => {
          open('meeting')
          history.push(`?sidebar=meeting`)
        }}
        isVisible={checkVisibility}
        permission="telehealth.view"
        featureFlagV2="telehealth"
      /> */}

      <Tag
        icon="startup"
        label="Onboarding"
        isActive={activeTab === 'onboarding'}
        className="help_center"
        onClick={() => {
          open('onboarding')
          history.push(`?onboarding=onboarding_home`)
        }}
        featureFlagV2="onboarding"
      />

      <Tag
        icon="help_center"
        label="Help Center"
        isActive={activeTab === 'help_center'}
        className="help_center"
        onClick={() => {
          open('help_center')
          history.push(`?guide=help_center_home`)
        }}
      />

      <Tag
        icon="insurance"
        label="RCM Help Center"
        isActive={activeTab === 'rcm_help_center'}
        className="rcm_help_center"
        onClick={() => {
          open('rcm_help_center')
        }}
        featureFlagV2="insurance_help_center"
      />

      {isBehave && (
        <Tag
          icon="behave_health"
          label="Internal Center"
          isActive={activeTab === 'internal_center'}
          className="internal_center"
          onClick={() => {
            open('internal_center')
            history.push(`?internal=internal_center_home`)
          }}
        />
      )}

      <Tag
        icon="narr"
        label="NARR Center"
        isActive={activeTab === 'narr_center'}
        className="narr_center"
        onClick={() => {
          open('narr_center')
          history.push(`?narr=narr_center_home`)
        }}
        featureFlagV2="narr"
      />

      {(hasRCFFlag || hasRCFDefaultFlag || hasRCFDrugCourtFlag || hasRCFPortalFlag) && (
        <Tag
          icon="roi"
          label="Recovery Outcomes Institute Knowledge Center"
          isActive={activeTab === 'roi_center'}
          className="roi_center"
          onClick={() => {
            open('roi_center')
            history.push(`?roi=roi_center_home`)
          }}
        />
      )}

      {isBehave && (
        <Tag
          icon="behave_health"
          label="Admin Help Center"
          isActive={activeTab === 'admin_help_center'}
          className="admin_help_center"
          onClick={() => {
            open('admin_help_center')
            history.push(`?internal=admin_help_center_home`)
          }}
        />
      )}

      {/* <Tag
        icon="bed_management"
        label="Bed Management"
        isActive={activeTab === 'bed_dashboard'}
        className="bed_dashboard"
        onClick={() => {
          open('bed_dashboard')
          history.push(`?sidebar=bed_dashboard`)
        }}
        featureFlagV2="bed_dashboard"
      /> */}

      <Tag
        icon="bookmarks"
        label="Bookmarks"
        isActive={activeTab === 'bookmarks'}
        className="bookmarks"
        featureFlagV2="bookmarks"
        onClick={() => {
          open('bookmarks')
          history.push(`?sidebar=bookmarks`)
        }}
      />

      <Tag
        icon="sticky_notes"
        label="Sticky Notes"
        isActive={activeTab === 'sticky_notes'}
        className="sticky_notes"
        featureFlagV2="sticky_notes"
        onClick={() => {
          open('sticky_notes')
          history.push(`?sidebar=sticky_notes`)
        }}
      />

      <Tag
        icon="pins"
        label="Pins"
        isActive={activeTab === 'pins'}
        className="pins"
        featureFlagV2="client_pins"
        onClick={() => {
          open('pins')
          history.push(`?sidebar=pins`)
        }}
      />

      <Tag
        icon="quick_text"
        label="Quick Text"
        isActive={activeTab === 'quick_text'}
        className="quick_text"
        featureFlagV2="quick_text"
        onClick={() => {
          open('quick_text')
          history.push(`?sidebar=quick_text`)
        }}
      />

      <Tag
        icon="ai_assistant"
        label="AI Assistant"
        isActive={activeTab === 'ai_assistant'}
        className="ai_assistant"
        featureFlagV2="behave_ai"
        onClick={() => {
          open('ai_assistant')
          history.push(`?sidebar=ai_assistant`)
        }}
      />

      <Tag
        icon="microphone"
        glyphColor="red"
        label="Voice Memos"
        isActive={activeTab === 'voice_memos'}
        className="voice_memos"
        featureFlagV2="voice_memos"
        onClick={() => {
          open('voice_memos')
          history.push(`?sidebar=voice_memos`)
        }}
      />
    </>
  )
}

const SidebarNavItem = withPermissions(({ icon, glyph, glyphColor, className, isActive, onClick, testKey }: any) => {
  const classNames = clsx({
    'is-active': isActive,
    [className]: className,
  })

  return (
    <div data-test={testKey} css={styles.navItem} className={classNames} onClick={onClick}>
      {icon && <Icon icon={icon} size={22} />}
      {glyph && <Glyph glyph={glyph} size={22} color={glyphColor} />}
    </div>
  )
})

const pulseAnimation = (color: string) => {
  return keyframes({
    '0%': {
      transform: 'scale(0.9)',
      boxShadow: `0 0 0 0 ${transparentize(0.3, color)}`,
    },

    '70%': {
      transform: 'scale(1.2)',
      boxShadow: `0 0 0 7px ${transparentize(1, color)}`,
    },

    '100%': {
      transform: 'scale(0.9)',
      boxShadow: `0 0 0 0 ${transparentize(1, color)}`,
    },
  })
}

const styles = {
  navItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 100,
    cursor: 'pointer',

    img: {
      transition: 'transform 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    },

    '&:hover': {
      img: {
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
    },

    '&.todo.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.green)),
    },

    '&.calendar.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.red)),
    },

    '&.meeting.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.vividBlue)),
    },

    '&.meeting.is-call-active': {
      position: 'relative',

      '&::after': {
        content: '""',
        position: 'absolute',
        width: 28,
        height: 28,
        borderRadius: '50%',
        animation: `${pulseAnimation(COLORS.blue)} 2s infinite`,
      },

      '&.is-active::after': {
        width: '100%',
        height: '100%',
      },
    },

    '&.help_center.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.vividBlue)),
    },

    '&.rcm_help_center.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.green)),
    },

    '&.internal_center.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.violet)),
    },

    '&.narr_center.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.narr)),
    },

    '&.roi_center.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.roi)),
    },

    '&.admin_help_center.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.violet)),
    },

    '&.bed_dashboard.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.violet)),
    },

    '&.bookmarks.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.blue)),
    },

    '&.sticky_notes.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.gold)),
    },

    '&.pins.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.violet)),
    },

    '&.quick_text.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.orange)),
    },

    '&.ai_assistant.is-active': {
      background: saturate(0.16, tint(0.72, COLORS.gray)),
    },

    '&.voice_memos.is-active': {
      background: saturate(0.16, tint(0.85, COLORS.green)),
    },
  },
}
