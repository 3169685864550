import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { COLORS } from '@behavehealth/theme'
import { countWord, getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useGet, useCreate } from '@behavehealth/hooks/useNewAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Button from '@behavehealth/components/Button'
import ClientStatus from '@behavehealth/components/Statuses/ClientStatus'
import ConfirmDialog from '@behavehealth/components/Dialogs/ConfirmDialog'
import Page from '@behavehealth/components/Page'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'
import Alert from '@behavehealth/components/Alert'
import Flex from '@behavehealth/components/Flex'
import Loader from '@behavehealth/components/Loader'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import { BatchEligibilityBuilderOverlay } from '@behavehealth/constructs/Eligibility/BatchEligibilityBuilderOverlay'
import { EligibilityBuilderOverlay } from '@behavehealth/constructs/Eligibility/EligibilityBuilderOverlay'
import { EligibilityDataTable } from '@behavehealth/constructs/Eligibility/EligibilityDataTable'
import { EligibilityOverlay } from '@behavehealth/constructs/Eligibility/EligibilityOverlay'

const pageConfig = {
  feature: 'eligibility',
}

const RootEligibility: React.FC = () => {
  const match = useRouteMatch()

  return (
    <Page
      useWrapper={false}
      breakpoint="0"
      {...pageConfig}
      actions={
        <>
          <Button type="primary" glyph="add" label="Check Eligibility" permission="eligibility.create" link={`${match.url}/checks/new`} />
        </>
      }
    >
      <Tabs>
        <Tabs.List className="px-4 bg-white">
          <Tabs.Item label="Eligibility Checks" icon="eligibility" to={`${match.url}/checks`} permission="eligibility.view" />
          {/* <Tabs.Item label="Clients" icon="clients" to={`${match.url}/clients`} permission="clients.view" /> */}
        </Tabs.List>
      </Tabs>

      <Switch>
        <Route path={`${match.url}/checks`} component={EligibilityChecks} />
        <Route path={`${match.url}/clients`} component={ClientsEligibility} />

        <Redirect exact from={match.url} to={`${match.url}/checks`} />
      </Switch>
    </Page>
  )
}

const EligibilityChecks = () => {
  const match = useRouteMatch()

  const tableProps = useDataTable({
    name: ['insurance_new_evobs'],
    endpoint: `/insurance_new_evobs`,
    localStorageKey: 'insurance_new_evobs_v1',
  })

  const { mutateAsync: rerunEvobs, isLoading: isRerunning } = useCreate({
    name: ['insurance_new_evobs', 'rerun'],
    url: '/insurance_new_evobs/rerun',
    invalidate: tableProps.queryKey,
  })

  const [shouldCheckStatus, setShouldCheckStatus] = React.useState(true)
  const [wasProcessing, setWasProcessing] = React.useState(false)

  const { data: statusData }: any = useGet({
    name: ['insurance_new_evobs', 'status'],
    url: `/insurance_new_evobs/status`,
    options: { refetchInterval: 30 * 1000, refetchOnMount: true, staleTime: 0, enabled: shouldCheckStatus },
    onSuccess: (success: any) => {
      if (wasProcessing && success?.data?.status === null) {
        tableProps.refetch()
        setWasProcessing(false)
      }
    },
  })

  React.useEffect(() => {
    if (statusData?.status === null) {
      setShouldCheckStatus(false)
    } else {
      setWasProcessing(true)
      setShouldCheckStatus(true)
    }
  }, [statusData])

  return (
    <>
      <div className="p-4">
        <EligibilityDataTable
          canBatchSelect
          {...tableProps}
          to={({ id }) => `${match.url}/${id}`}
          headerAfter={
            statusData?.status === 'processing' && (
              <Alert small type="warning">
                <Flex nowrap centerY gap="0.5rem">
                  <Loader size={16} color={COLORS.orange} />
                  <div>Refreshing eligibility checks</div>
                </Flex>
              </Alert>
            )
          }
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'eligibility.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          renderBatchActions={({ selectedRows, selectNone }: any) => (
            <ConfirmDialog
              title={`Re-Run ${countWord('Eligibility Checks', size(selectedRows))}?`}
              message={`Are you sure you want to re-run the selected ${countWord('eligibility checks', size(selectedRows))}?`}
              yesColor="green"
              onYes={async () => {
                try {
                  await rerunEvobs({ evob_ids: selectedRows.map((o: any) => o.id) })
                } catch (e) {
                  console.error(e)
                } finally {
                  selectNone()
                }
              }}
              isDisabled={selectedRows.length === 0}
            >
              <Button
                label={`Re-Run ${countWord('Eligibility Checks', size(selectedRows))}`}
                glyph="multi_select"
                type="primary"
                size={100}
                isDisabled={selectedRows.length === 0}
                className="mr-3"
                isLoading={isRerunning}
              />
            </ConfirmDialog>
          )}
        />
      </div>

      <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
        <Route path={`${match.url}/new`}>
          <EligibilityBuilderOverlay
            onSaveSuccessful={() => {
              setShouldCheckStatus(true)
            }}
          />
        </Route>

        <Route path={`${match.url}/:id`} component={EligibilityOverlay} />
      </AnimatedSwitch>
    </>
  )
}

const ClientsEligibility = () => {
  const match = useRouteMatch()

  return (
    <>
      <Tabs>
        <Tabs.List className="px-4">
          <Tabs.Item label="Can Run Eligibility" name="ready" to={`${match.url}/ready`} />
          <Tabs.Item label="Insurance Data Missing" name="unready" to={`${match.url}/unready`} />
        </Tabs.List>
      </Tabs>

      <Switch>
        <Route path={`${match.url}/ready`} component={ReadyClientsTable} />
        <Route path={`${match.url}/unready`} component={UnreadyClientsTable} />

        <Redirect exact from={match.url} to={`${match.url}/ready`} />
      </Switch>
    </>
  )
}

const ReadyClientsTable = () => {
  const tableProps = useDataTable({
    name: ['clients'],
    endpoint: `/residents`,
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        isSticky: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} avatar={data.avatar} to={getClientLink(data)} value={value} />,
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <ClientStatus small status={value} />,
      },
      {
        title: 'Last Eligibility Check Date',
        model: 'last_evob_checked_at',
        type: 'date_time',
      },
      {
        title: 'Last Eligibility Check',
        model: 'last_evob_checked',
        formatValue: ({ data }: any) => <LinkCell value="Open Eligibility Check →" icon="eligibility" to={`/eligibility/checks`} />,
      },
      {
        title: 'Primary Insurance Policy',
        model: 'primary_insurance',
        formatValue: ({ data }: any) => (
          <LinkCell value="Open Primary Insurance →" icon="eligibility" to={`${getClientLink(data)}/insurance`} />
        ),
      },
      {
        title: 'Primary Insurance Policy Payer',
        model: 'primary_insurance.insurance_local_payer.name',
        width: 280,
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <div className="p-4">
      <DataTable
        asCard
        canBatchSelect
        title="Clients"
        icon="clients"
        columns={columns}
        {...tableProps}
        renderBatchActions={({ selectedRows, selectNone }: any) => (
          <SummonOverlay overlay={<BatchEligibilityBuilderOverlay selectedClients={selectedRows} />}>
            <Button
              label="Check Eligibility"
              glyph="multi_select"
              type="primary"
              size={100}
              onClick={selectNone}
              isDisabled={selectedRows.length === 0}
            />
          </SummonOverlay>
        )}
      />
    </div>
  )
}

const UnreadyClientsTable = () => {
  const tableProps = useDataTable({
    name: ['clients'],
    endpoint: `/residents`,
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        isSticky: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} avatar={data.avatar} to={getClientLink(data)} value={value} />,
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <ClientStatus small status={value} />,
      },
      {
        title: 'Primary Insurance Policy',
        id: 'add_insurance_policy',
        hoverExpand: false,
        formatValue: ({ data }: any) => (
          <Button label="Add Insurance Policy" glyph="add" size={100} display="inline-flex" link={`${getClientLink(data)}/insurance/new`} />
        ),
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <div className="p-4">
      <DataTable asCard title="Clients" icon="clients" columns={columns} {...tableProps} />
    </div>
  )
}

export const Eligibility = withPageError(withMarketing(RootEligibility, pageConfig))
