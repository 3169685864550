import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import { useHistory } from 'react-router-dom'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import { DataTable } from '../../DataTable/DataTable'
import { MainCell } from '../../DataTable/cells/MainCell'
import { useCreate } from '../../../hooks/useNewAPI'
import { useDataTable } from '../../DataTable/useDataTable'
import Button from '../../Button'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Tabs from '../../Tabs'

import { ImportTemplatesTabs } from '../../../constructs/Templates/ImportTemplatesTabs'
import TemplateLegalTooltip from '../TemplateLegalTooltip'

const TreatmentBookTemplatesImportOverlay = (props) => {
  const history = useHistory()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [selectedType, setSelectedType]: any = React.useState('public_template')

  const [subcategory, setSubcategory] = React.useState(props.category || 'clinical')

  console.log('props.category', props.category)

  const createMutation = useCreate({
    name: ['import-treatment-book-templates'],
    url: '/internal_templates/import',
    invalidate: 'treatment-book-problems',
  })

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    try {
      const ids = selectedRows.map((o: any) => o.id)

      await createMutation.mutateAsync({
        ids: ids,
        category: `treatment_book_problem`,
        subcategory: subcategory,
      })

      history.goBack()
    } catch (errors) {
      console.debug(errors)
    }
  }

  React.useEffect(() => {
    setSelectedRows([])
  }, [subcategory])

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Treatment Book Problem Templates" icon="treatment_plans" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        <Section margin="0 0 1.5rem 0">
          <ImportTemplatesTabs hideSales onChange={setSelectedType} />

          <Tabs activeTab={subcategory} onChange={setSubcategory}>
            <Tabs.List className="px-0 mb-4">
              <Tabs.Item label="Clinical" name={`clinical`} />
              <Tabs.Item label="Medical" name={`medical`} />
              <Tabs.Item label="Case Management" name={`case_management`} />
              <Tabs.Item label="Peer" name={`peer`} />
            </Tabs.List>
          </Tabs>

          <TreatmentPlanTemplatesDataTable
            key={`subcategory-${subcategory}`}
            subcategory={subcategory}
            selectedType={selectedType}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Templates', selectedRowsCount)}` : 'Select Templates to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={createMutation.isLoading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const TreatmentPlanTemplatesDataTable = (props: any) => {
  const { subcategory, selectedType, setSelectedRows } = props

  const tableProps = useDataTable({
    name: ['internal_treatment_book_templates'],
    endpoint: '/internal_templates',
    params: {
      category: `treatment_book_problem`,
      subcategory: subcategory,
      level: selectedType,
      status: 'active',
    },
    localStorageKey: `internal_treatment_book_problem_templates_${subcategory}_v1`,
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} id={data.id} value={value} />,
      },
      {
        width: 200,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'updated_at',
        title: 'Last Edited',
        type: 'date_time',
      },
      {
        width: 180,
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [])

  return (
    <DataTable
      asCard
      title={`Treatment Book Problem Templates`}
      icon="treatment_plans"
      columns={columns}
      emptyDescription={`No Treatment Book Problem Templates have been added yet`}
      canBatchSelect
      onRowSelectionUpdate={setSelectedRows}
      {...tableProps}
    />
  )
}

export default TreatmentBookTemplatesImportOverlay
