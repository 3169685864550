import React from 'react'
import startCase from 'lodash/startCase'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'

import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

export const OrganizationsCommunicationsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Contact Name',
        id: 'contact',
        model: 'contact.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.contact?.name} avatar={data.contact?.avatar} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'customer',
        model: 'customer.name',
        title: 'Contact For',
        type: 'profile',
      },
      {
        width: 180,
        id: 'author',
        model: 'author.name',
        title: 'Added By',
        type: 'profile',
      },
      {
        width: 140,
        id: 'category',
        model: 'category',
        title: 'Contact Method',
        formatValue: ({ data, value }: any) => startCase(value),
        // filterOptions: [
        //   {
        //     value: 'phone',
        //     label: 'Phone',
        //   },
        //   {
        //     value: 'email',
        //     label: 'Email',
        //   },
        //   {
        //     value: 'text_message',
        //     label: 'Text Message',
        //   },
        //   {
        //     value: 'meeting',
        //     label: 'Meeting',
        //   },
        //   {
        //     value: 'web',
        //     label: 'Web',
        //   },
        //   {
        //     value: 'other',
        //     label: 'Other',
        //   },
        // ],
      },
      {
        width: 220,
        id: 'reason',
        model: 'reason',
        title: 'Notes',
      },
      {
        width: 200,
        id: 'contacted_at',
        model: 'contacted_at',
        title: 'Contacted At',
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Organization Communications"
      icon="communications"
      columns={columns}
      filtersConfig={FILTERS.organization_communications}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="organization_communications"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=organization_communications'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'organization_communications' }} />
        </>
      }
      {...props}
    />
  )
}
