import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import State from '../../components/State'

import { CLAIMMD_SERVICE_CODE_DATA, ClaimMDServiceCodesSelector } from './components/ClaimMDServiceCodesSelector'
import { formatServiceCodes } from './functions'

const defaultServiceCodes = CLAIMMD_SERVICE_CODE_DATA.filter((item) => item.code === '30')

const RootBatchEligibilityBuilderOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'eligibility',
    endpoint: '/eligibility',
    invalidate: 'eligibility',
    options: props,
    isEditable: true,
  })

  const { selectedClients } = props
  const selectedClientsCount = size(selectedClients)

  const { tenant, timezone } = useSettings()

  const save = async () => {
    const formData = form.current.getFormValue()

    const saveData = {
      ...formData,
      service_codes: formatServiceCodes(formData.service_code_ids),
    }

    await saveWithData(saveData)
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} position="center">
      <Overlay.Header icon="eligibility" title="Batch Eligibility Check" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            ...initialModel,
            credential: tenant?.default_evob_provider,
            service_codes: defaultServiceCodes,
          }}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            {selectedClientsCount === 0 ? (
              <State isEmpty icon="clients" title="Batch Eligibility Check" emptyDescription="No clients selected" />
            ) : (
              <FormSection maxWidth="100%">
                <ObjectSelector
                  label="Provider"
                  icon="staff_credentials"
                  type="company.npis"
                  model="credential"
                  dependentValue={tenant.id}
                  selectTitle={(data) => `${data.credential} (${data.license_number})`}
                  selectDescription={(data) => (data.level === 'organization' ? data.organization : `${data.first_name} ${data.last_name}`)}
                  validations={{
                    presence: {
                      message: 'Please select a valid NPI',
                    },
                  }}
                />

                <ClaimMDServiceCodesSelector
                  label="Please select the Service Codes you would like to use for the eVOB:"
                  model="service_codes"
                />
              </FormSection>
            )}
          </Section>
        </Form>
      </Overlay.Content>

      {selectedClientsCount > 0 && (
        <Overlay.Footer>
          <Button
            label={`Check Eligibility for ${countWord('Clients', selectedClientsCount)}`}
            glyph="check"
            type="primary"
            color="green"
            isLoading={isSaving}
            onClick={save}
            isDisabled={isInvalid}
            flex="100 1 auto"
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const BatchEligibilityBuilderOverlay = withOverlayError(RootBatchEligibilityBuilderOverlay)
