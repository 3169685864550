import React from 'react'
import { parse } from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isUUID } from '../../utils/functions'
import { NotionBrowser } from '../../constructs/Notion'
import type { NotionPage } from '../../constructs/Notion/NotionBrowser'

import Button from '../Button'
import Flex from '../Flex'
import OverlaySubHeader from '../Overlay/OverlaySubHeader'

import { AppSidebarContext } from './context'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

const HOME_PAGE = {
  name: 'Recovery Outcomes Institute Knowledge Center',
  page_id: '1c741205967d80e88343c6261ea486c3',
  url: 'https://www.notion.so/behave-help/Recovery-Outcomes-Institute-Knowledge-Center-1c741205967d80e88343c6261ea486c3',
}

const ROICenterView: React.FC<any> = () => {
  const location = useLocation()
  const history = useHistory()

  const { isFullScreen }: any = React.useContext(AppSidebarContext)

  const user = useSelector((state: any) => state.me?.user)
  const isBehave = user?.type === 'bh_employee'

  let params: any = parse(location.search)
  const helpTag: any = isUUID(params.roi) ? '' : params.roi

  const homePage = HOME_PAGE

  const handlePageChange = (page: NotionPage) => {
    if (!page?.id) return

    const searchParams = new URLSearchParams({ roi: page.id })
    history.replace({ search: searchParams.toString() })
  }

  return (
    <>
      <AppSidebarView>
        <AppSidebarHeader title="Recovery Outcomes Institute Knowledge Center" icon="roi" />

        {isBehave && (
          <OverlaySubHeader css={styles.subHeader}>
            <Flex centerY gap={8} justifyContent="flex-end">
              <Button
                useGlyphForTarget
                label="Open in Notion"
                color="text"
                glyph="notion"
                href={`https://www.notion.so/${homePage?.page_id}`}
                size={200}
                target="_blank"
                type="minimal"
              />
            </Flex>
          </OverlaySubHeader>
        )}

        <NotionBrowser
          homeTitle={homePage?.name}
          homeID={homePage?.page_id}
          pageTitle={homePage?.name}
          pageID={homePage?.page_id}
          onPageChange={handlePageChange}
          css={styles.notionBrowser}
          feedbackCategory="internal_article"
        />
      </AppSidebarView>
    </>
  )
}

const styles = {
  subHeader: {
    padding: '0.5rem',
  },

  notionBrowser: {
    padding: '1rem 1rem 0',
  },
}

export default ROICenterView
