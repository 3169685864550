import React from 'react'

import { getResourceLink, getVersionSlug } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { ProgressReviewsReportDataTable } from '@behavehealth/constructs/LiveReports/ProgressReviewsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ProgressReviews = () => {
  const tableProps = useDataTable({
    name: ['reports', ']'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_progress_reviews_v1',
    params: { category: 'progress_reviews' },
  })

  const to = React.useMemo(
    () => (rowData: any) => {
      const { id, version } = rowData

      const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

      return `${getResourceLink(rowData?.client)}/progress-reviews${version === '3.0' ? '/progress-review' : ''}/${id}${formattedVersion}`
    },
    [],
  )

  return (
    <Page feature="progress_reviews" title="Live Progress Reviews Report">
      <Grid>
        <ProgressReviewsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ProgressReviews)
