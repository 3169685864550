import React from 'react'
import { PanelGroup, Panel } from 'react-resizable-panels'
import { useMedia } from 'use-media'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import get from 'lodash/get'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'

import { countWord, usDateTime } from '../../utils/functions'
import { PanelResize } from '../FormBuilder/components/PanelResize'
import { SectionCard } from '../../components/SectionCard'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { ComboBox } from '../../components/Forms/ComboBoxes/ComboBox'
import { PDFViewer } from './components/PDFViewer'
import { PlainSwitch } from '../../components/Forms/Switch'
import { Spreadsheet } from '../../components/Spreadsheet/Spreadsheet'
import { useSpreadsheet } from '../../components/Spreadsheet/useSpreadsheet'
import Alert from '../../components/Alert'
import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import ButtonGroup from '../../components/ButtonGroup'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardTitle from '../../components/CardTitle'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Select from '../../components/Forms/Select'
import SummonOverlay from '../../components/SummonOverlay'

import { ClaimsImportOverlay } from './ClaimsImportOverlay'
import { ERAClaims } from './components/ERAClaims'
import { ERAServiceLinesFullTable } from './components/ERAServiceLinesFullTable'
import { InsuranceInvoiceOverlay } from './InsuranceInvoiceOverlay'

const SERVICE_LINE_STATUSES = {
  draft: 'Draft',
  validated: 'Validated',
  validation_error: 'Validation Error',
  submitted: 'Submitted',
  submission_error: 'Submission Error',
  denied: 'Denied',
  balance_due_client: 'Balance Due Client',
  paid: 'Paid',
  written_off: 'Written Off',
}

const DESKTOP_MIN_WIDTH = 1200
const DESKTOP_MIN_HEIGHT = 1000

const DESKTOP_MQ = `@media (min-width: ${DESKTOP_MIN_WIDTH}px) and (min-height: ${DESKTOP_MIN_HEIGHT}px)`

const RootPaymentOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    data,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    onValidationUpdate,
    isOverlayLoading,
    saveWithData,
  } = useOverlay({
    name: 'insurance_new_payments',
    endpoint: '/insurance_new_payments',
    invalidate: 'insurance_new_payments',
    options: props,
  })

  const { isBehave, timezone } = useSettings()
  const isDesktop = useMedia({ minWidth: DESKTOP_MIN_WIDTH, minHeight: DESKTOP_MIN_HEIGHT })
  const shouldPinColumns = useMedia({ minWidth: 1200 })

  const [isDetailsOpen, setIsDetailsOpen] = React.useState(true)
  const [isSaving, setIsSaving] = React.useState(false)
  const [invoiceData, setInvoiceData] = React.useState(null)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)

  const [eraView, setEraView] = React.useState('table')
  const [availableEOBs, setAvailableEOBs] = React.useState(null)
  const [selectedEOB, setSelectedEOB] = React.useState(null)

  const [availableERAs, setAvailableERAs] = React.useState(initialModel?.insurance_eras || null)
  const [selectedERA, setSelectedERA] = React.useState(null)

  const [isEOBVisible, setIsEOBVisible] = React.useState(false)
  const [isERAVisible, setIsERAVisible] = React.useState(false)
  const [columnsLayout, setColumnsLayout] = React.useState('vertical')

  const selectedERAData = React.useMemo(() => {
    const res: any = []

    if (!selectedERA?.insurance_era_claims) return res

    for (const claim of selectedERA.insurance_era_claims) {
      for (const serviceLine of claim.insurance_era_service_lines) {
        res.push({
          insurance_era_claim: claim,
          ...serviceLine,
        })
      }
    }

    return res
  }, [selectedERA])

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [deletedIds, setDeletedIds] = React.useState([])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'ID',
        model: 'id',
        width: 50,
      },
      {
        title: '_ID',
        model: '_id',
        width: 50,
      },
      {
        title: 'Insurance Claim Service Line ID',
        model: 'insurance_claim_service_line_id',
        getInitialValue: (row) => row?.insurance_claim_service_line?.id,
        readOnly: true,
      },
      {
        title: ' ',
        type: 'select_row',
        width: 50,
        config: {
          onRowSelect: (rowIndex, checked) => {
            setSelectedRows((prev: any) => {
              if (checked) {
                return prev.includes(rowIndex) ? prev : [...prev, rowIndex]
              }

              return prev.filter((r: number) => r !== rowIndex)
            })
          },
        },
      },
      {
        title: 'Client',
        model: 'insurance_claim.resident.name',
        readOnly: true,
      },
      {
        title: 'Service Start Date',
        model: 'insurance_claim_service_line.service_date_start',
        type: 'date',
        readOnly: true,
      },
      {
        title: 'Service End Date',
        model: 'insurance_claim_service_line.service_date_end',
        type: 'date',
        readOnly: true,
      },
      {
        title: 'Procedure Code',
        model: 'insurance_claim_service_line.insurance_new_code.procedure_code',
        readOnly: true,
      },
      {
        title: 'Payment Service Line ID',
        model: 'insurance_claim_service_line.identifier',
        readOnly: true,
      },
      {
        title: 'Claim ID',
        model: 'insurance_claim.identifier',
        readOnly: true,
      },
      {
        title: 'Service Line Status',
        model: 'insurance_claim_service_line.status',
        readOnly: true,
        config: {
          render: (value: any) => SERVICE_LINE_STATUSES[value] || null,
        },
      },
      {
        title: 'Billed Amount',
        model: 'insurance_claim_service_line.total_amount',
        type: 'amount',
        readOnly: true,
      },
      {
        title: 'Allowed Amount',
        model: 'allowed_amount',
        type: 'amount',
        onUpdate: ({ value, y, instance, get, set }) => {
          const total = get('insurance_claim_service_line.total_amount') || 0
          const adjustmentAmount = total - value

          set('adjustment_amount', adjustmentAmount || 0)
        },
        config: {
          validations: {
            lessThan: {
              value: 1_000_000,
              message: 'Amount must be less than $1,000,000',
            },
          },
        },
      },
      {
        title: 'Insurance Paid',
        model: 'insurance_paid_amount',
        type: 'amount',
        onUpdate: ({ value, set }) => {},
        config: {
          validations: {
            lessThan: {
              value: 1_000_000,
              message: 'Amount must be less than $1,000,000',
            },
          },
        },
      },
      {
        title: 'Deductible',
        model: 'deductible_amount',
        type: 'amount',
        onUpdate: ({ value, y, instance, get, set }: any) => {
          const deductibleAmount = value || 0
          const copayAmount = get('copay_amount') || 0
          const coInsuranceAmount = get('coinsurance_amount') || 0
          const balanceClientDueAmount = deductibleAmount + copayAmount + coInsuranceAmount

          set('balance_client_due_amount', balanceClientDueAmount || 0)
        },
        config: {
          validations: {
            lessThan: {
              value: 1_000_000,
              message: 'Amount must be less than $1,000,000',
            },
          },
        },
      },
      {
        title: 'Co-Pay',
        model: 'copay_amount',
        type: 'amount',
        onUpdate: ({ value, y, instance, get, set }: any) => {
          const copayAmount = value || 0
          const deductibleAmount = get('deductible_amount') || 0
          const coInsuranceAmount = get('coinsurance_amount') || 0

          const balanceClientDueAmount = deductibleAmount + copayAmount + coInsuranceAmount

          set('balance_client_due_amount', balanceClientDueAmount || 0)
        },
        config: {
          validations: {
            lessThan: {
              value: 1_000_000,
              message: 'Amount must be less than $1,000,000',
            },
          },
        },
      },
      {
        title: 'Coinsurance',
        model: 'coinsurance_amount',
        type: 'amount',
        onUpdate: ({ value, y, instance, get, set }: any) => {
          const coinsuranceAmount = value || 0
          const deductibleAmount = get('deductible_amount') || 0
          const copayAmount = get('copay_amount') || 0
          const balanceClientDueAmount = deductibleAmount + copayAmount + coinsuranceAmount

          set('balance_client_due_amount', balanceClientDueAmount || 0)
        },
        config: {
          validations: {
            lessThan: {
              value: 1_000_000,
              message: 'Amount must be less than $1,000,000',
            },
          },
        },
      },
      {
        title: 'Claim Adjustment Group Code',
        model: 'adjustment_group_code',
      },
      {
        title: 'Adj Reason Code',
        model: 'adjustment_reason_code',
      },
      {
        title: 'Adjustment Amount',
        model: 'adjustment_amount',
        type: 'amount',
        config: {
          validations: {
            lessThan: {
              value: 1_000_000,
              message: 'Amount must be less than $1,000,000',
            },
          },
        },
      },
      {
        title: 'Balance Due Client',
        model: 'balance_client_due_amount',
        type: 'amount',
        config: {
          validations: {
            lessThan: {
              value: 1_000_000,
              message: 'Amount must be less than $1,000,000',
            },
          },
        },
      },
    ]
  }, [])

  const spreadsheet = useSpreadsheet({ columns })

  const initialSpreadsheetData = React.useMemo(() => {
    if (isNew) return initialModel?._service_lines || []

    if (!data?.insurance_new_payment_service_lines) return null

    const res: any = []
    const sorted = sortBy(data.insurance_new_payment_service_lines, 'created_at')

    for (const line of sorted) {
      const row = {
        _id: uuid(),
        insurance_claim_service_line_id: line?.insurance_claim_service_line?.id,
      }

      for (const column of columns) {
        row[column.model] = column?.getInitialValue ? column.getInitialValue(line) : get(line, column.model)
      }

      res.push(row)
    }

    return res
  }, [columns, data, isNew])

  const deleteSelectedRows = () => {
    if (!spreadsheet) return

    let increment = 0
    const apiIds: any = []

    const orderedRows = selectedRows.sort((a: number, b: number) => a - b)

    for (const index of orderedRows) {
      const row = spreadsheet.spreadsheet.hotInstance.getDataAtRow(index - increment)
      const rowId = spreadsheet.spreadsheet.hotInstance.getDataAtRowProp(index - increment, 'id')

      if (rowId) apiIds.push(rowId)

      spreadsheet.spreadsheet.hotInstance.alter('remove_row', index - increment)
      increment++
    }

    setDeletedIds((prev: any) => [...prev, ...apiIds])
    setSelectedRows([])
  }

  React.useEffect(() => {
    if (!availableEOBs || size(availableEOBs) === 0) {
      setSelectedEOB(null)
      setIsEOBVisible(false)
    } else {
      if (!!selectedEOB) return
      setSelectedEOB(availableEOBs[0])
    }
  }, [availableEOBs])

  React.useEffect(() => {
    if (!availableERAs || size(availableERAs) === 0) {
      setSelectedERA(null)
      setIsERAVisible(false)
    } else {
      if (!!selectedERA) return
      setSelectedERA(availableERAs[0])
    }
  }, [availableERAs])

  const hasEOBs = size(availableEOBs)
  const hasERAs = size(availableERAs)

  const handleCancel = () => {
    cancel()
  }

  const onSave = async () => {
    setIsSaving(true)

    const formValue = form.current.getFormValue()
    const saveData = []

    for (const row of spreadsheet.getData()) {
      const parsed: any = {
        _id: row._id,
        insurance_claim_service_line_id: row.insurance_claim_service_line_id,
      }

      for (const key in row) {
        const column = columns.find((c) => c.model === key)
        let value = row[key]

        if (key === 'id' && !value) continue

        if (column?.readOnly) continue

        if (column?.type === 'amount' && !value) {
          value = 0
        }

        parsed[key] = value
      }

      saveData.push(parsed)
    }

    const deleteData: any = []

    for (const id of deletedIds) {
      deleteData.push({ id, _destroy: true })
    }

    try {
      await saveWithData({ ...formValue, insurance_new_payment_service_lines_attributes: [...saveData, ...deleteData] })
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
      setDeletedIds([])
    }
  }

  const onAutoPost = async () => {
    if (!availableERAs || size(availableERAs) === 0) return

    const formatKey = ({ claimIdentifier, procedureCode, fromDate, untilDate }) =>
      `${claimIdentifier}-${procedureCode}-${fromDate}-${untilDate}`

    const eraServiceLinesMap = new Map()

    // parse through the available ERAs
    for (const era of availableERAs) {
      if (size(era.insurance_era_claims) === 0) continue

      for (const claim of era.insurance_era_claims) {
        const eraInsuranceClaimIdentifier = claim.insurance_claim?.identifier

        if (size(claim.insurance_era_service_lines) === 0) continue

        for (const serviceLine of claim.insurance_era_service_lines) {
          const procedureCode = serviceLine?.proc_code
          const fromDate = serviceLine?.from_date
          const untilDate = serviceLine?.until_date

          const key = formatKey({
            claimIdentifier: eraInsuranceClaimIdentifier,
            procedureCode,
            fromDate,
            untilDate,
          })

          eraServiceLinesMap.set(key, serviceLine)
        }
      }
    }

    const spreadsheetData = spreadsheet.getData()

    // parse through the spreadsheet service lines
    for (let i = 0; i < spreadsheetData.length; i++) {
      const row = spreadsheetData[i]

      const procedureCode = get(row, 'insurance_claim_service_line.insurance_new_code.procedure_code')
      const claimIdentifier = get(row, 'insurance_claim.identifier')
      const fromDate = usDateTime(get(row, 'insurance_claim_service_line.service_date_start'))
      const untilDate = usDateTime(get(row, 'insurance_claim_service_line.service_date_end'))

      const key = formatKey({
        claimIdentifier,
        procedureCode,
        fromDate,
        untilDate,
      })

      // update data for the matched service lines
      if (eraServiceLinesMap.has(key)) {
        const line = eraServiceLinesMap.get(key)

        spreadsheet.spreadsheet.hotInstance.setDataAtRowProp(i, 'allowed_amount', line.allowed || 0)
        spreadsheet.spreadsheet.hotInstance.setDataAtRowProp(i, 'insurance_paid_amount', line.paid || 0)
      }
    }
  }

  if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={150} />

  const importClaimsButton = (
    <SummonOverlay
      overlay={
        <ClaimsImportOverlay
          onSelect={(selectedClaims: any) => {
            let finalServiceLines: any[] = []

            for (let i = 0; i < selectedClaims.length; i++) {
              const claim = selectedClaims[i]

              for (let j = 0; j < claim.insurance_claim_service_lines.length; j++) {
                const serviceLine = claim.insurance_claim_service_lines[j]

                finalServiceLines.push({
                  _id: uuid(),
                  insurance_claim: {
                    ...claim,
                    resident: claim?.resident || {},
                  },
                  insurance_claim_service_line: {
                    ...serviceLine,
                    insurance_new_code: serviceLine.insurance_new_code || {},
                  },
                  insurance_claim_service_line_id: serviceLine.id,
                })
              }
            }

            spreadsheet.addDataRows(finalServiceLines)
          }}
          getImportedData={spreadsheet.getData}
        />
      }
    >
      <Button label="Import Claims" glyph="add_file" type="primary" size={200} />
    </SummonOverlay>
  )

  return (
    <Overlay
      onClose={close}
      showBackdrop={isNew || isEditable}
      isDirty={isEditable}
      position="right"
      maxWidth={150}
      closeOnEscape={!isEditable}
    >
      <Overlay.Header icon="accounting" title="Payment" />

      <Overlay.Content>
        <Form
          isCompact
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
          maxWidth="100%"
          className="mq1024:grid grid-rows-[auto_auto_1fr] mq1024:h-full grid-cols-[100%] grid-rows-[100%] mq1024:overflow-hidden"
        >
          <div className="px-4 pt-4 border-b border-solid border-0 border-divider">
            <div className={clsx(isDetailsOpen ? 'block' : 'hidden', 'pb-4')}>
              <Flex gap="0.5rem">
                <SectionCard title="Payment" className="!flex-grow-4 !basis-[500px]">
                  <Flex stretchChildrenX gap="1rem">
                    <ComboBox
                      label="Insurance Payer"
                      model="insurance_local_payer"
                      icon="insurance"
                      type="insurance_local_payers"
                      selectTitle={(data: any) => data?.name}
                      selectDescription={(data: any) => data?.notes}
                      validations={{
                        presence: {
                          message: 'Please select an Insurance Payer',
                        },
                      }}
                    />

                    <Select label="Payment Method" defaultValue="check" model="payment_method">
                      <Option label="Check" value="check" />
                      <Option label="Bank Transfer (ACH)" value="ach" />
                      <Option label="Other" value="other" />
                    </Select>

                    <DateInput
                      className="!max-w-[200px]"
                      label="Payment Date"
                      model="payed_at"
                      validations={{
                        presence: {
                          message: 'Please enter a Payment Date',
                        },
                      }}
                    />

                    <Input
                      label="Payment Reference #"
                      model="reference"
                      validations={{
                        presence: {
                          message: 'Please enter a Payment Reference #',
                        },
                      }}
                    />
                    <AmountInput label="Payment Amount" model="amount" />

                    {!isNew && <DateInput isEditable={false} label="Posted Date" model="posted_at" />}

                    <MultiOverlaySelector
                      label="Connected EOBs"
                      type="insurance.insurance_eobs"
                      model="insurance_eobs"
                      icon="insurance"
                      selectTitle={(data: any) => data?.identifier}
                      onUpdate={(state: any) => {
                        setAvailableEOBs(state.value)
                        setIsEOBVisible(size(state.value) > 0)
                      }}
                    />

                    <MultiOverlaySelector
                      label="Connected ERAs"
                      type="insurance.insurance_eras"
                      model="insurance_eras"
                      icon="insurance"
                      selectTitle={(data: any) => data?.identifier}
                      onUpdate={(state: any) => {
                        setAvailableERAs(state.value)
                        setIsERAVisible(size(state.value) > 0)
                      }}
                    />
                  </Flex>
                </SectionCard>

                {!isNew && (
                  <>
                    <SectionCard title="Service Lines Amounts" className="!flex-grow-1">
                      <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
                        <AmountInput isEditable={false} label="Total Billed" value={data.billed_amount} />
                        <AmountInput isEditable={false} label="Total Adjustment" value={data.adjustment_amount} />
                        <AmountInput isEditable={false} label="Total Allowed Amount" value={data.allowed_amount} />
                        <AmountInput isEditable={false} label="Total Balance Due Client" value={data.balance_client_due_amount} />
                        <AmountInput isEditable={false} label="Total Client Respons." value={data.client_responsibility_amount} />
                        <AmountInput isEditable={false} label="Total Paid Amount" value={data.paid_amount} />
                      </div>
                    </SectionCard>

                    <SectionCard title="Details" className="!flex-grow-1">
                      <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
                        <OverlaySelector
                          isEditable={false}
                          label="Created By"
                          model="author"
                          type="employees.active"
                          icon="employees"
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => startCase(data.position)}
                        />

                        <OverlaySelector
                          isEditable={false}
                          label="Last Edited By"
                          model="last_edited_by"
                          type="employees.active"
                          icon="employees"
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => startCase(data.position)}
                        />

                        <DateTimeInput isEditable={false} label="Created At" model="created_at" />
                        <DateTimeInput isEditable={false} label="Last Edited At" model="updated_at" />
                      </div>
                    </SectionCard>
                  </>
                )}
              </Flex>
            </div>
          </div>

          <div className="flex items-center justify-start px-4 py-2">
            <div className="flex items-center mr-4">
              <div className={clsx('flex flex-nowrap items-center', !hasEOBs && 'pointer-events-none opacity-50')}>
                <PlainSwitch
                  horizontal
                  isEditable
                  isChecked={isEOBVisible}
                  onCheckedChange={setIsEOBVisible}
                  labelWidth="auto"
                  withHover={false}
                  size={100}
                  label={null}
                />
                <Label label="Show EOB" className="ml-1.5 whitespace-nowrap cursor-pointer" />
              </div>

              <div className={clsx('flex flex-nowrap items-center', !hasERAs && 'pointer-events-none opacity-50')}>
                <PlainSwitch
                  horizontal
                  isEditable
                  isChecked={isERAVisible}
                  onCheckedChange={setIsERAVisible}
                  labelWidth="auto"
                  withHover={false}
                  size={100}
                  label={null}
                />
                <Label label="Show ERA" className="ml-1.5 whitespace-nowrap cursor-pointer" />
              </div>

              {isDesktop && (
                <div className={clsx('ml-2', !hasEOBs && !hasERAs && 'pointer-events-none opacity-50')}>
                  <ButtonGroup>
                    <Button
                      size={100}
                      label="Vertical"
                      color="gray"
                      type={columnsLayout === 'vertical' ? 'primary' : 'default'}
                      onClick={() => setColumnsLayout('vertical')}
                    />
                    <Button
                      size={100}
                      label="Horizontal"
                      color="gray"
                      type={columnsLayout === 'horizontal' ? 'primary' : 'default'}
                      onClick={() => setColumnsLayout('horizontal')}
                    />
                  </ButtonGroup>
                </div>
              )}
            </div>

            {!hasEOBs && !hasERAs && (
              <Alert contrast glyph="info" className="!px-2 !py-1 !text-sm !rounded-md">
                Connect EOBs and/or ERAs to preview them here
              </Alert>
            )}

            <div className="flex-[1_1_auto]" />

            {isBehave && isEditable && (
              <ConfirmDialog
                glyph="behave_health"
                title={`Auto-Post`}
                yesLabel="Auto-Post"
                yesColor="blue"
                message={
                  <div className="leading-relaxed">
                    <Alert small contrast glyph="info">
                      This feature is currently in beta, please proceed with caution.
                    </Alert>

                    <div className="grid gap-2 mt-4">
                      <div className="font-[600]">Auto-posting will match the service lines based on the following data points:</div>

                      <ul className="pl-6">
                        <li>Claim Identifier</li>
                        <li>Procedure Code</li>
                        <li>Start Date</li>
                        <li>End Date</li>
                      </ul>

                      <div className="font-[600]">…and set the corresponding amounts for:</div>

                      <ul className="pl-6">
                        <li>Allowed Amount</li>
                        <li>Insurance Paid Amount</li>
                      </ul>
                    </div>
                  </div>
                }
                onYes={onAutoPost}
              >
                <Button label="🚧 Auto-Post" glyph="behave" size={200} />
              </ConfirmDialog>
            )}
          </div>

          <div className="grid grid-rows-[100%] grid-cols-[100%] !overflow-hidden">
            <PanelGroup direction={columnsLayout}>
              <Panel id="one" className="grid grid-rows-[100%] grid-cols-[100%] px-5 py-3 !overflow-auto">
                {initialSpreadsheetData && (
                  <>
                    <ServiceLinesSpreadsheet
                      key={`updated-${data?.updated_at}`}
                      columns={columns}
                      importClaimsButton={importClaimsButton}
                      isEditable={isEditable}
                      shouldPinColumns={shouldPinColumns}
                      spreadsheet={spreadsheet}
                      initialData={initialSpreadsheetData}
                      selectedRows={selectedRows}
                      editActions={
                        size(selectedRows) > 0 && (
                          <Button
                            size={100}
                            label={`Delete ${countWord('Row', size(selectedRows))}`}
                            glyph="delete"
                            color="red"
                            onClick={() => setIsDeleteDialogOpen(true)}
                          />
                        )
                      }
                    />
                  </>
                )}
              </Panel>

              {isEOBVisible && (
                <>
                  <PanelResize direction={columnsLayout === 'vertical' ? 'horizontal' : 'vertical'} />

                  <Panel id="two" className="grid grid-rows-[100%] grid-cols-[100%] px-5 py-3">
                    <Card className="grid grid-rows-[auto_1fr] grid-cols-[100%] overflow-hidden">
                      <CardHeader>
                        <div className="flex items-center">
                          <div className="mr-4">
                            <CardTitle title="EOBs" />
                          </div>

                          <Flex gap="0.5rem">
                            {availableEOBs?.map((eob: any) => (
                              <Button
                                key={eob.identifier}
                                size={200}
                                label={eob.identifier}
                                type={selectedEOB?.id === eob.id ? 'primary' : 'default'}
                                onClick={() => {
                                  setSelectedEOB(eob)
                                }}
                              />
                            ))}
                          </Flex>
                        </div>
                      </CardHeader>

                      <div className="grid grid-cols-[100%] grid-rows-[100%] overflow-hidden">
                        <PDFViewer url={selectedEOB?.upload} />
                      </div>
                    </Card>
                  </Panel>
                </>
              )}

              {isERAVisible && (
                <>
                  <PanelResize direction={columnsLayout === 'vertical' ? 'horizontal' : 'vertical'} />

                  <Panel id="three" className="grid grid-rows-[100%] grid-cols-[100%] px-5 py-3">
                    <Card className="grid grid-rows-[auto_1fr] grid-cols-[100%] overflow-hidden">
                      <CardHeader className="border-b border-solid border-divider border-0 shadow-hard-2">
                        <div className="flex items-center">
                          <div className="mr-4">
                            <CardTitle title="ERAs" />
                          </div>

                          <ButtonGroup className="ml-2 mr-6">
                            <Button
                              label="Table"
                              color="text"
                              type={eraView === 'table' ? 'primary' : 'default'}
                              size={100}
                              onClick={() => {
                                setEraView('table')
                              }}
                            />
                            <Button
                              label="Grouped"
                              color="text"
                              type={eraView === 'grouped' ? 'primary' : 'default'}
                              size={100}
                              onClick={() => {
                                setEraView('grouped')
                              }}
                            />
                          </ButtonGroup>

                          <Flex gap="0.5rem">
                            {availableERAs?.map((era: any) => (
                              <Button
                                size={100}
                                label={era.identifier}
                                type={selectedERA?.id === era.id ? 'primary' : 'default'}
                                onClick={() => {
                                  setSelectedERA(era)
                                }}
                              />
                            ))}
                          </Flex>
                        </div>
                      </CardHeader>

                      <div className="overflow-y-auto">
                        {eraView === 'table' && selectedERA && (
                          <div className="w-full h-full overflow-hidden grid grid-rows-[100%] grid-cols-[100%]">
                            <ERAServiceLinesFullTable data={selectedERAData} />
                          </div>
                        )}

                        {eraView === 'grouped' && <ERAClaims data={selectedERA?.insurance_era_claims} />}
                      </div>
                    </Card>
                  </Panel>
                </>
              )}
            </PanelGroup>
          </div>
        </Form>

        <SummonOverlay isOpen={!!invoiceData} overlay={<InsuranceInvoiceOverlay />} onClose={() => setInvoiceData(null)} />
        <DeleteDialog
          setOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          message="Are you sure you want to delete the selected service lines?"
          onYes={deleteSelectedRows}
        />
      </Overlay.Content>

      <Overlay.Footer withGradient={false}>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={onSave}
              // isDisabled={isInvalid}
              flex="100 1 auto"
              permission="insurance_posting.create"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={handleCancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="insurance_posting.edit"
            />

            <DeleteDialog
              title="Delete Payment?"
              message="Are you sure you want to delete this Payment? This action cannot be undone."
              onYes={deleteRecord}
              permission="insurance_posting.delete"
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="insurance_posting.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const ServiceLinesSpreadsheet = (props: any) => {
  const { columns, importClaimsButton, isEditable, shouldPinColumns, spreadsheet, initialData, editActions, selectedRows } = props

  if (!initialData) return null

  return (
    <Spreadsheet
      title={isEditable ? 'Edit Service Lines' : 'Service Lines'}
      icon="accounting"
      canAdd={false}
      canDelete={false}
      columnSorting={false}
      manualColumnMove={false}
      isEditable={isEditable}
      getSpreadsheet={spreadsheet.getSpreadsheet}
      columns={columns}
      getData={spreadsheet.getData}
      editActions={editActions}
      headerAfter={isEditable && importClaimsButton}
      fixedColumnsStart={shouldPinColumns ? 7 : 0}
      hiddenColumns={{ columns: [0, 1, 2] }}
      initialData={initialData}
      selectedRows={selectedRows}
      height="100%"
    />
  )
}

export const PaymentOverlay = withOverlayError(RootPaymentOverlay)
