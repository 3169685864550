import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import useSettings from '../../hooks/useSettings'

import EVOBNewStatus from '../../components/Statuses/EVOBNewStatus'
import { getClientLink } from '../../utils/functions'

export const EligibilityDataTable = (props: any) => {
  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Identifier',
        model: 'identifier',
        isSticky: true,
        formatValue: ({ data, value }: any) => <MainCell to={props.to?.(data)} value={value} />,
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => {
          return <EVOBNewStatus small status={value} />
        },
      },
      {
        title: 'Policy Holder Name',
        model: 'insured_name',
      },
      {
        title: 'Client',
        model: 'client',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell value={value?.name} avatar={value.avatar} to={getClientLink(value)} />
        },
      },
      {
        title: 'Date of Service',
        model: 'service_date',
        type: 'date_time',
      },
      {
        title: 'Insurance Payer',
        model: 'insurance_local_payer.name',
      },
      {
        title: 'Date Requested',
        model: 'created_at',
        type: 'date_time',
      },
    ],
    [props.to, timezone],
  )

  return <DataTable asCard title="Eligibility Checks" icon="eligibility" columns={columns} {...props} />
}
