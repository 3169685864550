import React from 'react'
import snakeCase from 'lodash/snakeCase'

import { H4, P } from '../Typography'
import { COLORS } from '../../theme'

import AddressSelectorInput from '../Forms/AddressSelectorInput'
import Alert from '../Alert'
import AmountInput from '../Forms/AmountInput'
import Button from '../Button'
import Checkbox from '../Forms/Checkbox'
import CheckboxGroup from '../Forms/CheckboxGroup'
import ContextHide from '../Forms/ContextHide'
import ContextShow from '../Forms/ContextShow'
import DataArray from '../Forms/DataArray'
import DateInput from '../Forms/DateInput'
import DateOfBirthInput from '../Forms/DateOfBirthInput'
import Divider from '../Divider'
import EmailInput from '../Forms/EmailInput'
import Flex from '../Flex'
import FormSection from '../Forms/FormSection'
import Grid from '../Grid'
import Input from '../Forms/Input'
import Label from '../Label'
import Option from '../Forms/Option'
import PhoneInput from '../Forms/PhoneInput'
import Radio from '../Forms/Radio'
import RadioGroup from '../Forms/RadioGroup'
import Select from '../Forms/Select'
import SexualOrientationCheckboxGroup from '../Forms/elements/SexualOrientationCheckboxGroup'
import SignaturePad from '../Forms/SignaturePad'
import Textarea from '../Forms/Textarea'

import { FileAttachmentsInput } from '../Forms/FileAttachmentsInput'

import {
  AddictionAreasCheckboxGroup,
  AddressInputs,
  ContactInputs,
  ContactMethodsCheckboxGroup,
  ContactMethodsRadioGroup,
  DrugsOfChoiceSelector,
  EthnicitySelect,
  GamblingTypesCheckboxGroup,
  GenderIdentitySelect,
  HeightInput,
  NewEthnicityCheckboxGroup,
  RaceCheckboxGroup,
  HighestEducationLevelSelect,
  InsurancePolicyInputs,
  LegalRequirementsInputs,
  MaritalStatusRadioGroup,
  MedicalConditionsCheckboxGroup,
  MedicalEquipmentCheckboxGroup,
  MedicationDescriptionSelect,
  PreferredPaymentMethodRadioGroup,
  PrimaryLanguageSelect,
  PrimaryTransportationModeRadioGroup,
  ProgramTypeRadioGroup,
  ProviderTypesCheckboxGroup,
  RelationshipSelect,
  ReligiousPreferenceSelect,
  SexSelect,
  TwelveStepMeetingsCheckboxGroup,
  WeightInput,
  YesNoRadioGroup,
  YesNoRadioGroupWithTextarea,
} from '../Forms/elements'

import { UATests } from '../../utils/constants'

type FieldProps = {
  companyName: string
  isRequired: boolean
}

type FormCardProps = {
  children: React.ReactNode
  description: string
  headerAside: React.ReactNode
  maxWidth?: boolean | string
  title: string
}

type DataArraySectionProps = {
  children: React.ReactNode
  description: string
  itemTitle: string
  maxWidth?: boolean | string
  model: string
  title: string
}

const MEDICAL_HISTORY_CONDITIONS = [
  {
    label: 'Cardiac/Heart Problems',
    model: 'cardiac_heart_problems',
  },
  {
    label: 'Chronic Pain',
    model: 'chronic_pain',
  },
  {
    label: 'Cirrhosis',
    model: 'cirrhosis',
  },
  {
    label: 'HIV',
    model: 'hiv',
  },
  {
    label: 'Diabetes',
    model: 'diabetes',
  },
  {
    label: 'Epilepsy',
    model: 'epilepsy',
  },
  {
    label: 'Gastrointestinal Problems',
    model: 'gastrointestinal_problems',
  },
  {
    label: 'Gastric Bypass Surgery',
    model: 'gastric_bypass_surgery',
  },
  {
    label: 'Hypertension',
    model: 'hypertension',
  },
  {
    label: 'Pancreatitis',
    model: 'pancreatitis',
  },
  {
    label: 'Hepatitis',
    model: 'hepatitis',
  },
  {
    label: 'Other STD/STI',
    model: 'other_std_sti',
    withExplain: true,
  },
  {
    label: 'Other Illness/Condition',
    model: 'other_illness_condition',
    withExplain: true,
  },
  {
    label: 'Other Illness/Condition',
    model: 'other_illness_condition',
    withExplain: true,
  },
  {
    label: 'Mental Health Condition/Challenge',
    model: 'mental_health_condition_challenge',
    withExplain: true,
  },
  {
    label: 'Mental Health Condition/Challenge',
    model: 'mental_health_condition_challenge',
    withExplain: true,
  },
  {
    label: 'Mental Health Condition/Challenge',
    model: 'mental_health_condition_challenge',
    withExplain: true,
  },
]

const FormCard = (props: FormCardProps) => {
  const { title, description, headerAside, children, maxWidth } = props

  return (
    <div
      css={{
        borderRadius: 5,
        border: `2px solid ${COLORS.divider}`,
        padding: '0.5rem 1.25rem 1.25rem',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '0.5rem',
        }}
      >
        <div className="my-2 mx-0">
          {title && <H4>{title}</H4>}
          {description && <P className="!mt-1 !mx-0 !mb-0">{description}</P>}
        </div>
        {headerAside}
      </div>

      <FormSection maxWidth={maxWidth}>{children}</FormSection>
    </div>
  )
}

const DataArraySection = (props: DataArraySectionProps) => {
  const { children, description, itemTitle, model, title, maxWidth } = props

  return (
    <>
      {(title || description) && (
        <div className="!mt-2">
          {title && <H4>{title}</H4>}
          {description && <P className="!mt-1 !mx-0 !mb-0">{description}</P>}
        </div>
      )}

      <DataArray model={model}>
        {({ orderedIds, add, remove, isEditable }) => (
          <>
            {orderedIds?.map((id: string, idx: number) => (
              <FormCard
                key={id}
                maxWidth={maxWidth}
                title={`${itemTitle} #${idx + 1}`}
                headerAside={isEditable && <Button type="minimal" color="red" glyph="delete" label="Remove" onClick={() => remove(id)} />}
              >
                {children(id)}
              </FormCard>
            ))}

            {isEditable && (
              <div>
                <Button label={`Add New ${itemTitle}`} glyph="add" type="default" display="inline-flex" size={200} onClick={add} />
              </div>
            )}
          </>
        )}
      </DataArray>
    </>
  )
}

export const data = [
  {
    title: 'Personal Details',
    model: 'personal_details',
    glyph: 'behave_health',
    isRequired: true,
    isOpen: true,
    fields: [
      {
        label: 'First Name',
        model: 'first_name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: () => (
          <Input
            label="First Name"
            model="personal_details.first_name"
            validations={{
              presence: {
                message: 'Please enter your first name',
              },
            }}
          />
        ),
      },
      {
        label: 'Middle Name',
        model: 'middle_name',
        isDisabled: false,
        isRequiredDisabled: true,
        isChecked: true,
        component: () => <Input label="Middle Name" model="personal_details.middle_name" />,
      },
      {
        label: 'Last Name',
        model: 'last_name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: () => (
          <Input
            label="Last Name"
            model="personal_details.last_name"
            validations={{
              presence: {
                message: 'Please enter your last name',
              },
            }}
          />
        ),
      },
      {
        label: 'Have you ever been known by an alternate name?',
        model: 'alternate_name',
        isDisabled: true,
        isChecked: true,
        component: () => (
          <>
            <YesNoRadioGroup label="Have you ever been known by an alternate name?" model="personal_details.has_alternate_name" />
            <ContextShow when="personal_details.has_alternate_name" is={true}>
              <Input
                label="Alternate Name"
                model="personal_details.alternate_name"
                validations={{
                  presence: {
                    message: 'Please enter your alternate name',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Date of Birth',
        model: 'dob',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: () => (
          <DateOfBirthInput
            label="Date of Birth"
            model="personal_details.dob"
            validations={{
              presence: {
                message: 'Please enter a date of birth',
              },
            }}
          />
        ),
      },
      {
        label: 'Address',
        model: 'address',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <AddressSelectorInput
            label="Address"
            actionLabel="Address"
            model="personal_details.address"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter your address',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Contact Details',
    model: 'contact',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: true,
    fields: [
      {
        label: 'In order to process your application we need a way to contact you. Please provide at least one of the following.',
        description: 'Cell Phone, Landline, Email',
        model: 'preferred_methods',
        component: ({ companyName, isRequired }: FieldProps) => (
          <>
            <Alert contrast glyph="info">
              We use the <strong>Cell Phone Number</strong> & <strong>Email</strong> below to text or email you the full application form.
            </Alert>

            <ContactMethodsCheckboxGroup
              label="Please provide at least one of the following Contact Details:"
              model="contact.primary_contact"
              isRequired={isRequired}
            />

            <CheckboxGroup label="Consent to contact" trueIcon="check" falseIcon="cross" falseStyle="faded">
              <Checkbox
                label={`I consent to be contacted by ${companyName} via SMS, email, or phone using the information I provided for the purposes of reviewing my application.`}
                model="contact.is_allowed_to_contact"
              />
            </CheckboxGroup>
          </>
        ),
      },
    ],
  },
  {
    title: 'Emergency Contact Details',
    model: 'emergency_contact',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: true,
    fields: [
      {
        label: 'Emergency Contact Name',
        model: 'emergency_contact_name',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Input
            label="Emergency Contact Name"
            model="emergency_contact.emergency_contact_name"
            validations={
              isRequired && {
                presence: {
                  message: 'Please add your answer',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Emergency Contact Cell Phone',
        model: 'emergency_contact_phone_no',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <PhoneInput
            label="Emergency Contact Cell Phone"
            model="emergency_contact.emergency_contact_phone_no"
            validations={
              isRequired && {
                presence: {
                  message: 'Please add your answer',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Emergency Contact Relationship to You',
        model: 'emergency_contact_relationship',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Input
            label="Emergency Contact Relationship to You"
            model="emergency_contact.emergency_contact_relationship"
            validations={
              isRequired && {
                presence: {
                  message: 'Please add your answer',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Demographics',
    model: 'demographics',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    subtitle: 'Demographics choices based on the US Census',
    fields: [
      {
        label: 'Ethnicity',
        model: 'new_ethnicity',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => <NewEthnicityCheckboxGroup model="demographics.new_ethnicity" isRequired={isRequired} />,
      },
      {
        label: 'Race',
        model: 'new_race',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => <RaceCheckboxGroup model="demographics.new_race" isRequired={isRequired} />,
      },
      {
        label: 'Sex Assigned at Birth',
        model: 'sex',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <SexSelect label="Sex Assigned at Birth" model="demographics.sex" isRequired={isRequired} />
        ),
      },
      {
        label: 'Sexual Orientation',
        model: 'sexual_orientation',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <SexualOrientationCheckboxGroup label="Sexual Orientation" model="demographics.sexual_orientation" isRequired={isRequired} />
        ),
      },
      {
        label: 'Gender Identity',
        model: 'gender_identity',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <GenderIdentitySelect label="Gender Identity" model="demographics.gender_identity" isRequired={isRequired} />
        ),
      },
      {
        label: 'Part of Priority Populations',
        model: 'part_of_priority_populations',
        isDisabled: false,
        isRequiredDisabled: true,
        component: () => (
          <CheckboxGroup label="Part of Priority Populations" layout="vertical-dense" trueIcon="check" falseStyle="hidden">
            <Checkbox label="IV Drug Users" model="demographics.part_of_priority_populations.iv_drug_users" />
            <Checkbox label="Pregnant Individuals" model="demographics.part_of_priority_populations.pregnant_individuals" />
            <Checkbox
              label="Mothers with Dependent Children"
              model="demographics.part_of_priority_populations.mothers_with_dependent_children"
            />
            <Checkbox
              label="Fathers with Dependent Children"
              model="demographics.part_of_priority_populations.fathers_with_dependent_children"
            />
            <Checkbox label="Veterans" model="demographics.part_of_priority_populations.veterans" />
            <Checkbox label="Co-Occurring Disorders" model="demographics.part_of_priority_populations.co_occurring_disorders" />
            <Checkbox label="Justice-Involved" model="demographics.part_of_priority_populations.justice_involved" />
            <Checkbox label="Homeless" model="demographics.part_of_priority_populations.homeless" />
            <Checkbox label="LGBTQ+" model="demographics.part_of_priority_populations.lgbtq" />
            <Checkbox label="Indigenous" model="demographics.part_of_priority_populations.indigenous" />
            <Checkbox label="Disabled" model="demographics.part_of_priority_populations.disabled" />
            <Checkbox
              label="DV / Human Trafficking Victims"
              model="demographics.part_of_priority_populations.dv_human_trafficking_victims"
            />
          </CheckboxGroup>
        ),
      },
      {
        label: 'Weight',
        model: 'weight',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => <WeightInput model="demographics.weight" isRequired={isRequired} />,
      },
      {
        label: 'Height',
        model: 'height',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          // TODO: (@Andrei) add validation
          <HeightInput model="demographics.height" isRequired={isRequired} />
        ),
      },
      {
        label: 'Are you currently enrolled in school?',
        model: 'school',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Are you currently enrolled in school?"
              yesLabel="Yes, I am enrolled in school"
              noLabel="No, I am not"
              model="demographics.is_enrolled_in_school"
              layout="vertical-dense"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="demographics.is_enrolled_in_school" is={false}>
              <YesNoRadioGroup
                label="Do you plan on continuing your education?"
                yesLabel="Yes, I do plan on continuing my education"
                noLabel="No, I don't"
                model="demographics.will_continue_education"
                layout="vertical-dense"
              />

              <ContextShow when="demographics.will_continue_education" is={true}>
                <RadioGroup
                  label="Will you continue as a full-time or as a part-time student?"
                  model="demographics.education_type"
                  layout="vertical-dense"
                >
                  <Radio label="Full-time student" value="full_time" />
                  <Radio label="Part-time student" value="part_time" />
                </RadioGroup>
              </ContextShow>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Highest level of education',
        model: 'highest_education_level',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <HighestEducationLevelSelect
            label="What is the highest level of education you completed?"
            model="demographics.highest_education_level"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Religious Preference',
        model: 'religious_preference',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <ReligiousPreferenceSelect
            label="What is your religious preference?"
            model="demographics.religious_preference"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'What is your primary language?',
        model: 'primary_language',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <PrimaryLanguageSelect label="What is your primary language?" model="demographics.primary_language" isRequired={isRequired} />
        ),
      },
      {
        label: 'What is your language preference?',
        model: 'language_preference',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <PrimaryLanguageSelect
            label="What is your language preference?"
            model="demographics.language_preference"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Military Status',
        model: 'military_status',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <RadioGroup
            label="Military Status"
            model="demographics.military_status"
            layout="vertical-dense"
            validations={
              isRequired && {
                presence: {
                  message: 'Please select an answer',
                },
              }
            }
          >
            <Radio label="No Military Service" value="no_military_service" />
            <Radio label="Currently Enlisted Active Duty" value="currently_enlisted_active_duty" />
            <Radio label="Currently Enlisted Reserves" value="currently_enlisted_reserves" />
            <Radio label="Veteran" value="veteran" />
          </RadioGroup>
        ),
      },
      {
        label: 'Discharge Status',
        model: 'military_discharge_status',
        tooltip: 'This question is applicable only when selecting "Veteran" for "Military Status"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="demographics.military_status" is={'veteran'}>
            <Input
              label="Discharge Status"
              model="demographics.military_discharge_status"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please enter your discharge status',
                  },
                }
              }
            />
          </ContextShow>
        ),
      },
      {
        label: 'Branch of Last Service',
        model: 'military_branch_of_last_service',
        tooltip: 'This question is applicable only when selecting "Veteran" for "Military Status"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="demographics.military_status" is={'veteran'}>
            <RadioGroup
              label="Branch of Last Service"
              model="demographics.military_branch_of_last_service"
              layout="vertical-dense"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select an answer',
                  },
                }
              }
            >
              <Radio label="Army" value="army" />
              <Radio label="Navy" value="navy" />
              <Radio label="Air Force" value="air_force" />
              <Radio label="Marine Corps" value="marine_corps" />
              <Radio label="Coast Guard" value="coast_guard" />
              <Radio label="Marine Corps O" value="marine_corps_o" />
              <Radio label="National Guard" value="national_guard" />
              <Radio label="Space Force" value="space_force" />
            </RadioGroup>
          </ContextShow>
        ),
      },
      {
        label: 'Length of Military Service',
        model: 'military_service_length',
        tooltip:
          'This question is applicable only when selecting "Currently Enlisted Active Duty", "Currently Enlisted Reserves", or "Veteran" for "Military Status"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow
            orMode
            when="demographics.military_status"
            within={['currently_enlisted_active_duty', 'currently_enlisted_reserves', 'veteran']}
          >
            <Input
              label="Length of Military Service"
              model="demographics.military_service_length"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please add your answer',
                  },
                }
              }
            />
          </ContextShow>
        ),
      },
      {
        label: 'Highest Rank',
        model: 'military_highest_rank',
        tooltip:
          'This question is applicable only when selecting "Currently Enlisted Active Duty", "Currently Enlisted Reserves", or "Veteran" for "Military Status"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow
            orMode
            when="demographics.military_status"
            within={['currently_enlisted_active_duty', 'currently_enlisted_reserves', 'veteran']}
          >
            <Input
              label="Highest Rank"
              model="demographics.military_highest_rank"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please add your answer',
                  },
                }
              }
            />
          </ContextShow>
        ),
      },
      {
        label: 'Do You Have a DD-214?',
        model: 'has_dd214',
        tooltip:
          'This question is applicable only when selecting "Currently Enlisted Active Duty", "Currently Enlisted Reserves", or "Veteran" for "Military Status"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow
            orMode
            when="demographics.military_status"
            within={['currently_enlisted_active_duty', 'currently_enlisted_reserves', 'veteran']}
          >
            <YesNoRadioGroup
              label="Do You Have a DD-214?"
              model="demographics.has_dd214"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please add your answer',
                  },
                }
              }
            />
          </ContextShow>
        ),
      },
      {
        label: 'Are you a veteran?',
        model: 'is_veteran',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <RadioGroup
              label="Are you a veteran?"
              model="demographics.is_veteran"
              layout="horizontal-dense"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="demographics.is_veteran" is={true}>
              <RadioGroup
                label="Status:"
                model="demographics.veteran_status"
                layout="vertical-dense"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please select a response',
                    },
                  }
                }
              >
                <Radio label="Currently on active duty" value="currently_on_active_duty" />
                <Radio label="Served on active duty" value="served_on_active_duty" />
              </RadioGroup>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Hearing Status',
        model: 'hearing_status',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Input
            label="Hearing Status"
            model="demographics.hearing_status"
            validations={
              isRequired && {
                presence: {
                  message: 'Please add your answer',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Seeing Status',
        model: 'seeing_status',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Input
            label="Seeing Status"
            model="demographics.seeing_status"
            validations={
              isRequired && {
                presence: {
                  message: 'Please add your answer',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do You Have a Service Animal?',
        model: 'has_service_animal',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroup
            label="Do You Have a Service Animal?"
            model="demographics.has_service_animal"
            validations={
              isRequired && {
                presence: {
                  message: 'Please add your answer',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Service Animal Type',
        model: 'service_animal_type',
        tooltip: 'This question is applicable only when selecting "Yes" for "Do You Have a Service Animal?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="demographics.has_service_animal" is={true}>
            <Input
              label="Service Animal Type"
              model="demographics.service_animal_type"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please add your answer',
                  },
                }
              }
            />
          </ContextShow>
        ),
      },
      {
        label: 'Service(s) Provided',
        model: 'service_animal_services_provided',
        tooltip: 'This question is applicable only when selecting "Yes" for "Do You Have a Service Animal?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="demographics.has_service_animal" is={true}>
            <Textarea
              label="Service(s) Provided"
              model="demographics.service_animal_services_provided"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please add your answer',
                  },
                }
              }
            />
          </ContextShow>
        ),
      },
    ],
  },
  {
    title: 'Questions',
    model: 'custom',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    renderSettings: () => (
      <DataArraySection maxWidth={false} itemTitle="Question" model="schema.custom.questions">
        {(id: any) => (
          <div className="flex items-center">
            <div className="flex-auto mr-4">
              <Input placeholder="Enter your question here…" maxWidth="100%" model={`${id}.question`} />
            </div>
            <Checkbox label="Required" trueIcon="check" falseIcon="cross" falseStyle="faded" model={`${id}.required`} />
          </div>
        )}
      </DataArraySection>
    ),
    renderForm: ({ questions }) => (
      <>
        {questions?.map((o) => (
          <Textarea
            label={o.question}
            model={`custom.${snakeCase(o.question)}`}
            validations={
              o.required && {
                presence: {
                  message: 'Please enter a response',
                },
              }
            }
          />
        ))}
      </>
    ),
  },
  {
    title: 'Medical Insurance',
    model: 'medical_insurance',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Do you have medical insurance?',
        model: 'has_medical_insurance',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you have medical insurance?"
              model="medical_insurance.has_medical_insurance"
              isRequired={isRequired}
            />

            <ContextShow when="medical_insurance.has_medical_insurance" is={true}>
              <FormCard title="Insurance Details">
                <InsurancePolicyInputs model="medical_insurance.insurance_policy" />
              </FormCard>
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Program Cost',
    model: 'program_cost',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    description: {
      label: 'Description',
      placeholder: 'Write your program cost description here…',
    },
    fields: [
      {
        label: 'How will you pay for the program?',
        description: 'Self-pay, private payer, insurance, other',
        model: 'program_payment_options',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <CheckboxGroup
              label="How will you pay for the program?"
              description="You can select one or multiple options"
              layout="vertical-dense"
              trueIcon="check"
              falseIcon="cross"
              falseStyle="faded"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select at least one payment option',
                  },
                }
              }
            >
              <Checkbox label="I will pay myself" model="program_cost.has_self_pay" />
              <Checkbox label="Someone else will pay" model="program_cost.has_private_payer" />
              <Checkbox label="Insurance will pay" model="program_cost.has_insurance" />
              <Checkbox label="I need financial assistance" model="program_cost.need_financial_assistance" />
              <Checkbox label="Other" model="program_cost.has_other_payment_method" />
            </CheckboxGroup>

            <ContextShow when="program_cost.has_private_payer" is={true}>
              <FormCard title="Private Payer Details">
                <Input
                  label="First Name"
                  model="program_cost.private_payer.first_name"
                  validations={{
                    presence: {
                      message: 'Please enter a first name',
                    },
                  }}
                />
                <Input
                  label="Last Name"
                  model="program_cost.private_payer.last_name"
                  validations={{
                    presence: {
                      message: 'Please enter a last name',
                    },
                  }}
                />
                <RelationshipSelect label="Relationship" model="program_cost.private_payer.relationship" isRequired />
                <PhoneInput label="Phone Number" model="program_cost.private_payer.phone_no" />
                <EmailInput label="Email Address" model="program_cost.private_payer.email" />
                <AddressInputs model="program_cost.private_payer.address" />
                <PreferredPaymentMethodRadioGroup
                  label="What is the likely preferred payment method? (can be changed later)"
                  model="program_cost.private_payer.preferred_payment_method"
                />
              </FormCard>
            </ContextShow>

            <ContextShow when="program_cost.need_financial_assistance" is={true}>
              <FormCard title="Financial Assistance">
                <Textarea label="Please describe your financial situation:" model="program_cost.financial_assistance_notes" minRows={10} />
              </FormCard>
            </ContextShow>

            <ContextShow when="program_cost.has_other_payment_method" is={true}>
              <FormCard title="Other Payment Method">
                <Textarea
                  label="Please describe how you would like to pay for the program"
                  model="program_cost.other_payment_method"
                  minRows={10}
                  validations={
                    isRequired && {
                      presence: {
                        message: 'Please enter a payment description',
                      },
                    }
                  }
                />
              </FormCard>
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Program Details',
    model: 'program_details',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Have you previously been part of this program?',
        model: 'is_past_client',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroup
            label="Have you previously been part of this program?"
            model="program_details.is_past_client"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you have any concerns with sharing a room?',
        model: 'can_share_room',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have any concerns with sharing a room?"
            model="program_details.can_share_room"
            textareaLabel="Please describe your concerns"
            isRequired={isRequired}
            textareaRequiredMessage="Please describe your concerns with sharing a room"
          />
        ),
      },
      {
        label: 'Are you able to sleep in a bunk bed?',
        model: 'can_sleep_in_bunk_bed',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Are you able to sleep in a bunk bed?"
              model="program_details.can_sleep_in_bunk_bed"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="program_details.can_sleep_in_bunk_bed" is={false}>
              <Textarea
                label="Please provide more details"
                model="program_details.can_sleep_in_bunk_bed_description"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please provide more details',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Are you able to perform household chores?',
        model: 'can_perform_household_chores',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Are you able to perform household chores?"
              model="program_details.can_perform_household_chores"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="program_details.can_perform_household_chores" is={false}>
              <Textarea
                label="Please provide more details"
                model="program_details.can_perform_household_chores_description"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please provide more details',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label:
          'Would you be interested in living at a faith-based or spiritually-based recovery house if there was space available for you?',
        model: 'is_ok_with_faith_based_house',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroup
            label="Would you be interested in living at a faith-based or spiritually-based recovery house if there was space available for you"
            model="program_details.is_ok_with_faith_based_house"
            validations={
              isRequired && {
                presence: {
                  message: 'Please select a response',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Current Living Situation',
    model: 'living_situation',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'What best describes your current living situation?',
        model: 'situation',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <div className="mt-2 italic">
              <Alert type="positive">
                <P css={{ marginBottom: '0.25rem', fontWeight: 600 }}>We understand that everyone has a unique journey.</P>

                <P>
                  In order to better understand your transition to our program it is helpful to understand your current living situation.
                </P>
              </Alert>
            </div>

            <RadioGroup
              label="What best describes your current living situation?"
              model="living_situation.current.type"
              layout="vertical-dense"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            >
              <Radio label="I am living by myself" value="alone" />
              <Radio label="I am living with my family/relatives" value="family" />
              <Radio label="I am living with my roommate(s)" value="roommates" />
              <Radio label="I am renting a room or apartment" value="renting_room_or_apartment" />
              <Radio label="I am living in a home I own" value="own_home" />
              <Radio label="I am living in a hotel/motel" value="hotel_motel" />
              <Radio label="I am living in a shelter" value="shelter" />
              <Radio label="I am living in a temporary/transitional housing (including recovery housing)" value="temporary_housing" />
              <Radio label="I am living at a program, facility, or institution" value="program_facility_or_institution" />
              <Radio label="I am living in a vehicle" value="vehicle" />
              <Radio label="I have no permanent place to live and I am currently experiencing homelessness" value="homelessness" />
            </RadioGroup>

            {/* Alone | Family | Roommates */}
            <ContextShow when="living_situation.current.type" not={undefined}>
              <ContextShow when="living_situation.current.type" not="homelessness">
                <ContextShow
                  when="living_situation.current.type"
                  notWithin={['program_facility_or_institution', 'vehicle', 'homelessness', 'hotel_motel', 'shelter']}
                >
                  {/* Residential Address */}
                  <FormCard title="Residential Address">
                    <AddressInputs model="living_situation.current.residential_address" />
                  </FormCard>

                  <YesNoRadioGroup
                    label="Is this your primary mailing address?"
                    model="living_situation.current.is_primary_mailing_address"
                  />

                  {/* Mailing Address */}
                  <ContextShow when="living_situation.current.is_primary_mailing_address" is={false}>
                    <YesNoRadioGroup label="Do you have a mailing address?" model="living_situation.current.has_mailing_address" />

                    <ContextShow when="living_situation.current.has_mailing_address" is={true}>
                      <FormCard title="Mailing Address">
                        <AddressInputs model="living_situation.current.mailing_address" />
                      </FormCard>
                    </ContextShow>
                  </ContextShow>

                  {/* Family */}
                  <ContextShow when="living_situation.current.type" is="family">
                    <RadioGroup
                      label="What best describes your family right now?"
                      model="living_situation.current.has_supportive_family"
                      layout="vertical-dense"
                    >
                      <Radio label="My family is supportive" value={true} />
                      <Radio label="My family is unsupportive" value={false} />
                    </RadioGroup>

                    <ContextShow when="living_situation.current.has_supportive_family" not={undefined}>
                      <Textarea
                        label="Please describe your family situation"
                        model="living_situation.current.has_supportive_family_description"
                      />
                    </ContextShow>
                  </ContextShow>

                  {/* Roommates */}
                  <ContextShow when="living_situation.current.type" is="roommates">
                    <RadioGroup
                      label="What best describes your roommate(s) right now?"
                      model="living_situation.current.has_supportive_roommates"
                      layout="vertical-dense"
                    >
                      <Radio label="They are supportive" value={true} />
                      <Radio label="They are unsupportive" value={false} />
                    </RadioGroup>

                    <ContextShow when="living_situation.current.has_supportive_roommates" not={undefined}>
                      <Textarea
                        label="Please describe your roommate(s) situation"
                        model="living_situation.current.has_supportive_roommates_description"
                      />
                    </ContextShow>
                  </ContextShow>

                  {/* Length of Stay */}
                  <RadioGroup
                    label="How long have you lived at the residential address above?"
                    model="living_situation.current.length_of_stay"
                    layout="vertical-dense"
                  >
                    <Radio label="1 year or less" value="1_year_or_less" />
                    <Radio label="1 to 5 years" value="1_to_5_years" />
                    <Radio label="Over 5 years" value="over_5_years" />
                  </RadioGroup>

                  {/* Will Return */}
                  <RadioGroup
                    label="Do you plan to return to this living situation?"
                    model="living_situation.current.will_return"
                    layout="vertical-dense"
                  >
                    <Radio label="Yes" value="yes" />
                    <Radio label="No" value="no" />
                    <Radio label="Not Sure" value="not_sure" />
                  </RadioGroup>

                  <ContextShow when="living_situation.current.will_return" is="no">
                    <Textarea label="Please explain" model="living_situation.current.will_return_description" />
                  </ContextShow>

                  <ContextShow when="living_situation.current.will_return" is="not_sure">
                    <Textarea label="Please explain" model="living_situation.current.will_return_description" />
                  </ContextShow>

                  <YesNoRadioGroupWithTextarea
                    label="Are you at risk of losing your living situation due to financial hardship?"
                    model="living_situation.current.does_risk_losing"
                  />
                </ContextShow>
              </ContextShow>
            </ContextShow>

            {/* Homelessness */}
            <ContextShow when="living_situation.current.type" is="homelessness">
              <RadioGroup
                label="How long have you been experiencing homelessness?"
                model="living_situation.current.homelessness_length"
                layout="vertical-dense"
              >
                <Radio label="1 to 7 days" value="1_to_7_days" />
                <Radio label="2 to 3 weeks" value="2_to_3_weeks" />
                <Radio label="1 to 6 months" value="1_to_6_months" />
                <Radio label="More than 6 months" value="more_than_6_months" />
              </RadioGroup>
            </ContextShow>

            {/* Previous Living Situation */}
            <ContextShow when="living_situation.current.type" is="program_facility_or_institution">
              <RadioGroup
                label="What was your living situation before the program, facility, or institution?"
                model="living_situation.previous.type"
                layout="vertical-dense"
              >
                <Radio label="I was living by myself" value="alone" />
                <Radio label="I was living with my family" value="family" />
                <Radio label="I was living with my roommate(s)" value="roommates" />
                <Radio label="I had no permanent place to live and I was experiencing homelessness" value="homelessness" />
              </RadioGroup>

              {/* Alone | Family | Roommates */}
              <ContextShow when="living_situation.previous.type" not={undefined}>
                <ContextShow when="living_situation.previous.type" not="homelessness">
                  {/* Residential Address */}
                  <FormCard title="Residential Address">
                    <AddressInputs model="living_situation.previous.residential_address" />
                  </FormCard>

                  <YesNoRadioGroup
                    label="Is this your primary mailing address?"
                    model="living_situation.previous.is_primary_mailing_address"
                  />

                  {/* Mailing Address */}
                  <ContextShow when="living_situation.previous.is_primary_mailing_address" is={false}>
                    <YesNoRadioGroup label="Do you have a mailing address?" model="living_situation.previous.has_mailing_address" />

                    <ContextShow when="living_situation.previous.has_mailing_address" is={true}>
                      <FormCard title="Mailing Address">
                        <AddressInputs model="living_situation.previous.mailing_address" />
                      </FormCard>
                    </ContextShow>
                  </ContextShow>

                  {/* Family */}
                  <ContextShow when="living_situation.previous.type" is="family">
                    <RadioGroup
                      label="What best describes your family right now?"
                      model="living_situation.previous.has_supportive_family"
                      layout="vertical-dense"
                    >
                      <Radio label="My family is supportive" value={true} />
                      <Radio label="My family is unsupportive" value={false} />
                    </RadioGroup>

                    <ContextShow when="living_situation.previous.has_supportive_family" not={undefined}>
                      <Textarea
                        label="Please describe your family situation"
                        model="living_situation.previous.has_supportive_family_description"
                      />
                    </ContextShow>
                  </ContextShow>

                  {/* Roommates */}
                  <ContextShow when="living_situation.previous.type" is="roommates">
                    <RadioGroup
                      label="What best describes your roommate(s) right now?"
                      model="living_situation.previous.has_supportive_roommates"
                      layout="vertical-dense"
                    >
                      <Radio label="They are supportive" value={true} />
                      <Radio label="They are unsupportive" value={false} />
                    </RadioGroup>

                    <ContextShow when="living_situation.previous.has_supportive_roommates" not={undefined}>
                      <Textarea
                        label="Please describe your roommate(s) situation"
                        model="living_situation.previous.has_supportive_roommates_description"
                      />
                    </ContextShow>
                  </ContextShow>

                  {/* Length of Stay */}
                  <RadioGroup
                    label="How long have you lived at the residential address above?"
                    model="living_situation.previous.length_of_stay"
                    layout="vertical-dense"
                  >
                    <Radio label="1 year or less" value="1_year_or_less" />
                    <Radio label="1 to 5 years" value="1_to_5_years" />
                    <Radio label="Over 5 years" value="over_5_years" />
                  </RadioGroup>

                  {/* Will Return */}
                  <RadioGroup
                    label="Do you plan to return to this living situation?"
                    model="living_situation.previous.will_return"
                    layout="vertical-dense"
                  >
                    <Radio label="Yes" value="yes" />
                    <Radio label="No" value="no" />
                    <Radio label="Not Sure" value="not_sure" />
                  </RadioGroup>

                  <ContextShow when="living_situation.previous.will_return" is="no">
                    <Textarea label="Please explain" model="living_situation.previous.will_return_description" />
                  </ContextShow>

                  <ContextShow when="living_situation.previous.will_return" is="not_sure">
                    <Textarea label="Please explain" model="living_situation.previous.will_return_description" />
                  </ContextShow>

                  <YesNoRadioGroupWithTextarea
                    label="Are you at risk of losing your living situation due to financial hardship?"
                    model="living_situation.previous.does_risk_losing"
                  />
                </ContextShow>
              </ContextShow>

              {/* Homelessness */}
              <ContextShow when="living_situation.previous.type" is="homelessness">
                <RadioGroup
                  label="How long have you been experiencing homelessness?"
                  model="living_situation.previous.homelessness_length"
                  layout="vertical-dense"
                >
                  <Radio label="1 to 7 days" value="1_to_7_days" />
                  <Radio label="2 to 3 weeks" value="2_to_3_weeks" />
                  <Radio label="1 to 6 months" value="1_to_6_months" />
                  <Radio label="More than 6 months" value="more_than_6_months" />
                </RadioGroup>
              </ContextShow>

              <div className="mt-2 italic">
                <Alert glyph="info">
                  <P>We will ask you more about your treatment history below.</P>
                </Alert>
              </div>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Who currently lives with you?',
        model: 'other_residents',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <CheckboxGroup
            label="Who currently lives with you?"
            layout="vertical-dense"
            validations={
              isRequired && {
                presence: {
                  message: 'Please add your answer',
                },
              }
            }
          >
            <Checkbox label="Myself" model="living_situation.other_residents.myself" />
            <Checkbox label="A Significant Other" model="living_situation.other_residents.significant_other" />
            <Checkbox label="My Children" model="living_situation.other_residents.children" />
            <Checkbox label="My Significant Other’s Children" model="living_situation.other_residents.significant_other_children" />
            <Checkbox label="Other Relatives" model="living_situation.other_residents.other_relatives" />
            <Checkbox label="Non-Relative Roommate(s)" model="living_situation.other_residents.non_relative_roommates" />
          </CheckboxGroup>
        ),
      },
      {
        label: 'Have you ever lived…?:',
        model: 'previous_living_situation',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <CheckboxGroup
            label="Have you ever lived…?"
            layout="vertical-dense"
            validations={
              isRequired && {
                presence: {
                  message: 'Please add your answer',
                },
              }
            }
          >
            <Checkbox label="Unsheltered (Outside)" model="living_situation.previous_living_situation.unsheltered" />
            <Checkbox label="In a Vehicle" model="living_situation.previous_living_situation.vehicle" />
            <Checkbox label="In a Motel/Hotel" model="living_situation.previous_living_situation.hotel_motel" />
            <Checkbox label="With a Relative/Friend Temporarily" model="living_situation.previous_living_situation.temporary_relative" />
            <Checkbox label="In a Shelter" model="living_situation.previous_living_situation.shelter" />
          </CheckboxGroup>
        ),
      },
      {
        label: 'What is your current housing goal?',
        model: 'current_housing_goal',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <CheckboxGroup
              label="What is your current housing goal?"
              layout="vertical-dense"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please add your answer',
                  },
                }
              }
            >
              <Checkbox label="Get Into Sober-Living/Chem-Free" model="living_situation.current_housing_goal.sober_living" />
              <Checkbox label="Get Into Other Recovery House" model="living_situation.current_housing_goal.other_recovery_house" />
              <Checkbox label="Rent My Own Place" model="living_situation.current_housing_goal.rent_own_place" />
              <Checkbox label="Rent a Better Place" model="living_situation.current_housing_goal.rent_better_place" />
              <Checkbox label="Become a Homeowner" model="living_situation.current_housing_goal.become_homeowner" />
              <Checkbox label="Other" model="living_situation.current_housing_goal.other" />
            </CheckboxGroup>

            <ContextShow when="living_situation.current_housing_goal.other" is={true}>
              <Textarea
                label="Please describe your current housing goal"
                model="living_situation.current_housing_goal_other"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe your current housing goal',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Have you ever been in another housing program within the last 90 days?',
        model: 'has_recent_housing_program',
        isDisabled: false,
        component: () => (
          <>
            <YesNoRadioGroupWithTextarea
              label="Have you ever been in another housing program within the last 90 days?"
              textareaLabel="Please mention the reasons for entering and leaving the program"
              model="living_situation.has_recent_housing_program"
            />
          </>
        ),
      },
    ],
  },
  {
    title: 'Personal Contacts (Family)',
    model: 'family',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        // TODO: (@Andrei) how do we add validation in this case?
        label: 'Please add at least two personal contacts',
        model: 'personal_contacts',
        isDisabled: false,
        isRequired: false,
        component: ({ roiDisclaimerMessage }) => (
          <DataArraySection
            title="Personal Contacts"
            description="Please add at least two personal contacts"
            itemTitle="Personal Contact"
            model="family.personal_contacts"
            children={(idx: string) => <ContactInputs model={idx} roiDisclaimerMessage={roiDisclaimerMessage} />}
          />
        ),
      },
      {
        label: 'What is your marital status?',
        model: 'marital_status',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <MaritalStatusRadioGroup label="What is your marital status?" model="family.marital_status" isRequired={isRequired} />
        ),
      },
      {
        label: 'How Long Has This Been Your Marital Status?',
        model: 'marital_status_length',
        tooltip: 'This questions is only applicable when selecting a marital status',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="family.marital_status" not="null">
            <Input
              label="How Long Has This Been Your Marital Status?"
              model="family.marital_status_length"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please add your answer',
                  },
                }
              }
            />
          </ContextShow>
        ),
      },
      {
        label: 'Are you fleeing a domestic violence situation?',
        model: 'domestic_violence',
        isDisabled: false,
        component: ({ isRequired, roiDisclaimerMessage }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Are you fleeing a domestic violence situation?"
              model="family.is_fleeing_domestic_violence"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="family.is_fleeing_domestic_violence" is={true}>
              <Textarea
                label="Please describe your situation"
                model="family.domestic_violence_description"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe your situation',
                    },
                  }
                }
              />

              <YesNoRadioGroup
                label="Have you notified the authorities about your domestic violence situation?"
                model="family.has_notified_domestic_violence_autorities"
              />

              <ContextShow when="family.has_notified_domestic_violence_autorities" is={true}>
                <Input label="Domestic Violence Case Reference Number" model="family.domestic_violence_case_reference_number" />

                <FormCard title="Domestic Violence Case Worker">
                  <ContactInputs
                    model="family.domestic_violence_case_worker"
                    relationship="domestic_violence_case_worker"
                    roiDisclaimerMessage={roiDisclaimerMessage}
                  />
                </FormCard>
              </ContextShow>

              <ContextShow when="family.has_notified_domestic_violence_autorities" is={false}>
                <YesNoRadioGroup
                  label="Do you need assistance notifying the authorities?"
                  model="family.does_need_assistance_notifying_domestic_violence_authorities"
                />

                <Textarea
                  label="Please provide any other details that might be helpful"
                  model="family.does_need_assistance_notifying_domestic_violence_authorities_description"
                />
              </ContextShow>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Are you in the process of family reunification?',
        model: 'family_reunification',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Are you in the process of family reunification?"
            model="family.goes_through_family_reunification"
            isRequired={isRequired}
            textareaRequiredMessage="Please describe your situation"
          />
        ),
      },
      {
        label: 'Do you have children?',
        model: 'children',
        isDisabled: false,
        component: ({ isRequired, roiDisclaimerMessage }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you have children?"
              model="family.has_children"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="family.has_children" is={true}>
              <Textarea
                label="Please describe your situation"
                description="i.e. how many children, their ages, etc"
                model="family.children_description"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe your situation',
                    },
                  }
                }
              />

              <YesNoRadioGroup
                label="Do you have legal custody of your children?"
                description="Legal custody means you have been to court and have paperwork"
                model="family.has_children_custody"
              />

              <YesNoRadioGroup
                label="Do you currently have an open case with Children’s Services?"
                model="family.has_childrens_services_open_case"
              />

              <ContextShow when="family.has_childrens_services_open_case" is={true}>
                <Textarea label="Please describe the situation" model="family.childrens_services_open_case.description" />

                <Input label="Children’s Services Case Reference Number" model="family.childrens_services_open_case.reference_number" />

                <FormCard title="Social Services Case Worker">
                  <ContactInputs
                    model="family.childrens_services_open_case.case_worker"
                    relationship="social_services_case_worker"
                    roiDisclaimerMessage={roiDisclaimerMessage}
                  />
                </FormCard>
              </ContextShow>

              <YesNoRadioGroup label="Do you have a child support obligation?" model="family.has_child_support_obligation" />

              <ContextShow when="family.has_child_support_obligation" is={true}>
                <AmountInput label="How much per month?" suffix="/ month" model="family.child_support_monthly_amount" />
              </ContextShow>

              <YesNoRadioGroupWithTextarea
                label="Do you need childcare assistance while in treatment or at a SLE?"
                model="family.needs_childcare_assistance_during_treatment"
              />
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Substance Use History',
    model: 'substance_use_history',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Drug(s) of Choice',
        model: 'drugs_of_choice',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <DrugsOfChoiceSelector model="substance_use_history.drugs_of_choice" isRequired={isRequired} />
        ),
      },
      {
        label: 'Have you used any substances in the past 6 months?',
        model: 'has_used_in_past_six_months',
        description: 'Please list all substances you have ever used in the space below, including best estimate of dates and time periods.',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Have you used any substances in the past 6 months?"
              model="substance_use_history.has_used_in_past_six_months"
              isRequired={isRequired}
            />

            <ContextShow when="substance_use_history.has_used_in_past_six_months" is={true}>
              <DataArraySection
                description="Please list all substances you have ever used below, including best estimate of dates and time periods."
                itemTitle="Substance"
                model="substance_use_history.substances"
                children={(id: string) => (
                  <>
                    <Input
                      label="Substance"
                      model={`${id}.substance`}
                      validations={{
                        presence: {
                          message: 'Please add your answer',
                        },
                      }}
                    />

                    <Input
                      label="Route of Administration"
                      model={`${id}.route_of_administration`}
                      validations={{
                        presence: {
                          message: 'Please add your answer',
                        },
                      }}
                    />

                    <Input
                      label="First Use"
                      model={`${id}.first_use`}
                      validations={{
                        presence: {
                          message: 'Please add your answer',
                        },
                      }}
                    />

                    <Input
                      label="Frequency of Use"
                      model={`${id}.frequency_of_use`}
                      validations={{
                        presence: {
                          message: 'Please add your answer',
                        },
                      }}
                    />

                    <Input
                      label="Duration of Use"
                      model={`${id}.duration_of_use`}
                      validations={{
                        presence: {
                          message: 'Please add your answer',
                        },
                      }}
                    />

                    <Input
                      label="Last Use"
                      model={`${id}.last_use`}
                      validations={{
                        presence: {
                          message: 'Please add your answer',
                        },
                      }}
                    />
                  </>
                )}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'What were the last drugs used and when?',
        model: 'last_drug_used_and_date',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Textarea
            label="What were the last drugs used and when?"
            model="substance_use_history.last_drug_used_and_date"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter a response',
                },
              }
            }
          />
        ),
      },
      {
        label: 'For how many years have you been using alcohol and/or drugs?',
        model: 'number_years_using_alcohol_or_drugs',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Input
            label="For how many years have you been using alcohol and/or drugs?"
            model="substance_use_history.number_years_using_alcohol_or_drugs"
            type="number"
            suffix="years"
            size={4}
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter a number of years',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you use tobacco?',
        model: 'tobacco',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you use tobacco?"
              model="substance_use_history.does_use_tobacco"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="substance_use_history.does_use_tobacco" is={true}>
              <YesNoRadioGroup label="Would you like to quit using tobacco?" model="substance_use_history.does_want_to_quit_tobacco" />

              <ContextShow when="substance_use_history.does_want_to_quit_tobacco" is={true}>
                <YesNoRadioGroup label="Do you need help with quitting?" model="substance_use_history.does_need_help_quitting_tobacco" />
              </ContextShow>
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Substance Use Full History',
    model: 'substance_use_full_history',
    glyph: 'behave_health',
    hideIsRequired: true,
    isOpen: false,
    fields: [
      {
        label: 'Include Substance Use Full History Form',
        model: 'substance_use_full_history',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Grid gap={16}>
            {UATests.map((substance) => (
              <FormSection key={substance.model} heading={substance.name} layout="vertical">
                <RadioGroup
                  layout="horizontal-dense"
                  label={`Have you ever used ${substance.name?.toLowerCase()}?`}
                  model={`substance_use_full_history.${substance.model}.used`}
                  defaultValue={null}
                >
                  <Radio testKey={`used_${snakeCase(substance.name)}_radio_button`} label="Yes" value={true} />
                  <Radio testKey={`did_not_use_${snakeCase(substance.name)}_radio_button`} label="No" value={false} />
                </RadioGroup>

                <ContextShow when={`substance_use_full_history.${substance.model}.used`} is={true}>
                  {['amphetamines', 'barbituates', 'benzodiazepines'].includes(substance.model) && (
                    <Textarea
                      testKey={`${snakeCase(substance.name)}_substance_type_input`}
                      label="What types of substances have you used?"
                      model={`substance_use_full_history.${substance.model}.substance_type`}
                    />
                  )}

                  <Textarea
                    testKey={`${snakeCase(substance.name)}_acquire_method_input`}
                    label="How did you acquire this substance?"
                    model={`substance_use_full_history.${substance.model}.acquire_method`}
                  />

                  {substance.is_prescriptible && (
                    <>
                      <YesNoRadioGroup
                        testKey={`${snakeCase(substance.name)}_is_prescribed_radio_group`}
                        label="Is this medication prescribed?"
                        model={`substance_use_full_history.${substance.model}.is_prescribed`}
                      />

                      <ContextShow when={`substance_use_full_history.${substance.model}.is_prescribed`} is={true}>
                        <YesNoRadioGroup
                          testKey={`${snakeCase(substance.name)}_is_following_prescription_directions`}
                          label="Are you taking the substance as directed?"
                          model={`substance_use_full_history.${substance.model}.is_following_prescription_directions`}
                        />
                      </ContextShow>
                    </>
                  )}

                  <Input
                    type="number"
                    testKey={`${snakeCase(substance.name)}_first_use_age_input`}
                    label="At what age did you use this substance for the first time?"
                    suffix="y/o"
                    size={4}
                    model={`substance_use_full_history.${substance.model}.first_use_age`}
                  />
                  <Input
                    type="number"
                    testKey={`${snakeCase(substance.name)}_daily_use_age_input`}
                    label="At what age did you begin daily use of this substance?"
                    suffix="y/o"
                    size={4}
                    model={`substance_use_full_history.${substance.model}.daily_use_age`}
                  />

                  <RadioGroup
                    testKey={`${snakeCase(substance.name)}_use_frequency_radio_group`}
                    label="During your heaviest use, how often did you use?"
                    model={`substance_use_full_history.${substance.model}.use_frequency`}
                    layout="vertical-dense"
                  >
                    <Radio testKey={`${snakeCase(substance.name)}_daily_use_frequency_radio_button`} label="Every day" value="daily" />
                    <Radio
                      testKey={`${snakeCase(substance.name)}_4_6_times_per_week_use_frequency_radio_button`}
                      label="4-6 times per week"
                      value="4_6_times_per_week"
                    />
                    <Radio
                      testKey={`${snakeCase(substance.name)}_1_3_times_per_week_use_frequency_radio_button`}
                      label="1-3 times per week"
                      value="1_3_times_per_week"
                    />
                  </RadioGroup>

                  <Textarea
                    testKey={`${snakeCase(substance.name)}_heaviest_use_quantity_input`}
                    label="During your heaviest use, how much did they use per day/week?"
                    model={`substance_use_full_history.${substance.model}.use_quantity`}
                  />

                  <Textarea
                    testKey={`${snakeCase(substance.name)}_current_use_frequency_input`}
                    label="What is your current frequency of use?"
                    model={`substance_use_full_history.${substance.model}.current_use_frequency`}
                  />

                  <Textarea
                    testKey={`${snakeCase(substance.name)}_current_use_frequency_length_input`}
                    label="For how long at this rate?"
                    model={`substance_use_full_history.${substance.model}.current_use_frequency_length`}
                  />

                  <CheckboxGroup
                    testKey={`${snakeCase(substance.name)}_method_of_use_checkbox_group`}
                    label="What was the method of use?"
                    layout="vertical-dense"
                  >
                    <Checkbox
                      testKey={`${snakeCase(substance.name)}_use_method_oral`}
                      label="Oral"
                      model={`substance_use_full_history.${substance.model}.use_method.oral`}
                    />
                    <Checkbox
                      testKey={`${snakeCase(substance.name)}_use_method_smoke`}
                      label="Smoke"
                      model={`substance_use_full_history.${substance.model}.use_method.smoke`}
                    />
                    <Checkbox
                      testKey={`${snakeCase(substance.name)}_use_method_snort`}
                      label="Snort"
                      model={`substance_use_full_history.${substance.model}.use_method.snort`}
                    />
                    <Checkbox
                      testKey={`${snakeCase(substance.name)}_use_method_inhalant`}
                      label="Inhalant"
                      model={`substance_use_full_history.${substance.model}.use_method.inhalant`}
                    />
                    <Checkbox
                      testKey={`${snakeCase(substance.name)}_use_method_intravenous`}
                      label="IV (intravenous)"
                      model={`substance_use_full_history.${substance.model}.use_method.intravenous`}
                    />
                    <Checkbox
                      testKey={`${snakeCase(substance.name)}_use_method_intramuscular`}
                      label="IM (intramuscular)"
                      model={`substance_use_full_history.${substance.model}.use_method.intramuscular`}
                    />
                  </CheckboxGroup>

                  <RadioGroup
                    layout="horizontal-dense"
                    testKey={`${snakeCase(substance.name)}_last_use_date_known_radio_group`}
                    label="Do you know the exact date they last used this substance?"
                    model={`substance_use_full_history.${substance.model}.last_use_date_known`}
                  >
                    <Radio testKey={`${snakeCase(substance.name)}_last_use_date_known`} label="Yes" value={true} />
                    <Radio testKey={`${snakeCase(substance.name)}_last_use_date_unknown`} label="No" value={false} />
                  </RadioGroup>

                  <ContextShow when={`substance_use_full_history.${substance.model}.last_use_date_known`} is={true}>
                    <DateInput
                      testKey={`${snakeCase(substance.name)}_last_use_date_input`}
                      label="Date of last use:"
                      model={`substance_use_full_history.${substance.model}.last_use_date`}
                    />
                  </ContextShow>

                  <ContextShow when={`substance_use_full_history.${substance.model}.last_use_date_known`} is={false}>
                    <Input
                      testKey={`${snakeCase(substance.name)}_last_use_date_approx_input`}
                      label="Approximately how long ago?"
                      model={`substance_use_full_history.${substance.model}.last_use_date_approx`}
                    />
                  </ContextShow>

                  <Divider className="!m-0" />
                </ContextShow>
              </FormSection>
            ))}
          </Grid>
        ),
      },
    ],
  },
  {
    title: 'Medical',
    model: 'medical',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label:
          'Have you ever been diagnosed or treated for the following conditions? For current diagnoses or current treatment, please list medications you are currently taking',
        model: 'medical_conditions',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <Label
              label="Have you ever been diagnosed or treated for the following conditions? For current diagnoses or current treatment, please list medications you are currently taking."
              className="!-mb-3"
            />

            {MEDICAL_HISTORY_CONDITIONS.map((condition) => (
              <FormCard key={condition.model} title={condition.label}>
                <YesNoRadioGroup model={`medical.medical_conditions.${condition.model}`} isRequired={isRequired} />

                <ContextShow when={`medical.medical_conditions.${condition.model}`} is={true}>
                  {condition.withExplain && (
                    <Textarea
                      label="Please explain"
                      model={`medical.medical_conditions.${condition.model}_extra.description`}
                      validations={
                        isRequired && {
                          presence: {
                            message: 'Please add your answer',
                          },
                        }
                      }
                    />
                  )}
                  <DateInput
                    label="Date Diagnosed"
                    model={`medical.medical_conditions.${condition.model}_extra.diagnosed_at`}
                    validations={
                      isRequired && {
                        presence: {
                          message: 'Please add your answer',
                        },
                      }
                    }
                  />
                  <Input
                    label="Physician"
                    model={`medical.medical_conditions.${condition.model}_extra.physician`}
                    validations={
                      isRequired && {
                        presence: {
                          message: 'Please add your answer',
                        },
                      }
                    }
                  />
                  <Textarea
                    label="Medication"
                    model={`medical.medical_conditions.${condition.model}_extra.medication`}
                    validations={
                      isRequired && {
                        presence: {
                          message: 'Please add your answer',
                        },
                      }
                    }
                  />
                </ContextShow>
              </FormCard>
            ))}
          </>
        ),
      },
      {
        label: 'Do you have any allergies?',
        model: 'has_allergies',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have any allergies?"
            model="medical.has_allergies"
            isRequired={isRequired}
            textareaLabel="Please enter your allergies"
            textareaRequiredMessage="Please enter your allergies"
          />
        ),
      },
      {
        label: 'Are you currently under the care of any of the following provider types:',
        description: 'Medical Doctor (Primary Care Physician), Psychiatrist, Psychologist, Therapist',
        model: 'current_providers',
        isDisabled: false,
        component: ({ isRequired, roiDisclaimerMessage }: FieldProps) => (
          <>
            <ProviderTypesCheckboxGroup
              label="Are you currently under the care of any of the following provider types:"
              model="medical.current_providers"
              isRequired={isRequired}
            />

            <ContextShow when="medical.current_providers.medical_doctor" is={true}>
              <FormCard title="Medical Doctor (Primary Care Physician)">
                <ContactInputs model="medical.medical_doctor" relationship="medical_doctor" roiDisclaimerMessage={roiDisclaimerMessage} />
              </FormCard>
            </ContextShow>

            <ContextShow when="medical.current_providers.psychiatrist" is={true}>
              <FormCard title="Psychiatrist">
                <ContactInputs model="medical.psychiatrist" relationship="psychiatrist" roiDisclaimerMessage={roiDisclaimerMessage} />
              </FormCard>
            </ContextShow>

            <ContextShow when="medical.current_providers.psychologist" is={true}>
              <FormCard title="Psychologist">
                <ContactInputs model="medical.psychologist" relationship="psychologist" roiDisclaimerMessage={roiDisclaimerMessage} />
              </FormCard>
            </ContextShow>

            <ContextShow when="medical.current_providers.therapist" is={true}>
              <FormCard title="Therapist">
                <ContactInputs model="medical.therapist" relationship="therapist" roiDisclaimerMessage={roiDisclaimerMessage} />
              </FormCard>
            </ContextShow>

            <ContextShow when="medical.current_providers.nutritionist" is={true}>
              <FormCard title="Nutritionist">
                <ContactInputs model="medical.nutritionist" relationship="nutritionist" roiDisclaimerMessage={roiDisclaimerMessage} />
              </FormCard>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Can you see a doctor when you need to?',
        model: 'can_see_doctor_when_needed',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroup
            label="Can you see a doctor when you need to?"
            model="medical.can_see_doctor_when_needed"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Where do you go for medical attention?',
        model: 'medical_attention_location',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <RadioGroup
              label="Where do you go for medical attention?"
              model="medical.medical_attention_location"
              layout="vertical-dense"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please add your answer',
                  },
                }
              }
            >
              <Radio label="Emergency Room" value="emergency_room" />
              <Radio label="Urgent Care" value="urgent_care" />
              <Radio label="My Primary Care Physician" value="primary_care_physician" />
              <Radio label="Other" value="other" />
            </RadioGroup>

            <ContextShow when="medical.medical_attention_location" is="other">
              <Textarea label="Please specify" model="medical.medical_attention_location_other" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'How would you describe your current physical health?',
        model: 'current_physical_health',
        isDisabled: false,
        component: () => (
          <RadioGroup
            label="How would you describe your current physical health?"
            model="medical.current_physical_health"
            layout="horizontal"
          >
            <Radio label="Good" value="good" />
            <Radio label="Fair" value="fair" />
            <Radio label="Poor" value="poor" />
          </RadioGroup>
        ),
      },
      {
        label: 'Do you have any physical health / medical conditions or disabilities?',
        model: 'has_physical_health_medical_conditions_or_disabilities',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have any physical health / medical conditions or disabilities?"
            model="medical.has_physical_health_medical_conditions_or_disabilities"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do any of the following apply to you?',
        model: 'conditions',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <MedicalConditionsCheckboxGroup
            label="Do any of the following apply to you?"
            model="medical.conditions"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you have a history of seizures?',
        model: 'has_history_of_seizures',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have a history of seizures?"
            textareaLabel="How many and when was the last episode?"
            model="medical.has_history_of_seizures"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you have any upcoming appointments or ongoing physical needs?',
        model: 'has_upcoming_appointments_or_ongoing_physical_needs',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have any upcoming appointments or ongoing physical needs?"
            textareaLabel="Please describe"
            model="medical.has_upcoming_appointments_or_ongoing_physical_needs"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you have any medical equipment?',
        model: 'has_medical_equipment',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <MedicalEquipmentCheckboxGroup
            label="Do you have any medical equipment?"
            model="medical.has_medical_equipment"
            isRequired={isRequired}
          />
        ),
      },
    ],
  },
  {
    title: 'Mental Health',
    model: 'mental_health',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Do you have any mental health issues or diagnosis?',
        model: 'has_mental_health_issues_or_diagnosis',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have any mental health issues or diagnosis?"
            model="mental_health.has_mental_health_issues_or_diagnosis"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you have a history of self-harm?',
        model: 'has_history_of_self_harm',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroupWithTextarea
              label="Do you have a history of self-harm?"
              model="mental_health.has_history_of_self_harm"
              isRequired={isRequired}
            />

            <ContextShow when="mental_health.has_history_of_self_harm" is={true}>
              <YesNoRadioGroup label="Have you self-harmed in the last week?" model="mental_health.has_self_harmed_in_last_week" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Have you ever experienced any suicidal ideations, attempts, or received in-patient treatment for self-harming behaviors?',
        model: 'has_experienced_suicidal_ideations',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroupWithTextarea
              label="Have you ever experienced any suicidal ideations, attempts, or received in-patient treatment for self-harming behaviors?"
              model="mental_health.has_experienced_suicidal_ideations"
              isRequired={isRequired}
            />

            <ContextShow when="mental_health.has_experienced_suicidal_ideations" is={true}>
              <YesNoRadioGroup
                label="Have you had any suicidal ideation in the past week?"
                model="mental_health.has_experienced_suicidal_ideation_in_last_week"
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you have an Eating Disorder or Body Image Disorder?',
        model: 'has_eating_disorder_or_body_image_disorder',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have an Eating Disorder or Body Image Disorder?"
            model="mental_health.has_eating_disorder_or_body_image_disorder"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you have a need for mental health services?',
        model: 'does_need_mental_health_services',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have a need for mental health services?"
            model="mental_health.does_need_mental_health_services"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Have you ever been a victim of sex trafficking?',
        model: 'was_sex_trafficking_victim',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Have you ever been a victim of sex trafficking?"
            model="mental_health.was_sex_trafficking_victim"
            isRequired={isRequired}
            textareaLabel="Please provide details"
            textareaRequiredMessage="Please provide details"
          />
        ),
      },
      {
        label: 'Have you ever been involved in prostitution?',
        model: 'was_involved_in_prostitution',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Have you ever been involved in prostitution?"
            model="mental_health.was_involved_in_prostitution"
            isRequired={isRequired}
            textareaLabel="Please provide details"
            textareaRequiredMessage="Please provide details"
          />
        ),
      },
      {
        label: 'Have you ever gotten in an altercation with a peer?',
        model: 'has_been_in_peer_altercations',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Have you ever gotten in an altercation with a peer?"
            model="mental_health.has_been_in_peer_altercations"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you currently have a purpose in your life?',
        model: 'has_life_purpose',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroup
            label="Do you currently have a purpose in your life?"
            model="mental_health.has_life_purpose"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you currently have goals and hopes for your future?',
        model: 'has_future_goals_hopes',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroup
            label="Do you currently have goals and hopes for your future?"
            model="mental_health.has_future_goals_hopes"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you have an active plan to harm yourself or others?',
        model: 'has_active_plan_to_harm',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroupWithTextarea
              label="Do you have an active plan to harm yourself or others?"
              model="mental_health.has_active_plan_to_harm"
              isRequired={isRequired}
            />
          </>
        ),
      },
    ],
  },
  {
    title: 'Addictive Behaviors',
    model: 'addictive_behaviors',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Do you identify patterns in other areas of your life that may have some addictive qualities?',
        model: 'addiction_areas',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <AddictionAreasCheckboxGroup
            label="Do you identify patterns in other areas of your life that may have some addictive qualities?"
            model="addictive_behaviors.addiction_areas"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you gamble?',
        model: 'gambling',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you gamble?"
              model="addictive_behaviors.gambling.does_gamble"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="addictive_behaviors.gambling.does_gamble" is={true}>
              <Input label="How long have you been gambling?" model="addictive_behaviors.gambling.length_of_gambling" />
              <GamblingTypesCheckboxGroup
                label="Please list the types of gambling (past and present) you have participated in"
                model="addictive_behaviors.gambling.gambling_types"
              />
              <YesNoRadioGroupWithTextarea
                label="Is gambling now or has gambling ever been a problem?"
                model="addictive_behaviors.gambling.has_gambling_been_a_problem"
              />
              <DateInput label="When did you last gamble?" model="addictive_behaviors.gambling.date_last_gambled" />
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Communicable Diseases',
    model: 'infectious_diseases',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Are you at risk for exposure to any communicable diseases, or have you been in contact with someone who has?',
        model: 'is_at_infectious_diseases_exposure_risk',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Are you at risk for exposure to any communicable diseases, or have you been in contact with someone who has?"
            model="infectious_diseases.is_at_infectious_diseases_exposure_risk"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Are you experiencing shortness of breath, coughing, fever, or other symptoms of Coronavirus and/or a flu?',
        model: 'is_experiencing_flu_or_similar_symptoms',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Are you experiencing shortness of breath, coughing, fever, or other symptoms of Coronavirus and/or a flu?"
              model="infectious_diseases.is_experiencing_flu_or_similar_symptoms"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="infectious_diseases.is_experiencing_flu_or_similar_symptoms" is={true}>
              <YesNoRadioGroup label="Have you been tested for the Coronavirus?" model="infectious_diseases.was_tested_for_coronavirus" />

              <ContextShow when="infectious_diseases.was_tested_for_coronavirus" is={true}>
                <RadioGroup
                  label="What was the result of your Coronavirus test?"
                  model="infectious_diseases.coronavirus_test_result"
                  layout="vertical-dense"
                >
                  <Radio label="Positive" value="positive" />
                  <Radio label="Negative" value="negative" />
                  <Radio label="I haven't received the result yet" value="pending" />
                </RadioGroup>
              </ContextShow>

              <ContextShow when="infectious_diseases.was_tested_for_coronavirus" is={false}>
                <YesNoRadioGroup
                  label="Are you willing to be tested for the Coronavirus?"
                  model="infectious_diseases.is_willing_to_test_for_coronavirus"
                />
              </ContextShow>

              <ContextShow when="infectious_diseases.is_willing_to_test_for_coronavirus" is={false}>
                <Textarea
                  label="Please describe the reason for refusing the Coronavirus test"
                  model="infectious_diseases.is_willing_to_test_for_coronavirus_description"
                />
              </ContextShow>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Are you at risk for exposure to Coronavirus?',
        model: 'is_at_coronavirus_exposure_risk',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroup
            label="Are you at risk for exposure to Coronavirus?"
            model="infectious_diseases.is_at_coronavirus_exposure_risk"
            validations={
              isRequired && {
                presence: {
                  message: 'Please select a response',
                },
              }
            }
          />
        ),
      },
      {
        label: 'What is your Coronavirus vaccination status?',
        model: 'coronavirus_vaccination_status',
        isDisabled: false,
        component: () => (
          <RadioGroup
            label="What is your Coronavirus vaccination status?"
            model="infectious_diseases.coronavirus_vaccination_status"
            layout="horizontal"
          >
            <Radio label="Vaccinated" value="vaccinated" />
            <Radio label="Unvaccinated" value="unvaccinated" />
          </RadioGroup>
        ),
      },
      {
        label: 'Do you need assistance with obtaining an appointment to receive a Coronavirus vaccination or a booster?',
        model: 'needs_coronavirus_vaccination_assistance',
        isDisabled: false,
        component: () => (
          <YesNoRadioGroup
            label="Do you need assistance with obtaining an appointment to receive a Coronavirus vaccination or a booster?"
            model="infectious_diseases.needs_coronavirus_vaccination_assistance"
          />
        ),
      },
      {
        label: 'How many times have you tested positive for Coronavirus?',
        model: 'positive_coronavirus_tests_count',
        isDisabled: false,
        component: () => (
          <Input
            type="number"
            label="How many times have you tested positive for Coronavirus?"
            size={6}
            suffix="times"
            model="infectious_diseases.positive_coronavirus_tests_count"
          />
        ),
      },
      {
        label: 'Have you traveled outside of the country in the last 30 days?',
        model: 'has_traveled_outside_country_in_past_month',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Have you traveled outside of the country in the last 30 days?"
            model="infectious_diseases.has_traveled_outside_country_in_past_month"
            isRequired={isRequired}
            textareaLabel="Please list the travel locations"
            textareaRequiredMessage="Please list the travel locations"
          />
        ),
      },
    ],
  },
  {
    title: 'Medications',
    model: 'medications',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Are you currently using any prescription medication?',
        model: 'does_use_prescription_medications',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Are you currently using any prescription medications?"
              model="medications.does_use_prescription_medications"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="medications.does_use_prescription_medications" is={true}>
              <DataArraySection
                description="Please list your prescription medication"
                itemTitle="Prescription Medication"
                model="medications.prescription_medications"
                children={(id: string) => (
                  <>
                    <Flex gap="1rem" stretchChildrenX>
                      <Select
                        fullWidth
                        allowEmpty
                        label="Status"
                        model={`${id}.status`}
                        defaultValue="active"
                        validations={{
                          presence: {
                            message: 'Please select a status',
                          },
                        }}
                      >
                        <Option label="Active" value="active" />
                        <Option label="Inactive" value="inactive" />
                      </Select>

                      <MedicationDescriptionSelect label="Medication Description" model={`${id}.description`} />
                    </Flex>

                    <Input
                      label="Medication Name"
                      model={`${id}.name`}
                      validations={{
                        presence: {
                          message: 'Please enter a medication name',
                        },
                      }}
                    />

                    <Flex gap={8} stretchChildrenX>
                      <Input label="Dosage" type="number" step="0.001" min="0" model={`${id}.dosage`} />
                      <Input label="Unit Type" labelDescription="(eg. mg, g, mL)" model={`${id}.unit`} />
                      <Input label="Quantity" type="number" min="0" model={`${id}.pill_count`} />
                    </Flex>

                    <ContextShow when={`${id}.dates_unknown`} is={false}>
                      <Flex horizontal gap="0.25rem">
                        <DateInput
                          model={`${id}.started_at`}
                          label="Start Date"
                          validations={{
                            presence: {
                              message: 'Please enter a start date',
                            },
                          }}
                        />

                        <DateInput
                          model={`${id}.ended_at`}
                          label="End Date"
                          validations={{
                            presence: {
                              message: 'Please enter an end date',
                            },
                          }}
                        />
                      </Flex>
                    </ContextShow>

                    <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                      <Checkbox label="Start / End Date Unknown" model={`${id}.dates_unknown`} />
                    </CheckboxGroup>
                  </>
                )}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Are you currently using any over-the-counter medication?',
        model: 'does_use_otc_medications',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Are you currently using any over-the-counter medication?"
              model="medications.does_use_otc_medications"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="medications.does_use_otc_medications" is={true}>
              <DataArraySection
                description="Please list your over-the-counter medication"
                itemTitle="OTC Medication"
                model="medications.otc_medications"
                children={(id: string) => (
                  <>
                    <Flex gap="1rem" stretchChildrenX>
                      <Select
                        fullWidth
                        allowEmpty
                        label="Status"
                        model={`${id}.status`}
                        defaultValue="active"
                        validations={{
                          presence: {
                            message: 'Please select a status',
                          },
                        }}
                      >
                        <Option label="Active" value="active" />
                        <Option label="Inactive" value="inactive" />
                      </Select>

                      <MedicationDescriptionSelect label="Medication Description" model={`${id}.description`} />
                    </Flex>

                    <Input
                      label="Medication Name"
                      model={`${id}.name`}
                      validations={{
                        presence: {
                          message: 'Please enter a medication name',
                        },
                      }}
                    />

                    <Flex gap={8} stretchChildrenX>
                      <Input label="Dosage" type="number" step="0.001" min="0" model={`${id}.dosage`} />
                      <Input label="Unit Type" labelDescription="(eg. mg, g, mL)" model={`${id}.unit`} />
                      <Input label="Quantity" type="number" min="0" model={`${id}.pill_count`} />
                    </Flex>

                    <Flex horizontal gap="0.25rem">
                      <DateInput
                        model={`${id}.started_at`}
                        label="Start Date"
                        validations={{
                          presence: {
                            message: 'Please enter a start date',
                          },
                        }}
                      />

                      <DateInput
                        model={`${id}.ended_at`}
                        label="End Date"
                        validations={{
                          presence: {
                            message: 'Please enter an end date',
                          },
                        }}
                      />
                    </Flex>
                  </>
                )}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you have enough prescriptions to last you for the next two weeks?',
        model: 'has_enough_prescriptions_for_two_weeks',
        isDisabled: false,
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Do you have enough prescriptions to last you for the next two weeks?"
            model="medications.has_enough_prescriptions_for_two_weeks"
            showTextareaWhen={false}
          />
        ),
      },
      // {
      //   label: 'How long have you been prescribed MAT for?',
      //   model: 'mat_prescription_duration_months',
      //   isDisabled: false,
      //   component: () => (
      //     <Input
      //       label="How long have you been prescribed MAT for?"
      //       size={4}
      //       suffix="months"
      //       model="medications.mat_prescription_duration_months"
      //     />
      //   ),
      // },
      {
        label: 'Are you participating in or about to enter any drug replacement program?',
        model: 'drug_replacement_programs',
        isDisabled: false,
        component: ({ isRequired, roiDisclaimerMessage }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Are you participating in or about to enter any drug replacement program?"
              model="medications.does_participate_in_drug_replacement_programs"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="medications.does_participate_in_drug_replacement_programs" is={true}>
              <CheckboxGroup label="Please select all applicable drug replacement programs:" layout="vertical-dense">
                <Checkbox
                  label="Vivitrol and ReVia"
                  description="Naltrexone"
                  model="medications.drug_replacement_programs.vivitrol_and_revia"
                />
                <Checkbox label="Narcan" description="Naloxone" model="medications.drug_replacement_programs.narcan" />
                <Checkbox label="Subutex" description="Buprenophine" model="medications.drug_replacement_programs.subutex" />
                <Checkbox label="Campral" description="Acamprosate" model="medications.drug_replacement_programs.campral" />
                <Checkbox label="Baclofen" description="Lioresal" model="medications.drug_replacement_programs.baclofen" />
                <Checkbox label="Methadone" description="Methadose & Dolophine" model="medications.drug_replacement_programs.methadone" />
                <Checkbox label="Suboxone" description="Buprenophine" model="medications.drug_replacement_programs.suboxone" />
                <Checkbox label="Antabuse" description="Disulfiram" model="medications.drug_replacement_programs.antabuse" />
                <Checkbox label="Topamax" description="Topiramate" model="medications.drug_replacement_programs.topamax" />
                <Checkbox label="Sublocade" description="Buprenophine" model="medications.drug_replacement_programs.sublocade" />
                <Checkbox label="Other" model="medications.drug_replacement_programs.other" />
              </CheckboxGroup>

              <ContextShow when="medications.drug_replacement_programs.vivitrol_and_revia" is={true}>
                <Textarea
                  label="Vivitrol and ReVia – please provide more details"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.vivitrol_and_revia_description"
                />
              </ContextShow>
              <ContextShow when="medications.drug_replacement_programs.narcan" is={true}>
                <Textarea
                  label="Narcan – please provide more details"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.narcan_description"
                />
              </ContextShow>
              <ContextShow when="medications.drug_replacement_programs.subutex" is={true}>
                <Textarea
                  label="Subutex – please provide more details"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.subutex_description"
                />
              </ContextShow>
              <ContextShow when="medications.drug_replacement_programs.campral" is={true}>
                <Textarea
                  label="Campral – please provide more details"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.campral_description"
                />
              </ContextShow>
              <ContextShow when="medications.drug_replacement_programs.baclofen" is={true}>
                <Textarea
                  label="Baclofen – please provide more details"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.baclofen_description"
                />
              </ContextShow>
              <ContextShow when="medications.drug_replacement_programs.methadone" is={true}>
                <Textarea
                  label="Methadone – please provide more details"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.methadone_description"
                />
              </ContextShow>
              <ContextShow when="medications.drug_replacement_programs.suboxone" is={true}>
                <Textarea
                  label="Suboxone – please provide more details"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.suboxone_description"
                />
              </ContextShow>
              <ContextShow when="medications.drug_replacement_programs.antabuse" is={true}>
                <Textarea
                  label="Antabuse – please provide more details"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.antabuse_description"
                />
              </ContextShow>
              <ContextShow when="medications.drug_replacement_programs.topamax" is={true}>
                <Textarea
                  label="Topamax – please provide more details"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.topamax_description"
                />
              </ContextShow>
              <ContextShow when="medications.drug_replacement_programs.sublocade" is={true}>
                <Textarea
                  label="Sublocade – please provide more details"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.sublocade_description"
                />
              </ContextShow>
              <ContextShow when="medications.drug_replacement_programs.other" is={true}>
                <Textarea
                  label="Please list the other drug replacement programs"
                  description="Include frequency of visits, dosage, length of prescription and any other relevant information; also, please list this in the Prescription Medication section as well"
                  model="medications.drug_replacement_programs.other_description"
                />
              </ContextShow>

              <FormCard title="MAT Doctor" description="Please add the contact details of your Medication-Assisted-Treatment Doctor">
                <ContactInputs model="medications.mat_doctor" relationship="mat_doctor" roiDisclaimerMessage={roiDisclaimerMessage} />
              </FormCard>
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Treatment History',
    model: 'treatment_history',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Are you currently in a treatment program?',
        model: 'has_current_treatment_program',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Are you currently in a treatment program?"
              model="treatment_history.has_current_treatment_program"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="treatment_history.has_current_treatment_program" is={true}>
              <FormCard title="Current Treatment Program">
                <Input label="Program Name" model="treatment_history.current_program.name" />

                <ProgramTypeRadioGroup label="Program Type" model="treatment_history.current_program.program_type" />

                <FormSection horizontal>
                  <DateInput label="Start Date" model="treatment_history.current_program.started_at" />

                  <DateInput label="Estimated Discharge Date" model="treatment_history.current_program.estimated_discharged_at" />
                </FormSection>

                <YesNoRadioGroupWithTextarea
                  label="Are you planning to attend an aftercare program or an intensive outpatient program?"
                  model="treatment_history.current_program.will_attend_aftercare_program_or_iop"
                />
              </FormCard>
            </ContextShow>

            <ContextShow when="treatment_history.has_current_treatment_program" is={false}>
              <YesNoRadioGroup
                label="Are you willing to enter a treatment program?"
                model="treatment_history.is_open_to_treatment_program"
              />

              <ContextShow when="treatment_history.is_open_to_treatment_program" is={true}>
                <YesNoRadioGroupWithTextarea
                  label="Do you need assistance?"
                  model="treatment_history.does_need_treatment_program_assistance"
                />
              </ContextShow>

              <ContextShow when="treatment_history.is_open_to_treatment_program" is={false}>
                <Textarea label="Why not?" model="treatment_history.is_not_open_to_treatment_program_description" />
              </ContextShow>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Have you ever been through any other treatment programs previously?',
        model: 'has_previous_treatment_programs',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Have you ever been through any other treatment programs previously?"
              description="Programs range from inpatient hospital detox or residential programs, to outpatient treatment centers and sober living homes"
              model="treatment_history.has_previous_treatment_programs"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="treatment_history.has_previous_treatment_programs" is={true}>
              <DataArraySection
                title="Previous Treatment Programs"
                description="Beginning with the MOST RECENT treatment program or resource, please complete the following list honestly and transparently to document your history of treatment and/or recovery programs."
                itemTitle="Treatment Program"
                model="treatment_history.previous_programs"
                children={(id: string) => (
                  <>
                    <Input label="Facility / Program Name" model={`${id}.name`} />

                    <Input label="Location" model={`${id}.location`} />

                    <ProgramTypeRadioGroup label="Program Type" model={`${id}.program_type`} />

                    <FormSection horizontal>
                      <DateInput label="Admit Date" model={`${id}.started_at`} />
                      <DateInput label="Discharge Date" model={`${id}.ended_at`} />
                    </FormSection>

                    <ContextShow when={`${id}.length_of_stay_days`} not="null">
                      <Input label="Approximate Length of Stay" model={`${id}.length_of_stay_days`} type="number" size={3} suffix="Days" />
                    </ContextShow>

                    <YesNoRadioGroup label="Did you successfully complete the program?" model={`${id}.did_complete`} />

                    <ContextShow when={`${id}.did_complete`} is={false}>
                      <RadioGroup label="What is your current status?" model={`${id}.discharge_type`} layout="vertical-dense">
                        <Radio label="Ongoing" value="ongoing" />
                        <Radio label="I was discharged Early" value="early_discharge" />
                        <Radio label="I was discharged against medical advice (AMA)" value="ama" />
                        <Radio label="Left the program without notification (AWOL)" value="awol" />
                        <Radio label="Facility decided discharge (Therapeutic Discharge)" value="therapeutic" />
                        <Radio label="Other" value="other" />
                      </RadioGroup>
                    </ContextShow>

                    <ContextShow when={`${id}.discharge_type`} is={'other'}>
                      <Input label="Please describe" model={`${id}.discharge_type_other`} />
                    </ContextShow>

                    <Input
                      label="Length of Abstinence After Treatment"
                      model={`${id}.abstinence_days`}
                      type="number"
                      size={3}
                      suffix="Days"
                    />
                  </>
                )}
              />
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Recovery',
    model: 'recovery',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Have you had periods of abstinence?',
        model: 'has_had_periods_of_abstinence',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Have you had periods of abstinence?"
              model="recovery.has_had_periods_of_abstinence"
              isRequired={isRequired}
            />

            <ContextShow when="recovery.has_had_periods_of_abstinence" is={true}>
              <Textarea
                label="What did you do to maintain your sobriety?"
                model="recovery.has_had_periods_of_abstinence_description"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please enter a response',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Which 12 step meetings do you attend?',
        model: 'twelve_step_meetings_attending',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <TwelveStepMeetingsCheckboxGroup
            label="Which 12 step meetings do you attend?"
            model="recovery.twelve_step_meetings_attending"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'When did you attend your first 12-step meeting?',
        model: 'first_time_attending_twelve_step_meeting',
        isDisabled: false,
        component: () => (
          <Input label="When did you attend your first 12-step meeting?" model="recovery.first_time_attending_twelve_step_meeting" />
        ),
      },
      {
        label: 'How many 12-step meetings do you currently attend each week?',
        model: 'twelve_step_meetings_weekly_attendance_count',
        isDisabled: false,
        component: () => (
          <Input
            label="How many 12-step meetings do you currently attend each week?"
            type="number"
            suffix="meetings per week"
            size={4}
            model="recovery.twelve_step_meetings_weekly_attendance_count"
          />
        ),
      },
      {
        label: 'What is your Sober or Clean date?',
        model: 'sober_date',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <DateInput
            label="What is your Sober or Clean date?"
            model="recovery.sober_date"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter your sober or clean date',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you plan to attend 90 meetings in 90 days?',
        model: 'will_attend_90_meetings_in_90_days',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you plan to attend 90 meetings in 90 days?"
              model="recovery.will_attend_90_meetings_in_90_days"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="recovery.will_attend_90_meetings_in_90_days" is={false}>
              <Textarea
                label="Please describe your reason"
                model="recovery.reason_not_to_attend_90_meetings_in_90_days"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe your reason',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you have a Sponsor?',
        model: 'has_sponsor',
        isDisabled: false,
        component: ({ isRequired, roiDisclaimerMessage }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you have a Sponsor?"
              model="recovery.has_sponsor"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="recovery.has_sponsor" is={true}>
              <FormCard title="Sponsor Details">
                <ContactInputs
                  model="recovery.sponsor"
                  relationship="sponsor"
                  isRequired={isRequired}
                  roiDisclaimerMessage={roiDisclaimerMessage}
                />
              </FormCard>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you have a Case Manager?',
        model: 'has_case_manager',
        isDisabled: false,
        component: ({ isRequired, roiDisclaimerMessage }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you have a Case Manager?"
              model="recovery.has_case_manager"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="recovery.has_case_manager" is={true}>
              <FormCard title="Case Manager Details">
                <ContactInputs
                  model="recovery.case_manager"
                  relationship="case_manager"
                  isRequired={isRequired}
                  roiDisclaimerMessage={roiDisclaimerMessage}
                />
              </FormCard>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you have a Recovery Coach?',
        model: 'has_recovery_coach',
        isDisabled: false,
        component: ({ isRequired, roiDisclaimerMessage }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you have a Recovery Coach?"
              model="recovery.has_recovery_coach"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="recovery.has_recovery_coach" is={true}>
              <FormCard title="Recovery Coach Details">
                <ContactInputs
                  model="recovery.recovery_coach"
                  relationship="recovery_coach"
                  isRequired={isRequired}
                  roiDisclaimerMessage={roiDisclaimerMessage}
                />
              </FormCard>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you have a community of family and/or friends that supports your recovery?',
        model: 'has_community_support',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have a community of family and/or friends that supports your recovery?"
            model="recovery.has_community_support"
            isRequired={isRequired}
          />
        ),
      },
      {
        label:
          'Does anyone in your family have a substance use or mental health challenge or history of substance use/mental health challenges?',
        model: 'family_substance_use_or_mental_health',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <Label
              label="Does anyone in your family have a substance use or mental health challenge or history of substance use/mental health challenges?"
              className="!-mb-3"
            />

            <div className="pl-4 grid gap-4">
              <YesNoRadioGroupWithTextarea
                label="Alcohol?"
                model="recovery.family_substance_use_or_mental_health.alcohol"
                isRequired={isRequired}
              />

              <YesNoRadioGroupWithTextarea
                label="Drugs?"
                model="recovery.family_substance_use_or_mental_health.drugs"
                isRequired={isRequired}
              />

              <YesNoRadioGroupWithTextarea
                label="Mental Health?"
                model="recovery.family_substance_use_or_mental_health.mental_health"
                isRequired={isRequired}
              />
            </div>
          </>
        ),
      },
      {
        label: 'Does your significant other or romantic partner use alcohol or other substances?',
        model: 'partner_substance_use',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Does your significant other or romantic partner use alcohol or other substances?"
            model="recovery.partner_substance_use"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do your friends use alcohol or other substances?',
        model: 'friends_substance_use',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do your friends use alcohol or other substances?"
            model="recovery.friends_substance_use"
            isRequired={isRequired}
          />
        ),
      },
    ],
  },
  {
    title: 'Assistance & Help',
    model: 'assistance_and_help',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Do you have a learning disability or difficulty reading?',
        model: 'has_learning_disability_or_difficulty_reading',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have a learning disability or difficulty reading?"
            model="assistance_and_help.has_learning_disability_or_difficulty_reading"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you identify as a person with a disability or other chronic condition?',
        model: 'is_person_with_disability_or_chronic_condition',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you identify as a person with a disability or other chronic condition?"
            model="assistance_and_help.is_person_with_disability_or_chronic_condition"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you have any immediate needs such as clothing or toiletries?',
        model: 'does_need_clothing_or_toiletries',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have any immediate needs such as clothing or toiletries?"
            model="assistance_and_help.does_need_clothing_or_toiletries"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you need assistance with any self-help, support group and/or networks within the local community?',
        model: 'support_group_assistance',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you need assistance with any self-help, support group and/or networks within the local community?"
              model="assistance_and_help.does_need_support_group_assistance"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="assistance_and_help.does_need_support_group_assistance" is={true}>
              <Textarea
                label="Please describe"
                model="assistance_and_help.support_group_assistance_description"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe',
                    },
                  }
                }
              />

              <CheckboxGroup label="Please select the assistance areas you need help with" layout="vertical-dense">
                <Checkbox label="None Applicable" model="assistance_and_help.support_group_assistance.areas.none" />

                <ContextHide when="assistance_and_help.support_group_assistance.areas.none" is={true}>
                  <Checkbox label="Legal Aid" model="assistance_and_help.support_group_assistance.areas.legal_aid" />
                  <Checkbox
                    label="Subsidized mass transit programs"
                    model="assistance_and_help.support_group_assistance.areas.subsidized_mass_transit_programs"
                  />
                  <Checkbox
                    label="Free Community college, literacy, GED and ELS programs"
                    model="assistance_and_help.support_group_assistance.areas.free_community_college_literacy_ged_and_els_programs"
                  />
                  <Checkbox
                    label="Credit restoration programs"
                    model="assistance_and_help.support_group_assistance.areas.credit_restoration_programs"
                  />
                  <Checkbox label="Faith community" model="assistance_and_help.support_group_assistance.areas.faith_community" />
                  <Checkbox
                    label="Identity-based community"
                    model="assistance_and_help.support_group_assistance.areas.identity_based_community"
                  />
                  <Checkbox
                    label="In-home supportive care"
                    model="assistance_and_help.support_group_assistance.areas.in_home_supportive_care"
                  />
                  <Checkbox
                    label="Nutritionist / Dietician"
                    model="assistance_and_help.support_group_assistance.areas.nutritionist_dietician"
                  />
                  <Checkbox label="Counseling" model="assistance_and_help.support_group_assistance.areas.counseling" />
                  <Checkbox
                    label="Single Parenting Support Group"
                    model="assistance_and_help.support_group_assistance.areas.single_parenting_support_group"
                  />
                  <Checkbox
                    label="Grief and Loss Support Group"
                    model="assistance_and_help.support_group_assistance.areas.grief_and_loss_support_group"
                  />
                  <Checkbox
                    label="Recovery Support Group"
                    model="assistance_and_help.support_group_assistance.areas.recovery_support_group"
                  />
                  <Checkbox label="Trauma Support Group" model="assistance_and_help.support_group_assistance.areas.trauma_support_group" />
                  <Checkbox
                    label="Postpartum Support Group"
                    model="assistance_and_help.support_group_assistance.areas.postpartum_support_group"
                  />
                  <Checkbox
                    label="Short-term disability"
                    model="assistance_and_help.support_group_assistance.areas.short_term_disability"
                  />
                  <Checkbox
                    label="Vocational training resources"
                    model="assistance_and_help.support_group_assistance.areas.vocational_training_resources"
                  />
                  <Checkbox
                    label="Employment readiness/placement services"
                    model="assistance_and_help.support_group_assistance.areas.employment_readiness_placement_services"
                  />
                  <Checkbox
                    label="Job seeker services such as the Office Vocational Rehabilitation (OVR) and CareerLink"
                    model="assistance_and_help.support_group_assistance.areas.job_seeker_services"
                  />
                  <Checkbox
                    label="Medical/Dental treatment"
                    model="assistance_and_help.support_group_assistance.areas.medical_dental_treatment"
                  />
                  <Checkbox label="Parenting classes" model="assistance_and_help.support_group_assistance.areas.parenting_classes" />
                  <Checkbox label="Family counseling" model="assistance_and_help.support_group_assistance.areas.family_counseling" />
                  <Checkbox
                    label="If child welfare-involved"
                    model="assistance_and_help.support_group_assistance.areas.if_child_welfare_involved"
                  />
                  <Checkbox
                    label="Activities associated with their Family Service Plan (e.g., visitation with their children)"
                    model="assistance_and_help.support_group_assistance.areas.family_service_plan_activities"
                  />
                  <Checkbox
                    label="Outpatient drug and alcohol treatment supports"
                    model="assistance_and_help.support_group_assistance.areas.outpatient_drug_and_alcohol_treatment_supports"
                  />
                  <Checkbox
                    label="Mental Health treatment and counseling"
                    model="assistance_and_help.support_group_assistance.areas.mental_health_treatment_and_counseling"
                  />
                  <Checkbox
                    label="Stress and anger management"
                    model="assistance_and_help.support_group_assistance.areas.stress_and_anger_management"
                  />
                  <Checkbox
                    label="Finance and budgeting classes"
                    model="assistance_and_help.support_group_assistance.areas.finance_and_budgeting_classes"
                  />
                  <Checkbox
                    label="Probation/Parole offices"
                    model="assistance_and_help.support_group_assistance.areas.probation_parole_offices"
                  />
                  <Checkbox
                    label="Volunteer/Community Services"
                    model="assistance_and_help.support_group_assistance.areas.volunteer_community_services"
                  />
                  <Checkbox
                    label="Driver's license restoration"
                    model="assistance_and_help.support_group_assistance.areas.drivers_license_restoration"
                  />
                  <Checkbox label="Tax consulting" model="assistance_and_help.support_group_assistance.areas.tax_consulting" />
                  <Checkbox
                    label="County assistance offices"
                    model="assistance_and_help.support_group_assistance.areas.county_assistance_offices"
                  />
                  <Checkbox label="Other" model="assistance_and_help.support_group_assistance.areas.other" />
                </ContextHide>
              </CheckboxGroup>

              <ContextHide when="assistance_and_help.support_group_assistance.areas.none" is={true}>
                <ContextShow when="assistance_and_help.support_group_assistance.areas.other" is={true}>
                  <Textarea
                    label="Please list the other assistance areas you need help with"
                    model="assistance_and_help.support_group_assistance.areas_other"
                  />
                </ContextShow>
              </ContextHide>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you need assistance with a POA, Will, Obtaining insurance, unpaid bills?',
        model: 'does_need_assistance_with_poa_will_insurance_unpaid_bills',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you need assistance with a POA, Will, Obtaining insurance, unpaid bills?"
            model="assistance_and_help.does_need_assistance_with_poa_will_insurance_unpaid_bills"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you need help to renew any forms of identification?',
        model: 'will_need_help_to_renew_identification_forms',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you need help to renew any forms of identification?"
            model="assistance_and_help.will_need_help_to_renew_identification_forms"
            isRequired={isRequired}
          />
        ),
      },

      {
        label: 'Are you currently receiving assistance with any food programs?',
        model: 'existing_food_programs_assistance',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you need assistance with any food programs?"
              model="assistance_and_help.has_existing_food_programs_assistance"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="assistance_and_help.has_existing_food_programs_assistance" is={true}>
              <Textarea
                label="Please describe"
                model="assistance_and_help.existing_food_programs_assistance_description"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe',
                    },
                  }
                }
              />
              <CheckboxGroup label="Food programs assistance - select all that apply:" layout="vertical-dense">
                <Checkbox label="Food Stamps (SNAP)" model="assistance_and_help.existing_food_programs_assistance.snap" />
                <Checkbox label="Food Pantry" model="assistance_and_help.existing_food_programs_assistance.food_pantry" />
                <Checkbox
                  label="Women, Infants, and Children Program (WIC)"
                  model="assistance_and_help.existing_food_programs_assistance.wic"
                />
                <Checkbox
                  label="Electronic Benefits Transfer Card (EBT)"
                  model="assistance_and_help.existing_food_programs_assistance.ebt"
                />
              </CheckboxGroup>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you need assistance with any food programs?',
        model: 'food_programs_assistance',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you need assistance with any food programs?"
              model="assistance_and_help.does_need_food_programs_assistance"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="assistance_and_help.does_need_food_programs_assistance" is={true}>
              <Textarea
                label="Please describe"
                model="assistance_and_help.food_programs_assistance_description"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe',
                    },
                  }
                }
              />
              <CheckboxGroup label="Food programs assistance - select all that apply:" layout="vertical-dense">
                <Checkbox label="Food Stamps (SNAP)" model="assistance_and_help.food_programs_assistance.snap" />
                <Checkbox label="Food Pantry" model="assistance_and_help.food_programs_assistance.food_pantry" />
                <Checkbox label="Women, Infants, and Children Program (WIC)" model="assistance_and_help.food_programs_assistance.wic" />
                <Checkbox label="Electronic Benefits Transfer Card (EBT)" model="assistance_and_help.food_programs_assistance.ebt" />
              </CheckboxGroup>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Are you currently receiving any other types of assistance?',
        model: 'other_assistance',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <CheckboxGroup
              label="Are you currently receiving any other types of assistance?"
              layout="vertical-dense"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please add your answer',
                  },
                }
              }
            >
              <Checkbox label="Rent / Utility Assistance" model="assistance_and_help.other_assistance.rent_utility" />
              <Checkbox label="Transportation Assistance" model="assistance_and_help.other_assistance.transportation" />
              <Checkbox label="Other" model="assistance_and_help.other_assistance.other" />
            </CheckboxGroup>

            <ContextShow when="assistance_and_help.other_assistance.other" is={true}>
              <Textarea label="Please describe" model="assistance_and_help.other_assistance_other" />
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Courts & Criminal Justice',
    model: 'courts_criminal_justice',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Are you now or have you ever been involved with the justice system (arrested or incarcerated)?',
        model: 'is_or_has_been_involved_with_justice_system',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroup
            label="Are you now or have you ever been involved with the justice system (arrested or incarcerated)?"
            model="courts_criminal_justice.is_or_has_been_involved_with_justice_system"
            validations={
              isRequired && {
                presence: {
                  message: 'Please select an answer',
                },
              }
            }
          />
        ),
      },
      {
        label: 'What justice involvement have you experienced?',
        model: 'justice_involvement',
        tooltip:
          'This question is only applicable when selecting "Yes" for "Are you now or have you ever been involved with the justice system (arrested or incarcerated)?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <>
            <ContextShow when="courts_criminal_justice.is_or_has_been_involved_with_justice_system" is={true}>
              <CheckboxGroup
                label="What justice involvement have you experienced?"
                layout="vertical-dense"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please add your answer',
                    },
                  }
                }
              >
                <Checkbox label="Been Arrested" model="courts_criminal_justice.justice_involvement.been_arrested" />
                <Checkbox label="Been Incarcerated" model="courts_criminal_justice.justice_involvement.been_incarcerated" />
                <Checkbox label="Probation" model="courts_criminal_justice.justice_involvement.probation" />
                <Checkbox label="Parole" model="courts_criminal_justice.justice_involvement.parole" />
                <Checkbox
                  label="Committed Crimes but Not Caught"
                  model="courts_criminal_justice.justice_involvement.committed_crimes_but_not_caught"
                />
                <Checkbox
                  label="Participated in Drug/Specialty Court"
                  model="courts_criminal_justice.justice_involvement.participated_in_drug_specialty_court"
                />
                <Checkbox label="Other" model="courts_criminal_justice.justice_involvement.other" />
              </CheckboxGroup>
            </ContextShow>

            <ContextShow when="courts_criminal_justice.justice_involvement.other" is={true}>
              <Textarea label="Please explain" model="courts_criminal_justice.justice_involvement_other" />
            </ContextShow>

            <ContextShow when="courts_criminal_justice.justice_involvement.participated_in_drug_specialty_court" is={true}>
              <Input label="Drug/Specialty Court Completed" model="courts_criminal_justice.drug_specialty_court_completed" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Have you ever been charged or convicted of a Felony?',
        model: 'was_charged_or_convicted_of_felony',
        tooltip:
          'This question is only applicable when selecting "Yes" for "Are you now or have you ever been involved with the justice system (arrested or incarcerated)?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="courts_criminal_justice.is_or_has_been_involved_with_justice_system" is={true}>
            <YesNoRadioGroupWithTextarea
              label="Have you ever been charged or convicted of a Felony?"
              model="courts_criminal_justice.was_charged_or_convicted_of_felony"
              textareaLabel="Please explain"
              textareaDescription="Please explain and add sentencing date and outcome"
              isRequired={isRequired}
            />
          </ContextShow>
        ),
      },
      {
        label:
          'Have you ever been charged or convicted of a violent crime, including but not limited to any form of assault, domestic violence, or other crimes involving weapons or physical violence?',
        model: 'was_charged_or_convicted_of_violent_crime',
        tooltip:
          'This question is only applicable when selecting "Yes" for "Are you now or have you ever been involved with the justice system (arrested or incarcerated)?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="courts_criminal_justice.is_or_has_been_involved_with_justice_system" is={true}>
            <YesNoRadioGroupWithTextarea
              label="Have you ever been charged or convicted of a violent crime, including but not limited to any form of assault, domestic violence, or other crimes involving weapons or physical violence?"
              model="courts_criminal_justice.was_charged_or_convicted_of_violent_crime"
              textareaLabel="Please explain"
              textareaDescription="Please explain and add sentencing date and outcome"
              isRequired={isRequired}
            />
          </ContextShow>
        ),
      },
      {
        label: 'Has your criminal record been sealed?',
        model: 'has_criminal_record_been_sealed',
        tooltip:
          'This question is only applicable when selecting "Yes" for "Are you now or have you ever been involved with the justice system (arrested or incarcerated)?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="courts_criminal_justice.is_or_has_been_involved_with_justice_system" is={true}>
            <YesNoRadioGroup
              label="Has your criminal record been sealed?"
              model="courts_criminal_justice.has_criminal_record_been_sealed"
              isRequired={isRequired}
            />
          </ContextShow>
        ),
      },
      {
        label: 'Are you interested in sealing your criminal record?',
        model: 'is_interested_in_sealing_criminal_record',
        tooltip:
          'This question is only applicable when selecting "Yes" for "Are you now or have you ever been involved with the justice system (arrested or incarcerated)?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="courts_criminal_justice.is_or_has_been_involved_with_justice_system" is={true}>
            <YesNoRadioGroup
              label="Are you interested in sealing your criminal record?"
              model="courts_criminal_justice.is_interested_in_sealing_criminal_record"
              isRequired={isRequired}
            />
          </ContextShow>
        ),
      },
      {
        label: 'Do you have any outstanding warrants?',
        model: 'has_outstanding_warrants',
        tooltip:
          'This question is only applicable when selecting "Yes" for "Are you now or have you ever been involved with the justice system (arrested or incarcerated)?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="courts_criminal_justice.is_or_has_been_involved_with_justice_system" is={true}>
            <YesNoRadioGroup
              label="Do you have any outstanding warrants?"
              model="courts_criminal_justice.has_outstanding_warrants"
              isRequired={isRequired}
            />
          </ContextShow>
        ),
      },
      {
        label: 'Do you have any pending charges or legal/justice issues?',
        model: 'has_pending_charges_or_legal_justice_issues',
        tooltip:
          'This question is only applicable when selecting "Yes" for "Are you now or have you ever been involved with the justice system (arrested or incarcerated)?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="courts_criminal_justice.is_or_has_been_involved_with_justice_system" is={true}>
            <YesNoRadioGroupWithTextarea
              label="Do you have any pending charges or legal/justice issues?"
              model="courts_criminal_justice.has_pending_charges_or_legal_justice_issues"
              textareaLabel="Please explain"
              isRequired={isRequired}
            />
          </ContextShow>
        ),
      },
      {
        label: 'Do you currently owe court costs, fines, or other criminal justice fees/penalties?',
        model: 'does_owe_court_costs_fines_or_other_fees',
        tooltip:
          'This question is only applicable when selecting "Yes" for "Are you now or have you ever been involved with the justice system (arrested or incarcerated)?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <>
            <ContextShow when="courts_criminal_justice.is_or_has_been_involved_with_justice_system" is={true}>
              <YesNoRadioGroup
                label="Do you currently owe court costs, fines, or other criminal justice fees/penalties?"
                model="courts_criminal_justice.does_owe_court_costs_fines_or_other_fees"
                isRequired={isRequired}
              />
            </ContextShow>

            <ContextShow when="courts_criminal_justice.does_owe_court_costs_fines_or_other_fees" is={true}>
              <AmountInput label="How much do you owe?" model="courts_criminal_justice.amount_owed" isRequired={isRequired} />

              <Flex gap="1rem">
                <AmountInput label="How much do you pay?" model="courts_criminal_justice.amount_paid" isRequired={isRequired} />
                <RadioGroup label="" model="courts_criminal_justice.amount_paid_frequency" layout="horizontal-dense" defaultValue="weekly">
                  <Radio label="Weekly" value="weekly" />
                  <Radio label="Monthly" value="monthly" />
                </RadioGroup>
              </Flex>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you have an attorney?',
        model: 'has_attorney',
        isDisabled: false,
        component: ({ isRequired, roiDisclaimerMessage }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you have an attorney?"
              model="courts_criminal_justice.has_attorney"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="courts_criminal_justice.has_attorney" is={true}>
              <FormCard title="Attorney Details">
                <ContactInputs
                  model="courts_criminal_justice.attorney"
                  relationship="attorney"
                  isRequired={isRequired}
                  roiDisclaimerMessage={roiDisclaimerMessage}
                />
              </FormCard>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you consent to a background check?',
        model: 'does_consent_to_background_check',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you consent to a background check?"
              model="courts_criminal_justice.does_consent_to_background_check"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="courts_criminal_justice.does_consent_to_background_check" is={false}>
              <Textarea
                label="Please describe your reason"
                model="courts_criminal_justice.reason_to_refuse_background_check"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe your reason',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Are you currently involved in any legal proceedings or criminal justice issues?',
        model: 'has_legal_proceedings_or_criminal_justice_issues',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Are you currently involved in any legal proceedings or criminal justice issues?"
            model="courts_criminal_justice.has_legal_proceedings_or_criminal_justice_issues"
            textareaLabel="Please describe and add any upcoming court dates"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do you have a requirement for Community Service?',
        model: 'has_requirement_for_community_service',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have a requirement for Community Service?"
            model="courts_criminal_justice.has_requirement_for_community_service"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Do have any court ordered treatment requirements?',
        model: 'court_ordered_treatment_requirements',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do have any court ordered treatment requirements?"
              model="courts_criminal_justice.has_court_ordered_treatment_requirements"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="courts_criminal_justice.has_court_ordered_treatment_requirements" is={true}>
              <YesNoRadioGroup
                label="Are you currently seeking treatment for this requirement or have you found a treatment program to adhere to this requirement?"
                model="courts_criminal_justice.is_seeking_or_found_treatment_for_court_order_requirements"
              />
              <ContextShow when="courts_criminal_justice.is_seeking_or_found_treatment_for_court_order_requirements" not={undefined}>
                <Textarea
                  label="Please provide more details"
                  model="courts_criminal_justice.is_seeking_or_found_treatment_for_court_order_requirements_description"
                />
              </ContextShow>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you have any pending sentencing or possible jail time upcoming?',
        model: 'has_pending_sentencing',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you have any pending sentencing or possible jail time upcoming?"
              model="courts_criminal_justice.has_pending_sentencing"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="courts_criminal_justice.has_pending_sentencing" is={true}>
              <DateInput
                label="When?"
                model="courts_criminal_justice.pending_sentencing_date"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please enter a date',
                    },
                  }
                }
              />
              <Textarea
                label="Please describe"
                model="courts_criminal_justice.pending_sentencing_description"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you have a Department of Corrections Number?',
        model: 'department_of_corrections_number',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you have a Department of Corrections Number?"
              model="courts_criminal_justice.has_department_of_corrections_number"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="courts_criminal_justice.has_department_of_corrections_number" is={true}>
              <Input
                label="Department of Corrections Number"
                model="courts_criminal_justice.department_of_corrections_number"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please enter a Department of Corrections Number',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Have you ever been charged or convicted of Arson?',
        model: 'was_charged_or_convicted_of_arson',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Have you ever been charged or convicted of Arson?"
            model="courts_criminal_justice.was_charged_or_convicted_of_arson"
            textareaDescription="Please add sentencing date and outcome"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Have you ever been charged or convicted of any sexual or violent crimes in any jurisdiction?',
        model: 'was_charged_or_convicted_of_sexual_or_violent_crimes',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Have you ever been charged or convicted of any sexual or violent crimes in any jurisdiction?"
            model="courts_criminal_justice.was_charged_or_convicted_of_sexual_or_violent_crimes"
            textareaLabel="Please describe IN DETAIL the events of all the sexual or violent crimes. We consider Violent Offenders on a case-by-case basis."
            isRequired={isRequired}
          />
        ),
      },
      {
        label:
          'Have you ever been charged or convicted of abuse or neglect of any person, including but not limited to disabled person, senior, or child?',
        model: 'was_charged_or_convicted_of_abuse_or_neglect',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Have you ever been charged or convicted of abuse or neglect of any person, including but not limited to disabled person, senior, or child?"
            model="courts_criminal_justice.was_charged_or_convicted_of_abuse_or_neglect"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Have you ever been charged or convicted of cruelty to animals?',
        model: 'was_charged_or_convicted_of_cruelty_to_animals',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Have you ever been charged or convicted of cruelty to animals?"
            model="courts_criminal_justice.was_charged_or_convicted_of_cruelty_to_animals"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Are you affiliated with any gang?',
        model: 'has_gang_affiliation',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Are you affiliated with any gang?"
            model="courts_criminal_justice.has_gang_affiliation"
            isRequired={isRequired}
          />
        ),
      },
    ],
  },
  {
    title: 'Restrictions',
    model: 'restrictions',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Select all legal requirements that apply',
        description: 'House Arrest, Probation, Parole, Drug Court',
        model: 'legal_requirements',
        isDisabled: false,
        component: ({ isRequired, roiDisclaimerMessage }: FieldProps) => (
          <>
            <LegalRequirementsInputs
              label="Select all legal requirements that apply"
              model="restrictions.legal_requirements"
              isRequired={isRequired}
            />

            <ContextHide when="restrictions.legal_requirements.none" is={true}>
              {/* Reference Numbers */}
              <ContextShow when="restrictions.legal_requirements.house_arrest" is={true}>
                <Input label="House Arrest Reference Number" model="restrictions.house_arrest_reference_number" />
              </ContextShow>

              <ContextShow when="restrictions.legal_requirements.probation" is={true}>
                <Input label="Probation Reference Number" model="restrictions.probation_reference_number" />
                <Input label="Length of Probation" model="restrictions.probation_length" />
              </ContextShow>

              <ContextShow when="restrictions.legal_requirements.parole" is={true}>
                <Input label="Parole Reference Number" model="restrictions.parole_reference_number" />
                <Input label="Length of Parole" model="restrictions.parole_length" />
              </ContextShow>

              <ContextShow when="restrictions.legal_requirements.drug_court" is={true}>
                <Input label="Drug Court Reference Number" model="restrictions.drug_court_reference_number" />
              </ContextShow>

              <ContextShow when="restrictions.legal_requirements.other" is={true}>
                <Textarea label="Please list any other legal requirements" model="restrictions.legal_requirements_other" />
              </ContextShow>

              {/* Legal Contacts */}
              <ContextShow when="restrictions.legal_requirements.house_arrest" is={true}>
                <FormCard title="House Arrest Contact">
                  <ContactInputs
                    model="restrictions.house_arrest_contact"
                    relationship="house_arrest_contact"
                    roiDisclaimerMessage={roiDisclaimerMessage}
                  />
                </FormCard>
              </ContextShow>

              <ContextShow when="restrictions.legal_requirements.probation" is={true}>
                <FormCard title="Probation Officer">
                  <ContactInputs
                    model="restrictions.probation_officer"
                    relationship="probation_officer"
                    roiDisclaimerMessage={roiDisclaimerMessage}
                  />
                </FormCard>
              </ContextShow>

              <ContextShow when="restrictions.legal_requirements.parole" is={true}>
                <FormCard title="Parole Officer">
                  <ContactInputs
                    model="restrictions.parole_officer"
                    relationship="parole_officer"
                    roiDisclaimerMessage={roiDisclaimerMessage}
                  />
                </FormCard>
              </ContextShow>

              <ContextShow when="restrictions.legal_requirements.drug_court" is={true}>
                <FormCard title="Drug Court Contact">
                  <ContactInputs
                    model="restrictions.drug_court_contact"
                    relationship="drug_court_contact"
                    roiDisclaimerMessage={roiDisclaimerMessage}
                  />
                </FormCard>
              </ContextShow>

              <ContextShow when="restrictions.legal_requirements.other" is={true}>
                <DataArraySection
                  title="Other Legal Contacts"
                  description="Please add any other legal contacts"
                  itemTitle="Legal Contact"
                  model="restrictions.legal_contacts"
                  children={(id: string) => (
                    <ContactInputs model={id} relationship="legal_contact" roiDisclaimerMessage={roiDisclaimerMessage} />
                  )}
                />
              </ContextShow>
            </ContextHide>
          </>
        ),
      },
      {
        label: 'Are you required to register as a sex offender?',
        model: 'is_required_to_register_as_sex_offender',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Are you required to register as a sex offender?"
            model="restrictions.is_required_to_register_as_sex_offender"
            textareaLabel="Please provide the level and any other relevant details"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Are you required to register with any other authority for any other reason?',
        model: 'is_required_to_register_with_other_authority',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Are you required to register with any other authority for any other reason?"
            model="restrictions.is_required_to_register_with_other_authority"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Are there any Restraining Orders against you or by you?',
        model: 'has_restraining_orders',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Are there any Restraining Orders against you or by you?"
            model="restrictions.has_restraining_orders"
            textareaLabel="Please describe the restraining order situation including who it is, your relationship, relevant dates"
            isRequired={isRequired}
          />
        ),
      },
    ],
  },
  {
    title: 'Admissions',
    model: 'admissions',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'When would you like to move in?',
        model: 'target_move_in_date',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <DateInput
            label="When would you like to move in?"
            model="admissions.target_move_in_date"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter a date',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you expect to move in on time?',
        model: 'does_expect_to_move_in_on_time',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you expect to move in on time?"
              model="admissions.does_expect_to_move_in_on_time"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="admissions.does_expect_to_move_in_on_time" is={false}>
              <Textarea
                label="Reason for delayed move-in"
                model="admissions.reason_for_delayed_move_in"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: ({ companyName }: FieldProps) => `Do you have a personal relationship with anyone that works for ${companyName}?`,
        model: 'personal_relationship_with_employee',
        isDisabled: false,
        component: ({ companyName, isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label={`Do you have a personal relationship with anyone that works for ${companyName}?`}
              model="admissions.has_personal_relationship_with_employee"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="admissions.has_personal_relationship_with_employee" is={true}>
              <Input
                label="Who is it?"
                model="admissions.personal_employee_relationship_name"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please add a full name',
                    },
                  }
                }
              />
              <Input
                label="What is the nature of the relationship?"
                model="admissions.personal_employee_relationship_type"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please describe the relationship nature',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: ({ companyName }: FieldProps) => `Have you previously applied to ${companyName}?`,
        model: 'has_applied_previously',
        isDisabled: false,
        component: ({ companyName, isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label={`Have you previously applied to ${companyName}?`}
              model="admissions.has_applied_previously"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
            <ContextShow when="admissions.has_applied_previously" is={true}>
              <DateInput
                label="When did you apply?"
                model="admissions.previous_application_date"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please enter a date',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: ({ companyName }: FieldProps) => `How long would you hope to stay at ${companyName}?`,
        model: 'preferred_length_of_stay',
        isDisabled: false,
        component: ({ companyName, isRequired }: FieldProps) => (
          <RadioGroup
            label={`How long would you hope to stay at ${companyName}?`}
            model="admissions.preferred_length_of_stay"
            layout="vertical-dense"
            validations={
              isRequired && {
                presence: {
                  message: 'Please select a response',
                },
              }
            }
          >
            <Radio label="30 Days" value="30_days" />
            <Radio label="60 Days" value="60_days" />
            <Radio label="90 Days" value="90_days" />
            <Radio label="6 Months" value="180_days" />
            <Radio label="1 Year" value="365_days" />
            <Radio label="18 Months" value="540_days" />
          </RadioGroup>
        ),
      },
      {
        label: 'Are there any issues that could prevent you from completing the program?',
        model: 'issues_for_completing_the_program',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Are there any issues that could prevent you from completing the program?"
            model="admissions.issues_for_completing_the_program"
            textareaLabel="Please provide details"
            isRequired={isRequired}
          />
        ),
      },
    ],
  },
  {
    title: 'Client Statement',
    model: 'client_statement',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Why do you want to live in a sober house?',
        model: 'reason_to_live_in_sober_house',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Textarea
            label="Why do you want to live in a sober house?"
            model="client_statement.reason_to_live_in_sober_house"
            validations={
              isRequired && {
                presence: {
                  message: 'Please describe',
                },
              }
            }
          />
        ),
      },
      {
        label: 'How did you hear about about our program?',
        model: 'referral_place',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Textarea
            label="How did you hear about about our program?"
            model="client_statement.referral_place"
            validations={
              isRequired && {
                presence: {
                  message: 'Please describe',
                },
              }
            }
          />
        ),
      },
      {
        label: ({ companyName }: FieldProps) => `Were you referred to ${companyName}?`,
        model: 'was_referred',
        isDisabled: false,
        component: ({ companyName, isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label={`Were you referred to ${companyName}?`}
            model="client_statement.was_referred"
            textareaLabel="Please include who referred you"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'What other information should we consider when reviewing your application?',
        model: 'other_information_to_consider',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Textarea
            label="What other information should we consider when reviewing your application?"
            model="client_statement.other_information_to_consider"
            validations={
              isRequired && {
                presence: {
                  message: 'Please describe',
                },
              }
            }
          />
        ),
      },
      {
        label: ({ companyName }: FieldProps) =>
          `Please describe what issues led you to seek housing with ${companyName}. Be specific as to details such as how, when, where and your personal responsibility.`,
        model: 'goals_and_expectations',
        isDisabled: false,
        component: ({ companyName, isRequired }: FieldProps) => (
          <Textarea
            label={`Please describe what issues led you to seek housing with ${companyName}. Be specific as to details such as how, when, where and your personal responsibility.`}
            model="client_statement.goals_and_expectations"
            validations={
              isRequired && {
                presence: {
                  message: 'Please describe',
                },
              }
            }
          />
        ),
      },
      {
        label: 'What are your goals and expectations?',
        model: 'issues_that_led_to_seek_housing',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Textarea
            label="What are your goals and expectations?"
            model="client_statement.issues_that_led_to_seek_housing"
            validations={
              isRequired && {
                presence: {
                  message: 'Please describe',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Why do you think you are a good fit for sober living?',
        model: 'is_good_fit_for_sober_living',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Textarea
            label="Why do you think you are a good fit for sober living?"
            model="client_statement.is_good_fit_for_sober_living"
            validations={
              isRequired && {
                presence: {
                  message: 'Please describe',
                },
              }
            }
          />
        ),
      },
      {
        label: ({ companyName }: FieldProps) => `What do you want to accomplish while residing at ${companyName}?`,
        model: 'accomplishments_wish_list',
        isDisabled: false,
        component: ({ companyName, isRequired }: FieldProps) => (
          <Textarea
            label={`What do you want to accomplish while residing at ${companyName}?`}
            model="client_statement.accomplishments_wish_list"
            validations={
              isRequired && {
                presence: {
                  message: 'Please describe',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Employment',
    model: 'employment',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Are you able to work?',
        model: 'is_able_to_work',
        isDisabled: false,
        component: () => (
          <>
            <YesNoRadioGroup label="Are you able to work?" model="employment.is_able_to_work" />

            <ContextShow when="employment.is_able_to_work_description" not="null">
              <Textarea label="Please Describe" model="employment.is_able_to_work_description" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Are you able to work full-time or part-time?',
        model: 'is_able_to_work_type',
        tooltip: 'This question is only applicable when selecting "Yes" for "Are you able to work?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <>
            <ContextShow when="employment.is_able_to_work" is={true}>
              <RadioGroup
                label="Are you able to work full-time or part-time?"
                model="employment.is_able_to_work_type"
                layout="vertical-dense"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please add your answer',
                    },
                  }
                }
              >
                <Radio label="Full-Time" value="full_time" />
                <Radio label="Part-Time" value="part_time" />
                <Radio label="Other" value="other" />
              </RadioGroup>
            </ContextShow>

            <ContextShow when="employment.is_able_to_work_type" is="other">
              <Textarea label="Please Specify" model="employment.is_able_to_work_type_other" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'What barriers to employment do you face?',
        model: 'is_not_able_to_work_barriers',
        tooltip: 'This question is only applicable when selecting "No" for "Are you able to work?"',
        isDisabled: false,
        indent: true,
        component: ({ isRequired }: FieldProps) => (
          <>
            <ContextShow when="employment.is_able_to_work" is={false}>
              <CheckboxGroup
                label="What barriers to employment do you face?"
                layout="vertical-dense"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please add your answer',
                    },
                  }
                }
              >
                <Checkbox label="Diagnosed Disability" model="employment.is_not_able_to_work_barriers.diagnosed_disability" />
                <Checkbox label="Undiagnosed Disability" model="employment.is_not_able_to_work_barriers.undiagnosed_disability" />
                <Checkbox label="Lack of Childcare" model="employment.is_not_able_to_work_barriers.lack_of_childcare" />
                <Checkbox label="Lack of Transportation" model="employment.is_not_able_to_work_barriers.lack_of_transportation" />
                <Checkbox
                  label="Criminal Record or Justice Involvement"
                  model="employment.is_not_able_to_work_barriers.criminal_record_or_justice_involvement"
                />
                <Checkbox label="Lack of Skills/Resources" model="employment.is_not_able_to_work_barriers.lack_of_skills_or_resources" />
                <Checkbox label="Other" model="employment.is_not_able_to_work_barriers.other" />
              </CheckboxGroup>
            </ContextShow>

            <ContextShow when="employment.is_not_able_to_work_barriers.other" is={true}>
              <Textarea label="Please Specify" model="employment.is_not_able_to_work_type_other" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Are you currently employed?',
        model: 'is_employed',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Are you currently employed?"
              model="employment.is_employed"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Is this full-time, part-time, permanent, temporary, or seasonal work?',
        model: 'work_type',
        tooltip: 'This question is only applicable when selecting "Yes" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: () => (
          <ContextShow when="employment.is_employed" is={true}>
            <RadioGroup
              label="Is this full-time, part-time, permanent, temporary, or seasonal work?"
              model="employment.work_type"
              layout="vertical-dense"
            >
              <Radio label="Full-Time" value="full_time" />
              <Radio label="Part-Time" value="part_time" />
              <Radio label="Permanent" value="permanent" />
              <Radio label="Temporary" value="temporary" />
              <Radio label="Seasonal" value="seasonal" />
            </RadioGroup>
          </ContextShow>
        ),
      },
      {
        label: "What is the company's name?",
        model: 'company_name',
        tooltip: 'This question is only applicable when selecting "Yes" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <ContextShow when="employment.is_employed" is={true}>
            <Input
              label="What is the company's name?"
              model="employment.company_name"
              validations={
                isRequired && {
                  presence: {
                    message: "Please enter the company's name",
                  },
                }
              }
            />
          </ContextShow>
        ),
      },
      {
        label: 'Employer Contact Details',
        model: 'employer_contact',
        tooltip: 'This question is only applicable when selecting "Yes" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: ({ roiDisclaimerMessage }: FieldProps) => (
          <ContextShow when="employment.is_employed" is={true}>
            <FormCard title="Employer Contact Details">
              <ContactInputs model="employment.employer_contact" relationship="employer" roiDisclaimerMessage={roiDisclaimerMessage} />
            </FormCard>
          </ContextShow>
        ),
      },
      {
        label: 'How long have you been employed there?',
        model: 'employment_length',
        tooltip: 'This question is only applicable when selecting "Yes" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: () => (
          <ContextShow when="employment.is_employed" is={true}>
            <Input label="How long have you been employed there?" model="employment.employment_length" />
          </ContextShow>
        ),
      },
      {
        label: 'How many hours do you typically work per week?',
        model: 'weekly_work_hours',
        tooltip: 'This question is only applicable when selecting "Yes" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: () => (
          <ContextShow when="employment.is_employed" is={true}>
            <Input
              label="How many hours do you typically work per week?"
              model="employment.weekly_work_hours"
              type="number"
              suffix="/ week"
              size={4}
            />
          </ContextShow>
        ),
      },
      {
        label: 'What is your work schedule?',
        model: 'work_schedule',
        tooltip: 'This question is only applicable when selecting "Yes" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: () => (
          <ContextShow when="employment.is_employed" is={true}>
            <Textarea label="What is your work schedule?" model="employment.work_schedule" />
          </ContextShow>
        ),
      },
      {
        label: 'Do you have an Employee Assistance Program (EAP)?',
        model: 'has_eap',
        tooltip: 'This question is only applicable when selecting "Yes" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: ({ roiDisclaimerMessage }: FieldProps) => (
          <ContextShow when="employment.is_employed" is={true}>
            <YesNoRadioGroup label="Do you have an Employee Assistance Program (EAP)?" model="employment.has_eap" />

            <ContextShow when="employment.has_eap" is={true}>
              <FormCard title="EAP Contact">
                <ContactInputs model="employment.eap_contact" relationship="eap_contact" roiDisclaimerMessage={roiDisclaimerMessage} />
              </FormCard>
            </ContextShow>
          </ContextShow>
        ),
      },
      {
        label: 'Are you a member of a Union?',
        model: 'is_union_member',
        tooltip: 'This question is only applicable when selecting "Yes" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: ({ roiDisclaimerMessage }: FieldProps) => (
          <ContextShow when="employment.is_employed" is={true}>
            <YesNoRadioGroup label="Are you a member of a Union?" model="employment.is_union_member" />

            <ContextShow when="employment.is_union_member" is={true}>
              <Input label="Union Name" model="employment.union_name" />
              <FormCard title="Union Representative">
                <ContactInputs
                  model="employment.union_representative"
                  relationship="union_representative"
                  roiDisclaimerMessage={roiDisclaimerMessage}
                />
              </FormCard>
            </ContextShow>
          </ContextShow>
        ),
      },
      {
        label: 'Does your employer know you are in treatment?',
        model: 'is_employer_aware_of_treatment',
        tooltip: 'This question is only applicable when selecting "Yes" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: () => (
          <ContextShow when="employment.is_employed" is={true}>
            <YesNoRadioGroup label="Does your employer know you are in treatment?" model="employment.is_employer_aware_of_treatment" />
          </ContextShow>
        ),
      },
      {
        label: 'Are you currently seeking employment?',
        model: 'is_seeking_employment',
        isDisabled: false,
        tooltip: 'This question is only applicable when selecting "No" for "Are you currently employed?"',
        indent: true,
        component: () => (
          <ContextShow when="employment.is_employed" is={false}>
            <YesNoRadioGroup label="Are you currently seeking employment?" model="employment.is_seeking_employment" />
          </ContextShow>
        ),
      },
      {
        label: 'Are you willing to complete a minimum of two job applications a day?',
        model: 'is_willing_to_complete_two_job_applications_per_day',
        tooltip: 'This question is only applicable when selecting "No" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: () => (
          <ContextShow when="employment.is_employed" is={false}>
            <YesNoRadioGroup
              label="Are you willing to complete a minimum of two job applications a day?"
              model="employment.is_willing_to_complete_two_job_applications_per_day"
            />
          </ContextShow>
        ),
      },
      {
        label: 'Do you have any skills or industry experience that would assist you in finding a job?',
        model: 'has_industry_skills',
        tooltip: 'This question is only applicable when selecting "No" for "Are you currently employed?"',
        indent: true,
        isDisabled: false,
        component: () => (
          <ContextShow when="employment.is_employed" is={false}>
            <YesNoRadioGroupWithTextarea
              label="Do you have any skills or industry experience that would assist you in finding a job?"
              model="employment.has_industry_skills"
            />
          </ContextShow>
        ),
      },
      {
        label: 'What is your primary source of income?',
        model: 'primary_income_source',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <RadioGroup
              label="What is your primary source of income?"
              model="employment.primary_income_source"
              layout="vertical-dense"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            >
              <Radio label="Employment" value="employment" />
              <Radio label="SSI/Disability Benefits" value="ssi_disability_benefits" />
              <Radio label="No Income" value="no_income" />
              <Radio label="Other" value="other" />
            </RadioGroup>

            <ContextShow when="employment.primary_income_source" is="other">
              <Textarea label="Please Specify" model="employment.primary_income_source_other" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'What is your income?',
        model: 'total_income',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Flex gap="0.75rem" alignItems="flex-end">
            <AmountInput label="What is your income?" model="employment.total_income" isRequired={isRequired} />
            <RadioGroup label="" model="employment.total_income_frequency" layout="horizontal-dense" defaultValue="weekly">
              <Radio label="Weekly" value="weekly" />
              <Radio label="Monthly" value="monthly" />
              <Radio label="Annually" value="annually" />
            </RadioGroup>
          </Flex>
        ),
      },
      {
        label: 'What are your regular expenses?',
        model: 'regular_expenses',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Flex gap="0.75rem" alignItems="flex-end">
            <AmountInput label="What are your regular expenses?" model="employment.regular_expenses" isRequired={isRequired} />
            <RadioGroup label="" model="employment.regular_expenses_frequency" layout="horizontal-dense" defaultValue="weekly">
              <Radio label="Weekly" value="weekly" />
              <Radio label="Monthly" value="monthly" />
              <Radio label="Annually" value="annually" />
            </RadioGroup>
          </Flex>
        ),
      },
      {
        label: 'What is your current occupation?',
        model: 'occupation',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Input
            label="What is your current occupation?"
            model="employment.occupation"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter an occupation',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Have you completed any job or skills training?',
        model: 'job_skills_training',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <CheckboxGroup
              label="Have you completed any job or skills training?"
              layout="vertical-dense"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please add your answer',
                  },
                }
              }
            >
              <Checkbox label="No Job or Skills Training" model="employment.job_skills_training.no_job_or_skills_training" />
              <Checkbox label="Some Job Training" model="employment.job_skills_training.some_job_training" />
              <Checkbox label="Completed Training Program" model="employment.job_skills_training.completed_training_program" />
              <Checkbox label="Completed Certificate" model="employment.job_skills_training.completed_certificate" />
              <Checkbox label="Completed Trade School" model="employment.job_skills_training.completed_trade_school" />
            </CheckboxGroup>

            <ContextHide when="employment.job_skills_training.no_job_or_skills_training" is={true}>
              <Textarea label="List Certification(s) Here" model="employment.completed_certificates" />
            </ContextHide>
          </>
        ),
      },
      {
        label: 'Where have you mostly worked in the past?',
        model: 'frequent_previous_work',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <RadioGroup
              label="Where have you mostly worked in the past?"
              model="employment.frequent_previous_work"
              layout="vertical-dense"
              validations={isRequired && { presence: { message: 'Please select a response' } }}
            >
              <Radio label="Food Service/Restaurants" value="food_service_restaurants" />
              <Radio label="Factories/Manufacturing" value="factories_manufacturing" />
              <Radio label="General Labor" value="general_labor" />
              <Radio label="Lawncare/Landscaping" value="lawncare_landscaping" />
              <Radio label="Construction" value="construction" />
              <Radio label="Office/Clerical Work" value="office_clerical_work" />
              <Radio label="Other" value="other" />
            </RadioGroup>

            <ContextShow when="employment.frequent_previous_work" is="other">
              <Textarea label="Please Specify" model="employment.frequent_previous_work_other" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Are you willing to work 40 hours a week of gainful employment?',
        model: 'is_willing_to_work_full_time',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroup
            label="Are you willing to work 40 hours a week of gainful employment?"
            model="employment.is_willing_to_work_full_time"
            validations={
              isRequired && {
                presence: {
                  message: 'Please select a response',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Current weekly income (or anticipated income if currently unemployed)',
        model: 'weekly_income',
        isDisabled: false,
        isHidden: true,
        component: ({ isRequired }: FieldProps) => (
          <>
            <ContextShow when="employment.weekly_income" not="null">
              <AmountInput
                label="Current weekly income"
                model="employment.weekly_income"
                suffix="/ week"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please enter an amount',
                    },
                  }
                }
              />
            </ContextShow>

            <ContextShow when="employment.anticipated_weekly_income" not="null">
              <AmountInput
                label="Anticipated weekly income"
                model="employment.anticipated_weekly_income"
                suffix="/ week"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please enter an amount',
                    },
                  }
                }
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Work History',
        description:
          'Beginning with your current OR most recent job, please list your work history as completely as possible. If you need to, estimate your start and end dates. List your job duties as completely as possible.',
        model: 'work_history',
        isDisabled: false,
        isRequiredDisabled: true,
        component: ({ isRequired }: FieldProps) => (
          <>
            <div>
              <Label label="Work History" />
              <div className="text-text-muted">
                Beginning with your current OR most recent job, please list your work history as completely as possible. If you need to,
                estimate your start and end dates. List your job duties as completely as possible.
              </div>
            </div>

            <DataArraySection maxWidth={false} itemTitle="Job" model="work_history.jobs">
              {(id: any) => (
                <FormSection>
                  <Flex gap="1rem">
                    <DateInput label="Start Date" model={`${id}.started_at`} />
                    <DateInput label="End Date" model={`${id}.ended_at`} />
                  </Flex>
                  <Input label="Job Title" model={`${id}.job_title`} />

                  <Flex stretchChildrenX gap="1rem">
                    <Input label="Employer" model={`${id}.employer`} />
                    <Input label="City" model={`${id}.city`} />
                  </Flex>

                  <Textarea label="Job Duties" model={`${id}.job_duties`} />
                </FormSection>
              )}
            </DataArraySection>
          </>
        ),
      },
    ],
  },
  {
    title: 'Personal Finance',
    model: 'personal_finance',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Do you currently have an income?',
        model: 'has_current_income',
        isDisabled: false,
        component: () => (
          <>
            <YesNoRadioGroup label="Do you currently have an income?" model="personal_finance.has_current_income" />

            <ContextShow when="personal_finance.has_current_income" is={true}>
              <CheckboxGroup label="What type(s) of income do you have?" layout="vertical">
                <Checkbox label="Employment" model="personal_finance.current_income_categories.employment" />
                <ContextShow when="personal_finance.current_income_categories.employment" is={true}>
                  <AmountInput label="Employment yearly amount" model="personal_finance.employment_yearly_amount" suffix="/ year" />
                </ContextShow>

                <Checkbox label="Disability" model="personal_finance.current_income_categories.disability" />
                <ContextShow when="personal_finance.current_income_categories.disability" is={true}>
                  <AmountInput label="Disability yearly amount" model="personal_finance.disability_yearly_amount" suffix="/ year" />
                </ContextShow>

                <Checkbox label="Social Security" model="personal_finance.current_income_categories.social_security" />
                <ContextShow when="personal_finance.current_income_categories.social_security" is={true}>
                  <AmountInput
                    label="Social Security yearly amount"
                    model="personal_finance.social_security_yearly_amount"
                    suffix="/ year"
                  />
                </ContextShow>

                <Checkbox label="Other" model="personal_finance.current_income_categories.other" />
                <ContextShow when="personal_finance.current_income_categories.other" is={true}>
                  <AmountInput label="Other yearly amount" model="personal_finance.other_yearly_amount" suffix="/ year" />
                </ContextShow>
              </CheckboxGroup>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Do you have a contact for financial inquiry?',
        model: 'has_financial_inquiry_contact',
        isDisabled: false,
        component: ({ isRequired, roiDisclaimerMessage }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you have a contact for financial inquiry?"
              model="personal_finance.has_financial_inquiry_contact"
              isRequired={isRequired}
            />

            <ContextShow when="personal_finance.has_financial_inquiry_contact" is={true}>
              <FormCard title="Financial Inquiry Contact Details">
                <ContactInputs
                  model="personal_finance.financial_inquiry_contact"
                  relationship="financial_inquiry_contact"
                  roiDisclaimerMessage={roiDisclaimerMessage}
                />
              </FormCard>
            </ContextShow>
          </>
        ),
      },
      // {
      //   label: 'Are you on disability?',
      //   model: 'is_on_disability',
      //   isDisabled: false,
      //   component: ({ isRequired }: FieldProps) => (
      //     <>
      //       <YesNoRadioGroup
      //         label="Are you on disability?"
      //         model="personal_finance.is_on_disability"
      //         validations={
      //           isRequired && {
      //             presence: {
      //               message: 'Please select a response',
      //             },
      //           }
      //         }
      //       />

      //       <ContextShow when="personal_finance.is_on_disability" is={true}>
      //         <AmountInput label="Disability yearly amount" model="personal_finance.disability_yearly_amount" suffix="/ year" />
      //       </ContextShow>
      //     </>
      //   ),
      // },
      {
        label: 'If for some reason you cannot pay rent per week / month who can you call upon to help you?',
        model: 'financial_help_contacts',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Textarea
            label="If for some reason you cannot pay rent per week / month who can you call upon to help you?"
            model="personal_finance.financial_help_contacts"
            validations={
              isRequired && {
                presence: {
                  message: 'Please describe',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you receive any ongoing financial reimbursement for any reason?',
        model: 'does_receive_ongoing_financial_reimbursement',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroup
            label="Do you receive any ongoing financial reimbursement for any reason?"
            model="personal_finance.does_receive_ongoing_financial_reimbursement"
            validations={
              isRequired && {
                presence: {
                  message: 'Please select a response',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you owe money to a former Sober Living House?',
        model: 'previous_sober_living_debt',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <YesNoRadioGroupWithTextarea
            label="Do you owe money to a former Sober Living House?"
            model="personal_finance.has_previous_sober_living_debt"
            isRequired={isRequired}
          />
        ),
      },
      {
        label: 'Weekly expenses (food, car payment, child support, etc)',
        model: 'weekly_expenses',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Textarea
            label="Weekly expenses (food, car payment, child support, etc)"
            model="personal_finance.weekly_expenses"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter your weekly expenses',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Additional Financial Information',
        model: 'additional_financial_information',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Textarea
            label="Additional Financial Information"
            model="personal_finance.additional_financial_information"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter any other relevant financial information',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you currently have any debts?',
        model: 'has_current_debts',
        isDisabled: false,
        component: () => (
          <>
            <YesNoRadioGroup label="Do you currently have any debts?" model="personal_finance.has_current_debts" />

            <ContextShow when="personal_finance.has_current_debts" is={true}>
              <CheckboxGroup label="Which types of debt do you have?" layout="vertical-dense">
                <Checkbox label="Student loans" model="personal_finance.current_debts.student_loans" />
                <Checkbox label="Car Loan(s)" model="personal_finance.current_debts.car_loans" />
                <Checkbox label="Mortgage" model="personal_finance.current_debts.mortgage" />
                <Checkbox label="Personal Loans" model="personal_finance.current_debts.personal_loans" />
                <Checkbox label="Credit Cards" model="personal_finance.current_debts.credit_cards" />
                <Checkbox label="Child Support" model="personal_finance.current_debts.child_support" />
                <Checkbox label="Court Fees" model="personal_finance.current_debts.court_fees" />
                <Checkbox label="Fines" model="personal_finance.current_debts.fines" />
                <Checkbox label="Past Due Bills" model="personal_finance.current_debts.past_due_bills" />
                <Checkbox label="Owe Dealer" model="personal_finance.current_debts.owe_dealer" />
                <Checkbox label="Prefer not to answer" model="personal_finance.current_debts.prefer_not_to_answer" />
              </CheckboxGroup>
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Transportation',
    model: 'transportation',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Do you have a valid drivers license?',
        model: 'drivers_license',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <YesNoRadioGroup
              label="Do you have a valid drivers license?"
              model="transportation.has_valid_drivers_license"
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a response',
                  },
                }
              }
            />

            <ContextShow when="transportation.has_valid_drivers_license" is={true}>
              <YesNoRadioGroup
                label="Are you willing to be of service and help other residents get to meetings?"
                model="transportation.is_willing_to_help_others_get_to_meetings"
              />
            </ContextShow>

            <ContextShow when="transportation.has_valid_drivers_license" is={false}>
              <RadioGroup label="Please describe" model="transportation.no_valid_drivers_license_status" layout="vertical-dense">
                <Radio label="Do not posses a drivers license" value="has_no_drivers_license" />
                <Radio label="Drivers license currently suspended" value="has_suspended_drivers_license" />
              </RadioGroup>

              <ContextShow when="transportation.no_valid_drivers_license_status" is="has_no_drivers_license">
                <YesNoRadioGroup
                  label="Do you need assistance obtaining your drivers license?"
                  model="transportation.needs_assistance_obtaining_drivers_license"
                />
              </ContextShow>

              <ContextShow when="transportation.no_valid_drivers_license_status" is="has_suspended_drivers_license">
                <YesNoRadioGroup
                  label="Do you need assistance reinstating your drivers license?"
                  model="transportation.needs_assistance_reinstating_drivers_license"
                />
              </ContextShow>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'What is your primary mode of transportation?',
        model: 'primary_transportation_mode',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <>
            <PrimaryTransportationModeRadioGroup
              label="What is your primary mode of transportation?"
              model="transportation.primary_transportation_mode"
              isRequired={isRequired}
            />

            <ContextShow when="transportation.primary_transportation_mode" is="personal_vehicle">
              <YesNoRadioGroup
                label="Do you plan on having your personal vehicle at the property?"
                model="transportation.does_plan_to_bring_vehicle"
                validations={
                  isRequired && {
                    presence: {
                      message: 'Please select a response',
                    },
                  }
                }
              />

              <ContextShow when="transportation.does_plan_to_bring_vehicle" is={true}>
                <H4 className="!mt-2">Vehicle Details</H4>
                <Input
                  label="Make"
                  model="transportation.personal_vehicle.make"
                  validations={
                    isRequired && {
                      presence: {
                        message: "Please enter your vehicle's make",
                      },
                    }
                  }
                />
                <Input
                  label="Model"
                  model="transportation.personal_vehicle.model"
                  validations={
                    isRequired && {
                      presence: {
                        message: "Please enter your vehicle's model",
                      },
                    }
                  }
                />
                <Input
                  label="Color"
                  model="transportation.personal_vehicle.color"
                  validations={
                    isRequired && {
                      presence: {
                        message: "Please enter your vehicle's color",
                      },
                    }
                  }
                />
                <Input
                  label="License Plate Number"
                  model="transportation.personal_vehicle.license_plate_number"
                  validations={
                    isRequired && {
                      presence: {
                        message: "Please enter your vehicle's license plate number",
                      },
                    }
                  }
                />
              </ContextShow>

              <YesNoRadioGroup label="Do you have proof of registration?" model="transportation.has_registration_proof" />

              <YesNoRadioGroup label="Do you have proof of insurance?" model="transportation.has_insurance_proof" />
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Sensitive Information',
    model: 'sensitive_information',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Drivers License Number',
        model: 'drivers_license_no',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Input
            label="Drivers License Number"
            model="sensitive_information.drivers_license_no"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter your Drivers License Number',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Social Security Number',
        model: 'ssn',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Input
            label="Social Security Number"
            model="sensitive_information.ssn"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter your Social Security Number',
                },
              }
            }
          />
        ),
      },
      {
        label: 'State ID Number',
        model: 'state_id_no',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Input
            label="State ID Number"
            model="sensitive_information.state_id_no"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter your State ID Number',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Limited License (Interlock Device)',
        model: 'limited_license_interlock_device',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Input
            label="Limited License (Interlock Device)"
            model="sensitive_information.limited_license_interlock_device"
            validations={
              isRequired && {
                presence: {
                  message: 'Please enter your Limited License Number',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Additional Info',
    model: 'additional_information',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    fields: [
      {
        label: 'Please enter any other information about yourself or your situation that you feel we need to know',
        model: 'other_information',
        isDisabled: false,
        component: ({ isRequired }: FieldProps) => (
          <Textarea
            label="Please enter any other information about yourself or your situation that you feel we need to know"
            model="additional_information.other_information"
            validations={
              isRequired && {
                presence: {
                  message: 'Please describe',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Attachments',
    model: 'attachments',
    glyph: 'behave_health',
    isRequired: false,
    isOpen: false,
    disableCustomQuestion: true,
    description: {
      label: 'Description',
      placeholder: 'Write a description here…',
    },
    renderForm: (_, { canUpload }) => (
      <>
        <FileAttachmentsInput
          canUpload={canUpload}
          model="file_attachments"
          label="Attachments"
          cannotUploadMessage={!canUpload && 'Files can only be uploaded when submitting an Application Form'}
        />
      </>
    ),
  },
  {
    title: 'Signature',
    model: 'signature',
    glyph: 'signature',
    isRequired: false,
    isOpen: false,
    disableCustomQuestion: true,
    description: {
      label: 'Signature disclaimer',
      placeholder: 'Write a signature disclaimer here…',
    },
    renderForm: () => (
      <>
        <SignaturePad
          showSignedBy={false}
          label="Signature"
          model="client_signature"
          signedAtModel="client_signature_signed_at"
          allowPin={false}
          showAccept={false}
        />
      </>
    ),
  },
]
