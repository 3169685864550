import React from 'react'

import { useSettings } from '../../../hooks/useSettings'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import GridTable from '../../../components/GridTable'
import SmartStatus from '../../../components/SmartStatus'
import Status from '../../../components/Status'
import SummonOverlay from '../../../components/SummonOverlay'

import { ProfileCell } from './TreeComponents'
import { TreatmentPlanSignatureOverlay } from '../TreatmentPlanSignatureOverlay'
import { usDateTime } from '../../../utils/functions'
import size from 'lodash/size'

const SignCell = ({ shouldSign, signature, signedAt, onClick, canEdit, permissionBase }: any) => {
  const didSign = !!signature && !!signedAt

  const { isPortal } = useSettings()

  return (
    <GridTable.Cell centerY>
      {didSign && (
        <div className="flex flex-nowrap items-center w-full">
          <Status small label={'Signed'} color="green" glyph="tick_circle" className="mr-1" />
          <Status small label={usDateTime(signedAt)} color="lightGray" glyph="date" />

          {canEdit && (
            <div className="ml-auto pl-2">
              <Button
                label="Edit"
                glyph="edit"
                size={100}
                onClick={onClick}
                css={{ svg: { margin: '0 !important' } }}
                permission={isPortal ? undefined : `${permissionBase}.edit`}
              />
            </div>
          )}
        </div>
      )}

      {!didSign && (
        <div className="flex flex-nowrap items-center justify-between w-full">
          {shouldSign ? <Status small label="Signature Required" color="red" /> : <div />}
          {canEdit && (
            <Button
              glyph="signature"
              label="Add Signature"
              size={100}
              onClick={onClick}
              permission={isPortal ? undefined : `${permissionBase}.edit`}
            />
          )}
        </div>
      )}
    </GridTable.Cell>
  )
}

export const TreatmentPlanSignees = (props: any) => {
  const { isOpen, treatmentPlan, canEdit, settings = {}, signees, renderAfter, after, permissionBase } = props

  const { isPortal, user } = useSettings()

  const [currentSignatureType, setCurrentSignatureType]: any = React.useState(null)
  const [currentSignee, setCurrentSignee]: any = React.useState(null)

  const { initial_signatures_required = {}, updates_signatures_required = {}, signatures_legal_copy } = settings

  let clientSignatureRequired = !!initial_signatures_required.client

  // request client signature only if guardian exists when the option is enabled
  if (initial_signatures_required?.client_only_if_guardian_exists === true) {
    clientSignatureRequired = size(treatmentPlan?.contacts) > 0 ? true : false
  }

  const CATEGORY_CONFIG = React.useMemo(() => {
    return {
      client: {
        label: 'Client',
        color: 'vividBlue',
        shouldSignInitially: clientSignatureRequired,
        shouldSignUpdates: updates_signatures_required.client,
        signatureLegalCopy: signatures_legal_copy?.client,
      },
      contact: {
        label: 'Guardian',
        color: 'vividBlue',
        shouldSignInitially: initial_signatures_required.contacts,
        shouldSignUpdates: updates_signatures_required.contacts,
        signatureLegalCopy: signatures_legal_copy?.contacts,
      },
      supervisor: {
        label: 'Supervisor',
        color: 'orange',
        shouldSignInitially: initial_signatures_required.supervisors,
        shouldSignUpdates: updates_signatures_required.supervisors,
        signatureLegalCopy: signatures_legal_copy?.supervisors,
      },
      staff: {
        label: 'Staff',
        color: 'blue',
        shouldSignInitially: initial_signatures_required.staff,
        shouldSignUpdates: updates_signatures_required.staff,
        signatureLegalCopy: signatures_legal_copy?.staff,
      },
    }
  }, [settings, clientSignatureRequired])

  const anyShouldSignInitially =
    clientSignatureRequired ||
    initial_signatures_required.supervisors ||
    initial_signatures_required.staff ||
    initial_signatures_required.contacts

  const anyShouldSignUpdates =
    updates_signatures_required.client ||
    updates_signatures_required.supervisors ||
    updates_signatures_required.staff ||
    updates_signatures_required.contacts

  const tableConfig = React.useMemo(() => {
    const result: any = [
      {
        title: 'Role',
        width: '100px',
        render: (signee: any) => (
          <GridTable.Cell>
            <SmartStatus small statuses={CATEGORY_CONFIG} status={signee.category} />
          </GridTable.Cell>
        ),
      },
      {
        title: 'Name',
        width: 'minmax(180px, 3fr)',
        render: (signee: any) => <ProfileCell avatar={signee.reference?.avatar} name={signee.reference?.name} />,
      },
    ]

    if (anyShouldSignInitially) {
      result.push({
        title: 'Signature',
        width: 'minmax(300px, 2fr)',
        render: (signee: any) => {
          const shouldSign = CATEGORY_CONFIG[signee.category]?.shouldSignInitially
          const reference = signee?.reference

          return (
            <SignCell
              canEdit={isPortal ? reference?.type === 'resident' && reference?.id === user?.id : canEdit}
              shouldSign={shouldSign}
              signature={signee.initial_signature}
              signedAt={signee.initial_signed_at}
              permissionBase={permissionBase}
              onClick={() => {
                setCurrentSignatureType('initial')
                setCurrentSignee(signee)
              }}
            />
          )
        },
      })
    }

    return result
  }, [isPortal, user, settings, anyShouldSignInitially, anyShouldSignUpdates, canEdit, CATEGORY_CONFIG])

  const templateColumns = React.useMemo(() => {
    return tableConfig.map((col: any) => col.width).join(' ')
  }, [tableConfig])

  if (!signees || !settings) return null

  return (
    <>
      <Card>
        {!anyShouldSignInitially && !anyShouldSignUpdates && (
          <div className="px-2 pt-2">
            <Alert small glyph="info">
              This Treatment Plan doesn't require any signatures. {canEdit && 'You can change this by editing the Signature Settings.'}
            </Alert>
          </div>
        )}

        <div className="text-[0.92rem]">
          <GridTable testKey="signees_table" useBanding={false} templateColumns={templateColumns}>
            <GridTable.Header>
              {tableConfig.map((col: any) => (
                <GridTable.Cell key={col.title}>{col.title}</GridTable.Cell>
              ))}
            </GridTable.Header>

            {signees?.map?.((signee: any) => {
              return (
                <GridTable.Row key={signee.id}>
                  {tableConfig.map((col: any) => (
                    <React.Fragment key={`${signee.id}-${col.title}`}>{col.render?.(signee)}</React.Fragment>
                  ))}
                </GridTable.Row>
              )
            })}
          </GridTable>
        </div>
      </Card>

      <SummonOverlay
        isOpen={!!currentSignee}
        onClose={() => {
          setCurrentSignee(null)
          setCurrentSignatureType(null)
        }}
        overlay={
          <TreatmentPlanSignatureOverlay
            dataID={currentSignee?.id}
            signatureType={currentSignatureType}
            legalCopy={currentSignee && CATEGORY_CONFIG?.[currentSignee?.category]?.signatureLegalCopy}
          />
        }
      />
    </>
  )
}
