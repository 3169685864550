import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { VitalSignsReportDataTable } from '@behavehealth/constructs/LiveReports/VitalSignsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const VitalSigns = () => {
  const tableProps = useDataTable({
    name: ['reports', 'vital-signs'],
    endpoint: `/live_reports?category=vital_signs`,
    localStorageKey: 'report_vital_signs_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData?.client)}/test-results/vital-signs/${rowData?.id}`, [])

  return (
    <Page feature="test_results" title="Live Vital Signs Report">
      <Grid>
        <VitalSignsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(VitalSigns)
