import React from 'react'
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom'
import size from 'lodash/size'
import compact from 'lodash/compact'

import { APP_URLS } from '@behavehealth/constructs/MyAccount/utils'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { ORGANIZATIONS_TYPE_VENDORS, ORGANIZATIONS_TYPE_RESOURCES, ORGANIZATIONS_TYPE_PROVIDERS } from '@behavehealth/utils/constants'

import { CommunityProfileShare } from '@behavehealth/constructs/Community/CommunityProfileShare'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { PARTNERSHIP_STATUSES } from '@behavehealth/constructs/Partnerships/PartnershipStatus'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { EHROrganizationStatus } from '@behavehealth/constructs/Organizations/EHROrganizationStatus'

import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import Icon from '@behavehealth/components/Icon'
import PageHeader from '@behavehealth/components/PageHeader'
import Status from '@behavehealth/components/Status'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'
import Tooltip from '@behavehealth/components/Tooltip'
import ConfirmDialog from '@behavehealth/components/Dialogs/ConfirmDialog'
import Permission from '@behavehealth/components/Permission'

import { CommunityMessageBoard } from './community_message_board'
import { PartnersLocations } from './community_shared_locations'

import { OrganizationCommunityRequestOverlay } from '@behavehealth/constructs/Community/OrganizationCommunityRequestOverlay'
import { OrganizationInviteRequestOverlay } from '@behavehealth/constructs/Community/OrganizationInviteRequestOverlay'
import { OrganizationDirectInviteOverlay } from '@behavehealth/constructs/Community/OrganizationDirectInviteOverlay'

import image from '@behavehealth/assets/images/organizations_statuses_legend.jpg'
import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'
import { OrganizationTrackSelector } from '@behavehealth/constructs/Organizations/OrganizationTrackSelector'
import { useUpdate } from '@behavehealth/hooks/useNewAPI'

export const CommunityOrganizations: React.FC = () => {
  const match = useRouteMatch()
  const location = useLocation()

  const { connectedEntities } = useSettings()
  const hasConnectedEntities = size(connectedEntities) > 0

  return (
    <div css={STYLES.root}>
      <div className="bg-white sticky top-0 z-10 flex-0">
        <PageHeader
          title="Organizations"
          icon="organizations"
          titleAside={
            hasConnectedEntities && (
              <div className="!ml-3">
                <CommunityProfileShare profiles={connectedEntities} label="Share Your Profile…" />
              </div>
            )
          }
          aside={
            <Flex gap="0.5rem">
              <Button
                label="Explore Community →"
                icon="community"
                type="minimal"
                link="/community/explore"
                permission="community_explore.view"
              />

              {/* <Button
                label="Add Internal Organization"
                type="default"
                glyph="add"
                link={`${location.pathname}/create-new`}
                permission="organizations.actions.create"
              /> */}

              <Button
                label="Import New Organization"
                type="primary"
                glyph="add"
                link={`${location.pathname}/import-new`}
                permission="organizations.actions.create"
              />
            </Flex>
          }
        />

        <Tabs>
          <Tabs.List className="px-4">
            <Tabs.Item label="Internal" to={`/community/organizations/internal`} />
            <Tabs.Item label="Providers" to={`/community/organizations/providers`} />
            <Tabs.Item label="Vendors" to={`/community/organizations/vendors`} />

            <Permission featureFlagV2="authority_organizations">
              <Tabs.Item label="Authorities" to={`/community/organizations/authorities`} />
            </Permission>

            <Tabs.Divider />

            <Tabs.Item label="Archived" to={`/community/organizations/archived`} />
          </Tabs.List>
        </Tabs>
      </div>

      <div css={STYLES.content}>
        <Switch>
          <Route path={`/community/organizations/internal`}>
            <OrganizationsDataTable title="Internal Organizations" queryParams={{ status: 'organization', relationship: 'internal' }} />
          </Route>

          <ProtectedRoute path={`/community/organizations/authorities`} featureFlag="authority_organizations">
            <OrganizationsDataTable title="Authority Organizations" queryParams={{ status: 'organization', relationship: 'authority' }} />
          </ProtectedRoute>

          <Route path={`/community/organizations/providers`}>
            <OrganizationsDataTable title="Providers" queryParams={{ status: 'organization', relationship: 'provider' }} />
          </Route>

          <Route path={`/community/organizations/vendors`}>
            <OrganizationsDataTable title="Vendors" queryParams={{ status: 'organization', relationship: 'vendor' }} />
          </Route>

          <Route path={`/community/organizations/shared-locations`} component={PartnersLocations} />
          <Route path={`/community/organizations/message-board`} component={CommunityMessageBoard} />

          {/* <Route path={`/community/organizations/all`}>
            <OrganizationsDataTable title="Organizations & Partners" testKey="organizations_data_table" />
          </Route> */}

          {/* <Route path={`/community/organizations/orgs`}>
            <OrganizationsDataTable title="Organizations" queryParams={{ status: 'organization' }} />
          </Route> */}

          {/* <Route path={`/community/organizations/partners`}>
            <OrganizationsDataTable title="Partners" queryParams={{ status: 'partner' }} />
          </Route> */}

          <Route path={`/community/organizations/archived`}>
            <ArchivedOrganizations />
          </Route>

          <Redirect exact from={match.url} to={`/community/organizations/internal`} />
        </Switch>
      </div>
    </div>
  )
}

const UnArchiveOrganization = ({ organization }) => {
  const { mutateAsync: updatePartnership }: any = useUpdate({
    name: ['update-organization'],
    url: `/organizations/${organization?.id}`,
    invalidate: ['organization', organization?.id],
    invalidateKeys: ['organizations'],
  })

  const unarchiveOrganization = async () => {
    await updatePartnership({ status: 'active' })
  }

  if (!organization?.id) return null

  return (
    <ConfirmDialog
      onYes={unarchiveOrganization}
      title="Un-Archive Organization"
      message="Are you sure you want to un-archive this organization?"
    >
      <Button label="Un-Archive" glyph="delete" type="default" color="gray" size={100} display="inline-flex" />
    </ConfirmDialog>
  )
}

const OrganizationsDataTable = ({ title, queryParams, isArchived }: any) => {
  const match = useRouteMatch()
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['organizations'],
    endpoint: '/organizations/search',
    updateDeleteEndpoint: '/organizations',
    params: queryParams,
    localStorageKey: isArchived ? 'archived_organizations_v3' : 'organizations_v3',
  })

  const [inviteCommunity, setInviteCommunity] = React.useState(null)
  const [inviteOrg, setInviteOrg] = React.useState(null)
  const [directInviteOrg, setDirectInviteOrg] = React.useState(null)

  const columns = React.useMemo(
    () =>
      compact([
        {
          title: 'Name',
          model: 'name',
          width: 260,
          disableHide: true,
          formatValue: ({ data }: any) => {
            return <MainCell id={data.id} value={data.name} to={`/community/organizations/${data?.id}`} />
          },
        },
        isArchived && {
          title: 'Actions',
          id: '_actions',
          width: 200,
          disableHide: true,
          disableSort: true,
          hoverExpand: 'false',
          formatValue: ({ data }: any) => {
            return <UnArchiveOrganization organization={data} />
          },
        },
        {
          title: 'Organization Track',
          model: 'organization_track',
          featureFlag: 'organization_tracks',
          formatValue: ({ value, data }) => <OrganizationTrackSelector organizationId={data.id} activeTrack={value} buttonLabel={null} />,
        },
        {
          title: 'Tags',
          model: 'tags',
          type: 'tags',
          featureFlag: 'tags',
          editPermission: 'tags.edit',
        },
        {
          title: 'Type',
          model: 'category',
          type: 'title',
        },
        {
          title: 'Category',
          id: 'subcategory',
          model: 'subcategory',
          type: 'title',
        },
        {
          title: 'Phone',
          model: 'phone_no',
          type: 'phone',
        },
        {
          title: 'Fax',
          model: 'fax',
          type: 'fax',
        },
        {
          title: 'Email',
          model: 'email',
          type: 'email',
        },
        {
          title: 'Website',
          model: 'website_url',
          type: 'external_url',
        },
        {
          title: 'City',
          id: 'city',
          model: 'address.city',
        },
        {
          title: 'State',
          id: 'state',
          model: 'address.state',
        },
        {
          title: 'Address',
          id: 'address',
          model: 'address',
          type: 'address',
          width: 280,
          disableSort: false,
        },
        {
          title: 'Client Portal Sharing',
          model: 'is_shared_with_portal',
          type: 'boolean',
          formatValue: ({ value }: any) => (value ? 'Shared' : 'Not shared'),
        },
      ]),
    [match.url],
  )

  return (
    <>
      <DataTable
        asCard
        testKey="organizations_data_table"
        title={title}
        headerAfter={
          isBehave && (
            <Tooltip glyph="question" position="bottom" content={<img src={image} className="w-full max-h-[2000px]" />}>
              <Status glyph="behave_health" label="Legend" color="blue" />
            </Tooltip>
          )
        }
        icon="organizations"
        columns={columns}
        filtersConfig={FILTERS_CONFIG}
        {...tableProps}
      />

      <SummonOverlay
        isOpen={!!inviteCommunity}
        onClose={() => {
          setInviteCommunity(null)
        }}
        overlay={<OrganizationCommunityRequestOverlay orgId={inviteCommunity?.id} orgName={inviteCommunity?.name} />}
      />

      <SummonOverlay
        isOpen={!!inviteOrg}
        onClose={() => {
          setInviteOrg(null)
        }}
        overlay={<OrganizationInviteRequestOverlay orgId={inviteOrg?.id} orgName={inviteOrg?.name} />}
      />

      <SummonOverlay
        isOpen={!!directInviteOrg}
        onClose={() => {
          setDirectInviteOrg(null)
        }}
        overlay={<OrganizationDirectInviteOverlay orgId={directInviteOrg?.id} orgName={directInviteOrg?.name} />}
      />
    </>
  )
}

const ArchivedOrganizations = () => {
  const match = useRouteMatch()

  return (
    <div>
      <Tabs>
        <Tabs.List className="-mt-2">
          <Tabs.Item label="Archived Internal" to={`/community/organizations/archived/internal`} />
          <Tabs.Item label="Archived Providers" to={`/community/organizations/archived/providers`} />
          <Tabs.Item label="Archived Vendors" to={`/community/organizations/archived/vendors`} />

          <Permission featureFlagV2="authority_organizations">
            <Tabs.Item label="Archived Authorities" to={`/community/organizations/archived/authorities`} />
          </Permission>
        </Tabs.List>
      </Tabs>

      <div className="mt-4">
        <Switch>
          <Route path={`/community/organizations/archived/internal`}>
            <OrganizationsDataTable
              isArchived
              title="Archived Internal Organizations"
              queryParams={{ status: 'archived', relationship: 'internal' }}
            />
          </Route>

          <ProtectedRoute path={`/community/organizations/archived/authorities`} featureFlag="authority_organizations">
            <OrganizationsDataTable
              isArchived
              title="Archived Authority Organizations"
              queryParams={{ status: 'archived', relationship: 'authority' }}
            />
          </ProtectedRoute>

          <Route path={`/community/organizations/archived/providers`}>
            <OrganizationsDataTable isArchived title="Archived Providers" queryParams={{ status: 'archived', relationship: 'provider' }} />
          </Route>

          <Route path={`/community/organizations/archived/vendors`}>
            <OrganizationsDataTable isArchived title="Archived Vendors" queryParams={{ status: 'archived', relationship: 'vendor' }} />
          </Route>

          <Redirect exact from={match.url} to={`/community/organizations/archived/internal`} />
        </Switch>
      </div>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
  },

  content: {
    padding: '1rem',
    overflowY: 'auto',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
  },
}

const VENDOR_SELECT_OPTIONS = Object.keys(ORGANIZATIONS_TYPE_VENDORS).map((vendor) => ({
  label: ORGANIZATIONS_TYPE_VENDORS[vendor],
  value: vendor,
}))

const RESOURCE_SELECT_OPTIONS = Object.keys(ORGANIZATIONS_TYPE_RESOURCES).map((resource) => ({
  label: ORGANIZATIONS_TYPE_RESOURCES[resource],
  value: resource,
}))

const PROVIDER_SELECT_OPTIONS = Object.keys(ORGANIZATIONS_TYPE_PROVIDERS).map((provider) => ({
  label: ORGANIZATIONS_TYPE_PROVIDERS[provider],
  value: provider,
}))

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  tags: {
    label: 'Tags',
    type: 'tags',
  },
  category: {
    label: 'Type',
    type: 'multi_select',
    options: [
      { label: 'Vendor', value: 'vendor' },
      { label: 'Community Resource', value: 'resource' },
      { label: 'Provider', value: 'provider' },
    ],
  },
  subcategory: {
    label: 'Category',
    type: 'multi_select',
    options: [
      { groupTitle: 'Vendor' },
      ...VENDOR_SELECT_OPTIONS,

      { groupTitle: 'Community Resource' },
      ...RESOURCE_SELECT_OPTIONS,

      { groupTitle: 'Provider' },
      ...PROVIDER_SELECT_OPTIONS,
    ],
  },
  phone_no: {
    label: 'Phone No',
    type: 'string',
  },
  fax: {
    label: 'Fax',
    type: 'string',
  },
  email: {
    label: 'Email',
    type: 'string',
  },
  city: {
    label: 'City',
    type: 'string',
  },
  state: {
    label: 'State',
    type: 'string',
  },
  website_url: {
    label: 'Website',
    type: 'string',
  },
  address: {
    label: 'Address',
    type: 'string',
  },
  is_shared_with_portal: {
    label: 'Client Portal Sharing',
    type: 'boolean',
  },
}
