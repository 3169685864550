import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  peer_note: {
    label: 'Peer Note',
    color: 'green',
  },
  daily_note: {
    label: 'Daily Note',
    color: 'green',
  },
  custom_note: {
    label: 'Custom Note',
    color: 'green',
  },
  treatment_plan: {
    label: 'Treatment Plan',
    color: 'green',
  },
  clinical_note: {
    label: 'Clinical Note',
    color: 'green',
  },
  ua_test: {
    label: 'UA Test',
    color: 'green',
  },
  breathalyzer_test: {
    label: 'Breathalyzer Test',
    color: 'green',
  },
  vital_sign: {
    label: 'Vital Sign',
    color: 'green',
  },
  lab_test: {
    label: 'Lab Test',
    color: 'green',
  },
  data_form: {
    label: 'Record Form',
    color: 'green',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
