import React from 'react'

import Checkbox from '../../components/Forms/Checkbox'
import Permission from '../../components/Permission'
import Overlay from '../../components/Overlay'
import Alert from '../../components/Alert'
import Divider from '../../components/Divider'
import SummonOverlay from '../../components/SummonOverlay'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'

export const RecoveryCapitalFormConsent = ({ label = 'The client has consented to participate in recovery research' }) => {
  return (
    <Permission featureFlagV2="recovery_capital_forms">
      <CheckboxGroup label="Consent for Participation in Recovery Research" trueIcon="check" falseIcon="cross" falseStyle="faded">
        <Alert small contrast glyph="info">
          View the{' '}
          <SummonOverlay overlay={<RecoveryCapitalFormConsentOverlay />}>
            <span className="text-blue-500 font-[600] cursor-pointer">Consent for Participation in Recovery Research</span>
          </SummonOverlay>{' '}
          for more information.
        </Alert>

        <Checkbox defaultChecked label={label} model="has_consent_for_rcf" />
      </CheckboxGroup>
    </Permission>
  )
}

export const RecoveryCapitalFormConsentOverlay = ({ onClose }) => {
  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={onClose} maxWidth={54}>
      <Overlay.Header title="Consent for Participation in Recovery Research" icon="recovery_capital_forms" />

      <Overlay.Content className="p-5 ">
        <h3>Research Objectives</h3>
        <p>
          Academic researchers utilize de-identified data to develop insights into the effective use of recovery capital measures for
          enhancing recovery care planning and fostering resilience. In partnership with the Recovery Outcomes Institute (ROI), researchers
          in the international scientific community may request access to de-identified Recovery Capital Growth Model data for research
          purposes.
        </p>
        <p>
          A core objective of this research is to improve wellness for persons living with substance use disorders (SUD). Research studies
          use Recovery Capital Measures to formulate benchmarks for individuals identifying as being in recovery, based on factors such as
          gender, age, recovery pathway, and length of time in recovery. This research requires large-scale data collection from service
          providers using the Recovery Capital Growth Model within their organizations.
        </p>
        <p>
          By allowing your de-identified Recovery Capital Growth Model data to be included in research, you contribute to improving future
          outcomes for individuals in recovery.
        </p>

        <Divider />

        <h3>How This Impacts You</h3>
        <p>
          You are being presented with this information because you are a client of a participating Provider. Your provider will collect
          responses from you using the Recovery Capital Growth Model, a tool designed to measure your recovery capital, or you may enter the
          responses on your own in the client portal.
        </p>
        <p>- Your responses will generate a visual summary of your progress.</p>
        <p>- This summary will support discussions about your recovery and assist in developing your care plan.</p>
        <p>- Your data will be stored securely and anonymously and may be used for research purposes.</p>
        <p>All data used for research is fully anonymized and complies with strict ethical and confidentiality guidelines, including:</p>
        <ul>
          <li>The Data Protection Act (1998)</li>
          <li>42 Code of Federal Regulations-Part 2 (42 C.F.R, Part 2)</li>
          <li>Health Insurance Portability and Accountability Act (HIPAA)</li>
          <li>National Health Service (NHS) Research Ethics Guidelines</li>
          <li>
            Your anonymized data may be analyzed and included in reports, academic research papers, or books to help advance the
            understanding of recovery pathways.
          </li>
        </ul>

        <Divider />

        <h3>De-Identification of Data</h3>
        <p>
          No personally identifying information (such as name, date of birth, social security numbers, or contact information) is ever
          shared with third parties or researchers.
        </p>
        <p>
          Your data is stored in a secure database, and each participant is assigned a unique, randomly generated identification number
          (ClientID) for anonymization.
        </p>
        <p>All public reports, research publications, and analyses will only use de-identified, anonymized data.</p>

        <h3>Your Consent and Opt-Out Option</h3>
        <p>
          Your participation in research is <strong>voluntary</strong>, and you may <strong>opt-out at any time</strong>.
        </p>
        <p>
          By default, <b>you consent to the use of your de-identified data</b> for research purposes.{' '}
          <b>No further action is required unless you choose to opt out.</b>
        </p>

        <h3>Opt-Out Process</h3>
        <p>
          If you <b>do not wish to contribute your de-identified data</b>, you may opt-out by logging into your client portal and updating
          your privacy settings.
        </p>
        <p>
          You may <b>change your consent status at any time</b> through your client portal.
        </p>
        <p>
          If you experience any challenges accessing your client portal please contact the organization who sent you this agreement for
          assistance.
        </p>

        <Divider />

        <h3>Categories of De-Identified Data Used for Research</h3>
        <p>
          The information used for research purposes includes <b>de-identified data</b> related to:
        </p>

        <ol>
          <li>
            <b>Program Participation & Discharge Trends</b>
          </li>
          <ul>
            <li>Enrollment periods, admission and discharge dates</li>
            <li>Service provider region and type</li>
            <li>General trends in residency duration and discharge reasons</li>
            <li>System-generated identifiers that do not contain personally identifiable information</li>
          </ul>
          <li>
            <b>Recovery Goals & Progress Tracking</b>
          </li>
          <ul>
            <li>General goal-setting trends (goal names, categories, and completion rates)</li>
            <li>De-identified timestamps for goal creation, updates, and completion</li>
            <li>Anonymized recovery plan activity, including goal completion percentages and status changes</li>
            <li>Broad patterns in recovery focus areas (e.g., employment, housing, social support)</li>
          </ul>
          <li>
            <b>Recovery Capital, Quality of Life & Service Needs</b>
          </li>
          <ul>
            <li>Recovery Capital Scores, including social, personal, and community recovery domains</li>
            <li>Patterns in quality of life, commitment to recovery, and social support structures</li>
            <li>Anonymous summaries of service needs (e.g., housing, mental health, employment)</li>
            <li>General trends in substance use, treatment engagement, and recovery pathway participation</li>
            <li>De-identified indicators of barriers to recovery (e.g., housing instability, criminal justice involvement)</li>
            <li>Anonymized information on employment, volunteering, and training participation</li>
          </ul>
          <li>
            <b>Client Demographics & Recovery Pathways</b>
          </li>
          <ul>
            <li>Age, gender, and racial/ethnic background (anonymized)</li>
            <li>General patterns in employment status and program engagement</li>
            <li>Self reported participation in various recovery pathways (e.g., 12-step, faith-based, SMART Recovery)</li>
            <li>Trends in priority populations (e.g., veterans, pregnant individuals, justice-involved persons)</li>
          </ul>
          <li>
            <b>Medication-Assisted Treatment (MOUD) Trends</b>
          </li>
          <ul>
            <li>Anonymous records of MOUD participation, including start and end dates</li>
            <li>General patterns in medication types and treatment durations</li>
            <li>De-identified demographic data (e.g., age, gender, ethnicity)</li>
            <li>Trends in priority populations (e.g., veterans, justice-involved persons, physical disabilities)</li>
            <li>Referral sources for treatment without personally identifying details</li>
          </ul>
        </ol>

        <Divider />

        <h3>Acknowledgment</h3>
        <p>By continuing to use the platform, you acknowledge that:</p>
        <ul>
          <li>You understand the research objectives and the de-identification process. </li>
          <li>You have the right to ask questions about this policy. </li>
          <li>Your participation is voluntary, and you may withdraw at any time without consequence. </li>
          <li>Your responses will always be anonymized before being used in research. </li>
          <li>You may opt out at any time by updating your settings in the client portal.</li>
          <li>If you opt back in then we will be allowed to use your historical data</li>
        </ul>

        <p>Together, we strive to enhance professional recovery services through scientific research. Your contribution is invaluable!</p>
      </Overlay.Content>

      <Overlay.Footer>
        <Alert small contrast glyph="info">
          <div className="grid gap-1">
            <h3>Opt-Out Process</h3>
            <div>
              If you do not wish to contribute your de-identified data, you may opt-out by logging into your{' '}
              <a href="https://portal.behavehealth.com/login" target="_blank" rel="noreferrer" className="text-blue-500">
                Client Portal
              </a>{' '}
              and updating your privacy settings.
            </div>

            <div>You may change your consent status at any time through your client portal.</div>
          </div>
        </Alert>
      </Overlay.Footer>
    </Overlay>
  )
}
