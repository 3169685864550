import React from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import State from '../../components/State'

import { AUTHORITY_FORMS } from './forms'
import { ClientTrackTabs } from '../ClientTracks/ClientTrackTabs'
import { titleCase } from '@behavehealth/utils/functions'

const ENDPOINTS = {
  certification: 'authority_forms',
  inspection: 'authority_forms',
  complaint: 'authority_complaints',
  issue: 'authority_complaints',
  study: 'authority_studies',
  survey: 'authority_studies',
}

export const AuthorityFormAddDropdown = (props: any) => {
  const { category, permission, featureFlagV2, manageLink, setLink, onClick, clientTrack } = props

  const [clientTrackId, setClientTrackId] = React.useState(clientTrack?.id)

  const endpoint = ENDPOINTS?.[category]

  const { data, isLoading } = useGet({
    name: [endpoint],
    url: `/${endpoint}`,
    options: { enabled: !!category },
  })

  const categoryConfig = React.useMemo(() => {
    return Object.values(AUTHORITY_FORMS).find((o) => o.category === category) || null
  }, [category])

  React.useEffect(() => {
    if (!clientTrack?.id) return

    setClientTrackId(clientTrack.id)
  }, [clientTrack?.id])

  if (!categoryConfig) return null

  return (
    <>
      <Button
        label="Manage Forms"
        glyph="settings"
        type="minimal"
        link={manageLink || `/settings/authority-forms/${category}`}
        permission="settings.authority_forms.view"
        featureFlagV2="authority_forms"
      />

      <Dropdown
        testKey="add_new_dropdown"
        label={`Add New…`}
        buttonType="primary"
        glyph="add"
        permission={permission}
        featureFlagV2={featureFlagV2}
      >
        <ClientTrackTabs clientTrack={clientTrack} activeId={clientTrackId} setActiveId={setClientTrackId} />

        {isLoading || size(data) === 0 ? (
          <State
            isLoading={isLoading}
            isEmpty={!isLoading && size(data) === 0}
            minHeight={100}
            icon="authority_forms"
            title={`Authority ${titleCase(category)} Forms`}
            emptyActions={
              <Button
                label="Manage Forms"
                glyph="settings"
                type="minimal"
                link={manageLink || `/settings/authority-forms/${category}`}
                permission="settings.authority_forms.view"
                featureFlagV2="authority_forms"
              />
            }
          />
        ) : (
          <>
            {data.map((form) => {
              const formConfig = AUTHORITY_FORMS[form.subcategory]

              if (!formConfig) return null

              return (
                <DropdownItem
                  key={form.id}
                  as={NavLink}
                  link={setLink ? setLink(form) : `${form.id}/new`}
                  onClick={onClick ? () => onClick(form) : undefined}
                  label={<span className={clsx(form.variant ? 'font-[400]' : 'font-[600]')}>{form.name}</span>}
                  before={
                    form.variant ? (
                      <Glyph glyph="curved_arrow_down_right" size={12} color={`${COLORS.blue} !important`} className="!ml-4" />
                    ) : (
                      <Icon icon={categoryConfig.icon} size={20} />
                    )
                  }
                />
              )
            })}
          </>
        )}
      </Dropdown>
    </>
  )
}
