import React from 'react'

import { useGet } from '../../hooks/useNewAPI'

import AgendaView from './AgendaView'
import CalendarTodoCard from '../Cards/CalendarTodoCard'

import TodoOverlay from '../Overlays/pages/Calendar/TodoOverlay'
import TodoBuilderOverlay from '../Overlays/pages/Calendar/TodoBuilderOverlay'
import { useDataTable } from '../DataTable/useDataTable'

type CalendarDates = {
  currentDate: string
  endDate: string
  startDate: string
}

const TodoView = () => {
  const [dates, setDates] = React.useState<CalendarDates | null>(null)

  const filters = {
    event_type: 'task',
    // action_status: 'due',
    from: dates?.startDate,
    until: dates?.endDate,
  }

  const { data, isLoading }: any = useDataTable({
    name: ['sidebar', 'todos'],
    endpoint: `/events/search`,
    params: filters,
    options: { enabled: !!dates },
  })

  const { data: templates }: any = useGet({
    name: 'todo-templates',
    url: `/event_templates`,
    params: { event_type: 'task' },
  })

  return (
    <AgendaView
      title="To-Do"
      addLabel="Add To-Do"
      icon="tasks"
      onDatesChange={setDates}
      data={data}
      templates={templates}
      isLoading={isLoading}
      recordOverlay={TodoOverlay}
      builderOverlay={TodoBuilderOverlay}
      renderEvent={({ record, isActive, onClick }: any) => <CalendarTodoCard task={record} isActive={isActive} onClick={onClick} />}
      permission="todos.create"
    />
  )
}

export default TodoView
