import React from 'react'

import { formatURL } from '../../utils/functions'

import ConfirmDialog from '../Dialogs/ConfirmDialog'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Divider from '../Divider'
import DropdownItem from '../DropdownItem'

const missingPhone = 'Missing phone no'
const missingWebsite = 'Missing website'

const Organization = ({ data, canRevokeReactivatePartnerships, onArchive, onUnarchive, revokePartnership, reactivatePartnership }: any) => {
  const { name, website, phone_no, email, whatsapp, facebook, skype, zoom, google_meet } = data

  return (
    <>
      <DropdownItem
        label={phone_no ? `Call ${name}` : missingPhone}
        glyph="landline"
        color="green"
        href={`tel:${phone_no}`}
        isDisabled={!phone_no}
      />
      {email && <DropdownItem label={`Email ${name}`} glyph="email" color="gray" href={`mailto:${email}`} />}
      {whatsapp && (
        <DropdownItem
          label="Open WhatsApp Chat"
          glyph="whatsapp"
          color="whatsapp"
          href={`https://wa.me/+1${whatsapp}`}
          target="_blank"
          useGlyphForTarget={false}
        />
      )}
      {facebook && (
        <DropdownItem
          label="Open Facebook Messenger"
          glyph="facebook_messenger"
          color="facebookMessenger"
          href={`http://m.me/${facebook}`}
          target="_blank"
          useGlyphForTarget={false}
        />
      )}
      {skype && (
        <DropdownItem
          label="Open Skype Chat"
          glyph="skype"
          color="skype"
          href={`skype:${skype}?chat`}
          target="_blank"
          useGlyphForTarget={false}
        />
      )}
      {zoom && (
        <DropdownItem
          label="Start Zoom Meeting"
          glyph="zoom"
          color="zoom"
          href={formatURL(zoom)}
          target="_blank"
          useGlyphForTarget={false}
        />
      )}
      {google_meet && (
        <DropdownItem
          label="Start Google Meeting"
          glyph="google_meet"
          color="googleMeet"
          href={formatURL(google_meet)}
          target="_blank"
          useGlyphForTarget={false}
        />
      )}

      <DropdownItem
        label={website ? 'Visit Website' : missingWebsite}
        glyph="website"
        color="blue"
        href={formatURL(website)}
        target="_blank"
        useGlyphForTarget={false}
        isDisabled={!website}
      />

      <Divider />

      {data.status === 'partner' && canRevokeReactivatePartnerships && (
        <>
          {data.internal_status === 'active_partnership' && (
            <ConfirmDialog
              title="Revoke Partnership"
              message="Are you sure you want to revoke this partnership?"
              yesColor="red"
              yesLabel="Revoke Partnership"
              onYes={revokePartnership}
              glyph="cross"
            >
              <DropdownItem label="Revoke Partnership" glyph="cross" color="red" />
            </ConfirmDialog>
          )}

          {data.internal_status === 'inactive_partnership' && (
            <ConfirmDialog
              title="Reactivate Partnership"
              message="Are you sure you want to reactivate this partnership?"
              yesColor="green"
              yesLabel="Reactivate Partnership"
              onYes={reactivatePartnership}
            >
              <DropdownItem label="Reactivate Partnership" glyph="tick_circle" color="green" />
            </ConfirmDialog>
          )}
        </>
      )}

      {data.status === 'archived' ? (
        <DeleteDialog
          title="Un-Archive Organization?"
          message="Are you sure you want to un-archive this organization?"
          yesColor="gray"
          yesLabel="Yes, Un-Archive"
          onYes={onUnarchive}
        >
          <DropdownItem label="Un-Archive Organization…" glyph="delete" color="gray" />
        </DeleteDialog>
      ) : (
        <DeleteDialog
          title="Archive Organization?"
          message="Are you sure you want to archive this organization?"
          yesColor="gray"
          yesLabel="Yes, Archive"
          onYes={onArchive}
        >
          <DropdownItem label="Archive Organization…" glyph="delete" color="gray" />
        </DeleteDialog>
      )}
    </>
  )
}

export default Organization
