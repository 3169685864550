import React from 'react'
import { BaseEditorComponent } from '@handsontable/react'
import { createRef, MouseEvent, RefObject } from 'react'
import { tint } from 'polished'
import Handsontable from 'handsontable'

import { COLORS } from '../../../theme'
import Glyph from '../../Glyph'

import { SpreadsheetDropdown } from '../common/SpreadsheetDropdown'

type SelectRendererProps = {
  TD?: HTMLTableCellElement
  value?: string | number
  row?: number
  col?: number
  cellProperties?: Handsontable.CellProperties
  config?: any
  isEditable?: boolean
  readOnly?: boolean
}

export const SelectRenderer = (props: SelectRendererProps) => {
  const { value, readOnly, isEditable, TD } = props
  const { options = [], render } = props.config || {}

  const displayValue = render && value ? render(value) : options.find((o) => o.value === value)?.label || value

  if (TD) {
    readOnly ? TD.classList.add('htDimmed') : TD.classList.remove('htDimmed')
  }

  return (
    <div className="flex items-center flex-nowrap h-[28px] justify-between">
      {displayValue}
      {isEditable && <Glyph glyph="triangle_down" size={10} color={COLORS.blue} className="ml-auto" />}
    </div>
  )
}

export class SelectEditor extends BaseEditorComponent {
  mainElementRef: RefObject<HTMLDivElement>

  constructor(props: BaseEditorComponent['props']) {
    super(props)

    this.mainElementRef = createRef()
    this.state = {
      value: '',
    }
  }

  setValue(value: any, callback: (() => void) | undefined) {
    const found = this.props.config.options.find((o: any) => o.value === value)

    this.setState((_state, _props) => {
      return { value: found ? value : '' }
    }, callback)
  }

  getValue() {
    return this.state.value
  }

  open() {
    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'block'
  }

  close() {
    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.display = 'none'
  }

  prepare(
    row: number,
    col: number,
    prop: string,
    td: HTMLTableColElement,
    originalValue: string,
    cellProperties: Handsontable.CellProperties,
  ) {
    super.prepare(row, col, prop, td, originalValue, cellProperties)

    const tdPosition = td.getBoundingClientRect()

    if (!this.mainElementRef.current) return
    this.mainElementRef.current.style.left = `${tdPosition.left + window.pageXOffset}px`
    this.mainElementRef.current.style.top = `${tdPosition.top + window.pageYOffset}px`
  }

  stopMousedownPropagation(e: MouseEvent) {
    e.stopPropagation()
  }

  render() {
    const { options = [], render } = this.props.config || {}

    return (
      <SpreadsheetDropdown ref={this.mainElementRef} onMouseDown={this.stopMousedownPropagation}>
        {options.map((option) => {
          const isActive = this.state.value && this.state.value === option.value

          return (
            <button
              key={option.value}
              css={STYLES.menuItem}
              onClick={() => {
                this.setState(
                  (state, props) => {
                    return { value: option.value }
                  },
                  () => {
                    this.finishEditing()
                  },
                )
              }}
              className={isActive ? 'is-active' : ''}
            >
              {render ? render(option.value) : option.label}
            </button>
          )
        })}
      </SpreadsheetDropdown>
    )
  }
}

const STYLES = {
  menuItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '28px',
    padding: '0.1rem 0.6rem',
    fontSize: '0.9rem',
    fontWeight: 600,
    color: COLORS.text,
    border: 'none',
    background: 'transparent',
    borderRadius: 5,

    'svg, img': {
      marginRight: '0.4rem',
    },

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      background: tint(0.9, COLORS.paleBlue),
    },

    '& > *': {
      pointerEvents: 'none',
    },
  },
}
