import React from 'react'

import Accordions from '../../../components/Accordions'
import Avatar from '../../../components/Avatar'
import Button from '../../../components/Button'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ClaimPlaceOfServiceSelect from '../../RCM/components/ClaimPlaceOfServiceSelect'
import ContextShow from '../../../components/Forms/ContextShow'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import Divider from '../../../components/Divider'
import EventAttendanceAbsentStatusSelect from '../../../components/Elements/EventAttendanceAbsentStatusSelect'
import EventAttendancePresentStatusSelect from '../../../components/Elements/EventAttendancePresentStatusSelect'
import Flex from '../../../components/Flex'
import FormSection from '../../../components/Forms/FormSection'
import Input from '../../../components/Forms/Input'
import MiniRichTextEditor from '../../../components/Forms/MiniRichTextEditor'
import OverlaySelector from '../../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Status from '../../../components/Status'
import Permission from '../../../components/Permission'
import Switch from '../../../components/Forms/Switch'

import { InlineInsuranceBillingFormSection } from '../../RCM/components/InlineInsuranceBillingFormSection'
import { TreatmentPlanSelector } from '../../../components/Forms/TreatmentPlanSelector'
import { DiagnosesSelector } from '../../Diagnoses/DiagnosesSelector'

export const SessionNote = (props: any) => {
  const { isEditable, format, client, onAttendanceUpdate, initialIsAttending, onDelete } = props

  const [isAttending, setIsAttending] = React.useState(initialIsAttending)

  React.useEffect(() => {
    if (isAttending !== undefined) {
      onAttendanceUpdate?.(isAttending)
    }
  }, [isAttending])

  if (!client) return null

  const { id: clientId } = client

  const baseModel = `local_clinical_notes.${clientId}`

  return (
    <Accordions.ItemCard
      isOpen
      id={`client-${client.id}`}
      key={`note-${client.id}`}
      identifier={`client-${client.id}`}
      className="[&_.card-title]:flex-[1_1_auto]"
      title={
        <div className="flex flex-nowrap items-center text-[1.05rem]">
          <Avatar size={28} src={client.avatar || ''} initials={client.name} className="flex-[0_0_auto] mr-1.5" />
          {client.name}
          {isAttending === true && <Status small label="Present" color="green" className="ml-auto" />}
          {isAttending === false && <Status small label="Absent" color="red" className="ml-auto" />}
        </div>
      }
    >
      <FormSection maxWidth="100%">
        <Permission featureFlagV2="diagnoses">
          <DiagnosesSelector isEditable={isEditable} baseModel={baseModel} clientId={clientId} />
        </Permission>

        <TreatmentPlanSelector baseModel={baseModel} clientId={clientId} />

        <Flex fixChildrenX gap="1rem" alignItems="flex-end">
          <RadioGroup
            label="Presence"
            model={`${baseModel}.is_attending`}
            layout="horizontal-dense"
            trueIcon="check"
            falseIcon="cross"
            validations={{
              presence: {
                message: 'Please set presence',
              },
            }}
            defaultValue={initialIsAttending}
            onUpdate={({ value }) => {
              setIsAttending(value)
            }}
          >
            <Radio label="Present" value={true} />
            <Radio label="Absent" value={false} />
          </RadioGroup>

          <ContextShow when={`${baseModel}.is_attending`} is={true}>
            <div className="!flex-auto">
              <EventAttendancePresentStatusSelect label="Present Status" model={`${baseModel}.present_status`} />
            </div>
          </ContextShow>

          <ContextShow when={`${baseModel}.is_attending`} is={false}>
            <div className="!flex-auto">
              <EventAttendanceAbsentStatusSelect label="Absent Status" model={`${baseModel}.absent_status`} />
            </div>
          </ContextShow>
        </Flex>

        <ContextShow when={`${baseModel}.is_attending`} is={true}>
          <Checkbox
            defaultValue={false}
            label="Override Default Session Date & Time"
            model={`${baseModel}.is_custom_time`}
            trueIcon="check"
            falseIcon="cross"
            css={{ flex: '1 1 auto !important' }}
          />
        </ContextShow>

        <ContextShow when={`${baseModel}.is_custom_time`} is={true}>
          <Flex gap="1rem">
            <DateTimeInput
              defaultToNow
              className="!flex-auto"
              model={`${baseModel}.custom_started_at`}
              label="Custom Date & Time"
              validations={{
                presence: {
                  message: 'Please enter a custom date and time',
                },
              }}
            />

            <Input
              model={`${baseModel}.custom_duration`}
              label="Custom Duration"
              suffix="mins"
              type="number"
              min={1}
              size={4}
              validations={{
                presence: {
                  message: 'Please enter a custom duration',
                },
                numericality: {
                  greaterThanOrEqualTo: 1,
                  message: 'The duration must be higher than 0 minutes',
                },
              }}
            />
          </Flex>
        </ContextShow>

        {isAttending && (
          <>
            {format === 'individual_assessment' && (
              <MiniRichTextEditor
                useDictation
                useQuickText
                model={`${baseModel}.assessment`}
                label="Client Note"
                validations={{
                  presence: {
                    message: 'Please provide an individual assessment',
                  },
                }}
              />
            )}

            {format === 'dap' && (
              <>
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.data`} label="Data" />
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.assessment`} label="Assessment" />
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.plan`} label="Plan" />
              </>
            )}

            {format === 'soap' && (
              <>
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.subjective`} label="Subjective" />
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.objective`} label="Objective" />
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.assessment`} label="Assessment" />
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.plan`} label="Plan" />
              </>
            )}

            {format === 'girp' && (
              <>
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.goals`} label="Goals" />
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.intervention`} label="Intervention" />
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.response`} label="Response" />
                <MiniRichTextEditor useDictation useQuickText model={`${baseModel}.plan`} label="Plan" />

                <div className="!hidden">
                  {/* keep assessment model data when toggling between different note types */}
                  <MiniRichTextEditor model={`${baseModel}.assessment`} label="Individual Assessment" />
                </div>
              </>
            )}
          </>
        )}

        <ContextShow when={`${baseModel}.is_attending`} is={false}>
          <MiniRichTextEditor
            useDictation
            useQuickText
            model={`${baseModel}.absentee_note`}
            label="Absentee Note"
            validations={{
              presence: {
                message: 'Please provide an absentee note',
              },
            }}
          />
        </ContextShow>

        {isEditable && <Divider className="!m-0" />}

        <Flex justifyContent="space-between" alignItems="center" className={!isEditable ? '!hidden' : ''}>
          <div>
            <h3 className="m-0">Insurance</h3>
            {isEditable && <div className="text-text-muted text-[0.95em]">Activate this section to add any Insurance related data</div>}
          </div>

          <div>
            <Switch horizontal size={200} withHover={false} label={null} model={`${baseModel}.use_insurance`} />
          </div>
        </Flex>

        <ContextShow when={`${baseModel}.use_insurance`} is={true}>
          <FormSection maxWidth="100%">
            <CheckboxGroup label="Insurance" trueIcon="check" falseIcon="cross" falseStyle="faded">
              <Checkbox defaultValue={false} label="Set as Billable" model={`${baseModel}.is_billable`} />
            </CheckboxGroup>

            <ContextShow when={`${baseModel}.is_billable`} is={true}>
              <InlineInsuranceBillingFormSection isBillable />
            </ContextShow>

            <ClaimPlaceOfServiceSelect model={`${baseModel}.place_of_service`} />

            <OverlaySelector
              maxWidth="100%"
              label="Location"
              type="properties.all"
              model={`${baseModel}.location`}
              icon="gps_check_in_out"
            />
          </FormSection>
        </ContextShow>

        {isEditable && (
          <>
            <Divider className="!m-0" />

            <div className="flex justify-start">
              <DeleteDialog
                title={`Remove Note for ${client.name}?`}
                message={`Are you sure you want to remove the note for ${client.name}? This action cannot be undone.`}
                onYes={() => onDelete()}
              >
                <Button label="Delete Note…" glyph="delete" color="red" type="default" display="inline-flex" size={200} />
              </DeleteDialog>
            </div>
          </>
        )}
      </FormSection>
    </Accordions.ItemCard>
  )
}
