import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import DataFormStatus from '../../components/Statuses/DataFormStatus'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { DataTableExportCSVButton } from '../../components/DataTable/DataTableExportCSVButton'

import { FILTERS } from '../Filters/config'

export const CustomNotesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'reference',
        model: 'reference.name',
        title: 'Reference',
        type: 'profile',
      },
      {
        width: 180,
        id: 'property',
        model: 'property',
        title: 'Location',
        type: 'profile',
      },
      {
        width: 200,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        width: 200,
        id: 'started_at',
        model: 'started_at',
        title: 'Start Date and Time',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'ended_at',
        model: 'ended_at',
        title: 'End Date and Time',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'created_at',
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 220,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author.name',
        title: 'Added By',
        type: 'profile',
      },
      {
        width: 220,
        id: 'author_signed_at',
        model: 'author_signed_at',
        title: 'Author Signed At',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'supervisor',
        model: 'supervisor.name',
        title: 'Supervisor',
        type: 'profile',
      },
      {
        width: 220,
        id: 'supervisor_signed_at',
        model: 'supervisor_signed_at',
        title: 'Supervisor Signed At',
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Custom Notes"
      icon="custom_notes"
      columns={columns}
      filtersConfig={FILTERS.custom_notes}
      {...props}
      headerAfter={
        <>
          <ReportRefetchButton
            forceShow={true}
            category="custom_notes"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=custom_notes'}
          />

          <DataTableExportCSVButton url={'/live_reports/csv'} params={{ category: 'custom_notes' }} />
        </>
      }
    />
  )
}
